import { push } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import { userSubmittedRegisterAuth } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import InputAuth from "../organisms/InputAuth";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

interface IProps {
  submittingAuthSms: boolean;
  actions: ActionDispatcher;
}

const RegisterAuthTemplate = React.memo((props: IProps) => {
  const { actions, submittingAuthSms } = props;

  const handleSubmit = useCallback(
    (authCode: string) => {
      actions.handleSubmit(authCode);
    },
    [actions]
  );

  const handleClickResendAuthCodeButton = useCallback(() => {
    actions.goToResendAuthCodePage();
  }, [actions]);

  return (
    <Page
      header={<SubPageHeader title="SMS認証" />}
      containerStyle={containerStyle}
    >
      <PageBody>
        <InputAuth
          loading={submittingAuthSms}
          handleSubmit={handleSubmit}
          handleClickResendAuthCodeButton={handleClickResendAuthCodeButton}
        />
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingAuthSms: state.app.isConnectedApi(EApiKey.AUTH_SMS)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public handleSubmit(authCode: string) {
    Analytics.logEvent("authsms_register");
    this.dispatch(userSubmittedRegisterAuth(authCode));
  }

  public goToResendAuthCodePage() {
    Analytics.logEvent("authsms_retry");
    this.dispatch(push("/resendSMSAuthCode"));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterAuthTemplate);
