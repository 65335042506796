import { List, Record } from "immutable";
import { ILatLng } from "../modules/search/model";
import Utility from "../util/Utility";
import ExternalLink, { IExternalLink } from "./ExternalLink";
import Feedback, { IFeedback } from "./Feedback";
import ShopBusinessData, { IShopBusinessData } from "./ShopBusinessData";
import { HIGH_RATING_MIN_VALUE } from "./ShopForSearch";
import ShopItem from "./ShopItem";
import Payment from "./Payment";

export enum EReleaseState {
  PRIVATE = 0,
  LIMITED = 1,
  PUBLISHED = 2
}

export interface IShopDataProps {
  id: number;
  person_in_charge_name: string;
  person_in_charge_name_ruby: string;
  mail: string;
  shop_name: string;
  shop_name_ruby: string;
  shop_name_en: string;
  tel: string;
  fax: string;
  address: string;
  address_en: string;
  description: string;
  standard_cooking_time: number;
  display_standard_cooking_time: string;
  header_image_path: string;
  icon_image_path: string;
  map_icon_image_path: string;
  appearance_image_path: string;
  release_state: EReleaseState;
  category_tag: string[];
  shop_order_stop_flag: boolean;
  latitude: string;
  longitude: string;
  is_favorite: boolean;
  luxury_flag: boolean;
  luxury_wait_induction_text: string;
  is_review_display: boolean;
  review_point: string;
  feedback_list: List<Feedback> | IFeedback[];
  shop_business_data: ShopBusinessData | IShopBusinessData;
  external_link: List<ExternalLink>;
  lowest_price: number;
  payment: Payment;
}

export interface IShopData extends IShopDataProps {
  feedback_list: List<Feedback>;
  shop_business_data: ShopBusinessData;
}

export const initialValues: IShopData = {
  id: 0,
  person_in_charge_name: "",
  person_in_charge_name_ruby: "",
  mail: "",
  shop_name: "",
  shop_name_en: "",
  shop_name_ruby: "",
  tel: "",
  fax: "",
  address: "",
  address_en: "",
  description: "",
  standard_cooking_time: 0,
  display_standard_cooking_time: "",
  header_image_path: "",
  icon_image_path: "",
  map_icon_image_path: "",
  appearance_image_path: "",
  release_state: EReleaseState.PRIVATE,
  category_tag: [],
  shop_order_stop_flag: false,
  latitude: "0",
  longitude: "0",
  is_favorite: false,
  luxury_flag: false,
  luxury_wait_induction_text: "",
  is_review_display: false,
  review_point: "0.0",
  feedback_list: List<Feedback>(),
  shop_business_data: new ShopBusinessData(),
  external_link: List<ExternalLink>(),
  lowest_price: 0,
  payment: new Payment()
};

/**
 * 店舗情報
 */
export default class ShopData extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        feedback_list: List(
          args.feedback_list &&
            args.feedback_list.map((i: IFeedback) => new Feedback(i))
        ),
        shop_business_data:
          args.shop_business_data &&
          new ShopBusinessData(args.shop_business_data),
        external_link: List(
          args.external_link &&
            args.external_link.map(
              (link: IExternalLink) => new ExternalLink(link)
            )
        ),
        payment: args.payment && new Payment(args.payment)
      })
    );
  }

  public getExternalLink() {
    return this.get("external_link");
  }

  public getFeedbackList() {
    return this.get("feedback_list");
  }

  public getDisplayableFeedbackList() {
    return this.getFeedbackList().filter(i => i.canDisplay());
  }

  public isLuxury() {
    return this.get("luxury_flag");
  }

  public getLuxuryWaitInductionText() {
    return this.get("luxury_wait_induction_text");
  }

  public getId(): number {
    return this.get("id");
  }

  public isStoppingReceiveOrder() {
    return this.get("shop_order_stop_flag");
  }

  public getDescription(): string {
    return this.get("description");
  }

  public getPersonInChargeName(locale: "ruby" | "" = ""): string {
    switch (locale) {
      case "ruby":
        return this.get("person_in_charge_name_ruby");
      default:
        return this.get("person_in_charge_name");
    }
  }

  public getEmail(): string {
    return this.get("mail");
  }

  public getTel(): string {
    return this.get("tel");
  }

  public getShopName(locale: "en" | "ruby" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("shop_name_en");
      case "ruby":
        return this.get("shop_name_ruby");
      default:
        return this.get("shop_name");
    }
  }

  public getAddress(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("address_en");
      default:
        return this.get("address");
    }
  }

  public canDisplayReviewPoint() {
    return this.get("is_review_display");
  }

  public isHighRating() {
    if (!this.canDisplayReviewPoint()) {
      return false;
    }
    return this.getReviewPointAsFloat() >= HIGH_RATING_MIN_VALUE;
  }

  public getReviewPoint() {
    return this.get("review_point");
  }

  public getReviewPointAsFloat() {
    const point = this.getReviewPoint();
    try {
      return parseFloat(point);
    } catch {
      return 0.0;
    }
  }

  public existsData() {
    return this.getId() !== 0;
  }

  public getHeaderImagePath(): string {
    return `${Utility.getImageDomain()}${this.get("header_image_path")}`;
  }

  public getMapIconImagePath(): string {
    return `${Utility.getImageDomain()}${this.get("map_icon_image_path")}`;
  }

  public existsAppearanceImagePath() {
    return this.get("appearance_image_path") !== "";
  }

  public getAppearanceImagePath(): string {
    return `${Utility.getImageDomain()}${this.get("appearance_image_path")}`;
  }

  public getCategoryTags(): string[] {
    return this.get("category_tag");
  }

  public getStandardCookingTime(): number {
    return this.getShopBusinessData()
      .getShopBusiness()
      .getCookingTime();
  }

  public getDisplayStandardCookingTime(): string {
    return this.get("display_standard_cooking_time");
  }

  public disabled() {
    return this.isStoppingReceiveOrder();
  }

  public getStateText() {
    if (this.isStoppingReceiveOrder()) {
      return "受付停止中";
    } else if (!this.isOpen()) {
      return "営業時間外";
    } else if (!this.canOrder()) {
      return "注文不可";
    }
    return "";
  }

  public disabledItem(item: ShopItem) {
    return item.isSoldOut() || this.disabled();
  }

  public getItemStateText(item: ShopItem | undefined) {
    if (typeof item === "undefined") {
      return "";
    }
    if (item.isSoldOut()) {
      return "SOLD OUT";
    } else if (this.isStoppingReceiveOrder()) {
      return "受付停止中";
    } else if (!this.isOpen()) {
      return "営業時間外";
    } else if (!this.canOrder()) {
      return "注文不可";
    } else if (!item.canOrder()) {
      return "受付時間外";
    }
    return "";
  }

  public getLocation(): ILatLng {
    return {
      lat: parseFloat(this.get("latitude")),
      lng: parseFloat(this.get("longitude"))
    };
  }

  /**
   * クライアント側で演出的に利用する
   */
  public setFavorite(bool: boolean) {
    return this.set("is_favorite", bool);
  }

  public isFavorite() {
    return this.get("is_favorite");
  }

  /**
   * 営業時間関連
   */
  public getShopBusinessData() {
    return this.get("shop_business_data");
  }

  public isBusy() {
    return this.getShopBusinessData().isBusy();
  }

  /* 営業時間かどうか */
  public isOpen() {
    return this.getShopBusinessData().isOpen();
  }

  /* すぐ受け取る注文が可能かどうか */
  public canFastestOrder() {
    return this.getShopBusinessData().canFastestOrder();
  }

  /* 予約注文が可能かどうか */
  public canReservationOrder() {
    return this.getShopBusinessData().canReservationOrder();
  }

  public canOrder() {
    return (
      this.isOpen() && (this.canFastestOrder() || this.canReservationOrder())
    );
  }

  public getPresentationShopBusinessCookingTime(): string {
    return this.getShopBusinessData()
      .getShopBusiness()
      .getPresentationCookingTime();
  }

  public getWeekDaysStrToBusinessHoursStrEntries() {
    return this.getShopBusinessData()
      .getShopBusiness()
      .getWeekDaysStrToBusinessHoursStrEntries();
  }

  public getLowestPrice() {
    return this.get("lowest_price");
  }

  public getPayment() {
    return this.get("payment");
  }
}
