import { goBack } from "connected-react-router";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { systemChangedCurrentSearchResultType } from "../../modules/search/actions";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import ArrowIcon from "../atoms/ArrowIcon";
import HeaderContainer from "../atoms/HeaderContainer";
import KeywordSearchField from "../organisms/KeywordSearchField";
import ListSearchResult from "../organisms/ListSearchResult";
import Analytics from "../../util/Analytics";
import Auth from "../organisms/Auth";

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const pageBodyStyle: React.CSSProperties = {
  position: "relative",
  padding: 0,
  height: "100%",
  backgroundColor: colorsConst.BACKGROUND
};

const BackIconContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  transform: translateY(50%);
  cursor: pointer;
  font-size: 1.9rem;
`;

interface IProps {}

const KeywordSearchTemplate: React.FC<IProps> = () => {
  const result = useSelector((state: ReduxModel) => {
    const searchSetting = state.search.getSearchSettings().getData("keyword");
    return state.search
      .getSearchResult()
      .getList("keyword", searchSetting.getSearchResultDisplayCondition());
  });

  const fetchingResult = useSelector((state: ReduxModel) => {
    return state.app.isConnectedApi(EApiKey.SEARCH_SHOP_WITH_KEYWORD);
  });

  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleClickShop = useCallback((shopId: number) => {
    Analytics.logEvent("search_keyword_shop", {
      content_type: "shop_id",
      item_id: `${shopId}`
    });
  }, []);

  useEffect(() => {
    dispatch(systemChangedCurrentSearchResultType("keyword"));
  }, [dispatch]);

  return (
    <Auth>
      <Page
        header={
          <HeaderContainer>
            <div style={{ paddingLeft: "24px" }}>
              <KeywordSearchField locationChange={false} />
            </div>
            <BackIconContainer onClick={handleGoBack}>
              <ArrowIcon />
            </BackIconContainer>
          </HeaderContainer>
        }
      >
        <PageBody style={pageBodyStyle}>
          <ListSearchResult
            shopList={result}
            handleClickShop={handleClickShop}
            resultType={"keyword"}
          />
        </PageBody>
      </Page>
      {fetchingResult ? (
        <LoadingOverlay>
          <Loading invert />
        </LoadingOverlay>
      ) : null}
    </Auth>
  );
};

export default KeywordSearchTemplate;
