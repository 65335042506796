import { ellipsis } from "polished";
import React, { useCallback } from "react";
import { Suggestion } from "react-places-autocomplete";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 62px;
  padding-left: 39px;
`;

const LabelText = styled.div`
  font-size: 16px;
  color: #3a3a47;
  padding-bottom: 5px;
  ${ellipsis()}
`;

const AddressText = styled.div`
  font-size: 14px;
  color: #757575;
  ${ellipsis()}
`;

interface IProps {
  suggestion: Suggestion;
  onClick: (suggestion: Suggestion) => void;
}

const SuggestionItem: React.FC<IProps> = React.memo(
  ({
    suggestion,
    suggestion: {
      formattedSuggestion: { mainText, secondaryText }
    },
    onClick
  }) => {
    const handleClick = useCallback(() => {
      onClick(suggestion);
    }, [onClick, suggestion]);

    return (
      <Container onClick={handleClick}>
        <LabelText>{mainText}</LabelText>
        <AddressText>{secondaryText}</AddressText>
      </Container>
    );
  }
);

export default SuggestionItem;
