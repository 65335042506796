import { push } from "connected-react-router";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetShopListByCategoryRequest,
  IGetShopListByCategoryResponse
} from "../../../apis/getShopListByCategory";
import { ReduxModel } from "../../../reducer";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import CategoryTag from "../../../records/CategoryTag";
import {
  UserTouchedCategoryTagThumbAction,
  updateCurrentCategoryTag,
  updateCurrentCategoryShopList
} from "../actions";
import ScrollTopMemory, { EScrollTopKey } from "../../../util/ScrollTopMemory";

export default function* userTouchedCategoryTagThumbSaga(
  action: UserTouchedCategoryTagThumbAction
) {
  try {
    const currentCategoryTag: CategoryTag | undefined = yield select<
      (state: ReduxModel) => CategoryTag | undefined
    >(state =>
      state.search
        .getCategoryTagList()
        .find(category => category.getId() === action.payload.categoryTagId)
    );

    if (typeof currentCategoryTag === "undefined") {
      throw new Error("currentArea is null");
    }

    const params: IGetShopListByCategoryRequest = {
      category_id: action.payload.categoryTagId
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_SHOP_LIST_BY_CATEGORY, params)
    );
    const {
      result,
      error
    }: {
      result: IGetShopListByCategoryResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      ScrollTopMemory.clear(`${EScrollTopKey.TEXT_SEARCH_RESULT}`);
      yield put(updateCurrentCategoryTag(currentCategoryTag));
      yield put(updateCurrentCategoryShopList(result.shop_list));
      yield put(push("/category/shopList"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
