import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  lightGray?: boolean;
  gray?: boolean;
  children?: never;
}

const SvgTime = (props: IProps) => {
  const { lightGray, gray, ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.06104 15.4157C11.8481 15.4157 14.9182 12.3457 14.9182 8.55859C14.9182 4.7715 11.8481 1.70145 8.06103 1.70145C4.27394 1.70145 1.20389 4.7715 1.20389 8.5586L0.0610354 8.5586C0.061035 4.14032 3.64276 0.558596 8.06103 0.558595C12.4793 0.558595 16.061 4.14032 16.061 8.55859C16.061 12.9769 12.4793 16.5586 8.06104 16.5586C6.54095 16.5586 5.11878 16.1335 3.90781 15.3966L4.50196 14.4203C5.53929 15.0516 6.75675 15.4157 8.06104 15.4157Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48926 4.26465H8.63211V8.36159H12.2236V9.50445H7.48926V4.26465Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
      <path
        d="M1.5867 10.9122C1.5867 11.2278 1.33087 11.4837 1.01528 11.4837C0.699685 11.4837 0.443848 11.2278 0.443848 10.9122C0.443848 10.5967 0.699685 10.3408 1.01528 10.3408C1.33087 10.3408 1.5867 10.5967 1.5867 10.9122Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70102 10.9123C1.70102 11.291 1.39401 11.598 1.0153 11.598C0.636595 11.598 0.32959 11.291 0.32959 10.9123C0.32959 10.5336 0.636595 10.2266 1.0153 10.2266C1.39401 10.2266 1.70102 10.5336 1.70102 10.9123ZM1.0153 11.4837C1.3309 11.4837 1.58673 11.2279 1.58673 10.9123C1.58673 10.5967 1.3309 10.3408 1.0153 10.3408C0.699713 10.3408 0.443876 10.5967 0.443876 10.9123C0.443876 11.2279 0.699713 11.4837 1.0153 11.4837Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
      <path
        d="M3.02616 13.4298C3.02616 13.7454 2.77032 14.0013 2.45473 14.0013C2.13914 14.0013 1.8833 13.7454 1.8833 13.4298C1.8833 13.1142 2.13914 12.8584 2.45473 12.8584C2.77032 12.8584 3.02616 13.1142 3.02616 13.4298Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14047 13.4299C3.14047 13.8086 2.83347 14.1156 2.45476 14.1156C2.07605 14.1156 1.76904 13.8086 1.76904 13.4299C1.76904 13.0511 2.07605 12.7441 2.45476 12.7441C2.83347 12.7441 3.14047 13.0511 3.14047 13.4299ZM2.45476 14.0013C2.77035 14.0013 3.02619 13.7454 3.02619 13.4299C3.02619 13.1143 2.77035 12.8584 2.45476 12.8584C2.13917 12.8584 1.88333 13.1143 1.88333 13.4299C1.88333 13.7454 2.13917 14.0013 2.45476 14.0013Z"
        fill={lightGray ? "#757575" : gray ? "#707070" : "#272727"}
      />
    </svg>
  );
};

const TimeIcon: React.FC<IProps> = (props: IProps) => <SvgTime {...props} />;

export default TimeIcon;
