import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Field, Fields, InjectedFormProps, reduxForm } from "redux-form";
import { EFormID } from "../../forms";
import {
  initialRegisterPaymentForm,
  IRegisterPaymentForm,
  validateRegisterPaymentForm
} from "../../forms/registerPayment";
import { systemOpenedModal } from "../../modules/app/actions";
import { EProcessingFlag } from "../../modules/app/model";
import { userSubmittedRegisterNewCreditCard } from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Analytics from "../../util/Analytics";
import CreditCardReader from "../../util/CreditCardReader";
import ButtonAlter from "../atoms/ButtonAlter";
import Grid from "../atoms/Grid";
import LabelText from "../atoms/LabelText";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import PeriodFields from "../organisms/FormFields/PriodFields";
import SecureCodeField from "../organisms/FormFields/SecureCodeField";
import TextField from "../organisms/FormFields/TextField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const cardNumberFieldStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  padding: "4px 8px",
  borderRadius: "3px 0 0 3px",
  border: "1px solid #E0E0E0",
  background: "#ffffff"
};

const cardNumberInputOuterStyle: React.CSSProperties = {
  padding: "0",
  border: "none"
};

const cardNumberInputStyle: React.CSSProperties = {
  fontSize: "20px"
};
const leftColumnStyle: React.CSSProperties = {
  marginRight: "12px"
};
const rightColumnStyle: React.CSSProperties = {
  marginLeft: "12px"
};

const submitButtonContainerStyle: React.CSSProperties = {
  marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
};

interface IPropsBase {
  submittingAddCreditCard: boolean;
  formValues: IRegisterPaymentForm;
  actions: ActionDispatcher;
}

interface IProps
  extends InjectedFormProps<IRegisterPaymentForm, IPropsBase>,
    IPropsBase {
  children?: never;
}

const RegisterNewCreditCardTemplate: React.FC<IProps> = React.memo(props => {
  const { submittingAddCreditCard, actions } = props;

  const handleClickLaunchReaderButton = useCallback(
    (event: React.MouseEvent<Element>) => {
      event.preventDefault();
      if (submittingAddCreditCard) {
        return;
      }
      Analytics.logEvent("credit_scan");
      CreditCardReader.scan(
        {
          requireExpiry: true,
          requireCVV: true,
          scanExpiry: true,
          hideCardIOLogo: true,
          guideColor: "#ffffff"
        },
        result => {
          try {
            props.change("credit", result.cardNumber);
            props.change("periodYear", `${result.expiryYear}`.slice(-2));
            props.change(
              "periodMonth",
              `${result.expiryMonth}`.padStart(2, "0")
            );
            props.change("secureCode", result.cvv);
          } catch (exception) {
            console.log({ exception });
          }
        },
        () => console.log("read credit card cancelled")
      );
    },
    [props.change, submittingAddCreditCard]
  );

  const canSubmit = useMemo(() => {
    return props.valid;
  }, [props.valid]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!canSubmit) {
        props.touch(...Object.keys(initialRegisterPaymentForm));
        return;
      }
      actions.handleSubmit();
    },
    [actions]
  );

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const userTouchedOpenCardPeriodTooltip = useCallback(() => {
    actions.userTouchedOpenCardPeriodTooltip();
  }, [actions]);

  const userTouchedOpenCardSecurityCordTooltip = useCallback(() => {
    actions.userTouchedOpenCardSecurityCordTooltip();
  }, [actions]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="クレジットカード登録"
            handleClickBack={handleGoBack}
          />
        }
        containerStyle={containerStyle}
      >
        <PageBody>
          <form onSubmit={handleSubmit} id={EFormID.REGISTER_PAYMENT}>
            <div>
              <LabelText>カード番号</LabelText>
              <TextField
                id="cardNumber"
                name="credit"
                type="number"
                placeholder="0000 0000 0000 0000"
                autoComplete="cc-number"
                disabled={submittingAddCreditCard}
                wrapperStyle={cardNumberFieldStyle}
                outerStyle={cardNumberInputOuterStyle}
                style={cardNumberInputStyle}
                hasToolTip={true}
                handleTouchedTooltip={handleClickLaunchReaderButton}
              />
            </div>

            <Grid container align="flex-start">
              <Grid item rate={1} style={leftColumnStyle}>
                <Fields
                  names={["periodMonth", "periodYear"]}
                  component={PeriodFields}
                  disabled={submittingAddCreditCard}
                  handleTouchedTooltip={userTouchedOpenCardPeriodTooltip}
                />
              </Grid>
              <Grid item rate={1} style={rightColumnStyle}>
                <Field
                  name="secureCode"
                  component={SecureCodeField}
                  disabled={submittingAddCreditCard}
                  handleTouchedTooltip={userTouchedOpenCardSecurityCordTooltip}
                />
              </Grid>
            </Grid>

            <div style={submitButtonContainerStyle}>
              <ButtonAlter
                appearance={"main"}
                block
                loading={submittingAddCreditCard}
                disabled={!canSubmit}
                type="submit"
                form={EFormID.REGISTER_PAYMENT}
              >
                カードを登録
              </ButtonAlter>
            </div>
          </form>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingAddCreditCard: state.app.isProcessing(
    EProcessingFlag.SUBMIT_REGISTER_CREDIT_CARD
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public handleSubmit() {
    Analytics.logEvent("credit_register");
    this.dispatch(userSubmittedRegisterNewCreditCard());
  }

  public userTouchedOpenCardPeriodTooltip() {
    this.dispatch(systemOpenedModal("TOOLTIP_CARD_PERIOD", {}));
  }

  public userTouchedOpenCardSecurityCordTooltip() {
    this.dispatch(systemOpenedModal("TOOLTIP_CARD_SECURITY_CORD", {}));
  }
}

const reduxFormConfig = {
  form: EFormID.REGISTER_PAYMENT,
  initialValues: initialRegisterPaymentForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateRegisterPaymentForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IRegisterPaymentForm, IPropsBase>(reduxFormConfig)(
    RegisterNewCreditCardTemplate
  )
);
