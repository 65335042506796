import React, { useMemo } from "react";
import styled from "styled-components";
import OrderData, { EOrderState } from "../../records/OrderData";
import ShopInfo from "../../records/ShopInfo";
import OrderAcceptIcon from "../atoms/OrderAcceptIcon";
import OrderCookingIcon from "../atoms/OrderCookingIcon";
import OrderProcessIcon from "../atoms/OrderProcessIcon";
import OrderReceivableIcon from "../atoms/OrderReceivableIcon";

const Container = styled.div``;
const ProcessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0;
`;

const OrderProcessItem = styled.div<{ active?: boolean }>`
  position: relative;
  flex: 0 0 10%;
  padding-bottom: 1.5em;
  font-size: 1.4rem;
  opacity: ${p => (p.active ? 1 : 0.25)};
`;

const StatusMessage = styled.div<{ finished?: boolean }>`
  width: 100%;
  padding-bottom: 10px;
  ${p => (p.finished ? "color: #DE3823;" : "")}
`;

const Pipeline = styled.div<{ active?: boolean }>`
  margin: 0 4px;
  margin-bottom: 0.5em;
  height: 6px;
  flex: 1 1 20%;
  background-color: ${p => (p.active ? "#DE3823" : "#C4C4C4")};
`;

const iconStyle: React.CSSProperties = {
  width: "100%",
  height: "100%"
};

interface IProps {
  order: OrderData;
}

const OrderProcess = React.memo((props: IProps) => {
  const { order } = props;
  const state = order.getOrderState();
  const progress = useMemo(() => {
    switch (state) {
      case EOrderState.ORDERED:
        return 1;
      case EOrderState.APPROVED:
        return 2;
      case EOrderState.FINISHED_LUXURY:
        return 3;
      case EOrderState.UER_ARRIVALED:
      case EOrderState.CONFIRM_USER_ARRIVAL:
      case EOrderState.FINISHED:
        return 4;
      case EOrderState.DENIED:
      case EOrderState.CANCELED:
      case EOrderState.AUTO_CANCELED:
      case EOrderState.FAILED_AUTO_CANCEL:
      case EOrderState.ERROR:
      default:
        return 0;
    }
  }, [state]);

  const shopInfo: ShopInfo = useMemo(() => {
    return order.getShopInfo();
  }, [order]);

  const statusMessage = useMemo(() => {
    switch (state) {
      case EOrderState.ORDERED:
        return `${shopInfo.getShopName()}に連絡しています...`;
      case EOrderState.APPROVED:
        return `現在調理中です...`;
      case EOrderState.FINISHED_LUXURY:
        return `調理が完了しました。お店に到着したら 受取場所を確認してください。`;
      case EOrderState.UER_ARRIVALED:
      case EOrderState.CONFIRM_USER_ARRIVAL:
      case EOrderState.FINISHED:
        return `調理が完了しました。受取が可能です。`;
      case EOrderState.DENIED:
      case EOrderState.CANCELED:
      case EOrderState.AUTO_CANCELED:
      case EOrderState.FAILED_AUTO_CANCEL:
      case EOrderState.ERROR:
      default:
        return `注文がキャンセルされました...`;
    }
  }, [state, shopInfo]);

  return (
    <Container>
      <ProcessContainer>
        <OrderProcessItem active={progress >= 1}>
          <OrderProcessIcon style={iconStyle} />
        </OrderProcessItem>

        <Pipeline active={progress >= 1} />

        <OrderProcessItem active={progress >= 1}>
          <OrderAcceptIcon style={iconStyle} />
        </OrderProcessItem>

        <Pipeline active={progress >= 2} />

        <OrderProcessItem active={progress >= 2}>
          <OrderCookingIcon style={iconStyle} />
        </OrderProcessItem>

        <Pipeline active={progress >= 3} />

        <OrderProcessItem active={progress >= 3}>
          <OrderReceivableIcon style={iconStyle} />
        </OrderProcessItem>
      </ProcessContainer>
      <StatusMessage finished={order.canDisplayFinishedOrderNotice()}>
        {statusMessage}
      </StatusMessage>
    </Container>
  );
});

export default OrderProcess;
