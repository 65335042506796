import { List, Record } from "immutable";
import moment from "moment";
import MappedDateToTimeList, {
  IMappedDateToTimeList
} from "./MappedDateToTimeList";
import { EOrderTimeType } from "./OrderData";
import OrderItem, { IOrderItem } from "./OrderItem";
import ReceptionTimeList from "./ReceptionTimeList";
import ReservationTime from "./ReservationTime";
import ShopData, { IShopDataProps } from "./ShopData";
import { IShopItemProps } from "./ShopItem";

interface ICartDataBase {
  cart_id: string;
  shop_id: number;
  item_list: IShopItemProps[] | List<OrderItem>;
  item_total_price: number;
  tax_rate: string;
  sales_tax: number;
  coupon_name: string;
  discount_code: string;
  discount_price: number;
  total_price: number;
  user_payment_price: number;
  reception_time_list: ReceptionTimeList;
  time_type: EOrderTimeType;
  receive_datetime: string;
  shop_data: IShopDataProps;
}
export interface ICartDataProps extends ICartDataBase {
  receivable_date_list: [];
}

// MEMO: receive_datetimeとtime_typeはサーバーレスポンスに含まれるので、update時は優先的に適用する必要があります。
// CartData内でのロジックではreservationTimeを使用する
export interface ICartData
  extends Omit<ICartDataBase, "time_type" | "receive_datetime"> {
  item_list: List<OrderItem>;
  shop_data: ShopData;
  receivable_date_list: List<MappedDateToTimeList>;
  reservationTime: ReservationTime;
}

const initialValues: ICartData = {
  cart_id: "",
  shop_id: 0,
  item_list: List<OrderItem>(),
  item_total_price: 0,
  tax_rate: "",
  sales_tax: 0,
  coupon_name: "",
  discount_code: "",
  discount_price: 0,
  total_price: 0,
  user_payment_price: 0,
  reception_time_list: new ReceptionTimeList(),
  receivable_date_list: List(),
  shop_data: new ShopData(),
  reservationTime: ReservationTime.createFastest()
};

export default class CartData extends Record(initialValues) {
  /**
   * カートを跨いだ合計金額を取得
   */
  public static getTotalPreOrderCount(cartList: List<CartData>): number {
    return cartList.reduce(
      (acc, crr) =>
        acc +
        crr
          .getItemList()
          .reduce((iacc, icrr) => iacc + icrr.getOrderCount(), 0),
      0
    );
  }

  /**
   * idからCartDataを取得する
   */
  public static findById(
    cartList: List<CartData>,
    cartId: string
  ): CartData | undefined {
    return cartList.find(i => i.getId() === cartId);
  }

  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        item_list: List(
          args.item_list
            ? args.item_list.map((item: IOrderItem) => new OrderItem(item))
            : []
        ),
        reception_time_list: new ReceptionTimeList(
          args.reception_time_list ? args.reception_time_list : {}
        ),
        shop_data: new ShopData(args.shop_data && args.shop_data),
        // MEMO: receive_datetimeとtime_typeはサーバーレスポンスに含まれるので、update時は優先的に適用する必要があります。
        // CartData内でのロジックではreservationTimeを使用する
        reservationTime:
          args.receive_datetime && args.time_type
            ? ReservationTime.fromTimeTypeAndDatetimeString(
                args.time_type,
                args.receive_datetime
              )
            : args.reservationTime
            ? args.reservationTime
            : ReservationTime.createFastest(),
        receivable_date_list: List(
          args.receivable_date_list
            ? args.receivable_date_list.map(
                (i: IMappedDateToTimeList) => new MappedDateToTimeList(i)
              )
            : []
        )
      })
    );
  }

  /**
   * カートの最初のアイテムを取得する
   */
  public getFirstItem() {
    const itemList = this.getItemList();
    return itemList.first<undefined>();
  }

  public findItemByIndex(index: number) {
    return this.getItemList().get(index);
  }

  /**
   * カートに表示する受け取り時間を取得する
   */
  public getPresentationReceiveDatetime(): Date {
    const reservationTime = this.getReservationTime();

    if (reservationTime.isFastest()) {
      return this.getReceptionTimeList().getFastestReceiveDate();
    }

    return reservationTime.getDate();
  }

  public exists() {
    return this.getId() !== "";
  }

  public getReceptionTimeList(): ReceptionTimeList {
    return this.get("reception_time_list");
  }

  public getOrderItemById(orderItemId: number) {
    return this.getItemList().get(orderItemId);
  }

  public getId(): string {
    return this.get("cart_id");
  }

  public getShopId(): number {
    return this.get("shop_id");
  }

  public getItemList(): List<OrderItem> {
    return this.get("item_list");
  }

  public getItemTotalPrice(): number {
    return this.get("item_total_price");
  }

  public getTaxRate(): string {
    return this.get("tax_rate");
  }

  public getSalesTax(): number {
    return this.get("sales_tax");
  }

  public getCouponName(): string {
    return this.get("coupon_name");
  }

  public getDiscountCode(): string {
    return this.get("discount_code");
  }

  public existsDiscountCode() {
    return this.getDiscountCode().trim() !== "";
  }

  public getDiscountPrice(): number {
    return this.get("discount_price");
  }

  public getTotalPrice(): number {
    return this.get("total_price");
  }

  public getUserPaymentPrice(): number {
    return this.get("user_payment_price");
  }

  public getReservationTime() {
    return this.get("reservationTime");
  }

  /**
   * @return YYYY/MM/DD[]
   */
  public getReceivableDateArray() {
    const list = this.get("receivable_date_list");
    return list
      .flatMap(i => {
        if (i.getTimeStringArray().length <= 0) {
          return [];
        }
        return [i.getDateString()];
      })
      .toArray();
  }

  /**
   * @param dateString YYYYMMDD
   *
   * @return HH:mm[]
   */
  public getReceivableTimeArray(dateString: string) {
    const list = this.get("receivable_date_list");
    const targetDate = list.find(
      i => moment(i.getDateAsDate()).format("YYYYMMDD") === dateString
    );
    if (typeof targetDate === "undefined") {
      return [];
    }
    return targetDate.getTimeStringArray();
  }

  public getShopData() {
    return this.get("shop_data");
  }

  public updateShopItemFavoriteState(shopItemId: number, bool: boolean) {
    const itemList = this.getItemList();
    const updateItemList = itemList.map(item => {
      if (item.getShopItemId() === shopItemId) {
        return item.setFavorite(bool);
      } else {
        return item;
      }
    });
    return this.set("item_list", updateItemList);
  }
}
