import { Action } from "redux";
import { IFeatureItem } from "../../records/FeatureItem";
import { INewsItem } from "../../records/NewsItem";
import { INotificationItem } from "../../records/NotificationItem";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_NOTIFICATIONS = "notification/U_ACCESSED_TO_PAGE_THAT_NEEDS_NOTIFICATIONS",

  R_UPDATE_NOTIFICATION_LIST = "notificaiton/R_UPDATE_NOTIFICATION_LIST",
  R_UPDATE_NEWS_LIST = "notification/R_UPDATE_NEWS_LIST",
  R_STARTED_FETCHING_NOTIFICATIONS = "notifications/R_STARTED_FETCHING_NOTIFICATIONS",
  R_FINISHED_FETCHING_NOTIFICATIONS = "notifications/R_FINISHED_FETCHING_NOTIFICATIONS",

  U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURES = "notificaiton/U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURES",
  R_UPDATE_FEATURE_LIST = "notificaiton/R_UPDATE_FEATURE_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURE_DETAIL = "notificaiton/U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURE_DETAIL",
  R_UPDATE_FEATURE_DETAIL_ACTION = "notificaiton/R_UPDATE_FEATURE_DETAIL_ACTION",
  R_INIT_FEATURE_DETAIL_ACTION = "notificaiton/R_INIT_FEATURE_DETAIL_ACTION"
}

export interface StartedFetchingNotificationsAction extends Action {
  type: ActionTypes.R_STARTED_FETCHING_NOTIFICATIONS;
}
export const startedFetchingNotifications = (): StartedFetchingNotificationsAction => ({
  type: ActionTypes.R_STARTED_FETCHING_NOTIFICATIONS
});

export interface FinishedFetchingNotificationsAction extends Action {
  type: ActionTypes.R_FINISHED_FETCHING_NOTIFICATIONS;
}
export const finishedFetchingNotifications = (): FinishedFetchingNotificationsAction => ({
  type: ActionTypes.R_FINISHED_FETCHING_NOTIFICATIONS
});

export interface UserAccessedToPageThatNeedsNotificationsAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NOTIFICATIONS;
}
export const userAccessedToPageThatNeedsNotifications = (): UserAccessedToPageThatNeedsNotificationsAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NOTIFICATIONS
});

export interface UpdateNotificationListAction extends Action {
  type: ActionTypes.R_UPDATE_NOTIFICATION_LIST;
  payload: {
    list: INotificationItem[] | null;
  };
}
export const updateNotificationList = (list: INotificationItem[] | null) => ({
  type: ActionTypes.R_UPDATE_NOTIFICATION_LIST,
  payload: {
    list
  }
});

export interface UpdateNewsListAction extends Action {
  type: ActionTypes.R_UPDATE_NEWS_LIST;
  payload: {
    list: INewsItem[] | null;
  };
}
export const updateNewsList = (
  list: INewsItem[] | null
): UpdateNewsListAction => ({
  type: ActionTypes.R_UPDATE_NEWS_LIST,
  payload: {
    list
  }
});

export interface UserAccessedToPageThatNeedsFeaturesAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURES;
}
export const userAccessedToPageThatNeedsFeatures = (): UserAccessedToPageThatNeedsFeaturesAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURES
});

export interface UpdateFeatureListAction extends Action {
  type: ActionTypes.R_UPDATE_FEATURE_LIST;
  payload: {
    list: IFeatureItem[] | null;
  };
}
export const updateFeatureList = (
  list: IFeatureItem[]
): UpdateFeatureListAction => ({
  type: ActionTypes.R_UPDATE_FEATURE_LIST,
  payload: {
    list
  }
});

export interface UserAccessedToPageThatNeedsFeatureDetailAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURE_DETAIL;
  payload: {
    id: number;
  };
}
export const userAccessedToPageThatNeedsFeatureDetail = (id: number) => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURE_DETAIL,
  payload: {
    id
  }
});

export interface UpdateFeatureDetailAction extends Action {
  type: ActionTypes.R_UPDATE_FEATURE_DETAIL_ACTION;
  payload: {
    source: string;
  };
}
export const updateFeatureDetail = (
  source: string
): UpdateFeatureDetailAction => ({
  type: ActionTypes.R_UPDATE_FEATURE_DETAIL_ACTION,
  payload: {
    source
  }
});

export interface InitFeatureDetailAction extends Action {
  type: ActionTypes.R_INIT_FEATURE_DETAIL_ACTION;
}
export const initFeatureDetail = (): InitFeatureDetailAction => ({
  type: ActionTypes.R_INIT_FEATURE_DETAIL_ACTION
});

export type NotificationActions =
  | StartedFetchingNotificationsAction
  | FinishedFetchingNotificationsAction
  | UserAccessedToPageThatNeedsNotificationsAction
  | UpdateNewsListAction
  | UpdateNotificationListAction
  | UserAccessedToPageThatNeedsFeaturesAction
  | UpdateFeatureListAction
  | UserAccessedToPageThatNeedsFeatureDetailAction
  | UpdateFeatureDetailAction
  | InitFeatureDetailAction;
