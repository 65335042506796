import { List } from "immutable";
import { fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IClearCartListWithShopRequest,
  IClearCartListWithShopResponse
} from "../../../apis/clearCartListWithShop";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCart,
  UserTouchedRequestClearCartButtonAction
} from "../actions";

export default function* userTouchedRequestClearCartButtonSaga(
  action: UserTouchedRequestClearCartButtonAction
) {
  try {
    const { cartId } = action.payload;

    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());

    const cart = CartData.findById(cartList, cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    const params: IClearCartListWithShopRequest = {
      shop_id: cart.getShopId()
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.CLEAR_CART_LIST_WITH_SHOP, params)
    );

    const {
      result,
      error
    }: { result: IClearCartListWithShopResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (result && !error) {
      yield put(updateCart(result.cart_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `カートのクリアに失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
