import { List, Record } from "immutable";
import EnvUtil from "../util/EnvUtil";
import Utility from "../util/Utility";
import Price from "./Price";
import ShopOption, { IShopOption } from "./ShopOption";
import ShopOptionSet, { IShopOptionSetProps } from "./ShopOptionSet";

export interface IOrderItemProps {
  shop_item_id: number;
  order_count: number;
  unit_price: number;
  image_path: string;
  item_name: string;
  item_name_en: string;
  description: string;
  description_en: string;
  instruction_text: string;
  option_set_list: IShopOptionSetProps[] | List<ShopOptionSet>;
  option_list: IShopOption[] | List<ShopOption>;
  is_favorite: boolean;
}

export interface IOrderItem extends IOrderItemProps {
  option_set_list: List<ShopOptionSet>;
  option_list: List<ShopOption>;
}

const initialValues: IOrderItem = {
  shop_item_id: 0,
  order_count: 0,
  unit_price: 0,
  image_path: "",
  item_name: "",
  item_name_en: "",
  description: "",
  description_en: "",
  instruction_text: "",
  option_set_list: List<ShopOptionSet>(),
  option_list: List<ShopOption>(),
  is_favorite: false
};

export default class OrderItem extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        option_set_list: List(
          args.option_set_list &&
            args.option_set_list.map(
              (optionSet: IShopOptionSetProps) => new ShopOptionSet(optionSet)
            )
        ),
        option_list: List(
          args.option_list &&
            args.option_list.map(
              (option: IShopOption) => new ShopOption(option)
            )
        )
      })
    );
  }

  public getShopItemId(): number {
    return this.get("shop_item_id");
  }

  public getOrderCount(): number {
    return this.get("order_count");
  }

  public getUnitPrice(): number {
    return this.get("unit_price");
  }

  public getPresentationPrice(): string {
    return Price.getPresentationValue(this.getUnitPrice());
  }

  public getImagePath() {
    const path = this.get("image_path");
    if (path.trim() === "") {
      return "";
    }
    return EnvUtil.withImageDomain(path);
  }

  public getItemName(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("item_name_en");
      default:
        return this.get("item_name");
    }
  }

  public getDescription(): string {
    return this.get("description");
  }

  public getInstructionText(): string {
    return this.get("instruction_text");
  }

  public updateInstructionText(text: string) {
    return this.set("instruction_text", text);
  }

  public isSetInstructionText() {
    return this.getInstructionText() !== "";
  }

  public getOptionSetList(): List<ShopOptionSet> {
    return this.get("option_set_list");
  }

  public findOptionSetbyOptionSetId(
    optionSetId: number
  ): ShopOptionSet | undefined {
    return this.getOptionSetList().find(
      option => option.getId() === optionSetId
    );
  }

  public updateOptionSetList(
    optionSetId: number,
    optionId: number,
    remove: boolean
  ) {
    const updateOptionSetList = this.getOptionSetList().map(
      (optionSet: ShopOptionSet) =>
        optionSet.getId() === optionSetId
          ? optionSet.updateOptionList(optionId, remove)
          : optionSet
    );
    return this.set("option_set_list", updateOptionSetList);
  }

  public clearSelectedOptionSetList(optionSetId: number) {
    const updateOptionSetList = this.getOptionSetList().map(
      (optionSet: ShopOptionSet) =>
        optionSet.getId() === optionSetId
          ? optionSet.clearSelectedOptionList()
          : optionSet
    );
    return this.set("option_set_list", updateOptionSetList);
  }

  public getSelectedOptionTotalPrice() {
    const sum = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;
    const totalPrice: number = this.getOptionSetList()
      .map((optionSet: ShopOptionSet) =>
        optionSet.getSelectedOptionTotalPrice()
      )
      .reduce(sum);
    return totalPrice;
  }

  public getItemTotalPrice() {
    const totalPrice: number = this.getUnitPrice() * this.getOrderCount();
    return totalPrice;
  }

  public getSelectedOptionIdList(): List<number> {
    const selectedOptionSetList = this.getOptionSetList().flatMap(
      (optionSet: ShopOptionSet) => {
        const selectedOptionList = optionSet.getSelectedOptionList();
        return typeof selectedOptionList !== "undefined"
          ? selectedOptionList.map((option: ShopOption) => option.getId())
          : List();
      }
    );
    return selectedOptionSetList;
  }

  public getSelectedOptionIdArray(): number[] {
    return this.getSelectedOptionIdList().toArray();
  }

  public getOptionList(): List<ShopOption> {
    return this.get("option_list");
  }

  // MEMO: optionSetIdとOptionIdの配列を渡して、他のidとマージして取得するメソッド
  public getMergedSelectedOptionIdArrayByOptionSetIdAndOptionIdArray(
    optionSetId: number,
    optionIdList: number[]
  ) {
    return this.getOptionSetList()
      .flatMap(optionSet => {
        if (optionSet.getId() === optionSetId) {
          return List(optionIdList);
        }
        return optionSet.getSelectedOptionList().map(i => i.getId());
      })
      .toArray();
  }

  public isFavorite() {
    return this.get("is_favorite");
  }
  public setFavorite(bool: boolean) {
    return this.set("is_favorite", bool);
  }
}
