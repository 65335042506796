import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51462 1L1.2289 7L5.51462 13H19V1H5.51462ZM5 14L0 7L5 0H20V14H5Z"
        fill="#3A3A47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7C8 8.10457 7.10457 9 6 9C4.89543 9 4 8.10457 4 7C4 5.89543 4.89543 5 6 5C7.10457 5 8 5.89543 8 7ZM6 8C6.55228 8 7 7.55228 7 7C7 6.44772 6.55228 6 6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const PromotionIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default PromotionIcon;
