import { List, Record } from "immutable";
import Utility from "../util/Utility";
import ShopItemReceptionTime, {
  IShopItemReceptionTime
} from "./ShopItemReceptionTime";
import ShopOption from "./ShopOption";
import ShopOptionSet, {
  IOptionSet,
  IShopOptionSetProps
} from "./ShopOptionSet";

const NOT_EXIST_ITEM_COOKING_TIME = -1;

export interface IShopItemProps {
  shop_item_id: number;
  item_name: string;
  item_name_en: string;
  description: string;
  description_en: string;
  price: number;
  image_path: string;
  instruction_text_flag: number;
  sold_out_flag: boolean;
  option_set_list: IShopOptionSetProps[] | List<ShopOptionSet>;
  main_flag: boolean;
  is_reception: boolean;
  reception_time_list: List<ShopItemReceptionTime>;
  cooking_time: number;
  is_favorite: boolean;
  recommend_flag: boolean;
}

export interface IShopItem extends IShopItemProps {
  option_set_list: List<ShopOptionSet>;
}

const initialValues: IShopItem = {
  shop_item_id: 0,
  item_name: "",
  item_name_en: "",
  description: "",
  description_en: "",
  price: 0,
  image_path: "",
  instruction_text_flag: 0,
  sold_out_flag: false,
  option_set_list: List<ShopOptionSet>(),
  main_flag: false,
  is_reception: false,
  reception_time_list: List<ShopItemReceptionTime>(),
  cooking_time: NOT_EXIST_ITEM_COOKING_TIME,
  is_favorite: false,
  recommend_flag: false
};

/**
 * 店舗メニュー
 */
export default class ShopItem extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        option_set_list: List(
          args.option_set_list &&
            args.option_set_list.map(
              (optionSet: IOptionSet) => new ShopOptionSet(optionSet)
            )
        ),
        reception_time_list: List(
          args.reception_time_list &&
            args.reception_time_list.map(
              (receptionTime: IShopItemReceptionTime) =>
                new ShopItemReceptionTime(receptionTime)
            )
        )
      })
    );
  }

  public getCookingTime() {
    return this.get("cooking_time");
  }

  public existsItemCookingTime() {
    return this.getCookingTime() !== NOT_EXIST_ITEM_COOKING_TIME;
  }

  public isMain() {
    return this.get("main_flag");
  }

  public isSoldOut() {
    return this.get("sold_out_flag");
  }

  public isRecommend() {
    return this.get("recommend_flag");
  }

  public getId(): number {
    return this.get("shop_item_id");
  }

  public getPrice(): number {
    return this.get("price");
  }

  public getPresentationPrice(): string {
    return `${this.getPrice().toLocaleString()}円`;
  }

  public getName(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("item_name_en");
      default:
        return this.get("item_name");
    }
  }

  public getDescription(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("description_en");
      default:
        return this.get("description");
    }
  }

  public getImagePath(): string {
    return `${Utility.getImageDomain()}${this.get("image_path")}`;
  }

  public getOptionSetList(): List<ShopOptionSet> {
    return this.get("option_set_list");
  }

  public initialOptionIdArray(): number[] {
    return this.getOptionSetList()
      .flatMap(optionSet => {
        return optionSet
          .initializedRequiredOption()
          .map(option => option.getId());
      })
      .toArray();
  }

  public getShopItemReceptionTimeList(): List<ShopItemReceptionTime> {
    return this.get("reception_time_list");
  }

  public canOrder() {
    return this.get("is_reception");
  }

  public existShopItemRectptionTimeList() {
    return !this.getShopItemReceptionTimeList().isEmpty();
  }

  /**
   * 必須選択のオプションのみ取得
   */
  public getRequiredOptionSetList(): List<ShopOptionSet> {
    return this.getOptionSetList().filter(optionSet => optionSet.isRequired());
  }

  public existsRequiredOptionSet() {
    return !this.getRequiredOptionSetList().isEmpty();
  }

  /**
   * 任意選択のオプションセットのみ取得
   */
  public getOptionalOptionSetList(): List<ShopOptionSet> {
    return this.getOptionSetList().filterNot(optionSet =>
      optionSet.isRequired()
    );
  }

  /**
   * shop_option_idを指定して紐付くオプションセットを取得する
   */
  public findOptionSetByShopOptionId(
    shopOptionId: number
  ): ShopOptionSet | null {
    const result = this.getOptionSetList().find(optionSet => {
      return optionSet
        .getOptionList()
        .some(option => option.getId() === shopOptionId);
    });
    return typeof result === "undefined" ? null : result;
  }

  /**
   * shop_option_idからoptionオブジェクトを検索する
   * 無ければnullが帰る
   */
  public findOptionById(shopOptionId: number): ShopOption | null {
    const option = this.getOptionSetList()
      .reduce(
        (reduction, value) => reduction.merge(value.getOptionList()),
        List<ShopOption>()
      )
      .find(shopOption => shopOption.getId() === shopOptionId);

    return typeof option === "undefined" ? null : option;
  }

  public getSelectedOptionIdList(): List<number> {
    const selectedOptionSetList = this.getOptionSetList().flatMap(
      (optionSet: ShopOptionSet) => {
        const selectedOptionList = optionSet.getSelectedOptionList();
        return selectedOptionList.map((option: ShopOption) => option.getId());
      }
    );
    return selectedOptionSetList;
  }

  public isFavorite() {
    return this.get("is_favorite");
  }

  public setFavorite(bool: boolean) {
    return this.set("is_favorite", bool);
  }
}
