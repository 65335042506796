import { put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { systemClosedModal } from "../../app/actions";
import {
  updateSearchSetting,
  UserSubmittedFilterSettingsAction
} from "../actions";

export default function* userSubmittedFilterSettingsSaga(
  action: UserSubmittedFilterSettingsAction
) {
  const {
    resultType,
    maxDisplayableCookingTime,
    filterCategoryList
  } = action.payload;

  const currentSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  const updated = currentSetting.withMutations(s =>
    s
      .setMaxDisplayableCookingTime(maxDisplayableCookingTime)
      .setFilterCategoryTagList(filterCategoryList)
  );

  yield put(systemClosedModal("SETTING_FILTER"));
  yield put(updateSearchSetting(resultType, updated));
}
