import { push } from "connected-react-router";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxModel } from "../../reducer";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import Grid from "../atoms/Grid";
import Button from "../atoms/ButtonAlter";
import Carousel from "../atoms/Carousel";
import { ShopSliderItemViewer } from "./ShopForSearchViewer";
import {
  userTouchedMoreNearOfficeShopListButton,
  userAccessedToPageThatNeedsNearOfficeShopList
} from "../../modules/search/actions";
import { ERegistrationPlace } from "../../records/PlaceListItem";
import MoreArrowIcon from "../atoms/MoreArrowIcon";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND};
  margin-bottom: 15px;
`;

const Inner = styled.div`
  margin-left: -10px;
  padding: 0 20px 0 30px;
  overflow: hidden;
  .slick-list {
    overflow: visible;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 8px;
  padding: 5px;
  line-height: ${28 / 19};
  border-bottom: 2px solid #ff3008;
`;

const Title = styled.div`
  flex-grow: 1;
  font-size: 19px;
  font-weight: 500;
  color: #595959;
`;

const MoreItem = styled.div`
  flex-grow: 0;
`;

const ItemInner = styled.div`
  padding: 12px;
  border-radius: 3px;
`;

const MoreItemButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50vw - 40px);
  height: 100%;
  color: ${colorsConst.MAIN};
`;

const BlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vw * ${115 / 375});
`;

interface IProps {}

const HomeNearOfficeShopList: React.FC<IProps> = React.memo(() => {
  const nearOfficeShopList = useSelector((state: ReduxModel) => {
    return state.search.getNearOfficeShopList();
  });

  const reduceShopList = useMemo(() => {
    return nearOfficeShopList.slice(0, 10);
  }, [nearOfficeShopList]);

  const isRegisterd = useSelector((state: ReduxModel) => {
    return state.user.getData().isRegisteredOfficeOrSchool();
  });

  const dispatch = useDispatch();

  const handleClickRegister = useCallback(() => {
    dispatch(
      push(`/user/inputRegistrationPlace/${ERegistrationPlace.OFFICE_SCHOOL}`)
    );
  }, [dispatch]);

  const handleClickMore = useCallback(() => {
    dispatch(userTouchedMoreNearOfficeShopListButton());
  }, [dispatch]);

  useEffect(() => {
    if (isRegisterd) {
      dispatch(userAccessedToPageThatNeedsNearOfficeShopList());
    }
  }, [dispatch, isRegisterd]);

  return (
    <Container>
      <Inner>
        <TitleContainer>
          <Title>勤務地周辺で選ぶ</Title>
          {isRegisterd && reduceShopList.size >= 10 && (
            <MoreItem onClick={handleClickMore}>すべて見る</MoreItem>
          )}
        </TitleContainer>
        {isRegisterd ? (
          <>
            {nearOfficeShopList.isEmpty() ? (
              <BlankContainer>検索結果がありません</BlankContainer>
            ) : (
              <Carousel
                infinite={false}
                dots={false}
                autoplay={false}
                speed={250}
                arrows={false}
              >
                {reduceShopList.map(shop => {
                  return (
                    <div key={shop.getId()}>
                      <ItemInner>
                        <ShopSliderItemViewer
                          item={shop}
                          type={"shop_near_office_list"}
                        />
                      </ItemInner>
                    </div>
                  );
                })}
                {nearOfficeShopList.size > 10 && (
                  <div>
                    <MoreItemButton onClick={handleClickMore}>
                      <MoreArrowIcon
                        style={{ fontSize: "40px", marginBottom: "15px" }}
                      />
                      もっと見る
                    </MoreItemButton>
                  </div>
                )}
              </Carousel>
            )}
          </>
        ) : (
          <Grid container style={{ padding: "20px 10px" }}>
            <Grid item grow={1}>
              勤務地を登録すると利用できます
            </Grid>
            <Grid item grow={0} onClick={handleClickRegister}>
              <Button appearance="sub">登録する</Button>
            </Grid>
          </Grid>
        )}
      </Inner>
    </Container>
  );
});

export default HomeNearOfficeShopList;
