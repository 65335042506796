import React from "react";
import styled from "styled-components";
import Loading from "../molecules/Loading";
import Modal from "../molecules/Modal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 106px;
  padding: 30px 0 45px;
  background: #ffffff;
  border-radius: 4px;
`;

const LoadingContainer = styled.div`
  padding: 30px;
`;

const StatusText = styled.div`
  font-size: 13px;
  color: #3a3a47;
`;

interface IProps {
  isOpen: boolean;
  statusText: string;
}

const LoadingDialog: React.FC<IProps> = React.memo(({ isOpen, statusText }) => {
  return (
    <Modal isOpen={isOpen}>
      <Container>
        <LoadingContainer>
          <Loading color="#C4C4C4" />
        </LoadingContainer>
        <StatusText>{statusText}</StatusText>
      </Container>
    </Modal>
  );
});

export default LoadingDialog;
