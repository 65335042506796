import { List, Record } from "immutable";
import moment from "moment";
import colorsConst from "../styles/const/colorsConst";
import EvilDatetime from "../util/EvilDatetime";
import Utility from "../util/Utility";
import OrderItem, { IOrderItem, IOrderItemProps } from "./OrderItem";
import ShopInfo, { IShopInfo } from "./ShopInfo";
import UserInfo, { IUserInfo } from "./UserInfo";
import { EPaymentType } from "./PaymentType";

// 注文可能最小数
export const ORDERABLE_COUNT_MIN = 1;

// 注文可能最大数
export const ORDERABLE_COUNT_MAX = 10;

export enum EOrderTimeType {
  FASTEST = 1,
  SPECIFIED = 2
}

export enum EOrderState {
  ORDERED = 1, // 注文
  APPROVED = 2, // 注文承認
  DENIED = 3, // 注文拒否
  FINISHED = 4, // 調理完了
  CANCELED = 5, // キャンセル
  AUTO_CANCELED = 6, // 自動キャンセル
  CANCEL_SETTLEMENT = 7, // 決済キャンセル
  FINISHED_LUXURY = 10, // 調理完了(高級店)
  UER_ARRIVALED = 11, // ユーザーが到着した(高級店)
  CONFIRM_USER_ARRIVAL = 12, // 店舗がユーザーの到着を確認(高級店)
  FAILED_AUTO_CANCEL = 90, // 自動キャンセルエラー
  ERROR = 99 // エラー
}

export enum EFeedbackState {
  NOT_SENDABLE = 1, // フィードバック不可
  SENDABLE = 2, // フィードバック可能
  SENT = 3 // 送信済み
}

export interface IOrderDataProps {
  /**
   * 注文番号
   */
  order_no: string;

  /**
   * ユーザー情報概要
   */
  user_info: IUserInfo | UserInfo;

  /**
   * 店舗情報概要
   */
  shop_info: IShopInfo | ShopInfo;

  /**
   * 受け取り時間設定タイプ
   */
  time_type: EOrderTimeType;

  /**
   * 受け取り時間
   */
  receive_time: string;

  /**
   * 表示用のフィールド
   */
  receive_datetime: string;

  /**
   * 注文状態
   */
  order_state: EOrderState;

  /**
   * 小計
   */
  item_total_price: number;

  /**
   * 消費税率
   */
  tax_rate: string;

  /**
   * 消費税
   */
  sales_tax: number;

  /**
   * 割引金額
   */
  discount_price: number;

  /**
   * 合計
   */
  total_price: number;

  /**
   * ユーザーが実際に支払った金額
   */
  user_payment_price: number;

  /**
   * 購入メニュー一覧
   */
  item_list: IOrderItemProps[] | List<OrderItem>;

  /**
   * 注文日時
   */
  order_date: string;

  /**
   * 受け取り日時
   */
  receive_date: string;

  /**
   * クーポンコード
   */
  discount_code: string;

  /**
   * クレジットカード番号
   */
  card_no: string;

  /**
   * 本日受け取りフラグ
   */
  is_today_receive: boolean;

  /**
   * フィードバックステータス
   */
  feedback_state: EFeedbackState;

  /**
   * フィードバック
   */
  feedback_point: number;

  /**
   * フィードバックテキスト
   */
  feedback_text: string;

  /**
   * 調理完了時刻 YYYY-MM-DD HH:II:SS（調理完了していないときは 空文字列）
   */
  finish_datetime: string;

  /**
   * 支払い方法
   */
  payment_type: EPaymentType;
}

export interface IOrderData extends IOrderDataProps {
  user_info: UserInfo;
  shop_info: ShopInfo;
  item_list: List<OrderItem>;
}

const initialValues: IOrderData = {
  order_no: "",
  user_info: new UserInfo(),
  shop_info: new ShopInfo(),
  time_type: EOrderTimeType.FASTEST,
  receive_time: "",
  receive_datetime: "",
  order_state: EOrderState.ORDERED,
  item_total_price: 0,
  tax_rate: "",
  sales_tax: 0,
  discount_price: 0,
  total_price: 0,
  user_payment_price: 0,
  item_list: List<OrderItem>(),
  order_date: "",
  receive_date: "",
  discount_code: "",
  card_no: "",
  is_today_receive: false,
  feedback_state: EFeedbackState.NOT_SENDABLE,
  feedback_point: 0,
  feedback_text: "",
  finish_datetime: "",
  payment_type: EPaymentType.CREDIT
};

export default class OrderData extends Record(initialValues) {
  public static filterOnlyCompleteOrder(list: List<OrderData>) {
    return list.filter(i =>
      [
        EOrderState.FINISHED,
        EOrderState.UER_ARRIVALED,
        EOrderState.CONFIRM_USER_ARRIVAL
      ].includes(i.getOrderState())
    );
  }
  /**
   * 注文に対する問い合わせフォーマットを定義
   */
  public static getInquiryMailToOrderFormat = (
    userEmailAddress: string,
    userId: string,
    orderShop: string,
    orderItems: List<OrderItem>,
    orderNumber: string
  ) => {
    const orderItemsList = orderItems
      .map((item: OrderItem) => {
        return item.getItemName();
      })
      .join(",");
    return {
      to: "cs@menu.inc",
      subject: "店舗・商品について",
      body: `＜お客様へ＞
        注文されたお店や商品についてのお問い合わせはこちらに記載してください。
        店舗名：${orderShop}
        商品名：${orderItemsList}
        注文番号：${orderNumber}
        お問い合わせ内容：
            
        
        ${Utility.generateInquiryMainBodyBottom(userId, userEmailAddress)}
        `
    };
  };

  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        item_list: List(
          args.item_list &&
            args.item_list.map((item: IOrderItem) => new OrderItem(item))
        ),
        user_info: args.user_info && new UserInfo(args.userInfo),
        shop_info: args.shop_info && new ShopInfo(args.shop_info)
      })
    );
  }

  public getFirstOrderItem() {
    return this.getItemList().first<undefined>();
  }

  public canDisplayFinishedOrderNotice() {
    const state = this.getOrderState();
    return [
      EOrderState.FINISHED,
      EOrderState.UER_ARRIVALED,
      EOrderState.CONFIRM_USER_ARRIVAL
    ].includes(state);
  }

  public canSendFeedback() {
    return (
      this.getFeedbackState() === EFeedbackState.SENDABLE ||
      this.getFeedbackState() === EFeedbackState.SENT
    );
  }

  public canDisplayReceipt() {
    const state = this.getOrderState();
    return (
      ![
        EOrderState.ORDERED,
        EOrderState.AUTO_CANCELED,
        EOrderState.CANCELED,
        EOrderState.CANCEL_SETTLEMENT,
        EOrderState.DENIED,
        EOrderState.ERROR,
        EOrderState.FAILED_AUTO_CANCEL
      ].includes(state) && this.getPaymentType() !== EPaymentType.LOCALLY
    );
  }

  public canDisplayRecieveTimeAim() {
    const state = this.getOrderState();
    return [
      EOrderState.ORDERED,
      EOrderState.AUTO_CANCELED,
      EOrderState.CANCELED,
      EOrderState.CANCEL_SETTLEMENT,
      EOrderState.DENIED,
      EOrderState.ERROR,
      EOrderState.FAILED_AUTO_CANCEL
    ].includes(state);
  }

  public exists() {
    return this.getOrderNumber() !== "";
  }

  public isActive() {
    const state = this.getOrderState();
    return [
      EOrderState.APPROVED,
      EOrderState.FINISHED_LUXURY,
      EOrderState.UER_ARRIVALED
    ].includes(state);
  }

  public canDisplayUserArrivedButton() {
    return this.getOrderState() === EOrderState.FINISHED_LUXURY;
  }

  /**
   * 現在の進行中の注文か
   * 未承認の注文も含む
   */
  public isOngoing() {
    const state = this.getOrderState();
    return (
      this.isLatestOrder() ||
      [
        EOrderState.ORDERED,
        EOrderState.APPROVED,
        EOrderState.FINISHED_LUXURY,
        EOrderState.UER_ARRIVALED
      ].includes(state)
    );
  }

  public getReceiveDatetime() {
    return this.get("receive_datetime");
  }

  /**
   * 受け取り時間のDateオブジェクトを取得
   */
  public getReceiveDatetimeAsDate() {
    return new Date(this.getReceiveDatetime().replace(/-/g, "/"));
  }

  /**
   * 最短の受け取りDateを取得
   */
  public getFastestReceiveDate() {
    return EvilDatetime.getDateByYYYYMMDDHHmmString(
      this.getReceiveDate(),
      this.getReceiveTime()
    );
  }

  public getPresentationOrderStateText(): string {
    switch (this.getOrderState()) {
      case EOrderState.ORDERED:
        return "確認中";
      case EOrderState.APPROVED:
        return "調理中";
      case EOrderState.FINISHED:
        return "調理済";
      case EOrderState.FINISHED_LUXURY:
        return "到着通知が必要です";
      case EOrderState.UER_ARRIVALED:
        return "店舗確認待ちです";
      case EOrderState.CONFIRM_USER_ARRIVAL:
        return "店舗確認済です";
      case EOrderState.DENIED:
      case EOrderState.CANCELED:
      case EOrderState.AUTO_CANCELED:
        return "キャンセルされました";
      case EOrderState.ERROR:
      case EOrderState.FAILED_AUTO_CANCEL:
        return "エラー";
      default:
        return "不明";
    }
  }

  public getColorByOrderState(): string {
    switch (this.getOrderState()) {
      case EOrderState.ORDERED:
        return colorsConst.WARNING;
      case EOrderState.APPROVED:
      case EOrderState.FINISHED_LUXURY:
      case EOrderState.UER_ARRIVALED:
        return colorsConst.SUCCESS;
      case EOrderState.FINISHED:
      case EOrderState.CONFIRM_USER_ARRIVAL:
        return "#000000";
      case EOrderState.DENIED:
        return "#a0a0a0";
      default:
        return "#a0a0a0";
    }
  }

  public getStuffMessage() {
    switch (this.getOrderState()) {
      case EOrderState.ORDERED:
        return `ご注文ありがとうございます。ご注文が確定してからの調理となりますので、出来上がりまでお時間を頂いております。`;
      case EOrderState.APPROVED:
        return `現在、調理中です。 出来上がりましたら通知いたします。 お時間まで今しばらくお待ちください。`;
      case EOrderState.FINISHED:
        return `調理が完了して、お渡しする準備が整いました。気をつけてお越しください。`;
      case EOrderState.FINISHED_LUXURY:
        return "調理が完了しました。お店に到着したら注文詳細画面の到着ボタンを押してください。";
      case EOrderState.UER_ARRIVALED:
        return "お店にお知らせしました。少々お待ちください。10分待って店員が来なければ、入店してください。";
      case EOrderState.CONFIRM_USER_ARRIVAL:
        return "お客様の来店を店員が確認しました。まもなくお渡しいたします。";

      default:
        return "";
    }
  }

  public isUnreviewed() {
    return this.getFeedbackState() === EFeedbackState.SENDABLE;
  }

  public getOrderNumber(): string {
    return this.get("order_no");
  }

  public getUserInfo(): UserInfo {
    return this.get("user_info");
  }

  public getShopInfo(): ShopInfo {
    return this.get("shop_info");
  }

  public getTimeType() {
    return this.get("time_type");
  }

  public getReceiveTime(): string {
    return this.get("receive_time");
  }

  public getOrderState(): EOrderState {
    return this.get("order_state");
  }

  public getItemTotalPrice(): number {
    return this.get("item_total_price");
  }

  public getTaxRate(): string {
    return this.get("tax_rate");
  }

  public getSalesTax(): number {
    return this.get("sales_tax");
  }

  public getDiscountPrice(): number {
    return this.get("discount_price");
  }

  public getTotalPrice(): number {
    return this.get("total_price");
  }

  public getUserPaymentPrice(): number {
    return this.get("user_payment_price");
  }

  public getItemList(): List<OrderItem> {
    return this.get("item_list");
  }

  public getOrderDate(): string {
    return this.get("order_date");
  }

  public getOrderDateAsDate(): Date {
    try {
      const date = new Date(this.getOrderDate().replace(/-/g, "/"));
      return date;
    } catch (_) {
      return new Date();
    }
  }

  public getReceiveDate(): string {
    return this.get("receive_date");
  }

  public getReceiveDateAsDate(): Date {
    try {
      const date = new Date(this.getReceiveDate().replace(/-/g, "/"));
      return date;
    } catch (_) {
      return new Date();
    }
  }

  public getDiscountCode(): string {
    return this.get("discount_code");
  }

  public existsDiscountCode() {
    return this.getDiscountCode() !== "";
  }

  public getCardNumber(): string {
    return this.get("card_no");
  }

  public getFeedbackState() {
    return this.get("feedback_state");
  }

  public canCancelOrder() {
    return this.getOrderState() === EOrderState.ORDERED;
  }

  public isCanceled() {
    return (
      this.getOrderState() === EOrderState.DENIED ||
      this.getOrderState() === EOrderState.CANCELED ||
      this.getOrderState() === EOrderState.AUTO_CANCELED
    );
  }

  public getFeedbackPoint() {
    return this.get("feedback_point");
  }

  public getFeedbackText() {
    return this.get("feedback_text");
  }

  public canShowMapInOrderDetail() {
    return this.getOrderState() !== EOrderState.ORDERED;
  }

  public getFinishDateTime() {
    return this.get("finish_datetime");
  }

  public isLatestOrder() {
    const nowTime = moment();
    const finishDatetime = this.getFinishDateTimeAsDate();
    if (finishDatetime === null) {
      return false;
    }
    const offsetFinishDatetime = moment(finishDatetime).add(1, "hour");
    return nowTime.isBefore(offsetFinishDatetime);
  }

  public getFinishDateTimeAsDate(): Date | null {
    try {
      const date = new Date(this.getFinishDateTime().replace(/-/g, "/"));
      return date;
    } catch {
      return null;
    }
  }

  public getPaymentType() {
    return this.get("payment_type");
  }

  public getPresentationPaymentTypeText(): string {
    switch (this.getPaymentType()) {
      case EPaymentType.CREDIT:
        return "クレジットカード支払い";
      case EPaymentType.LOCALLY:
        return "お店で支払い";
      default:
        return "";
    }
  }
}
