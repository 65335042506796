import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const ReserveIcon: React.FC<IProps> = ({
  size = 21,
  color = "#000",
  ...rest
}) => (
  <svg width={size} height={size} viewBox="0 0 21 21" fill="none" {...rest}>
    <circle cx={10.5} cy={10.5} r={9.8} stroke={color} strokeWidth={1.4} />
    <path
      d="M10 5v7h5"
      stroke={color}
      strokeWidth={1.4}
      strokeLinecap="round"
    />
  </svg>
);

export default ReserveIcon;
