import React from "react";
import styled from "styled-components";
import { ETagType } from "../../../records/ShopForSearch";
import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";

const TAG_IMAGE_SIZE = { width: 84, height: 15 };

export const Container = styled.div<{ tagType: ETagType }>`
  display: inline-block;
  width: ${TAG_IMAGE_SIZE.width};
  height: ${TAG_IMAGE_SIZE.height};
  border-radius: 3px;
`;

const getPresentationTagText = (type: ETagType): string => {
  switch (type) {
    case ETagType.MICHELIN:
      return "ミシュラン掲載店";
    case ETagType.GRANDPRIX:
      return "グランプリ受賞店";
    case ETagType.TABELOG:
      return "食べログ3.5以上";
    default:
      return "";
  }
};

const getTagImage = (type: ETagType) => {
  switch (type) {
    case ETagType.MICHELIN:
      return image1;
    case ETagType.GRANDPRIX:
      return image2;
    case ETagType.TABELOG:
      return image3;
    default:
      return "";
  }
};

interface IProps {
  tagType: ETagType;
}

const Tag: React.FC<IProps> = React.memo(props => {
  const { tagType } = props;
  const image = getTagImage(tagType);
  return image !== "" ? (
    <Container tagType={tagType}>
      <img
        src={image}
        width={TAG_IMAGE_SIZE.width}
        height={TAG_IMAGE_SIZE.height}
        alt={getPresentationTagText(tagType)}
      />
    </Container>
  ) : null;
});

export default Tag;
