import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IUpdateMailAddressForm,
  initialUpdateMailAddressForm,
  validateUpdateMailAddressForm
} from "../../forms/updateMailAddress";
import { userSubmittedUpdateMailAddress } from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ButtonAlter from "../atoms/ButtonAlter";
import LabelText from "../atoms/LabelText";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import TextField from "../organisms/FormFields/TextField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const UpdateMailTemplate: React.FC<
  IProps & InjectedFormProps<IUpdateMailAddressForm>
> = React.memo(props => {
  const {
    submittingUpdateMailAddress,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialUpdateMailAddressForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="メールアドレス"
            handleClickBack={handleClickBack}
          />
        }
        containerStyle={containerStyle}
        contentStyle={contentStyle}
      >
        <PageBody style={pageBodyStyle}>
          <div style={{ flexGrow: 1 }}>
            <form id={EFormID.UPDATE_MAIL_ADDRESS} onSubmit={handleSubmitWrap}>
              <LabelText htmlFor="mail">メールアドレス</LabelText>
              <TextField
                id="mail"
                name="mail"
                type="email"
                placeholder="menu@mail.com"
                autoComplete="email"
              />
            </form>
          </div>
          <div style={{ flexGrow: 0 }}>
            <ButtonAlter
              block
              appearance={"secondary"}
              loading={submittingUpdateMailAddress}
              disabled={!valid}
              type="submit"
              form={EFormID.UPDATE_MAIL_ADDRESS}
            >
              変更
            </ButtonAlter>
          </div>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingUpdateMailAddress: state.app.isConnectedApi(
    EApiKey.UPDATE_MAIL_ADDRESS
  ),
  initialValues: { mail: state.user.getData().getEmail() }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleSubmit() {
    dispatch(userSubmittedUpdateMailAddress());
  }
});

const reduxFormConfig = {
  form: EFormID.UPDATE_MAIL_ADDRESS,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateUpdateMailAddressForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IUpdateMailAddressForm, IProps>(reduxFormConfig)(UpdateMailTemplate)
);
