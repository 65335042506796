import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const LinkText = styled.span`
  color: ${colorsConst.LINK};
  padding-bottom: 0.05em;
  border-bottom: ${colorsConst.LINK} solid 1px;
`;

export default LinkText;
