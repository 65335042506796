import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { userAccessedToPageThatNeedsAuth } from "../../modules/user/actions";
import { ReduxAction } from "../../reducer";

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public redirectByAccountState() {
    this.dispatch(userAccessedToPageThatNeedsAuth());
  }
}

interface IProps {
  actions: ActionDispatcher;
  children: React.ReactNode;
}

/**
 * ユーザー認証が必要なcomponentのwrapper
 */
const Auth = React.memo((props: IProps) => {
  const { actions, children } = props;

  useEffect(() => {
    actions.redirectByAccountState();
  }, []);

  return <>{children}</>;
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
