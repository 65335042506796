import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { ISendFeedbackRequest } from "../../../apis/sendFeedback";
import { AlertType } from "../../../records/Alert";
import { closeModal, systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateFeedbackPoint,
  updateFeedbackState,
  updateFeedbackText,
  UserSubmittedFeedbackAction
} from "../actions";

export default function* userSubmittedFeedbackSaga(
  action: UserSubmittedFeedbackAction
) {
  const { orderNumber, comment, rating } = action.payload;
  const params: ISendFeedbackRequest = {
    order_no: orderNumber,
    point: rating,
    comment
  };
  try {
    const commonApiTask = yield fork(
      commonApiSaga,
      EApiKey.SEND_FEEDBACK,
      params,
      { fallback: true }
    );
    const { result, error } = yield join(commonApiTask);
    if (result && !error) {
      yield put(closeModal("SEND_FEEDBACK"));
      yield put(updateFeedbackState(orderNumber));
      yield put(updateFeedbackPoint(orderNumber, rating));
      if (comment !== "") {
        yield put(updateFeedbackText(orderNumber, comment));
      }
    } else {
      throw error;
    }
  } catch {
    yield put(closeModal("SEND_FEEDBACK"));
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `評価の送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
