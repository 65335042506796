import styled from "styled-components";
import sizesConst from "../../styles/const/sizesConst";

interface IContainerProps {
  transparent?: boolean;
  shopItem?: boolean;
  hasBorder?: boolean;
}

const headerHeight = sizesConst.GLOBAL_HEADER;
const HeaderContainer = styled.div<IContainerProps>`
  position: relative;
  height: ${headerHeight};
  height: calc(${headerHeight} + constant(safe-area-inset-top));
  height: calc(${headerHeight} + env(safe-area-inset-top));
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-color: ${p => (p.transparent ? "transparent" : "white")};
  box-shadow: ${p =>
    p.transparent ? "" : "0 2px 4px 0 rgba(211, 211, 211, 0.5)"};
  overflow: hidden;
  ${p => (p.transparent ? "z-index: 2;" : "")}
  ${p => (p.shopItem ? "position: absolute;" : "")}
  ${p => (p.shopItem ? "width: 100%;" : "")}
  ${p =>
    p.hasBorder ? "box-shadow: none; border-bottom: 1px solid #D9D9DD" : ""}
`;

export default HeaderContainer;
