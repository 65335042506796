import { List } from "immutable";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import OrderData from "../../records/OrderData";
import { ReduxModel } from "../../reducer";
import FeedbackDialog from "./FeedbackDialog";

interface IProps {
  selectedOrderNumber: string | null;
  orderList: List<OrderData>;
}

const HomeFeedbackDialog: React.FC<IProps> = React.memo(props => {
  const { selectedOrderNumber, orderList } = props;

  const inProgressOrderData: OrderData = useMemo(() => {
    const selectedOrderListList: List<OrderData> = orderList.filter(
      order => order.getOrderNumber() === selectedOrderNumber
    );
    return selectedOrderListList.get(0, new OrderData());
  }, [selectedOrderNumber, orderList]);

  return (
    <>
      <FeedbackDialog orderData={inProgressOrderData} />
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  selectedOrderNumber: state.order.getOrderNumberForDialog(),
  orderList: state.order.getDisplayableOrderList()
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeFeedbackDialog);
