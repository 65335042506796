import { put, take } from "redux-saga/effects";
import LocalRepository from "../../../util/LocalRepository";
import { ActionTypes, systemOpenedModal } from "../../app/actions";
import { FLAG_THAT_VIEWED_GEOLOCATION_PERMISSION_DIALOG } from "../model";

export default function* requestGeolocationPermissionSaga() {
  if (
    !LocalRepository.hasItem(FLAG_THAT_VIEWED_GEOLOCATION_PERMISSION_DIALOG)
  ) {
    yield put(systemOpenedModal("REQUEST_GEOLOCATION_PERMISSION", {}));
    yield take(ActionTypes.U_TOUCHED_GRANT_GEOLOCATION_PERMISSION_BUTTON);
    LocalRepository.setItem(
      FLAG_THAT_VIEWED_GEOLOCATION_PERMISSION_DIALOG,
      "true"
    );
  }
}
