import React from "react";
import styled from "styled-components";
import Thumbnail from "./Thumbnail";

const Container = styled.div<{ radius: number }>`
  position: relative;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  border-radius: 100%;
  overflow: hidden;
`;

const DisableOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface IProps {
  src: string;
  radius: number;
  disabled?: boolean;
  [other: string]: any;
}

const CircleThumbnail = React.memo((props: IProps) => {
  const { src, radius, disabled, ...rest } = props;
  return (
    <Container radius={radius} {...rest}>
      <>
        <Thumbnail src={src} />
        {disabled ? <DisableOverlay /> : null}
      </>
    </Container>
  );
});

export default CircleThumbnail;
