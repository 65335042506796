import { push } from "connected-react-router";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IAddCartListRequest,
  IAddCartListResponse
} from "../../../apis/addCartList";
import { AlertType } from "../../../records/Alert";
import ReservationTime from "../../../records/ReservationTime";
import { ReduxModel } from "../../../reducer";
import Appsflyer from "../../../util/Appsflyer";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCart, UserSubmittedAddToCartAction } from "../actions";

export default function* userSubmittedAddToCartSaga(
  action: UserSubmittedAddToCartAction
) {
  try {
    const {
      resultType,
      params: { shop_id, shop_item_id }
    } = action.payload;
    yield put(setProcessingFlag(EProcessingFlag.SUBMIT_ADD_CART));

    const selectorFunc = (state: ReduxModel) => ({
      count: state.order.getPreOrderCount(),
      instruction_text: state.order.getPreOrderInstructionText(),
      option_list: state.order.getPreOrderOptionList().toArray()
    });

    const editableParams: ReturnType<typeof selectorFunc> = yield select<
      typeof selectorFunc
    >(selectorFunc);

    const reservationTime: ReservationTime = yield select<
      (state: ReduxModel) => ReservationTime
    >(state =>
      state.search
        .getSearchSettings()
        .getData(resultType)
        .getReservationTime()
    );

    const params: IAddCartListRequest = {
      shop_id,
      shop_item_id,
      count: editableParams.count,
      instruction_text: editableParams.instruction_text,
      option_list: editableParams.option_list,
      time_type: reservationTime.getTimeType(),
      receive_datetime: reservationTime.getDatetimeString()
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.ADD_CART_LIST, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IAddCartListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      // カート追加時のイベント集計
      const appVersion: string | null = yield select<
        (state: ReduxModel) => string | null
      >(state => state.app.getAppVersion());
      const userId: string = yield select<(state: ReduxModel) => string>(
        state => state.user.getData().getId()
      );
      if (
        userId !== "" &&
        appVersion &&
        Appsflyer.isEnableVersion(appVersion)
      ) {
        Appsflyer.trackEvent("menu_add_cart", userId, "");
      }

      yield put(updateCart(result.cart_list));
      yield put(push(`/cart`));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `カートへの追加に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.SUBMIT_ADD_CART));
  }
}
