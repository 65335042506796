import { ActionTypes, NotificationActions } from "./actions";
import NotificationModel from "./model";

export default function reducer(
  model = new NotificationModel(),
  action: NotificationActions
): NotificationModel {
  switch (action.type) {
    case ActionTypes.R_UPDATE_NOTIFICATION_LIST:
      return model.updateNotificationList(action.payload.list);
    case ActionTypes.R_UPDATE_NEWS_LIST:
      return model.updateNewsList(action.payload.list);
    case ActionTypes.R_STARTED_FETCHING_NOTIFICATIONS:
      return model.startFetchingNotifications();
    case ActionTypes.R_FINISHED_FETCHING_NOTIFICATIONS:
      return model.finishFetchingNotifications();
    case ActionTypes.R_UPDATE_FEATURE_LIST:
      return model.updateFeatureList(action.payload.list);
    case ActionTypes.R_UPDATE_FEATURE_DETAIL_ACTION:
      return model.updateFeatureDetail(action.payload.source);
    case ActionTypes.R_INIT_FEATURE_DETAIL_ACTION:
      return model.initFeatureDetail();
    default:
      return model;
  }
}
