import { List, Record } from "immutable";
import Utility from "../util/Utility";

export interface IArea {
  area_id: number;
  area_name: string;
  area_image_path: string;
  shop_count: number;
}

const initial: IArea = {
  area_id: 0,
  area_name: "",
  area_image_path: "",
  shop_count: 0
};

export default class Area extends Record(initial) {
  public getId() {
    return this.get("area_id");
  }
  public existsData() {
    return this.getId() !== 0;
  }
  public getImagePath() {
    return `${Utility.getImageDomain()}${this.get("area_image_path")}`;
  }
  public getName() {
    return this.get("area_name");
  }
  public getShopCount() {
    return this.get("shop_count");
  }
}
