import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { batch, connect } from "react-redux";
import { Dispatch } from "redux";
import { systemClosedModal } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import ButtonAlter from "../atoms/ButtonAlter";
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter
} from "../atoms/Dialog";
import Modal from "../molecules/Modal";
import colorsConst from "../../styles/const/colorsConst";

const mapStateToProps = (state: ReduxModel) => ({
  isOpenDialog: state.app.getModalManager().canDisplay("REQUEST_REGISTER_EMAIL")
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeDialog() {
    dispatch(systemClosedModal("REQUEST_REGISTER_EMAIL"));
  },
  goToRegister() {
    batch(() => {
      dispatch(push("/user/info"));
      dispatch(systemClosedModal("REQUEST_REGISTER_EMAIL"));
    });
  }
});

const contentStyle: React.CSSProperties = {
  margin: "35px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RequestRegisterEmailDialog: React.FC<IProps> = React.memo(props => {
  const { isOpenDialog, closeDialog, goToRegister } = props;

  const handleClickClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleClickGoToRegister = useCallback(() => {
    goToRegister();
  }, [goToRegister]);

  return (
    <Modal
      isOpen={isOpenDialog}
      handleClickBackdrop={handleClickClose}
      contentStyle={contentStyle}
    >
      <Dialog style={{ padding: "30px" }}>
        <DialogHeader>メールアドレスが登録されていません</DialogHeader>
        <DialogBody>
          領収書の送信には、メールアドレスの登録が必要です。登録はマイページから行えます。
        </DialogBody>
        <DialogFooter>
          <ButtonAlter
            block
            appearance={"secondary"}
            onClick={handleClickGoToRegister}
          >
            メールアドレスを登録
          </ButtonAlter>
          <div
            style={{
              paddingTop: "24px",
              textAlign: "center"
            }}
          >
            <span
              onClick={handleClickClose}
              style={{ color: colorsConst.SECONDARY }}
            >
              閉じる
            </span>
          </div>
        </DialogFooter>
      </Dialog>
    </Modal>
  );
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestRegisterEmailDialog);
