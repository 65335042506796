import { replace } from "connected-react-router";
import { List } from "immutable";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdateCartListRequest,
  IUpdateCartListResponse
} from "../../../apis/updateCartList";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import ReservationTime from "../../../records/ReservationTime";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCart, UserSubmittedUpdateCartAction } from "../actions";

export default function* userSubmittedUpdateCartSaga(
  action: UserSubmittedUpdateCartAction
) {
  try {
    const selectorFunc = (state: ReduxModel) => ({
      count: state.order.getPreOrderCount(),
      instruction_text: state.order.getPreOrderInstructionText(),
      option_list: state.order.getPreOrderOptionList().toArray()
    });

    const editableParams: ReturnType<typeof selectorFunc> = yield select<
      typeof selectorFunc
    >(selectorFunc);

    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());
    const cart = CartData.findById(cartList, action.payload.cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    const reservationTime: ReservationTime = cart.getReservationTime();

    const shopId = cart.getShopId();

    const params: IUpdateCartListRequest = {
      shop_id: shopId,
      index: action.payload.index,
      count: editableParams.count,
      option_list: editableParams.option_list,
      instruction_text: editableParams.instruction_text,
      time_type: reservationTime.getTimeType(),
      receive_datetime: reservationTime.getDatetimeString()
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.UPDATE_CART_LIST, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IUpdateCartListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateCart(result.cart_list));
      yield put(replace("/cart"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `カート内アイテムの更新に失敗しました。	
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
