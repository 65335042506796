import * as React from "react";
import { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { systemRemovedAlert } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import CommonAlert from "./CommonAlert";

const mapStateToProps = (state: ReduxModel) => ({
  isOpenDialog: state.app.getModalManager().canDisplay("COMMON_ALERT"),
  modalPayload: state.app.getModalManager().getPayload("COMMON_ALERT"),
  isPageTransitionAnimationPlaying: state.app.isPageTransitionAnimationPlaying()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userClickCloseModalButton(id: string) {
    dispatch(systemRemovedAlert(id));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const CommonAlertContainer: React.FC<IProps> = React.memo(props => {
  const {
    isOpenDialog,
    modalPayload,
    isPageTransitionAnimationPlaying,
    userClickCloseModalButton
  } = props;

  const alert = useMemo(() => {
    if (typeof modalPayload === "undefined") {
      return undefined;
    }
    return modalPayload.alert;
  }, [modalPayload]);

  const handleClickCloseModal = useCallback(
    (id: string) => {
      userClickCloseModalButton(id);
    },
    [userClickCloseModalButton]
  );

  return (
    <div>
      {isOpenDialog &&
        typeof alert !== "undefined" &&
        !isPageTransitionAnimationPlaying && (
          <CommonAlert
            key={alert.getId()}
            alert={alert}
            handleClick={handleClickCloseModal}
          />
        )}
    </div>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonAlertContainer);
