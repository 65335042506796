import * as React from "react";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import image0 from "./0.png";
import image1 from "./1.png";
import image2 from "./2.png";

const HOME_IMAGE_LIST = [image0, image1, image2];
const TRANSITION_DURATION = 5000;

const Container = styled.div`
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 100vw;
  height: ${(100 * 228) / 375}vw;
`;

const Img = styled.img<{ active: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 1s;
  opacity: ${p => (p.active ? 1 : 0)};
`;

const HomeTopImg: React.FC = React.memo(
  () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useLayoutEffect(() => {
      let unmounted = false;

      const intervalHandler = () => {
        if (unmounted) {
          return;
        }
        setActiveIndex(active => {
          if (active + 1 >= HOME_IMAGE_LIST.length) {
            return 0;
          }
          return active + 1;
        });
      };

      const timerId = setInterval(intervalHandler, TRANSITION_DURATION);

      return () => {
        unmounted = true;
        clearInterval(timerId);
      };
    }, []);

    return (
      <Container>
        {HOME_IMAGE_LIST.map((image, index) => (
          <Img key={index} src={image} active={index === activeIndex} />
        ))}
      </Container>
    );
  },
  () => true
);

export default HomeTopImg;
