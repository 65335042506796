import { call } from "redux-saga/effects";
import { ILatLng } from "../model";

const reverseGeocode = (latlng: ILatLng) => {
  return new Promise((resolve, reject) => {
    try {
      if (typeof google === "undefined") {
        throw new Error("google is not found");
      }

      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject();
        }
        resolve(results[0]);
      });
    } catch {
      reject();
    }
  });
};

const EXCLUDE_GEOCODE_ADDRESS_TYPES = [
  "postal_code",
  "country",
  "administrative_area_level_1",
  "locality"
];

export default function* fetchAddressByLocation(latlng: ILatLng) {
  const geocoderResult: google.maps.GeocoderResult = yield call(() =>
    reverseGeocode(latlng)
  );
  console.log({ geocoderResult });
  const address = geocoderResult.address_components
    .slice()
    .reverse()
    .reduce((acc, crr) => {
      if (crr.types.some(i => EXCLUDE_GEOCODE_ADDRESS_TYPES.includes(i))) {
        return acc;
      }
      return acc + crr.short_name;
    }, "");
  if (address === "") {
    const spareAddress = geocoderResult.address_components.slice().shift();
    return typeof spareAddress === "undefined" ? "" : spareAddress.short_name;
  }

  return address;
}
