import { push } from "connected-react-router";
import { fork, join, put } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import {
  updateSearchSetting,
  UserTouchedCurrentLocationSearchButtonAction
} from "../actions";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import fetchCurrentLocationSaga from "./fetchCurrentLocationSaga";
import getSearchResultSaga from "./getSearchResultSaga";
import requestGeolocationPermissionSaga from "./requestGeolocationPermissionSaga";
import { FastestSearchSetting } from "../../../records/SearchSetting";

export default function* userTouchedCurrentLocationSearchButtonSaga(
  action: UserTouchedCurrentLocationSearchButtonAction
) {
  yield put(setProcessingFlag(EProcessingFlag.FETCHING_HOME_FASTEST_SEARCH));
  try {
    yield put(updateSearchSetting("fastest", FastestSearchSetting.asDefault()));
    yield fork(() => requestGeolocationPermissionSaga());
    const fetchCurrentLocationTask = yield fork(() =>
      fetchCurrentLocationSaga()
    );
    yield join(fetchCurrentLocationTask);

    const fetchingtask = yield fork(() => getSearchResultSaga("fastest"));
    yield join(fetchingtask);
    yield put(push("/search/fastest"));
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  } finally {
    yield put(
      removeProcessingFlag(EProcessingFlag.FETCHING_HOME_FASTEST_SEARCH)
    );
  }
}
