import { List, Record } from "immutable";
import BusinessTime from "./BusinessTime";

export interface IBusinessBusyTime {
  week: number;
  busy_times: List<BusinessTime>;
}

const initialValues: IBusinessBusyTime = {
  week: 0,
  busy_times: List<BusinessTime>()
};

export default class BusinessBusyTime extends Record(initialValues) {
  public getWeek(): number {
    return this.get("week");
  }

  public getBusyTimes(): List<BusinessTime> {
    return this.get("busy_times");
  }
}
