enum EDeviceType {
  IPHONE = "iphone",
  IPAD = "ipad",
  ANDROID = "android",
  UNKNOWN = "unknown"
}

export default class UserAgent {
  public static getValue() {
    return window.navigator.userAgent;
  }

  public static getType() {
    if (!window.hasOwnProperty("navigator")) {
      return EDeviceType.UNKNOWN;
    }
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.includes(EDeviceType.IPHONE)) {
      return EDeviceType.IPHONE;
    } else if (ua.includes(EDeviceType.IPAD)) {
      return EDeviceType.IPAD;
    } else if (ua.includes(EDeviceType.ANDROID)) {
      return EDeviceType.ANDROID;
    } else {
      return EDeviceType.UNKNOWN;
    }
  }

  /**
   * @deprecated
   * platformの判定はsrc/util/CordovaUtil.ts getPlatformIdを使用して下さい
   */
  public static isIOS() {
    const type = UserAgent.getType();
    return [EDeviceType.IPHONE, EDeviceType.IPAD].includes(type);
  }

  /**
   * @deprecated
   * platformの判定はsrc/util/CordovaUtil.ts getPlatformIdを使用して下さい
   */
  public static isAndroid() {
    return UserAgent.getType() === EDeviceType.ANDROID;
  }
}
