import { BackgroundColorProperty, ColorProperty } from "csstype";
import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div<{ size: number }>`
  display: inline-block;
  height: ${p => p.size}px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Child = styled.span<{
  index: number;
  size: number;
  color: BackgroundColorProperty;
}>`
  display: inline-block;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 100%;
  flex: 0 0 auto;
  background-color: ${p => p.color};
  animation: 0.5s ${animation} ease-out ${p => p.index * 0.1}s infinite
    alternate;
  & + & {
    margin-left: ${p => p.size}px;
  }
`;

interface IProps {
  invert?: boolean;
  size?: number;
  style?: React.CSSProperties;
  color?: ColorProperty;
  children?: never;
}

const Loading: React.FC<IProps> = React.memo(
  ({ style, invert, color, size = 8 }) => {
    const fillColor = useMemo(() => {
      if (typeof color !== "undefined") {
        return color;
      }
      if (typeof invert !== "undefined") {
        return "#ffffff";
      }
      return colorsConst.MAIN;
    }, [color, invert]);

    return (
      <Container size={size} style={style}>
        <Inner>
          {Array(3)
            .fill("")
            .map((_, index) => (
              <Child key={index} index={index} size={size} color={fillColor} />
            ))}
        </Inner>
      </Container>
    );
  }
);

export default Loading;
