import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetOrderHistoryRequest,
  IGetOrderHistoryResponse
} from "../../../apis/getOrderHistory";
import { AlertType } from "../../../records/Alert";
import { IOrderDataProps } from "../../../records/OrderData";
import { systemAddedAlert } from "../../app/actions";
import { removeProcessingFlag, setProcessingFlag } from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateOrderList } from "../actions";

export default function* getOrderHistorySaga() {
  try {
    yield put(setProcessingFlag(EProcessingFlag.FETCHING_ORDER_HISTORY));

    const params: IGetOrderHistoryRequest = {
      offset: 0
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_ORDER_HISTORY, params, { ignoreCache: true })
    );
    const {
      result,
      error
    }: { result: IGetOrderHistoryResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      const orderList: IOrderDataProps[] = result.order_list;
      yield put(updateOrderList(orderList));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文履歴の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.FETCHING_ORDER_HISTORY));
  }
}
