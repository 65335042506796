import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ChevronNarrowIcon from "../atoms/ChevronNarrowIcon";
import Grid from "../atoms/Grid";

interface IProps extends React.LiHTMLAttributes<HTMLLIElement> {
  link?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Container = styled.li`
  padding: 20px 8px 20px 16px;
  border-bottom: 1px solid #d7d7d7;
`;

const Inner = styled(Grid).attrs({ container: true, align: "center" })``;

const Content = styled(Grid).attrs({ item: true, rate: 1 })``;

const Icon = styled(Grid).attrs({ item: true, rate: 0 })``;

function ListItem(props: IProps) {
  const { link, children, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      {link ? (
        <Link to={link}>
          <Inner>
            <Content>{children}</Content>
            <Icon>
              <ChevronNarrowIcon width="8px" />
            </Icon>
          </Inner>
        </Link>
      ) : (
        <Inner>
          <Content>{children}</Content>
          <Icon>
            <ChevronNarrowIcon width="8px" />
          </Icon>
        </Inner>
      )}
    </Container>
  );
}

export default React.memo(ListItem);
