import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { userSubmittedSearchQuery } from "../../modules/search/actions";
import SearchQuery from "../../records/SearchQuery";
import { ReduxModel } from "../../reducer";
import { useSetTimeoutFlag } from "../../util/CustomHooks";
import SearchField from "./SearchField";
import Analytics from "../../util/Analytics";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";
import {
  userStartedInputtingText,
  userFinishedInputtingText
} from "../../modules/app/actions";

const BackIcon = ({ size = 20, color = "#9FA0A0" }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.878906 10.0208L10.0713 0.828364L12.1087 2.86575L4.95369 10.0208L12.1087 17.1757L10.0713 19.2131L0.878906 10.0208Z"
      fill={color}
    />
  </svg>
);

const BackIconContainer = styled.div`
  padding-right: 10px;
  flex: 0 0 auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 27px;
`;

interface IProps {
  locationChange?: boolean;
  disabled?: boolean;
  handleBack?: () => void;
}

const KeywordSearchField: React.FC<IProps> = React.memo(
  ({ locationChange = true, disabled, handleBack }) => {
    const [
      avoidDuplicateSubmitFlag,
      setAvoidDuplicateSubmitlag
    ] = useSetTimeoutFlag(false, 1000);

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

    const searchQuery = useSelector((state: ReduxModel) =>
      state.search.getCurrentSearchQuery()
    );

    const submitting = useSelector((state: ReduxModel) =>
      state.app.isConnectedApi(EApiKey.SEARCH_SHOP_WITH_KEYWORD)
    );

    const [currentSearchQuery, setCurrentSearchQuery] = useState<string>(() => {
      return searchQuery.getTextListAsString();
    });

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentSearchQuery(event.target.value);
      },
      []
    );

    const dispatch = useDispatch();

    const handleFocus = useCallback(() => {
      dispatch(userStartedInputtingText());
    }, [dispatch]);

    const handleBlur = useCallback(() => {
      dispatch(userFinishedInputtingText());
    }, [dispatch]);

    const handleSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        fixBodyScrollTopWhenInputBlurred();
        event.preventDefault();
        if (submitting || avoidDuplicateSubmitFlag) {
          return;
        }
        if (currentSearchQuery.trim() === "") {
          return;
        }
        if (inputRef !== null) {
          inputRef.blur();
        }
        Analytics.logEvent("home_search_input");
        setAvoidDuplicateSubmitlag(true);
        dispatch(
          userSubmittedSearchQuery(
            SearchQuery.fromString(currentSearchQuery),
            locationChange
          )
        );
      },
      [
        avoidDuplicateSubmitFlag,
        currentSearchQuery,
        dispatch,
        setAvoidDuplicateSubmitlag,
        submitting,
        locationChange,
        inputRef
      ]
    );

    useEffect(() => {
      setCurrentSearchQuery(searchQuery.getTextListAsString());
    }, [searchQuery]);

    return (
      <form action="/" onSubmit={handleSubmit}>
        <Container>
          {handleBack ? (
            <BackIconContainer onClick={handleBack}>
              <BackIcon />
            </BackIconContainer>
          ) : null}
          <SearchField
            defaultValue={currentSearchQuery}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={"店名・ジャンルで検索"}
            disabled={disabled}
            inputRef={setInputRef}
          />
        </Container>
      </form>
    );
  }
);

export default KeywordSearchField;
