import { ellipsis } from "polished";
import { push } from "connected-react-router";
import { List } from "immutable";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { userTouchedOpenDialogWithOrderNumber } from "../../modules/order/actions";
import OrderData from "../../records/OrderData";
import OrderItem from "../../records/OrderItem";
import Price from "../../records/Price";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import Utility from "../../util/Utility";
import ButtonAlter from "../atoms/ButtonAlter";
import CheckedIconInCircle from "../atoms/CheckedIconInCircle";
import ChevronIconInCircle from "../atoms/ChevronIconInCircle";
import CircleIcon from "../atoms/CircleIcon";
import Grid from "../atoms/Grid";
import PencilIcon from "../atoms/PencilIcon";
import Stars from "../atoms/Stars";
import Loading from "../molecules/Loading";
import TimeDisplay from "../molecules/TimeDisplay";

const Container = styled.div`
  margin: 0 0 8px;
  padding: 16px;
  background-color: #fff;
`;

const ShopNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${colorsConst.MAIN};
`;

const ShopName = styled.div`
  flex-grow: 1;
  font-weight: bold;
  min-width: 0;
  ${ellipsis()}
`;

const OrderItemContainer = styled.div`
  padding-top: 16px;
`;

const OrderItemTitle = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  color: #898989;
`;
const OrderItemRow = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
`;
const OrderItemCount = styled.div<{ isActive: boolean }>`
  flex-grow: 0;
  min-width: 25px;
  font-size: 14px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  color: ${p => (p.isActive ? colorsConst.MAIN : "#000")};
  border: 1px solid #000;
`;
const OrderItemDetail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 10px;
`;
const OrderItemName = styled.p`
  padding-top: 5px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.1rem;
`;
const OrderItemOptionList = styled.ul``;
const OrderItemOption = styled.li`
  padding-top: 5px;
  font-size: 14px;
  color: #898989;
`;
const OrderItemImg = styled.div<{ src: string }>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 75px;
  height: 75px;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const OrderIntoroductionTextContainer = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid #efefef;
`;
const OrderIntoroductionTextTitle = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  color: #898989;
`;
const OrderIntoroductionText = styled.div`
  padding-top: 5px;
`;

const OrderReceiveTimeContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #dcdcdc;
`;

const OrderReceiveTimeLabel = styled.div`
  flex-grow: 1;
  font-size: 16px;
  color: #898989;
`;

const TimeDisplayContainer = styled.div`
  flex-grow: 0;
  display: flex;
  align-items: flex-end;
`;

const TimeDisplayAim = styled.div<{ isDisplay: boolean }>`
  visibility: ${p => (p.isDisplay ? "visible" : "hidden")};
  padding-left: 5px;
  font-size: 14px;
  line-height: 20px;
`;

const StarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StarsEditText = styled.div`
  color: #272727;
  text-decoration-line: underline;
`;

const mapStateToProps = (state: ReduxModel) => ({
  userEmail: state.user.getData().getEmail(),
  userId: state.user.getData().getId(),
  submittingCancelOrder: state.app.isConnectedApi(EApiKey.CANCEL_ORDER)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedOpenFeedbackDialogButton(orderNumber: string) {
    this.dispatch(
      userTouchedOpenDialogWithOrderNumber(orderNumber, "SEND_FEEDBACK")
    );
  }

  public userTouchedOpenReceiptDialogButton = (orderNumber: string) => {
    this.dispatch(push(`/orderReceipt/${orderNumber}`));
  };

  public userTouchedOpenCancelDialogButton = (orderNumber: string) => {
    this.dispatch(
      userTouchedOpenDialogWithOrderNumber(orderNumber, "CANCEL_ORDER")
    );
  };

  public goToDetail(orderNumber: string) {
    this.dispatch(push(`/history/${orderNumber}`));
  }

  public goToShop(shopId: number) {
    this.dispatch(push(`/shop/${shopId}`));
  }
}

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  orderData: OrderData;
  children?: never;
}

const OrderHistoryItem: React.FC<IProps> = React.memo(
  ({ orderData, userId, userEmail, submittingCancelOrder, actions }) => {
    const shopInfo = orderData.getShopInfo();
    const orderDataItems = orderData.getItemList();

    const isActive = orderData.isActive();

    const handleClickOpenReceiptButton = useCallback(
      (receiptOrderNumber: string) => {
        if (orderData.isOngoing()) {
          Analytics.logEvent("hist_ongoing_receipt");
        }
        actions.userTouchedOpenReceiptDialogButton(receiptOrderNumber);
      },
      [orderData, actions]
    );

    const handleClickOpenFeedbackButton = useCallback(
      (receiptOrderNumber: string) => {
        Analytics.logEvent("hist_past_evaluate");
        actions.userTouchedOpenFeedbackDialogButton(receiptOrderNumber);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [actions.userTouchedOpenFeedbackDialogButton]
    );

    const handleClickOpenCancelDialog = useCallback(
      (orderNumber: string) => {
        Analytics.logEvent("hist_ongoing_cancel");
        actions.userTouchedOpenCancelDialogButton(orderNumber);
      },
      [actions]
    );

    const handleClickSendInquiry = useCallback(
      (orderShop: string, orderItems: List<OrderItem>, orderNumber: string) => {
        if (orderData.isOngoing()) {
          Analytics.logEvent("hist_ongoing_inquiry");
        }
        const { to, subject, body } = OrderData.getInquiryMailToOrderFormat(
          userEmail,
          userId,
          orderShop,
          orderItems,
          orderNumber
        );
        Utility.launchMailer(to, subject, body);
      },
      [orderData, userEmail, userId]
    );

    const handleClickDetailButton = useCallback(() => {
      if (orderData.isOngoing()) {
        Analytics.logEvent("hist_ongoing_detail");
      }
      actions.goToDetail(orderData.getOrderNumber());
    }, [orderData, actions]);

    const handleClickReOrderButton = useCallback(() => {
      Analytics.logEvent("hist_past_reorder");
      actions.goToShop(shopInfo.getId());
    }, [actions, shopInfo]);

    return (
      <Container>
        <Link to={`/shop/${shopInfo.getId()}`} style={{ display: "block" }}>
          <ShopNameContainer>
            <ShopName>{shopInfo.getShopName()}</ShopName>
            <ChevronIconInCircle />
          </ShopNameContainer>
        </Link>

        <Grid container align="center" style={{ marginBottom: "25px" }}>
          <Grid item grow={1}>
            <Grid container align="flex-start">
              <Grid
                item
                grow={0}
                style={{ marginRight: "5px", marginTop: "-1px" }}
              >
                {orderData.isCanceled() ? (
                  <CircleIcon />
                ) : (
                  <CheckedIconInCircle color="#80B927" />
                )}
              </Grid>
              <Grid item grow={1}>
                {orderData.isOngoing() ? (
                  <div>
                    <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                      {orderData.getPresentationOrderStateText()}
                    </div>
                    <div>注文番号　{orderData.getOrderNumber()}</div>
                  </div>
                ) : (
                  <div>
                    {orderData.isCanceled() && (
                      <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        {orderData.getPresentationOrderStateText()}
                      </div>
                    )}
                    <div>注文番号　{orderData.getOrderNumber()}</div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item grow={0}>
            {orderData.isOngoing() ? (
              <ButtonAlter
                block
                appearance="secondary"
                onClick={handleClickDetailButton}
                style={{ paddingTop: "9px", paddingBottom: "9px" }}
              >
                状況を確認
              </ButtonAlter>
            ) : (
              <>
                {orderData.canSendFeedback() &&
                  (orderData.getFeedbackPoint() === 0 ? (
                    <ButtonAlter
                      appearance="secondary"
                      onClick={() =>
                        handleClickOpenFeedbackButton(
                          orderData.getOrderNumber()
                        )
                      }
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                    >
                      <Grid container align="center">
                        <Grid item>口コミを書く</Grid>
                        <Grid
                          item
                          style={{ marginLeft: "5px", marginTop: "-3px" }}
                        >
                          <PencilIcon />
                        </Grid>
                      </Grid>
                    </ButtonAlter>
                  ) : (
                    <StarsContainer
                      onClick={() =>
                        handleClickOpenFeedbackButton(
                          orderData.getOrderNumber()
                        )
                      }
                    >
                      <Stars rate={orderData.getFeedbackPoint()} />
                      <StarsEditText>編集</StarsEditText>
                    </StarsContainer>
                  ))}
              </>
            )}
          </Grid>
        </Grid>

        <div style={{ marginBottom: "20px" }}>
          <OrderReceiveTimeContainer>
            <OrderReceiveTimeLabel>受取日時</OrderReceiveTimeLabel>
            <TimeDisplayContainer>
              <TimeDisplay
                date={orderData.getReceiveDatetimeAsDate()}
                isActive={isActive}
              />
              <TimeDisplayAim isDisplay={orderData.canDisplayRecieveTimeAim()}>
                目安
              </TimeDisplayAim>
            </TimeDisplayContainer>
          </OrderReceiveTimeContainer>
        </div>

        <OrderItemContainer>
          <OrderItemTitle>ご注文内容</OrderItemTitle>
          <ul>
            {orderDataItems.map((item: OrderItem, index: number) => {
              return (
                <OrderItemRow key={index}>
                  <OrderItemCount isActive={isActive}>
                    {item.getOrderCount()}
                  </OrderItemCount>
                  <OrderItemDetail>
                    <OrderItemName>{item.getItemName()}</OrderItemName>
                    <OrderItemOptionList>
                      {item.getOptionList().map((option, optionIndex) => {
                        return (
                          <OrderItemOption key={optionIndex}>
                            {option.getOptionName()}
                            {option.getPresentationPrice()}
                          </OrderItemOption>
                        );
                      })}
                    </OrderItemOptionList>
                  </OrderItemDetail>
                  <OrderItemImg src={item.getImagePath()} />
                </OrderItemRow>
              );
            })}
          </ul>
        </OrderItemContainer>

        <OrderIntoroductionTextContainer>
          <OrderIntoroductionTextTitle>
            お店への連絡事項
          </OrderIntoroductionTextTitle>
          {orderDataItems.map((item, index) => (
            <OrderIntoroductionText key={index}>
              {item.getInstructionText()}
            </OrderIntoroductionText>
          ))}
        </OrderIntoroductionTextContainer>

        <Grid
          container
          style={{ padding: "15px 0", borderBottom: "1px solid #efefef" }}
        >
          <Grid item style={{ color: "#898989" }} grow={1}>
            支払い方法
          </Grid>
          <Grid item grow={0}>
            {orderData.getPresentationPaymentTypeText()}
          </Grid>
        </Grid>

        <Grid container align="center" style={{ padding: "15px 0" }}>
          <Grid item grow={0} style={{ width: "50%", textAlign: "center" }}>
            <span style={{ color: "#898989" }}>合計: </span>
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>
              {Price.getPresentationValue(orderData.getUserPaymentPrice())}
            </span>
          </Grid>
          <Grid item grow={0} style={{ width: "50%", marginLeft: "15px" }}>
            {orderData.isOngoing() ? (
              <>
                {
                  // 現在進行中の注文の場合
                  // キャンセルできる場合…キャンセル
                  // キャンセルできない場合…サポートに連絡
                }
                {orderData.canCancelOrder() ? (
                  <ButtonAlter
                    invert
                    block
                    appearance={"main"}
                    onClick={() =>
                      handleClickOpenCancelDialog(orderData.getOrderNumber())
                    }
                    style={{ paddingTop: "9px", paddingBottom: "9px" }}
                  >
                    {submittingCancelOrder ? <Loading /> : "注文をキャンセル"}
                  </ButtonAlter>
                ) : (
                  <ButtonAlter
                    block
                    appearance={"sub"}
                    onClick={() =>
                      handleClickSendInquiry(
                        orderData.getShopInfo().getShopName(),
                        orderDataItems,
                        orderData.getOrderNumber()
                      )
                    }
                    style={{ padding: "9px 0" }}
                  >
                    サポートに連絡
                  </ButtonAlter>
                )}
              </>
            ) : (
              <>
                {
                  // 現在進行中でない場合
                  // キャンセルされた場合…サポートに連絡
                  // キャンセルされていない場合…再注文
                }
                {orderData.isCanceled() ? (
                  <ButtonAlter
                    block
                    appearance={"sub"}
                    onClick={() =>
                      handleClickSendInquiry(
                        orderData.getShopInfo().getShopName(),
                        orderDataItems,
                        orderData.getOrderNumber()
                      )
                    }
                    style={{ padding: "9px 0" }}
                  >
                    サポートに連絡
                  </ButtonAlter>
                ) : (
                  <ButtonAlter
                    block
                    appearance={"secondary"}
                    onClick={handleClickReOrderButton}
                    style={{ padding: "9px 0" }}
                  >
                    再注文
                  </ButtonAlter>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {
          // 現在進行中でない場合かつキャンセルされていない場合
          // 領収書・サポートに連絡
        }
        {!orderData.isOngoing() && !orderData.isCanceled() && (
          <Grid container align="center" style={{ padding: "15px 0" }}>
            <Grid item grow={0} style={{ width: "50%", textAlign: "center" }}>
              {orderData.canDisplayReceipt() && (
                <ButtonAlter
                  block
                  appearance={"sub"}
                  onClick={() =>
                    handleClickOpenReceiptButton(orderData.getOrderNumber())
                  }
                  style={{ padding: "9px 0" }}
                >
                  領収書を表示
                </ButtonAlter>
              )}
            </Grid>
            <Grid grow={0} style={{ width: "50%", marginLeft: "15px" }}>
              <ButtonAlter
                block
                appearance={"sub"}
                onClick={() =>
                  handleClickSendInquiry(
                    orderData.getShopInfo().getShopName(),
                    orderDataItems,
                    orderData.getOrderNumber()
                  )
                }
                style={{ padding: "9px 0" }}
              >
                サポートに連絡
              </ButtonAlter>
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryItem);
