import * as React from "react";
import styled from "styled-components";
import ShopForSearch from "../../../records/ShopForSearch";
import Thumbnail from "../../molecules/Thumbnail";

const Container = styled.div`
  position: relative;
`;

const OverlayText = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 4px 21px 9px;
  background-color: rgba(0, 0, 0, 0.55);
`;

const OverlayTextMain = styled.div`
  font-size: 20px;
  line-height: ${29 / 20};
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OverlayTextSub = styled.div`
  font-size: 14px;
  line-height: ${20 / 14};
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const thumbnailStyle: React.CSSProperties = {
  paddingTop: `${Math.floor((154 / 375) * 100)}%`
};

interface IProps {
  shop: ShopForSearch;
  children?: never;
}

const ShopImage: React.FC<IProps> = React.memo(props => {
  const { shop } = props;

  const imagePath = shop.getHeaderImagePath();
  const notBusinessReason = shop
    .getShopListBusinessData()
    .getNotBusinessReason();

  return (
    <Container>
      <Thumbnail src={imagePath} containerStyle={thumbnailStyle} />
      {shop.shouldDisplayNotBusinessReason() ? (
        <OverlayText>
          <OverlayTextMain>{notBusinessReason.getMainText()}</OverlayTextMain>
          <OverlayTextSub>{notBusinessReason.getSubText()}</OverlayTextSub>
        </OverlayText>
      ) : null}
    </Container>
  );
});

export default ShopImage;
