import React from "react";
import styled from "styled-components";
import { SearchResultType } from "../../../records/SearchResult";
import FastestIcon from "../../atoms/FastestIcon";
import ReserveIcon from "../../atoms/ReserveIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 80px;
  height: 38px;
`;

const IconContainer = styled.div<{ type: SearchResultType }>`
  flex: 0 0 auto;
  margin: ${p => (p.type === "fastest" ? "0 -5px;" : "0 -5px 0 0")};
`;

const LabelText = styled.div<{ type: SearchResultType }>`
  flex: 1 1 auto;
  font-size: ${p => (p.type === "fastest" ? 17 : 19)}px;
  font-weight: 900;
  text-align: center;
  color: #80b927;
  white-space: nowrap;
`;

interface IProps {
  searchResultType: Exclude<SearchResultType, "keyword">;
}

const ResultTypeSymbol: React.FC<IProps> = React.memo(
  ({ searchResultType }) => {
    return (
      <Container>
        <IconContainer type={searchResultType}>
          {searchResultType === "fastest" ? (
            <FastestIcon size={30} color={"#80B927"} />
          ) : (
            <ReserveIcon size={30 * 0.875} color={"#80B927"} />
          )}
        </IconContainer>
        <LabelText type={searchResultType}>
          {searchResultType === "fastest" ? (
            <>
              <div>{"今すぐ"}</div>
              <div>{"受取"}</div>
            </>
          ) : (
            "予約"
          )}
        </LabelText>
      </Container>
    );
  }
);

export default ResultTypeSymbol;
