import * as React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      プライバシーポリシー（個人情報取扱方針）
      <br />
      <br />
      <br />
      １．個人情報の利用目的
      <br />
      menu株式会社（以下、「当社」といいます）は、下記の目的において、個人情報を取得し、利用します。
      <br />
      （１） 当社の運営する各種サービスの提供と、それらサービスの案内のため
      <br />
      （２）
      当社の運営する各種サービスの利用料金その他の支払いにつき、請求又は支払いを行うため
      <br />
      （３） 当社の運営する各種サービスの改善、及び新サービスの開発のため
      <br />
      （４）
      当社の運営する各種サービスの利用規約に違反する態様での利用を防止するため
      <br />
      <br />
      ２．個人情報利用の制限
      <br />
      当社は、あらかじめ本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱うことはありません。ただし、次の場合はこの限りではありません。
      <br />
      （１） 法令に基づく場合
      <br />
      （２）
      人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      （３）
      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      （４）
      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
      <br />
      <br />
      ３．個人情報の適正な取得
      <br />
      当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。
      <br />
      <br />
      ４．個人情報の取得に際する利用目的の通知等
      <br />
      当社は、個人情報を取得するにあたり、あらかじめその利用目的を通知又は公表します。ただし、次の場合はこの限りではありません。
      <br />
      （１）
      利用目的を本人に通知し、又は公表することにより本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
      <br />
      （２）
      利用目的を本人に通知し、又は公表することにより当社の権利又は正当な利益を害するおそれがある場合
      <br />
      （３）
      国の機関又は地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的を本人に通知し、又は公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき
      <br />
      （４） 取得の状況からみて利用目的が明らかであると認められる場合
      <br />
      <br />
      ５．個人情報利用目的の変更
      <br />
      当社は、個人情報の利用目的を変更する場合には、変更前の利用目的と関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、本人に通知し、又は公表します。
      <br />
      <br />
      ６. データ内容の正確性の確保等及び安全管理措置
      <br />
      当社は、利用目的の達成に必要な範囲内において、個人データを、正確かつ最新の内容に保つとともに、利用する必要がなくなったときは、当該個人データを遅滞なく消去するように努めます。また、取り扱う個人データの漏えい、滅失又はき損の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。
      <br />
      <br />
      ７．委託先の監督
      <br />
      当社は、個人データの取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約を締結し、又は、当社が定める規約に合意を求め、さらに、委託先において個人データの安全管理が図られるよう、委託先に対する必要かつ適切な監督を行います。
      <br />
      <br />
      ８．第三者提供の制限
      <br />
      当社は、次に掲げる場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。
      <br />
      （１） 法令に基づく場合
      <br />
      （２）
      人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      （３）
      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      （４）
      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
      <br />
      ただし次に掲げる場合は上記に定める第三者には該当しません。
      <br />
      （１）
      当社が利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することに伴って当該個人データが提供される場合
      <br />
      （２） 合併その他の事由による事業の承継に伴って個人データが提供される場合
      <br />
      （３）
      個人データを特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的および当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いているとき
      <br />
      当社は、個人データを特定の者との間で共同して利用する場合、利用目的又は個人データの管理について責任を有する者の氏名若しくは名称を変更する場合は、変更する内容について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置きます。
      <br />
      <br />
      ９．保有個人データの開示
      <br />
      当社は、本人から、当該本人が識別される保有個人データの開示を求められたときは、本人に対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
      <br />
      （１）
      本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
      <br />
      （２） 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
      <br />
      （３） 他の法令に違反することとなる場合
      <br />
      なお、アクセスログなどの個人データ以外の情報又はデータは、原則として開示いたしません。
      <br />
      <br />
      １０．保有個人データの訂正
      <br />
      当社は、本人から、当該本人が識別される保有個人データの内容が事実でないという理由によって、内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、その内容の訂正等に関して法令の規定により特別の手続が定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、当該保有個人データの内容の訂正等を行い、その旨を本人に通知します。
      <br />
      <br />
      １１．保有個人データの利用停止
      <br />
      当社は、本人から、当該本人が識別される保有個人データが、個人情報の保護に関する法律第16条の規定に違反して取り扱われているという理由、又は個人情報保護法第17条の規定に違反して取得されたものであるという理由により、当該保有個人データの利用の停止又は消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、違反を是正するために必要な限度で、遅滞なく当該保有個人データの利用停止等を行い、その旨を本人に通知します。ただし、当該保有個人データの利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとる場合は、当該代替措置を講じます。
      <br />
      <br />
      １２．データ等の利用について
      <br />
      当社は、当社の提供する各種サービスの運営にあたり、以下のデータ等を利用します。当社によるこれらデータ等の利用については、これらデータ等が本プライバシーポリシー第一条に定義した個人情報に該当しない限りで、かつ、個人情報保護法に反しない限度において、制限を受けないものとします。
      <br />
      （１）
      個人を識別できないように個人情報を加工し、作成した匿名加工情報その他統計情報等
      <br />
      （２）
      当社及び当社の提携する各事業者のWebサイト閲覧者、アプリケーション閲覧者にまつわる、閲覧広告・ページ、性別、職業、年齢、ご覧になったページ・広告、ご利用時間帯、ご利用の方法、ご利用環境等などの情報
      <br />
      （３） IPアドレス、クッキー情報、位置情報、端末の個体識別情報
      <br />
      <br />
      個人情報に関する問い合わせ窓口
      <br />
      <br />
      事業者名
      <br />
      menu株式会社
      <br />
      <br />
      保有個人データの利用目的
      <br />
      プライバシーポリシー記載の目的
      <br />
      <br />
      開示等の求めの手続き
      <br />
      1.お問い合わせ窓口より開示を希望する旨と、①開示を希望する情報②その目的、③使用されているサービスで登録している氏名及びメールアドレスをご送付ください。
      <br />
      2.お問い合わせ窓口よりいただいた情報を確認した上でご本人確認を行わせていただきます。運転免許証、住民票等本人確認書類の提示が必要となります。本人確認書類の取得に要した費用等は当社では負担いたしませんのでご了承ください。
      <br />
      <br />
      問い合わせ窓口
      <br />
      info@menu.inc
      <br />
      受付時間：月～金　11:00 ～18:00 （土日・祝日 12/29～1/3は休）
      <br />
      <br />
      <br />
      以上
      <br />
    </>
  );
};
export default PrivacyPolicy;
