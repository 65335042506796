import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  invert?: boolean;
}

const SvgMenuLogo = (props: IProps) => {
  const { invert, ...rest } = props;
  const fill = invert ? "#fff" : "#000";
  return (
    <svg viewBox="0 0 692 158" fill="none" {...rest}>
      <path
        d="M123.176.062c-13.95 0-26.63 5.609-35.89 14.657C78.007 5.65 65.348.062 51.377.062 23.046.062 0 23.092 0 51.404v103.321h30.953V51.405c0-11.26 9.157-20.41 20.423-20.41 11.267 0 20.424 9.15 20.424 20.41v103.32h30.953V51.405c0-11.26 9.157-20.41 20.423-20.41 11.267 0 20.424 9.15 20.424 20.41v103.32h30.953V51.405c0-28.313-23.046-51.343-51.377-51.343zM280.501 158c-21.12 0-40.97-8.209-55.903-23.133-30.83-30.81-30.83-80.924 0-111.714C239.531 8.21 259.381 0 280.501 0c21.12 0 40.97 8.21 55.904 23.133l10.939 10.932-88.106 88.048c6.534 3.235 13.766 4.934 21.263 4.934 12.844 0 24.93-4.995 34.005-14.085 4.568-4.565 8.112-9.867 10.509-15.763l28.659 11.71c-3.975 9.724-9.792 18.445-17.29 25.938C321.471 149.77 301.621 158 280.501 158zm0-127.067c-12.844 0-24.93 4.995-34.005 14.084-14.913 14.903-17.965 37.279-9.136 55.212l64.405-64.363c-6.535-3.234-13.766-4.933-21.264-4.933zM449.236.062c-39.577 0-71.779 32.18-71.779 71.732v82.951h30.952V71.774c0-22.499 18.314-40.78 40.807-40.78 22.492 0 40.806 18.302 40.806 40.78v82.951h30.953V71.774c.04-39.552-32.162-71.712-71.739-71.712zM660.047 3.316v82.951c0 22.499-18.314 40.78-40.806 40.78-22.493 0-40.806-18.302-40.806-40.78V3.317h-30.953v82.95c0 39.552 32.202 71.733 71.779 71.733s71.78-32.181 71.78-71.733V3.317h-30.994z"
        fill={fill}
      />
    </svg>
  );
};

const MenuLogo = React.memo((props: IProps) => <SvgMenuLogo {...props} />);

export default MenuLogo;
