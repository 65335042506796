import React from "react";
import SearchTemplateBase from "../organisms/SearchTemplateBase";

interface IProps {}

const ReserveSearchTemplate: React.FC<IProps> = () => {
  return <SearchTemplateBase searchResultType={"reserve"} />;
};

export default ReserveSearchTemplate;
