import { FormErrors } from "redux-form";

export interface IRegisterPaymentForm {
  credit: string;
  periodMonth: string;
  periodYear: string;
  secureCode: string;
}

export const initialRegisterPaymentForm: IRegisterPaymentForm = {
  credit: "",
  periodMonth: "",
  periodYear: "",
  secureCode: ""
};

export function validateRegisterPaymentForm(values: IRegisterPaymentForm) {
  const errors: FormErrors<IRegisterPaymentForm> = {};
  if (!values.credit) {
    errors.credit = "カード番号を入力して下さい";
  } else if (
    !values.credit.match(/^[0-9\-]+$/) ||
    values.credit.length < 14 ||
    values.credit.length > 16
  ) {
    errors.credit = "カード番号の形式が正しくありません";
  }
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (!values.periodYear) {
    errors.periodYear = "期限年を入力して下さい";
  } else if (!values.periodYear.match(/^[0-9]+$/)) {
    errors.periodYear = "期限年の形式が正しくありません";
  } else if (values.periodYear.length !== 2) {
    errors.periodYear = "期限年は西暦の下２桁で入力して下さい";
  } else if (parseInt(`20${values.periodYear}`, 10) < currentYear) {
    errors.periodYear = "期限年は未来である必要があります";
  }

  if (!values.periodMonth) {
    errors.periodMonth = "期限月を入力して下さい";
  } else if (!values.periodMonth.match(/^[0-9]+$/)) {
    errors.periodMonth = "期限月の形式が正しくありません";
  } else if (
    values.periodYear &&
    values.periodYear.length === 2 &&
    values.periodMonth &&
    parseInt(
      `20${values.periodYear}${
        values.periodMonth.length < 2
          ? values.periodMonth.padStart(2, "0")
          : values.periodMonth
      }`,
      10
    ) < parseInt(`${currentYear}${String(currentMonth).padStart(2, "0")}`, 10)
  ) {
    errors.periodMonth = "期限月は未来である必要があります";
  }

  if (!values.secureCode) {
    errors.secureCode = "セキュリティコードを入力して下さい";
  } else if (!values.secureCode.match(/^[0-9]{3,4}/)) {
    errors.secureCode = "セキュリティコードの形式が正しくありません";
  }

  return errors;
}
