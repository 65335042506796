import { ActionTypes, AppActions } from "./actions";
import AppModel from "./model";

export default function reducer(
  model = new AppModel(),
  action: AppActions
): AppModel {
  switch (action.type) {
    case ActionTypes.R_UPDATE_PLAYING_PAGE_TRANSITION_ANIMATION_FLAG:
      return action.payload.started
        ? model.startPlayingPageTransitionAnimation()
        : model.finishPlayingPageTransitionAnimation();
    case ActionTypes.R_UPDATE_CONNECTING_API_SET:
      return action.payload.add
        ? model.startConnect(action.payload.apiKey)
        : model.finishConnect(action.payload.apiKey);
    case ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG:
      return model.setShouldUpdateResource();
    case ActionTypes.R_LAUNCH:
      return model.launch();
    case ActionTypes.R_READY_FOR_USER_OPERATION:
      return model.completeToReadyForUserOperation();
    case ActionTypes.R_UPDATE_USER_INPUTTING_TEXT_FLAG:
      return model.updateUserInputtingTextFlag(action.payload.started);
    case ActionTypes.R_SET_MAINTENANCE_INFO:
      return model.setMaintenanceInfo(action.payload.info);
    case ActionTypes.R_SET_APP_VERSION:
      return model.setAppVersion(action.payload.version);
    case ActionTypes.R_OPEN_MODAL:
      return model.addModal(action.payload.key, action.payload.payload);
    case ActionTypes.R_CLOSE_MODAL:
      return model.removeModal(action.payload.key);
    case ActionTypes.R_REMOVED_ALERT:
      return model.removeAlert(action.payload.id);
    case ActionTypes.R_SET_PROCESSING_FLAG:
      return model.setProcessingFlag(action.payload.key);
    case ActionTypes.R_REMOVE_PROCESSING_FLAG:
      return model.removeProcessingFlag(action.payload.key);
    case ActionTypes.R_SET_UPDATE_BUILD_PAYLOAD:
      return model.setUpdateBuildPayload(action.payload.payload);
    case ActionTypes.R_SET_UPDATE_RESOURCE_PAYLOAD:
      return model.setUpdateResourcePayload(action.payload.payload);
    case ActionTypes.R_UPDATE_FLAG:
      return action.payload.bool
        ? model.setFlag(action.payload.key)
        : model.unsetFlag(action.payload.key);
    default:
      return model;
  }
}
