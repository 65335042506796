import moment from "moment";
import { EApiKey } from "../apis";

type CacheKey = string;

export default class APICache {
  public static shouldUseCache(method: EApiKey, params: {}) {
    if (!APICache.cacheEnabled(method)) {
      return false;
    }
    if (typeof APICache.getCache(method, params) === "undefined") {
      return false;
    }
    if (APICache.expired(method, params)) {
      return true;
    } else {
      return false;
    }
  }

  public static expired(method: EApiKey, params: {}) {
    const cacheKey = APICache.generateCacheKey(method, params);
    const ttl = APICache.ttlMap.get(cacheKey);
    if (typeof ttl === "undefined") {
      return true;
    }
    return moment().isBefore(ttl);
  }

  public static setCache(method: EApiKey, params: {}, responseBody: {}) {
    if (!APICache.cacheEnabled(method)) {
      return;
    }
    const cacheKey = APICache.generateCacheKey(method, params);
    // MEMO: inject field to judge data whether from cache
    (responseBody as any).fromCache = true;
    APICache.cacheMap.set(cacheKey, responseBody);
    APICache.ttlMap.set(cacheKey, APICache.getTTL(method));
  }

  public static getCache(method: EApiKey, params: {}): {} | undefined {
    const cacheKey = APICache.generateCacheKey(method, params);
    return APICache.cacheMap.get(cacheKey);
  }

  private static cacheMap: Map<CacheKey, {}> = new Map();
  private static ttlMap: Map<CacheKey, Date> = new Map();

  private static generateCacheKey(method: EApiKey, params: {}) {
    return `${method}-${JSON.stringify(params)}`;
  }

  private static getTTL(method: EApiKey) {
    switch (method) {
      case EApiKey.GET_HOME:
      case EApiKey.GET_CATEGORY_TAG_LIST:
      case EApiKey.GET_ORDER_HISTORY:
        return moment()
          .add(5, "minute")
          .toDate();
      default:
        return new Date(0);
    }
  }

  private static cacheEnabled(method: EApiKey) {
    switch (method) {
      case EApiKey.GET_HOME:
      case EApiKey.GET_CATEGORY_TAG_LIST:
      case EApiKey.GET_ORDER_HISTORY:
        return true;
      default:
        return false;
    }
  }
}
