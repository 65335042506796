import { ellipsis } from "polished";
import React from "react";
import styled from "styled-components";
import OrderItem from "../../records/OrderItem";
import ShopItem from "../../records/ShopItem";
import Grid from "../atoms/Grid";

const Container = styled(Grid).attrs({
  container: true,
  align: "flex-start",
  justify: "flex-start"
})``;

const MenuName = styled.div`
  font-size: 1.6rem;
  flex: 1 1 auto;
  ${ellipsis()}
`;

const MenuPrice = styled.div`
  font-size: 1.6rem;
  flex: 0 0 auto;
  padding-left: 10px;
`;

interface IProps {
  item: ShopItem | OrderItem;
}

const MenuMinimalInfo = React.memo((props: IProps) => {
  const { item } = props;
  return (
    <Container>
      <MenuName>
        {item instanceof ShopItem ? item.getName() : item.getItemName()}
      </MenuName>
      <MenuPrice>{item.getPresentationPrice()}</MenuPrice>
    </Container>
  );
});

export default MenuMinimalInfo;
