import React from "react";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import MenuIcon from "../atoms/MenuIcon";

interface IContainerProps {
  src?: string;
}

const Container = styled("div")<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100%;
  background-color: #e5e5e5;
  background-size: cover;
  background-position: center;
  ${p => (p.src ? `background-image: url(${p.src});` : "")};
`;

const MenuIconContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  src: string;
  containerStyle?: React.CSSProperties;
}

const Thumbnail: React.FC<IProps> = React.memo(props => {
  const { src, containerStyle, ...rest } = props;

  const [loadedFlag, setLoadedFlag] = useState(false);

  useLayoutEffect(() => {
    let unmounted = false;
    function handleLoadImage() {
      if (unmounted) {
        return;
      }
      setLoadedFlag(true);
    }

    function handleError(e: any) {
      console.log("handleError", { e });
    }

    let imageElem: HTMLImageElement | null = null;
    if (src !== "") {
      setLoadedFlag(false);
      imageElem = new Image();
      imageElem.addEventListener("load", handleLoadImage);
      imageElem.addEventListener("error", handleError);
      imageElem.src = src;
    }

    return () => {
      unmounted = true;
      if (imageElem !== null) {
        imageElem.removeEventListener("load", handleLoadImage);
        imageElem.removeEventListener("error", handleError);
      }
    };
  }, [src]);

  return loadedFlag ? (
    <Container src={src} style={containerStyle} {...rest} />
  ) : (
    <Container style={containerStyle} {...rest}>
      <MenuIconContainer>
        <MenuIcon width="100%" height="100%" />
      </MenuIconContainer>
    </Container>
  );
});

export default Thumbnail;
