import React from "react";
import HomeList, { displayableHomeList } from "../../records/HomeList";
import ShopListItemSlider from "./ShopListItemSlider";

interface IProps {
  homeList: HomeList;
}

const HomeShopList: React.FC<IProps> = React.memo(({ homeList }) => {
  return (
    <ul>
      {displayableHomeList.map(type => {
        const list = homeList.getList(type);
        const title = HomeList.getTitle(type);
        return !list.isEmpty() ? (
          <li key={type}>
            <ShopListItemSlider
              key={type}
              type={type}
              title={title}
              shopList={list}
            />
          </li>
        ) : null;
      })}
    </ul>
  );
});

export default HomeShopList;
