import { put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import {
  updateSearchSetting,
  UserTouchedToggleResultViewTypeButtonAction
} from "../actions";

export default function* userTouchedToggleResultViewTypeButtonSaga(
  action: UserTouchedToggleResultViewTypeButtonAction
) {
  const { resultType } = action.payload;

  const searchSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  yield put(updateSearchSetting(resultType, searchSetting.toggleViewType()));
}
