import React, { useCallback } from "react";
import styled from "styled-components";
import colorsConst from "../../../styles/const/colorsConst";
import { fixBodyScrollTopWhenInputBlurred } from "../../../util/Utility";

const Container = styled.div`
  padding: 10px 0;
  background-color: ${colorsConst.BACKGROUND};
`;

const Input = styled.input.attrs({ type: "tel", maxLength: 4 })`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  caret-color: ${colorsConst.MAIN};
  background-color: transparent;
  letter-spacing: 0.25em;
  border: 1px solid ${colorsConst.BORDER.LIGHT};
`;

interface IProps {
  handleChangeAuthCode: (code: string) => void;
}

const AuthCodeFieldAlter: React.FC<IProps> = React.memo(props => {
  const { handleChangeAuthCode } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      handleChangeAuthCode(value);
    },
    [handleChangeAuthCode]
  );

  return (
    <Container>
      <Input
        onChange={handleChange}
        onBlur={fixBodyScrollTopWhenInputBlurred}
        autoComplete="one-time-code"
        maxLength={4}
      />
    </Container>
  );
});

export default AuthCodeFieldAlter;
