import { List, Record } from "immutable";
import FeatureItem, { IFeatureItem } from "../../records/FeatureItem";
import NewsItem, { INewsItem } from "../../records/NewsItem";
import NotificationItem, {
  INotificationItem
} from "../../records/NotificationItem";

export interface NotificationState {
  notificationList: List<NotificationItem>;
  newsList: List<NewsItem>;
  fetchingNotificationsFlag: boolean;
  features: List<FeatureItem>;
  featureDetail: string | null;
}

const initialState: NotificationState = {
  notificationList: List<NotificationItem>(),
  newsList: List<NewsItem>(),
  fetchingNotificationsFlag: false,
  features: List<FeatureItem>(),
  featureDetail: null
};

export default class NotificationModel extends Record(initialState) {
  public startFetchingNotifications() {
    return this.set("fetchingNotificationsFlag", true);
  }

  public finishFetchingNotifications() {
    return this.set("fetchingNotificationsFlag", false);
  }

  public isFetchingNotifications() {
    return this.get("fetchingNotificationsFlag");
  }

  public updateNotificationList(list: INotificationItem[] | null) {
    return this.set(
      "notificationList",
      List(list !== null ? list.map(item => new NotificationItem(item)) : [])
    );
  }

  public updateNewsList(list: INewsItem[] | null) {
    return this.set(
      "newsList",
      List(list !== null ? list.map(item => new NewsItem(item)) : [])
    );
  }

  public existsNotificationOrNews() {
    return this.existsNotification() || this.existsNews();
  }

  public existsNotification() {
    return !this.getNotificationList().isEmpty();
  }

  public existsNews() {
    return !this.getNewsList().isEmpty();
  }

  public getNotificationList(): List<NotificationItem> {
    return this.get("notificationList");
  }

  public getNewsList(): List<NewsItem> {
    return this.get("newsList");
  }

  public updateFeatureList(list: IFeatureItem[] | null) {
    return this.set(
      "features",
      List(list !== null ? list.map(item => new FeatureItem(item)) : [])
    );
  }

  public getFeatureList(): List<FeatureItem> {
    return FeatureItem.filteredList(this.get("features")).sort(
      FeatureItem.sortByWeight
    );
  }

  public updateFeatureDetail(source: string) {
    return this.set("featureDetail", source);
  }

  public getFeatureDetail(): string | null {
    return this.get("featureDetail");
  }

  public initFeatureDetail() {
    return this.set("featureDetail", null);
  }
}
