import { cancel, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetCreditCardListRequest,
  IGetCreditCardListResponse
} from "../../../apis/getCreditCardList";
import { AlertType } from "../../../records/Alert";
import { ICreditCardItem } from "../../../records/CreditCardItem";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCreditCard } from "../actions";

export default function* fetchCreditCardSaga() {
  try {
    const params: IGetCreditCardListRequest = {};
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.GET_CREDIT_CARD_LIST,
      params
    );
    const {
      result,
      error
    }: { result: IGetCreditCardListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      const creditCardList = result.credit_card_list as ICreditCardItem[];
      const selectCardIndex = result.select_card_index;
      yield put(updateCreditCard(creditCardList, selectCardIndex));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クレジットカード情報の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
