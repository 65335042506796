import React, { useCallback } from "react";
import styled from "styled-components";
import CategoryTag from "../../../records/CategoryTag";
import colorsConst from "../../../styles/const/colorsConst";
import CheckBox from "../../atoms/CheckBox";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background-color: #ffffff;
`;

const TagName = styled.div`
  padding-right: 4px;
  font-size: 14px;
  line-height: ${20 / 14};
  color: #000000;
`;

interface IProps {
  active: boolean;
  tag: CategoryTag;
  handleClick: (tag: CategoryTag) => void;
}

const FilterCategoryTagItem: React.FC<IProps> = React.memo(
  ({ tag, active, handleClick }) => {
    const handleClickWrap = useCallback(() => {
      handleClick(tag);
    }, [handleClick, tag]);

    return (
      <Container onClick={handleClickWrap}>
        <TagName>{tag.getTagName()}</TagName>
        <CheckBox checked={active} fillColor={colorsConst.SECONDARY} />
      </Container>
    );
  }
);

export default FilterCategoryTagItem;
