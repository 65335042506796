import React from "react";
import styled from "styled-components";
import Price from "../../records/Price";
import ShopOption from "../../records/ShopOption";
import Grid from "../atoms/Grid";

const Container = styled.div``;

const OptionContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})``;

const MenuName = styled.div`
  flex: 1 1 auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MenuPrice = styled.div`
  flex: 0 0 auto;
  padding-left: 10px;
  min-width: 100px;
  font-size: 12px;
  line-height: 17px;
  color: #5b5b5b;
`;

interface IProps {
  option: ShopOption;
}

const OptionMinimalInfo = React.memo((props: IProps) => {
  const { option } = props;
  return (
    <Container>
      <OptionContainer>
        <MenuName>{option.getOptionName()}</MenuName>
        <MenuPrice>{Price.getPresentationValue(option.getPrice())}</MenuPrice>
      </OptionContainer>
    </Container>
  );
});

export default OptionMinimalInfo;
