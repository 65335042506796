import { Map, Record } from "immutable";
import EvilDatetime from "../util/EvilDatetime";
import ReceptionDatetime, { IReceptionDatetimeProp } from "./ReceptionDatetime";

interface IArgs {
  [ymd: string]: IReceptionDatetimeProp;
}

const initial = {
  mappedDate: Map<string, ReceptionDatetime>()
};

export default class ReceptionTimeList extends Record(initial) {
  constructor(args: IArgs = {}) {
    super(
      Object.assign({}, args, {
        mappedDate: Object.entries(args).reduce((prev, [date, value]) => {
          return prev.set(date, new ReceptionDatetime(value));
        }, Map<string, ReceptionDatetime>())
      })
    );
  }

  public getFastestReceiveDate(): Date {
    const receiveDateList = Array.from(this.get("mappedDate").entries());

    const firstDate = receiveDateList.sort(this.compareDate.bind(this)).shift();
    if (firstDate) {
      return EvilDatetime.getDateByYYYYMMDDHHmmString(
        firstDate[0],
        firstDate[1].fastest_receive_time
      );
    }
    return new Date();
  }

  private compareDate(
    a: [string, ReceptionDatetime],
    b: [string, ReceptionDatetime]
  ): number {
    const [parsedA, parsedB] = [this.parseDate(a[0]), this.parseDate(b[0])];
    const [timeA, timeB] = [parsedA.getTime(), parsedB.getTime()];
    if (timeA === timeB) {
      return 0;
    }
    return timeA > timeB ? 1 : -1;
  }

  private parseDate(dateStr: string) {
    const target = new Date();
    const year = parseInt(dateStr.substr(0, 4), 10);
    const month = parseInt(dateStr.substr(4, 2), 10) - 1;
    const date = parseInt(dateStr.substr(-2), 10);
    target.setFullYear(year);
    target.setMonth(month);
    target.setDate(date);
    return target;
  }
}
