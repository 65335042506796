import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxModel } from "../../reducer";
import { EProcessingFlag } from "../../modules/app/model";
import {
  systemOpenedModal,
  setProcessingFlag
} from "../../modules/app/actions";
import {
  systemChangedCurrentSearchResultType,
  userTouchedHomeReservationButton
} from "../../modules/search/actions";
import styled from "styled-components";
import MapIcon from "../atoms/MapPinFillIcon";
import ButtonAlter from "../atoms/ButtonAlter";

const Container = styled.div`
  position: relative;
  z-index: 2;
  margin-right: 15px;
  margin-bottom: 20px;
  margin-left: 15px;
`;

const Inner = styled.div`
  padding: 12px;
`;

const Title = styled.div`
  margin-bottom: 8px;
  padding: 5px;
  font-size: 19px;
  line-height: ${28 / 19};
  font-weight: 500;
  color: #595959;
  border-bottom: 2px solid #ff3008;
`;

const SettingDisplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #c9caca;
  border-radius: 5px;
  color: #898989;
`;

const SettingItem = styled("div")<{ grow: number }>`
  flex-grow: ${p => p.grow};
  padding: 12px 20px 12px 10px;
`;

const SettingItemWithArrow = styled(SettingItem)`
  position: relative;
  &::after {
    content: "▼";
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const iconStyle: React.CSSProperties = {
  fontSize: "15px",
  marginTop: "-2px",
  marginRight: "5px"
};

const Divider = styled.div`
  margin: 5px;
  flex-grow: 0;
  width: 1px;
  background-color: #c9caca;
`;

const HomeSettingReservation: React.FC = React.memo(() => {
  const searchPlace = useSelector((state: ReduxModel) => {
    const searchSetting = state.search.getSearchSettings().getData("reserve");
    return searchSetting.getSearchPlace();
  });

  const reservationTime = useSelector((state: ReduxModel) => {
    const searchSetting = state.search.getSearchSettings().getData("reserve");
    return searchSetting.getReservationTime();
  });

  const dispatch = useDispatch();

  const goToSettingReservation = useCallback(() => {
    dispatch(setProcessingFlag(EProcessingFlag.SEARCH_SETTING_AT_HOME));
    dispatch(systemChangedCurrentSearchResultType("reserve"));
    dispatch(push(`/setting/search/reserve`));
  }, [dispatch]);

  const handleClickSettingReserveTime = useCallback(() => {
    dispatch(setProcessingFlag(EProcessingFlag.SEARCH_SETTING_AT_HOME));
    dispatch(systemChangedCurrentSearchResultType("reserve"));
    // Analytics.logEvent("search_reserve_open_reserve_time_setting");
    dispatch(
      systemOpenedModal("SETTING_RESERVATION", { searchResultType: "reserve" })
    );
  }, [dispatch]);

  const handleClickReservationButton = useCallback(() => {
    dispatch(userTouchedHomeReservationButton());
  }, [dispatch]);

  return (
    <Container>
      <Title>テイクアウト受取日時から探す</Title>
      <Inner>
        <SettingDisplay>
          <SettingItem grow={0} onClick={goToSettingReservation}>
            <MapIcon fill={"#666"} style={iconStyle} />
            {searchPlace.getSettingText()}
          </SettingItem>
          <Divider />
          <SettingItemWithArrow
            grow={1}
            onClick={handleClickSettingReserveTime}
          >
            {reservationTime.getSettingText()}
          </SettingItemWithArrow>
        </SettingDisplay>
        <ButtonAlter
          invert
          block
          type="button"
          appearance="main"
          onClick={handleClickReservationButton}
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
            fontWeight: "bold"
          }}
        >
          予約できるお店を探す
        </ButtonAlter>
      </Inner>
    </Container>
  );
});

export default HomeSettingReservation;
