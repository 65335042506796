import { fork, put, select } from "redux-saga/effects";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { ReduxModel } from "../../../reducer";
import { systemClosedModal } from "../../app/actions";
import { UserChangedReservationTimeAction } from "../actions";
import getSearchResultSaga from "./getSearchResultSaga";

export default function* userChangedReservationTimeSaga(
  action: UserChangedReservationTimeAction
) {
  const { resultType, time } = action.payload;

  const currentSearchPlace: SearchPlace = yield select<
    (state: ReduxModel) => SearchPlace
  >(state =>
    state.search
      .getSearchSettings()
      .getData(resultType)
      .getSearchPlace()
  );

  yield put(systemClosedModal("SETTING_RESERVATION"));

  yield fork(() => getSearchResultSaga(resultType, currentSearchPlace, time));
}
