import { stripUnit } from "polished";
import * as React from "react";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import fontsConst from "../../styles/const/fontsConst";

const Container = styled.div`
  overflow-wrap: break-word;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  lines?: number;
}

const Ellipsis = React.memo((props: IProps) => {
  const { children, lines, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const linesVal = lines ? lines : 1;
    if (container.current) {
      const elem = container.current as HTMLElement;
      const fontSize = getComputedStyle(elem).fontSize;
      const fontSizeVal =
        fontSize !== null
          ? (stripUnit(fontSize) as number)
          : (stripUnit(fontsConst.SIZE.DEFAULT) as number) * 10;
      const lineHeight = getComputedStyle(elem).lineHeight;
      const lineHeightVal =
        lineHeight !== null ? (stripUnit(lineHeight) as number) : fontSizeVal;
      let height;
      if (elem.clientHeight > lineHeightVal * linesVal) {
        height = lineHeightVal * linesVal;
      } else {
        return;
      }
      const dummy = elem.cloneNode(true) as HTMLElement;
      dummy.style.cssText = `
          font-size: ${fontSizeVal}px;
          line-height: ${lineHeightVal}px;
          position: absolute;
          overflow: visible;
          visibility: hidden;
          width: ${elem.clientWidth}px;
          max-height: none;
          overflow-wrap: break-word;
        `;
      document.body.appendChild(dummy);
      if (dummy.textContent !== null && dummy.clientHeight > height) {
        dummy.textContent += "…";
        while (dummy.clientHeight > height) {
          dummy.textContent = `${dummy.textContent.slice(0, -2)}…`;
        }
        elem.textContent = dummy.textContent;
      }
      document.body.removeChild(dummy);
    }
  }, [children, lines]);

  return (
    <Container ref={container} {...rest}>
      {children}
    </Container>
  );
});

export default Ellipsis;
