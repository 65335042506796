import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { userAccessedToCart } from "../../modules/order/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import CartList from "../organisms/CartList";

const BlankContainer = styled.div`
  text-align: center;
  padding: 1em;
`;

const pageBodyStyle: React.CSSProperties = {
  padding: 0,
  paddingBottom: "24px"
};

const MultipleCartNotes = styled.div`
  padding: 10px 0;
  font-size: 16px;
  color: #ffffff;
  background-color: ${colorsConst.MAIN};
  text-align: center;
`;

const mapStateToProps = (state: ReduxModel) => ({
  cartList: state.order.getCartList(),
  fetchingCartData: state.app.isConnectedApi(EApiKey.GET_CART_LIST)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public userAccessedToCart() {
    this.dispatch(userAccessedToCart());
  }

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const CartTemplate = React.memo((props: IProps) => {
  const { cartList, actions, fetchingCartData } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  useEffect(() => {
    actions.userAccessedToCart();
  }, []);

  return (
    <>
      <Auth>
        <Page
          header={
            <SubPageHeader title="カート" handleClickBack={handleGoBack} />
          }
        >
          {cartList.size >= 2 ? (
            <MultipleCartNotes>
              {cartList.size}つのお店への注文があります
            </MultipleCartNotes>
          ) : (
            undefined
          )}
          <PageBody style={pageBodyStyle}>
            {cartList.isEmpty() ? (
              <BlankContainer>カートは空です</BlankContainer>
            ) : fetchingCartData ? (
              <BlankContainer>
                <Loading />
              </BlankContainer>
            ) : (
              <CartList />
            )}
          </PageBody>
        </Page>
      </Auth>
    </>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartTemplate);
