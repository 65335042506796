import { Record } from "immutable";

// クレジットカードの種別
export enum ECreditCardType {
  AMEX = 1,
  JCB = 2,
  VISA = 3,
  MASTER_CARD = 4,
  OTHER = 5
}

export interface ICreditCardItem {
  card_id: string;
  card_number: string;
  card_company: ECreditCardType;
}

const initialValues: ICreditCardItem = {
  card_id: "",
  card_number: "",
  card_company: ECreditCardType.VISA
};

export default class CreditCardItem extends Record(initialValues) {
  public getId(): string {
    return this.get("card_id");
  }

  public getCardNumber(): string {
    return this.get("card_number");
  }

  public getCardCompany(): ECreditCardType {
    return this.get("card_company");
  }

  public getTextByCreditCardType() {
    switch (this.getCardCompany()) {
      case ECreditCardType.AMEX:
        return "Amex";
      case ECreditCardType.JCB:
        return "JCB";
      case ECreditCardType.MASTER_CARD:
        return "MasterCard";
      case ECreditCardType.VISA:
        return "Visa";
      default:
        return "その他";
    }
  }
}
