import { Record } from "immutable";

export interface IShopItemReceptionTime {
  start_time: string;
  end_time: string;
}

const initial = {
  start_time: "",
  end_time: ""
};

export default class ShopItemReceptionTime extends Record(initial) {
  public getStartTime() {
    return this.get("start_time");
  }
  public getEndTime() {
    return this.get("end_time");
  }
}
