import * as React from "react";
import styled from "styled-components";
import ShopItemCategory from "../../records/ShopItemCategory";
import colorsConst from "../../styles/const/colorsConst";
import ShopMenuItem from "../molecules/ShopMenuItem";

const CategoryListItem = styled.li`
  padding: 20px 20px 0;
`;
const CategoryListItemHead = styled.div`
  padding-bottom: 6px;
  font-size: 18px;
  line-height: ${26 / 18};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colorsConst.MAIN};
  font-weight: 500;
  color: #595959;
`;

interface IProps {
  category: ShopItemCategory;
  shopId: number;
}

const CategoryMenuList = React.memo((props: IProps) => {
  const { category, shopId } = props;
  return (
    <>
      {!category.getItemList().isEmpty() && (
        <CategoryListItem>
          <CategoryListItemHead>
            {category.getCategoryName()}
          </CategoryListItemHead>
          <ul>
            {category.getItemList().map(item => (
              <ShopMenuItem key={item.getId()} shopId={shopId} item={item} />
            ))}
          </ul>
        </CategoryListItem>
      )}
    </>
  );
});

export default CategoryMenuList;
