import { goBack, push } from "connected-react-router";
import * as React from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxModel } from "../../reducer";
import {
  userTouchedCategoryTagThumb,
  userAccessedToPageThatNeedsCategoryTagList
} from "../../modules/search/actions";
import { EApiKey } from "../../apis";
import styled from "styled-components";
import Auth from "../organisms/Auth";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Loading from "../molecules/Loading";

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.45);
`;

const Title = styled.div`
  font-size: 16px;
  color: #707070;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ListItem = styled.div`
  width: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 3px;
`;

const CategoryThumb = styled.div<{ src: string }>`
  position: relative;
  height: ${(135 / 375) * 100}vw;
  font-size: 18px;
  color: #fff;
  background-image: url(${p => p.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const overlayTextContainerStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.3)"
};

const overlayTextStyle: React.CSSProperties = {
  padding: "0 0.5em",
  fontSize: "18px",
  lineHeight: 17 / 12,
  letterSpacing: "-0.03em",
  fontWeight: 500,
  color: "#FFFFFF",
  textShadow: "0px 0px 3px rgba(0, 0, 0, 0.8)",
  textAlign: "center"
};

interface IProps {}

const CategoryTagListTemplate: React.FC<IProps> = React.memo(props => {
  const {} = props;

  const categoryTagList = useSelector((state: ReduxModel) =>
    state.search.getCategoryTagList()
  );

  const fetching = useSelector((state: ReduxModel) =>
    state.app.isConnectedApi(EApiKey.GET_SHOP_LIST_BY_CATEGORY)
  );

  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleClick = useCallback(
    (categoryId: number) => {
      dispatch(userTouchedCategoryTagThumb(categoryId));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsCategoryTagList());
  }, [dispatch]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title={"ジャンル一覧"}
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          <Title>人気のジャンル</Title>
          <ListContainer>
            {categoryTagList.map(category => (
              <ListItem key={category.getId()}>
                <CategoryThumb
                  src={category.getImagePath()}
                  onClick={() => handleClick(category.getId())}
                >
                  <div style={overlayTextContainerStyle}>
                    <div style={overlayTextStyle}>{category.getTagName()}</div>
                  </div>
                </CategoryThumb>
              </ListItem>
            ))}
          </ListContainer>
        </PageBody>
      </Page>
      {fetching && (
        <LoadingContainer>
          <Loading invert />
        </LoadingContainer>
      )}
    </Auth>
  );
});

export default CategoryTagListTemplate;
