import React from "react";
import { connect } from "react-redux";
import { EApiKey } from "../../apis";
import { ReduxModel } from "../../reducer";
import LoadingDialog from "./LoadingDialog";

const mapStateToProps = (state: ReduxModel) => ({
  submittingDelete: state.app.isConnectedApi(EApiKey.CLEAR_CART_LIST_WITH_SHOP)
});

const mapDispatchToProps = null;

interface IProps extends ReturnType<typeof mapStateToProps> {}

const ClearingCartDialog: React.FC<IProps> = React.memo(
  ({ submittingDelete }) => {
    return (
      <LoadingDialog
        isOpen={submittingDelete}
        statusText={"削除しています..."}
      />
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClearingCartDialog);
