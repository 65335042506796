import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgShareLine = (props: IProps) => (
  <svg width={30} height={30} {...props}>
    <defs>
      <path id="Share_Line_svg__a" d="M0 0h30v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="Share_Line_svg__b" fill="#fff">
          <use xlinkHref="#Share_Line_svg__a" />
        </mask>
        <path
          d="M23.5 30h-17A6.5 6.5 0 0 1 0 23.5v-17A6.5 6.5 0 0 1 6.5 0h17A6.5 6.5 0 0 1 30 6.5v17a6.5 6.5 0 0 1-6.5 6.5"
          fill="#00B900"
          mask="url(#Share_Line_svg__b)"
        />
      </g>
      <path
        d="M25.874 13.68c0-4.887-4.9-8.863-10.923-8.863C8.93 4.817 4.03 8.793 4.03 13.68c0 4.382 3.886 8.051 9.135 8.745.355.077.84.235.962.539.11.276.072.708.035.987l-.156.935c-.048.276-.22 1.08.946.59 1.166-.492 6.29-3.705 8.582-6.343 1.583-1.735 2.341-3.497 2.341-5.453"
        fill="#FFF"
      />
      <path
        d="M12.732 11.319h-.766a.213.213 0 0 0-.213.212v4.76c0 .117.095.212.213.212h.766a.212.212 0 0 0 .213-.212v-4.76a.213.213 0 0 0-.213-.212M18.005 11.319h-.766a.213.213 0 0 0-.212.212v2.828l-2.182-2.945a.233.233 0 0 0-.016-.022l-.001-.002-.013-.013c-.002 0-.003-.002-.004-.003a.21.21 0 0 0-.017-.014l-.011-.008-.007-.003a.26.26 0 0 0-.01-.007c-.003 0-.005-.002-.008-.003l-.012-.005-.007-.002-.012-.005-.008-.002a.205.205 0 0 0-.012-.002l-.009-.002a.087.087 0 0 0-.011-.001h-.011l-.008-.001h-.766a.213.213 0 0 0-.212.212v4.76c0 .117.095.212.212.212h.766a.212.212 0 0 0 .213-.212v-2.827l2.184 2.95a.212.212 0 0 0 .054.052l.002.002.013.007a.075.075 0 0 0 .006.004l.01.005a.208.208 0 0 0 .017.006l.015.005h.003a.205.205 0 0 0 .054.008h.766a.212.212 0 0 0 .213-.212v-4.76a.213.213 0 0 0-.213-.212M10.885 15.311H8.803v-3.78a.213.213 0 0 0-.212-.212h-.766a.213.213 0 0 0-.213.213v4.758c0 .058.023.11.06.148 0 0 0 .002.002.003a.212.212 0 0 0 .15.062h3.061a.213.213 0 0 0 .213-.213v-.766a.213.213 0 0 0-.213-.213M22.236 12.51a.213.213 0 0 0 .213-.212v-.766a.213.213 0 0 0-.213-.213h-3.06a.211.211 0 0 0-.148.06l-.003.002a.21.21 0 0 0-.062.15v4.76c0 .057.023.109.06.147l.002.003.003.003c.039.036.09.059.147.059h3.061a.212.212 0 0 0 .213-.212v-.767a.213.213 0 0 0-.213-.213h-2.081v-.804h2.081a.212.212 0 0 0 .213-.213v-.766a.213.213 0 0 0-.213-.213h-2.081v-.804h2.081z"
        fill="#00B900"
      />
    </g>
  </svg>
);

export default function LineIcon(props: IProps) {
  return <SvgShareLine {...props} />;
}
