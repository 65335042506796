import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import ShopData from "../../records/ShopData";
import ChevronWideIcon from "../atoms/ChevronWideIcon";
import Grid from "../atoms/Grid";
import Loading from "../molecules/Loading";
import MapViewer from "../organisms/MapViewer";
import ShopMarker from "./ShopMarker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MapWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
`;

const MapContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const AddressContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  position: absolute;
  bottom: 19px;
  width: calc(100% - ${19 * 2}px);
  margin: 0 19px;
  padding: 8px 15px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f6f6f6;
`;

const mapLinkStyle: React.CSSProperties = {
  fontWeight: 500,
  fontSize: "12px",
  color: "#383838",
  flexWrap: "nowrap"
};

interface IProps {
  containerHeight: number;
  shopData: ShopData;
  handleClickLaunchMapApp: () => void;
  setSwipeableTabFlag: (isSwipeable: boolean) => void;
}

const ShopTabMap: React.FC<IProps> = React.memo(props => {
  const {
    containerHeight,
    shopData,
    handleClickLaunchMapApp,
    setSwipeableTabFlag
  } = props;

  const handleTouchStartAddressContainer = useCallback(() => {
    setSwipeableTabFlag(true);
  }, [setSwipeableTabFlag]);

  const handleTouchEndAddressContainer = useCallback(() => {
    setSwipeableTabFlag(false);
  }, [setSwipeableTabFlag]);

  return (
    <Container style={{ height: containerHeight }}>
      <>
        <MapWrapper>
          {shopData.getLocation() ? (
            <MapViewer
              containerElement={<MapContainer />}
              defaultCenter={shopData.getLocation()}
              // options={{
              //   gestureHandling: "cooperative"
              // }}
            >
              <ShopMarker
                location={shopData.getLocation()}
                isOpen={shopData.isOpen()}
              />
              <AddressContainer
                onClick={handleClickLaunchMapApp}
                onTouchStart={handleTouchStartAddressContainer}
                onTouchEnd={handleTouchEndAddressContainer}
              >
                <Grid item grow={1}>
                  {shopData.getAddress()}
                </Grid>
                <Grid item grow={0} style={{ paddingLeft: "10px" }}>
                  <Grid container align="center" style={mapLinkStyle}>
                    <Grid item style={{ whiteSpace: "nowrap" }}>
                      マップアプリで見る
                    </Grid>
                    <ChevronWideIcon
                      color="#383838"
                      style={{ fontSize: "11px", marginLeft: "5px" }}
                    />
                  </Grid>
                </Grid>
              </AddressContainer>
            </MapViewer>
          ) : (
            <MapContainer>
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            </MapContainer>
          )}
        </MapWrapper>
      </>
    </Container>
  );
});

export default ShopTabMap;
