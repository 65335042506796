import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Settings } from "react-slick";
import styled, { createGlobalStyle } from "styled-components";
import FeatureItem from "../../records/FeatureItem";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import Carousel from "../atoms/Carousel";
import Ellipsis from "../organisms/Ellipsis";

const Container = styled.div`
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: ${colorsConst.BACKGROUND};
`;

const Title = styled.div`
  margin-bottom: 20px;
  padding: 5px;
  font-size: 19px;
  line-height: ${28 / 19};
  font-weight: 500;
  color: #595959;
  border-bottom: 2px solid #ff3008;
`;

const CarouselContainer = styled.div`
  font-size: 0;
`;

const Inner = styled.div`
  .slick-list {
    overflow: hidden;
  }
`;

const DOTS_CLASS_NAME = "carousel-dots";

const DotsList = styled.ul`
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 7px;
    background-color: #c9caca;
    border-radius: 100%;
    button {
      width: 7px;
      height: 7px;
      font-size: 0;
    }
    &.slick-active {
      background-color: #ff3008;
    }
  }
`;

const DotsStyle = createGlobalStyle`
  .${DOTS_CLASS_NAME} {
  }
`;

const BannerImgContainer = styled.div`
  display: block;
  width: 100%;
  height: calc(100vw * ${160 / 375});
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ItemTitle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
`;
const ItemDescription = styled.div`
  height: calc(2em * 1.5);
  font-size: 12px;
  color: #898989;
  line-height: 1.5;
`;

interface IProps extends Settings {
  features: List<FeatureItem>;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const HomeCarousel: React.FC<IProps> = React.memo(props => {
  const { containerRef, features } = props;

  const renderDots = useCallback((dots: React.ReactNode) => {
    return <DotsList>{dots}</DotsList>;
  }, []);

  const handleClickCarousel = useCallback(() => {
    Analytics.logEvent("home_banner");
  }, []);

  const featureList = useMemo(() => {
    return features.sort(FeatureItem.sortByWeight);
  }, [features]);

  return (
    <>
      {featureList.isEmpty() ? null : (
        <Container>
          <Title>特集</Title>
          <CarouselContainer ref={containerRef}>
            <Inner>
              <DotsStyle />
              <Carousel
                dots
                infinite
                autoplay
                autoplaySpeed={5000}
                speed={250}
                slidesToShow={1}
                slidesToScroll={1}
                appendDots={renderDots}
                arrows={false}
                dotsClass={DOTS_CLASS_NAME}
                {...props}
              >
                {featureList.map(item => (
                  <Link
                    key={item.getId()}
                    to={item.getLink()}
                    onClick={handleClickCarousel}
                  >
                    <BannerImgContainer>
                      <BannerImage src={`${item.getImageFilePath()}`} />
                    </BannerImgContainer>
                    <ItemTitle>{item.getTitle()}</ItemTitle>
                    <ItemDescription>
                      <Ellipsis lines={2}>{item.getDescriptionText()}</Ellipsis>
                    </ItemDescription>
                  </Link>
                ))}
              </Carousel>
            </Inner>
          </CarouselContainer>
        </Container>
      )}
    </>
  );
});

export default HomeCarousel;
