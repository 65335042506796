import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import { userTouchedToButtonThatReturnFromMaintenancePage } from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedToButtonThatReturnFromMaintenancePage() {
    this.dispatch(userTouchedToButtonThatReturnFromMaintenancePage());
  }
}

interface IProps {
  message: string;
  fetchingUserData: boolean;
  actions: ActionDispatcher;
}

const MaintenanceTemplate = React.memo((props: IProps) => {
  const { fetchingUserData, message, actions } = props;

  const handleTouchReturnFromMaintenanceButton = useCallback(
    (event: React.FormEvent<HTMLButtonElement>) => {
      actions.userTouchedToButtonThatReturnFromMaintenancePage();
    },
    [actions]
  );

  return (
    <Page header={<SubPageHeader title="メンテナンス" />}>
      <PageBody>
        <>
          {message
            .split("\n")
            .map((line: string, index: number) =>
              line.trim() === "" ? (
                <br key={index} />
              ) : (
                <Notes key={index}>{line}</Notes>
              )
            )}
        </>
        <div style={{ marginTop: "36px" }}>
          <Button
            block
            onClick={handleTouchReturnFromMaintenanceButton}
            loading={fetchingUserData}
          >
            OK
          </Button>
        </div>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  message: state.app.getMaintenanceInfo().getMessage(),
  fetchingUserData: state.app.isConnectedApi(EApiKey.GET_USER_DATA)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceTemplate);
