import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const ErrorText = styled.div`
  padding: 10px 0;
  min-height: calc(20px + ${1.5 * 14}px);
  color: ${colorsConst.WARNING};
  line-height: 1.5;
  font-size: 1.4rem;
`;

export default ErrorText;
