import { ReducerActions } from "./reducer";
import { UserAndSystemActions } from "./userAndSystem";

export enum ActionTypes {
  U_TOUCHED_MAP_SEARCH_PIN = "search/U_TOUCHED_MAP_SEARCH_PIN",
  U_ACCESSED_SHOP_PAGE = "search/U_ACCESSED_SHOP_PAGE",
  U_ACCESSED_SHOP_TOP_PAGE = "search/U_ACCESSED_SHOP_TOP_PAGE",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SEARCH_HISTORY_LIST = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_SEARCH_HISTORY_LIST",
  U_SUBMITTED_SEARCH_KEYWORD = "search/U_SUBMITTED_SEARCH_KEYWORD",
  U_SUBMITTED_SEARCH_LOCATION = "search/U_SUBMITTED_SEARCH_LOCATION",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST",
  U_TOUCHED_CHANGE_SHOP_FAVORITE_STATE_BUTTON = "search/U_TOUCHED_CHANGE_FAVORITE_SHOP_STATE_BUTTON",
  U_SUBMITTED_SEARCH_QUERY = "search/U_SUBMITTED_SEARCH_QUERY",
  U_SUBMITTED_SEARCH_SETTINGS = "search/U_SUBMITTED_SEARCH_SETTINGS",
  U_SUBMITTED_SEARCH_SETTINGS_WITHOUT_SEARCH = "search/U_SUBMITTED_SEARCH_SETTINGS_WITHOUT_SEARCH",
  U_TOUCHED_SEARCH_BY_MAP_LOCATION_BUTTON = "search/U_TOUCHED_SEARCH_BY_MAP_LOCATION_BUTTON",
  U_TOUCHED_WRITE_REVIEW_BUTTON = "search/U_TOUCHED_WRITE_REVIEW_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_MAP_SEARCH_RESULT = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_MAP_SEARCH_RESULT",
  U_ACCESSED_TO_HOME = "search/U_ACCESSED_TO_HOME",
  U_TOUCHED_TOGGLE_RESULT_VIEW_TYPE_BUTTON = "search/U_TOUCHED_TOGGLE_RESULT_VIEW_TYPE_BUTTON",
  U_CHANGED_SEARCH_RADIUS = "search/U_CHANGED_SEARCH_RADIUS",
  U_CHANGED_RESERVATION_TIME = "search/U_CHANGED_RESERVATION_TIME",
  U_CHANGED_RESERVATION_TIME_WITHOUT_SEARCH = "search/U_CHANGED_RESERVATION_TIME_WITHOUT_SEARCH",
  U_CHANGED_PRICE_RANGE = "search/U_CHANGED_PRICE_RANGE",
  U_CHANGED_SORT_TYPE = "search/U_CHANGED_SORT_TYPE",
  U_SUBMITTED_FILTER_SETTINGS = "search/U_SUBMITTED_FILTER_SETTINGS",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY_LIST_FOR_SEARCH_RESULT = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY_LIST_FOR_SEARCH_RESULT",
  U_TOUCHED_CURRENT_LOCATION_SEARCH_BUTTON = "search/U_TOUCHED_CURRENT_LOCATION_SEARCH_BUTTON",
  U_TOUCHED_HOME_RESERVATION_BUTTON = "search/U_TOUCHED_HOME_RESERVATION_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_INITIAL_SEARCH_RESULT = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_INITIAL_SEARCH_RESULT",
  U_ACCESSED_AREA_PAGE = "search/U_ACCESSED_AREA_PAGE",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_AREA_SHOP_LIST = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_AREA_SHOP_LIST",
  U_TOUCHED_CATEGORY_TAG_THUMB = "search/U_TOUCHED_CATEGORY_TAG_THUMB",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_NEAR_OFFICE_SHOP_LIST = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_NEAR_OFFICE_SHOP_LIST",
  U_TOUCHED_MORE_NEAR_OFFICE_SHOP_LIST_BUTTON = "search/U_TOUCHED_MORE_NEAR_OFFICE_SHOP_LIST_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_HOME_SHOP_LIST = "search/U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_HOME_SHOP_LIST",

  S_DETECTED_POSITION_ERROR = "search/S_DETECTED_POSITION_ERROR",
  S_CHANGED_CURRENT_SEARCH_RESULT_TYPE = "search/S_CHANGED_CURRENT_SEARCH_RESULT_TYPE",

  R_UPDATE_SHOP_FAVORITE_STATE = "search/R_UPDATE_SHOP_FAVORITE_STATE",
  R_UPDATE_CATEGORY_TAG_LIST = "search/R_UPDATE_CATEGORY_TAG_LIST",
  R_UPDATE_HOME_CATEGORY_TAG_LIST = "search/R_UPDATE_HOME_CATEGORY_TAG_LIST",
  R_UPDATE_AREA_LIST = "search/R_UPDATE_AREA_LIST",
  R_UPDATE_FETCHING_CURRENT_LOCATION_FLAG = "search/R_UPDATE_FETCHING_CURRENT_LOCATION_FLAG",
  R_UPDATE_CURRENT_SHOP_DATA = "search/R_UPDATE_CURRENT_SHOP_DATA",
  R_UPDATE_CURRENT_SHOP_ITEM_LIST = "search/R_UPDATE_CURRENT_SHOP_ITEM_LIST",
  R_UPDATE_CURRENT_LOCATION = "search/R_UPDATE_CURRENT_LOCATION",
  R_UPDATE_SEARCH_HISTORY_LIST = "search/R_UPDATE_SEARCH_HISTORY_LIST",
  R_UPDATE_CURRENT_LOCATION_INQUIRY_STATUS = "search/R_UPDATE_CURRENT_LOCATION_INQUIRY_STATUS",
  R_UPDATE_SEARCH_QUERY = "search/R_UPDATE_SEARCH_QUERY",
  R_UPDATE_HOME_LIST = "search/R_UPDATE_HOME_LIST",
  R_UPDATE_SEARCH_SETTING = "search/R_UPDATE_SEARCH_SETTING",
  R_UPDATE_SEARCH_RESULT = "search/R_UPDATE_SEARCH_RESULT",
  R_UPDATE_CURRENT_SEARCH_RESULT_TYPE = "search/R_UPDATE_CURRENT_SEARCH_RESULT_TYPE",
  R_INIT_RESERVE_SEARCH_SETTING = "search/R_INIT_RESERVE_SEARCH_SETTING",
  R_UPDATE_ONCE_SEARCHED = "search/R_UPDATE_ONCE_SEARCHED",
  R_UPDATE_CURRENT_AREA = "search/R_UPDATE_CURRENT_AREA",
  R_UPDATE_CURRENT_SUB_AREA_LIST = "search/R_UPDATE_CURRENT_SUB_AREA_LIST",
  R_UPDATE_CURRENT_SUB_AREA = "search/R_UPDATE_CURRENT_SUB_AREA",
  R_UPDATE_CURRENT_AREA_SHOP_LIST = "search/R_UPDATE_CURRENT_AREA_SHOP_LIST",
  R_UPDATE_CURRENT_CATEGORY_TAG = "search/R_UPDATE_CURRENT_CATEGORY_TAG",
  R_UPDATE_CURRENT_CATEGORY_SHOP_LIST = "search/R_UPDATE_CURRENT_CATEGORY_SHOP_LIST",
  R_UPDATE_NEAR_OFFICE_SHOP_LIST = "search/R_UPDATE_NEAR_OFFICE_SHOP_LIST",
  R_UPDATE_CURRENT_HOME_SHOP_LIST = "search/R_UPDATE_CURRENT_HOME_SHOP_LIST"
}

export type SearchActions = UserAndSystemActions | ReducerActions;
export * from "./userAndSystem";
export * from "./reducer";
