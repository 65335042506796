import { cancel, fork, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IPublishSmsRequest,
  IPublishSmsResponse
} from "../../../apis/publishSms";
import { IUpdateTelRequest, IUpdateTelResponse } from "../../../apis/updateTel";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateUser,
  UserTouchedRequestToResentSMSAuthCodeButtonAction
} from "../actions";

export function* requestToResentSMSAuthCodeSaga(
  action: UserTouchedRequestToResentSMSAuthCodeButtonAction
) {
  try {
    const params: IUpdateTelRequest = {
      tel: action.payload.tel
    };
    const updateTelTask = yield fork(() => updateTel(params));
    const successedUpdateTel = yield join(updateTelTask);
    if (!successedUpdateTel) {
      yield cancel();
    }
    yield fork(() => publishSMS(params));
  } catch (e) {
    console.log(e);
  }
}

function* updateTel(params: IUpdateTelRequest) {
  try {
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IUpdateTelResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      return true;
    } else {
      throw error;
    }
  } catch (exception) {
    return false;
  }
}

function* publishSMS(params: IPublishSmsRequest) {
  try {
    const task = yield spawn(commonApiSaga, EApiKey.PUBLISH_SMS, params);
    const {
      result,
      error
    }: { result: IPublishSmsResponse; error: unknown } = yield join(task);
    if (task.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "認証コードを送信しました。",
          message: ""
        })
      );
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証コードの送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
