import * as React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

interface IProps extends React.SVGAttributes<SVGElement> {
  checked?: boolean;
  style?: React.CSSProperties;
}

const Box = styled.span`
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid #e0e0e0;
  border-radius: 1em;
`;

const Checked = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 1em;
  height: 1em;
  background: radial-gradient(
    circle,
    ${colorsConst.MAIN},
    ${colorsConst.MAIN} 45%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0)
  );
  transform: translate3d(-50%, -50%, 0);
`;

const CheckIcon = (props: IProps) => {
  const { checked, style } = props;
  return <Box style={style}>{checked && <Checked />}</Box>;
};

export default CheckIcon;
