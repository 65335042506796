import { all, spawn } from "redux-saga/effects";
import getCartListSaga from "../../order/sagas/getCartListSaga";
import getOrderHistorySaga from "../../order/sagas/getOrderHistorySaga";
import setAnalyticsUserIdSaga from "./setAnalyticsUserIdSaga";

export default function* userLoggedInSaga() {
  yield all([
    spawn(() => setAnalyticsUserIdSaga()),
    spawn(() => getCartListSaga()),
    spawn(() => getOrderHistorySaga())
  ]);
}
