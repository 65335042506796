import { goBack } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxAction } from "../../reducer";
import BlockParagraph from "../atoms/BlockParagraph";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Paper from "../atoms/Paper";
import SubPageHeader from "../molecules/SubPageHeader";
import PrivacyPolicy from "../organisms/PrivacyPolicy";

const pageBodyStyle: React.CSSProperties = {
  padding: "29px 24px"
};

interface IProps {
  actions: ActionDispatcher;
}

const PrivacyPolicyTemplate = React.memo((props: IProps) => {
  const { actions } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <Page
      header={
        <SubPageHeader
          title="プライバシーポリシー"
          handleClickBack={handleGoBack}
        />
      }
    >
      <PageBody style={pageBodyStyle}>
        <Paper>
          <BlockParagraph>
            <PrivacyPolicy />
          </BlockParagraph>
        </Paper>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}
  public goBack() {
    this.dispatch(goBack());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicyTemplate);
