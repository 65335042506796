import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: ColorProperty;
  size?: number;
}

const ratio = 15 / 21;

const CheckedIcon: React.FC<IProps> = ({
  color = "#fff",
  size = 21,
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size * ratio}
      viewBox="0 0 21 15"
      fill="none"
      {...rest}
    >
      <path
        d="M18.307.824a1.74 1.74 0 0 0-1.196.525c-2.736 2.742-5.883 5.881-8.728 8.728L3.807 6.18a1.74 1.74 0 1 0-2.248 2.655l5.8 4.93a1.74 1.74 0 0 0 2.357-.1c3.09-3.097 6.764-6.757 9.86-9.86a1.74 1.74 0 0 0-1.27-2.981z"
        fill={color}
      />
    </svg>
  );
};

export default CheckedIcon;
