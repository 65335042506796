import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { systemClosedModal } from "../../modules/app/actions";
import { EProcessingFlag } from "../../modules/app/model";
import { EPaymentType } from "../../records/PaymentType";
import {
  userSelectedPaymentType,
  userConfirmedOrder
} from "../../modules/order/actions";
import Price from "../../records/Price";
import { ReduxModel } from "../../reducer";
import CloseIcon from "../atoms/CloseIcon";
import CircleCheckIcon from "../atoms/CircleCheckIcon";
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from "../atoms/Dialog";
import Grid from "../atoms/Grid";
import Modal from "../molecules/Modal";
import OrderButton from "../molecules/OrderButton";
import colorsConst from "../../styles/const/colorsConst";

const contentStyle: React.CSSProperties = {
  position: "absolute",
  bottom: "0",
  width: "100%",
  paddingTop: "0",
  background: "#ffffff"
};

const dialogStyle: React.CSSProperties = {
  borderRadius: "0",
  padding: "0 16px 50px"
};

const dialogHeaderStyle: React.CSSProperties = {
  position: "relative",
  marginBottom: "0",
  padding: "16px 0 11px",
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: "23px",
  letterSpacing: "1px",
  textAlign: "center",
  borderBottom: `1px solid ${colorsConst.MAIN}`
};

const dialogBodyStyle: React.CSSProperties = {
  padding: "0",
  textAlign: "center",
  letterSpacing: "1px",
  color: "#272727"
};

const dialogFooterStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  marginTop: "0"
};

const CloseIconContainer = styled.div`
  position: absolute;
  top: 13px;
  left: 2px;
`;

const TotalPriceContainer = styled.div`
  padding: 20px 14px 3px;
  border-bottom: solid 1px #d7d7da;
`;

const TotalPrice = styled(Grid).attrs({ container: true })`
  padding-bottom: 17px;
`;

const TotalPriceTitle = styled(Grid).attrs({ item: true })`
  margin-right: auto;
  font-size: 14px;
  line-height: 23px;
`;

const TotalPriceValue = styled(Grid).attrs({ item: true })`
  font-size: 16px;
  line-height: 23px;
  text-align: right;
`;

const Coupon = styled(Grid).attrs({ container: true })`
  padding-bottom: 17px;
`;

const CouponTitle = styled(Grid).attrs({ item: true })`
  margin-right: auto;
  font-size: 14px;
  line-height: 18px;
`;

const CouponValue = styled(Grid).attrs({ item: true })`
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: #ed2d21;
`;

const PaymentPriceContainer = styled.div`
  padding: 15px 14px 6px;
`;

const PaymentPrice = styled(Grid).attrs({ container: true })`
  padding-bottom: 17px;
`;

const PaymentPriceTitle = styled(Grid).attrs({ item: true })`
  margin-right: auto;
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
`;

const PaymentPriceValue = styled(Grid).attrs({ item: true })`
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  font-weight: bold;
`;

const PaymentTypeContainer = styled.div`
  margin-bottom: 20px;
`;

const PaymentTypeTitle = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid ${colorsConst.MAIN};
  text-align: left;
  font-size: 18px;
  font-weight: bold;
`;

const PaymentTypeRow = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  padding: 8px 5px;
`;

const PaymentIconContainer = styled(Grid).attrs({ item: true, rate: 0 })`
  margin-right: 5px;
  font-size: 18px;
`;

const PaymentTypeName = styled(Grid).attrs({ item: true, rate: 1 })<{
  checked: boolean;
}>`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  ${p => p.checked && "font-weight: bold;"}
`;

const ChangeCreditCard = styled(Grid).attrs({ item: true, rate: 0 })`
  font-size: 14px;
  color: ${colorsConst.MAIN};
  border-bottom: 1px solid ${colorsConst.MAIN};
`;

const DelayOrderNotes = styled.div`
  padding: 9px 14px 30px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #ed2d21;
`;

const mapStateToProps = (state: ReduxModel) => ({
  openConfirmOrderDialg: state.app
    .getModalManager()
    .canDisplay("CONFIRM_ORDER"),
  cartData: state.order.findCartDataByCartId(state.order.getPreOrderCartId()),
  creditCard: state.user.getCreditCard(),
  submittingOrder: state.app.isProcessing(EProcessingFlag.SUBMIT_ORDER),
  currentPaymentType: state.order.getPaymentType()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userTouchedCloseConfirmOrderDialog() {
    dispatch(systemClosedModal("CONFIRM_ORDER"));
  },
  userTouchedConfirmedOrder() {
    dispatch(userConfirmedOrder());
  },
  handleChangePaymentType(type: EPaymentType) {
    dispatch(userSelectedPaymentType(type));
  },
  goToSelectCreditCard() {
    dispatch(push("user/changeCreditCard"));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const ConfirmOrderDialog: React.FC<IProps> = (props: IProps) => {
  const {
    openConfirmOrderDialg,
    userTouchedCloseConfirmOrderDialog,
    userTouchedConfirmedOrder,
    cartData,
    creditCard,
    submittingOrder,
    currentPaymentType,
    handleChangePaymentType,
    goToSelectCreditCard
  } = props;

  const handleClickChangeCreditCargButton = useCallback(() => {
    goToSelectCreditCard();
  }, [goToSelectCreditCard]);

  const handleChange = useCallback(
    (type: EPaymentType) => {
      handleChangePaymentType(type);
    },
    [handleChangePaymentType]
  );

  return (
    <Modal
      isOpen={openConfirmOrderDialg && typeof cartData !== "undefined"}
      contentStyle={contentStyle}
      handleClickBackdrop={userTouchedCloseConfirmOrderDialog}
    >
      <Dialog style={dialogStyle}>
        <DialogHeader style={dialogHeaderStyle}>
          <CloseIconContainer>
            <CloseIcon onClick={userTouchedCloseConfirmOrderDialog} />
          </CloseIconContainer>
          注文の確認
        </DialogHeader>
        {typeof cartData !== "undefined" && (
          <>
            <DialogBody style={dialogBodyStyle}>
              <TotalPriceContainer>
                <TotalPrice>
                  <TotalPriceTitle>注文代金</TotalPriceTitle>

                  <TotalPriceValue>
                    {Price.getPresentationValue(cartData.getItemTotalPrice())}
                  </TotalPriceValue>
                </TotalPrice>
                {cartData.existsDiscountCode() && (
                  <Coupon>
                    <CouponTitle>{cartData.getCouponName()}</CouponTitle>
                    <CouponValue>
                      {`-${Price.getPresentationValue(
                        cartData.getDiscountPrice()
                      )}`}
                    </CouponValue>
                  </Coupon>
                )}
              </TotalPriceContainer>
              <PaymentPriceContainer>
                <PaymentPrice>
                  <PaymentPriceTitle>支払金額</PaymentPriceTitle>
                  <PaymentPriceValue>
                    {Price.getPresentationValue(cartData.getUserPaymentPrice())}
                  </PaymentPriceValue>
                </PaymentPrice>
              </PaymentPriceContainer>
              <PaymentTypeContainer>
                <PaymentTypeTitle>お支払い方法</PaymentTypeTitle>
                {cartData
                  .getShopData()
                  .getPayment()
                  .canCredit() && (
                  <PaymentTypeRow
                    onClick={() => handleChange(EPaymentType.CREDIT)}
                  >
                    <PaymentIconContainer>
                      <CircleCheckIcon
                        checked={currentPaymentType === EPaymentType.CREDIT}
                      />
                    </PaymentIconContainer>
                    <PaymentTypeName
                      checked={currentPaymentType === EPaymentType.CREDIT}
                    >
                      クレジットカード
                    </PaymentTypeName>
                    <ChangeCreditCard
                      onClick={handleClickChangeCreditCargButton}
                    >
                      {creditCard.exists() ? "変更" : "登録する"}
                    </ChangeCreditCard>
                  </PaymentTypeRow>
                )}
                {cartData
                  .getShopData()
                  .getPayment()
                  .canLocal() && (
                  <PaymentTypeRow
                    onClick={() => handleChange(EPaymentType.LOCALLY)}
                  >
                    <PaymentIconContainer>
                      <CircleCheckIcon
                        checked={currentPaymentType === EPaymentType.LOCALLY}
                      />
                    </PaymentIconContainer>
                    <PaymentTypeName
                      checked={currentPaymentType === EPaymentType.LOCALLY}
                    >
                      お店でお支払い
                    </PaymentTypeName>
                  </PaymentTypeRow>
                )}
              </PaymentTypeContainer>
              {cartData.getShopData().isBusy() && (
                <DelayOrderNotes>
                  注文数が多いまたは混みあっているため、受取時間が遅れる場合がございます。よろしいですか？
                </DelayOrderNotes>
              )}
            </DialogBody>
            <DialogFooter style={dialogFooterStyle}>
              <OrderButton
                isSubmit
                shopData={cartData.getShopData()}
                submittingOrder={submittingOrder}
                currentPaymentType={currentPaymentType}
                handleClickButton={userTouchedConfirmedOrder}
                style={{ borderRadius: "4px" }}
              >
                注文を確定する
              </OrderButton>
            </DialogFooter>
          </>
        )}
      </Dialog>
    </Modal>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderDialog);
