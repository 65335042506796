import { goBack } from "connected-react-router";
import { cancel, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdateSelectCreditCardRequest,
  IUpdateSelectCreditCardResponse
} from "../../../apis/updateSelectCreditCard";
import { AlertType } from "../../../records/Alert";
import { ICreditCardItem } from "../../../records/CreditCardItem";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCreditCard,
  UserSubmittedChangeSelectCreditCardAction
} from "../actions";

export default function* userSubmittedChangeSelectCreditCardSaga(
  action: UserSubmittedChangeSelectCreditCardAction
) {
  try {
    const params: IUpdateSelectCreditCardRequest = {
      card_id: action.payload.id
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_SELECT_CREDIT_CARD,
      params
    );
    const {
      result,
      error
    }: { result: IUpdateSelectCreditCardResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      const creditCardList: ICreditCardItem[] = result.credit_card_list as ICreditCardItem[];
      const selectCardIndex = result.select_card_index;
      yield put(updateCreditCard(creditCardList, selectCardIndex));
      yield put(goBack());
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クレジットカードの変更に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
