import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { userTouchedChangeShopFavoriteStateButton } from "../../../modules/search/actions";
import { HomeSliderListType } from "../../../records/HomeList";
import ShopForSearch from "../../../records/ShopForSearch";
import Analytics, { AnalyticsEventName } from "../../../util/Analytics";
import Grid from "../../atoms/Grid";
import Tag from "../../atoms/Tag";
import ShopFavoriteButton from "../ShopFavoriteButton";
import ShopCaption from "./ShopCaption";
import ShopImage from "./ShopImage";
import ShopName from "./ShopName";

const ShopImageContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const TagContainer = styled.div`
  height: 15px;
  margin-top: -5px;
  margin-bottom: 5px;
`;

interface IProps {
  item: ShopForSearch;
  type: HomeSliderListType;
}

const getAnalyticsEventNameByType = (
  type: HomeSliderListType
): AnalyticsEventName => {
  switch (type) {
    case "shop_favorite_list":
      return "home_blk_like";
    case "shop_popular_list":
      return "home_blk_pop";
    case "shop_luxury_list":
      return "home_blk_luxury";
    case "shop_topic_list":
      return "home_blk_topic";
    default:
      return "";
  }
};

const ShopSliderItemViewer: React.FC<IProps> = React.memo(({ item, type }) => {
  const dispatch = useDispatch();

  const handleClickFavoriteButton = useCallback(() => {
    if (item.isFavorite()) {
      Analytics.logEvent("home_like_cancel");
    } else {
      Analytics.logEvent("home_like");
    }
    dispatch(
      userTouchedChangeShopFavoriteStateButton(item.getId(), !item.isFavorite())
    );
  }, [dispatch, item]);

  const handleClick = useCallback(() => {
    Analytics.logEvent(getAnalyticsEventNameByType(type));
    dispatch(push(`/shop/${item.getId()}`));
  }, [dispatch, item, type]);

  return (
    <div>
      <TagContainer>
        {item.existsTag() && <Tag tagType={item.getTag()} />}
      </TagContainer>
      <Grid container direction="row" align="center" justify="space-between">
        <ShopCaption>{item.getDescription()}</ShopCaption>
        <ShopFavoriteButton
          active={item.isFavorite()}
          handleClick={handleClickFavoriteButton}
        />
      </Grid>
      <div onClick={handleClick}>
        <ShopName>{item.getShopName()}</ShopName>

        <ShopImageContainer>
          <ShopImage shop={item} />
        </ShopImageContainer>

        {/*
        <Grid container direction="row" align="center" justify="flex-start">
          <ShopCookingTimeInfo item={item} />
          {item.canDisplayReviewPoint() ? (
            <ShopReviewPoint item={item} />
          ) : null}
        </Grid>
        */}
      </div>
    </div>
  );
});

export default ShopSliderItemViewer;
