import * as React from "react";
import styled from "styled-components";
import CloseIconButton from "../molecules/CloseIconButton";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 23px 16px 29px;
  border-radius: 4px;
  background-color: #ffffff;
`;

const DialogHeader = styled.div`
  flex: 0 0 auto;
  margin-bottom: 18px;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #000000;
`;

const DialogBody = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 23px;
`;

const DialogFooter = styled.div`
  flex: 0 0 auto;
  margin-top: 25px;
`;

const CloseIconButtonContainer = styled.div`
  position: absolute;
  top: -11px;
  right: -14px;
`;

interface IProps {
  handleCloseButton?: (event: React.MouseEvent<HTMLElement>) => void;
  [other: string]: any;
}

const Dialog: React.FC<IProps> = React.memo(props => {
  const { handleCloseButton, children, ...rest } = props;
  return (
    <Container {...rest}>
      {handleCloseButton && (
        <CloseIconButtonContainer onClick={handleCloseButton}>
          <CloseIconButton />
        </CloseIconButtonContainer>
      )}
      {children}
    </Container>
  );
});

export default Dialog;
export { DialogHeader, DialogBody, DialogFooter };
