import { ActionTypes, OrderActions } from "./actions";
import OrderModel from "./model";

export default function reducer(
  model = new OrderModel(),
  action: OrderActions
): OrderModel {
  switch (action.type) {
    case ActionTypes.R_UPDATE_CART:
      return model.updateCartList(action.payload.cartList);
    case ActionTypes.R_UPDATE_ORDER_LIST:
      return model.updateOrderList(action.payload.orders);
    case ActionTypes.R_UPDATE_CURRENT_ORDER_DETAIL:
      return model.updateCurrentOrderDetail(action.payload.detail);
    case ActionTypes.R_INIT_PRE_ORDER:
      return model.initPreOrder(action.payload.initial);
    case ActionTypes.R_UPDATE_PRE_ORDER_OPTION_LIST:
      return model.setPreOrderOptionList(action.payload.optionList);
    case ActionTypes.R_UPDATE_PRE_ORDER_COUNT:
      return model.setPreOrderCount(action.payload.count);
    case ActionTypes.R_UPDATE_PRE_ORDER_TEXT:
      return model.setPreOrderInstructionText(action.payload.text);
    case ActionTypes.R_UPDATE_FEEDBACK_STATE:
      return model.updateFeedbackStateByOrderNumber(action.payload.orderNumber);
    case ActionTypes.R_UPDATE_FEEDBACK_POINT:
      return model.updateFeedbackPointByOrderNumber(
        action.payload.orderNumber,
        action.payload.point
      );
    case ActionTypes.R_UPDATE_FEEDBACK_TEXT:
      return model.updateFeedbackTextByOrderNumber(
        action.payload.orderNumber,
        action.payload.text
      );
    case ActionTypes.R_UPDATE_DRAFT_FEEDBACK_POINT:
      return model.updateDraftFeedbackPointByOrderNumber(
        action.payload.orderNumber,
        action.payload.point
      );
    case ActionTypes.R_UPDATE_DRAFT_FEEDBACK_TEXT:
      return model.updateDraftFeedbackTextByOrderNumber(
        action.payload.orderNumber,
        action.payload.text
      );
    case ActionTypes.R_UPDATE_ORDER_NUMBER_FOR_DIALOG:
      return model.updateOrderNumberForDialog(action.payload.orderNumber);
    case ActionTypes.R_UPDATE_ORDER_CART_ID:
      return model.setOrderCartId(action.payload.cartId);
    case ActionTypes.R_UPDATE_RESERVATION_TIME_FOR_DIALOG:
      return model.updateReservationTimeForDialog(
        action.payload.reservationTime
      );
    case ActionTypes.R_UPDATE_CART_ID_FOR_DIALOG:
      return model.updateCartIdForDialog(action.payload.cartId);
    case ActionTypes.R_UPDATE_ITEM_INDEX_FOR_DIALOG:
      return model.updateItemIndexForDialog(action.payload.index);
    case ActionTypes.R_UPDATE_SHOP_ITEM_FAVORITE_STATE:
      return model.updateShopItemFavoriteState(
        action.payload.shopItemId,
        action.payload.bool
      );
    case ActionTypes.R_UPDATE_PRE_ORDER_CART_ID:
      return model.updatePreOrderCartId(action.payload.cartId);
    case ActionTypes.R_UPDATE_PAYMENT_TYPE:
      return model.updatePaymentType(action.payload.paymentType);
    default:
      return model;
  }
}
