import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: string;
}

const SvgVector = (props: IProps) => {
  const { color, ...rest } = props;
  const fill = color ? color : "#000";
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 18" fill="none" {...rest}>
      <path
        d="M1 1L9 9L1 17"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ChevronWideIcon = (props: IProps) => <SvgVector {...props} />;

export default ChevronWideIcon;
