import { ellipsis } from "polished";
import React, { useMemo } from "react";
import styled from "styled-components";
import ShopData from "../../records/ShopData";
import { EPaymentType } from "../../records/PaymentType";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";

const SubmitOrderButtonContainer = styled.div<{
  isStatic?: boolean;
  zIndex?: number;
}>`
  position: sticky;
  ${p => (p.isStatic ? "" : "width: 100%;")}
  left: 0;
  bottom: 0;
  ${p => (p.zIndex ? `z-index: ${100 + p.zIndex};` : "")}
  padding: 0 ${p => (p.isStatic ? "18px" : "0")};
  ${p =>
    p.isStatic
      ? ""
      : `
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);`}
  background: #ffffff;
`;

const SubmitOrderButton = styled(Button).attrs({ block: true })`
  position: relative;
  margin-top: 0;
  padding: 8px 18px;
  text-align: center;
  border-radius: 0;
`;

const SubmitOrderButtonText = styled(Grid).attrs({
  container: true,
  align: "center",
  justify: "center"
})`
  width: 60%;
  margin: 0 auto;
`;

const SubmitOrderButtonShopName = styled.div`
  padding-right: 5px;
  text-align: right;
  font-size: 13px;
  line-height: 19px;
  ${ellipsis()}
`;

const SubmitOrderButtonTextSuffix = styled.div``;

const SubmitOrderButtonConfirmText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;

interface IProps extends React.ButtonHTMLAttributes<HTMLElement> {
  shopData: ShopData;
  isStatic?: boolean;
  zIndex?: number;
  isSubmit?: boolean;
  currentPaymentType?: EPaymentType;
  submittingOrder: boolean;
  submittingClearCart?: boolean;
  handleClickButton: () => void;
}

const OrderButton: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    shopData,
    isStatic,
    zIndex,
    isSubmit = false,
    currentPaymentType,
    submittingOrder,
    submittingClearCart,
    handleClickButton,
    children,
    ...rest
  } = props;

  /**
   *  支払い方法がユーザー／セッションごとのため、選択中の支払い方法と
   *  店舗で可能な支払い方法が対応しているか判定
   */
  const canPayWithCurrentPaymentType = useMemo(() => {
    return isSubmit && typeof currentPaymentType !== "undefined"
      ? shopData.getPayment().canPay(currentPaymentType)
      : true;
  }, [shopData, isSubmit, currentPaymentType]);

  return (
    <SubmitOrderButtonContainer isStatic={isStatic} zIndex={zIndex}>
      <SubmitOrderButton
        onClick={handleClickButton}
        loading={submittingOrder || submittingClearCart}
        disabled={!shopData.canOrder() || !canPayWithCurrentPaymentType}
        {...rest}
      >
        <SubmitOrderButtonText>
          <SubmitOrderButtonShopName>
            {shopData.getShopName()}
          </SubmitOrderButtonShopName>
          <SubmitOrderButtonTextSuffix>の</SubmitOrderButtonTextSuffix>
        </SubmitOrderButtonText>
        <SubmitOrderButtonConfirmText>{children}</SubmitOrderButtonConfirmText>
      </SubmitOrderButton>
    </SubmitOrderButtonContainer>
  );
});

export default OrderButton;
