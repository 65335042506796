import styled from "styled-components";

const ThumbnailContainer = styled.div<{ size?: number; borderRadius?: number }>`
  position: relative;
  width: ${p => (p.size ? p.size : 110)}px;
  height: ${p => (p.size ? p.size : 110)}px;
  border-radius: ${p => (p.borderRadius ? p.borderRadius : 2)}px;
  overflow: hidden;
`;

export default ThumbnailContainer;
