import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgBag = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0h-9v3.387H0V15h16V3.387h-3.5V0zm-1 3.387V.967h-7v2.42h7zM15 7.761v6.271H1v-6.27L7 9.42v1.225h2V9.42l6-1.659zm-6-.02v.673l6-1.66v-2.4H1v2.4l6 1.66v-.672h2z"
      fill="#3A3A47"
    />
  </svg>
);

const WorkPlaceIcon: React.FC<IProps> = props => <SvgBag {...props} />;

export default WorkPlaceIcon;
