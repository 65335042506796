import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import {
  userSubmittedCancelOrder,
  userTouchedCloseDialogWithOrderNumber
} from "../../modules/order/actions";
import OrderData from "../../records/OrderData";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Dialog, { DialogBody, DialogFooter } from "../atoms/Dialog";
import Modal from "../molecules/Modal";
import FeedbackDialog from "./FeedbackDialog";

const cancelContentStyle: React.CSSProperties = {
  position: "absolute",
  bottom: "0",
  width: "100%"
};

const cancelInnerStyle: React.CSSProperties = {};

const cancelDialogStyle: React.CSSProperties = {
  borderRadius: "0"
};

const cancelDialogBodyStyle: React.CSSProperties = {
  fontSize: "2.4rem",
  lineHeight: "3.4rem",
  letterSpacing: "0.1rem"
};

const cancelDialogFooterStyle: React.CSSProperties = {
  display: "flex"
};

const cancelLeftButtonStyle: React.CSSProperties = { marginRight: "8px" };

interface IProps {
  receiptOrderNumber: string | null;
  orderData: List<OrderData>;
  openCancelOrderDialogFlag: boolean;
  openPushNotificationPermissionDialogFlag: boolean;
  submittingCancelOrder: boolean;
  actions: ActionDispatcher;
  children?: never;
}

const OrderHistoryModal: React.FC<IProps> = React.memo(props => {
  const {
    receiptOrderNumber,
    orderData,
    openCancelOrderDialogFlag,
    openPushNotificationPermissionDialogFlag,
    submittingCancelOrder,
    actions
  } = props;

  const currentReceiptData: OrderData = useMemo(() => {
    const selectedReciptDataList: List<OrderData> = orderData.filter(
      order => order.order_no === receiptOrderNumber
    );
    return selectedReciptDataList.get(0, new OrderData());
  }, [receiptOrderNumber]);

  const handleCloseCancelOrder = useCallback(() => {
    actions.userTouchedCloseCancelOrderDialogButton();
  }, [actions.userTouchedCloseCancelOrderDialogButton]);

  const handleSubmitCancelOrder = useCallback(
    (orderNumber: string) => {
      actions.userSubmittedCancelOrder(orderNumber);
    },
    [actions.userSubmittedCancelOrder]
  );

  return (
    <>
      <FeedbackDialog orderData={currentReceiptData} />

      <Modal
        isOpen={openCancelOrderDialogFlag}
        contentStyle={cancelContentStyle}
        innerStyle={cancelInnerStyle}
        handleClickBackdrop={handleCloseCancelOrder}
      >
        <Dialog style={cancelDialogStyle}>
          <DialogBody style={cancelDialogBodyStyle}>
            本当に注文をキャンセルしますか？
          </DialogBody>
          <DialogFooter style={cancelDialogFooterStyle}>
            <Button
              block
              secondary
              positionFooter
              onClick={handleCloseCancelOrder}
              style={cancelLeftButtonStyle}
            >
              戻る
            </Button>
            <Button
              block
              loading={submittingCancelOrder}
              cancel
              positionFooter
              onClick={() =>
                handleSubmitCancelOrder(currentReceiptData.getOrderNumber())
              }
            >
              注文をキャンセル
            </Button>
          </DialogFooter>
        </Dialog>
      </Modal>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  receiptOrderNumber: state.order.getOrderNumberForDialog(),
  orderData: state.order.getOrderList(),
  openCancelOrderDialogFlag: state.app
    .getModalManager()
    .canDisplay("CANCEL_ORDER"),
  submittingCancelOrder: state.app.isConnectedApi(EApiKey.CANCEL_ORDER)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedCloseCancelOrderDialogButton() {
    this.dispatch(userTouchedCloseDialogWithOrderNumber(null, "CANCEL_ORDER"));
  }
  public userSubmittedCancelOrder(orderNumber: string) {
    this.dispatch(userSubmittedCancelOrder(orderNumber));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryModal);
