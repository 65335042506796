import { List, Record, Set } from "immutable";
import { EApiKey } from "../../apis/index";
import Alert, { IAlertProps } from "../../records/Alert";
import FlagManager, { EFlagKey } from "../../records/FlagManager";
import MaintenanceInfo, {
  IMaintenanceInfo
} from "../../records/MaintenanceInfo";
import ModalManager, {
  ModalKey,
  ModalPayload
} from "../../records/ModalManager";
import UpdateBuildPayload, {
  IUpdateBuildPayload
} from "../../records/UpdateBuildPayload";
import UpdateResourcePayload, {
  IUpdateResourcePayload
} from "../../records/UpdateResourcePayload";
import CordovaUtil from "../../util/CordovaUtil";
import LocalRepository from "../../util/LocalRepository";

declare var location: Location;

export const SHOULD_UPDATE_RESOURCE_COOKIE_KEY = "shouldUpdateBuild";
export const CURRENT_RESOURCE_VERSION_COOKIE_KEY = "currentBuildVersion";
export const LATEST_RESOURCE_VERSION_COOKIE_KEY = "latestBuildVersion";
export const INTERVAL_CHECK_UPDATE_RESOURCE = 60000;
export const PATH_LIST_THAT_CAN_UPDATE_RESOURCE = ["/", "/top"];

// FIXME: ごめんなさい、あとで整理して捨て（ます|てください）
export const NAVIGATION_PATH_HOME = "/";
export const NAVIGATION_PATH_FASTEST_ORDER = "/search/fastest";
export const NAVIGATION_PATH_RESERVE_ORDER = "/search/reserve";
export const NAVIGATION_PATH_HISTORY = "/history";
export const NAVIGATION_PATH_USER = "/user";
export type NavigationPath =
  | typeof NAVIGATION_PATH_HOME
  | typeof NAVIGATION_PATH_FASTEST_ORDER
  | typeof NAVIGATION_PATH_RESERVE_ORDER
  | typeof NAVIGATION_PATH_HISTORY
  | typeof NAVIGATION_PATH_USER;

export enum EProcessingFlag {
  SUBMIT_GUEST_LOGIN,
  SUBMIT_UPDATE_TEL,
  SUBMIT_REGISTER_CREDIT_CARD,
  SUBMIT_ADD_CART,
  SUBMIT_ORDER,
  FETCHING_TEXT_SEARCH_RESULT,
  FETCHING_ORDER_HISTORY,
  UPDATING_FAVORITE_STATE,
  FETCH_FASTEST_SEARCH,
  SEARCH_SETTING_AT_HOME,
  FETCHING_HOME_SHOP_LIST,
  FETCHING_HOME_FASTEST_SEARCH,
  FETCHING_HOME_RESERVE_SEARCH,
  FETCHING_INITIAL_SEARCH
}

export interface AppState {
  /**
   * アプリの起動が済んでいるか
   */
  launched: boolean;

  /**
   * ユーザーの操作を受け付ける準備ができているか
   */
  readyForUserOperation: boolean;

  /**
   * 通信中のapiの種別
   */
  connectingApiSet: List<EApiKey>;

  /**
   * リソースのアップデートを実施するべきか
   */
  shouldUpdateResource: boolean;

  /**
   * ユーザーがテキストを入力しているか
   * (OSの仮想キーボードなど、下からせりあがってくるUIが表示されているか)
   */
  isUserInputtingTextFlag: boolean;

  /**
   * ページ遷移のアニメーションを再生しているか
   */
  isPageTransitionAnimationPlayingFlag: boolean;

  /**
   * メンテナンス画面で表示される情報
   */
  maintenanceInfo: MaintenanceInfo;

  /**
   * アプリのビルド番号
   */
  appVersion: string | null;

  /**
   * モーダルの表示管理用ステート
   */
  modalManager: ModalManager;

  /**
   * 処理中フラグセット
   */
  processingFlagSet: Set<EProcessingFlag>;

  /**
   * フラグセット
   */
  flagManager: FlagManager;

  /**
   * ビルド更新情報
   */
  updateBuildPayload: UpdateBuildPayload;

  /**
   * リソース更新情報
   */
  updateResourcePayload: UpdateResourcePayload;
}

const initialState: AppState = {
  launched: false,
  readyForUserOperation: false,
  connectingApiSet: List<EApiKey>(),
  shouldUpdateResource: false,
  isUserInputtingTextFlag: false,
  isPageTransitionAnimationPlayingFlag: false,
  maintenanceInfo: new MaintenanceInfo(),
  appVersion: null,
  modalManager: new ModalManager(),
  processingFlagSet: Set<EProcessingFlag>(),
  flagManager: new FlagManager(),
  updateBuildPayload: new UpdateBuildPayload(),
  updateResourcePayload: new UpdateResourcePayload()
};

export default class AppModel extends Record(initialState) {
  public static getPlatform() {
    return CordovaUtil.getPlatform();
  }

  public static hardReloadIfShouldUpdateResource() {
    const hash = window.location.hash;
    if (hash === `#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`) {
      window.history.replaceState("", document.title, window.location.pathname);
      window.location.reload(true);
    }
  }

  public getUpdateBuildPayload() {
    return this.get("updateBuildPayload");
  }

  public setUpdateBuildPayload(payload: IUpdateBuildPayload) {
    return this.set("updateBuildPayload", new UpdateBuildPayload(payload));
  }

  public getUpdateResourcePayload() {
    return this.get("updateResourcePayload");
  }

  public setUpdateResourcePayload(payload: IUpdateResourcePayload) {
    return this.set(
      "updateResourcePayload",
      new UpdateResourcePayload(payload)
    );
  }

  public getResourceVersion() {
    try {
      const resourceVersion = parseInt(
        LocalRepository.getItem(CURRENT_RESOURCE_VERSION_COOKIE_KEY),
        10
      );
      return isNaN(resourceVersion) ? 0 : resourceVersion;
    } catch {
      return 0;
    }
  }

  public getProcessingFlagSet() {
    return this.get("processingFlagSet");
  }

  public isProcessing(key: EProcessingFlag) {
    return this.getProcessingFlagSet().includes(key);
  }

  public setProcessingFlag(key: EProcessingFlag) {
    return this.set("processingFlagSet", this.getProcessingFlagSet().add(key));
  }

  public removeProcessingFlag(key: EProcessingFlag) {
    return this.set(
      "processingFlagSet",
      this.getProcessingFlagSet().filter(item => item !== key)
    );
  }

  public getFlagManager() {
    return this.get("flagManager");
  }

  public setFlag(key: EFlagKey | EFlagKey[]) {
    return this.set("flagManager", this.getFlagManager().setFlag(key));
  }

  public unsetFlag(key: EFlagKey | EFlagKey[]) {
    return this.set("flagManager", this.getFlagManager().unsetFlag(key));
  }

  /**
   * allがtureの場合はevery、falseの場合はsomeで判定される
   */
  public isEnableFlag(key: EFlagKey | EFlagKey[], all: boolean = false) {
    const m = this.getFlagManager();
    return Array.isArray(key)
      ? all
        ? m.allEnabled(key)
        : m.someEnabled(key)
      : m.enabled(key);
  }

  public getModalManager() {
    return this.get("modalManager");
  }

  public addModal<K extends ModalKey>(key: K, payload: ModalPayload<K>) {
    return this.set(
      "modalManager",
      this.getModalManager().pushKey(key, payload)
    );
  }

  public removeModal(key: ModalKey) {
    return this.set("modalManager", this.getModalManager().removeKey(key));
  }

  public getAppVersion() {
    return this.get("appVersion");
  }

  public setAppVersion(version: string | null) {
    return this.set("appVersion", version);
  }

  public getMaintenanceInfo() {
    return this.get("maintenanceInfo");
  }

  public setMaintenanceInfo(maintenanceInfo: IMaintenanceInfo) {
    return this.set("maintenanceInfo", new MaintenanceInfo(maintenanceInfo));
  }

  public isUserInputtingText() {
    return this.get("isUserInputtingTextFlag");
  }

  public updateUserInputtingTextFlag(bool: boolean) {
    return this.set("isUserInputtingTextFlag", bool);
  }

  public isPageTransitionAnimationPlaying() {
    return this.get("isPageTransitionAnimationPlayingFlag");
  }

  public startPlayingPageTransitionAnimation() {
    return this.set("isPageTransitionAnimationPlayingFlag", true);
  }

  public finishPlayingPageTransitionAnimation() {
    return this.set("isPageTransitionAnimationPlayingFlag", false);
  }

  public setShouldUpdateResource() {
    return this.set("shouldUpdateResource", true);
  }

  public updateResourceManually() {
    const latestResourceVersion = this.getUpdateResourcePayload().getLatestUpdateTime();
    if (latestResourceVersion) {
      LocalRepository.setItem(
        CURRENT_RESOURCE_VERSION_COOKIE_KEY,
        String(latestResourceVersion)
      );
    }
    if (location.pathname === "/") {
      location.reload(true);
    } else {
      location.href = `/#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`;
    }
  }

  /**
   * @deprecated
   * @see this.updateResourceManually
   */
  public updateResource() {
    LocalRepository.removeItem(SHOULD_UPDATE_RESOURCE_COOKIE_KEY);
    if (LocalRepository.hasItem(LATEST_RESOURCE_VERSION_COOKIE_KEY)) {
      LocalRepository.setItem(
        CURRENT_RESOURCE_VERSION_COOKIE_KEY,
        LocalRepository.getItem(LATEST_RESOURCE_VERSION_COOKIE_KEY)
      );
      LocalRepository.removeItem(LATEST_RESOURCE_VERSION_COOKIE_KEY);
    }
    if (location.pathname === "/") {
      location.reload(true);
    } else {
      location.href = `/#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`;
    }
  }

  public startConnect(apiKey: EApiKey) {
    return this.set(
      "connectingApiSet",
      List<EApiKey>(this.getConnectingApiSet().push(apiKey))
    );
  }

  public finishConnect(apiKey: EApiKey) {
    return this.set(
      "connectingApiSet",
      this.getConnectingApiSet().filterNot(key => key === apiKey)
    );
  }

  public isConnectedApi(apiKey: EApiKey | EApiKey[]) {
    if (Array.isArray(apiKey)) {
      return !List(apiKey)
        .filter(key => this.getConnectingApiSet().includes(key))
        .isEmpty();
    }
    return this.getConnectingApiSet().includes(apiKey);
  }

  public isConnectedAnyApi() {
    return !this.getConnectingApiSet().isEmpty();
  }

  public getConnectingApiSet(): List<EApiKey> {
    return this.get("connectingApiSet");
  }

  public launch() {
    return this.set("launched", true);
  }

  public isLaunched(): boolean {
    return this.get("launched");
  }

  public completeToReadyForUserOperation() {
    return this.set("readyForUserOperation", true);
  }

  public completedToReadyForUserOperation() {
    return this.get("readyForUserOperation");
  }

  public createAlert(alertProps: IAlertProps): Alert {
    return Alert.create(alertProps);
  }

  public removeAlert(id: string) {
    return this.set("modalManager", this.getModalManager().removeById(id));
  }
}
