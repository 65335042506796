import { List, Record } from "immutable";
import moment from "moment";
import Utility from "../util/Utility";

export interface IFeatureItem {
  id: number;
  name: string;
  title: string;
  description_text: string;
  image_file_path: string;
  weight: number;
  start_datetime: string;
  end_datetime: string;
}

const initialValues: IFeatureItem = {
  id: 0,
  name: "",
  title: "",
  description_text: "",
  image_file_path: "",
  weight: 0,
  start_datetime: "",
  end_datetime: ""
};

const ITEM_ID_REQUEST_FOR_REVIEW = -1;
const FEATURE_ITEM_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default class FeatureItem extends Record(initialValues) {
  /**
   * レビューされていない注文が一件でもあったら差し込まれる特殊バナー
   */
  public static asRequestForReview() {
    return new FeatureItem({
      id: ITEM_ID_REQUEST_FOR_REVIEW,
      name: "レビューのお願い",
      image_file_path: "image/banner_request_for_review_b.png",
      start_datetime: moment().format(FEATURE_ITEM_DATE_FORMAT),
      end_datetime: moment()
        .add(1, "day")
        .format(FEATURE_ITEM_DATE_FORMAT),
      weight: 1
    });
  }

  public static sortByWeight(valueA: FeatureItem, valueB: FeatureItem) {
    const [a, b] = [valueA.getWeight(), valueB.getWeight()];
    return a === b ? 0 : a < b ? 1 : -1;
  }

  public static filteredList(list: List<FeatureItem>) {
    return list.filter(i => i.canDisplay());
  }

  public canDisplay() {
    const [begin, end] = [
      moment(this.getStartDateTime(), FEATURE_ITEM_DATE_FORMAT),
      moment(this.getEndDateTime(), FEATURE_ITEM_DATE_FORMAT)
    ];
    return moment().isSameOrAfter(begin) && moment().isSameOrBefore(end);
  }

  public getId(): number {
    return this.get("id");
  }

  public getImageFilePath(): string {
    const file = this.get("image_file_path");
    if (file.slice(0, 5) === "image") {
      return `/${file}`;
    }
    return `${Utility.getImageDomain()}/${file}`;
  }

  public getLink() {
    if (this.getId() === ITEM_ID_REQUEST_FOR_REVIEW) {
      return "/history";
    }
    return `/feature/${this.getId()}`;
  }

  public getName(): string {
    return this.get("name");
  }

  public getTitle(): string {
    return this.get("title");
  }

  public getDescriptionText(): string {
    return this.get("description_text");
  }

  public getWeight(): number {
    return this.get("weight");
  }

  public getStartDateTime(): string {
    return this.get("start_datetime");
  }

  public getEndDateTime(): string {
    return this.get("end_datetime");
  }
}
