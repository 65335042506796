import React, { CSSProperties } from "react";

const defaultLineStyle: React.CSSProperties = {
  wordBreak: "break-all"
};

interface IProps {
  lineStyle?: CSSProperties;
  children: string;
}

const MultiLine: React.FC<IProps> = React.memo(props => {
  const { lineStyle, children } = props;
  return (
    <>
      {children.split("\n").map((line, index) =>
        line.trim() === "" ? (
          <br key={index} />
        ) : (
          <div key={index} style={{ ...defaultLineStyle, ...lineStyle }}>
            {line}
          </div>
        )
      )}
    </>
  );
});

export default MultiLine;
