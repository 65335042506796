import { call, cancel, put, select } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import Geolocation, { EPositionError } from "../../../util/Geolocation";
import { systemAddedAlert } from "../../app/actions";
import {
  systemDetectedPositionError,
  updateFetchingCurrentLocationFlag,
  updateLocation
} from "../actions/index";

export default function* fetchCurrentLocationSaga() {
  if (!window.navigator.geolocation) {
    yield cancel();
  }

  try {
    yield put(updateFetchingCurrentLocationFlag(true));

    const {
      location
    }: {
      location: Position;
    } = yield call(Geolocation.getCurrentLocation);

    const currentLocation = {
      lat: location.coords.latitude,
      lng: location.coords.longitude
    };

    yield put(systemDetectedPositionError(null));
    yield put(updateLocation(currentLocation));
  } catch (exception) {
    // エラー形式が不正の場合は不明なエラー扱いにし、処理をキャンセルする
    if (!("error" in exception && "code" in exception.error)) {
      yield put(systemDetectedPositionError(EPositionError.UNKNOWN));
      console.log("位置情報取得#不明なエラー", { exception });
      yield cancel();
    }

    const { error } = exception;

    // すでに権限エラー発生済みで再発した場合にダイアログを表示する
    if (error.code === EPositionError.PERMISSION_DENIED) {
      const status: EPositionError | null = yield select<
        (state: ReduxModel) => EPositionError | null
      >(state => state.search.getCurrentLocationInquiryStatus());
      if (status === EPositionError.PERMISSION_DENIED) {
        yield put(
          systemAddedAlert({
            type: AlertType.Danger,
            title: "取得エラー",
            message:
              "現在地を取得できませんでした。\n位置情報の利用を許可して下さい。"
          })
        );
      }
    }

    switch (error.code) {
      case EPositionError.PERMISSION_DENIED:
      case EPositionError.POSITION_UNAVAILABLE:
      case EPositionError.TIMEOUT:
        yield put(systemDetectedPositionError(error.code));
        break;
      default:
        yield put(systemDetectedPositionError(EPositionError.UNKNOWN));
        console.log("位置情報取得#不明なエラー", { error });
    }
  } finally {
    yield put(updateFetchingCurrentLocationFlag(false));
  }
}
