import { List } from "immutable";
import { goBack } from "connected-react-router";
import React, { useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import styled from "styled-components";
import { userAccessedToPageThatNeedsAreaShopListPage } from "../../modules/search/actions";
import { EApiKey } from "../../apis";
import colorsConst from "../../styles/const/colorsConst";
import SubArea from "../../records/SubArea";
import ShopForSearch from "../../records/ShopForSearch";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";
import ListSearchResult from "../organisms/ListSearchResult";
import Auth from "../organisms/Auth";

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AreaSubTemplate: React.FC<IProps> = React.memo(props => {
  const {
    areaId,
    subAreaId,
    currentSubArea,
    currentAreaShopList,
    fetchingResult,
    actions
  } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  useEffect(() => {
    actions.userAccessedToPageThatNeedsAreaShopListPage(areaId, subAreaId);
  }, [actions, areaId, subAreaId]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title={
              !fetchingResult && currentSubArea.getName() !== ""
                ? currentSubArea.getName()
                : " "
            }
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody style={{ padding: 0 }}>
          {fetchingResult ? (
            <BlankContainer>
              <Loading />
            </BlankContainer>
          ) : (
            <ListSearchResult
              shopList={currentAreaShopList}
              resultType={"keyword"}
            />
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ id: string; subAreaId: string }>
) => ({
  areaId: parseInt(ownProps.match.params.id, 10),
  subAreaId: parseInt(ownProps.match.params.subAreaId, 10),
  currentSubArea: state.search.getCurrentSubArea(),
  currentAreaShopList: state.search.getCurrentAreaShopList(),
  fetchingResult: state.app.isConnectedApi(EApiKey.GET_SHOP_LIST_WITH_AREA)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public userAccessedToPageThatNeedsAreaShopListPage(
    areaId: number,
    subAreaId: number
  ) {
    this.dispatch(
      userAccessedToPageThatNeedsAreaShopListPage(areaId, subAreaId)
    );
  }

  public goBack() {
    this.dispatch(goBack());
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AreaSubTemplate)
);
