import { Action } from "redux";
import { ActionTypes } from ".";
import { EApiKey } from "../../../apis";
import { IAlertProps } from "../../../records/Alert";
import { EFlagKey } from "../../../records/FlagManager";
import { ModalKey, ModalPayload } from "../../../records/ModalManager";

interface UserTouchedGrantGeolocationPermissionButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_GRANT_GEOLOCATION_PERMISSION_BUTTON;
}
export const userTouchedGrantGeolocationPermissionButton = (): UserTouchedGrantGeolocationPermissionButtonAction => ({
  type: ActionTypes.U_TOUCHED_GRANT_GEOLOCATION_PERMISSION_BUTTON
});

export interface UserStartedInputtingTextAction extends Action {
  type: ActionTypes.U_STARTED_INPUTTING_TEXT;
}
export const userStartedInputtingText = () => ({
  type: ActionTypes.U_STARTED_INPUTTING_TEXT
});

export interface UserFinishedInputtingTextAction extends Action {
  type: ActionTypes.U_FINISHED_INPUTTING_TEXT;
}
export const userFinishedInputtingText = () => ({
  type: ActionTypes.U_FINISHED_INPUTTING_TEXT
});

export interface SystemAddedAlertAction extends Action {
  type: ActionTypes.S_ADDED_ALERT;
  payload: {
    alertProps: IAlertProps;
  };
}
export const systemAddedAlert = (alertProps: IAlertProps) => ({
  type: ActionTypes.S_ADDED_ALERT,
  payload: {
    alertProps
  }
});

export interface SystemRemovedAlertAction extends Action {
  type: ActionTypes.S_REMOVED_ALERT;
  payload: {
    id: string;
  };
}
export const systemRemovedAlert = (id: string) => ({
  type: ActionTypes.S_REMOVED_ALERT,
  payload: {
    id
  }
});

export interface SystemOpenedModalAction extends Action {
  type: ActionTypes.S_OPENED_MODAL;
  payload: {
    modalKey: ModalKey;
    modalPayload: ModalPayload<ModalKey>;
  };
}
export const systemOpenedModal = <T extends ModalKey>(
  modalKey: T,
  modalPayload: ModalPayload<T>
) => ({
  type: ActionTypes.S_OPENED_MODAL,
  payload: {
    modalKey,
    modalPayload
  }
});

export interface SystemClosedModalAction extends Action {
  type: ActionTypes.S_CLOSED_MODAL;
  payload: {
    modalKey: ModalKey;
  };
}
export const systemClosedModal = (modalKey: ModalKey) => ({
  type: ActionTypes.S_CLOSED_MODAL,
  payload: {
    modalKey
  }
});

export interface SystemStartedPlayingPageTransitionAnimationAction
  extends Action {
  type: ActionTypes.S_STARTED_PLAYING_PAGE_TRANSITION_ANIMATION;
}
export const systemStartedPlayingPageTransitionAnimation = () => ({
  type: ActionTypes.S_STARTED_PLAYING_PAGE_TRANSITION_ANIMATION
});

export interface SystemFinishedPlayingPageTransitionAnimationAction
  extends Action {
  type: ActionTypes.S_FINISHED_PLAYING_PAGE_TRANSITION_ANIMATION;
}
export const systemFinishedPlayingPageTransitionAnimation = () => ({
  type: ActionTypes.S_FINISHED_PLAYING_PAGE_TRANSITION_ANIMATION
});

export interface SystemDetectedResourceUpdateAction extends Action {
  type: ActionTypes.S_DETECTED_RESOURCE_UPDATE;
}
export const systemDetectedResourceUpdate = (): SystemDetectedResourceUpdateAction => ({
  type: ActionTypes.S_DETECTED_RESOURCE_UPDATE
});

export interface SystemLaunchedAction extends Action {
  type: ActionTypes.S_LAUNCHED;
}
export const systemLaunched = (): SystemLaunchedAction => ({
  type: ActionTypes.S_LAUNCHED
});

export interface SystemStartedConnectApiAction extends Action {
  type: ActionTypes.S_STARTED_CONNECT_API;
  payload: {
    apiKey: EApiKey;
  };
}
export const systemStartedConnectApi = (
  apiKey: EApiKey
): SystemStartedConnectApiAction => ({
  type: ActionTypes.S_STARTED_CONNECT_API,
  payload: {
    apiKey
  }
});

export interface SystemFinishedConnectApiAction extends Action {
  type: ActionTypes.S_FINISHED_CONNECT_API;
  payload: {
    apiKey: EApiKey;
  };
}
export const systemFinishedConnectApi = (
  apiKey: EApiKey
): SystemFinishedConnectApiAction => ({
  type: ActionTypes.S_FINISHED_CONNECT_API,
  payload: {
    apiKey
  }
});

export interface SystemReadyForUserOperationAction extends Action {
  type: ActionTypes.S_READY_FOR_USER_OPERATION;
}
export const systemReadyForUserOperation = (): SystemReadyForUserOperationAction => ({
  type: ActionTypes.S_READY_FOR_USER_OPERATION
});

export interface SystemSetFlagAction extends Action {
  type: ActionTypes.S_SET_FLAG;
  payload: {
    key: EFlagKey | EFlagKey[];
  };
}
export const systemSetFlag = (
  key: EFlagKey | EFlagKey[]
): SystemSetFlagAction => ({
  type: ActionTypes.S_SET_FLAG,
  payload: {
    key
  }
});

export interface SystemUnsetFlagAction extends Action {
  type: ActionTypes.S_UNSET_FLAG;
  payload: {
    key: EFlagKey | EFlagKey[];
  };
}
export const systemUnsetFlag = (
  key: EFlagKey | EFlagKey[]
): SystemUnsetFlagAction => ({
  type: ActionTypes.S_UNSET_FLAG,
  payload: {
    key
  }
});

export type UserAndSystemActions =
  | SystemSetFlagAction
  | SystemUnsetFlagAction
  | UserTouchedGrantGeolocationPermissionButtonAction
  | UserStartedInputtingTextAction
  | UserFinishedInputtingTextAction
  | SystemAddedAlertAction
  | SystemRemovedAlertAction
  | SystemOpenedModalAction
  | SystemClosedModalAction
  | SystemStartedPlayingPageTransitionAnimationAction
  | SystemFinishedPlayingPageTransitionAnimationAction
  | SystemDetectedResourceUpdateAction
  | SystemStartedConnectApiAction
  | SystemFinishedConnectApiAction
  | SystemLaunchedAction
  | SystemReadyForUserOperationAction;
