import { put, select } from "redux-saga/effects";
import { ReduxModel } from "../../../reducer";
import { systemClosedModal } from "../../app/actions";
import { userSubmittedOrder } from "../actions";

export default function* userConfirmedOrderSaga() {
  const cartId: string = yield select<(state: ReduxModel) => string>(state =>
    state.order.getPreOrderCartId()
  );
  yield put(systemClosedModal("CONFIRM_ORDER"));
  yield put(userSubmittedOrder(cartId));
}
