import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgFooterMap = (props: IProps) => {
  const { fill, ...rest } = props;
  const fillColor = fill ? fill : "inherit";
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 28" {...rest}>
      <path
        d="M11.067 1.977c4.937.072 8.917 4.033 8.917 8.903 0 6.036-5.795 14.88-8.917 15.143h-.14c-3.122-.264-8.917-9.1-8.917-15.143 0-4.87 3.979-8.83 8.916-8.903h.141zM11.087 0h-.154C4.89.092 0 4.975 0 10.88 0 17.7 6.23 27.61 10.759 27.993l.087.007h.315l.087-.007C15.77 27.611 22.007 17.7 22.007 10.88c0-5.911-4.89-10.788-10.9-10.88h-.02z"
        fill={fillColor}
      />
      <path
        d="M11 7.427c1.822 0 3.296 1.45 3.296 3.242S12.822 13.91 11 13.91c-1.822 0-3.296-1.45-3.296-3.242C7.697 8.883 9.178 7.427 11 7.427zm0-1.977c-2.927 0-5.306 2.34-5.306 5.219 0 2.88 2.379 5.219 5.306 5.219 2.928 0 5.306-2.34 5.306-5.22 0-2.879-2.378-5.218-5.306-5.218z"
        fill={fillColor}
      />
    </svg>
  );
};

const MapPinIcon: React.FC<IProps> = React.memo(props => (
  <SvgFooterMap {...props} />
));

export default MapPinIcon;
