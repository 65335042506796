import { goBack } from "connected-react-router";
import { Set } from "immutable";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import ChevronNarrowIcon from "../atoms/ChevronNarrowIcon";
import MultiLine from "../atoms/MultiLine";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ListLink from "../molecules/ListLink";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";

const FAQPageBody = styled(PageBody)`
  padding: 0;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const FAQNotes = styled(Notes)`
  font-size: 1.3rem;
  text-align: justify;
`;

const LoadingContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const TopicList = styled.ul``;

const TopicContainer = styled.li`
  position: relative;
  padding: 20px;
  padding-right: 40px;
  border-bottom: 1px solid #cccccc;
  background-color: ${colorsConst.BACKGROUND};
`;

const TopicItemBody = styled.div`
  padding-top: 24px;
`;

const TopicItemChevron = styled.div<{ open: boolean }>`
  position: absolute;
  top: 19px;
  right: 20px
  transform: ${p => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: transform 0.25s ease;
`;

const TopicItem: React.FC<{
  data: { title: string; body: string };
  index: number;
  open: boolean;
  handleClick: (index: number) => void;
}> = React.memo(props => {
  const { data, open, index } = props;

  const handleClick = useCallback(() => {
    props.handleClick(index);
  }, [props.handleClick, index]);

  return (
    <TopicContainer onClick={handleClick}>
      <div>Q.{data.title}</div>
      {open && (
        <TopicItemBody>
          <FAQNotes>
            <MultiLine>{data.body}</MultiLine>
          </FAQNotes>
        </TopicItemBody>
      )}
      <TopicItemChevron open={open}>
        <ChevronNarrowIcon width="8px" />
      </TopicItemChevron>
    </TopicContainer>
  );
});

interface IFAQData {
  title: string;
  body: string;
}

interface IProps {
  actions: ActionDispatcher;
}

const UserFAQTemplate: React.FC<IProps> = React.memo(props => {
  const { actions } = props;

  const [fAQData, setFAQData] = useState<IFAQData[] | null>(null);
  const [activeTopic, setActiveTopic] = useState<Set<number>>(Set());

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  const fetchDataJson = useCallback(() => {
    return new Promise<IFAQData[]>(async (resolve, reject) => {
      try {
        const response = await fetch("/data/faq.json");
        resolve(await response.json());
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const isActiveIndex = useCallback(
    (index: number) => {
      return activeTopic.has(index);
    },
    [activeTopic]
  );

  const handleClickTopic = useCallback((index: number) => {
    setActiveTopic(set =>
      set.has(index) ? set.delete(index) : set.add(index)
    );
  }, []);

  useEffect(() => {
    fetchDataJson().then((receivedData: IFAQData[]) =>
      setFAQData(receivedData)
    );
  }, []);

  return (
    <Page
      header={
        <SubPageHeader title="よくある質問" handleClickBack={handleGoBack} />
      }
    >
      <FAQPageBody>
        {fAQData === null ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <TopicList>
            {fAQData.map((faq, index) => (
              <TopicItem
                key={index}
                index={index}
                data={faq}
                open={isActiveIndex(index)}
                handleClick={handleClickTopic}
              />
            ))}
            <ListLink link={"/user/inquiry"}>お問い合わせ(メール)</ListLink>
          </TopicList>
        )}
      </FAQPageBody>
    </Page>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFAQTemplate);
