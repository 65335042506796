import { replace } from "connected-react-router";
import { cancel, fork, put, select, spawn } from "redux-saga/effects";
import { AlertType, IAlertProps } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { systemRequestedFetchCartList } from "../../order/actions";
import { resetUser, updateLoginToken } from "../../user/actions";
import { systemAddedAlert, systemReadyForUserOperation } from "../actions";
import AppModel from "../model";

// 320~は汎用エラー
// 321~は個別エラー
export enum EErrorCode {
  ALREADY_EXISTS_EXCEPTION = -32007,
  RUNTIME = -32099,

  INVALID_TOKEN = -32100,
  UPDATE_CREDIT_CARD_INFO = -32101,
  EXECUTE_CREDIT_CARD_INFO = -32102,
  USER_NOT_FOUND = -32103,
  LOGIN_FAILED = -32104,
  SEND_MAIL = -32105,
  CODE_AUTH_FAILED = -32106,
  ORDER_STOP = -32107,
  REGISTER_ID = -32108,
  ORDER_EXPIRE = -32110,
  ADD_CART_LIMIT = -32112,
  CREDIT_CARD_INPUT_LIMIT = -32113,
  ORDER_EXCLUSION_CONTROL = -32114,
  OUT_OF_RECEPTION_TIME_EXCEPTION = -32115,
  SOLD_OUT_ITEM = -32116,
  DISPLAY_DIFFERENT_PRICE = -32118,
  SHOP_ITEM_LIMIT_OVER_EXCEPTION = -32119,
  ORDER_VALIDATION_EXCEPTION = -32120,
  MAIL_ADDRESS_NOT_REGISTERED_EXCEPTION = -32121,
  NOT_EXIST_PHONE_NUMBER_EXCEPTION = -32122
}

export default function* commonApiFailedSaga(response: Response) {
  try {
    const data = yield response.json();
    const error = data.error;
    if (Array.isArray(error)) {
      for (const errorItem of error) {
        yield spawn(generateAlertSaga, errorItem);
      }
    } else {
      yield spawn(generateAlertSaga, error);
    }
    yield cancel();
  } catch (e) {
    // エラー時の処理
  }
}

function* generateAlertSaga(error: any) {
  switch (error.code) {
    case EErrorCode.ALREADY_EXISTS_EXCEPTION:
    case EErrorCode.RUNTIME:
    case EErrorCode.UPDATE_CREDIT_CARD_INFO:
    case EErrorCode.EXECUTE_CREDIT_CARD_INFO:
    case EErrorCode.LOGIN_FAILED:
    case EErrorCode.SEND_MAIL:
    case EErrorCode.CODE_AUTH_FAILED:
    case EErrorCode.ORDER_STOP:
    case EErrorCode.REGISTER_ID:
    case EErrorCode.ORDER_EXPIRE:
    case EErrorCode.ADD_CART_LIMIT:
    case EErrorCode.CREDIT_CARD_INPUT_LIMIT:
    case EErrorCode.ORDER_EXCLUSION_CONTROL:
    case EErrorCode.OUT_OF_RECEPTION_TIME_EXCEPTION:
    case EErrorCode.SOLD_OUT_ITEM:
    case EErrorCode.DISPLAY_DIFFERENT_PRICE:
    case EErrorCode.ORDER_VALIDATION_EXCEPTION:
    case EErrorCode.MAIL_ADDRESS_NOT_REGISTERED_EXCEPTION:
    case EErrorCode.NOT_EXIST_PHONE_NUMBER_EXCEPTION:
      yield fork(() => generateAlertDialogSaga(error, AlertType.Danger));
      break;
    case EErrorCode.SHOP_ITEM_LIMIT_OVER_EXCEPTION:
      yield fork(() =>
        generateAlertDialogSaga(error, AlertType.WarningOverFavorite)
      );
      break;
    case EErrorCode.INVALID_TOKEN:
    case EErrorCode.USER_NOT_FOUND:
      yield fork(() => generateAlertAndRedirectSaga(error));
      break;

    default:
      yield fork(() => generateUnknownErrorAlertAndRedireactSaga(error));
  }
}

// ダイアログを表示する処理
function* generateAlertDialogSaga(error: any, aleartType: AlertType) {
  const alert: IAlertProps = {
    type: aleartType
  };
  alert.title =
    typeof error.title !== "undefined" &&
    error.title !== null &&
    error.title !== ""
      ? error.title
      : "エラー";
  if (
    typeof error.message !== "undefined" &&
    error.message !== null &&
    error.message !== ""
  ) {
    alert.message = error.message;
  }
  yield put(systemAddedAlert(alert));
  if (error.code === EErrorCode.DISPLAY_DIFFERENT_PRICE) {
    yield put(systemRequestedFetchCartList());
  }
}

// リダイレクト後にダイアログを表示する処理
function* generateAlertAndRedirectSaga(error: any) {
  yield put(resetUser());
  yield put(updateLoginToken(null));
  yield put(replace("/login"));
  const errorAlert: IAlertProps = {
    type: AlertType.Danger
  };
  errorAlert.title =
    typeof error.title !== "undefined" &&
    error.title !== null &&
    error.title !== ""
      ? error.title
      : "エラー";
  errorAlert.message = `ログイン処理に失敗しました。
  再度ログインを行ってください。`;
  yield put(systemAddedAlert(errorAlert));

  const app: AppModel = yield select<(state: ReduxModel) => AppModel>(
    state => state.app
  );
  if (!app.completedToReadyForUserOperation()) {
    yield put(systemReadyForUserOperation());
  }
}

// 不明なエラーとして扱い、リダイレクト後にダイアログを表示する処理
function* generateUnknownErrorAlertAndRedireactSaga(error: any) {
  console.log({ error });
  const unknownAlert: IAlertProps = {
    type: AlertType.Danger
  };
  unknownAlert.title =
    typeof error.title !== "undefined" &&
    error.title !== null &&
    error.title !== ""
      ? error.title
      : "エラー";
  const errorCode = "code" in error ? error.code : "-";
  unknownAlert.message = `不明なエラーが発生しました。\n（code: ${errorCode}）`;
  yield put(replace("/"));
  yield put(systemAddedAlert(unknownAlert));
}
