import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { userTouchedGrantGeolocationPermissionButton } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Dialog, { DialogBody, DialogFooter } from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";
import { systemClosedModal } from "../../modules/app/actions";

const modalStyle: React.CSSProperties = {
  padding: "35px"
};

const DialogHead = styled.div`
  font-weight: bold;
  font-size: 2.3rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 10px;
  span {
    display: inline-block;
    white-space: nowrap;
  }
`;

interface IProps {
  isOpen: boolean;
  actions: ActionDispatcher;
}

const GeolocationPermissionDialog: React.FC<IProps> = React.memo(props => {
  const { isOpen, actions } = props;

  const handleClickClose = useCallback(() => {
    actions.userTouchedCloseModalButton();
  }, [actions]);

  return (
    <Modal
      isOpen={isOpen}
      innerStyle={modalStyle}
      handleClickBackdrop={handleClickClose}
    >
      <Dialog>
        <DialogBody>
          <img
            src="/image/reccomend_location.png"
            style={{ width: "100%", height: "auto", marginBottom: "10px" }}
          />
          <DialogHead>
            <span>位置情報をONにして</span>
            <span>周辺のお店を探そう</span>
          </DialogHead>

          <Notes>
            あなたの周辺エリアのお店探しや行き先案内が便利に！ぜひ位置情報をオンにしてお楽しみください。
          </Notes>
        </DialogBody>
        <DialogFooter>
          <Button block onClick={handleClickClose}>
            OK
          </Button>
        </DialogFooter>
      </Dialog>
    </Modal>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  isOpen: state.app
    .getModalManager()
    .canDisplay("REQUEST_GEOLOCATION_PERMISSION")
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedCloseModalButton() {
    this.dispatch(userTouchedGrantGeolocationPermissionButton());
    this.dispatch(systemClosedModal("REQUEST_GEOLOCATION_PERMISSION"));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeolocationPermissionDialog);
