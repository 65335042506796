import "babel-polyfill";
import "core-js/es/map";
import "core-js/es/set";
import "rc-slider/assets/index.css";
import "react-virtualized/styles.css";
import "whatwg-fetch";
// tslint:disable-next-line:ordered-imports
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import AppModel from "./modules/app/model";
import { unregister } from "./registerServiceWorker";
import rootRoute from "./route";
import configureStore from "./store";
import CordovaUtil from "./util/CordovaUtil";
import PushUtil from "./util/PushUtil";

smoothscroll.polyfill();

const startApp = () => {
  ReactDOM.render(
    <Provider store={configureStore()}>{rootRoute}</Provider>,
    document.getElementById("root") as HTMLElement
  );

  unregister();
};

// アップデートフラグが立っていたらブラウザをハードリロード
AppModel.hardReloadIfShouldUpdateResource();

if (CordovaUtil.isCordovaEnabled()) {
  CordovaUtil.initialize(() => {
    // push通知用のデバイストークンの保存
    // Routerを通過するとqueryStringが飛ぶのでここで事前に取得しておく
    PushUtil.setDeviceToken()
      .then(() => {
        startApp();
      })
      .catch(error => console.log({ error }));
  });
} else {
  startApp();
}
