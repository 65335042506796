import { Record } from "immutable";
import { SearchResultType } from "./SearchResult";
import { FastestSearchSetting, ReserveSearchSetting } from "./SearchSetting";

interface SearchSettingTypeMap {
  ["fastest"]: FastestSearchSetting;
  ["reserve"]: ReserveSearchSetting;
  ["keyword"]: FastestSearchSetting;
}

type ISearchSettings = { [key in SearchResultType]: SearchSettingTypeMap[key] };

const initial: ISearchSettings = {
  fastest: FastestSearchSetting.asDefault(),
  reserve: ReserveSearchSetting.asDefault(),
  keyword: FastestSearchSetting.asDefault()
};

export default class SearchSettings extends Record(initial) {
  public static asDefault() {
    return new SearchSettings();
  }

  private constructor(args: any = {}) {
    super(args);
  }

  public getData<T extends SearchResultType>(type: T) {
    return this.get(type);
  }

  public updateData<K extends SearchResultType>(
    type: K,
    data: SearchSettingTypeMap[K]
  ) {
    return this.set(type, data);
  }

  public initReserveData() {
    return this.set("reserve", this.get("reserve").getInitSetting());
  }

  public isConfigured<T extends SearchResultType>(type: T) {
    return (
      this.getData(type).getMaxDisplayableCookingTime() !== null ||
      !this.getData(type)
        .getFilterCategoryTagList()
        .isEmpty()
    );
  }
}
