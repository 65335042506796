import React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const Container = styled.div<{ invert: boolean }>`
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 1.4rem;
  background-color: ${colorsConst.BACKGROUND};
  border: 1px solid ${colorsConst.BORDER.LIGHT};
`;

const Input = styled.input<{ invert: boolean }>`
  display: block;
  width: 100%;
  background-color: ${p => (p.invert ? "inherit" : "")};
  caret-color: ${colorsConst.MAIN};
  ::placeholder {
    color: ${colorsConst.DISABLED};
  }
  :disabled {
    background-color: inherit;
  }
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  invert?: boolean;
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement | null) => void);
  outerStyle?: React.CSSProperties;
}

const TextInput: React.FC<IProps> = React.memo(props => {
  const { outerStyle, invert, inputRef, ...rest } = props;
  return (
    <Container style={outerStyle} invert={invert ? invert : false}>
      <Input {...rest} ref={inputRef} invert={invert ? invert : false} />
    </Container>
  );
});

export default TextInput;
