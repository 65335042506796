import { List } from "immutable";
import { fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdateCartListRequest,
  IUpdateCartListResponse
} from "../../../apis/updateCartList";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCart, UserChangedOrderItemQuantityAction } from "../actions";

export default function* userChangedOrderItemQuantitySaga(
  action: UserChangedOrderItemQuantityAction
) {
  try {
    const { cartId, index, quantity } = action.payload;

    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());

    const cart = CartData.findById(cartList, cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    const cartItem = cart.findItemByIndex(index);

    if (typeof cartItem === "undefined") {
      throw new Error("cart item is not found.");
    }

    const reservationTime = cart.getReservationTime();

    const params: IUpdateCartListRequest = {
      shop_id: cart.getShopId(),
      index,
      count: quantity,
      option_list: cartItem.getSelectedOptionIdArray(),
      instruction_text: cartItem.getInstructionText(),
      time_type: reservationTime.getTimeType(),
      receive_datetime: reservationTime.getDatetimeString()
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.UPDATE_CART_LIST, params)
    );

    const {
      result,
      error
    }: { result: IUpdateCartListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateCart(result.cart_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文数の変更に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
