import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetOrderHistoryRequest,
  IGetOrderHistoryResponse
} from "../../../apis/getOrderHistory";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateOrderList } from "../actions";

export default function* userAccessedToPageThatNeedsOrderHistorySaga() {
  try {
    const params: IGetOrderHistoryRequest = {
      offset: 0
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_ORDER_HISTORY, params, { ignoreCache: true })
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IGetOrderHistoryResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateOrderList(result.order_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文履歴の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
