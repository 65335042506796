export default class LocalStorage {
  public static save(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public static load(key: string) {
    return localStorage.getItem(key);
  }

  public static loadOrDefault(key: string, def: string) {
    const value = localStorage.getItem(key);
    return value !== null ? value : def;
  }

  public static remove(key: string) {
    localStorage.removeItem(key);
  }
}
