import { Set } from "immutable";
import { Action } from "redux";
import CategoryTag from "../../../records/CategoryTag";
import ReservationTime from "../../../records/ReservationTime";
import SearchQuery from "../../../records/SearchQuery";
import { SearchResultType } from "../../../records/SearchResult";
import { MaxDisplayableCookingTime } from "../../../records/SearchSetting/cookingTime";
import PriceRange from "../../../records/SearchSetting/PriceRange";
import { ResultSortType } from "../../../records/SearchSetting/resultSortType";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { SearchRadius } from "../../../records/SearchSetting/searchRadius";
import ShopForSearch from "../../../records/ShopForSearch";
import { HomeListType } from "../../../records/HomeList";
import { EPositionError } from "../../../util/Geolocation";
import { ILatLng } from "../model";
import { ActionTypes } from "./index";

export interface UserAccessedToPageThatNeedsMapSearchResultAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_MAP_SEARCH_RESULT;
}
export const userAccessedToPageThatNeedsMapSearchResult = (): UserAccessedToPageThatNeedsMapSearchResultAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_MAP_SEARCH_RESULT
});

export interface UserTouchedMapSearchPinAction extends Action {
  type: ActionTypes.U_TOUCHED_MAP_SEARCH_PIN;
  payload: {
    resultType: SearchResultType;
    item: ShopForSearch | undefined;
  };
}
export const userTouchedMapSearchPin = (
  resultType: SearchResultType,
  item: ShopForSearch | undefined
): UserTouchedMapSearchPinAction => ({
  type: ActionTypes.U_TOUCHED_MAP_SEARCH_PIN,
  payload: {
    resultType,
    item
  }
});

export interface UserSubmittedSearchKeywordAction extends Action {
  type: ActionTypes.U_SUBMITTED_SEARCH_KEYWORD;
  payload: {
    keyword: string;
  };
}
export const userSubmittedSearchKeyword = (
  keyword: string
): UserSubmittedSearchKeywordAction => ({
  type: ActionTypes.U_SUBMITTED_SEARCH_KEYWORD,
  payload: {
    keyword
  }
});

export interface SystemDetectedPositionErrorAction extends Action {
  type: ActionTypes.S_DETECTED_POSITION_ERROR;
  payload: {
    status: EPositionError | null;
  };
}
export const systemDetectedPositionError = (status: EPositionError | null) => ({
  type: ActionTypes.S_DETECTED_POSITION_ERROR,
  payload: {
    status
  }
});

export interface UserAccessedToPageThatNeedsSearchHistoryListAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SEARCH_HISTORY_LIST;
}
export const userAccessedToPageThatNeedsSearchHistoryList = (): UserAccessedToPageThatNeedsSearchHistoryListAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SEARCH_HISTORY_LIST
});

export interface UserAccessedShopPageAction extends Action {
  type: ActionTypes.U_ACCESSED_SHOP_PAGE;
  payload: {
    shopId: number;
  };
}
export const userAccessedShopPage = (
  shopId: number
): UserAccessedShopPageAction => ({
  type: ActionTypes.U_ACCESSED_SHOP_PAGE,
  payload: {
    shopId
  }
});

export interface UserSubmittedSearchLocationAction extends Action {
  type: ActionTypes.U_SUBMITTED_SEARCH_LOCATION;
}
export const userSubmittedSearchLocation = (): UserSubmittedSearchLocationAction => ({
  type: ActionTypes.U_SUBMITTED_SEARCH_LOCATION
});

export interface UserAccessedToPageThatNeedsCategoryTagListAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST;
}
export const userAccessedToPageThatNeedsCategoryTagList = (): UserAccessedToPageThatNeedsCategoryTagListAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST
});

export interface UserTouchedChangeShopFavoriteStateButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_CHANGE_SHOP_FAVORITE_STATE_BUTTON;
  payload: {
    shopId: number;
    bool: boolean;
  };
}
export const userTouchedChangeShopFavoriteStateButton = (
  shopId: number,
  bool: boolean
): UserTouchedChangeShopFavoriteStateButtonAction => ({
  type: ActionTypes.U_TOUCHED_CHANGE_SHOP_FAVORITE_STATE_BUTTON,
  payload: {
    shopId,
    bool
  }
});

export interface UserSubmittedSearchQueryAction extends Action {
  type: ActionTypes.U_SUBMITTED_SEARCH_QUERY;
  payload: {
    query: SearchQuery;
    locationChange: boolean;
  };
}
export const userSubmittedSearchQuery = (
  query: SearchQuery,
  locationChange: boolean
): UserSubmittedSearchQueryAction => ({
  type: ActionTypes.U_SUBMITTED_SEARCH_QUERY,
  payload: {
    query,
    locationChange
  }
});

export interface UserSubmittedSearchSettingsAction extends Action {
  type: ActionTypes.U_SUBMITTED_SEARCH_SETTINGS;
  payload: {
    searchResultType: SearchResultType;
    searchPlace: SearchPlace;
    reservationTime: ReservationTime;
  };
}
export const userSubmittedSearchSettings = (
  searchResultType: SearchResultType,
  searchPlace: SearchPlace,
  reservationTime: ReservationTime
): UserSubmittedSearchSettingsAction => ({
  type: ActionTypes.U_SUBMITTED_SEARCH_SETTINGS,
  payload: {
    searchResultType,
    searchPlace,
    reservationTime
  }
});

export interface UserSubmittedSearchSettingsWithoutSearchAction extends Action {
  type: ActionTypes.U_SUBMITTED_SEARCH_SETTINGS_WITHOUT_SEARCH;
  payload: {
    searchResultType: SearchResultType;
    searchPlace: SearchPlace;
    reservationTime: ReservationTime;
  };
}
export const userSubmittedSearchSettingsWithoutSearch = (
  searchResultType: SearchResultType,
  searchPlace: SearchPlace,
  reservationTime: ReservationTime
): UserSubmittedSearchSettingsWithoutSearchAction => ({
  type: ActionTypes.U_SUBMITTED_SEARCH_SETTINGS_WITHOUT_SEARCH,
  payload: {
    searchResultType,
    searchPlace,
    reservationTime
  }
});

export interface UserTouchedSearchByMapLocationButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_SEARCH_BY_MAP_LOCATION_BUTTON;
  payload: {
    searchResultType: SearchResultType;
    latlng: ILatLng;
  };
}
export const userTouchedSearchByMapLocationButton = (
  searchResultType: SearchResultType,
  latlng: ILatLng
): UserTouchedSearchByMapLocationButtonAction => ({
  type: ActionTypes.U_TOUCHED_SEARCH_BY_MAP_LOCATION_BUTTON,
  payload: {
    searchResultType,
    latlng
  }
});

export interface UserTouchedWriteReviewButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_WRITE_REVIEW_BUTTON;
  payload: {
    shopId: number;
  };
}
export const userTouchedWriteReviewButton = (
  shopId: number
): UserTouchedWriteReviewButtonAction => ({
  type: ActionTypes.U_TOUCHED_WRITE_REVIEW_BUTTON,
  payload: {
    shopId
  }
});

export interface UserAccessedToHomeAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_HOME;
}
export const userAccessedToHome = (): UserAccessedToHomeAction => ({
  type: ActionTypes.U_ACCESSED_TO_HOME
});

export interface SystemChangedCurrentSearchResultTypeAction extends Action {
  type: ActionTypes.S_CHANGED_CURRENT_SEARCH_RESULT_TYPE;
  payload: {
    type: SearchResultType;
  };
}
export const systemChangedCurrentSearchResultType = (
  type: SearchResultType
): SystemChangedCurrentSearchResultTypeAction => ({
  type: ActionTypes.S_CHANGED_CURRENT_SEARCH_RESULT_TYPE,
  payload: {
    type
  }
});

export interface UserTouchedToggleResultViewTypeButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_TOGGLE_RESULT_VIEW_TYPE_BUTTON;
  payload: {
    resultType: SearchResultType;
  };
}
export const userTouchedToggleResultViewTypeButton = (
  resultType: SearchResultType
): UserTouchedToggleResultViewTypeButtonAction => ({
  type: ActionTypes.U_TOUCHED_TOGGLE_RESULT_VIEW_TYPE_BUTTON,
  payload: {
    resultType
  }
});

export interface UserChangedSearchRadiusAction extends Action {
  type: ActionTypes.U_CHANGED_SEARCH_RADIUS;
  payload: {
    resultType: SearchResultType;
    radius: SearchRadius;
  };
}
export const userChangedSearchRadius = (
  resultType: SearchResultType,
  radius: SearchRadius
): UserChangedSearchRadiusAction => ({
  type: ActionTypes.U_CHANGED_SEARCH_RADIUS,
  payload: {
    resultType,
    radius
  }
});

export interface UserChangedReservationTimeAction extends Action {
  type: ActionTypes.U_CHANGED_RESERVATION_TIME;
  payload: {
    resultType: SearchResultType;
    time: ReservationTime;
  };
}
export const userChangedReservationTime = (
  resultType: SearchResultType,
  time: ReservationTime
): UserChangedReservationTimeAction => ({
  type: ActionTypes.U_CHANGED_RESERVATION_TIME,
  payload: {
    resultType,
    time
  }
});

export interface UserChangedReservationTimeWithoutSearchAction extends Action {
  type: ActionTypes.U_CHANGED_RESERVATION_TIME_WITHOUT_SEARCH;
  payload: {
    resultType: SearchResultType;
    time: ReservationTime;
  };
}
export const userChangedReservationTimeWithoutSearch = (
  resultType: SearchResultType,
  time: ReservationTime
): UserChangedReservationTimeWithoutSearchAction => ({
  type: ActionTypes.U_CHANGED_RESERVATION_TIME_WITHOUT_SEARCH,
  payload: {
    resultType,
    time
  }
});

export interface UserChangedPriceRangeAction extends Action {
  type: ActionTypes.U_CHANGED_PRICE_RANGE;
  payload: {
    resultType: SearchResultType;
    priceRange: PriceRange;
  };
}
export const userChangedPriceRange = (
  resultType: SearchResultType,
  priceRange: PriceRange
): UserChangedPriceRangeAction => ({
  type: ActionTypes.U_CHANGED_PRICE_RANGE,
  payload: {
    resultType,
    priceRange
  }
});

export interface UserChangedSortTypeAction extends Action {
  type: ActionTypes.U_CHANGED_SORT_TYPE;
  payload: {
    resultType: SearchResultType;
    sortType: ResultSortType;
  };
}
export const userChangedSortType = (
  resultType: SearchResultType,
  sortType: ResultSortType
): UserChangedSortTypeAction => ({
  type: ActionTypes.U_CHANGED_SORT_TYPE,
  payload: {
    resultType,
    sortType
  }
});

export interface UserSubmittedFilterSettingsAction extends Action {
  type: ActionTypes.U_SUBMITTED_FILTER_SETTINGS;
  payload: {
    resultType: SearchResultType;
    maxDisplayableCookingTime: MaxDisplayableCookingTime;
    filterCategoryList: Set<CategoryTag>;
  };
}
export const userSubmittedFilterSettings = (
  resultType: SearchResultType,
  maxDisplayableCookingTime: MaxDisplayableCookingTime,
  filterCategoryList: Set<CategoryTag>
): UserSubmittedFilterSettingsAction => ({
  type: ActionTypes.U_SUBMITTED_FILTER_SETTINGS,
  payload: {
    resultType,
    maxDisplayableCookingTime,
    filterCategoryList
  }
});

export interface UserAccessedToPageThatNeedsOrderHistoryListForSearchResultAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY_LIST_FOR_SEARCH_RESULT;
}
export const userAccessedToPageThatNeedsOrderHistoryListForSearchResult = (): UserAccessedToPageThatNeedsOrderHistoryListForSearchResultAction => ({
  type:
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY_LIST_FOR_SEARCH_RESULT
});

export interface UserTouchedCurrentLocationSearchButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_CURRENT_LOCATION_SEARCH_BUTTON;
}
export const userTouchedCurrentLocationSearchButton = (): UserTouchedCurrentLocationSearchButtonAction => ({
  type: ActionTypes.U_TOUCHED_CURRENT_LOCATION_SEARCH_BUTTON
});

export interface UserTouchedHomeReservationButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_HOME_RESERVATION_BUTTON;
}
export const userTouchedHomeReservationButton = (): UserTouchedHomeReservationButtonAction => ({
  type: ActionTypes.U_TOUCHED_HOME_RESERVATION_BUTTON
});

export interface UserAccessedToPageThatNeedsInitialSearchResultAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_INITIAL_SEARCH_RESULT;
  payload: {
    resultType: SearchResultType;
  };
}
export const userAccessedToPageThatNeedsInitialSearchResult = (
  resultType: SearchResultType
): UserAccessedToPageThatNeedsInitialSearchResultAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_INITIAL_SEARCH_RESULT,
  payload: {
    resultType
  }
});

export interface UserAccessedAreaPageAction extends Action {
  type: ActionTypes.U_ACCESSED_AREA_PAGE;
  payload: {
    areaId: number;
  };
}
export const userAccessedAreaPage = (
  areaId: number
): UserAccessedAreaPageAction => ({
  type: ActionTypes.U_ACCESSED_AREA_PAGE,
  payload: {
    areaId
  }
});

export interface UserAccessedToPageThatNeedsAreaShopListAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AREA_SHOP_LIST;
  payload: {
    areaId: number;
    subAreaId: number;
  };
}
export const userAccessedToPageThatNeedsAreaShopListPage = (
  areaId: number,
  subAreaId: number
): UserAccessedToPageThatNeedsAreaShopListAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AREA_SHOP_LIST,
  payload: {
    areaId,
    subAreaId
  }
});

export interface UserTouchedCategoryTagThumbAction extends Action {
  type: ActionTypes.U_TOUCHED_CATEGORY_TAG_THUMB;
  payload: {
    categoryTagId: number;
  };
}
export const userTouchedCategoryTagThumb = (
  categoryTagId: number
): UserTouchedCategoryTagThumbAction => ({
  type: ActionTypes.U_TOUCHED_CATEGORY_TAG_THUMB,
  payload: {
    categoryTagId
  }
});

export interface UserAccessedToPageThatNeedsNearOfficeShopListAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEAR_OFFICE_SHOP_LIST;
}
export const userAccessedToPageThatNeedsNearOfficeShopList = (): UserAccessedToPageThatNeedsNearOfficeShopListAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEAR_OFFICE_SHOP_LIST
});

export interface UserTouchedMoreNearOfficeShopListButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_MORE_NEAR_OFFICE_SHOP_LIST_BUTTON;
}
export const userTouchedMoreNearOfficeShopListButton = (): UserTouchedMoreNearOfficeShopListButtonAction => ({
  type: ActionTypes.U_TOUCHED_MORE_NEAR_OFFICE_SHOP_LIST_BUTTON
});

export interface UserAccessedToPageThatNeedsCurrentHomeShopListAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_HOME_SHOP_LIST;
  payload: {
    homeListType: HomeListType;
  };
}
export const userAccessedToPageThatNeedsCurrentHomeShopList = (
  homeListType: HomeListType
): UserAccessedToPageThatNeedsCurrentHomeShopListAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_HOME_SHOP_LIST,
  payload: {
    homeListType
  }
});

export type UserAndSystemActions =
  | UserAccessedToPageThatNeedsOrderHistoryListForSearchResultAction
  | UserSubmittedFilterSettingsAction
  | UserChangedSortTypeAction
  | UserChangedPriceRangeAction
  | UserChangedReservationTimeAction
  | UserChangedSearchRadiusAction
  | UserTouchedToggleResultViewTypeButtonAction
  | UserAccessedToHomeAction
  | UserTouchedWriteReviewButtonAction
  | UserTouchedSearchByMapLocationButtonAction
  | UserSubmittedSearchSettingsAction
  | UserSubmittedSearchQueryAction
  | UserTouchedChangeShopFavoriteStateButtonAction
  | UserAccessedToPageThatNeedsCategoryTagListAction
  | UserTouchedMapSearchPinAction
  | UserSubmittedSearchLocationAction
  | UserSubmittedSearchKeywordAction
  | UserAccessedToPageThatNeedsSearchHistoryListAction
  | UserAccessedShopPageAction
  | UserTouchedCurrentLocationSearchButtonAction
  | UserAccessedToPageThatNeedsInitialSearchResultAction
  | UserAccessedAreaPageAction
  | UserAccessedToPageThatNeedsAreaShopListAction
  | UserTouchedCategoryTagThumbAction
  | UserAccessedToPageThatNeedsNearOfficeShopListAction
  | UserTouchedMoreNearOfficeShopListButtonAction
  | UserAccessedToPageThatNeedsCurrentHomeShopListAction
  | SystemDetectedPositionErrorAction
  | SystemChangedCurrentSearchResultTypeAction;
