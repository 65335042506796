import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { systemClosedModal } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import ButtonAlter from "../atoms/ButtonAlter";
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter
} from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";

const modalStyle: React.CSSProperties = {
  margin: "35px"
};

const buttonStyle: React.CSSProperties = {
  paddingTop: "14px",
  paddingBottom: "14px"
};

const closeButtonStyle: React.CSSProperties = {
  paddingTop: "23px",
  color: "#838383",
  textAlign: "center"
};

interface IProps {}

const ZeroResultDialog: React.FC<IProps> = React.memo(props => {
  const openZeroResultDialog = useSelector((state: ReduxModel) =>
    state.app.getModalManager().canDisplay("ZERO_RESULT")
  );

  const dispatch = useDispatch();

  const handleClickClose = useCallback(() => {
    dispatch(systemClosedModal("ZERO_RESULT"));
  }, [dispatch]);

  const handleClickShop = useCallback(() => {
    batch(() => {
      dispatch(push("/request/shop"));
      dispatch(systemClosedModal("ZERO_RESULT"));
    });
  }, [dispatch]);

  const handleClickArea = useCallback(() => {
    batch(() => {
      dispatch(push("/request/area"));
      dispatch(systemClosedModal("ZERO_RESULT"));
    });
  }, [dispatch]);

  return openZeroResultDialog ? (
    <Modal
      isOpen={true}
      innerStyle={modalStyle}
      handleClickBackdrop={handleClickClose}
    >
      <Dialog>
        <DialogHeader style={{ fontSize: "17px", fontWeight: "bold" }}>
          掲載店・エリア拡大中！
        </DialogHeader>
        <DialogBody>
          <Notes>
            現在地または検索したお店・エリアについて、現在対象外となっております。掲載希望の方は、ぜひお知らせください。
          </Notes>
        </DialogBody>
        <DialogFooter>
          <div>
            <ButtonAlter
              block
              appearance={"secondary"}
              onClick={handleClickShop}
              style={buttonStyle}
            >
              希望のお店をリクエスト
            </ButtonAlter>
          </div>
          <div style={{ marginTop: "20px" }}>
            <ButtonAlter
              block
              appearance={"secondary"}
              onClick={handleClickArea}
              style={buttonStyle}
            >
              希望エリアをリクエスト
            </ButtonAlter>
          </div>
          <div style={closeButtonStyle} onClick={handleClickClose}>
            閉じる
          </div>
        </DialogFooter>
      </Dialog>
    </Modal>
  ) : null;
});

export default ZeroResultDialog;
