import { FormErrors } from "redux-form";

export interface IRegisterAgreementsForm {
  hasAgreedTerms: boolean;
  hasAgreedPrivacyPolicy: boolean;
}

export const initialRegisterAgreementsForm: IRegisterAgreementsForm = {
  hasAgreedTerms: false,
  hasAgreedPrivacyPolicy: false
};

export function validateRegisterAgreementsForm(
  values: IRegisterAgreementsForm
) {
  const errors: FormErrors<IRegisterAgreementsForm> = {};
  if (!values.hasAgreedTerms) {
    errors.hasAgreedTerms = "利用規約に同意してください";
  }
  if (!values.hasAgreedPrivacyPolicy) {
    errors.hasAgreedPrivacyPolicy = "プライバシーポリシーに同意してください";
  }
  return errors;
}
