import { Record } from "immutable";
import { ILatLng } from "../modules/search/model";

interface ILabeledPlace {
  address: string;
  latLng: ILatLng;
}

const initial: ILabeledPlace = {
  address: "",
  latLng: { lat: 0, lng: 0 }
};

/**
 * テキストと座標の組み合わせを表現するクラス
 */
export default class LabeledPlace extends Record(initial) {
  public static create(address: string, latLng: ILatLng) {
    return new LabeledPlace({ address, latLng });
  }

  private constructor(args: any = {}) {
    super(args);
  }

  public getAddress() {
    return this.get("address");
  }

  public getLocation() {
    return this.get("latLng");
  }

  public validLocation() {
    const { lat, lng } = this.getLocation();
    return lat !== 0 && lng !== 0;
  }
}
