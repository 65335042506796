import React from "react";
import ClearingCartDialog from "./ClearingCartDialog";
import ConfirmOrderDialog from "./ConfirmOrderDialog";
import SettingReservationTimeForCartDialog from "./SettingReservationTimeForCartDialog";
import SubmittingAddCartDialog from "./SubmittingAddCartDialog";
import SubmittingOrderDialog from "./SubmittingOrderDialog";

const CartModal: React.FC = React.memo(() => {
  return (
    <>
      <SubmittingOrderDialog />
      <SubmittingAddCartDialog />
      <SettingReservationTimeForCartDialog />
      <ClearingCartDialog />
      <ConfirmOrderDialog />
    </>
  );
});

export default CartModal;
