import { ILatLng } from "../modules/search/model";

export enum EPositionError {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
  UNKNOWN = 4
}

export default class Geolocation {
  public static getCurrentLocation(): Promise<{
    location: Position;
  }> {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          (position: Position) => {
            resolve({ location: position });
          },
          (error: PositionError) => reject({ error }),
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 20000
          }
        );
      } catch (error) {
        reject({ error });
      }
    });
  }

  public static getCenterOfGravity(locations: ReadonlyArray<ILatLng>) {
    const len = locations.length;
    return {
      lat: locations.reduce((total, { lat }) => total + lat, 0) / len,
      lng: locations.reduce((total, { lng }) => total + lng, 0) / len
    };
  }

  public static getLatLngBounds(locations: readonly ILatLng[]) {
    const latLngBounds: google.maps.LatLngBoundsLiteral = {
      east: 0,
      west: 0,
      north: 0,
      south: 0
    };
    return locations.reduce((acc, crr) => {
      if (acc.north === 0 || acc.north < crr.lat) {
        acc.north = crr.lat;
      } else if (acc.south === 0 || acc.south > crr.lat) {
        acc.south = crr.lat;
      }
      if (acc.east === 0 || acc.east < crr.lng) {
        acc.east = crr.lng;
      }
      if (acc.west === 0 || acc.west > crr.lng) {
        acc.west = crr.lng;
      }
      return latLngBounds;
    }, latLngBounds);
  }

  public static toLatLng(
    lat: string | number | null,
    lng: string | number | null
  ): ILatLng {
    try {
      if (lat === null || lng === null) {
        throw new Error("invalid location");
      }
      return {
        lat: typeof lat === "string" ? parseFloat(lat) : lat,
        lng: typeof lng === "string" ? parseFloat(lng) : lng
      };
    } catch {
      return {
        lat: 0,
        lng: 0
      };
    }
  }

  public static equalsLatLng(a: ILatLng, b: ILatLng) {
    return a.lat === b.lat && a.lng === b.lng;
  }

  public static isValidLocation(location: ILatLng) {
    return !(location.lat === 0 && location.lng === 0);
  }

  public getErrorMessageByCurrentLocationInquiryStatus(status: EPositionError) {
    switch (status) {
      case EPositionError.PERMISSION_DENIED:
        return "位置情報の利用が許可されていません。";
      case EPositionError.POSITION_UNAVAILABLE:
        return "お店の情報が表示できませんでした。";
      case EPositionError.TIMEOUT:
        return "要求がタイムアウトになりました。";
      case EPositionError.UNKNOWN:
        return "不明なエラーが発生しました。";
      default:
        return "";
    }
  }
}
