import { put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { systemClosedModal } from "../../app/actions";
import { updateSearchSetting, UserChangedPriceRangeAction } from "../actions";

export default function* userChangedPriceRangeSaga(
  action: UserChangedPriceRangeAction
) {
  const { resultType, priceRange } = action.payload;
  const currentSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  yield put(systemClosedModal("SETTING_PRICE_RANGE"));

  yield put(
    updateSearchSetting(resultType, currentSetting.setPriceRange(priceRange))
  );
}
