import { replace } from "connected-react-router";
import { cancel, fork, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IAuthSmsByTelRequest,
  IAuthSmsByTelResponse
} from "../../../apis/authSmsByTel";
import { AlertType } from "../../../records/Alert";
import LocalRepository from "../../../util/LocalRepository";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateLoginToken,
  updateUser,
  UserSubmittedAuthResetPasswordAction
} from "../actions";
import getUserDataSaga from "./getUserDataSaga";

export default function* userSubmittedAuthResetPasswordSaga(
  action: UserSubmittedAuthResetPasswordAction
) {
  try {
    const params: IAuthSmsByTelRequest = {
      tel: action.payload.tel,
      auth_code: action.payload.auth_code
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.AUTH_SMS_BY_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IAuthSmsByTelResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      // FIXME: telの受渡しを後ほど別の方法に切り替え
      LocalRepository.removeItem("authSmsByTel");
      yield put(updateLoginToken(result.token));
      yield put(replace("/resetPassword"));
      const getUserDataTask = yield fork(() => getUserDataSaga());
      const userData = yield join(getUserDataTask);
      yield put(updateUser(userData));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証コードの承認に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
