import { List, Record } from "immutable";
import Utility from "../util/Utility";

export interface ISubArea {
  sub_area_id: number;
  sub_area_name: string;
}

const initial: ISubArea = {
  sub_area_id: 0,
  sub_area_name: ""
};

export default class SubArea extends Record(initial) {
  public getId() {
    return this.get("sub_area_id");
  }
  public getName() {
    return this.get("sub_area_name");
  }
}
