import { goBack, push } from "connected-react-router";
import { is } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import { EProcessingFlag } from "../../modules/app/model";
import {
  systemAddedAlert,
  removeProcessingFlag
} from "../../modules/app/actions";
import {
  userSubmittedSearchSettings,
  userSubmittedSearchSettingsWithoutSearch
} from "../../modules/search/actions";
import { ILatLng } from "../../modules/search/model";
import { AlertType } from "../../records/Alert";
import LabeledPlace from "../../records/LabeledPlace";
import PlaceListItem, { ERegistrationPlace } from "../../records/PlaceListItem";
import ReservationTime from "../../records/ReservationTime";
import { SearchResultType } from "../../records/SearchResult";
import SearchPlace from "../../records/SearchSetting/SearchPlace";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import CrossMarkIcon from "../atoms/CrossMarkIcon";
import MapPinIconAlter from "../atoms/MapPinIconAlter";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SolidCheckIcon from "../atoms/SolidCheckIcon";
import TimeIcon from "../atoms/TimeIcon";
import InputSuggestionPlace from "../organisms/InputSuggestionPlace";
import Auth from "../organisms/Auth";

const pageBodyStyle: React.CSSProperties = {
  padding: 0
};

const HeaderContainer = styled.div`
  position: relative;
  padding: 16px;
  padding-top: calc(16px + constant(safe-area-inset-top));
  padding-top: calc(16px + env(safe-area-inset-top));
  border-bottom: 1px solid #cdcdd2;
  background: ${colorsConst.BACKGROUND};
  text-align: center;
  color: #272727;
  font-size: 1.6rem;
`;

interface IHeaderProps {
  handleClickClose: () => void;
}

const Header: React.FC<IHeaderProps> = props => {
  const { handleClickClose } = props;

  const handleClick = useCallback(() => {
    handleClickClose();
  }, [handleClickClose]);

  return (
    <HeaderContainer>
      <div
        style={{ position: "absolute", bottom: "16px", left: "16px" }}
        onClick={handleClick}
      >
        <CrossMarkIcon />
      </div>
      受取設定
    </HeaderContainer>
  );
};

const SearchPlaceListContainer = styled.div`
  padding: 0 15px;
  background-color: ${colorsConst.BACKGROUND};
`;

const SelectItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 39px;
  height: 62px;
  font-size: 16px;
  color: #272727;
  & + & {
    border-top: 1px solid #f3f3f5;
  }
`;

const SelectItemIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;
const SelectItemCheck = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

interface IProps
  extends RouteComponentProps<{ searchResultType: SearchResultType }> {
  children?: never;
}

const SettingSearchTemplate: React.FC<IProps> = React.memo(({ match }) => {
  const { searchResultType } = match.params;

  const searchPlace = useSelector((state: ReduxModel) => {
    const setting = state.search.getSearchSettings().getData(searchResultType);
    return setting.getSearchPlace();
  });

  const placeList = useSelector((state: ReduxModel) =>
    PlaceListItem.getOrderedList(state.user.getData().getPlaceList())
  );

  const edittingReservationTime = useSelector((state: ReduxModel) => {
    const setting = state.search.getSearchSettings().getData(searchResultType);
    return setting.getReservationTime();
  });

  const isSearchSettingAtHome = useSelector((state: ReduxModel) => {
    return state.app.isProcessing(EProcessingFlag.SEARCH_SETTING_AT_HOME);
  });

  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const goToRegisterPlace = useCallback(
    (type: ERegistrationPlace) => {
      dispatch(push(`/user/inputRegistrationPlace/${type}`));
    },
    [dispatch]
  );

  const openErrorDialog = useCallback(() => {
    dispatch(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "住所情報の取得に失敗しました",
        message: "入力項目や通信環境をご確認の上、再度お試しください"
      })
    );
  }, [dispatch]);

  const [selectSearchPlace, setSelectSearchPlace] = useState<SearchPlace>(
    searchPlace
  );
  const [additionalSearchPlace, setAdditionalSearchPlace] = useState<
    SearchPlace | undefined
  >();

  const [currentReservationTime, setCurrentReservationTime] = useState<
    ReservationTime
  >(edittingReservationTime);

  const historyList = useMemo(() => {
    const history = SearchPlace.getHistoryFromStorage();
    return typeof additionalSearchPlace === "undefined"
      ? history
      : history.unshift(additionalSearchPlace);
  }, [additionalSearchPlace]);

  const handleSubmitLabeledPlace = useCallback(
    (address: string, latLng: ILatLng) => {
      Analytics.logEvent("search_setting_input");
      const labeledPlace = SearchPlace.asLabeledPlace(address, latLng);
      if (historyList.every(i => !is(i, labeledPlace))) {
        setAdditionalSearchPlace(labeledPlace);
      }
      setSelectSearchPlace(labeledPlace);
    },
    [historyList]
  );

  const handleClickAreaItem = useCallback(
    (place: SearchPlace) => {
      if (!place.isCurrentLocation() && additionalSearchPlace !== place) {
        Analytics.logEvent("search_setting_reserve");
      }
      setSelectSearchPlace(place);
    },
    [additionalSearchPlace]
  );

  const handleSubmit = useCallback(() => {
    if (isSearchSettingAtHome) {
      dispatch(
        userSubmittedSearchSettingsWithoutSearch(
          searchResultType,
          selectSearchPlace,
          currentReservationTime
        )
      );
    } else {
      dispatch(
        userSubmittedSearchSettings(
          searchResultType,
          selectSearchPlace,
          currentReservationTime
        )
      );
    }
  }, [dispatch, searchResultType, selectSearchPlace, currentReservationTime]);

  useEffect(() => {
    setCurrentReservationTime(edittingReservationTime);
  }, [edittingReservationTime]);

  /**
   * HOMEから予約検索設定に遷移した場合のみフラグを立てて確定時の検索を回避
   */
  useEffect(() => {
    return () => {
      dispatch(removeProcessingFlag(EProcessingFlag.SEARCH_SETTING_AT_HOME));
    };
  }, [dispatch]);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }
    handleSubmit();
  }, [selectSearchPlace]);

  return (
    <Auth>
      <Page header={<Header handleClickClose={handleGoBack} />}>
        <PageBody style={pageBodyStyle}>
          <div style={{ padding: "8px 0" }}>
            <InputSuggestionPlace
              handleSubmit={handleSubmitLabeledPlace}
              openErrorDialog={openErrorDialog}
              clearInputAfterSubmit
            />
          </div>
          <SearchPlaceListContainer>
            <div>
              <SelectItem
                onClick={() =>
                  handleClickAreaItem(SearchPlace.asCurrentLocation())
                }
              >
                <SelectItemIcon>
                  <MapPinIconAlter />
                </SelectItemIcon>
                <span>現在地近辺</span>
                {selectSearchPlace.isCurrentLocation() && (
                  <SelectItemCheck>
                    <SolidCheckIcon />
                  </SelectItemCheck>
                )}
              </SelectItem>

              {searchPlace.isMapLocation() ? (
                <SelectItem onClick={() => handleClickAreaItem(searchPlace)}>
                  <SelectItemIcon>
                    <MapPinIconAlter />
                  </SelectItemIcon>
                  <span>{searchPlace.getMapLocation()!.getAddress()}</span>
                  {selectSearchPlace.isMapLocation() ? (
                    <SelectItemCheck>
                      <SolidCheckIcon />
                    </SelectItemCheck>
                  ) : null}
                </SelectItem>
              ) : null}

              {placeList.map(item => {
                const searchPlaceTmp = SearchPlace.fromRegistrationPlace(item);
                if (searchPlaceTmp === null) {
                  return null;
                }
                const registrationPlace = searchPlaceTmp.getRegistrationPlace();
                if (registrationPlace === null) {
                  return null;
                }
                const Icon = registrationPlace.getIconComponent();
                return item.registered() ? (
                  <SelectItem
                    key={item.getAddressText()}
                    onClick={() => handleClickAreaItem(searchPlaceTmp)}
                  >
                    <SelectItemIcon>
                      <Icon />
                    </SelectItemIcon>
                    <div>
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#3A3A47",
                          paddingBottom: "5px"
                        }}
                      >
                        {item.getLabelText()}
                      </div>
                      <div style={{ fontSize: "14px", color: "#757575" }}>
                        {item.getAddressText()}
                      </div>
                    </div>
                    {selectSearchPlace.isSameRegistrationPlace(
                      searchPlaceTmp
                    ) ? (
                      <SelectItemCheck>
                        <SolidCheckIcon />
                      </SelectItemCheck>
                    ) : null}
                  </SelectItem>
                ) : (
                  <SelectItem
                    key={item.getLabelText()}
                    onClick={() =>
                      goToRegisterPlace(registrationPlace.getType())
                    }
                  >
                    <SelectItemIcon>
                      <Icon />
                    </SelectItemIcon>
                    <div>
                      <div style={{ fontSize: "16px", color: "#3A3A47" }}>
                        {item.getLabelText()}
                      </div>
                      <div style={{ fontSize: "14px", color: "#757575" }}>
                        変更する
                      </div>
                    </div>
                  </SelectItem>
                );
              })}

              {historyList.map((item, index) => {
                const labeledPlace = item.getLabeledPlace() as LabeledPlace;
                return (
                  <SelectItem
                    key={index}
                    onClick={() => handleClickAreaItem(item)}
                  >
                    <SelectItemIcon>
                      <TimeIcon />
                    </SelectItemIcon>
                    {labeledPlace.getAddress()}
                    {is(item, selectSearchPlace) && (
                      <SelectItemCheck>
                        <SolidCheckIcon />
                      </SelectItemCheck>
                    )}
                  </SelectItem>
                );
              })}
            </div>
          </SearchPlaceListContainer>
        </PageBody>
      </Page>
    </Auth>
  );
});

export default withRouter(SettingSearchTemplate);
