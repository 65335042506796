import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25812 13.0625H17.8125V1.1875H1.1875V16.1331L4.25812 13.0625ZM0 19V0H19V14.25H4.75L0 19Z"
        fill="#3A3A47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89841 8.78906L8.76152 2.85156L10.2422 2.85156L10.0859 8.78906L8.89841 8.78906Z"
        fill="#3A3A47"
      />
      <path
        d="M10.2239 10.5494C10.2239 10.95 9.89919 11.2747 9.49866 11.2747C9.09813 11.2747 8.77344 10.95 8.77344 10.5494C8.77344 10.1489 9.09813 9.82422 9.49866 9.82422C9.89919 9.82422 10.2239 10.1489 10.2239 10.5494Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const NewsIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default NewsIcon;
