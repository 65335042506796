import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetShopListByTypeRequest,
  IGetShopListByTypeResponse
} from "../../../apis/getShopListByType";
import { AlertType } from "../../../records/Alert";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCurrentHomeList,
  UserAccessedToPageThatNeedsCurrentHomeShopListAction
} from "../actions";
import HomeList from "../../../records/HomeList";

export default function* userAccessedToPageThatNeedsCurrentHomeShopListSaga(
  action: UserAccessedToPageThatNeedsCurrentHomeShopListAction
) {
  yield put(setProcessingFlag(EProcessingFlag.FETCHING_HOME_SHOP_LIST));
  try {
    const param: IGetShopListByTypeRequest = {
      type: HomeList.getFetchType(action.payload.homeListType)
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_SHOP_LIST_BY_TYPE, param)
    );
    const {
      result,
      error
    }: { result: IGetShopListByTypeResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateCurrentHomeList(result.shop_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.FETCHING_HOME_SHOP_LIST));
  }
}
