import * as React from "react";
import { useCallback } from "react";
import { Field, WrappedFieldProps } from "redux-form";
import ErrorText from "../../../components/atoms/ErrorText";
import TextInput from "../../../components/atoms/TextInput";
import CaptureIcon from "../../atoms/CaptureIcon";
import { fixBodyScrollTopWhenInputBlurred } from "../../../util/Utility";

const captureIconStyle: React.CSSProperties = {
  padding: "10px 3px 10px 13px"
};

interface IContainerProps extends WrappedFieldProps {
  wrapperStyle?: React.CSSProperties;
  outerStyle?: React.CSSProperties;
  hasToolTip?: boolean;
  handleTouchedTooltip?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const RenderTextField = (props: IContainerProps) => {
  const {
    wrapperStyle,
    outerStyle,
    input,
    meta: { error, touched },
    hasToolTip,
    handleTouchedTooltip,
    ...rest
  } = props;

  return (
    <div>
      <div style={wrapperStyle}>
        <TextInput outerStyle={outerStyle} {...input} {...rest} />
        {hasToolTip ? (
          <CaptureIcon
            style={captureIconStyle}
            onClick={handleTouchedTooltip}
          />
        ) : null}
      </div>
      <ErrorText>{touched && error && <div>※{error}</div>}</ErrorText>
    </div>
  );
};

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperStyle?: React.CSSProperties;
  outerStyle?: React.CSSProperties;
  hasToolTip?: boolean;
  handleTouchedTooltip?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const TextField: React.FC<IProps> = React.memo(props => {
  const { name, ...rest } = props;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (props.onBlur) {
        props.onBlur(event);
      }
      fixBodyScrollTopWhenInputBlurred();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onBlur]
  );

  return (
    <Field
      name={name}
      component={RenderTextField}
      {...rest}
      onBlur={handleBlur}
    />
  );
});

export default TextField;
