import React from "react";
import RegisterTooltipDialog from "./RegisterTooltipDialog";

interface IProps {
  children?: never;
}

const RegisterTooltipModal: React.FC<IProps> = React.memo(props => {
  return (
    <>
      <RegisterTooltipDialog period />
      <RegisterTooltipDialog />
    </>
  );
});

export default RegisterTooltipModal;
