import React, { useEffect } from "react";
import { useCallback } from "react";
import Analytics from "../../util/Analytics";
import CordovaUtil from "../../util/CordovaUtil";

const GeolocationPermissionErrorForHome: React.FC = () => {
  const handleClickGoToSetting = useCallback(() => {
    CordovaUtil.openAppLocationSettings();
    Analytics.logEvent("error_no_gps_setting");
  }, []);

  useEffect(() => {
    Analytics.logEvent("error_no_gps");
  }, []);

  return (
    <div>
      <div
        style={{
          paddingTop: "30px",
          fontSize: "15px",
          fontWeight: 500,
          color: "#737373",
          textAlign: "center"
        }}
      >
        端末の設定で
        <br />
        位置情報サービスをオンにして、
        <br />
        menuが現在位置を
        <br />
        判別できるようにしてください。
      </div>
      <div
        style={{
          marginTop: "22px",
          textAlign: "center"
        }}
      >
        <div
          style={{
            display: "inline-block",
            padding: "14px",
            background: "#FFFFFF",
            color: "#3A3A47",
            border: "1px solid #E1E1E1",
            borderRadius: "2px"
          }}
          onClick={handleClickGoToSetting}
        >
          設定を開く
        </div>
      </div>
    </div>
  );
};

export default GeolocationPermissionErrorForHome;
