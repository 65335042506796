import { createGlobalStyle, css } from "styled-components";
import colorConst from "../const/colorsConst";
import fontsConst from "../const/fontsConst";

const baseCss = css`
  html {
    font-size: 62.5%;
    touch-action: manipulation;
  }

  body {
    font-size: ${fontsConst.SIZE.DEFAULT};
    font-family: ${fontsConst.FAMILY};
    color: ${colorConst.BASE};
    line-height: ${fontsConst.LINE_HEIHT.DEFAULT};
    letter-spacing: ${fontsConst.LETTER_SPACING.DEFAULT};
    backface-visibility: hidden;
    font-feature-settings: "palt";
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input,
  textarea {
    -moz-user-select: text;
  }

  input,
  textarea,
  select,
  button {
    color: ${colorConst.BASE};
    font-size: ${fontsConst.SIZE.DEFAULT};
    line-height: ${fontsConst.LINE_HEIHT.DEFAULT};
    box-sizing: border-box;
    background: none;
  }

  select,
  button {
    font: normal ${fontsConst.SIZE.DEFAULT} ${fontsConst.FAMILY};
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }

  input[type="text"] {
    background-color: transparent;
  }

  a {
    text-decoration: none;
    color: ${colorConst.BASE};
  }

  img {
    vertical-align: bottom;
  }

  svg {
    vertical-align: middle;
  }

  textarea {
    resize: none;
    padding: 0;
    font-family: sans-serif;
  }

  input,
  textarea,
  select,
  option,
  button {
    border-radius: 0;
    border: none;
    appearance: none;
    outline: none;
  }

  button {
    cursor: pointer;
    padding: 0;
    background: none;
    &:focus {
      outline: inherit;
    }
  }

  ul,
  li {
    list-style: none;
  }
`;

const BaseStyle = createGlobalStyle`
${baseCss.join("")}
`;

export default BaseStyle;
