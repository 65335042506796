import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IInputDiscountCodeForm {
  discount_code: string;
}

export const initialInputDiscountCodeForm: IInputDiscountCodeForm = {
  discount_code: ""
};

export function validateInputDiscountCodeForm(values: IInputDiscountCodeForm) {
  const errors: FormErrors<IInputDiscountCodeForm> = {};
  if (!values.discount_code) {
    errors.discount_code = "クーポンコードを入力してください";
  } else if (!UserModel.validDiscrountCode(values.discount_code)) {
    errors.discount_code = "クーポンコードの形式が正しくありません";
  }
  return errors;
}
