import { List } from "immutable";
import { ellipsis } from "polished";
import React from "react";
import styled from "styled-components";
import Feedback from "../../records/Feedback";
import Grid from "../atoms/Grid";
import Stars from "../atoms/Stars";
import FeedbackListItem from "../molecules/FeedbackListItem";

const Container = styled.ul``;

const Item = styled.li`
  padding-bottom: 17px;
  & + & {
    padding-top: 17px;
    border-top: 1px solid #efefef;
  }
`;

const StarRatingContainer = styled(Grid).attrs({ item: true, rate: 0 })`
  padding-right: 9px;
  white-space: nowrap;
`;

const OrderedItemName = styled(Grid).attrs({ item: true, shrink: 1 })`
  max-width: 50%;
  padding-right: 9px;
  font-size: 12px;
  color: #ff3008;
  ${ellipsis()}
`;

const FeedbackTime = styled(Grid).attrs({ item: true, rate: 1, shrink: 0 })`
  font-size: 12px;
  color: #595959;
  white-space: nowrap;
`;

interface IProps {
  list: List<Feedback>;
}

const FeedbackList: React.FC<IProps> = React.memo(props => {
  const { list } = props;

  return (
    <Container>
      {list.map((feedback, index) => {
        return (
          <Item key={index}>
            <div style={{ paddingBottom: "6px" }}>
              <Grid container align="center" wrap="nowrap">
                <StarRatingContainer>
                  <Stars rate={feedback.getPointAsFloat()} />
                </StarRatingContainer>
                <OrderedItemName>
                  {feedback.getPresentationalFeedbackItemName()}
                </OrderedItemName>
                <FeedbackTime>
                  {feedback.getPresentationalSentTime()}
                </FeedbackTime>
              </Grid>
            </div>
            <FeedbackListItem comment={feedback.getComment()} />
          </Item>
        );
      })}
    </Container>
  );
});

export default FeedbackList;
