import {
  connectRouter,
  RouterAction,
  RouterState
} from "connected-react-router";
import { History } from "history";
import { Action, combineReducers } from "redux";
import * as ReduxForm from "redux-form";
import app from "./modules/app";
import { AppActions } from "./modules/app/actions";
import AppModel from "./modules/app/model";
import notification from "./modules/notification";
import { NotificationActions } from "./modules/notification/actions";
import NotificationModel from "./modules/notification/model";
import order from "./modules/order";
import { OrderActions } from "./modules/order/actions";
import OrderModel from "./modules/order/model";
import search from "./modules/search";
import { SearchActions } from "./modules/search/actions";
import SearchModel from "./modules/search/model";
import user from "./modules/user";
import { UserActions } from "./modules/user/actions";
import UserModel from "./modules/user/model";

export default function(history: History) {
  return combineReducers({
    router: connectRouter(history),
    form: ReduxForm.reducer,
    app,
    order,
    search,
    user,
    notification
  });
}

export interface ReduxModel {
  router: RouterState;
  form: ReduxForm.FieldState;
  app: AppModel;
  order: OrderModel;
  search: SearchModel;
  user: UserModel;
  notification: NotificationModel;
}

export type ReduxAction =
  | RouterAction
  | ReduxForm.FormAction
  | AppActions
  | OrderActions
  | SearchActions
  | UserActions
  | NotificationActions
  | Action;
