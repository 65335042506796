import { Action } from "redux";
import { ActionTypes } from ".";
import { EApiKey } from "../../../apis";
import { IAlertProps } from "../../../records/Alert";
import { EFlagKey } from "../../../records/FlagManager";
import { IMaintenanceInfo } from "../../../records/MaintenanceInfo";
import { ModalKey, ModalPayload } from "../../../records/ModalManager";
import { IUpdateBuildPayload } from "../../../records/UpdateBuildPayload";
import { IUpdateResourcePayload } from "../../../records/UpdateResourcePayload";
import { EProcessingFlag } from "../model";

export interface SetShouldUpdateResourceFlagAction extends Action {
  type: ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG;
}
export const setShouldUpdateResourceFlag = (): SetShouldUpdateResourceFlagAction => ({
  type: ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG
});

export interface UpdatePlayingPageTransitionAnimationFlagAction extends Action {
  type: ActionTypes.R_UPDATE_PLAYING_PAGE_TRANSITION_ANIMATION_FLAG;
  payload: {
    started: boolean;
  };
}
export const updatePlayingPageTransitionAnimationFlag = (started: boolean) => ({
  type: ActionTypes.R_UPDATE_PLAYING_PAGE_TRANSITION_ANIMATION_FLAG,
  payload: {
    started
  }
});

export interface UpdateConnectingApiSetAction extends Action {
  type: ActionTypes.R_UPDATE_CONNECTING_API_SET;
  payload: {
    apiKey: EApiKey;
    add: boolean;
  };
}
export const updateConnectingApiSet = (
  apiKey: EApiKey,
  add: boolean
): UpdateConnectingApiSetAction => ({
  type: ActionTypes.R_UPDATE_CONNECTING_API_SET,
  payload: {
    apiKey,
    add
  }
});

export interface LaunchAction extends Action {
  type: ActionTypes.R_LAUNCH;
}
export const launch = (): LaunchAction => ({
  type: ActionTypes.R_LAUNCH
});

export interface ReadyForUserOperationAction extends Action {
  type: ActionTypes.R_READY_FOR_USER_OPERATION;
}
export const readyForUserOperation = (): ReadyForUserOperationAction => ({
  type: ActionTypes.R_READY_FOR_USER_OPERATION
});

export interface UpdateUserInputtingTextFlagAction extends Action {
  type: ActionTypes.R_UPDATE_USER_INPUTTING_TEXT_FLAG;
  payload: {
    started: boolean;
  };
}
export const updateUserInputtingTextFlag = (started: boolean) => ({
  type: ActionTypes.R_UPDATE_USER_INPUTTING_TEXT_FLAG,
  payload: {
    started
  }
});

export interface SetMaintenanceInfoAction extends Action {
  type: ActionTypes.R_SET_MAINTENANCE_INFO;
  payload: {
    info: IMaintenanceInfo;
  };
}
export const setMaintenanceInfo = (
  info: IMaintenanceInfo
): SetMaintenanceInfoAction => ({
  type: ActionTypes.R_SET_MAINTENANCE_INFO,
  payload: {
    info
  }
});

export interface SetAppVersionAction extends Action {
  type: ActionTypes.R_SET_APP_VERSION;
  payload: {
    version: string | null;
  };
}
export const setAppVersion = (version: string | null): SetAppVersionAction => ({
  type: ActionTypes.R_SET_APP_VERSION,
  payload: {
    version
  }
});

export interface OpenModalAction extends Action {
  type: ActionTypes.R_OPEN_MODAL;
  payload: {
    key: ModalKey;
    payload: ModalPayload<ModalKey>;
  };
}
export const openModal = <T extends ModalKey>(
  key: T,
  payload: ModalPayload<T>
): OpenModalAction => ({
  type: ActionTypes.R_OPEN_MODAL,
  payload: {
    key,
    payload
  }
});

export interface CloseModalAction extends Action {
  type: ActionTypes.R_CLOSE_MODAL;
  payload: {
    key: ModalKey;
  };
}
export const closeModal = (key: ModalKey): CloseModalAction => ({
  type: ActionTypes.R_CLOSE_MODAL,
  payload: {
    key
  }
});

export interface RemovedAlertAction extends Action {
  type: ActionTypes.R_REMOVED_ALERT;
  payload: {
    id: string;
  };
}
export const removedAlert = (id: string) => ({
  type: ActionTypes.R_REMOVED_ALERT,
  payload: {
    id
  }
});

export interface SetProcessingFlagAction extends Action {
  type: ActionTypes.R_SET_PROCESSING_FLAG;
  payload: {
    key: EProcessingFlag;
  };
}
export const setProcessingFlag = (
  key: EProcessingFlag
): SetProcessingFlagAction => ({
  type: ActionTypes.R_SET_PROCESSING_FLAG,
  payload: {
    key
  }
});

export interface RemoveProcessingFlagAction extends Action {
  type: ActionTypes.R_REMOVE_PROCESSING_FLAG;
  payload: {
    key: EProcessingFlag;
  };
}
export const removeProcessingFlag = (
  key: EProcessingFlag
): RemoveProcessingFlagAction => ({
  type: ActionTypes.R_REMOVE_PROCESSING_FLAG,
  payload: {
    key
  }
});

export interface SetUpdateBuildPayloadAction extends Action {
  type: ActionTypes.R_SET_UPDATE_BUILD_PAYLOAD;
  payload: {
    payload: IUpdateBuildPayload;
  };
}
export const setUpdateBuildPayload = (
  payload: IUpdateBuildPayload
): SetUpdateBuildPayloadAction => ({
  type: ActionTypes.R_SET_UPDATE_BUILD_PAYLOAD,
  payload: {
    payload
  }
});

export interface SetUpdateResourcePayloadAction extends Action {
  type: ActionTypes.R_SET_UPDATE_RESOURCE_PAYLOAD;
  payload: {
    payload: IUpdateResourcePayload;
  };
}
export const setUpdateResourcePayload = (
  payload: IUpdateResourcePayload
): SetUpdateResourcePayloadAction => ({
  type: ActionTypes.R_SET_UPDATE_RESOURCE_PAYLOAD,
  payload: {
    payload
  }
});

export interface UpdateFlagAction extends Action {
  type: ActionTypes.R_UPDATE_FLAG;
  payload: {
    key: EFlagKey | EFlagKey[];
    bool: boolean;
  };
}
export const updateFlag = (
  key: EFlagKey | EFlagKey[],
  bool: boolean
): UpdateFlagAction => ({
  type: ActionTypes.R_UPDATE_FLAG,
  payload: {
    key,
    bool
  }
});

export type ReducerActions =
  | UpdateFlagAction
  | SetUpdateBuildPayloadAction
  | SetUpdateResourcePayloadAction
  | SetProcessingFlagAction
  | OpenModalAction
  | CloseModalAction
  | RemoveProcessingFlagAction
  | RemovedAlertAction
  | SetShouldUpdateResourceFlagAction
  | SetAppVersionAction
  | SetMaintenanceInfoAction
  | UpdateUserInputtingTextFlagAction
  | UpdateConnectingApiSetAction
  | UpdatePlayingPageTransitionAnimationFlagAction
  | UpdateConnectingApiSetAction
  | LaunchAction
  | ReadyForUserOperationAction;
