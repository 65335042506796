import { Record } from "immutable";
import moment from "moment";
import EvilDatetime from "../util/EvilDatetime";
import { EOrderTimeType } from "./OrderData";

interface IReservationTime {
  timeType: EOrderTimeType;
  date: Date;
}

const initial: IReservationTime = {
  timeType: EOrderTimeType.FASTEST,
  date: new Date()
};

/**
 * 予約注文に関する情報
 */
export default class ReservationTime extends Record(initial) {
  public static fromTimeTypeAndDatetimeString(
    timeType: EOrderTimeType,
    receiveDatetime: string
  ): ReservationTime {
    return new ReservationTime({
      timeType,
      date: moment(receiveDatetime).toDate()
    });
  }

  public static createFastest() {
    return new ReservationTime({
      timeType: EOrderTimeType.FASTEST
    });
  }

  /**
   * @param {YYYYMMDDHHmm} datetimeString
   */
  public static fromDatetimeString(datetimeString: string) {
    return new ReservationTime({
      timeType: EOrderTimeType.SPECIFIED,
      date: moment(datetimeString, "YYYYMMDDHHmm").toDate()
    });
  }

  private constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        date: args.date
          ? typeof args.date === "string"
            ? new Date(args.date)
            : args.date
          : initial.date
      })
    );
  }

  public validDate() {
    const date = this.getDate();
    return date instanceof Date && moment(date).isValid();
  }

  public getSettingText() {
    switch (this.getTimeType()) {
      case EOrderTimeType.FASTEST:
        return "今すぐ受取";
      case EOrderTimeType.SPECIFIED:
        return EvilDatetime.getDisplaySpecifiedDateString(
          this.getDateString(),
          this.getTimeString()
        );
      default:
        return "";
    }
  }

  public getTimeType() {
    return this.get("timeType");
  }

  public isFastest() {
    return this.getTimeType() === EOrderTimeType.FASTEST;
  }

  public getDate() {
    return this.get("date");
  }

  /**
   * @return YYYYMMDD
   */
  public getDateString() {
    return moment(this.getDate()).format("YYYYMMDD");
  }

  /**
   * @return HHmm
   */
  public getTimeString() {
    return moment(this.getDate()).format("HHmm");
  }

  /**
   * @return YYYYMMDDHHmm
   */
  public getDatetimeString() {
    return moment(this.getDate()).format("YYYYMMDDHHmm");
  }
}
