import React, { useCallback } from "react";
import Star from "./Star";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 1em;
`;

const Inner = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
`;

interface IProps {
  rate: number;
  size?: number;
}

const Stars: React.FC<IProps> = React.memo(props => {
  const { rate, size } = props;
  const percentage = useCallback(
    (index: number) => {
      return `${Math.min(Math.max(rate - index, 0), 1) * 100}%`;
    },
    [rate]
  );
  return (
    <>
      {Array(5)
        .fill("")
        .map((_, index) => {
          return (
            <Container key={index}>
              <Inner style={{ width: percentage(index) }}>
                <Star fillType="full" size={size} />
              </Inner>
              <Star fillType="empty" size={size} />
            </Container>
          );
        })}
    </>
  );
});

export default Stars;
