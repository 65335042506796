import { goBack } from "connected-react-router";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EFormID } from "../../forms";
import { EProcessingFlag } from "../../modules/app/model";
import {
  IUpdateTelForm,
  initialUpdateTelForm,
  validateUpdateTelForm
} from "../../forms/updateTel";
import { userSubmittedRegisterTelAtFirstAccess } from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Notes from "../atoms/Notes";
import SubPageHeader from "../molecules/SubPageHeader";
import InputTel from "../organisms/InputTel";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RegisterTelAtFirstAccessTemplate: React.FC<
  IProps & InjectedFormProps<IUpdateTelForm>
> = React.memo(props => {
  const {
    submittingRegisterTel,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialUpdateTelForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Page
      header={
        <SubPageHeader title="電話番号登録" handleClickBack={handleClickBack} />
      }
      containerStyle={containerStyle}
      contentStyle={contentStyle}
    >
      <PageBody style={pageBodyStyle}>
        <Notes style={{ padding: "40px 0" }}>
          本クーポンは携帯電話番号の認証が必要です。
          <br />
          お使いの携帯電話番号を入力してください。
          <br />
          SMSで認証コードをお送りします。
        </Notes>
        <InputTel
          handleSubmit={handleSubmitWrap}
          loading={submittingRegisterTel}
          disabled={!valid}
        />
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingRegisterTel: state.app.isProcessing(
    EProcessingFlag.SUBMIT_UPDATE_TEL
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleSubmit() {
    dispatch(userSubmittedRegisterTelAtFirstAccess());
  }
});

const reduxFormConfig = {
  form: EFormID.UPDATE_TEL,
  initialValues: initialUpdateTelForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateUpdateTelForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IUpdateTelForm, IProps>(reduxFormConfig)(
    RegisterTelAtFirstAccessTemplate
  )
);
