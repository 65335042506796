import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const HomeIcon: React.FC<IProps> = ({ size = 23, color = "#000", ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.137.43a.991.991 0 0 0-1.176 0L.96 7.78c-.26.19-.412.495-.412.818v12.627c0 .558.448 1.01 1 1.01h10.5c.552 0 1-.452 1-1.01v-6.571c0-.558.447-1.011 1-1.011h3c.552 0 1 .453 1 1.01v6.572c0 .558.448 1.01 1 1.01h2.5c.552 0 1-.452 1-1.01V8.598c0-.323-.153-.627-.412-.818l-10-7.351zm9.012 8.375l-9.6-7.058-9.6 7.058V20.82h9.7v-6.166c0-1.34 1.074-2.426 2.4-2.426h3c1.325 0 2.4 1.086 2.4 2.426v6.166h1.7V8.805zm.164.12v.001z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
