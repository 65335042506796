import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { IUpdateShopFavoriteStateRequest } from "../../../apis/updateShopFavoriteState";
import { AlertType } from "../../../records/Alert";
import Analytics from "../../../util/Analytics";
import { systemAddedAlert } from "../../app/actions";
import { NAVIGATION_PATH_HOME } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateShopFavoriteState,
  UserTouchedChangeShopFavoriteStateButtonAction
} from "../actions";
import { removeProcessingFlag, setProcessingFlag } from "../../app/actions";
import { EProcessingFlag } from "../../app/model";

export default function* userTouchedChangeShopFavoriteStateButtonSaga(
  action: UserTouchedChangeShopFavoriteStateButtonAction
) {
  yield put(setProcessingFlag(EProcessingFlag.UPDATING_FAVORITE_STATE));
  const { shopId, bool } = action.payload;
  yield put(updateShopFavoriteState(shopId, bool));
  const params: IUpdateShopFavoriteStateRequest = {
    shop_id: shopId
  };

  const pathName = location.pathname;
  if (pathName === NAVIGATION_PATH_HOME) {
    if (action.payload.bool) {
      Analytics.logEvent("home_like");
    } else {
      Analytics.logEvent("home_like_cancel");
    }
  }

  try {
    const commonApiTask = yield fork(
      commonApiSaga,
      EApiKey.UPDATE_SHOP_FAVORITE_STATE,
      params,
      { fallback: true }
    );

    const { result, error } = yield join(commonApiTask);

    if (!(result && !error)) {
      throw error;
    }
  } catch (exception) {
    yield put(updateShopFavoriteState(shopId, !bool));
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `お気に入り登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.UPDATING_FAVORITE_STATE));
  }
}
