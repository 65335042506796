import { List } from "immutable";
import { fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdateCartListRequest,
  IUpdateCartListResponse
} from "../../../apis/updateCartList";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert, systemClosedModal } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCart, UserChangedCartReservationTimeAction } from "../actions";

export default function* userChangedCartReservationTimeSaga(
  action: UserChangedCartReservationTimeAction
) {
  try {
    const { cartId, reservationTime } = action.payload;
    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());
    const cart = CartData.findById(cartList, cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    // 更新しないが、リクエストに乗せる為に取得
    const firstItem = cart.getFirstItem();
    if (typeof firstItem === "undefined") {
      throw new Error("cart is empty.");
    }

    const params: IUpdateCartListRequest = {
      shop_id: cart.getShopId(),
      index: 0,
      count: firstItem.getOrderCount(),
      option_list: firstItem.getSelectedOptionIdArray(),
      instruction_text: firstItem.getInstructionText(),
      time_type: reservationTime.getTimeType(),
      receive_datetime: reservationTime.getDatetimeString()
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.UPDATE_CART_LIST, params)
    );

    const {
      result,
      error
    }: { result: IUpdateCartListResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (result && !error) {
      yield put(updateCart(result.cart_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `受取時間の変更に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(systemClosedModal("SETTING_RESERVATION_FOR_CART"));
  }
}
