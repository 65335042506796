import { goBack, push } from "connected-react-router";
import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import {
  initFeatureDetail,
  userAccessedToPageThatNeedsFeatureDetail
} from "../../modules/notification/actions";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Utility from "../../util/Utility";
import ErrorBoundary from "../atoms/ErrorBoundary";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";

const FeaturePageBody = styled(PageBody)`
  padding: 0;
  padding-bottom: calc(37px + constant(safe-area-inset-bottom));
  padding-bottom: calc(37px + env(safe-area-inset-bottom));
  background-color: ${colorsConst.BACKGROUND};
`;
const LoadingContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

interface IProps {
  featureId: number;
  featureDetail: string | null;
  actions: ActionDispatcher;
}

const FeatureTemplate: React.FC<IProps> = React.memo(props => {
  const { featureId, featureDetail, actions } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  const handleClick = useCallback(
    (event: Event, url: string) => {
      event.preventDefault();
      actions.handleClick(url);
    },
    [actions.handleClick]
  );

  const featureDetailString = useCallback(() => {
    return featureDetail
      ? featureDetail.replace(
          /img([\s\S]+?)src=\"/g,
          `img$1src=\"${Utility.getImageDomain()}/`
        )
      : "";
  }, [featureDetail]);

  useEffect(() => {
    actions.userAccessedToPageThatNeedsFeatureDetail(featureId);
    return () => {
      actions.initFeatureDetail();
    };
  }, []);

  const featurePageBodyRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (featurePageBodyRef.current) {
      const pattern = /^https?:\/\//;
      const anchors = Array.prototype.slice.call(
        featurePageBodyRef.current.querySelectorAll("a")
      );
      anchors.map((anchor: HTMLAnchorElement) => {
        const url = anchor.getAttribute("href");
        if (url !== null && !pattern.test(url)) {
          anchor.addEventListener("click", (event: Event) => {
            handleClick(event, url);
          });
        }
      });
    }
  }, [featureDetail]);

  return (
    <Page
      header={<SubPageHeader title="特集" handleClickBack={handleGoBack} />}
    >
      {featureDetail === null ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <ErrorBoundary>
          <FeaturePageBody
            ref={featurePageBodyRef}
            dangerouslySetInnerHTML={{ __html: featureDetailString() }}
          />
        </ErrorBoundary>
      )}
    </Page>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ featureId: string }>
) => ({
  featureId: Number(ownProps.match.params.featureId),
  featureDetail: state.notification.getFeatureDetail()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  public userAccessedToPageThatNeedsFeatureDetail(featureId: number) {
    this.dispatch(userAccessedToPageThatNeedsFeatureDetail(featureId));
  }

  public initFeatureDetail() {
    this.dispatch(initFeatureDetail());
  }

  public goBack() {
    this.dispatch(goBack());
  }

  public handleClick(url: string) {
    this.dispatch(push(url));
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeatureTemplate)
);
