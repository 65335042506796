import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetInformationRequest,
  IGetInformationResponse
} from "../../../apis/getInformation";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import getUserDataSaga from "../../user/sagas/getUserDataSaga";
import {
  finishedFetchingNotifications,
  startedFetchingNotifications,
  updateNewsList,
  updateNotificationList,
  UserAccessedToPageThatNeedsNotificationsAction
} from "../actions";

export default function* userAccessedToPageThatNeedsNotificationsSaga(
  action: UserAccessedToPageThatNeedsNotificationsAction
) {
  try {
    const params: IGetInformationRequest = {};
    yield put(startedFetchingNotifications());
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_INFORMATION, params)
    );
    const {
      result,
      error
    }: { result: IGetInformationResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield put(finishedFetchingNotifications());
      yield cancel();
    }
    if (result && !error) {
      yield put(finishedFetchingNotifications());
      const notificationList = result.notification_list;
      const newsList = result.news_list;
      yield put(updateNotificationList(notificationList));
      yield put(updateNewsList(newsList));
      yield fork(() => getUserDataSaga());
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(finishedFetchingNotifications());
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `お知らせ情報の取得に失敗しました。
            通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
