import React, { useCallback } from "react";
import styled from "styled-components";
import ShopInfo from "../../records/ShopInfo";
import MemoTextInput from "../atoms/MemoTextInput";
import RatingInput from "./RatingInput";

const ShopName = styled.h2`
  padding: 18px 3px 35px;
  font-size: 1.9rem;
  font-weight: 500;
  text-align: center;
`;

const MessageContainer = styled.div`
  padding: 12px;
  background-color: #efefef;
  border-radius: 3px;
  font-size: 1.1rem;
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.15em;
`;

const StarContainer = styled.div`
  padding: 36px 24px;
`;

interface IProps {
  rating: number;
  comment: string;
  shopInfo: ShopInfo;
  handleChangeComment: (comment: string) => void;
  handleChangeRating: (rating: number) => void;
  children?: never;
}

const FeedbackContent: React.FC<IProps> = React.memo(props => {
  const { rating, comment, shopInfo } = props;

  const handleChangeComment = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.currentTarget.value;
      props.handleChangeComment(value);
    },
    [props.handleChangeComment]
  );

  const handleChangeRating = useCallback(
    (newRating: number) => {
      props.handleChangeRating(newRating);
    },
    [props.handleChangeRating]
  );

  const message = `この度はご注文ありがとうございました。ご注文の品はいかがでしたでしょうか。よろしければご感想をいただけますでしょうか。`;

  return (
    <>
      <ShopName>{shopInfo.getShopName()}</ShopName>

      <MessageContainer>{message}</MessageContainer>

      <StarContainer>
        <RatingInput rating={rating} handleChangeRating={handleChangeRating} />
      </StarContainer>

      <MemoTextInput
        placeholder="コメント(500文字まで)"
        maxLength={500}
        onChange={handleChangeComment}
        value={comment}
      />
    </>
  );
});

export default FeedbackContent;
