import Appsflyer from "./Appsflyer";
import * as CDVPluginIDFA from "./CDVPluginIDFA";
import Utility from "./Utility";

export default class Advertising {
  private static get ENABLE_ADVERTISING_ID_FEATURE_BUILD_VERSION() {
    return "1.9.0";
  }

  // IDFA / AAID の取得
  public static getTrackingId(buildNumber: string) {
    return new Promise<string | undefined>(async (resolve, reject) => {
      try {
        let id: string | undefined;
        if (Advertising.enabledAdvertisingIdFeature(buildNumber)) {
          id = String(await CDVPluginIDFA.getAdvertisingId());
        } else {
          id = String(await Appsflyer.getDeviceId());
        }
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  }

  // idfa/aaidの取得が可能なbuildNumberか
  // falseの場合はAppsflyerのUIDが使われる
  private static enabledAdvertisingIdFeature(buildNumber: string) {
    return Utility.isSameOrNewerVersion(
      Advertising.ENABLE_ADVERTISING_ID_FEATURE_BUILD_VERSION,
      buildNumber
    );
  }
}
