import * as React from "react";
interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgGroup = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 30 22" fill="none" {...props}>
    <path
      d="M29.328 4.448a3.6 3.6 0 0 0-3.6-3.6H4.272a3.6 3.6 0 0 0-3.6 3.6v13.104a3.6 3.6 0 0 0 3.6 3.6h21.456a3.6 3.6 0 0 0 3.6-3.6V4.448zM4.236 2.288h21.492a2.16 2.16 0 0 1 2.16 2.16v.936H2.112v-.936a2.16 2.16 0 0 1 2.16-2.16h-.036zM27.888 6.86v2.196H2.112V6.86h25.776zm-2.16 12.852H4.272a2.16 2.16 0 0 1-2.16-2.16v-7.056h25.776v7.056a2.16 2.16 0 0 1-2.16 2.16z"
      fill="#5B5B5B"
    />
    <path
      d="M23.928 14.456h-3.456a.72.72 0 0 0 0 1.44h3.492a.72.72 0 0 0 0-1.44h-.036z"
      fill="#5B5B5B"
    />
  </svg>
);

const CardIcon: React.FC<IProps> = React.memo(props => <SvgGroup {...props} />);

export default CardIcon;
