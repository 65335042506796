import React, { useCallback } from "react";
import styled from "styled-components";
import StarIcon from "../atoms/StarIcon";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.6rem;
`;

const StarContainer = styled.div`
  flex: 0 0 auto;
`;

const Star: React.FC<{
  index: number;
  active: boolean;
  handleClick: (rating: number) => void;
}> = React.memo(props => {
  const { index, active } = props;

  const rate = index + 1;

  const color = active ? "#FFB800" : "#E4E4E4";

  const handleClick = useCallback(() => {
    props.handleClick(rate);
  }, [props.handleClick, rate]);

  return (
    <StarContainer onClick={handleClick}>
      <StarIcon color={color} />
    </StarContainer>
  );
});

interface IProps {
  rating: number;
  handleChangeRating?: (rating: number) => void;
}

const RatingInput: React.FC<IProps> = React.memo(props => {
  const { rating } = props;

  const isActive = useCallback(
    (index: number) => {
      return rating >= index + 1;
    },
    [rating]
  );

  const handleClick = useCallback(
    (newRating: number) => {
      if (props.handleChangeRating) {
        props.handleChangeRating(newRating);
      }
    },
    [props.handleChangeRating]
  );

  return (
    <Container>
      {Array(5)
        .fill("")
        .map((_, index) => (
          <Star
            key={index}
            active={isActive(index)}
            index={index}
            handleClick={handleClick}
          />
        ))}
    </Container>
  );
});

export default RatingInput;
