import { replace } from "connected-react-router";
import { cancel, put, select } from "redux-saga/effects";
import UserData from "../../../records/UserData";
import { ReduxModel } from "../../../reducer";
import { UserAccessedToPageThatNeedsAuthAction } from "../actions";

export function* userAccessedToPageThatNeedsAuthSaga(
  action: UserAccessedToPageThatNeedsAuthAction
) {
  const userData: UserData = yield select<(state: ReduxModel) => UserData>(
    state => state.user.getData()
  );
  if (userData.getMaintenanceFlag()) {
    yield put(replace("/maintenance"));
    return;
  }
  if (userData.getPasswordResetFlag()) {
    yield put(replace("/resetPassword"));
    return;
  }
  yield cancel();
}
