import { LocationChangeAction } from "connected-react-router";
import { cancel } from "redux-saga/effects";
import Analytics from "../../../util/Analytics";
import LocalRepository from "../../../util/LocalRepository";

export default function* changeLocationSaga(action: LocationChangeAction) {
  if (LocalRepository.hasItem("current_url")) {
    const current = LocalRepository.getItem("current_url");
    LocalRepository.setItem("referrer_url", current);
  }
  const pathName = action.payload.location.pathname;
  LocalRepository.setItem("current_url", `${location.origin}${pathName}`);
  Analytics.logEvent("app_change_location", {
    content_type: "pathname",
    item_id: pathName
  });
  yield cancel();
}
