import { push } from "connected-react-router";
import { fork, put, select, join } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import { FastestSearchSetting } from "../../../records/SearchSetting";
import { systemAddedAlert } from "../../app/actions";
import { updateSearchSetting } from "../actions";
import { ReduxModel } from "../../../reducer";
import PlaceListItem, {
  ERegistrationPlace
} from "../../../records/PlaceListItem";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import getSearchResultSaga from "./getSearchResultSaga";

export default function* userTouchedMoreNearOfficeShopListButtonSaga() {
  try {
    yield put(updateSearchSetting("fastest", FastestSearchSetting.asDefault()));

    const searchLocation: PlaceListItem | undefined = yield select<
      (state: ReduxModel) => PlaceListItem | undefined
    >(state =>
      state.user
        .getData()
        .getPlaceListItemByType(ERegistrationPlace.OFFICE_SCHOOL)
    );

    if (typeof searchLocation === "undefined") {
      throw new Error("location is null");
    }

    const searchPlace = SearchPlace.fromRegistrationPlace(searchLocation);

    const fetchingTask = yield fork(() =>
      getSearchResultSaga("fastest", searchPlace)
    );
    yield join(fetchingTask);
    yield put(push("/search/fastest"));
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
