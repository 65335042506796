import Slider from "rc-slider";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  MaxDisplayableCookingTime,
  toSettingFromSliderValue,
  toSliderValueFromSetting
} from "../../../records/SearchSetting/cookingTime";
import verticalDots from "./verticalDots.svg";
import Analytics from "../../../util/Analytics";

const sliderTrackStyle: React.CSSProperties = {
  height: "2px",
  backgroundColor: "#272727"
};

const sliderRailStyle: React.CSSProperties = {
  height: "2px",
  backgroundColor: "#757575"
};

const sliderDotStyle: React.CSSProperties = {
  backgroundColor: "#757575",
  border: "solid 2px #ffffff",
  borderWidth: "0 2px",
  width: "6px",
  height: "2px",
  bottom: 0,
  margin: 0,
  borderRadius: 0,
  transform: "translateX(-50%)"
};

const sliderActiveDotStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  border: "solid 2px #ffffff",
  borderWidth: "0 2px",
  width: "6px",
  height: "17px",
  bottom: 0,
  margin: 0,
  borderRadius: 0,
  transform: "translateX(-50%)",
  backgroundImage: `url(${verticalDots})`
};

const SliderContainer = styled.div`
  padding: 15px;
`;

const SliderInner = styled.div`
  padding: 20px 14px;
  .rc-slider-step {
    height: 2px;
  }
`;

const sliderHandleStyle: React.CSSProperties = {
  width: "32px",
  height: "32px",
  background: "#FFFFFF",
  border: "1px solid #D4D4D8",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
  borderRadius: "100%",
  transform: "translate(-10px, -10px)",
  cursor: "pointer"
};

const BottomText = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: ${20 / 14};
  color: #666666;
  border-bottom: 2px solid #ffa592;
  font-weight: 500;
`;

const BottomTextContainer = styled.div`
  text-align: center;
`;

const SliderMarks = styled.div<{ active: boolean }>`
  padding: 20px 20px 0 20px;
  position: absolute;
  bottom: 0;
  transition: transform 0.25s ease;
  white-space: nowrap;
  transform: translate(-50%, -${p => (p.active ? 38 : 24)}px);
`;

interface IProps {
  currentSettingValue: MaxDisplayableCookingTime;
  handleChange: (value: MaxDisplayableCookingTime) => void;
}

const CookingTimeSlider: React.FC<IProps> = React.memo(
  ({ currentSettingValue, handleChange }) => {
    const handleChangeCookingTimeSlider = useCallback(
      (value: number) => {
        Analytics.logEvent("search_filter_set_time", {
          content_type: "time",
          item_id: `${value}`
        });
        handleChange(toSettingFromSliderValue(value));
      },
      [handleChange]
    );

    const isActiveSliderMarker = useCallback(
      (value: MaxDisplayableCookingTime) => {
        if (currentSettingValue === null) {
          return true;
        }
        if (value === null) {
          return value === currentSettingValue;
        }
        return currentSettingValue >= value;
      },
      [currentSettingValue]
    );

    const sliderMarks = useMemo(() => {
      return {
        0: <SliderMarks active={isActiveSliderMarker(10)}>10分</SliderMarks>,
        33: <SliderMarks active={isActiveSliderMarker(20)}>20分</SliderMarks>,
        66: <SliderMarks active={isActiveSliderMarker(30)}>30分</SliderMarks>,
        100: (
          <SliderMarks active={isActiveSliderMarker(null)}>30分+</SliderMarks>
        )
      };
    }, [isActiveSliderMarker]);

    return (
      <SliderContainer>
        <SliderInner>
          <Slider
            onChange={handleChangeCookingTimeSlider}
            min={0}
            value={toSliderValueFromSetting(currentSettingValue)}
            marks={sliderMarks}
            step={null}
            trackStyle={sliderTrackStyle}
            railStyle={sliderRailStyle}
            dotStyle={sliderDotStyle}
            activeDotStyle={sliderActiveDotStyle}
            handleStyle={sliderHandleStyle}
          />
        </SliderInner>
        <BottomTextContainer>
          <BottomText>以内に出来上がり</BottomText>
        </BottomTextContainer>
      </SliderContainer>
    );
  }
);

export default CookingTimeSlider;
