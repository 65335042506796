import React from "react";
import styled from "styled-components";

const Container = styled.div`
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;

interface IProps extends React.SVGAttributes<SVGElement> {}

const GotoCurrentLocationButton: React.FC<IProps> = React.memo(
  ({ ...props }) => {
    return (
      <Container>
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" {...props}>
          <circle
            cx="24"
            cy="24.0107"
            r="24"
            transform="rotate(90 24 24.0107)"
            fill="white"
          />
          <path
            d="M33.8988 16.4007L23.5448 35.7635C23.0724 36.6471 21.7385 36.364 21.6656 35.3646L21.0519 26.9465C21.0192 26.4979 20.691 26.1264 20.2499 26.0385L12.3597 24.4671C11.3702 24.27 11.2621 22.8978 12.2086 22.5483L32.6705 14.991C33.5333 14.6723 34.3325 15.5895 33.8988 16.4007Z"
            fill="#81B826"
          />
        </svg>
      </Container>
    );
  }
);

export default GotoCurrentLocationButton;
