import { List, Record } from "immutable";
import moment from "moment";

interface IFeedbackOrderData {
  item_name: string;
}

export interface IFeedback {
  point: number;
  comment: string;
  sent_datetime: string;
  item_list: List<IFeedbackOrderData>;
}

const initial: IFeedback = {
  point: 0.0,
  comment: "",
  sent_datetime: "",
  item_list: List<IFeedbackOrderData>()
};

export default class Feedback extends Record(initial) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        item_list: List(args.item_list && args.item_list)
      })
    );
  }

  public getItemList() {
    return this.get("item_list");
  }

  public getPresentationalFeedbackItemName(): string {
    const itemList = this.getItemList();
    const item = itemList.first<undefined>();
    if (typeof item === "undefined") {
      return "";
    }
    const suffix = itemList.size <= 1 ? "" : " 他";
    return `${item.item_name}${suffix}`;
  }

  public getPoint() {
    return this.get("point");
  }

  public getPointAsFloat() {
    try {
      return parseFloat(`${this.getPoint()}`);
    } catch {
      return 0.0;
    }
  }

  public canDisplay() {
    return this.getPointAsFloat() !== 0.0 && this.getComment().trim() !== "";
  }

  public getComment() {
    return this.get("comment");
  }

  public getSentDatetime() {
    return this.get("sent_datetime");
  }

  public getSentDatetimeAsDate() {
    return moment(this.getSentDatetime(), "YYYY-MM-DD HH:mm:ss").toDate();
  }

  public getPresentationalSentTime(): string {
    const now = moment();
    const sent = moment(this.getSentDatetimeAsDate());
    for (const [unit, suffix] of [
      ["month", "か月"],
      ["week", "週間"],
      ["day", "日"],
      ["hour", "時間"],
      ["minute", "分"]
    ] as Array<["month" | "week" | "day" | "hour" | "minute", string]>) {
      const diff = now.diff(sent, unit);
      if (unit === "month" && diff >= 2) {
        return `${diff}${suffix}以上前`;
      }
      if (diff > 0) {
        return `${diff}${suffix}前`;
      }
    }
    return "たった今";
  }
}
