import { push } from "connected-react-router";
import { List } from "immutable";
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { batch, connect } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { systemOpenedModal } from "../../modules/app/actions";
import { EProcessingFlag } from "../../modules/app/model";
import {
  updateReservationTimeForDialog,
  userChangedOrderItemQuantity,
  userTouchedDeleteCartItemButton,
  userTouchedOpenSettingReservationTime,
  userTouchedOrderButton,
  userTouchedRequestClearCartButton
} from "../../modules/order/actions";
import ReservationTime from "../../records/ReservationTime";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import ScrollTopMemory, { EScrollTopKey } from "../../util/ScrollTopMemory";
import { useScrollContainer } from "../atoms/Page";
import Cart from "./Cart";

const CartContainer = styled.div``;

const CartElement = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
`;

const mapStateToProps = (state: ReduxModel) => ({
  cartList: state.order.getCartList(),
  submittingOrder: state.app.isProcessing(EProcessingFlag.SUBMIT_ORDER),
  fetchingCreditCardList: state.app.isConnectedApi(
    EApiKey.GET_CREDIT_CARD_LIST
  ),
  creditCard: state.user.getCreditCard(),
  submittingChangeQuantity: state.app.isConnectedApi(EApiKey.UPDATE_CART_LIST),
  submittingDeleteItem: state.app.isConnectedApi(EApiKey.DELETE_CART_LIST_ITEM),
  submittingClearCart: state.app.isConnectedApi(
    EApiKey.CLEAR_CART_LIST_WITH_SHOP
  )
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  changeOrderQuantity(cartId: string, index: number, quantity: number) {
    dispatch(userChangedOrderItemQuantity(cartId, index, quantity));
  },
  handleDeleteItem(cartId: string, index: number) {
    dispatch(userTouchedDeleteCartItemButton(cartId, index));
  },
  handleClearCart(cartId: string) {
    dispatch(userTouchedRequestClearCartButton(cartId));
  },
  handleSubmitOrder(cartId: string) {
    dispatch(userTouchedOrderButton(cartId));
    Analytics.logEvent("cart_order");
  },
  goToShop(shopId: number) {
    ScrollTopMemory.clear(`${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`);
    dispatch(push(`/shop/${shopId}`));
  },
  openSettingReservationTimeDialog(
    cartId: string,
    reservationTime: ReservationTime
  ) {
    batch(() => {
      dispatch(updateReservationTimeForDialog(reservationTime));
      dispatch(userTouchedOpenSettingReservationTime(cartId));
    });
  },
  goToSelectCreditCard() {
    dispatch(push("user/changeCreditCard"));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const CartList: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    cartList,
    creditCard,
    submittingOrder,
    fetchingCreditCardList,
    changeOrderQuantity,
    handleDeleteItem,
    handleClearCart,
    handleSubmitOrder,
    submittingChangeQuantity,
    submittingDeleteItem,
    submittingClearCart,
    goToShop,
    openSettingReservationTimeDialog,
    goToSelectCreditCard
  } = props;

  const handleClickChangeCreditCargButton = useCallback(() => {
    Analytics.logEvent("cart_credit");
    goToSelectCreditCard();
  }, [goToSelectCreditCard]);

  const handleChangeOrderQuantity = useCallback(
    (cartId: string, index: number, quantity: number) => {
      changeOrderQuantity(cartId, index, quantity);
    },
    [changeOrderQuantity]
  );

  const handleTouchedReservationTimeSettingButton = useCallback(
    (cartId: string, reservationTime: ReservationTime) => {
      Analytics.logEvent("cart_change_time");
      openSettingReservationTimeDialog(cartId, reservationTime);
    },
    [openSettingReservationTimeDialog]
  );

  // ここからスクロール処理
  const scrollContainer = useScrollContainer();

  const cartRef = useRef<HTMLDivElement>(null);

  const getCurrentScrollTop = useCallback(() => {
    return scrollContainer ? scrollContainer.scrollTop : 0;
  }, [scrollContainer]);

  const [cartButtonPositions, setCartButtonPositions] = useState(
    List<number>()
  );

  // cartButtonPositionsの初期化
  useEffect(() => {
    if (cartRef.current) {
      const positionList = List<number>(
        Array.from(cartRef.current.children).map(child => {
          return Math.floor(
            child.getBoundingClientRect().bottom + getCurrentScrollTop() - 49
          );
        })
      );
      setCartButtonPositions(positionList);
    }
  }, [cartList, cartRef.current, getCurrentScrollTop]);

  const recentPassedScrollPosition = useCallback(() => {
    if (scrollContainer) {
      return cartButtonPositions.reverse().find((position: number) => {
        return position - scrollContainer.clientHeight <= getCurrentScrollTop();
      });
    }
  }, [scrollContainer, cartButtonPositions]);

  const recentPassedScrollPositionIndex = useCallback(() => {
    const currentPosition = recentPassedScrollPosition();
    return typeof currentPosition === "undefined"
      ? 0
      : cartButtonPositions.indexOf(currentPosition);
  }, [recentPassedScrollPosition, cartButtonPositions]);

  const [recentPassedIndex, setRecentPassedIndex] = useState<number>(0);

  const handleScroll = useCallback(() => {
    if (!cartButtonPositions.isEmpty()) {
      setRecentPassedIndex(recentPassedScrollPositionIndex());
    }
  }, [cartButtonPositions, recentPassedScrollPositionIndex]);

  // スクロールイベントの登録と解除
  useEffect(() => {
    const listener = handleScroll;
    document.addEventListener("scroll", listener, true);
    return () => {
      document.removeEventListener("scroll", listener, true);
    };
  }, [handleScroll]);

  const isPassedScroll = useCallback(
    (cartIndex: number) => {
      return recentPassedIndex > cartIndex;
    },
    [recentPassedIndex]
  );

  return (
    <CartContainer ref={cartRef}>
      {cartList.map((cart, index) => (
        <CartElement
          key={cart.getId()}
          style={{
            marginTop: index === 0 ? 0 : "8px"
          }}
        >
          <Cart
            cart={cart}
            submittingOrder={submittingOrder}
            fetchingCreditCardList={fetchingCreditCardList}
            creditCard={creditCard}
            handleChangeOrderQuantity={handleChangeOrderQuantity}
            handleClickChangeCreditCardButton={
              handleClickChangeCreditCargButton
            }
            handleClearCart={handleClearCart}
            goToShop={goToShop}
            handleDeleteItem={handleDeleteItem}
            handleSubmitOrder={handleSubmitOrder}
            submittingChangeQuantity={submittingChangeQuantity}
            submittingDeleteItem={submittingDeleteItem}
            submittingClearCart={submittingClearCart}
            isStaticButton={isPassedScroll(index)}
            zIndex={cartList.size - index}
            handleTouchedReservationTimeSettingButton={
              handleTouchedReservationTimeSettingButton
            }
          />
        </CartElement>
      ))}
    </CartContainer>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartList);
