import { push } from "connected-react-router";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { userAccessedToPageThatNeedsOrderHistoryListForSearchResult } from "../../modules/search/actions";
import OrderData from "../../records/OrderData";
import { ReduxModel } from "../../reducer";
import ReceiptIcon from "../atoms/ReceiptIcon";
import ThumbnailContainer from "../atoms/ThumbnailContainer";
import Thumbnail from "../molecules/Thumbnail";

const Inner = styled.div`
  padding: 15px;
`;

const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;

const HeadIcon = styled.div`
  flex: 0 0 auto;
  padding-bottom: 3px;
`;

const HeadText = styled.div`
  flex: 1 1 auto;
  padding-left: 4px;
  font-size: 15px;
  line-height: ${22 / 15};
  font-weight: 500;
  color: #000000;
`;

const Divider = styled.div`
  margin: 0 15px;
  height: 2px;
  background-color: #efefef;
`;

const ItemListContainer = styled.div`
  overflow: hidden;
`;

const ItemList = styled.ul`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: -20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const ItemListItem = styled.li<{ index: number }>`
  padding-left: ${p => (p.index !== 0 ? 10 : 0)}px;
`;

interface IProps {}

const OrderHistoryShopItemList: React.FC<IProps> = () => {
  const orderHistoryList = useSelector((state: ReduxModel) => {
    return OrderData.filterOnlyCompleteOrder(
      state.order.getOrderList().take(10)
    );
  });

  const dispatch = useDispatch();

  const handleClick = useCallback(
    (shopId: number, itemId: number) => {
      dispatch(push(`/shop/${shopId}/item/${itemId}`));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsOrderHistoryListForSearchResult());
  }, [dispatch]);

  return orderHistoryList.isEmpty() ? null : (
    <div>
      <Inner>
        <HeadContainer>
          <HeadIcon>
            <ReceiptIcon color={"#ED2D21"} size={15} />
          </HeadIcon>
          <HeadText>注文履歴</HeadText>
        </HeadContainer>
        <ItemListContainer>
          <ItemList>
            {orderHistoryList.map((order, index) => {
              const item = order.getFirstOrderItem();
              return typeof item === "undefined" ? null : (
                <ItemListItem key={order.getOrderNumber()} index={index}>
                  <ThumbnailContainer
                    size={80}
                    borderRadius={8}
                    onClick={() =>
                      handleClick(
                        order.getShopInfo().getId(),
                        item.getShopItemId()
                      )
                    }
                  >
                    <Thumbnail src={item.getImagePath()} />
                  </ThumbnailContainer>
                </ItemListItem>
              );
            })}
          </ItemList>
        </ItemListContainer>
      </Inner>
      <Divider />
    </div>
  );
};

export default OrderHistoryShopItemList;
