import React, { useCallback } from "react";
import styled from "styled-components";
import CloseIcon from "../../atoms/CloseIcon";

interface IProps {
  disabled?: boolean;
  handleClick?: () => void;
  handleReset?: () => void;
}

const Container = styled.div`
  flex: 0 0 auto;
  padding-right: 10px;
`;

const Inner = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 29px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${p => (p.disabled ? "#C9CACA" : "#80b927")};
`;

const Text = styled.div<{ disabled?: boolean }>`
  font-size: 15px;
  line-height: ${22 / 15};
  font-weight: bold;
  color: ${p => (p.disabled ? "#C9CACA" : "#80b927")};
`;

const Icon = styled.div<{ disabled?: boolean }>`
  padding-left: 6px;
  font-size: 15px;
  color: ${p => (p.disabled ? "#C9CACA" : "#80b927")};
`;

const ConditionItem: React.FC<IProps> = ({
  children,
  handleClick,
  disabled,
  handleReset
}) => {
  const handleClickResetButton = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (handleReset) {
        event.stopPropagation();
        handleReset();
      }
    },
    [handleReset]
  );

  return (
    <Container onClick={handleClick}>
      <Inner disabled={disabled}>
        <Text disabled={disabled}>{children}</Text>
        <Icon disabled={disabled} onClick={handleClickResetButton}>
          {handleReset ? <CloseIcon size={15} color={"#80b927"} /> : "▼"}
        </Icon>
      </Inner>
    </Container>
  );
};

export default ConditionItem;
