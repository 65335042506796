import { Action } from "redux";
import { ModalKey } from "../../../records/ModalManager";
import ReservationTime from "../../../records/ReservationTime";
import { SearchResultType } from "../../../records/SearchResult";
import { IAddToCartRequestParams } from "../model";
import { EPaymentType } from "../../../records/PaymentType";
import { ActionTypes } from "./index";

export interface SystemStartedPollingOrderDetailAction extends Action {
  type: ActionTypes.S_STARTED_POLLING_ORDER_DETAIL;
  payload: {
    orderNumber: string;
  };
}
export const systemStartedPollingOrderDetail = (
  orderNumber: string
): SystemStartedPollingOrderDetailAction => ({
  type: ActionTypes.S_STARTED_POLLING_ORDER_DETAIL,
  payload: {
    orderNumber
  }
});

export interface SystemStoppedPollingOrderDetailAction extends Action {
  type: ActionTypes.S_STOPPED_POLLING_ORDER_DETAIL;
}
export const systemStoppedPollingOrderDetail = (): SystemStoppedPollingOrderDetailAction => ({
  type: ActionTypes.S_STOPPED_POLLING_ORDER_DETAIL
});

export interface SystemFailedPollingOrderDetailAction extends Action {
  type: ActionTypes.S_FAILED_POLLING_ORDER_DETAIL;
}
export const systemFailedPollingOrderDetail = (): SystemFailedPollingOrderDetailAction => ({
  type: ActionTypes.S_FAILED_POLLING_ORDER_DETAIL
});

export interface UserTouchedRequestClearCartButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_REQUEST_CLEAR_CART_BUTTON;
  payload: {
    cartId: string;
  };
}
export const userTouchedRequestClearCartButton = (
  cartId: string
): UserTouchedRequestClearCartButtonAction => ({
  type: ActionTypes.U_TOUCHED_REQUEST_CLEAR_CART_BUTTON,
  payload: {
    cartId
  }
});

export interface UserAccessedToCartAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_CART;
}
export const userAccessedToCart = (): UserAccessedToCartAction => ({
  type: ActionTypes.U_ACCESSED_TO_CART
});

export interface UserSubmittedAddToCartAction extends Action {
  type: ActionTypes.U_SUBMITTED_ADD_TO_CART;
  payload: {
    resultType: SearchResultType;
    params: Pick<IAddToCartRequestParams, "shop_id" | "shop_item_id">;
  };
}
export const userSubmittedAddToCart = (
  resultType: SearchResultType,
  params: Pick<IAddToCartRequestParams, "shop_id" | "shop_item_id">
): UserSubmittedAddToCartAction => ({
  type: ActionTypes.U_SUBMITTED_ADD_TO_CART,
  payload: {
    resultType,
    params
  }
});

export interface UserSubmittedUpdateCartAction extends Action {
  type: ActionTypes.U_SUBMITTED_UPDATE_CART;
  payload: {
    cartId: string;
    index: number;
    count: number;
    optionList: number[];
    instructionText: string;
  };
}
export const userSubmittedUpdateCart = (
  cartId: string,
  index: number,
  count: number,
  optionList: number[],
  instructionText: string
): UserSubmittedUpdateCartAction => ({
  type: ActionTypes.U_SUBMITTED_UPDATE_CART,
  payload: {
    cartId,
    index,
    count,
    optionList,
    instructionText
  }
});

export interface UserTouchedDeleteCartItemButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_DELETE_CART_ITEM_BUTTON;
  payload: {
    cartId: string;
    deleteKey: number;
    // カート編集画面からの実行か
    // TODO: actionを分ける
    isCartEdit?: boolean;
  };
}
export const userTouchedDeleteCartItemButton = (
  cartId: string,
  deleteKey: number,
  isCartEdit?: boolean
): UserTouchedDeleteCartItemButtonAction => ({
  type: ActionTypes.U_TOUCHED_DELETE_CART_ITEM_BUTTON,
  payload: {
    cartId,
    deleteKey,
    isCartEdit
  }
});

export interface UserSubmittedOrderAction extends Action {
  type: ActionTypes.U_SUBMITTED_ORDER;
  payload: {
    cartId: string;
  };
}
export const userSubmittedOrder = (
  cartId: string
): UserSubmittedOrderAction => ({
  type: ActionTypes.U_SUBMITTED_ORDER,
  payload: {
    cartId
  }
});

export interface UserSubmittedCancelOrderAction extends Action {
  type: ActionTypes.U_SUBMITTED_CANCEL_ORDER;
  payload: {
    orderNumber: string;
  };
}
export const userSubmittedCancelOrder = (
  orderNumber: string
): UserSubmittedCancelOrderAction => ({
  type: ActionTypes.U_SUBMITTED_CANCEL_ORDER,
  payload: {
    orderNumber
  }
});

export interface UserAccessedToPageThatNeedsOrderHistoryAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY;
}
export const userAccessedToPageThatNeedsOrderHistory = (): UserAccessedToPageThatNeedsOrderHistoryAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY
});

export interface UserAccessedToPageThatNeedsOrderDetailAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL;
  payload: {
    orderNumber: string;
  };
}
export const userAccessedToPageThatNeedsOrderDetail = (
  orderNumber: string
): UserAccessedToPageThatNeedsOrderDetailAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL,
  payload: {
    orderNumber
  }
});

export interface UserAccessedToPreOrderPageAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PRE_ORDER_PAGE;
  payload: {
    initial: {
      option_list: number[];
      count: number;
      instruction_text: string;
    };
  };
}
export const userAccessedToPreOrderPage = (initial: {
  option_list: number[];
  count: number;
  instruction_text: string;
}): UserAccessedToPreOrderPageAction => ({
  type: ActionTypes.U_ACCESSED_TO_PRE_ORDER_PAGE,
  payload: {
    initial
  }
});

export interface UserChangedPreOrderOptionListAction extends Action {
  type: ActionTypes.U_CHANGED_PRE_ORDER_OPTION_LIST;
  payload: {
    optionList: number[];
  };
}
export const userChangedPreOrderOptionList = (
  optionList: number[]
): UserChangedPreOrderOptionListAction => ({
  type: ActionTypes.U_CHANGED_PRE_ORDER_OPTION_LIST,
  payload: {
    optionList
  }
});

export interface UserChangedPreOrderCountAction extends Action {
  type: ActionTypes.U_CHANGED_PRE_ORDER_COUNT;
  payload: {
    count: number;
  };
}
export const userChangedPreOrderCount = (
  count: number
): UserChangedPreOrderCountAction => ({
  type: ActionTypes.U_CHANGED_PRE_ORDER_COUNT,
  payload: {
    count
  }
});

export interface UserChangedPreOrderTextAction extends Action {
  type: ActionTypes.U_CHANGED_PRE_ORDER_TEXT;
  payload: {
    text: string;
  };
}
export const userChangedPreOrderText = (
  text: string
): UserChangedPreOrderTextAction => ({
  type: ActionTypes.U_CHANGED_PRE_ORDER_TEXT,
  payload: {
    text
  }
});

export interface UserChangedDraftFeedbackTextAction extends Action {
  type: ActionTypes.U_CHANGED_DRAFT_FEEDBACK_TEXT;
  payload: {
    orderNumber: string;
    text: string;
  };
}
export const userChangedDraftFeedbackText = (
  orderNumber: string,
  text: string
): UserChangedDraftFeedbackTextAction => ({
  type: ActionTypes.U_CHANGED_DRAFT_FEEDBACK_TEXT,
  payload: {
    orderNumber,
    text
  }
});

export interface UserChangedDraftFeedbackPointAction extends Action {
  type: ActionTypes.U_CHANGED_DRAFT_FEEDBACK_POINT;
  payload: {
    orderNumber: string;
    point: number;
  };
}
export const userChangedDraftFeedbackPoint = (
  orderNumber: string,
  point: number
): UserChangedDraftFeedbackPointAction => ({
  type: ActionTypes.U_CHANGED_DRAFT_FEEDBACK_POINT,
  payload: {
    orderNumber,
    point
  }
});

export interface UserSubmittedFeedbackAction extends Action {
  type: ActionTypes.U_SUBMITTED_FEEDBACK;
  payload: {
    orderNumber: string;
    rating: number;
    comment: string;
  };
}
export const userSubmittedFeedback = (
  orderNumber: string,
  rating: number,
  comment: string
): UserSubmittedFeedbackAction => ({
  type: ActionTypes.U_SUBMITTED_FEEDBACK,
  payload: {
    orderNumber,
    rating,
    comment
  }
});

export interface UserTouchedNotifyArrivalForLuxuryShopAction extends Action {
  type: ActionTypes.U_TOUCHED_NOTIFY_ARRIVAL_FOR_LUXURY_SHOP;
  payload: {
    orderNumber: string;
  };
}
export const userTouchedNotifyArrivalForLuxuryShop = (orderNumber: string) => ({
  type: ActionTypes.U_TOUCHED_NOTIFY_ARRIVAL_FOR_LUXURY_SHOP,
  payload: {
    orderNumber
  }
});

export interface UserTouchedOpenDialogWithOrderNumberAction extends Action {
  type: ActionTypes.U_TOUCHED_OPEN_DIALOG_WITH_ORDER_NUMBER;
  payload: {
    orderNumber: string;
    modalKey: ModalKey;
  };
}

export const userTouchedOpenDialogWithOrderNumber = (
  orderNumber: string | null,
  modalKey: ModalKey
) => ({
  type: ActionTypes.U_TOUCHED_OPEN_DIALOG_WITH_ORDER_NUMBER,
  payload: {
    orderNumber,
    modalKey
  }
});

export interface UserTouchedCloseDialogWithOrderNumberAction extends Action {
  type: ActionTypes.U_TOUCHED_CLOSE_DIALOG_WITH_ORDER_NUMBER;
  payload: {
    orderNumber: string;
    modalKey: ModalKey;
  };
}

export const userTouchedCloseDialogWithOrderNumber = (
  orderNumber: string | null,
  modalKey: ModalKey
) => ({
  type: ActionTypes.U_TOUCHED_CLOSE_DIALOG_WITH_ORDER_NUMBER,
  payload: {
    orderNumber,
    modalKey
  }
});

export interface UserTouchedSendReceiptMailAction extends Action {
  type: ActionTypes.U_TOUCHED_SEND_RECEIPT_MAIL;
  payload: {
    orderNumber: string;
  };
}
export const userTouchedSendReceiptMail = (
  orderNumber: string
): UserTouchedSendReceiptMailAction => ({
  type: ActionTypes.U_TOUCHED_SEND_RECEIPT_MAIL,
  payload: {
    orderNumber
  }
});

export interface UserChangedOrderItemQuantityAction extends Action {
  type: ActionTypes.U_CHANGED_ORDER_ITEM_QUANTITY;
  payload: {
    cartId: string;
    index: number;
    quantity: number;
  };
}
export const userChangedOrderItemQuantity = (
  cartId: string,
  index: number,
  quantity: number
): UserChangedOrderItemQuantityAction => ({
  type: ActionTypes.U_CHANGED_ORDER_ITEM_QUANTITY,
  payload: {
    cartId,
    index,
    quantity
  }
});

export interface SystemRequestedFetchCartListAction extends Action {
  type: ActionTypes.S_REQUESTED_FETCH_CART_LIST;
}
export const systemRequestedFetchCartList = (): SystemRequestedFetchCartListAction => ({
  type: ActionTypes.S_REQUESTED_FETCH_CART_LIST
});

export interface UserChangedCartReservationTimeAction extends Action {
  type: ActionTypes.U_CHANGED_CART_RESERVATION_TIME;
  payload: {
    cartId: string;
    reservationTime: ReservationTime;
  };
}
export const userChangedCartReservationTime = (
  cartId: string,
  reservationTime: ReservationTime
): UserChangedCartReservationTimeAction => ({
  type: ActionTypes.U_CHANGED_CART_RESERVATION_TIME,
  payload: {
    cartId,
    reservationTime
  }
});

export interface UserTouchedOpenSettingReservationTimeAction extends Action {
  type: ActionTypes.U_TOUCHED_OPEN_SETTING_RESERVATION_DIALOG_BUTTON;
  payload: {
    cartId: string;
  };
}
export const userTouchedOpenSettingReservationTime = (
  cartId: string
): UserTouchedOpenSettingReservationTimeAction => ({
  type: ActionTypes.U_TOUCHED_OPEN_SETTING_RESERVATION_DIALOG_BUTTON,
  payload: {
    cartId
  }
});

export interface UserChangedClearCartSelectOptionAction extends Action {
  type: ActionTypes.U_CHANGED_CLEAR_CART_SELECT_OPTION;
  payload: {
    cartId: string;
    index: number;
    optionSetId: number;
  };
}
export const userChangedClearCartSelectOption = (
  cartId: string,
  index: number,
  optionSetId: number
): UserChangedClearCartSelectOptionAction => ({
  type: ActionTypes.U_CHANGED_CLEAR_CART_SELECT_OPTION,
  payload: {
    cartId,
    index,
    optionSetId
  }
});

export interface UserTouchedOrderButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_ORDER_BUTTON;
  payload: {
    cartId: string;
  };
}
export const userTouchedOrderButton = (
  cartId: string
): UserTouchedOrderButtonAction => ({
  type: ActionTypes.U_TOUCHED_ORDER_BUTTON,
  payload: {
    cartId
  }
});

export interface UserConfirmedOrderAction extends Action {
  type: ActionTypes.U_CONFIRMED_ORDER;
}

export const userConfirmedOrder = (): UserConfirmedOrderAction => ({
  type: ActionTypes.U_CONFIRMED_ORDER
});

export interface UserSelectedPaymentTypeAction extends Action {
  type: ActionTypes.U_SELECTED_PAYMENT_TYPE;
  payload: {
    paymentType: EPaymentType;
  };
}
export const userSelectedPaymentType = (
  paymentType: EPaymentType
): UserSelectedPaymentTypeAction => ({
  type: ActionTypes.U_SELECTED_PAYMENT_TYPE,
  payload: {
    paymentType
  }
});

export type UserAndSystemActions =
  | SystemRequestedFetchCartListAction
  | UserChangedOrderItemQuantityAction
  | UserTouchedNotifyArrivalForLuxuryShopAction
  | UserChangedDraftFeedbackTextAction
  | UserChangedDraftFeedbackPointAction
  | UserSubmittedFeedbackAction
  | UserAccessedToPreOrderPageAction
  | UserChangedPreOrderOptionListAction
  | UserChangedPreOrderCountAction
  | UserChangedPreOrderTextAction
  | SystemStartedPollingOrderDetailAction
  | SystemStoppedPollingOrderDetailAction
  | SystemFailedPollingOrderDetailAction
  | UserAccessedToCartAction
  | UserTouchedRequestClearCartButtonAction
  | UserSubmittedUpdateCartAction
  | UserTouchedDeleteCartItemButtonAction
  | UserSubmittedOrderAction
  | UserSubmittedCancelOrderAction
  | UserAccessedToPageThatNeedsOrderHistoryAction
  | UserAccessedToPageThatNeedsOrderDetailAction
  | UserSubmittedAddToCartAction
  | UserTouchedCloseDialogWithOrderNumberAction
  | UserTouchedOpenDialogWithOrderNumberAction
  | UserTouchedSendReceiptMailAction
  | UserChangedCartReservationTimeAction
  | UserTouchedOpenSettingReservationTimeAction
  | UserChangedClearCartSelectOptionAction
  | UserTouchedOrderButtonAction
  | UserConfirmedOrderAction
  | UserSelectedPaymentTypeAction;
