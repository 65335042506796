import { Record } from "immutable";

export interface INotBusinessReason {
  main_text: "";
  sub_text: "";
}

const initial: INotBusinessReason = {
  main_text: "",
  sub_text: ""
};

export default class NotBusinessReason extends Record(initial) {
  public exists() {
    return this.getMainText() !== "";
  }

  public getMainText() {
    return this.get("main_text");
  }

  public getSubText() {
    return this.get("sub_text");
  }
}
