import React from "react";
import GeolocationPermissionDialog from "./GeolocationPermissionDialog";
import HomeFeedbackDialog from "./HomeFeedbackDialog";
import RegisterTooltipDialog from "./RegisterTooltipDialog";
import SettingFilterDialog from "./SettingFilterDialog";
import SettingPriceRangeDialog from "./SettingPriceRangeDialog";
import SettingReservationTimeDialog from "./SettingReservationTimeDialog";
import SubmittingAddCartDialog from "./SubmittingAddCartDialog";
import ZeroResultDialog from "./ZeroResultDialog";

interface IProps {
  children?: never;
}

const HomeModal: React.FC<IProps> = React.memo(props => {
  return (
    <>
      <SettingFilterDialog />
      <SettingReservationTimeDialog />
      <SettingPriceRangeDialog />
      <GeolocationPermissionDialog />
      <ZeroResultDialog />
      <RegisterTooltipDialog period />
      <RegisterTooltipDialog />
      <HomeFeedbackDialog />
      <SubmittingAddCartDialog />
    </>
  );
});

export default HomeModal;
