import { goBack } from "connected-react-router";
import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { systemClosedModal } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter
} from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";

const modalStyle: React.CSSProperties = {
  margin: "35px"
};

const closeButtonStyle: React.CSSProperties = {
  paddingTop: "23px",
  color: "#838383",
  textAlign: "center"
};

interface IProps {}

const SendRequestCompleteDialog: React.FC<IProps> = React.memo(props => {
  const isOpen = useSelector((state: ReduxModel) =>
    state.app.getModalManager().canDisplay("SEND_REQUEST_COMPLETE")
  );

  const dispatch = useDispatch();

  const handleClickClose = useCallback(() => {
    batch(() => {
      dispatch(goBack());
      dispatch(systemClosedModal("SEND_REQUEST_COMPLETE"));
    });
  }, [dispatch]);

  return isOpen ? (
    <Modal
      isOpen={true}
      innerStyle={modalStyle}
      handleClickBackdrop={handleClickClose}
    >
      <Dialog>
        <DialogHeader style={{ fontSize: "17px", fontWeight: "bold" }}>
          リクエストありがとうございました！
        </DialogHeader>
        <DialogBody>
          <Notes>
            現在menuでは、掲載店・エリアを拡大しております。リクエストをいただきましたお店やエリアでサービスが始まりましたら、お知らせさせていただきます。
          </Notes>
        </DialogBody>
        <DialogFooter>
          <div style={closeButtonStyle} onClick={handleClickClose}>
            閉じる
          </div>
        </DialogFooter>
      </Dialog>
    </Modal>
  ) : null;
});

export default SendRequestCompleteDialog;
