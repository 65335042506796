import { Record } from "immutable";

export interface IReceptionTime {
  start_time: string;
  end_time: string;
}

const initialValues: IReceptionTime = {
  start_time: "",
  end_time: ""
};

class ReceptionTime extends Record(initialValues) {
  constructor(args: any = {}) {
    super(args);
  }

  public getStartTime(): string {
    return this.get("start_time");
  }

  public getEndTime(): string {
    return this.get("end_time");
  }
}

export default ReceptionTime;
