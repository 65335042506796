import { RouterActionType } from "connected-react-router";
import * as History from "history";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Switch } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Dispatch } from "redux";
import styled, { css } from "styled-components";
import {
  systemFinishedPlayingPageTransitionAnimation,
  systemStartedPlayingPageTransitionAnimation
} from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";

const DURATION_MILLIS = 250;
const DURATION_SECONDS = "0.25s";

const Container = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export type RouterActionTypeAlter = RouterActionType | "NONE";

/**
 * -- Push
 */
const PushEnterCSS = css`
  z-index: 2;
  transform: translate3d(100%, 0, 0);
`;
const PushEnterActiveCSS = css`
  z-index: 2;
  will-change: transform;
  transition-property: transform;
  transition-duration: ${DURATION_SECONDS};
  transition-timing-function: ease-out;
  transform: translate3d(0, 0, 0);
`;
const PushEnterDoneCSS = css`
  z-index: 2;
  transform: translate3d(0, 0, 0);
`;
const PushExitCSS = css`
  z-index: 1;
`;
const PushExitActiveCSS = css`
  z-index: 1;
  will-change: none;
  transition: none;
  transform: none;
`;
const PushExitDoneCSS = css`
  z-index: 1;
  transform: translate3d(0, 0, 0);
`;

/**
 * -- Pop
 */
const PopEnterCSS = css`
  z-index: 1;
`;
const PopEnterActiveCSS = css`
  z-index: 1;
  will-change: none;
  transition: none;
  transform: none;
`;
const PopEnterDoneCSS = css`
  z-index: 1;
  transform: translate3d(0, 0, 0);
`;

const PopExitCSS = css`
  z-index: 2;
  transform: translate3d(0, 0, 0);
`;
const PopExitActiveCSS = css`
  z-index: 2;
  will-change: transform;
  transition-property: transform;
  transition-duration: ${DURATION_SECONDS};
  transition-timing-function: ease-out;
  transform: translate3d(100%, 0, 0);
`;
const PopExitDoneCSS = css`
  z-index: 2;
  transform: translate3d(100%, 0, 0);
`;

/**
 * -- Pop
 */
const NoneEnterCSS = css`
  z-index: 1;
`;
const NoneEnterActiveCSS = css`
  z-index: 1;
  will-change: none;
  transition: none;
  transform: none;
`;
const NoneEnterDoneCSS = css`
  z-index: 1;
  transform: translate3d(0, 0, 0);
`;

const NoneExitCSS = css`
  z-index: 2;
  transform: translate3d(0, 0, 0);
`;
const NoneExitActiveCSS = css`
  z-index: 2;
  will-change: none;
  transition: none;
  transform: none;
`;
const NoneExitDoneCSS = css`
  z-index: 2;
  transform: translate3d(100%, 0, 0);
`;

const CSSNameBase = "PageTransitionAnimation";

const getCSSTransitionClassNames = (
  routerActionType: RouterActionTypeAlter
) => {
  let suffix = "";
  switch (routerActionType.trim()) {
    case "POP":
      suffix = "Pop";
      break;
    case "PUSH":
      suffix = "Push";
      break;
    case "REPLACE":
      suffix = "Push";
      break;
    case "NONE":
      suffix = "None";
      break;
    default:
  }
  return `${CSSNameBase}${suffix}`;
};

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  /**
   * -- Push
   */
  &.${CSSNameBase}Push-enter {
    ${PushEnterCSS}
  }
  &.${CSSNameBase}Push-enter-active {
    ${PushEnterActiveCSS}
  }
  &.${CSSNameBase}Push-enter-done {
    ${PushEnterDoneCSS}
  }
  &.${CSSNameBase}Push-exit {
    ${PushExitCSS}
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done + & {
      ${PopExitCSS}
    }
    .${CSSNameBase}None-enter + &, .${CSSNameBase}None-enter-active + &, .${CSSNameBase}None-enter-done + & {
      ${NoneExitCSS}
    }
  }
  &.${CSSNameBase}Push-exit-active {
    ${PushExitActiveCSS}
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done + & {
      ${PopExitActiveCSS}
    }
    .${CSSNameBase}None-enter + &, .${CSSNameBase}None-enter-active + &, .${CSSNameBase}None-enter-done + & {
      ${NoneExitActiveCSS}
    }
  }
  &.${CSSNameBase}Push-exit-done {
    ${PushExitDoneCSS}
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done + & {
      ${PopExitDoneCSS}
    }
    .${CSSNameBase}None-enter + &, .${CSSNameBase}None-enter-active + &, .${CSSNameBase}None-enter-done + & {
      ${NoneExitDoneCSS}
    }
  }

  /**
   * -- Pop
   */
  &.${CSSNameBase}Pop-enter {
    ${PopEnterCSS}
  }
  &.${CSSNameBase}Pop-enter-active {
    ${PopEnterActiveCSS}
  }
  &.${CSSNameBase}Pop-enter-done {
    ${PopEnterDoneCSS}
  }
  &.${CSSNameBase}Pop-exit {
    ${PopExitCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done + & {
      ${PushExitCSS}
    }
    .${CSSNameBase}None-enter + &, .${CSSNameBase}None-enter-active + &, .${CSSNameBase}None-enter-done + & {
      ${NoneExitCSS}
    }
  }
  &.${CSSNameBase}Pop-exit-active {
    ${PopExitActiveCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done {
      ${PushExitActiveCSS}
    }
    .${CSSNameBase}None-enter + &, .${CSSNameBase}None-enter-active + &, .${CSSNameBase}None-enter-done {
      ${NoneExitActiveCSS}
    }
  }
  &.${CSSNameBase}Pop-exit-done {
    ${PopExitDoneCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done {
      ${PushExitDoneCSS}
    }
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done {
      ${PopExitDoneCSS}
    }
  }

  /**
   * -- None
   */
  &.${CSSNameBase}None-enter {
    ${NoneEnterCSS}
  }
  &.${CSSNameBase}None-enter-active {
    ${NoneEnterActiveCSS}
  }
  &.${CSSNameBase}None-enter-done {
    ${NoneEnterDoneCSS}
  }
  &.${CSSNameBase}None-exit {
    ${NoneExitCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done + & {
      ${PushExitCSS}
    }
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done + & {
      ${PopExitCSS}
    }
  }
  &.${CSSNameBase}None-exit-active {
    ${NoneExitActiveCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done {
      ${PushExitActiveCSS}
    }
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done {
      ${PopExitActiveCSS}
    }
  }
  &.${CSSNameBase}None-exit-done {
    ${NoneExitDoneCSS}
    .${CSSNameBase}Push-enter + &, .${CSSNameBase}Push-enter-active + &, .${CSSNameBase}Push-enter-done {
      ${PushExitDoneCSS}
    }
    .${CSSNameBase}Pop-enter + &, .${CSSNameBase}Pop-enter-active + &, .${CSSNameBase}Pop-enter-done {
      ${PopExitDoneCSS}
    }
  }
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  location: History.Location;
  routerActionType: RouterActionTypeAlter;
  actions: ActionDispatcher;
}

const PageTransitionSwitch = React.memo((props: IProps) => {
  const { location, routerActionType, actions, ...rest } = props;
  const animationEnabled = useMemo(() => {
    const env = process.env.REACT_APP_PAGE_TRANSITION_ANIMATION_ENABLED;
    return typeof env !== "undefined" && env === "true";
  }, []);

  const handleAnimationStart = useCallback(() => {
    actions.startPlayingAnimation();
  }, [actions.startPlayingAnimation]);

  const handleAnimationEnd = useCallback(() => {
    actions.finishPlayingAnimation();
  }, [actions.finishPlayingAnimation]);

  return animationEnabled ? (
    <TransitionGroup component={Container}>
      <CSSTransition
        key={props.location.pathname}
        classNames={getCSSTransitionClassNames(routerActionType)}
        timeout={routerActionType === "NONE" ? 0 : DURATION_MILLIS}
        onEnter={handleAnimationStart}
        onExited={handleAnimationEnd}
        unmountOnExit
      >
        <Inner>
          <Switch location={location} {...rest} />
        </Inner>
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <Switch {...rest} />
  );
});

const mapStateToProps = (state: ReduxModel) => {
  let routerActionType = state.router.action;
  if (
    state.router.location.state &&
    state.router.location.state.routerActionType
  ) {
    const pathname = state.router.location.pathname;
    const action = state.router.action;
    if (
      Object.keys(state.router.location.state.routerActionType).includes(
        `${action}${pathname}`
      )
    ) {
      routerActionType =
        state.router.location.state.routerActionType[`${action}${pathname}`];
    }
  }
  return {
    location: state.router.location,
    routerActionType
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public startPlayingAnimation() {
    this.dispatch(systemStartedPlayingPageTransitionAnimation());
  }

  public finishPlayingAnimation() {
    this.dispatch(systemFinishedPlayingPageTransitionAnimation());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTransitionSwitch);
