import { FormErrors } from "redux-form";

export interface IRequesrShopForm {
  posting_shop_name: string;
  recommend_reason: string;
  contact_phone_number: string;
}

export const initialRequesrShopForm: IRequesrShopForm = {
  posting_shop_name: "",
  recommend_reason: "",
  contact_phone_number: ""
};

export function validateRequesrShopForm(values: IRequesrShopForm) {
  const errors: FormErrors<IRequesrShopForm> = {};
  if (!values.posting_shop_name) {
    errors.posting_shop_name = "必須項目が入力されていません";
  }
  return errors;
}
