import { Record } from "immutable";
import ShopBusiness from "./ShopBusiness";

export interface IShopBusinessData {
  shop_business: ShopBusiness;
  is_busy: boolean;
  is_fastest_order: boolean;
  is_reservation_order: boolean;
  is_business: boolean;
}

const initialValues: IShopBusinessData = {
  shop_business: new ShopBusiness(),
  is_busy: false,
  is_fastest_order: false,
  is_reservation_order: false,
  is_business: true
};

export default class ShopBusinessData extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        shop_business:
          args.shop_business && new ShopBusiness(args.shop_business)
      })
    );
  }
  public isBusy(): boolean {
    return this.get("is_busy");
  }

  public isOpen(): boolean {
    return this.get("is_business");
  }

  public canFastestOrder(): boolean {
    return this.get("is_fastest_order");
  }

  public canReservationOrder(): boolean {
    return this.get("is_reservation_order");
  }

  public getShopBusiness(): ShopBusiness {
    return this.get("shop_business");
  }
}
