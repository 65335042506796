import { goBack } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ListLink from "../molecules/ListLink";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

const bodyStyle: React.CSSProperties = { padding: 0 };

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps {
  actions: ActionDispatcher;
}

const UserTermsTemplate = React.memo((props: IProps) => {
  const { actions } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="利用規約" handleClickBack={handleGoBack} />
        }
      >
        <PageBody style={bodyStyle}>
          <ul>
            <ListLink link="/user/terms/user">ユーザー利用規約</ListLink>
            <ListLink link="/user/terms/privacyPolicy">
              プライバシーポリシー
            </ListLink>
            <ListLink link="/user/terms/law">特定商取引法に基づく表示</ListLink>
            {/* <ListLink link="/user/terms/point">ポイント規約</ListLink> */}
          </ul>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTermsTemplate);
