export default {
  MAIN: "#FF3008",
  SECONDARY: "#81B826",
  BASE: "#000000",
  LINK: "#000000",
  DISABLED: "#c8c8c8",
  REMARKS: "#eaeaea", // 備考欄など
  WARNING: "#FC0000",
  SUCCESS: "#20C795",
  BACKGROUND: "#ffffff",
  PAGE_BACKGROUND: "#f2f2f4",
  TERTIARY: "#FF6056",
  BORDER: {
    LIGHT: "#c9caca",
    DARK: "#000000"
  },
  BUTTON: {
    TEXT: {
      DEFAULT: "#fff", // primary
      SECONDARY: "#4E4E4E", // secondary
      GRAYOUT: "#c8c8c8"
    },
    BACKGROUND: {
      DEFAULT: "#20C795", // primary
      SECONDARY: "#F6F6F6", // secondary
      DISABLED: "#c8c8c8",
      GRAYOUT: "#f2f2f4",
      TERTIARY: "#FF6056"
    }
  }
};
