import { List, Record } from "immutable";
import BusinessTime, { IBusinessTime } from "./BusinessTime";

export enum BusinessType {
  ONCE = 11,
  TWICE = 12,
  DAYOFF = 21
}

export interface IBusinessWeekTime {
  week: number;
  business_type: BusinessType;
  business_times: List<BusinessTime>;
}

const initialValues: IBusinessWeekTime = {
  week: 0,
  business_type: BusinessType.ONCE,
  business_times: List<BusinessTime>()
};

export default class BusinessWeekTime extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        business_times: List(
          args.business_times &&
            args.business_times.map(
              (item: IBusinessTime) => new BusinessTime(item)
            )
        )
      })
    );
  }

  public getWeek(): number {
    return this.get("week");
  }

  public getBusinessType(): BusinessType {
    return this.get("business_type");
  }

  public getBusinessTimes(): List<BusinessTime> {
    return this.get("business_times");
  }

  public isRegularHoliday() {
    return this.getBusinessType() === BusinessType.DAYOFF;
  }
}
