import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import { Helmet } from "react-helmet";
import AppContainer from "./components/organisms/AppContainer";
import GlobalContent from "./components/organisms/GlobalContent";
import GlobalFooter from "./components/organisms/GlobalFooter";
import GlobalModal from "./components/organisms/GlobalModal";
import ShouldUpdateModal from "./components/organisms/ShouldUpdateModal";
import { history } from "./store";
import BaseStyle from "./styles/global/base";
import GlobalStyle from "./styles/global/reset";

const appTitleEnv = process.env.REACT_APP_APP_TITLE;
const appTitle = typeof appTitleEnv !== "undefined" ? appTitleEnv : "menu";

export default (
  <ConnectedRouter history={history}>
    <>
      <Helmet htmlAttributes={{ lang: "ja" }}>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
        />
        <title>{appTitle}</title>
      </Helmet>
      <GlobalStyle />
      <BaseStyle />
      <AppContainer>
        <GlobalContent />
        <GlobalFooter />
        <GlobalModal />
      </AppContainer>
      <ShouldUpdateModal />
    </>
  </ConnectedRouter>
);
