import React from "react";

interface IProps {
  active: boolean;
  size?: number;
}

const ratio = 26 / 19;

const ShopFavoriteIcon: React.FC<IProps> = ({ size = 19, active }) => {
  return (
    <svg width={size} height={size * ratio} viewBox="0 0 19 26" fill="none">
      {active ? (
        <path
          d="M0.333496 0.833334C0.333496 0.373097 0.706592 0 1.16683 0H17.8335C18.2937 0 18.6668 0.373096 18.6668 0.833333V24.1667C18.6668 24.8697 17.8499 25.2568 17.3058 24.8116L9.50016 18.3333L1.69453 24.8116C1.15042 25.2568 0.333496 24.8697 0.333496 24.1667V0.833334Z"
          fill="#FF3008"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.50016 18.3333L17.3058 24.8116C17.8499 25.2568 18.6668 24.8697 18.6668 24.1667V0.833333C18.6668 0.373096 18.2937 0 17.8335 0H1.16683C0.706592 0 0.333496 0.373097 0.333496 0.833334V24.1667C0.333496 24.8697 1.15042 25.2568 1.69453 24.8116L9.50016 18.3333ZM3.66683 18.8429L8.96796 14.4432C9.27654 14.1871 9.72378 14.1871 10.0324 14.4432L15.3335 18.8429V3.33333H3.66683V18.8429Z"
          fill="#C9CACA"
        />
      )}
    </svg>
  );
};

export default ShopFavoriteIcon;
