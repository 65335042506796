import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: string;
}

const SvgNounMinus585754000000 = (props: IProps) => {
  const { color, ...rest } = props;
  const fillColor = color ? color : "black";
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 2" fill="none" {...rest}>
      <path d="M0 1H12" stroke={fillColor} strokeWidth="2" />
    </svg>
  );
};

const MinusIcon = (props: IProps) => <SvgNounMinus585754000000 {...props} />;

export default MinusIcon;
