import { goBack } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import Price from "../../records/Price";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import zindexConst from "../../styles/const/zIndexConst";
import Utility from "../../util/Utility";
import ErrorBoundary from "../atoms/ErrorBoundary";
import Grid from "../atoms/Grid";
import MapPinIcon from "../atoms/MapPinIcon";
import MultiLine from "../atoms/MultiLine";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";

const MenuShopName = styled.p`
  color: #000000;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  padding-bottom: 6px;
`;

const MenuImage = styled.div<{ src: string }>`
  padding-top: calc(100vw * (144 / 345));
  background-image: url(${p => p.src});
  background-color: #e5e5e5;
  background-size: cover;
  background-position: center;
`;

const MenuItem = styled.li`
  padding: 15px 0;
  margin: 0 15px;
  background-color: ${colorsConst.BACKGROUND};
  border-top: 1px solid #d9d9d9;
`;

const MenuShopDiscription = styled.div`
  padding-top: 12px;
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 15px;
`;

const MenuName = styled.div`
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
`;

const MenuPrice = styled.div`
  padding-right: 6px;
  font-size: 15px;
  color: #9c9c9c;
`;

const MenuShopGridContainer = styled(Grid).attrs({ container: true })`
  line-height: 23px;
`;

const MenuShopPlace = styled.div``;

interface ICampaignItemData {
  shop_id: number;
  shop_description: string;
  shop_name: string;
  shop_place: string;
  item_image: string;
  item_name: string;
  item_price: number;
}

interface ICampaignData {
  image: string;
  message: string;
  items: ICampaignItemData[];
}

interface ICampaingMenuItemsProps {
  data: ICampaignItemData;
}

const CampaingMenuItems: React.FC<ICampaingMenuItemsProps> = React.memo(
  props => {
    const { data } = props;

    return (
      <>
        <MenuItem>
          <Link to={`/shop/${data.shop_id}`}>
            <MenuImage src={`${Utility.getImageDomain()}/${data.item_image}`} />
            {data.shop_description.length !== 0 && (
              <MenuShopDiscription>{data.shop_description}</MenuShopDiscription>
            )}
            <MenuShopName>{data.shop_name}</MenuShopName>
            {data.item_name.length !== 0 && (
              <MenuName>{data.item_name}</MenuName>
            )}
            <MenuShopGridContainer>
              {data.item_price !== 0 && (
                <MenuPrice>
                  {Price.getPresentationValue(data.item_price)}
                </MenuPrice>
              )}
              {data.shop_place.length !== 0 && (
                <MenuShopPlace>
                  <MapPinIcon />
                  {data.shop_place}
                </MenuShopPlace>
              )}
            </MenuShopGridContainer>
          </Link>
        </MenuItem>
      </>
    );
  }
);

const pageBodyStyle: React.CSSProperties = {
  background: "#ffffff",
  padding: "0 0 28px 0"
};

const CampaignImage = styled.img`
  width: 100%;
`;

const CampaignDescriptionText = styled.div`
  padding: 18px 37px 23px;
  font-size: 15px;
  line-height: 23px;
  color: #4e4e4e;
`;

const LoadingContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

interface IProps {
  actions: ActionDispatcher;
}

const ExclusiveMemberArticlesTemplate: React.FC<IProps> = React.memo(
  (props: IProps) => {
    const { actions } = props;

    const [campaignData, setCampaignData] = useState<ICampaignData | null>(
      null
    );

    const handleGoBack = useCallback(() => {
      actions.goBack();
    }, [actions]);

    const fetchDataJson = useCallback(() => {
      return new Promise<ICampaignData>(async (resolve, reject) => {
        try {
          const response: Response = await fetch(
            "/data/exculusiveCampaignToOther.json"
          );
          resolve(await response.json());
        } catch (error) {
          reject(error);
        }
      });
    }, []);

    const [hasError, setHasError] = useState(false);
    useEffect(() => {
      let unmounted = false;
      fetchDataJson()
        .then((receivedData: ICampaignData) => {
          if (!unmounted) {
            setCampaignData(receivedData);
          }
        })
        .catch(error => {
          setHasError(true);
        });
      return () => {
        unmounted = true;
      };
    }, [actions]);

    return (
      <>
        <Page
          header={<SubPageHeader title="特集" handleClickBack={handleGoBack} />}
          headerContainerStyle={{ zIndex: zindexConst.MODAL + 1 }}
        >
          <ErrorBoundary error={hasError}>
            <PageBody style={pageBodyStyle}>
              {campaignData === null ? (
                <LoadingContainer>
                  <Loading />
                </LoadingContainer>
              ) : (
                <>
                  {campaignData.image && campaignData.message && (
                    <>
                      <CampaignImage
                        src={`${Utility.getImageDomain()}/${
                          campaignData.image
                        }`}
                      />
                      <CampaignDescriptionText>
                        <MultiLine>{campaignData.message}</MultiLine>
                      </CampaignDescriptionText>
                    </>
                  )}
                </>
              )}
              <div>
                <ul>
                  {campaignData === null ? (
                    <LoadingContainer>
                      <Loading />
                    </LoadingContainer>
                  ) : (
                    <>
                      {campaignData.items &&
                        campaignData.items.length > 0 &&
                        campaignData.items.map((itemData, index) => (
                          <CampaingMenuItems key={index} data={itemData} />
                        ))}
                    </>
                  )}
                </ul>
              </div>
            </PageBody>
          </ErrorBoundary>
        </Page>
      </>
    );
  }
);

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExclusiveMemberArticlesTemplate);
