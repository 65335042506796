import { put } from "redux-saga/effects";
import {
  updatePreOrderCount,
  UserChangedPreOrderCountAction
} from "../actions";

export default function* userChangedPreOrderCountSaga(
  action: UserChangedPreOrderCountAction
) {
  yield put(updatePreOrderCount(action.payload.count));
}
