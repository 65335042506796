import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { userTouchedToggleResultViewTypeButton } from "../../modules/search/actions";
import { SearchResultType } from "../../records/SearchResult";
import { ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";

export const TOGGLE_RESULT_VIEW_TYPE_BUTTON_HEIGHT = 56;

const Container = styled.div`
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;

interface IProps {
  searchResultType: SearchResultType;
}

const ToggleResultViewTypeButton: React.FC<IProps> = React.memo(
  ({ searchResultType }) => {
    const currentType = useSelector((state: ReduxModel) =>
      state.search
        .getSearchSettings()
        .getData(searchResultType)
        .getViewType()
    );

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      switch (searchResultType) {
        case "fastest":
          currentType === "list"
            ? Analytics.logEvent("search_fastest_map_to_list")
            : Analytics.logEvent("search_fastest_list_to_map");
          break;
        case "reserve":
          currentType === "list"
            ? Analytics.logEvent("search_reserve_map_to_list")
            : Analytics.logEvent("search_reserve_list_to_map");
          break;
        default:
      }
      dispatch(userTouchedToggleResultViewTypeButton(searchResultType));
    }, [dispatch, searchResultType, currentType]);

    return (
      <Container onClick={handleClick}>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
          {currentType === "map" ? (
            <>
              <circle cx="28" cy="28" r="28" fill="#FF3008" />
              <path
                d="M22.18 38.14H20.35C20.39 38.44 20.42 38.78 20.42 39.22V41.29C20.42 42.67 20.28 43.38 19.61 44.09C19.01 44.71 18.23 45.08 17.19 45.31L18.46 46.65C19.19 46.42 20.24 45.91 20.91 45.21C21.67 44.41 22.14 43.41 22.14 41.4V39.22C22.14 38.78 22.16 38.44 22.18 38.14ZM17.55 38.23H15.8C15.83 38.48 15.84 38.83 15.84 39.02V42.41C15.84 42.72 15.8 43.15 15.79 43.35H17.55C17.53 43.09 17.51 42.67 17.51 42.42V39.02C17.51 38.66 17.53 38.48 17.55 38.23ZM31.68 39.17L30.69 38.44C30.46 38.52 29.99 38.59 29.49 38.59H26.15C25.86 38.59 25.22 38.56 24.87 38.51V40.23C25.15 40.21 25.7 40.15 26.15 40.15H29.26C29.05 40.79 28.51 41.67 27.86 42.41C26.97 43.4 25.39 44.63 23.77 45.22L25.03 46.53C26.34 45.9 27.65 44.89 28.7 43.8C29.6 44.67 30.45 45.63 31.09 46.54L32.48 45.33C31.93 44.65 30.73 43.38 29.76 42.54C30.41 41.64 30.94 40.64 31.27 39.9C31.38 39.65 31.59 39.3 31.68 39.17ZM35.31 45C35.31 45.41 35.26 46.08 35.19 46.51H37.09C37.04 46.06 36.98 45.27 36.98 45V42.43C38.04 42.81 39.41 43.34 40.42 43.86L41.11 42.17C40.27 41.76 38.33 41.05 36.98 40.66V39.29C36.98 38.81 37.04 38.37 37.08 37.99H35.19C35.27 38.37 35.31 38.89 35.31 39.29V45Z"
                fill="white"
              />
              <path
                d="M18 21.7086H37"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <path
                d="M18 29.416H37"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <path
                d="M18 14H37"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </>
          ) : (
            <>
              <circle cx="28" cy="28" r="28" fill="#FF3008" />
              <path
                d="M22.66 40.72L21.46 39.73C21.21 39.81 20.81 39.86 20.36 39.86H15.05C14.71 39.86 14.08 39.8 13.82 39.75V41.45C14.04 41.42 14.64 41.36 15.05 41.36H20C19.55 42.13 18.74 43.13 17.79 43.91C17.2 43.39 16.62 42.93 16.2 42.61L14.92 43.65C15.55 44.1 16.49 44.96 17.07 45.54C17.73 46.21 18.59 47.18 19.03 47.77L20.46 46.63C20.07 46.17 19.53 45.57 18.99 45.03C20.27 43.95 21.56 42.37 22.27 41.2C22.36 41.06 22.5 40.91 22.66 40.72ZM27.32 40.96L25.88 41.43C26.15 41.99 26.57 43.17 26.71 43.67L28.16 43.17C28.01 42.7 27.52 41.4 27.32 40.96ZM31.05 41.78L29.34 41.23C29.25 42.47 28.78 43.83 28.12 44.66C27.29 45.71 25.83 46.47 24.77 46.74L26.04 48.04C27.24 47.58 28.5 46.71 29.43 45.49C30.09 44.63 30.51 43.61 30.77 42.65C30.84 42.41 30.91 42.17 31.05 41.78ZM25 41.5L23.54 42.02C23.81 42.5 24.29 43.81 24.46 44.35L25.94 43.8C25.74 43.22 25.28 42.06 25 41.5ZM39.33 39.58C39.33 39.29 39.57 39.05 39.86 39.05C40.15 39.05 40.39 39.29 40.39 39.58C40.39 39.87 40.15 40.11 39.86 40.11C39.57 40.11 39.33 39.87 39.33 39.58ZM38.62 39.58C38.62 39.63 38.62 39.67 38.63 39.71C38.36 39.75 38.08 39.76 37.91 39.76H33.5C33.17 39.76 32.51 39.71 32.2 39.67V41.38C32.46 41.36 33.01 41.33 33.5 41.33H37.9C37.77 42.13 37.44 43.12 36.8 43.91C36.01 44.89 34.88 45.77 32.87 46.22L34.19 47.67C35.95 47.1 37.36 46.07 38.26 44.86C39.12 43.7 39.53 42.18 39.77 41.24C39.8 41.12 39.84 40.97 39.89 40.82C40.54 40.82 41.1 40.26 41.1 39.58C41.1 38.9 40.54 38.34 39.86 38.34C39.18 38.34 38.62 38.9 38.62 39.58Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 35.4401C27.3518 36.12 27.3515 36.1197 27.3515 36.1197L27.3491 36.1172L27.3427 36.1105L27.3193 36.0857L27.2312 35.9917C27.1548 35.9096 27.0438 35.7895 26.9031 35.6346C26.6218 35.3249 26.2215 34.8759 25.742 34.3148C24.7837 33.1936 23.5042 31.6192 22.2225 29.8093C20.9429 28.0023 19.6478 25.9418 18.6692 23.8492C17.6971 21.7704 17 19.5819 17 17.5393C17 11.1663 21.9249 6 28 6C34.0751 6 39 11.1663 39 17.5393C39 19.5819 38.303 21.7704 37.3308 23.8492C36.3522 25.9418 35.0571 28.0023 33.7775 29.8093C32.4958 31.6192 31.2163 33.1936 30.258 34.3148C29.7785 34.8759 29.3782 35.3249 29.0969 35.6346C28.9562 35.7895 28.8452 35.9096 28.7688 35.9917L28.6807 36.0857L28.6573 36.1105L28.6509 36.1172L28.6491 36.1191C28.6491 36.1191 28.6482 36.12 28 35.4401ZM28 35.4401L28.6491 36.1191L28 36.8L27.3515 36.1197L28 35.4401Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.009 21.3997C30.5217 21.3997 32.5586 19.2629 32.5586 16.6271C32.5586 13.9913 30.5217 11.8545 28.009 11.8545C25.4964 11.8545 23.4595 13.9913 23.4595 16.6271C23.4595 19.2629 25.4964 21.3997 28.009 21.3997Z"
                fill="#FF3008"
              />
            </>
          )}
        </svg>
      </Container>
    );
  }
);

export default ToggleResultViewTypeButton;
