import { List, Record } from "immutable";
import ShopItem from "./ShopItem";
import ShopItemCategory, { IShopItemCategory } from "./ShopItemCategory";

const initialValues = {
  list: List<ShopItemCategory>()
};

/**
 * 店舗メニューのカテゴリリスト
 */
export default class ShopItemCategoryList extends Record(initialValues) {
  constructor(args: any = []) {
    super(
      Object.assign({}, args, {
        list: List(
          args &&
            args.map((val: IShopItemCategory) => new ShopItemCategory(val))
        )
      })
    );
  }

  public exists() {
    return !this.getList().isEmpty();
  }

  public getList(): List<ShopItemCategory> {
    return this.get("list");
  }

  public getItemIds(): number[] {
    const reducedItemIds = this.getList().reduce(
      (itemIds: number[], category: ShopItemCategory) => {
        const ids = category.getItemList().reduce((acc: number[], item) => {
          acc.push(item.getId());
          return acc;
        }, []);
        return itemIds.concat(ids);
      },
      []
    );
    return reducedItemIds;
  }

  /**
   * インデックスからShopItemを取得する
   */
  public findItemByIndex(index: number): ShopItem | undefined {
    return this.getList()
      .flatMap(shopItemCategory => shopItemCategory.getItemList())
      .get(index);
  }

  public getNumberOfOtherItems(itemId: number, num: number) {
    const itemList = this.getList().flatMap(categoryItem =>
      categoryItem.getOtherItemList(itemId)
    );
    return itemList.take(num);
  }
}
