export type SearchRadius = 250 | 500 | 750 | 1000 | 1500;
export const DEFAULT_SEARCH_RADIUS: SearchRadius = 500;
export const displayableSearchRadiusList: SearchRadius[] = [
  250,
  500,
  750,
  1000,
  1500
];
export const displayableSearchRadiusPresentationText = (
  radius: SearchRadius
) => {
  switch (radius) {
    case 250:
      return "250m";
    case 500:
      return "500m";
    case 750:
      return "750m";
    case 1000:
      return "1km";
    case 1500:
      return "1.5km";
  }
};
