import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetCreditCardListRequest,
  IGetCreditCardListResponse
} from "../../../apis/getCreditCardList";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";

export default function* checkCreditCardExistsSaga() {
  try {
    const param: IGetCreditCardListRequest = {};

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_CREDIT_CARD_LIST, param)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }

    const {
      result,
      error
    }: { result: IGetCreditCardListResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (result && !error) {
      const list = result.credit_card_list as any[];
      return list.length > 0;
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クレジットカード情報の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
    yield cancel();
  }
}
