import { goBack, push } from "connected-react-router";
import { List } from "immutable";
import { useCallback, useEffect } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IInputDiscountCodeForm,
  initialInputDiscountCodeForm,
  validateInputDiscountCodeForm
} from "../../forms/InputDiscountCode";
import {
  userAccessedToPageThatNeedsDiscountCodes,
  userSubmittedDiscountCode
} from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";
import Button from "../atoms/Button";
import spaceConst from "../../styles/const/spaceConst";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const SmallButton = styled(Button)`
  margin-top: 36px;
  width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1.5rem;
`;

const DiscountCodeListHead = styled.div`
  font-size: 15px;
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
  color: #464646;
`;

const DiscountCodeList = styled.ul``;
const DiscountCodeItem = styled("li")<{ disabled?: boolean }>`
  padding: 13px;
  background: #ffffff;
  text-align: center;
  border-radius: 4px;
  opacity: ${p => (p.disabled ? "0.5" : "1")};
`;
const DiscountCodeItemLine = styled.div`
  font-size: 15px;
  line-height: 26px;
  color: #464646;
`;

const BlankItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 117px;
`;

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RegisterDiscountCodeTemplate: React.FC<
  IProps & InjectedFormProps<IInputDiscountCodeForm>
> = React.memo(props => {
  const {
    fetchingUserCouponList,
    submittingInputDiscountCode,
    discountCodes,
    handleGoBack,
    handleMount,
    handleSubmit,
    valid,
    touch
  } = props;

  useEffect(() => {
    handleMount();
  }, [handleMount]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialInputDiscountCodeForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="クーポンコード入力"
            handleClickBack={handleClickBack}
          />
        }
        containerStyle={containerStyle}
      >
        <PageBody>
          <form id="discountCodeForm" onSubmit={handleSubmitWrap}>
            <LabelText htmlFor="inputDiscountCode">
              クーポンコードを入力してください
            </LabelText>
            <TextField
              name="discount_code"
              id="inputDiscountCode"
              type="text"
              placeholder="XXXXX"
            />
            <div
              style={{
                textAlign: "center",
                marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
              }}
            >
              <SmallButton
                loading={submittingInputDiscountCode}
                form="discountCodeForm"
                type="submit"
                disabled={!valid}
              >
                登録
              </SmallButton>
            </div>
          </form>
          <div style={{ marginTop: "36px" }}>
            <DiscountCodeListHead>取得済クーポン</DiscountCodeListHead>
            {fetchingUserCouponList ? (
              <BlankItemContainer>
                <Loading />
              </BlankItemContainer>
            ) : discountCodes.isEmpty() ? (
              <BlankItemContainer>
                取得済クーポンはありません
              </BlankItemContainer>
            ) : (
              <DiscountCodeList>
                {discountCodes.map(code => (
                  <DiscountCodeItem
                    key={code.getId()}
                    disabled={code.expired()}
                  >
                    <DiscountCodeItemLine>
                      {code.getCouponName()}
                    </DiscountCodeItemLine>
                    <DiscountCodeItemLine>
                      {code.getDescription()}
                    </DiscountCodeItemLine>
                    <DiscountCodeItemLine>
                      有効期限
                      {"　"}
                      {code.getExpireDateTime().replace(/-/g, "/")}
                    </DiscountCodeItemLine>
                  </DiscountCodeItem>
                ))}
              </DiscountCodeList>
            )}
          </div>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  fetchingUserCouponList: state.app.isConnectedApi(
    EApiKey.GET_USER_COUPON_LIST
  ),
  submittingInputDiscountCode: state.app.isConnectedApi(
    EApiKey.INPUT_DISCOUNT_CODE
  ),
  discountCodes: state.user.getDiscountCodes()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleMount() {
    dispatch(userAccessedToPageThatNeedsDiscountCodes());
  },

  handleSubmit() {
    Analytics.logEvent("promo_code_add");
    dispatch(userSubmittedDiscountCode());
  }
});

const reduxFormConfig = {
  form: EFormID.INPUT_DISCOUNT_CODE,
  initialValues: initialInputDiscountCodeForm,
  validate: validateInputDiscountCodeForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IInputDiscountCodeForm, IProps>(reduxFormConfig)(
    RegisterDiscountCodeTemplate
  )
);
