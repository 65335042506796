import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const ratio = 23 / 24;

const FastestIcon: React.FC<IProps> = ({
  size = 24,
  color = "#000",
  ...rest
}) => (
  <svg
    width={size}
    height={size * ratio}
    viewBox="0 0 24 23"
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.174 7.025a.7.7 0 01.697-.63H21.3a.7.7 0 01.697.63L23.5 21.866a.7.7 0 01-.697.77H5.368a.7.7 0 01-.696-.77L6.174 7.025zM22.03 21.237L20.669 7.796H7.504l-1.362 13.44H22.03z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.832 4.651a4.25 4.25 0 118.502 0v5.407h-1.2V4.65a3.05 3.05 0 00-6.102 0v5.407h-1.2V4.65z"
      fill={color}
    />
    <path
      d="M11.58 10.551a1.144 1.144 0 11-2.287 0 1.144 1.144 0 012.287 0zM18.88 10.551a1.143 1.143 0 11-2.286 0 1.143 1.143 0 012.287 0z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.871 10.327a.5.5 0 01.5-.5h3.632a.5.5 0 110 1H2.371a.5.5 0 01-.5-.5zM0 13.708a.5.5 0 01.5-.5h5.502a.5.5 0 010 1H.5a.5.5 0 01-.5-.5zM2.05 17.089a.5.5 0 01.5-.5h3.453a.5.5 0 110 1H2.55a.5.5 0 01-.5-.5z"
      fill={color}
    />
  </svg>
);

export default FastestIcon;
