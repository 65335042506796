import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetSubAreaListRequest,
  IGetSubAreaListResponse
} from "../../../apis/getSubAreaList";
import { ReduxModel } from "../../../reducer";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import Area from "../../../records/Area";
import {
  UserAccessedAreaPageAction,
  updateCurrentArea,
  updateCurrentSubAreaList
} from "../actions";

export default function* userAccessedAreaPageSaga(
  action: UserAccessedAreaPageAction
) {
  try {
    const currentArea: Area | undefined = yield select<
      (state: ReduxModel) => Area | undefined
    >(state =>
      state.search
        .getAreaList()
        .find(area => area.getId() === action.payload.areaId)
    );

    if (typeof currentArea === "undefined") {
      throw new Error("currentArea is null");
    }

    const params: IGetSubAreaListRequest = {
      area_id: action.payload.areaId
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_SUB_AREA_LIST, params)
    );
    const {
      result,
      error
    }: {
      result: IGetSubAreaListResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateCurrentArea(currentArea));
      yield put(updateCurrentSubAreaList(result.sub_area_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `エリア情報の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
