import { List, Record } from "immutable";
import { ILatLng } from "../modules/search/model";
import { IPlaceListItem } from "./PlaceListItem";
import PlaceListItem, { ERegistrationPlace } from "./PlaceListItem";
import SearchQuery, { ISearchQuery } from "./SearchQuery";

interface IUserDataPropsBase {
  id: string;
  nick_name: string;
  mail: string;
  tel: string;
  check_information_flag: boolean;
  invite_code: string;
  place_list: IPlaceListItem[] | List<PlaceListItem>;
  search_keyword_list: ISearchQuery[] | List<SearchQuery>;
  auth_sms_flag: boolean;
  input_register_coupon_flag: boolean;
  credit_card_flag: boolean;
  maintenance_flag: boolean;
  password_reset_flag: boolean;
}

export interface IUserDataProps extends IUserDataPropsBase {
  place_list: IPlaceListItem[];
  search_keyword_list: ISearchQuery[];
}

export interface IUserData extends IUserDataPropsBase {
  place_list: List<PlaceListItem>;
  search_keyword_list: List<SearchQuery>;
}

const initialValues: IUserData = {
  id: "",
  nick_name: "",
  mail: "",
  tel: "",
  check_information_flag: false,
  invite_code: "",
  place_list: List(),
  search_keyword_list: List(),
  auth_sms_flag: false,
  input_register_coupon_flag: false,
  credit_card_flag: false,
  maintenance_flag: false,
  password_reset_flag: false
};

export default class UserData extends Record(initialValues) {
  constructor(args: Partial<IUserDataProps | IUserData> = {}) {
    super(
      Object.assign({}, args, {
        place_list: List(
          args.place_list
            ? !Array.isArray(args.place_list)
              ? args.place_list
              : args.place_list.map(i => new PlaceListItem(i))
            : []
        ),
        search_keyword_list: List(
          args.search_keyword_list
            ? !Array.isArray(args.search_keyword_list)
              ? args.search_keyword_list
              : args.search_keyword_list.map(i => new SearchQuery(i))
            : []
        )
      })
    );
  }

  public getPlaceListItemByType(type: ERegistrationPlace) {
    return this.getPlaceList().find(i => i.getType() === type);
  }

  public getId(): string {
    return this.get("id");
  }

  public exists() {
    return this.getId() !== "";
  }

  public resetData() {
    return this.clear();
  }

  public existsNickName(): boolean {
    return this.get("nick_name") !== "";
  }

  public getNickName(): string {
    return this.get("nick_name");
  }

  public existEmail(): boolean {
    return this.get("mail") !== "";
  }

  public getEmail(): string {
    return this.get("mail");
  }

  public existsTel(): boolean {
    return this.get("tel") !== "";
  }

  public getTel(): string {
    return this.get("tel");
  }

  public getPlaceList() {
    return this.get("place_list");
  }

  public setRegistrationPlace(
    type: ERegistrationPlace,
    text: string,
    latLng: ILatLng
  ) {
    return this.set(
      "place_list",
      this.getPlaceList().map(i =>
        i.getType() !== type ? i : PlaceListItem.create(type, text, latLng)
      )
    );
  }

  /**
   * 勤務地・学校が登録済かどうか
   */
  public isRegisteredOfficeOrSchool() {
    const placeList = this.getPlaceListItemByType(
      ERegistrationPlace.OFFICE_SCHOOL
    );
    return typeof placeList !== "undefined" ? placeList.registered() : false;
  }

  public checkedInformation() {
    return this.get("check_information_flag");
  }

  public getInviteCode(): string {
    return this.get("invite_code");
  }

  public setMaintenanceFlag() {
    return this.set("maintenance_flag", true);
  }

  public getMaintenanceFlag() {
    return this.get("maintenance_flag");
  }

  public setPasswordResetFlag() {
    return this.set("password_reset_flag", true);
  }

  public getPasswordResetFlag() {
    return this.get("password_reset_flag");
  }

  public getAuthSmsFlag(): boolean {
    return this.get("auth_sms_flag");
  }

  public getInputRegisterCouponFlag(): boolean {
    return this.get("input_register_coupon_flag");
  }

  public getSearchKeywordList() {
    return this.get("search_keyword_list");
  }
}
