import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import BlockParagraph from "../atoms/BlockParagraph";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Paper from "../atoms/Paper";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import UserTerms from "../organisms/UserTerms";

const pageBodyStyle: React.CSSProperties = {
  padding: "29px 24px"
};

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps {
  actions: ActionDispatcher;
}

const UserTermsUserTemplate = React.memo((props: IProps) => {
  const { actions } = props;

  const handleGoBack = useCallback(() => actions.goBack(), [actions]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="ユーザー利用規約"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody style={pageBodyStyle}>
          <Paper>
            <BlockParagraph>
              <UserTerms />
            </BlockParagraph>
          </Paper>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTermsUserTemplate);
