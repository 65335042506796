import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import userAccessedToPageThatNeedsFeatureDetailSaga from "./sagas/userAccessedToPageThatNeedsFeatureDetailSaga";
import userAccessedToPageThatNeedsNotificationsSaga from "./sagas/userAccessedToPageThatNeedsNotificationsSaga";

export default function* notificationSaga() {
  yield fork(() => watchUserAccessedToPageThatNeedsNotificationsSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsFeatureDetailSaga());
}

function* watchUserAccessedToPageThatNeedsNotificationsSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NOTIFICATIONS,
    userAccessedToPageThatNeedsNotificationsSaga
  );
}

function* watchUserAccessedToPageThatNeedsFeatureDetailSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_FEATURE_DETAIL,
    userAccessedToPageThatNeedsFeatureDetailSaga
  );
}
