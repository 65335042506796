import { goBack } from "connected-react-router";
import { List } from "immutable";
import { useCallback, useEffect } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { userAccessedToPageThatNeedsNotifications } from "../../modules/notification/actions";
import NewsItem from "../../records/NewsItem";
import { ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ListLink from "../molecules/ListLink";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import Debug from "../organisms/Debug";
import NewsContent from "../organisms/NewsContent";

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userAccessedToPageThatNeedsNotifications() {
    this.dispatch(userAccessedToPageThatNeedsNotifications());
  }

  public goBack() {
    this.dispatch(goBack());
  }
}

const Content = styled.div`
  padding: 26px 38px;
  margin-bottom: 10px;
`;

/**
 * @deprecated
 */
export enum ENotificationType {
  NOTICE = 0,
  NEWS = 1
}

const pageBodyStyle = {
  padding: 0,
  background: "#ffffff"
};

interface IProps {
  newsList: List<NewsItem>;
  actions: ActionDispatcher;
}

const NotificationTemplate = React.memo((props: IProps) => {
  const { newsList, actions } = props;

  useEffect(() => {
    actions.userAccessedToPageThatNeedsNotifications();
  }, []);

  const handleClickBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="ニュース" handleClickBack={handleClickBack} />
        }
      >
        <PageBody style={pageBodyStyle}>
          <Content>
            <NewsContent newsList={newsList} />
          </Content>
          <Debug>
            <ListLink link="/user/debug">デバッグ機能</ListLink>
          </Debug>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  newsList: state.notification.getNewsList()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationTemplate);
