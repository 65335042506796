import { goBack } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import BlockParagraph from "../atoms/BlockParagraph";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps {
  actions: ActionDispatcher;
}

const UserTermsPointTemplate = React.memo((props: IProps) => {
  const { actions } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="ポイント規約" handleClickBack={handleGoBack} />
        }
      >
        <PageBody>
          <BlockParagraph>
            本サービスは「現状有姿」かつ「利用可能な状態」で提供されます。Tapgoは、明示、黙示、制定法上、本条件に明示的に定められるか否かを問わず、商品性、特定目的適合性、及び非侵害の黙示保証を含む、一切の表明及び保証を否認します。さらに、Tapgoは本サービス、又は本サービスの使用を通して要請されるサービス若しくは物品の信頼性、適時性、品質、適合性若しくは利用可能性、又は本サービスが妨害や誤謬がないことの表明保証又は保証も何ら行いません。Tapgoは本第三者の提供業者の品質、適合性、安全性又は能力について何ら保証しません。貴殿は、本サービスの使用に起因するリスクの一切、及びこれらとの関連において要請されたサービス若しくは物品の一切は、適用法により最大限許容される限りにおいて専ら貴殿が負担することに同意することとします。
          </BlockParagraph>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTermsPointTemplate);
