import { IFirebasePlugin } from "../FirebasePlugin";
import LocalRepository from "./LocalRepository";
import UrlSearchParams from "./UrlSearchParams";

declare var FirebasePlugin: IFirebasePlugin;

export default class PushUtil {
  static get DEVICE_TOKEN_KEY() {
    return "deviceToken";
  }

  public static setDeviceToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const { token } = await PushUtil.getDeviceToken();
        if (token !== null && token !== "") {
          LocalRepository.setItem(PushUtil.DEVICE_TOKEN_KEY, token);
        }
        resolve();
      } catch (error) {
        // TODO: error tracking
        console.log({ error });
      }
    });
  }

  private static getDeviceToken(): Promise<{
    token: string | null;
  }> {
    if ("FirebasePlugin" in window) {
      return PushUtil.getDeviceTokenFromFCM();
    } else {
      return PushUtil.getDeviceTokenFromUrlSearch();
    }
  }

  /**
   * iOSで必ず呼ぶ事
   */
  private static grantPermission() {
    if ("FirebasePlugin" in window) {
      return FirebasePlugin.grantPermission();
    }
  }

  /**
   * @deprecated
   */
  private static getDeviceTokenFromUrlSearch() {
    return new Promise<{ token: string }>((resolve, reject) => {
      try {
        const token = UrlSearchParams.get(PushUtil.DEVICE_TOKEN_KEY);
        if (typeof token === "string" && token !== "" && token !== "null") {
          resolve({ token });
        } else {
          resolve({ token: "" });
        }
      } catch (error) {
        reject({ error });
      }
    });
  }

  private static getDeviceTokenFromFCM() {
    return new Promise<{ token: string | null }>((resolve, reject) => {
      FirebasePlugin.getToken(
        (token: string | null) => {
          FirebasePlugin.onTokenRefresh(
            (refreshedToken: string | null) => {
              PushUtil.grantPermission();
              resolve({ token: refreshedToken });
            },
            (error: unknown) => reject({ error })
          );
        },
        (error: unknown) => {
          reject({ error });
        }
      );
    });
  }
}
