import { replace } from "connected-react-router";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import {
  IGetUserDataRequest,
  IGetUserDataResponse
} from "../../../apis/getUserData";
import { EApiKey } from "../../../apis/index";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { resetUser, updateUser } from "../actions";
import UserModel from "../model";

export default function* userTouchedToButtonThatReturnFromMaintenancePageSaga() {
  try {
    const user: UserModel = yield select<(state: ReduxModel) => UserModel>(
      state => state.user
    );
    if (user.getLoginToken() === null) {
      yield put(resetUser());
      yield put(replace("/top"));
      yield cancel();
    }
    const params: IGetUserDataRequest = {};
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_USER_DATA, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IGetUserDataResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateUser(result.user_data));
      yield put(replace("/"));
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `エラーが発生しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
