import { ellipsis } from "polished";
import { goBack } from "connected-react-router";
import { List } from "immutable";
import * as React from "react";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SwipeableViews, { OnChangeIndexCallback } from "react-swipeable-views";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EProcessingFlag } from "../../modules/app/model";
import {
  userAccessedShopPage,
  userTouchedChangeShopFavoriteStateButton,
  userTouchedWriteReviewButton
} from "../../modules/search/actions";
import { EShopTabType } from "../../modules/search/model";
import ExternalLink from "../../records/ExternalLink";
import Feedback from "../../records/Feedback";
import ShopData from "../../records/ShopData";
import ShopItem from "../../records/ShopItem";
import ShopItemCategoryList from "../../records/ShopItemCategoryList";
import { ReduxAction, ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Analytics, { AnalyticsEventName } from "../../util/Analytics";
import ScrollTopMemory, { EScrollTopKey } from "../../util/ScrollTopMemory";
import Utility from "../../util/Utility";
import HeaderContainer from "../atoms/HeaderContainer";
import Page from "../atoms/Page";
import Loading from "../molecules/Loading";
import Auth from "../organisms/Auth";
import CartButton from "../organisms/CartButton";
import ShopTabFeedback from "../organisms/ShopTabFeedback";
import ShopTabLink from "../organisms/ShopTabLink";
import ShopTabMap from "../organisms/ShopTabMap";
import ShopTabMenu from "../organisms/ShopTabMenu";
import ShopTabTop from "../organisms/ShopTabTop";

const ShopPageBody = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const CartButtonContainer = styled.div`
  background: #ffffff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const ShopHeaderContainer = styled(HeaderContainer).attrs({ hasBorder: true })`
  height: auto;
`;

const ShopNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
`;

const ShopName = styled.h1`
  min-height: ${26 / 18}em;
  padding: 0 20px;
  flex: 1 1 auto;
  font-size: 18px;
  line-height: ${26 / 18};
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.03em;
  color: #000000;
  ${ellipsis()}
`;

const TabList = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabListItem = styled.div<{ active: boolean }>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  width: 20%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  color: #7c7c7c;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.281;

  ${p =>
    p.active
      ? `
    color: ${colorsConst.MAIN};
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: ${colorsConst.MAIN};
    }
  `
      : ""}
`;

const TabItem = styled.div`
  position: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const BackIconContainer = styled.div`
  position: absolute;
  top: calc(23px + constant(safe-area-inset-top));
  top: calc(23px + env(safe-area-inset-top));
  left: 20px;
  cursor: pointer;
`;

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row"
};

const SHOP_TAB_TYPES: List<EShopTabType> = List([
  EShopTabType.TOP,
  EShopTabType.MENU,
  EShopTabType.FEEDBACK,
  EShopTabType.MAP,
  EShopTabType.LINK
]);

const getIndexByTabType = (tabType: EShopTabType) => {
  return SHOP_TAB_TYPES.findIndex(type => type === tabType);
};

const getShopTabTypeText = (value: EShopTabType): string => {
  switch (value) {
    case EShopTabType.TOP:
      return "トップ";
    case EShopTabType.MENU:
      return "メニュー";
    case EShopTabType.FEEDBACK:
      return "口コミ";
    case EShopTabType.MAP:
      return "マップ";
    case EShopTabType.LINK:
      return "リンク";
    default:
      return "";
  }
};

const getAnalyticsEventNameByTabType = (
  value: EShopTabType
): AnalyticsEventName => {
  switch (value) {
    case EShopTabType.TOP:
      return "shop_tab_top";
    case EShopTabType.MENU:
      return "shop_tab_menu";
    case EShopTabType.FEEDBACK:
      return "shop_tab_feedback";
    case EShopTabType.MAP:
      return "shop_tab_map";
    case EShopTabType.LINK:
      return "shop_tab_link";
    default:
      return "";
  }
};

interface IProps extends RouteComponentProps<{ id: string }> {
  currentShopItemList: ShopItemCategoryList;
  currentShopRecommendItemList: List<ShopItem>;
  currentShopData: ShopData;
  currentFeedbackList: List<Feedback>;
  existsOtherShopCart: boolean;
  existsCart: boolean;
  isUpdatingFavoriteState: boolean;
  shopId: number;
  playingPageTransitionAnimation: boolean;
  actions: ActionDispatcher;
  children?: never;
}

const ShopTemplate: React.FC<IProps> = React.memo(props => {
  const {
    currentShopItemList,
    currentShopRecommendItemList,
    currentShopData,
    currentFeedbackList,
    existsOtherShopCart,
    existsCart,
    isUpdatingFavoriteState,
    shopId,
    playingPageTransitionAnimation,
    actions
  } = props;

  // SwipeableViewsのswipe操作が有効か
  const [isSwipeableTab, setSwipeableTabFlag] = useState(true);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      if (playingPageTransitionAnimation) {
        return;
      }
      ScrollTopMemory.save(
        `${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`,
        event.currentTarget.scrollTop
      );
    },
    [shopId, playingPageTransitionAnimation]
  );

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const isLoading = useMemo(
    () => !currentShopData.existsData() || currentShopData.getId() !== shopId,
    [currentShopData, shopId]
  );

  const [pageContainer, setPageContainer] = useState<HTMLDivElement | null>(
    null
  );
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null
  );
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const notActiveTabItemStyle: React.CSSProperties = useMemo(() => {
    // 非アクティブ時に高さを固定して余分なスクロールを制御
    return {
      overflow: "hidden",
      height: containerHeight,
      zIndex: -1,
      position: "relative"
    };
  }, [containerHeight]);

  const [tabIndex, setTabIndex] = useState<EShopTabType>(
    ScrollTopMemory.loadOrDefault(
      `${EScrollTopKey.SHOP_TAB}-${shopId}`,
      EShopTabType.TOP
    )
  );

  const handleChangeTabIndex = useCallback<OnChangeIndexCallback>(
    index => {
      setSwipeableTabFlag(index !== getIndexByTabType(EShopTabType.MAP));

      Analytics.logEvent(getAnalyticsEventNameByTabType(index));
      setTabIndex(index);
      // スワイプ時にスクロール位置を初期化
      if (scrollContainer !== null) {
        scrollContainer.scrollTo(0, 0);
      }
      ScrollTopMemory.save(`${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`, 0);
      ScrollTopMemory.save(`${EScrollTopKey.SHOP_TAB}-${shopId}`, index);
    },
    [shopId, scrollContainer]
  );

  useEffect(() => {
    actions.userAccessedShopPage(shopId);
    Analytics.logEvent("shop_display", {
      content_type: "shop_id",
      item_id: `${shopId}`
    });
  }, [actions, shopId]);

  const handleClickLaunchMapApp = useCallback(() => {
    Analytics.logEvent("shop_launch_map", {
      content_type: "shop_id",
      item_id: `${shopId}`
    });
    Utility.launchMapApp(currentShopData.getLocation());
  }, [shopId, currentShopData]);

  const handleClickReviewButton = useCallback(() => {
    Analytics.logEvent("shop_reputation_vote", {
      content_type: "shop_id",
      item_id: `${shopId}`
    });
    actions.handleClickReviewButton(shopId);
  }, [shopId, actions]);

  const handleClickFavoriteButton = useCallback(() => {
    if (currentShopData.isFavorite()) {
      Analytics.logEvent("shop_like_cancel", {
        content_type: "shop_id",
        item_id: `${shopId}`
      });
    } else {
      Analytics.logEvent("shop_like", {
        content_type: "shop_id",
        item_id: `${shopId}`
      });
    }
    actions.userTouchedChangeShopFavoriteStateButton(
      shopId,
      !currentShopData.isFavorite()
    );
  }, [actions, currentShopData, shopId]);

  const [existExternalLinkUrl, setExistExternalLinkUrl] = useState(false);

  const checkExistExternalLinkUrl = useCallback(() => {
    setExistExternalLinkUrl(false);
    currentShopData.getExternalLink().map((link: ExternalLink) => {
      link.existLinkUrl() && setExistExternalLinkUrl(true);
    });
  }, [currentShopData]);

  useEffect(() => {
    checkExistExternalLinkUrl();
  }, [checkExistExternalLinkUrl]);

  useLayoutEffect(() => {
    let timerId = 0;
    timerId = setTimeout(() => {
      if (scrollContainer !== null) {
        const scrollTop = ScrollTopMemory.loadOrDefault(
          `${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`,
          0
        );
        scrollContainer.scrollTo(0, scrollTop);
      }
      if (pageContainer !== null) {
        setContainerHeight(pageContainer.clientHeight);
      }
    }, 16);
    return () => {
      clearTimeout(timerId);
    };
  }, [shopId, scrollContainer, pageContainer]);

  return (
    <>
      <Auth>
        <Page
          header={
            <ShopHeaderContainer>
              <ShopNameContainer>
                <BackIconContainer onClick={handleGoBack}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.878906 10.0208L10.0713 0.828364L12.1087 2.86575L4.95369 10.0208L12.1087 17.1757L10.0713 19.2131L0.878906 10.0208Z"
                      fill="#9FA0A0"
                    />
                  </svg>
                </BackIconContainer>
                <ShopName>
                  {!isLoading ? <>{currentShopData.getShopName()}</> : null}
                </ShopName>
              </ShopNameContainer>
              <TabList>
                {SHOP_TAB_TYPES.map(tab => (
                  <TabListItem
                    onClick={() => handleChangeTabIndex(tab, tabIndex)}
                    key={tab}
                    active={tabIndex === tab}
                  >
                    {getShopTabTypeText(tab)}
                  </TabListItem>
                ))}
              </TabList>
            </ShopHeaderContainer>
          }
          footer={
            !existsOtherShopCart && existsCart ? (
              <CartButtonContainer>
                <CartButton />
              </CartButtonContainer>
            ) : (
              undefined
            )
          }
          containerStyle={containerStyle}
          contentStyle={contentStyle}
        >
          <ShopPageBody ref={setPageContainer}>
            {isLoading ? (
              <BlankContainer>
                <Loading />
              </BlankContainer>
            ) : (
              <Container ref={setScrollContainer} onScroll={handleScroll}>
                <SwipeableViews
                  index={tabIndex}
                  onChangeIndex={handleChangeTabIndex}
                  disabled={!isSwipeableTab}
                >
                  {SHOP_TAB_TYPES.map(tab => (
                    <TabItem
                      key={tab}
                      style={tabIndex !== tab ? notActiveTabItemStyle : {}}
                    >
                      {tab === EShopTabType.TOP ? (
                        <ShopTabTop
                          shopData={currentShopData}
                          currentFeedbackList={currentFeedbackList}
                          currentShopRecommendItemList={
                            currentShopRecommendItemList
                          }
                          handleClickLaunchMapApp={handleClickLaunchMapApp}
                          handleClickReviewButton={handleClickReviewButton}
                          handleClickFavoriteButton={handleClickFavoriteButton}
                          isUpdatingFavoriteState={isUpdatingFavoriteState}
                          externalLink={currentShopData.getExternalLink()}
                          existExternalLinkUrl={existExternalLinkUrl}
                          handleChangeIndex={handleChangeTabIndex}
                        />
                      ) : tab === EShopTabType.MENU ? (
                        <ShopTabMenu
                          shopId={shopId}
                          list={currentShopItemList}
                        />
                      ) : tab === EShopTabType.FEEDBACK ? (
                        <ShopTabFeedback
                          feedbackList={currentShopData.getDisplayableFeedbackList()}
                        />
                      ) : tab === EShopTabType.MAP ? (
                        <ShopTabMap
                          containerHeight={containerHeight}
                          shopData={currentShopData}
                          handleClickLaunchMapApp={handleClickLaunchMapApp}
                          setSwipeableTabFlag={setSwipeableTabFlag}
                        />
                      ) : tab === EShopTabType.LINK ? (
                        <ShopTabLink
                          shopId={shopId}
                          externalLink={currentShopData.getExternalLink()}
                          existExternalLinkUrl={existExternalLinkUrl}
                        />
                      ) : null}
                    </TabItem>
                  ))}
                </SwipeableViews>
              </Container>
            )}
          </ShopPageBody>
        </Page>
      </Auth>
    </>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ id: string }>
) => {
  const shopId = parseInt(ownProps.match.params.id, 10);
  const cartList = state.order.getCartList();
  return {
    currentShopItemList: state.search.getCurrentShopItemList(),
    currentShopData: state.search.getCurrentShopData(),
    currentFeedbackList: state.search.getCurrentFeedbackList(),
    currentShopRecommendItemList: state.search.getCurrentShopRecommendItemList(),
    shopId,
    existsCart: !cartList.isEmpty(),
    playingPageTransitionAnimation: state.app.isPageTransitionAnimationPlaying(),
    isUpdatingFavoriteState: state.app.isProcessing(
      EProcessingFlag.UPDATING_FAVORITE_STATE
    )
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public userAccessedShopPage(shopId: number) {
    this.dispatch(userAccessedShopPage(shopId));
  }

  public handleClickReviewButton(shopId: number) {
    this.dispatch(userTouchedWriteReviewButton(shopId));
  }

  public userTouchedChangeShopFavoriteStateButton(
    shopId: number,
    bool: boolean
  ) {
    this.dispatch(userTouchedChangeShopFavoriteStateButton(shopId, bool));
  }

  public goBack() {
    this.dispatch(goBack());
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShopTemplate)
);
