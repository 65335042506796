export default {
  FAMILY: `Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;`,
  SIZE: {
    DEFAULT: "1.4rem"
  },
  LINE_HEIHT: {
    DEFAULT: "1.5"
  },
  LETTER_SPACING: {
    DEFAULT: "0"
  }
};
