import { cancel, fork, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IPublishSmsByTelRequest,
  IPublishSmsByTelResponse
} from "../../../apis/publishSmsByTel";
import { IUpdateTelRequest, IUpdateTelResponse } from "../../../apis/updateTel";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateUser,
  UserTouchedRequestToResentSMSAuthCodeForResetPasswordButtonAction
} from "../actions";

export default function* requestToResentSMSAuthCodeForResetPasswordSaga(
  action: UserTouchedRequestToResentSMSAuthCodeForResetPasswordButtonAction
) {
  const params: IUpdateTelRequest = {
    tel: action.payload.tel
  };
  const updateTelTask = yield fork(() => updateTel(params));
  const successUpdateTel = yield join(updateTelTask);
  if (!successUpdateTel) {
    yield cancel();
  }
  yield fork(() => publishSMS(params));
}

function* updateTel(params: { tel: string }) {
  try {
    const updateTelForkTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IUpdateTelResponse; error: unknown } = yield join(
      updateTelForkTask
    );
    if (updateTelForkTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));
      return true;
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "電話番号の変更に失敗しました。",
        message: "通信環境を確認の上、再度お試し下さい。"
      })
    );
    return false;
  }
}

function* publishSMS(params: IPublishSmsByTelRequest) {
  try {
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.PUBLISH_SMS_BY_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IPublishSmsByTelResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "認証コードを送信しました。",
          message: ""
        })
      );
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証コードの送信に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
