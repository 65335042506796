import { Action } from "redux";
import { ICreditCardItem } from "../../../records/CreditCardItem";
import { IDiscountCode } from "../../../records/DiscountCode";
import { IUserData, IUserDataProps } from "../../../records/UserData";
import { IInstallCoupon } from "../../../records/InstallCoupon";
import { ActionTypes } from "./index";

export interface ResetUserAction extends Action {
  type: ActionTypes.R_RESET_USER;
}
export const resetUser = (): ResetUserAction => ({
  type: ActionTypes.R_RESET_USER
});

export interface UpdateDiscountCodesAciton extends Action {
  type: ActionTypes.R_UPDATE_DISCOUNT_CODES;
  payload: {
    codes: IDiscountCode[];
  };
}
export const updateDiscountCodes = (
  codes: IDiscountCode[]
): UpdateDiscountCodesAciton => ({
  type: ActionTypes.R_UPDATE_DISCOUNT_CODES,
  payload: {
    codes
  }
});

// ユーザー情報の更新
interface UpdateUserAction extends Action {
  type: ActionTypes.R_UPDATE_USER;
  payload: {
    data: IUserDataProps | IUserData;
  };
}
export const updateUser = (
  data: IUserDataProps | IUserData
): UpdateUserAction => ({
  type: ActionTypes.R_UPDATE_USER,
  payload: {
    data
  }
});

// ログイントークンの更新
interface UpdateLoginTokenAction extends Action {
  type: ActionTypes.R_UPDATE_LOGIN_TOKEN;
  payload: {
    token: string | null;
  };
}
export const updateLoginToken = (
  token: string | null
): UpdateLoginTokenAction => ({
  type: ActionTypes.R_UPDATE_LOGIN_TOKEN,
  payload: {
    token
  }
});

export interface UpdateCreditCardAction extends Action {
  type: ActionTypes.R_UPDATE_CREDIT_CARD;
  payload: {
    credit_card_list: ICreditCardItem[];
    select_card_index: number;
  };
}
export const updateCreditCard = (
  creditCardList: ICreditCardItem[],
  selectCardIndex: number
): UpdateCreditCardAction => ({
  type: ActionTypes.R_UPDATE_CREDIT_CARD,
  payload: {
    credit_card_list: creditCardList,
    select_card_index: selectCardIndex
  }
});

// ログイントークンの更新
interface UpdateInstallCouponAction extends Action {
  type: ActionTypes.R_UPDATE_INSTALL_COUPON;
  payload: {
    install_coupon: IInstallCoupon;
  };
}
export const updateInstallCoupon = (
  install_coupon: IInstallCoupon
): UpdateInstallCouponAction => ({
  type: ActionTypes.R_UPDATE_INSTALL_COUPON,
  payload: {
    install_coupon
  }
});

export type ReducerActions =
  | ResetUserAction
  | UpdateDiscountCodesAciton
  | UpdateCreditCardAction
  | UpdateUserAction
  | UpdateLoginTokenAction
  | UpdateInstallCouponAction;
