import { push } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  ILoginForm,
  initialLoginForm,
  validateLoginForm
} from "../../forms/login";
import { userSubmittedLogin } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Analytics from "../../util/Analytics";
import ButtonAlter from "../atoms/ButtonAlter";
import LabelText from "../atoms/LabelText";
import LinkText from "../atoms/LinkText";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const PasswordNotice = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
`;

interface IPropsBase {
  submittingLoginUser: boolean;
  actions: ActionDispatcher;
}

interface IProps
  extends InjectedFormProps<ILoginForm, IPropsBase>,
    IPropsBase {}

const LoginTemplate = React.memo((props: IProps) => {
  const { submittingLoginUser, actions } = props;

  const handleClickBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!props.valid) {
        props.touch(...Object.keys(initialLoginForm));
        return;
      }
      actions.handleSubmit();
    },
    [actions, props.valid, props.touch]
  );

  const handleClickResetPassword = useCallback(() => {
    actions.handleClickResetPasswordButton();
  }, [actions]);

  return (
    <>
      <Page
        header={
          <SubPageHeader title="ログイン" handleClickBack={handleClickBack} />
        }
        containerStyle={containerStyle}
      >
        <PageBody>
          <form id={EFormID.LOGIN} onSubmit={handleSubmit}>
            <div>
              <LabelText htmlFor="inputMail">メールアドレス</LabelText>
              <TextField
                id="inputMail"
                name="mail"
                type="email"
                placeholder="menu@mail.com"
                autoComplete="email"
              />
            </div>
            <div>
              <LabelText htmlFor="inputPassword">パスワード</LabelText>
              <TextField
                id="inputPassword"
                name="password"
                type="password"
                placeholder="半角英数字８文字以上"
                autoComplete="current-password"
              />
            </div>
            {/*
            <PasswordNotice>
              パスワードをお忘れの方は
              <LinkText onClick={handleClickResetPassword}>こちら</LinkText>
            </PasswordNotice>
            */}
            <div
              style={{
                marginTop: spaceConst.GUTTER.OVER_BUTTON
              }}
            >
              <ButtonAlter
                appearance={"secondary"}
                block
                loading={submittingLoginUser}
                disabled={!props.valid}
                type="submit"
                form={EFormID.LOGIN}
              >
                次へ
              </ButtonAlter>
            </div>
          </form>
        </PageBody>
      </Page>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingLoginUser: state.app.isConnectedApi([
    EApiKey.LOGIN_USER,
    EApiKey.GET_USER_DATA
  ])
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(
      push("/top", { routerActionType: { [`${"PUSH"}${"/top"}`]: "POP" } })
    );
  }

  public handleClickResetPasswordButton() {
    Analytics.logEvent("login_pwreset");
    this.dispatch(push("/requestResetPassword"));
  }

  public handleSubmit() {
    Analytics.logEvent("login_in");
    this.dispatch(userSubmittedLogin());
  }
}

const reduxFormConfig = {
  form: EFormID.LOGIN,
  initialValues: initialLoginForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateLoginForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<ILoginForm, IPropsBase>(reduxFormConfig)(LoginTemplate));
