import * as React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import CloseIcon from "../atoms/CloseIcon";

const Container = styled.span`
  display: inline-block;
  background: ${colorsConst.BACKGROUND};
  padding: 8px;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

function CloseIconButton() {
  return (
    <Container>
      <CloseIcon />
    </Container>
  );
}

export default CloseIconButton;
