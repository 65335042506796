import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { IGetHomeRequest, IGetHomeResponse } from "../../../apis/getHome";
import { AlertType } from "../../../records/Alert";
import APICache from "../../../util/APICache";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateFeatureList } from "../../notification/actions";
import {
  updateHomeList,
  updateHomeCategoryTagList,
  updateAreaList
} from "../actions";

export default function* userAccessedToHomeSaga() {
  try {
    const params: IGetHomeRequest = {};
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_HOME, params)
    );
    const {
      result,
      error,
      fromCache
    }: {
      result: IGetHomeResponse;
      error: unknown;
      fromCache?: boolean;
    } = yield join(commonApiTask);

    if (commonApiTask.isCancelled()) {
      yield cancel();
    }

    if (result && !error) {
      const {
        feature_list,
        area_list,
        category_tag_list,
        ...shopList
      } = result;
      yield put(updateFeatureList(feature_list));
      yield put(updateAreaList(area_list));
      yield put(updateHomeCategoryTagList(category_tag_list));
      if (typeof fromCache === "undefined") {
        yield put(
          updateHomeList({
            ...shopList
          })
        );
      }
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `情報の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
