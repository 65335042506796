import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  userSubmittedRegisterNickNameAtOrder,
  userTouchedSkipRegisterNickName
} from "../../modules/user/actions";
import {
  IRegisterNickNameForm,
  initialRegisterNickNameForm,
  validateRegisterNickNameForm
} from "../../forms/registerNickName";
import { ReduxModel } from "../../reducer";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";
import Auth from "../organisms/Auth";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};
const labelStyle: React.CSSProperties = {
  marginLeft: "5px",
  padding: "2px",
  color: "#fff",
  backgroundColor: "#9fa0a0",
  borderRadius: "2px"
};
const noteStyle: React.CSSProperties = {
  textIndent: "-1em",
  paddingLeft: "1em",
  paddingTop: "5px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RegisterNickNameAtOrderTemplate: React.FC<
  IProps & InjectedFormProps<IRegisterNickNameForm>
> = React.memo(props => {
  const {
    submittingRegisterNickName,
    userId,
    handleGoBack,
    handleSubmit,
    handleSkip,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSkipWrap = useCallback(() => {
    handleSkip();
  }, [handleSkip]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialRegisterNickNameForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="受取時の名前の登録"
            handleClickBack={handleClickBack}
          />
        }
        containerStyle={containerStyle}
        contentStyle={contentStyle}
      >
        <PageBody style={pageBodyStyle}>
          <div style={{ flexGrow: 1 }}>
            <form id={EFormID.REGISTER_NICK_NAME} onSubmit={handleSubmitWrap}>
              <LabelText htmlFor="nickName">
                受取時の名前<span style={labelStyle}>任意</span>
              </LabelText>
              <TextField
                id="nickName"
                name="nick_name"
                type="text"
                placeholder="ヤマダタロウ"
              />
            </form>
            <div style={{ color: "#9fa0a0" }}>ID:{userId}</div>
            <Notes style={{ ...noteStyle, marginTop: "40px" }}>
              ※受取時の名前はお店で商品受け取り時に使用します。
            </Notes>
            <Notes style={noteStyle}>
              ※入力可能な文字は、漢字・ひらがな・カタカナ・全半角の英数字です。
            </Notes>
            <Notes style={noteStyle}>※記号・絵文字は使用できません。</Notes>
          </div>
          <div style={{ flexGrow: 0 }}>
            <Button
              block
              loading={submittingRegisterNickName}
              disabled={!valid}
              type="submit"
              form={EFormID.REGISTER_NICK_NAME}
            >
              受取時の名前を登録する
            </Button>
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              <span
                onClick={handleSkipWrap}
                style={{
                  color: colorsConst.MAIN,
                  borderBottom: `1px solid ${colorsConst.MAIN}`
                }}
              >
                スキップ
              </span>
            </div>
          </div>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingRegisterNickName: state.app.isConnectedApi(
    EApiKey.UPDATE_NICK_NAME
  ),
  userId: state.user.getData().getId()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleSubmit() {
    dispatch(userSubmittedRegisterNickNameAtOrder());
  },

  handleSkip() {
    dispatch(userTouchedSkipRegisterNickName());
  }
});

const reduxFormConfig = {
  form: EFormID.REGISTER_NICK_NAME,
  initialValues: initialRegisterNickNameForm,
  validate: validateRegisterNickNameForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IRegisterNickNameForm, IProps>(reduxFormConfig)(
    RegisterNickNameAtOrderTemplate
  )
);
