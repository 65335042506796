import React from "react";
import ConsoleIcon from "../../atoms/ConsoleIcon";
import Grid from "../../atoms/Grid";

interface IProps {}

const SettingFilterDialogHeader: React.FC<IProps> = React.memo(() => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "8px 15px",
        height: "58px",
        borderBottom: "1px solid #C9CACA"
      }}
    >
      <Grid container>
        <Grid item rate={0}>
          <ConsoleIcon size={20} />
        </Grid>
        <Grid item rate={1}>
          <div
            style={{
              paddingLeft: "8px",
              fontSize: "16px",
              lineHeight: 23 / 16,
              color: "#666666"
            }}
          >
            条件を追加
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default SettingFilterDialogHeader;
