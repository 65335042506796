import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import ShopForSearch from "../../../records/ShopForSearch";
import Carousel from "../../atoms/Carousel";
import Loading from "../../molecules/Loading";
import MapCarouselItem, { EmptyCarouselItem } from "./CarouselItem";

export const MAP_CAROUSEL_HEIGHT = 74;

const Container = styled.div`
  padding-top: 11px;
  padding-bottom: 11px;
`;

const Inner = styled.div`
  padding-right: 23px;
  padding-left: 23px;
  .slick-list {
    overflow: visible;
  }
`;

const ItemContainer = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`;

interface IProps {
  shopList: List<ShopForSearch>;
  currentIndex: number;
  handleChange: (index: number, item: ShopForSearch) => void;
  handleClickItem: (shopId: number) => void;
  selectedItem: ShopForSearch | undefined;
  fetchingResult: boolean;
}

const MapCarousel: React.FC<IProps> = React.memo(
  ({
    shopList,
    currentIndex,
    handleChange,
    handleClickItem,
    selectedItem,
    fetchingResult
  }) => {
    const carouselContainer: React.RefObject<Slider> = useRef(null);

    const handleCarouselChange = useCallback(
      (index: number) => {
        const targetItem = shopList.get(index);
        if (typeof targetItem !== "undefined") {
          handleChange(index, targetItem);
        }
      },
      [handleChange, shopList]
    );

    const initialSlide = useMemo(() => {
      if (typeof selectedItem === "undefined") {
        return 0;
      }
      const index = shopList.findIndex(i => i.getId() === selectedItem.getId());
      return index === -1 ? 0 : index;
    }, [selectedItem, shopList]);

    useEffect(() => {
      if (carouselContainer.current) {
        carouselContainer.current.slickGoTo(currentIndex);
      }
    }, [currentIndex]);

    return (
      <Container>
        <Inner>
          <Carousel
            infinite={false}
            dots={false}
            autoplay={false}
            speed={250}
            arrows={false}
            afterChange={handleCarouselChange}
            sliderRef={carouselContainer}
            initialSlide={initialSlide}
          >
            {fetchingResult ? (
              <ItemContainer>
                <EmptyCarouselItem>
                  <Loading />
                </EmptyCarouselItem>
              </ItemContainer>
            ) : (
              shopList.map(shop => (
                <li key={shop.getId()}>
                  <ItemContainer>
                    <MapCarouselItem
                      shop={shop}
                      handleClick={handleClickItem}
                    />
                  </ItemContainer>
                </li>
              ))
            )}
          </Carousel>
        </Inner>
      </Container>
    );
  }
);

export default MapCarousel;
