import {
  AlignItemsProperty,
  AlignSelfProperty,
  FlexBasisProperty,
  FlexDirectionProperty,
  FlexProperty,
  FlexWrapProperty,
  JustifyContentProperty
} from "csstype";
import * as React from "react";
import styled from "styled-components";

interface IProps {
  container?: boolean;
  item?: boolean;
  direction?: FlexDirectionProperty;
  align?: AlignItemsProperty;
  justify?: JustifyContentProperty;
  wrap?: FlexWrapProperty;
  rate?: FlexProperty<number>;
  grow?: FlexProperty<number>;
  shrink?: FlexProperty<number>;
  basis?: FlexBasisProperty<number>;
  order?: number;
  self?: AlignSelfProperty;
  style?: React.CSSProperties;
}

const containerProps = (p: IProps) => {
  return `
    display: flex;
    ${p.direction ? "flex-direction:" + p.direction + ";" : ""}
    ${p.align ? "align-items: " + p.align + ";" : ""}
    ${p.justify ? "justify-content: " + p.justify + ";" : ""}
    ${p.wrap ? "flex-wrap: " + p.wrap + ";" : ""}
  `;
};

const itemProps = (p: IProps) => {
  return `
    ${p.order ? "order: " + p.order + ";" : ""}
    ${
      p.grow || p.rate
        ? "flex-grow: " + (p.grow ? p.grow + ";" : p.rate ? p.rate + ";" : "0;")
        : ""
    }
    ${
      p.shrink || p.rate
        ? "flex-shrink: " + p.shrink
          ? p.shrink + ";"
          : p.rate
          ? p.rate + ";"
          : "1;"
        : ""
    }
    ${p.basis ? "flex-basis: " + p.basis + ";" : ""}
    ${p.self ? "align-self: " + p.self + ";" : ""}
  `;
};

const Grid = styled("div")<IProps>`
  ${p => (p.container ? containerProps(p) : "")};
  ${p => (p.item ? itemProps(p) : "")};
`;

export default React.memo(Grid);
