import * as React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import ListItem from "./ListItem";

interface IProps extends React.LiHTMLAttributes<HTMLLIElement> {
  link?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Container = styled(ListItem)`
  padding: 20px;
  background-color: ${colorsConst.BACKGROUND};
`;

function ListLink(props: IProps) {
  const { link, children, ...otherProps } = props;
  return (
    <Container link={link} {...otherProps}>
      {children}
    </Container>
  );
}

export default React.memo(ListLink);
