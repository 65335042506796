import { Record } from "immutable";

const initial = {
  value: 0,
  locale: "ja"
};

export default class Price extends Record(initial) {
  public static getPresentationValue(value: number) {
    return `${value.toLocaleString()}円`;
  }
}
