import { IFirebasePlugin } from "../FirebasePlugin";
import Utility from "./Utility";

declare var FirebasePlugin: IFirebasePlugin;

export type AnalyticsEventMapSearchAgain =
  | "map_fastest_search_again"
  | "map_reserve_search_again";

export type AnalyticsEventCarouselChange =
  | "map_fastest_carousel_swipe"
  | "map_reserve_carousel_swipe";

export type AnalyticsEventPanToCurrentLocationButton =
  | "map_fastest_click_pan_to_current_location_button"
  | "map_reserve_click_pan_to_current_location_button";

export type AnalyticsEventClickMapPinItem =
  | "map_fastest_click_map_pin_item"
  | "map_reserve_click_map_pin_item";

export type AnalyticsEventClickMapCarouselItem =
  | "map_fastest_click_carousel_item"
  | "map_reserve_click_carousel_item";

export type AnalyticsEventSearchClickShopLink =
  | "search_keyword_shop"
  | "search_fastest_shop"
  | "search_reserve_shop";

export type AnalyticsEventSearchLike =
  | "search_keyword_like"
  | "search_fastest_like"
  | "search_reserve_like";

export type AnalyticsEventSearchLikeCancel =
  | "search_keyword_like_cancel"
  | "search_fastest_like_cancel"
  | "search_reserve_like_cancel";

export type AnalyticsEventSearchFilterSet =
  | "search_fastest_filter_set"
  | "search_reserve_filter_set";

export type AnalyticsEventSearchFilterReset =
  | "search_fastest_filter_reset"
  | "search_reserve_filter_reset";

export type AnalyticsEventSearchFilterSetTag =
  | "search_fastest_filter_set_tag"
  | "search_reserve_filter_set_tag";

export type AnalyticsEventSearchFilterUnsetTag =
  | "search_fastest_filter_unset_tag"
  | "search_reserve_filter_unset_tag";

export type AnalyticsEventSearchChangePriceRange =
  | "search_fastest_change_price_range"
  | "search_reserve_change_price_range";

export type AnalyticsEventSearchOpenPlaceSetting =
  | "search_fastest_open_place_setting"
  | "search_reserve_open_place_setting";

export type AnalyticsEventSearchChangeDistance =
  | "search_fastest_change_distance"
  | "search_reserve_change_distance";

export type AnalyticsEventSearchOpenPriceSetting =
  | "search_fastest_open_price_setting"
  | "search_reserve_open_price_setting";

export type AnalyticsEventSearchOpenFilterSetting =
  | "search_fastest_open_filter_setting"
  | "search_reserve_open_filter_setting";

export type AnalyticsEventSearchResetPriceRange =
  | "search_fastest_reset_price_range"
  | "search_reserve_reset_price_range";

export type AnalyticsEventSearchSortType =
  | "search_fastest_sort_distance"
  | "search_reserve_sort_distance"
  | "search_fastest_sort_price_low"
  | "search_reserve_sort_price_low"
  | "search_fastest_sort_price_high"
  | "search_reserve_sort_price_high"
  | "search_fastest_sort_release_date"
  | "search_reserve_sort_release_date";

export type AnalyticsEventName =
  | "app_change_location"
  | "top_login"
  | "top_guestplay"
  | "login_in"
  | "login_pwreset"
  | "pwreset"
  | "userform_register"
  | "authsms_register"
  | "authsms_retry"
  | "register_mail"
  | "register_pass"
  | "register_name"
  | "register_terms"
  | "register_phone"
  | "register_gender"
  | "home_banner"
  | "home_blk_like"
  | "home_blk_pop"
  | "home_blk_luxury"
  | "home_blk_topic"
  | "home_search_input"
  | "home_like"
  | "home_like_cancel"
  | "footer_home"
  | "footer_fastest"
  | "footer_reserve"
  | "footer_hist"
  | "footer_mypage"
  | "shop_tab_top"
  | "shop_tab_menu"
  | "shop_tab_feedback"
  | "shop_tab_map"
  | "shop_tab_link"
  | "shop_launch_map"
  | "shop_like"
  | "shop_like_cancel"
  | "shop_all_menu"
  | "shop_all_feedback"
  | "shop_reputation_vote"
  | "shop_display"
  | "shop_item_to_top"
  | "shop_item_launch_map"
  | "shop_detail_links_tabelog"
  | "shop_detail_links_retty"
  | "credit_scan"
  | "credit_register"
  | "home_cart"
  | "go_to_cart"
  | "item_display"
  | "add_cart"
  | "cart_delete"
  | "cart_change_quant"
  | "cart_credit"
  | "cart_order"
  | "cart_delete_all"
  | "cart_to_shop_title"
  | "cart_to_shop"
  | "cart_change_time"
  | "hist_ongoing_detail"
  | "hist_ongoing_cancel"
  | "hist_ongoing_receipt"
  | "hist_ongoing_inquiry"
  | "hist_past_tab"
  | "hist_past_evaluate"
  | "hist_past_reorder"
  | "hist_ongoing_tab"
  | "eval_"
  | "eval_comment"
  | "eval_submit"
  | "search_setting_input"
  | "search_setting_reserve"
  | "search_fastest_map_to_list"
  | "search_fastest_list_to_map"
  | "search_reserve_shop"
  | "search_reserve_map_to_list"
  | "search_reserve_list_to_map"
  | "search_reserve_open_reserve_time_setting"
  | "search_reserve_set_time"
  | "search_filter_set_time"
  | AnalyticsEventSearchClickShopLink
  | AnalyticsEventSearchLike
  | AnalyticsEventSearchLikeCancel
  | AnalyticsEventSearchFilterSet
  | AnalyticsEventSearchFilterReset
  | AnalyticsEventSearchFilterSetTag
  | AnalyticsEventSearchFilterUnsetTag
  | AnalyticsEventSearchChangePriceRange
  | AnalyticsEventSearchOpenPlaceSetting
  | AnalyticsEventSearchChangeDistance
  | AnalyticsEventSearchOpenPriceSetting
  | AnalyticsEventSearchOpenFilterSetting
  | AnalyticsEventSearchResetPriceRange
  | AnalyticsEventSearchSortType
  | AnalyticsEventMapSearchAgain
  | AnalyticsEventCarouselChange
  | AnalyticsEventPanToCurrentLocationButton
  | AnalyticsEventClickMapPinItem
  | AnalyticsEventClickMapCarouselItem
  | "mypage_to_regist"
  | "mypage_payment"
  | "mypage_help"
  | "mypage_promote"
  | "mypage_invite"
  | "promo_code_add"
  | "error_no_gps"
  | "error_no_gps_setting"
  | "";

export interface AnalyticsEvent {
  app_change_location: AnalyticsEventPayload<"pathname", string>;
  search_genre: AnalyticsEventPayload<"category_tag", string>;
  search_kw: AnalyticsEventPayload<"keyword", string>;
  search_keyword_shop: AnalyticsEventPayload<"shop_id", string>;
  search_fastest_shop: AnalyticsEventPayload<"shop_id", string>;
  search_reserve_shop: AnalyticsEventPayload<"shop_id", string>;
  search_keyword_like: AnalyticsEventPayload<"shop_id", string>;
  search_fastest_like: AnalyticsEventPayload<"shop_id", string>;
  search_reserve_like: AnalyticsEventPayload<"shop_id", string>;
  search_keyword_like_cancel: AnalyticsEventPayload<"shop_id", string>;
  search_fastest_like_cancel: AnalyticsEventPayload<"shop_id", string>;
  search_reserve_like_cancel: AnalyticsEventPayload<"shop_id", string>;
  search_fastest_change_distance: AnalyticsEventPayload<"distance", string>;
  search_fastest_change_price_range: AnalyticsEventPayload<
    "price_range",
    string
  >;
  search_reserve_change_distance: AnalyticsEventPayload<"distance", string>;
  search_reserve_change_price_range: AnalyticsEventPayload<
    "price_range",
    string
  >;
  search_filter_set_time: AnalyticsEventPayload<"time", string>;
  search_fastest_filter_set_tag: AnalyticsEventPayload<"tag_id", string>;
  search_reserve_filter_set_tag: AnalyticsEventPayload<"tag_id", string>;
  search_fastest_filter_unset_tag: AnalyticsEventPayload<"tag_id", string>;
  search_reserve_filter_unset_tag: AnalyticsEventPayload<"tag_id", string>;
  go_to_cart: AnalyticsEventPayload<"pathname", string>;
  shop_launch_map: AnalyticsEventPayload<"shop_id", string>;
  shop_like: AnalyticsEventPayload<"shop_id", string>;
  shop_like_cancel: AnalyticsEventPayload<"shop_id", string>;
  shop_all_menu: AnalyticsEventPayload<"shop_id", string>;
  shop_all_feedback: AnalyticsEventPayload<"shop_id", string>;
  shop_reputation_vote: AnalyticsEventPayload<"shop_id", string>;
  shop_detail_links_tabelog: AnalyticsEventPayload<"shop_id", string>;
  shop_detail_links_retty: AnalyticsEventPayload<"shop_id", string>;
  shop_item_to_top: AnalyticsEventPayload<"shop_item_id", string>;
  shop_item_launch_map: AnalyticsEventPayload<"shop_item_id", string>;
  add_cart: AnalyticsEventPayload<"shop_item_id", string>;
  eval_: AnalyticsEventPayload<"shop_id", string>;
  eval_comment: AnalyticsEventPayload<"shop_id", string>;
  eval_submit: AnalyticsEventPayload<"shop_id", string>;
  shop_display: AnalyticsEventPayload<"shop_id", string>;
  item_display: AnalyticsEventPayload<"shop_item_id", string>;
  cart_delete_all: AnalyticsEventPayload<"shop_id", string>;
  map_fastest_search_again: AnalyticsEventPayload<"latlng", string>;
  map_reserve_search_again: AnalyticsEventPayload<"latlng", string>;
  map_fastest_carousel_swipe: AnalyticsEventPayload<"shop_id", string>;
  map_reserve_carousel_swipe: AnalyticsEventPayload<"shop_id", string>;
  map_fastest_click_map_pin_item: AnalyticsEventPayload<"shop_id", string>;
  map_reserve_click_map_pin_item: AnalyticsEventPayload<"shop_id", string>;
  map_fastest_click_carousel_item: AnalyticsEventPayload<"shop_id", string>;
  map_reserve_click_carousel_item: AnalyticsEventPayload<"shop_id", string>;
}

export interface IAnalyticsEventPayload<T = "", S = ""> {
  content_type: T;
  item_id: S;
}

export type AnalyticsEventPayload<T = "", S = ""> = T extends ""
  ? S extends ""
    ? undefined
    : IAnalyticsEventPayload<T, S>
  : IAnalyticsEventPayload<T, S>;

export default class Analytics {
  public static isEnableVersion(appVersion: string) {
    return Utility.isSameOrNewerVersion("1.7.0", appVersion);
  }

  public static enabled() {
    return !Utility.isDebug() && "FirebasePlugin" in window;
  }

  public static logEvent<K extends AnalyticsEventName>(
    eventName: K,
    payload?: K extends keyof AnalyticsEvent
      ? AnalyticsEvent[K]
      : AnalyticsEventPayload
  ) {
    if (Analytics.enabled()) {
      FirebasePlugin.logEvent(
        eventName,
        typeof payload !== "undefined" ? payload : {}
      );
    }
  }

  public static setUserId(userId: string) {
    if (Analytics.enabled()) {
      FirebasePlugin.setUserId(userId);
    }
  }
}
