import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgShareLink = (props: IProps) => (
  <svg width={30} height={30} {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#B5B5B6" width={30} height={30} rx={6} />
      <path
        d="M16.431 20.204l-2.518 2.563a4.08 4.08 0 0 1-5.845 0 4.213 4.213 0 0 1-1.21-2.975c0-1.123.428-2.18 1.209-2.975l3.395-3.455a4.087 4.087 0 0 1 6.604 1.084l-1.111 1.131a2.753 2.753 0 0 0-.673-1.173c-1.01-1.029-2.742-1.073-3.795-.001v.001L9.09 17.86a2.77 2.77 0 0 0 0 3.863 2.651 2.651 0 0 0 3.797 0l1.692-1.721c.692.202 1.25.238 1.851.202zm.497-12.112l-2.498 2.545c.592-.036 1.157 0 1.854.201l1.673-1.7a2.658 2.658 0 0 1 3.803 0 2.775 2.775 0 0 1 0 3.872l-3.377 3.438-.002.001c-1.005 1.023-2.717 1.105-3.802-.001a2.692 2.692 0 0 1-.669-1.17l-.058.05-1.06 1.077c.195.396.443.765.76 1.087 1.57 1.598 4.197 1.69 5.856 0l3.378-3.437a4.266 4.266 0 0 0 0-5.961 4.088 4.088 0 0 0-5.858-.002z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default function LinkIcon(props: IProps) {
  return <SvgShareLink {...props} />;
}
