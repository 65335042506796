import { put } from "redux-saga/effects";
import {
  SystemStartedConnectApiAction,
  updateConnectingApiSet
} from "../actions";

export default function* systemStartedConnectApiSaga(
  action: SystemStartedConnectApiAction
) {
  yield put(updateConnectingApiSet(action.payload.apiKey, true));
}
