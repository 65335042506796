import { put } from "redux-saga/effects";
import {
  updateDraftFeedbackPoint,
  UserChangedDraftFeedbackPointAction
} from "../actions";

export default function* userChangedDraftFeedbackPointSaga(
  action: UserChangedDraftFeedbackPointAction
) {
  yield put(
    updateDraftFeedbackPoint(action.payload.orderNumber, action.payload.point)
  );
}
