import { fork, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import systemChangedCurrentSearchResultTypeSaga from "./sagas/systemChangedCurrentSearchResultTypeSaga";
import systemDetectedPositionErrorSaga from "./sagas/systemDetectedPositionErrorSaga";
import userAccessedShopPageSaga from "./sagas/userAccessedShopPageSaga";
import userAccessedToHomeSaga from "./sagas/userAccessedToHomeSaga";
import userAccessedToPageNeedsSearchHistoryListSaga from "./sagas/userAccessedToPageNeedsAreaListSaga";
import userAccessedToPageThatNeedsCategoryTagListSaga from "./sagas/userAccessedToPageThatNeedsCategoryTagListSaga";
import userAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga from "./sagas/userAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga";
import userChangedPriceRangeSaga from "./sagas/userChangedPriceRangeSaga";
import userChangedReservationTimeSaga from "./sagas/userChangedReservationTimeSaga";
import userChangedReservationTimeWithoutSearchSaga from "./sagas/userChangedReservationTimeWithoutSearchSaga";
import userChangedSearchRadiusSaga from "./sagas/userChangedSearchRadiusSaga";
import userChangedSortTypeSaga from "./sagas/userChangedSortTypeSaga";
import userSubmittedFilterSettingsSaga from "./sagas/userSubmittedFilterSettingsSaga";
import userSubmittedSearchQuerySaga from "./sagas/userSubmittedSearchQuerySaga";
import userSubmittedSearchSettingsSaga from "./sagas/userSubmittedSearchSettingsSaga";
import userSubmittedSearchSettingsWithoutSearchSaga from "./sagas/userSubmittedSearchSettingsWithoutSearchSaga";
import userTouchedChangeShopFavoriteStateButtonSaga from "./sagas/userTouchedChangeShopFavoriteStateButtonSaga";
import userTouchedMapSearchPinSaga from "./sagas/userTouchedMapSearchPinSaga";
import userTouchedSearchByMapLocationButtonSaga from "./sagas/userTouchedSearchByMapLocationButtonSaga";
import userTouchedToggleResultViewTypeButtonSaga from "./sagas/userTouchedToggleResultViewTypeButtonSaga";
import userTouchedWriteReviewButtonSaga from "./sagas/userTouchedWriteReviewButtonSaga";
import userTouchedCurrentLocationSearchButtonSaga from "./sagas/userTouchedCurrentLocationSearchButtonSaga";
import userTouchedHomeReservationButtonSaga from "./sagas/userTouchedHomeReservationButtonSaga";
import userAccessedToPageThatNeedsInitialSearchResultSaga from "./sagas/userAccessedToPageThatNeedsInitialSearchResultSaga";
import userAccessedAreaPageSaga from "./sagas/userAccessedAreaPageSaga";
import userAccessedToPageThatNeedsAreaShopListSaga from "./sagas/userAccessedToPageThatNeedsAreaShopListSaga";
import userTouchedCategoryTagThumbSaga from "./sagas/userTouchedCategoryTagThumbSaga";
import userAccessedToPageThatNeedsNearOfficeShopListSaga from "./sagas/userAccessedToPageThatNeedsNearOfficeShopListSaga";
import userTouchedMoreNearOfficeShopListButtonSaga from "./sagas/userTouchedMoreNearOfficeShopListButtonSaga";
import userAccessedToPageThatNeedsCurrentHomeShopListSaga from "./sagas/userAccessedToPageThatNeedsCurrentHomeShopListSaga";

export default function* searchSaga() {
  yield fork(() => watchUserAccessedShopPageSaga());
  yield fork(() => watchUserAccessedToPageNeedsSearchHistoryListSaga());
  yield fork(() => watchSystemDetectedPositionErrorSaga());
  yield fork(() => watchUserTouchedMapSearchPinSaga());
  yield fork(() => watchUserAccessedToPageNeedsCategoryTagListSaga());
  yield fork(() => watchUserTouchedChangeShopFavoriteStateButtonSaga());
  yield fork(() => watchUserSubmittedSearchSettingsSaga());
  yield fork(() => watchUserSubmittedSearchSettingsWithoutSearchSaga());
  yield fork(() => watchUserTouchedSearchByMapLocationButtonSaga());
  yield fork(() => watchUserTouchedWriteReviewButtonSaga());
  yield fork(() => watchUserAccessedToHomeSaga());
  yield fork(() => watchSystemChangedCurrentSearchResultTypeSaga());
  yield fork(() => watchUserSubmittedSearchQuerySaga());
  yield fork(() => watchUserTouchedToggleResultViewTypeButtonSaga());
  yield fork(() => watchUserChangedSearchRadiusSaga());
  yield fork(() => watchUserChangedReservationTimeSaga());
  yield fork(() => watchUserChangedReservationTimeWithoutSearchSaga());
  yield fork(() => watchUserChangedPriceRangeSaga());
  yield fork(() => watchUserChangedSortTypeSaga());
  yield fork(() => watchUserSubmittedFilterSettingsSaga());
  yield fork(() =>
    watchUserAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga()
  );
  yield fork(() => watchUserTouchedCurrentLocationSearchButtonSaga());
  yield fork(() => watchUserTouchedHomeReservationButtonSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsInitialSearchResultSaga());
  yield fork(() => watchUserAccessedAreaPageSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsAreaShopListSaga());
  yield fork(() => watchUserTouchedCategoryTagThumbSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsNearOfficeShopListSaga());
  yield fork(() => watchUserTouchedMoreNearOfficeShopListButtonSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsCurrentHomeShopListSaga());
}

function* watchUserAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY_LIST_FOR_SEARCH_RESULT,
    userAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga
  );
}

function* watchUserSubmittedFilterSettingsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_FILTER_SETTINGS,
    userSubmittedFilterSettingsSaga
  );
}

function* watchUserChangedSortTypeSaga() {
  yield takeLatest(ActionTypes.U_CHANGED_SORT_TYPE, userChangedSortTypeSaga);
}

function* watchUserChangedPriceRangeSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_PRICE_RANGE,
    userChangedPriceRangeSaga
  );
}

function* watchUserChangedReservationTimeSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_RESERVATION_TIME,
    userChangedReservationTimeSaga
  );
}

function* watchUserChangedReservationTimeWithoutSearchSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_RESERVATION_TIME_WITHOUT_SEARCH,
    userChangedReservationTimeWithoutSearchSaga
  );
}

function* watchUserChangedSearchRadiusSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_SEARCH_RADIUS,
    userChangedSearchRadiusSaga
  );
}

function* watchUserTouchedToggleResultViewTypeButtonSaga() {
  yield takeEvery(
    ActionTypes.U_TOUCHED_TOGGLE_RESULT_VIEW_TYPE_BUTTON,
    userTouchedToggleResultViewTypeButtonSaga
  );
}

function* watchSystemChangedCurrentSearchResultTypeSaga() {
  yield takeEvery(
    ActionTypes.S_CHANGED_CURRENT_SEARCH_RESULT_TYPE,
    systemChangedCurrentSearchResultTypeSaga
  );
}

function* watchUserTouchedWriteReviewButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_WRITE_REVIEW_BUTTON,
    userTouchedWriteReviewButtonSaga
  );
}

function* watchUserTouchedSearchByMapLocationButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SEARCH_BY_MAP_LOCATION_BUTTON,
    userTouchedSearchByMapLocationButtonSaga
  );
}

function* watchUserSubmittedSearchSettingsSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SEARCH_SETTINGS,
    userSubmittedSearchSettingsSaga
  );
}

function* watchUserSubmittedSearchSettingsWithoutSearchSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SEARCH_SETTINGS_WITHOUT_SEARCH,
    userSubmittedSearchSettingsWithoutSearchSaga
  );
}

function* watchUserTouchedChangeShopFavoriteStateButtonSaga() {
  yield takeEvery(
    ActionTypes.U_TOUCHED_CHANGE_SHOP_FAVORITE_STATE_BUTTON,
    userTouchedChangeShopFavoriteStateButtonSaga
  );
}

function* watchUserAccessedToPageNeedsCategoryTagListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST,
    userAccessedToPageThatNeedsCategoryTagListSaga
  );
}

function* watchSystemDetectedPositionErrorSaga() {
  yield takeLatest(
    ActionTypes.S_DETECTED_POSITION_ERROR,
    systemDetectedPositionErrorSaga
  );
}

function* watchUserTouchedMapSearchPinSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_MAP_SEARCH_PIN,
    userTouchedMapSearchPinSaga
  );
}

function* watchUserAccessedToPageNeedsSearchHistoryListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SEARCH_HISTORY_LIST,
    userAccessedToPageNeedsSearchHistoryListSaga
  );
}

function* watchUserAccessedShopPageSaga() {
  yield takeLatest(ActionTypes.U_ACCESSED_SHOP_PAGE, userAccessedShopPageSaga);
}

function* watchUserAccessedToHomeSaga() {
  yield takeLatest(ActionTypes.U_ACCESSED_TO_HOME, userAccessedToHomeSaga);
}

function* watchUserSubmittedSearchQuerySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SEARCH_QUERY,
    userSubmittedSearchQuerySaga
  );
}

function* watchUserTouchedCurrentLocationSearchButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_CURRENT_LOCATION_SEARCH_BUTTON,
    userTouchedCurrentLocationSearchButtonSaga
  );
}

function* watchUserTouchedHomeReservationButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_HOME_RESERVATION_BUTTON,
    userTouchedHomeReservationButtonSaga
  );
}

function* watchUserAccessedToPageThatNeedsInitialSearchResultSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_INITIAL_SEARCH_RESULT,
    userAccessedToPageThatNeedsInitialSearchResultSaga
  );
}

function* watchUserAccessedAreaPageSaga() {
  yield takeLatest(ActionTypes.U_ACCESSED_AREA_PAGE, userAccessedAreaPageSaga);
}

function* watchUserAccessedToPageThatNeedsAreaShopListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AREA_SHOP_LIST,
    userAccessedToPageThatNeedsAreaShopListSaga
  );
}

function* watchUserTouchedCategoryTagThumbSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_CATEGORY_TAG_THUMB,
    userTouchedCategoryTagThumbSaga
  );
}

function* watchUserAccessedToPageThatNeedsNearOfficeShopListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_NEAR_OFFICE_SHOP_LIST,
    userAccessedToPageThatNeedsNearOfficeShopListSaga
  );
}

function* watchUserTouchedMoreNearOfficeShopListButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_MORE_NEAR_OFFICE_SHOP_LIST_BUTTON,
    userTouchedMoreNearOfficeShopListButtonSaga
  );
}

function* watchUserAccessedToPageThatNeedsCurrentHomeShopListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_HOME_SHOP_LIST,
    userAccessedToPageThatNeedsCurrentHomeShopListSaga
  );
}
