import { goBack, replace } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AppModel from "../../modules/app/model";
import {
  updateUser,
  userTouchedLogoutButton
} from "../../modules/user/actions";
import UserModel from "../../modules/user/model";
import { ReduxModel } from "../../reducer";
import SocialSharing from "../../util/SocialSharing";
import Utility from "../../util/Utility";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ListLink from "../molecules/ListLink";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public userTouchedLogoutButton() {
    this.dispatch(userTouchedLogoutButton());
  }

  public goToMaintenancePage(user: UserModel) {
    this.dispatch(updateUser(user.getData().setMaintenanceFlag()));
    this.dispatch(replace("/maintenance"));
  }
}

interface IProps {
  user: UserModel;
  appModel: AppModel;
  actions: ActionDispatcher;
}

const UserDebugTemplate = React.memo((props: IProps) => {
  const { actions, user, appModel } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const goToMaintenancePage = useCallback(() => {
    actions.goToMaintenancePage(user);
  }, [actions, user]);

  const logOut = useCallback(() => {
    actions.userTouchedLogoutButton();
  }, [actions]);

  const snsShareText = useCallback(() => {
    SocialSharing.socialShareText("シェアテキスト");
  }, []);

  const handleClickUpdateResource = useCallback(() => {
    appModel.updateResourceManually();
  }, [appModel]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="デバッグ機能" handleClickBack={handleGoBack} />
        }
      >
        <PageBody style={{ padding: 0 }}>
          <div
            style={{ padding: "20px" }}
            onClick={() => Utility.execCopyText(user.getData().getId())}
          >
            <div>ユーザーID: {user.getData().getId()}</div>
            <div style={{ paddingTop: "4px", color: "gray" }}>
              タップでコピーされます
            </div>
          </div>
          <ul>
            <ListLink onClick={logOut}>ログアウト</ListLink>
            <ListLink onClick={goToMaintenancePage}>
              メンテナンスページ確認
            </ListLink>
            <ListLink onClick={snsShareText}>メッセージをシェア</ListLink>
            <ListLink link="/invitation">友達招待コード</ListLink>
            <ListLink link="/register/discountCodeAtFirstAccess">
              初回アクセス時クーポン入力
            </ListLink>
            <ListLink link="/inputSpecialCode">QRコードリーダ</ListLink>
            <ListLink link="/resendSMSAuthCode">SMS再送信ページ</ListLink>
            <ListLink onClick={handleClickUpdateResource}>
              リソース再読み込み
            </ListLink>
          </ul>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  user: state.user,
  appModel: state.app
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDebugTemplate);
