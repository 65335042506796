import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { ISendReceiptMailRequest } from "../../../apis/sendReceiptMail";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { UserTouchedSendReceiptMailAction } from "../actions";

export default function* userTouchedSendReceiptMailSaga(
  action: UserTouchedSendReceiptMailAction
) {
  const { orderNumber } = action.payload;
  const params: ISendReceiptMailRequest = {
    order_no: orderNumber
  };
  try {
    const commonApiTask = yield fork(
      commonApiSaga,
      EApiKey.SEND_RECEIPT_MAIL,
      params
    );
    const { result, error } = yield join(commonApiTask);
    if (result && !error) {
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "領収書を送信しました。",
          message: `ご登録のメールアドレス宛に
          領収書を送信しました。`
        })
      );
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `領収書の送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
