import { Record } from "immutable";
import Price from "./Price";

export interface IShopOption {
  shop_option_id: number;
  shop_option_set_id: number;
  option_set_name: string;
  option_name: string;
  option_name_en: string;
  price: number;
  is_selected: boolean;
}

const initialValues: IShopOption = {
  shop_option_id: 0,
  shop_option_set_id: 0,
  option_set_name: "",
  option_name: "",
  option_name_en: "",
  price: 0,
  is_selected: false
};

/**
 * メニューのオプションにひもづく個別の選択項目
 */
export default class ShopOption extends Record(initialValues) {
  public getId(): number {
    return this.get("shop_option_id");
  }

  public getShopOptionSetId(): number {
    return this.get("shop_option_set_id");
  }

  public getOptionSetName(): string {
    return this.get("option_set_name");
  }

  public getOptionName(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("option_name_en");
      default:
        return this.get("option_name");
    }
  }

  public getPrice(): number {
    return this.get("price");
  }

  public getPresentationPrice(): string {
    return `${this.getPrice() > 0 ? "+" : ""}${Price.getPresentationValue(
      this.getPrice()
    )}`;
  }

  public isSelected(): boolean {
    return this.get("is_selected");
  }

  public updateSelected() {
    return this.isSelected()
      ? this.set("is_selected", false)
      : this.set("is_selected", true);
  }

  public removeSelected() {
    return this.set("is_selected", false);
  }
}
