import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IInputDiscountCodeForm,
  initialInputDiscountCodeForm,
  validateInputDiscountCodeForm
} from "../../forms/InputDiscountCode";
import {
  userSubmittedDiscountCodeAtFirstAccess,
  userTouchedSkipInputDiscountCodeButton
} from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import ButtonAlter from "../atoms/ButtonAlter";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};
const noteStyle: React.CSSProperties = {
  textIndent: "-1em",
  paddingLeft: "1em"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RegisterDiscountCodeAtFirstAccessTemplate: React.FC<
  IProps & InjectedFormProps<IInputDiscountCodeForm>
> = React.memo(props => {
  const {
    submittingInputDiscountCode,
    installCoupon,
    handleSubmit,
    handleSkip,
    valid,
    touch
  } = props;

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialInputDiscountCodeForm));
        return;
      }
      handleSubmit();
    },
    [valid, touch, handleSubmit]
  );

  const handleSkipWrap = useCallback(() => {
    handleSkip();
  }, [handleSkip]);

  return (
    <Page
      header={<SubPageHeader title="クーポンコード入力" />}
      containerStyle={containerStyle}
      contentStyle={contentStyle}
    >
      <PageBody style={pageBodyStyle}>
        <div style={{ flexGrow: 1 }}>
          <form id="discountCodeForm" onSubmit={handleSubmitWrap}>
            {installCoupon.existsCode() && (
              <>
                <Notes
                  style={{
                    color: colorsConst.MAIN,
                    marginBottom: "25px",
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "bold"
                  }}
                >
                  クーポンコード「{installCoupon.getCode()}
                  」を入力してクーポンGET
                </Notes>
              </>
            )}

            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              クーポンコードを入力してください
            </div>

            <TextField
              name="discount_code"
              id="inputDiscountCode"
              type="text"
              placeholder="クーポンコード"
            />
            <div
              style={{
                textAlign: "center",
                marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
              }}
            />
            <Notes style={{ ...noteStyle, marginTop: "30px" }}>
              ※クーポンの種類によっては、電話番号登録とSMS認証が必要です。
            </Notes>
            {installCoupon.existsCode() && (
              <Notes style={{ ...noteStyle, paddingTop: "1em" }}>
                ※上記のクーポンコード以外をお持ちの方は、お手持ちのクーポンコードを入力してください。
              </Notes>
            )}
            <Notes style={{ ...noteStyle, paddingTop: "1em" }}>
              ※後からでもマイページのクーポン管理画面で登録することができます。
            </Notes>
          </form>
        </div>
        <div style={{ flexGrow: 0 }}>
          <ButtonAlter
            block
            appearance={"secondary"}
            form="discountCodeForm"
            disabled={!valid}
            loading={submittingInputDiscountCode}
            type="submit"
          >
            登録
          </ButtonAlter>
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <span
              onClick={handleSkipWrap}
              style={{
                color: colorsConst.MAIN,
                borderBottom: `1px solid ${colorsConst.MAIN}`
              }}
            >
              後で登録
            </span>
          </div>
        </div>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  installCoupon: state.user.getInstallCoupon(),
  submittingInputDiscountCode: state.app.isConnectedApi(
    EApiKey.INPUT_DISCOUNT_CODE
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleSubmit() {
    dispatch(userSubmittedDiscountCodeAtFirstAccess());
  },
  handleSkip() {
    dispatch(userTouchedSkipInputDiscountCodeButton());
  }
});

const reduxFormConfig = {
  form: EFormID.INPUT_DISCOUNT_CODE,
  initialValues: initialInputDiscountCodeForm,
  validate: validateInputDiscountCodeForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IInputDiscountCodeForm, IProps>(reduxFormConfig)(
    RegisterDiscountCodeAtFirstAccessTemplate
  )
);
