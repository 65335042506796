import { goBack } from "connected-react-router";
import { List } from "immutable";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { EProcessingFlag } from "../../modules/app/model";
import {
  userAccessedToPreOrderPage,
  userChangedPreOrderCount,
  userChangedPreOrderOptionList,
  userChangedPreOrderText,
  userSubmittedAddToCart
} from "../../modules/order/actions";
import { IAddToCartRequestParams } from "../../modules/order/model";
import { userAccessedShopPage } from "../../modules/search/actions";
import { SearchResultType } from "../../records/SearchResult";
import ShopData from "../../records/ShopData";
import ShopItem from "../../records/ShopItem";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import ArrowIcon from "../atoms/ArrowIcon";
import HeaderContainer from "../atoms/HeaderContainer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import Auth from "../organisms/Auth";
import ShopItemViewer from "../organisms/ShopItemViewer";
import ShopItemViewerFooter from "../organisms/ShopItemViewerFooter";
import ShopMenuContainer from "../organisms/ShopMenuContainer";

const pageBodyStyle: React.CSSProperties = {
  padding: 0,
  height: "100%"
};

const BlankContainer = styled.div`
  text-align: center;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
`;

const BlankImgWrap = styled.div`
  position: relative;
  overflow: hidden;
`;

const BlankImg = styled.div`
  padding-top: ${(380 / 375) * 100}%;
  background-color: #c8c8c8;
`;

const BlankOverlay = styled.span`
  width: 100%;
  height: 50%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 10%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const BlankTitle = styled.div`
  padding: 45px 0;
`;

const BlankContent = styled.div`
  padding: 0 0 15px 0;
`;

const BlankOption = styled.div`
  width: 100%;
  height: 62px;
  background-color: #f5f5f5;
`;

const BlankComment = styled.div`
  margin: 15px 20px;
  min-height: calc(1.5 * 2em + 20px);
  border-bottom: 1px solid #f1f1f1;
`;

const ShopItemHeaderContainer = styled(HeaderContainer)`
  height: 0;
  overflow: visible;
`;

const BackIconContainer = styled.div`
  position: absolute;
  top: calc(10px + constant(safe-area-inset-top));
  top: calc(10px + env(safe-area-inset-top));
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  cursor: pointer;
  font-size: 19px;
`;

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public userAccessedShopPage(shopId: number) {
    this.dispatch(userAccessedShopPage(shopId));
  }

  public userSubmittedAddToCart(
    resultType: SearchResultType,
    params: Pick<IAddToCartRequestParams, "shop_id" | "shop_item_id">
  ) {
    this.dispatch(userSubmittedAddToCart(resultType, params));
  }

  public userChangedPreOrderOptionList(list: number[]) {
    this.dispatch(userChangedPreOrderOptionList(list));
  }

  public userChangedPreOrderInstructionText(text: string) {
    this.dispatch(userChangedPreOrderText(text));
  }

  public userChangedPreOrderCount(count: number) {
    this.dispatch(userChangedPreOrderCount(count));
  }

  public userAccessedToPreOrderPage(initial: {
    option_list: number[];
    count: number;
    instruction_text: string;
  }) {
    this.dispatch(userAccessedToPreOrderPage(initial));
  }
}

interface IProps
  extends RouteComponentProps<{ shopId: string; itemId: string }> {
  shopItem: ShopItem | undefined;
  currentShopData: ShopData;
  submittingAddCart: boolean;
  gettingShopData: boolean;
  actions: ActionDispatcher;
  shopId: number;
  itemId: number;
  options: List<number>;
  notes: string;
  quantity: number;
  searchResultType: SearchResultType;
}

const ShopItemTemplate = React.memo((props: IProps) => {
  const {
    shopId,
    itemId,
    shopItem,
    currentShopData,
    gettingShopData: fetchingShopData,
    submittingAddCart,
    options,
    notes,
    quantity,
    searchResultType,
    actions
  } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const handleSubmit = useCallback(() => {
    const params: Pick<IAddToCartRequestParams, "shop_id" | "shop_item_id"> = {
      shop_id: shopId,
      shop_item_id: itemId
    };
    actions.userSubmittedAddToCart(searchResultType, params);
    Analytics.logEvent("add_cart", {
      content_type: "shop_item_id",
      item_id: `${itemId}`
    });
  }, [shopId, itemId, actions, searchResultType]);

  const isLoading = useMemo(() => {
    return (
      !currentShopData.existsData() ||
      fetchingShopData ||
      typeof shopItem === "undefined" ||
      shopId !== currentShopData.getId()
    );
  }, [currentShopData, fetchingShopData, shopItem, shopId]);

  const handleChangePreOrderOption = useCallback(
    (list: number[]) => {
      actions.userChangedPreOrderOptionList(list);
    },
    [actions]
  );

  const handleChangePreOrderInstructionText = useCallback(
    (text: string) => {
      actions.userChangedPreOrderInstructionText(text);
    },
    [actions]
  );

  const handleChangePreOrderCount = useCallback(
    (count: number) => {
      actions.userChangedPreOrderCount(count);
    },
    [actions]
  );

  const [optionListError, setOptionListError] = useState(false);

  const handleMountViewer = useCallback(() => {
    if (typeof shopItem !== "undefined") {
      actions.userAccessedToPreOrderPage({
        count: 1,
        option_list: shopItem.getSelectedOptionIdList().toArray(),
        instruction_text: ""
      });
    }
  }, [actions, shopItem]);

  const handleValidateOptionList = useCallback((disable: boolean) => {
    setOptionListError(disable);
  }, []);

  useEffect(() => {
    actions.userAccessedShopPage(shopId);
  }, [actions, shopId]);

  useEffect(() => {
    Analytics.logEvent("item_display", {
      content_type: "shop_item_id",
      item_id: `${itemId}`
    });
  }, [itemId]);

  return (
    <Auth>
      <Page
        footer={
          <ShopItemViewerFooter
            shopId={currentShopData.getId()}
            item={shopItem}
            options={options}
            notes={notes}
            quantity={quantity}
            stateText={
              !currentShopData.getItemStateText(shopItem) && optionListError
                ? "オプションを確認してください"
                : currentShopData.getItemStateText(shopItem)
            }
            submitButtonLabel={`カートに入れる`}
            handleSubmit={handleSubmit}
            connectingAddOrUpdateItem={submittingAddCart}
            disabled={
              shopItem && !optionListError
                ? currentShopData.disabledItem(shopItem)
                : true
            }
          />
        }
      >
        <PageBody style={pageBodyStyle}>
          <ShopItemHeaderContainer transparent shopItem>
            <BackIconContainer onClick={handleGoBack}>
              <ArrowIcon invert />
            </BackIconContainer>
          </ShopItemHeaderContainer>
          <ShopMenuContainer itemId={itemId}>
            {isLoading ? (
              <BlankContainer>
                <LoadingContainer>
                  <Loading />
                </LoadingContainer>
                <BlankImgWrap>
                  <BlankImg />
                  <BlankOverlay />
                </BlankImgWrap>
                <BlankTitle />
                <BlankContent>
                  <BlankOption />
                  <BlankComment />
                </BlankContent>
              </BlankContainer>
            ) : typeof shopItem === "undefined" ? (
              <BlankContainer>メニューが見つかりません</BlankContainer>
            ) : (
              <ShopItemViewer
                shopId={shopId}
                shopData={currentShopData}
                item={shopItem}
                options={options}
                quantity={quantity}
                notes={notes}
                handleMount={handleMountViewer}
                handleChangePreOrderOptionList={handleChangePreOrderOption}
                handleChangePreOrderInstructionText={
                  handleChangePreOrderInstructionText
                }
                handleChangePreOrderCount={handleChangePreOrderCount}
                connectingAddOrUpdateItem={submittingAddCart}
                disabled={currentShopData.disabledItem(shopItem)}
                handleValidateOptionList={handleValidateOptionList}
                isLuxury={currentShopData.isLuxury()}
              />
            )}
          </ShopMenuContainer>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ id: string; itemId: string }>
) => {
  const shopId = parseInt(ownProps.match.params.id, 10);
  const itemId = parseInt(ownProps.match.params.itemId, 10);
  return {
    shopId,
    itemId,
    shopItem: state.search.findItemData(itemId),
    currentShopData: state.search.getCurrentShopData(),
    submittingAddCart: state.app.isProcessing(EProcessingFlag.SUBMIT_ADD_CART),
    gettingShopData: state.app.isConnectedApi(
      EApiKey.GET_SHOP_DATA_WITH_TIME_TYPE
    ),
    options: state.order.getPreOrderOptionList(),
    notes: state.order.getPreOrderInstructionText(),
    quantity: state.order.getPreOrderCount(),
    searchResultType: state.search.getCurrentSearchResultType()
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShopItemTemplate)
);
