import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IUpdateMailAddressForm {
  mail: string;
}

export const initialUpdateMailAddressForm: IUpdateMailAddressForm = {
  mail: ""
};

export function validateUpdateMailAddressForm(values: IUpdateMailAddressForm) {
  const errors: FormErrors<IUpdateMailAddressForm> = {};
  if (!values.mail) {
    errors.mail = "メールアドレスを入力して下さい";
  } else if (!UserModel.validEmail(values.mail)) {
    errors.mail = "メールアドレスの形式が正しくありません";
  }
  return errors;
}
