import { put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import {
  updateSearchSetting,
  UserTouchedMapSearchPinAction
} from "../actions/index";

export default function* userTouchedMapSearchPinSaga(
  action: UserTouchedMapSearchPinAction
) {
  const { resultType, item } = action.payload;
  const searchSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  yield put(
    updateSearchSetting(resultType, searchSetting.setSelectedMapItem(item))
  );
}
