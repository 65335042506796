import { fork, put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { updateSearchSetting, UserChangedSearchRadiusAction } from "../actions";
import getSearchResultSaga from "./getSearchResultSaga";

export default function* userChangedSearchRadiusSaga(
  action: UserChangedSearchRadiusAction
) {
  const { resultType, radius } = action.payload;

  const currentSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  yield put(
    updateSearchSetting(resultType, currentSetting.setSearchRadius(radius))
  );
  yield fork(() => getSearchResultSaga(resultType));
}
