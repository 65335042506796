import { ActionTypes, UserActions } from "./actions";
import UserModel from "./model";

export default function reducer(
  model = new UserModel(),
  action: UserActions
): UserModel {
  switch (action.type) {
    case ActionTypes.R_UPDATE_USER:
      return model.updateData(action.payload.data);
    case ActionTypes.R_UPDATE_LOGIN_TOKEN:
      return model.updateLoginToken(action.payload.token);
    case ActionTypes.R_UPDATE_CREDIT_CARD:
      return model.updateCreditCard(action.payload);
    case ActionTypes.R_UPDATE_DISCOUNT_CODES:
      return model.updateDiscountCodes(action.payload.codes);
    case ActionTypes.R_RESET_USER:
      return model.resetUser();
    case ActionTypes.R_UPDATE_INSTALL_COUPON:
      return model.updateInstallCoupon(action.payload.install_coupon);
    default:
      return model;
  }
}
