import { fork } from "redux-saga/effects";
import appSaga from "./modules/app/sagas";
import notificationSaga from "./modules/notification/sagas";
import orderSaga from "./modules/order/sagas";
import searchSaga from "./modules/search/sagas";
import userSaga from "./modules/user/sagas";

export default function* rootSaga() {
  try {
    yield fork(() => appSaga());
    yield fork(() => orderSaga());
    yield fork(() => searchSaga());
    yield fork(() => userSaga());
    yield fork(() => notificationSaga());
  } catch (error) {
    console.log({ error });
  }
}
