import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { destroy } from "redux-form";
import { EApiKey } from "../../../apis";
import {
  ISendShopPostingDesiredRequest,
  ISendShopPostingDesiredResponse
} from "../../../apis/sendShopPostingDesired";
import { systemAddedAlert, systemOpenedModal } from "../../app/actions";
import { AlertType } from "../../../records/Alert";
import { EFormID } from "../../../forms";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  IRequesrShopForm,
  initialRequesrShopForm
} from "../../../forms/requestShop";
import { ReduxModel } from "../../../reducer";
import Utility from "../../../util/Utility";

export default function* userSubmittedRequestShopSaga() {
  try {
    const {
      posting_shop_name,
      recommend_reason,
      contact_phone_number
    }: IRequesrShopForm = yield select<(state: ReduxModel) => IRequesrShopForm>(
      state =>
        Utility.getReduxFormValues(
          state,
          EFormID.REQUEST_SHOP,
          initialRequesrShopForm
        )
    );
    const params: ISendShopPostingDesiredRequest = {
      posting_shop_name,
      recommend_reason,
      contact_phone_number
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.SEND_SHOP_POSTING_DESIRED,
      params
    );
    const {
      result,
      error
    }: {
      result: ISendShopPostingDesiredResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(destroy(EFormID.REQUEST_SHOP));
      yield put(systemOpenedModal("SEND_REQUEST_COMPLETE", {}));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `リクエストの送信に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
