import { Record } from "immutable";
import uuid from "uuid";

export enum AlertType {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  SubmittedOrder = "submittedOrder",
  WarningOverFavorite = "warningOverFavorite"
}

export interface IAlertProps {
  type: AlertType;
  title?: string;
  message?: string;
}

export interface IAlert extends IAlertProps {
  id: string;
}

const initialValues: IAlert = {
  id: "",
  type: AlertType.Info,
  title: "",
  message: ""
};

export default class Alert extends Record(initialValues) {
  public static create(alertProps: IAlertProps) {
    return new Alert(
      Object.assign({}, alertProps, {
        id: uuid(),
        hasViewedFlag: false,
        hasMountedFlag: false
      })
    );
  }

  private constructor(param: any) {
    super(param);
  }

  public getId(): string {
    return this.get("id");
  }

  public isSubmittedOrder() {
    return this.getType() === AlertType.SubmittedOrder;
  }

  public getTitle() {
    return this.get("title");
  }

  public hasTitle() {
    const title = this.getTitle();
    return typeof title !== "undefined" && title !== null && title !== "";
  }

  public getMessage() {
    return this.get("message");
  }

  public hasMessage() {
    const message = this.getMessage();
    return typeof message !== "undefined" && message !== null && message !== "";
  }

  public getType(): AlertType {
    return this.get("type");
  }

  public isError() {
    return this.getType() === AlertType.Danger;
  }

  public getButtonLabel() {
    switch (this.getType()) {
      case AlertType.SubmittedOrder:
      case AlertType.WarningOverFavorite:
        return "閉じる";
      default:
        return "OK";
    }
  }
}
