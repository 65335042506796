import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";
import Utility from "../util/Utility";

export interface IRegisterNickNameForm {
  nick_name: string;
}

export const initialRegisterNickNameForm: IRegisterNickNameForm = {
  nick_name: ""
};

export function validateRegisterNickNameForm(values: IRegisterNickNameForm) {
  const errors: FormErrors<IRegisterNickNameForm> = {};
  if (!values.nick_name) {
    errors.nick_name = "ニックネームを入力してください";
  } else if (!UserModel.validNickName(values.nick_name)) {
    errors.nick_name = "ニックネームに記号は使用できません";
  } else if (Utility.getTextBytes(values.nick_name) > 20) {
    errors.nick_name = "半角20文字・全角10文字以内で記入してください";
  }
  return errors;
}
