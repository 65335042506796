import * as React from "react";
interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgGroup = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 27 27" fill="none" {...props}>
    <path
      d="M13.3 5.74a.872.872 0 0 0 .868-.868V.868A.872.872 0 0 0 13.3 0a.872.872 0 0 0-.868.868v4.004c0 .476.392.868.868.868zM7.672 7.56a.869.869 0 0 0 .616.252.869.869 0 0 0 .616-.252.879.879 0 0 0 0-1.232L6.076 3.5a.879.879 0 0 0-1.232 0 .879.879 0 0 0 0 1.232L7.672 7.56zM18.312 7.812a.869.869 0 0 0 .616-.252l2.828-2.828a.879.879 0 0 0 0-1.232.879.879 0 0 0-1.232 0l-2.828 2.828a.879.879 0 0 0 0 1.232.869.869 0 0 0 .616.252zM25.732 24.808H24.08a10.846 10.846 0 0 0-9.912-9.912v-2.8h2.212a.872.872 0 0 0 .868-.868.872.872 0 0 0-.868-.868h-6.16a.872.872 0 0 0-.868.868c0 .476.392.868.868.868h2.184v2.8a10.843 10.843 0 0 0-9.884 9.912H.868a.872.872 0 0 0-.868.868c0 .476.392.868.868.868h24.836a.872.872 0 0 0 .868-.868c0-.476-.364-.868-.84-.868zM13.3 16.576c4.732 0 8.568 3.64 9.016 8.232H4.284c.448-4.62 4.284-8.232 9.016-8.232z"
      fill="#000"
    />
  </svg>
);

const OrderAcceptIcon = React.memo((props: IProps) => <SvgGroup {...props} />);

export default OrderAcceptIcon;
