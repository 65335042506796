import { Record } from "immutable";
import { AnalyticsEventName } from "../util/Analytics";

export enum EexternalLinktype {
  TABELOG = 1,
  RETTY = 2
}

export interface IExternalLink {
  type: EexternalLinktype;
  url: string;
}

const initial = {
  type: EexternalLinktype.TABELOG,
  url: ""
};

export default class ExternalLink extends Record(initial) {
  public getType() {
    return this.get("type");
  }
  public getUrl() {
    return this.get("url");
  }
  public existLinkUrl() {
    return this.getUrl() !== "";
  }
  public getLinkText() {
    const type = this.getType();
    switch (type) {
      case EexternalLinktype.TABELOG:
        return "食べログ";
      case EexternalLinktype.RETTY:
        return "Retty";
      default:
        return "";
    }
  }
  public getAnalyticsEventName(): AnalyticsEventName {
    const type = this.getType();
    switch (type) {
      case EexternalLinktype.TABELOG:
        return "shop_detail_links_tabelog";
      case EexternalLinktype.RETTY:
        return "shop_detail_links_retty";
      default:
        return "";
    }
  }
}
