import { List } from "immutable";
import * as React from "react";
import styled from "styled-components";
import ExternalLink from "../../records/ExternalLink";
import Analytics from "../../util/Analytics";
import ExternalLinkIcon from "../atoms/ExternalLinkIcon";

const ExternalLinkItem = styled.a``;

const ExternalLinkElement = styled.div`
  padding-bottom: 17px;
`;

const ExternalLinkText = styled.span`
  display: inline-block;
  padding-right: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: ${20 / 14};
  text-decoration: underline;
  text-decoration-line: underline;
  color: #595959;
`;

interface IProps {
  shopId: number;
  existExternalLinkUrl: boolean;
  externalLink: List<ExternalLink>;
}

const ShopTabLink: React.FC<IProps> = React.memo(props => {
  const { shopId, existExternalLinkUrl, externalLink } = props;
  return (
    <>
      {existExternalLinkUrl ? (
        <div
          style={{
            paddingTop: "20px",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          {externalLink.map((link: ExternalLink, index) => {
            return (
              link.existLinkUrl() && (
                <ExternalLinkElement key={index}>
                  <ExternalLinkItem
                    target="_system"
                    href={link.getUrl()}
                    onClick={() =>
                      Analytics.logEvent(link.getAnalyticsEventName(), {
                        content_type: "shop_id",
                        item_id: `${shopId}`
                      })
                    }
                  >
                    <ExternalLinkText>
                      {link.getLinkText()}で見る
                    </ExternalLinkText>
                    <ExternalLinkIcon />
                  </ExternalLinkItem>
                </ExternalLinkElement>
              )
            );
          })}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "60px",
            color: "#b5b5b6"
          }}
        >
          リンクがありません
        </div>
      )}
    </>
  );
});

export default ShopTabLink;
