import { ColorProperty } from "csstype";
import React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
}

const ratio = 25 / 22;

const ConsoleIcon: React.FC<IProps> = ({
  size = 22,
  color = "#000",
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size * ratio}
      viewBox="0 0 22 25"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3549 21.8462C18.9792 23.306 17.654 24.3847 16.0769 24.3847C14.4998 24.3847 13.1747 23.306 12.7989 21.8462L0.848946 21.8462C0.380085 21.8462 0 21.4706 0 21.0001C0 20.5328 0.381851 20.1539 0.848946 20.1539L12.7989 20.1539C13.1747 18.6941 14.4998 17.6155 16.0769 17.6155C17.654 17.6155 18.9792 18.6941 19.3549 20.1539H21.1511C21.6199 20.1539 22 20.5295 22 21.0001C22 21.4674 21.6181 21.8462 21.1511 21.8462H19.3549ZM2.64509 11.6924C3.02082 10.2326 4.34598 9.15392 5.92308 9.15392C7.50017 9.15392 8.82533 10.2326 9.20106 11.6924L21.1511 11.6924C21.6199 11.6924 22 12.068 22 12.5385C22 13.0059 21.6181 13.3847 21.1511 13.3847L9.20106 13.3847C8.82533 14.8445 7.50017 15.9232 5.92308 15.9232C4.34598 15.9232 3.02082 14.8445 2.64509 13.3847H0.848946C0.380085 13.3847 0 13.0091 0 12.5385C0 12.0712 0.381851 11.6924 0.848946 11.6924H2.64509ZM19.3549 4.92315C18.9792 6.38296 17.654 7.46161 16.0769 7.46161C14.4998 7.46161 13.1747 6.38296 12.7989 4.92315L0.848946 4.92315C0.380085 4.92315 0 4.54757 0 4.077C0 3.60968 0.381851 3.23084 0.848946 3.23084L12.7989 3.23084C13.1747 1.77104 14.4998 0.692383 16.0769 0.692383C17.654 0.692383 18.9792 1.77104 19.3549 3.23084L21.1511 3.23084C21.6199 3.23084 22 3.60642 22 4.077C22 4.54432 21.6181 4.92315 21.1511 4.92315L19.3549 4.92315ZM14.3846 4.077C14.3846 5.01163 15.1423 5.76931 16.0769 5.76931C17.0116 5.76931 17.7692 5.01163 17.7692 4.077C17.7692 3.14236 17.0116 2.38469 16.0769 2.38469C15.1423 2.38469 14.3846 3.14236 14.3846 4.077ZM7.61538 12.5385C7.61538 11.6039 6.85771 10.8462 5.92308 10.8462C4.98844 10.8462 4.23077 11.6039 4.23077 12.5385C4.23077 13.4732 4.98844 14.2308 5.92308 14.2308C6.85771 14.2308 7.61538 13.4732 7.61538 12.5385ZM14.3846 21.0001C14.3846 21.9347 15.1423 22.6924 16.0769 22.6924C17.0116 22.6924 17.7692 21.9347 17.7692 21.0001C17.7692 20.0654 17.0116 19.3078 16.0769 19.3078C15.1423 19.3078 14.3846 20.0654 14.3846 21.0001Z"
        fill={color}
      />
    </svg>
  );
};

export default ConsoleIcon;
