export type ETempStringKey = "DISCOUNT_CODE";

export default class TempStringMemory {
  public static save(key: string, value: string) {
    this.memory.set(key, value);
  }

  public static load(key: string) {
    return this.memory.get(key);
  }

  public static delete(key: string) {
    this.memory.delete(key);
  }

  private static memory = new Map<string, string>();
}
