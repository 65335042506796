import { List, Record } from "immutable";
import HomePlaceIcon from "../components/atoms/HomePlaceIcon";
import RoutePlaceIcon from "../components/atoms/RoutePlaceIcon";
import WorkPlaceIcon from "../components/atoms/WorkPlaceIcon";
import { ILatLng } from "../modules/search/model";

export enum ERegistrationPlace {
  NULL = 0,
  OFFICE_SCHOOL = 1,
  HOME = 2
}

// ソート順保障の為
const displayableRegistrationPlaceTypeList = List<ERegistrationPlace>([
  ERegistrationPlace.HOME,
  ERegistrationPlace.OFFICE_SCHOOL
]);

export interface IPlaceListItem {
  type: ERegistrationPlace;
  address_text: string;
  latitude: string;
  longitude: string;
}

const initial: IPlaceListItem = {
  type: ERegistrationPlace.NULL,
  address_text: "",
  latitude: "0",
  longitude: "0"
};

export default class PlaceListItem extends Record(initial) {
  public static create(
    type: ERegistrationPlace,
    text: string,
    latLng: ILatLng
  ) {
    return new PlaceListItem({
      type,
      address_text: text,
      latitude: `${latLng.lat}`,
      longitude: `${latLng.lng}`
    });
  }

  public static getOrderedList(list: List<PlaceListItem>) {
    return displayableRegistrationPlaceTypeList.flatMap<PlaceListItem>(type =>
      list.filter(i => i.getType() === type)
    );
  }

  private static getIconComponent(type: ERegistrationPlace) {
    switch (type) {
      case ERegistrationPlace.OFFICE_SCHOOL:
        return WorkPlaceIcon;
      case ERegistrationPlace.HOME:
        return HomePlaceIcon;
      default:
        return RoutePlaceIcon;
    }
  }

  private static getLabelText(type: ERegistrationPlace) {
    switch (type) {
      case ERegistrationPlace.OFFICE_SCHOOL:
        return "勤務地・学校";
      case ERegistrationPlace.HOME:
        return "最寄駅（自宅）";
      default:
        return "";
    }
  }

  public getLabelText() {
    return PlaceListItem.getLabelText(this.getType());
  }

  public getIconComponent() {
    return PlaceListItem.getIconComponent(this.getType());
  }

  public getType() {
    return this.get("type");
  }

  public getAddressText() {
    return this.get("address_text");
  }

  public getLocation(): ILatLng {
    const [lat, lng] = [
      parseFloat(this.get("latitude")),
      parseFloat(this.get("longitude"))
    ];
    return { lat, lng };
  }

  public registered() {
    return (
      this.getType() !== ERegistrationPlace.NULL &&
      this.getAddressText() !== "" &&
      this.validLatLng()
    );
  }

  private validLatLng() {
    const { lat, lng } = this.getLocation();
    return !isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0;
  }
}
