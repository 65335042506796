import { List, Record, Set } from "immutable";
import { DEFAULT_MAP_LOCATION } from "../../components/organisms/MapViewerStruct";
import { ILatLng } from "../../modules/search/model";
import EvilDatetime from "../../util/EvilDatetime";
import Geolocation from "../../util/Geolocation";
import CategoryTag from "../CategoryTag";
import ReservationTime from "../ReservationTime";
import { SearchResultDisplayCondition } from "../SearchResult";
import ShopForSearch from "../ShopForSearch";
import { MaxDisplayableCookingTime } from "./cookingTime";
import PriceRange from "./PriceRange";
import { ResultSortType } from "./resultSortType";
import SearchPlace from "./SearchPlace";
import { DEFAULT_SEARCH_RADIUS, SearchRadius } from "./searchRadius";

export type ResultViewType = "list" | "map";
const DEFAULT_RESULT_VIEW_TYPE = "list";

interface ISearchSetting {
  reservationTime: ReservationTime;
  searchPlace: SearchPlace;
  sortType: ResultSortType;
  searchRadius: SearchRadius;
  viewType: ResultViewType;
  selectedMapItem: ShopForSearch | undefined;
  mapLocation: ILatLng;
  priceRange: PriceRange;
  maxDisplayableCookingTime: MaxDisplayableCookingTime;
  filterCategoryTagList: Set<CategoryTag>;
}

const initialSetting: ISearchSetting = {
  reservationTime: ReservationTime.createFastest(),
  searchPlace: SearchPlace.asCurrentLocation(),
  sortType: "distance",
  searchRadius: DEFAULT_SEARCH_RADIUS,
  viewType: DEFAULT_RESULT_VIEW_TYPE,
  selectedMapItem: undefined,
  mapLocation: DEFAULT_MAP_LOCATION,
  priceRange: PriceRange.asDefault(),
  maxDisplayableCookingTime: null,
  filterCategoryTagList: Set()
};

class SearchSettingBase extends Record(initialSetting) {
  public static asDefault() {
    return new SearchSettingBase();
  }

  protected constructor(args: any = {}) {
    super(args);
  }

  public getReservationTime() {
    return this.get("reservationTime");
  }

  public setReservationTime(time: ReservationTime) {
    return this.set("reservationTime", time);
  }

  public getSearchPlace() {
    return this.get("searchPlace");
  }

  public setSearchPlace(place: SearchPlace) {
    return this.set("searchPlace", place);
  }

  public getSortType() {
    return this.get("sortType");
  }

  public setSortType(type: ResultSortType) {
    return this.set("sortType", type);
  }

  public getSearchRadius() {
    return this.get("searchRadius");
  }

  public setSearchRadius(radius: SearchRadius) {
    return this.set("searchRadius", radius);
  }

  public getViewType() {
    return this.get("viewType");
  }

  public toggleViewType() {
    const current = this.getViewType();
    const next = current === "list" ? "map" : "list";
    return this.set("viewType", next);
  }

  public getSelectedMapItem() {
    return this.get("selectedMapItem");
  }

  public setSelectedMapItem(item: ShopForSearch | undefined) {
    return this.set("selectedMapItem", item);
  }

  public getMapLocation() {
    return this.get("mapLocation");
  }

  public setMapLocation(loc: ILatLng) {
    return this.set("mapLocation", loc);
  }

  public isDefaultMapLocation() {
    return Geolocation.equalsLatLng(
      this.getMapLocation(),
      DEFAULT_MAP_LOCATION
    );
  }

  public getPriceRange() {
    return this.get("priceRange");
  }

  public setPriceRange(range: PriceRange) {
    return this.set("priceRange", range);
  }

  public getMaxDisplayableCookingTime() {
    return this.get("maxDisplayableCookingTime");
  }

  public setMaxDisplayableCookingTime(time: MaxDisplayableCookingTime) {
    return this.set("maxDisplayableCookingTime", time);
  }

  public getFilterCategoryTagList() {
    return this.get("filterCategoryTagList");
  }

  public setFilterCategoryTagList(list: Set<CategoryTag>) {
    return this.set("filterCategoryTagList", list);
  }

  public getSearchResultDisplayCondition(): SearchResultDisplayCondition {
    return {
      sortType: this.getSortType(),
      priceRange: this.getPriceRange(),
      maxDisplayableCookingTime: this.getMaxDisplayableCookingTime(),
      filterCategoryTagList: this.getFilterCategoryTagList()
    };
  }
}

export class FastestSearchSetting extends SearchSettingBase {
  public static asDefault() {
    return new FastestSearchSetting({
      ...initialSetting
    });
  }
}

export class ReserveSearchSetting extends SearchSettingBase {
  public static asDefault() {
    return new ReserveSearchSetting({
      ...initialSetting,
      reservationTime: ReservationTime.fromDatetimeString(
        EvilDatetime.getFastestReservableDatetime()
      )
    });
  }
  public getInitSetting() {
    return new ReserveSearchSetting({
      ...initialSetting,
      reservationTime: this.getReservationTime(),
      searchPlace: this.getSearchPlace()
    });
  }
}

export type SearchSetting = FastestSearchSetting | ReserveSearchSetting;
