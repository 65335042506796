import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  invert?: boolean;
  color?: string;
}

const SvgVector = (props: IProps) => {
  const { invert, color, ...rest } = props;
  const fill = invert ? "#EF4237" : color ? color : "#fff";
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.11845 8.77504L10.3862 4.50729L11.0933 5.2144L6.11845 10.1893L3.02514 7.09595L3.73225 6.38884L6.11845 8.77504Z"
        fill={fill}
      />
    </svg>
  );
};

const CheckedIconInCircle = (props: IProps) => <SvgVector {...props} />;

export default CheckedIconInCircle;
