import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import BlockParagraph from "../atoms/BlockParagraph";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Paper from "../atoms/Paper";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

const pageBodyStyle: React.CSSProperties = {
  padding: "29px 24px"
};

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps {
  actions: ActionDispatcher;
}

const UserTermsSpecifiedCommercialTransactionLawTemplate = React.memo(
  (props: IProps) => {
    const { actions } = props;

    const handleGoBack = useCallback(() => {
      actions.goBack();
    }, [actions]);

    return (
      <Auth>
        <Page
          header={
            <SubPageHeader
              title="特定商取引法に基づく表示"
              handleClickBack={handleGoBack}
            />
          }
        >
          <PageBody style={pageBodyStyle}>
            <Paper>
              <BlockParagraph>
                特定商取引法に基づく表示
                <br />
                <br />
                販売業者名
                <br />
                menu株式会社/menuに出店する各店舗
                <br />
                <br />
                所在地
                <br />
                東京都新宿区四谷１-22-5
                <br />
                <br />
                連絡先
                <br />
                info@menu.inc
                <br />
                受付時間：月～土　10:00 ～18:00 （日・祝日 12/29～1/3は休）
                <br />
                <br />
                販売価格
                <br />
                商品毎に記載
                <br />
                <br />
                販売価格以外に必要な費用
                <br />
                消費税
                <br />
                <br />
                支払方法及び支払時期
                <br />
                クレジットカードでのお支払い
                <br />
                ご利用頂けるカードは国内発行かつご本人様名義のVISA、MasterCard、JCB、AMEX、Dinersです。
                <br />
                支払時期は各カード会社の引き落とし日です。
                <br />
                <br />
                引渡時期、発送方法
                <br />
                お客様がご注文を確定した後、ご注文の品の調理が完了した時点で各出店者よりmenuを通じて通知いたします。その後直接出店者よりお受け取りください。
                <br />
                <br />
                返品、交換について
                <br />
                各出店者に直接お電話にてお問い合わせください。
                <br />
                商品に欠陥がある場合を除き、返品には応じません。
                <br />
                <br />
                購入申し込み後のキャンセル、途中での解約について
                <br />
                利用規約の定めに則り売買契約が成立前の当社が指定する期間中に限り、当社所定の方法で、購入申し込みの取り消しをすることができます。当該期間の経過後は、お客様都合による購入申し込みの取り消し、解約はできません。ただし、当社の責めに帰すべき事由による場合は除きます。
                <br />
              </BlockParagraph>
            </Paper>
          </PageBody>
        </Page>
      </Auth>
    );
  }
);

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTermsSpecifiedCommercialTransactionLawTemplate);
