import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}
const ratio = 20 / 21;

const ReceiptIcon: React.FC<IProps> = ({
  size = 21,
  color = "#000",
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size * ratio}
      viewBox="0 0 21 20"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.549.7a.7.7 0 0 1 .7-.7h17.155a.7.7 0 0 1 .7.7v.856h-17v17H1.25a.7.7 0 0 1-.7-.7V.7zM4.17 3.622V18.6H19.15V3.622H4.17zm-.7-1.4a.7.7 0 0 0-.7.7V19.3a.7.7 0 0 0 .7.7h16.378a.7.7 0 0 0 .7-.7V2.922a.7.7 0 0 0-.7-.7H3.47zM7.215 5.89a.778.778 0 1 0 0 1.555h8.89a.778.778 0 0 0 0-1.555h-8.89zm-.777 5.22c0-.429.348-.777.777-.777h8.89a.778.778 0 0 1 0 1.556h-8.89a.778.778 0 0 1-.777-.778zm.777 3.669a.778.778 0 1 0 0 1.555h4.445a.778.778 0 1 0 0-1.555H7.215z"
        fill={color}
      />
    </svg>
  );
};

export default ReceiptIcon;
