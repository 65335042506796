import { ColorProperty } from "csstype";
import React from "react";

type IProps = { active?: boolean } & {};

const navigationIcon = (
  Fc: React.FC<{ color?: ColorProperty } & {}>
): React.FC<IProps> => ({ active }) => {
  const color = active ? "#FF0000" : "#000000";
  return <Fc color={color} />;
};

export default navigationIcon;
