import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IResetPasswordForm {
  newPassword: string;
  newPasswordConfirm: string;
}

export const initialResetPasswordForm: IResetPasswordForm = {
  newPassword: "",
  newPasswordConfirm: ""
};

export function validateResetPasswordForm(values: IResetPasswordForm) {
  const errors: FormErrors<IResetPasswordForm> = {};
  if (!values.newPassword) {
    errors.newPassword = "新しいパスワードを入力して下さい";
  } else if (!UserModel.validPassword(values.newPassword)) {
    errors.newPassword = "半角英数記号のみ使用可能です";
  } else if (values.newPassword.length < 8) {
    errors.newPassword = "8文字以上である必要があります";
  }
  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = "確認の為、新しいパスワードを再度入力ください";
  } else if (values.newPassword !== values.newPasswordConfirm) {
    errors.newPasswordConfirm = "直前に入力されたパスワードと異なります";
  }
  return errors;
}
