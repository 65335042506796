import React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import Alert, { AlertType } from "../../records/Alert";
import Button from "../atoms/Button";
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from "../atoms/Dialog";
import ErrorIcon from "../atoms/ErrorIcon";
import Notes from "../atoms/Notes";
import SubmittedOrderPicture from "../atoms/SubmittedOrderPicture";
import Modal from "../molecules/Modal";

const PictureContainer = styled.div`
  padding: 50px 0 40px;
  text-align: center;
  line-height: 1;
`;

const dialogStyle: React.CSSProperties = {
  margin: "0 20px"
};

interface IProps {
  alert: Alert;
  handleClick: (id: string) => void;
}

const CommonAlert: React.FC<IProps> = React.memo(props => {
  const { alert } = props;

  const handleClick = useCallback(() => {
    props.handleClick(alert.getId());
  }, [props.handleClick, alert]);

  const message = alert.getMessage();

  return (
    <Modal isOpen={true} handleClickBackdrop={handleClick}>
      <Dialog style={dialogStyle}>
        {alert.hasTitle() && <DialogHeader>{alert.getTitle()}</DialogHeader>}
        <DialogBody>
          {typeof message !== "undefined" && (
            <>
              {message.split("\n").map((line, index) => (
                <Notes key={index}>{line}</Notes>
              ))}
            </>
          )}
          {alert.isError() && (
            <PictureContainer>
              <ErrorIcon />
            </PictureContainer>
          )}
          {alert.isSubmittedOrder() && (
            <PictureContainer>
              <SubmittedOrderPicture />
            </PictureContainer>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            block
            secondary
            onClick={handleClick}
            transparent={alert.getType() === AlertType.WarningOverFavorite}
          >
            {alert.getButtonLabel()}
          </Button>
        </DialogFooter>
      </Dialog>
    </Modal>
  );
});

export default CommonAlert;
