import React, { useCallback, useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  PropTypes,
  Suggestion
} from "react-places-autocomplete";
import scriptjs from "scriptjs";
import styled from "styled-components";
import { ILatLng } from "../../../modules/search/model";
import LabeledPlace from "../../../records/LabeledPlace";
import colorsConst from "../../../styles/const/colorsConst";
import Utility, {
  fixBodyScrollTopWhenInputBlurred
} from "../../../util/Utility";
import MapPinIconAlter from "../../atoms/MapPinIconAlter";
import Loading from "../../molecules/Loading";
import SuggestionItem from "./SuggestionItem";

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuggestionContainer = styled.div`
  margin-top: 8px;
  background-color: ${colorsConst.BACKGROUND};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  background-color: ${colorsConst.BACKGROUND};
`;

const Input = styled.input`
  flex: 1 1 auto;
  caret-color: ${colorsConst.MAIN};
  font-size: 1.6rem;
`;

const Icon = styled.div`
  flex: 0 0 auto;
  font-size: 2rem;
  padding-right: 16px;
`;

const ZeroResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
`;

type ScriptLoadingState = "NONE" | "BEGIN" | "LOADED";

const searchOptions: PropTypes["searchOptions"] = {
  componentRestrictions: { country: "jp" }
};

interface IProps {
  defaultValue?: LabeledPlace;
  handleSubmit: (address: string, latLng: ILatLng) => void;
  openErrorDialog: () => void;
  clearInputAfterSubmit?: boolean;
}

const InputSuggestionPlace: React.FC<IProps> = React.memo(
  ({ defaultValue, handleSubmit, openErrorDialog, clearInputAfterSubmit }) => {
    const [loadingState, setLoadingState] = useState<ScriptLoadingState>(
      "NONE"
    );

    const [fetchingGeometry, setFetchingGeometryFlag] = useState(false);

    /**
     * refObject to hold the latest suggest result
     */
    const prevSuggestions = useRef<readonly Suggestion[]>([]);

    const [errorStatus, setErrorStatus] = useState("");

    /**
     * text being editing
     */
    const [draftAddress, setDraftAddress] = useState(
      typeof defaultValue !== "undefined" ? defaultValue.getAddress() : ""
    );

    /**
     * selected address string
     * get latlng via geocode api based on this string
     */
    const [submitAddress, setSubmitAddress] = useState<string | undefined>();
    const prevSubmitAddress = useRef<string | undefined>();

    const onChange = useCallback((value: string) => {
      setDraftAddress(value);
    }, []);

    const onSelect = useCallback((address: string, _: string) => {
      if (address.trim() === "") {
        return;
      }
      setSubmitAddress(address);
    }, []);

    const handleClickSuggestion = useCallback((suggestion: Suggestion) => {
      setDraftAddress(suggestion.formattedSuggestion.mainText);
    }, []);

    const onError = useCallback(
      (status: string, clearSuggestions: () => void) => {
        setErrorStatus(status);
        clearSuggestions();
      },
      []
    );

    const handleSubmitWrap = useCallback(
      (address: string, latLng: ILatLng) => {
        // sumbmit by default if exists defaultValue and if equals address of defaultValue
        if (
          typeof defaultValue !== "undefined" &&
          address === defaultValue.getAddress()
        ) {
          handleSubmit(defaultValue.getAddress(), defaultValue.getLocation());
        } else {
          handleSubmit(address, latLng);
        }
        if (
          typeof clearInputAfterSubmit !== "undefined" &&
          clearInputAfterSubmit
        ) {
          setDraftAddress("");
        }
      },
      [clearInputAfterSubmit, defaultValue, handleSubmit]
    );

    useEffect(() => {
      setErrorStatus("");
    }, [draftAddress]);

    // load google maps api script
    useEffect(() => {
      let unmounted = false;

      const handleLoaded = () => {
        if (unmounted) {
          return;
        }
        setLoadingState("LOADED");
      };

      if (loadingState === "NONE") {
        setLoadingState("BEGIN");

        const scriptUrl = Utility.getGoogleMapsApiUrl();
        scriptjs(scriptUrl, handleLoaded);
      }

      return () => {
        unmounted = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // fetch geometry
    useEffect(() => {
      let canceled = false;

      const fetchLatLng = async (address: string) => {
        try {
          setFetchingGeometryFlag(true);

          const result = await geocodeByAddress(address);
          if (canceled) {
            return;
          }

          const latLng = await getLatLng(result[0]);
          if (canceled) {
            return;
          }

          const targetSuggestion = prevSuggestions.current.find(
            sugg => sugg.description === address
          );
          if (typeof targetSuggestion !== "undefined") {
            handleSubmitWrap(
              targetSuggestion.formattedSuggestion.mainText,
              latLng
            );
          } else if (typeof submitAddress !== "undefined") {
            handleSubmitWrap(submitAddress, latLng);
          }
          // TODO: submit by draftAddress if not exists targetSuggestion
        } catch (e) {
          if (!canceled && e !== "ZERO_RESULTS") {
            openErrorDialog();
          }
        } finally {
          if (!canceled) {
            setFetchingGeometryFlag(false);
          }
        }
      };

      if (
        typeof submitAddress !== "undefined" &&
        prevSubmitAddress.current !== submitAddress
      ) {
        fetchLatLng(submitAddress);
      } else {
        setFetchingGeometryFlag(false);
      }

      return () => {
        canceled = true;
      };
    }, [submitAddress, handleSubmitWrap, openErrorDialog]);

    useEffect(() => {
      prevSubmitAddress.current = submitAddress;
    }, [submitAddress]);

    return loadingState !== "LOADED" ? (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    ) : (
      <PlacesAutocomplete
        value={draftAddress}
        onChange={onChange}
        onSelect={onSelect}
        onError={onError}
        debounce={800}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          // save latest suggestion if exists results
          if (suggestions.length !== 0) {
            prevSuggestions.current = suggestions;
          }

          const isZeroResult = errorStatus === "ZERO_RESULTS";

          return (
            <>
              <form action="/">
                <Container>
                  <Icon>
                    <MapPinIconAlter />
                  </Icon>
                  <Input
                    {...getInputProps({
                      type: "search",
                      placeholder: "エリア・駅名・住所を入力",
                      onBlur: fixBodyScrollTopWhenInputBlurred
                    })}
                  />
                  {loading || fetchingGeometry ? <Loading size={6} /> : null}
                </Container>
              </form>
              <SuggestionContainer>
                {loading || fetchingGeometry ? null : isZeroResult ? (
                  <ZeroResult>該当する住所が見付かりません</ZeroResult>
                ) : (
                  <ul>
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion)}
                        style={{
                          borderTop: index !== 0 ? "1px solid #f3f3f5" : "none"
                        }}
                      >
                        <SuggestionItem
                          suggestion={suggestion}
                          onClick={handleClickSuggestion}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </SuggestionContainer>
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
);

export default InputSuggestionPlace;
