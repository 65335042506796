import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavigationPath } from "../../../modules/app/model";
import Grid from "../../atoms/Grid";

const Container = styled(Grid).attrs({
  item: true,
  rate: 0
})<{ itemWidth: string; disabled?: boolean }>`
  position: relative;
  text-align: center;
  flex-basis: ${p => p.itemWidth};
`;

const linkStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "55px"
};

const IconWrapper = styled.div``;

const NavLabel = styled.div<{ active: boolean }>`
  font-size: 10px;
  font-weight: bold;
  padding-top: 5px;
  color: ${p => (p.active ? "#ff0000" : "#000")};
`;

interface IProps {
  active: boolean;
  icon: React.FC<{ active?: boolean } & {}>;
  link: NavigationPath;
  label: string;
  itemWidth: string;
  handleClick: (path: NavigationPath) => void;
}

const NavigationItem: React.FC<IProps> = React.memo(props => {
  const { icon: Icon, active, link, label, itemWidth, handleClick } = props;

  const locationDiscriptor = useMemo(
    () => ({
      pathname: link,
      state: { routerActionType: { [`${"PUSH"}${link}`]: "NONE" } }
    }),
    [link]
  );

  const handleClickWrap = useCallback(() => {
    handleClick(link);
  }, [handleClick, link]);

  return (
    <Container itemWidth={itemWidth} onClick={handleClickWrap}>
      <Link to={locationDiscriptor} style={linkStyle}>
        <IconWrapper>
          <Icon active={active} />
        </IconWrapper>
        <NavLabel active={active}>{label}</NavLabel>
      </Link>
    </Container>
  );
});

export default NavigationItem;
