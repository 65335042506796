import { goBack } from "connected-react-router";
import { fork, put } from "redux-saga/effects";
import { UserSubmittedSearchSettingsAction } from "../actions";
import getSearchResultSaga from "./getSearchResultSaga";

export default function* userSubmittedSearchSettingsSaga(
  action: UserSubmittedSearchSettingsAction
) {
  const { searchResultType, searchPlace, reservationTime } = action.payload;
  yield put(goBack());
  yield fork(() =>
    getSearchResultSaga(searchResultType, searchPlace, reservationTime)
  );
}
