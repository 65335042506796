import React, { useCallback } from "react";
import { WrappedFieldProps } from "redux-form";
import ErrorText from "../../../components/atoms/ErrorText";
import Grid from "../../../components/atoms/Grid";
import LabelText from "../../../components/atoms/LabelText";
import TextInput from "../../../components/atoms/TextInput";
import TooltipIcon from "../../atoms/TooltipIcon";
import { fixBodyScrollTopWhenInputBlurred } from "../../../util/Utility";

const InputContainerStyle: React.CSSProperties = {
  height: "46px",
  background: "#ffffff",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
  padding: "4px 8px"
};

const outerInputStyle: React.CSSProperties = {
  padding: "0",
  border: "none"
};

const InputStyle: React.CSSProperties = {
  fontSize: "20px"
};

const tooltipIconStyle: React.CSSProperties = {
  padding: "0 3px 0 13px"
};

interface IProps extends WrappedFieldProps {
  handleTouchedTooltip: () => void;
}

const SecureCodeField = (field: IProps) => {
  const { input, meta, handleTouchedTooltip, ...rest } = field;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      input.onBlur(event.currentTarget.value);
      fixBodyScrollTopWhenInputBlurred();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input.onBlur]
  );

  return (
    <div>
      <LabelText htmlFor="inputSecureCode">セキュリティコード</LabelText>
      <Grid
        style={InputContainerStyle}
        container
        align="center"
        justify="space-between"
      >
        <Grid item rate={1}>
          <TextInput
            {...input}
            type="number"
            name="secureCode"
            id="inputSecureCode"
            placeholder="123"
            maxLength={4}
            autoComplete="cc-csc"
            onBlur={handleBlur}
            outerStyle={outerInputStyle}
            style={InputStyle}
            {...rest}
          />
        </Grid>
        <Grid item rate={0}>
          <TooltipIcon
            style={tooltipIconStyle}
            onClick={handleTouchedTooltip}
          />
        </Grid>
      </Grid>
      <ErrorText>
        {meta.touched && meta.error && <div>※{meta.error}</div>}
      </ErrorText>
    </div>
  );
};

export default SecureCodeField;
