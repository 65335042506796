import { goBack } from "connected-react-router";
import { fork, join, put, select } from "redux-saga/effects";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { AlertType } from "../../../records/Alert";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import { UserSubmittedSearchSettingsWithoutSearchAction } from "../actions";
import updateSearchSettingSaga from "./updateSearchSettingSaga";
import { ILatLng } from "../model";
import fetchCurrentLocationSaga from "./fetchCurrentLocationSaga";

export default function* userSubmittedSearchSettingsWithoutSearchSaga(
  action: UserSubmittedSearchSettingsWithoutSearchAction
) {
  try {
    const { searchResultType, searchPlace, reservationTime } = action.payload;

    const searchSetting: SearchSetting = yield select<
      (state: ReduxModel) => SearchSetting
    >(state => state.search.getSearchSettings().getData(searchResultType));

    let needsCurrentLocation = false;
    if (typeof searchPlace !== "undefined" && searchPlace.isCurrentLocation()) {
      needsCurrentLocation = true;
    } else {
      const currentSearchPlace = yield select<
        (state: ReduxModel) => SearchPlace
      >(state =>
        state.search
          .getSearchSettings()
          .getData(searchResultType)
          .getSearchPlace()
      );
      if (currentSearchPlace.isCurrentLocation()) {
        needsCurrentLocation = true;
      }
    }

    if (needsCurrentLocation) {
      const fetchCurrentLocationTask = yield fork(() =>
        fetchCurrentLocationSaga()
      );
      yield join(fetchCurrentLocationTask);
    }

    const currentLocation: ILatLng | null = yield select<
      (state: ReduxModel) => ILatLng | null
    >(state => state.search.getCurrentLocation());

    const searchLocation = (typeof searchPlace === "undefined"
      ? searchSetting.getSearchPlace()
      : searchPlace
    ).getSearchLocationWithCurrentLocation(currentLocation);

    if (searchLocation === null) {
      throw new Error("location is null");
    }

    yield fork(() =>
      updateSearchSettingSaga(
        searchResultType,
        searchLocation,
        searchSetting,
        searchPlace,
        reservationTime
      )
    );

    yield put(goBack());
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `検索条件の変更に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
