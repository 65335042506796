import { goBack, replace } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, fork, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { IAuthSmsRequest, IAuthSmsResponse } from "../../../apis/authSms";
import { EFormID } from "../../../forms";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateUser,
  userLoggedIn,
  UserSubmittedRegisterAuthAtFirstAccessAction,
  UserSubmittedRegisterAuthAction
} from "../actions/index";
import UserModel from "../model";
import getUserDataSaga from "./getUserDataSaga";
import { fetchInputDiscountCodeSaga } from "./userSubmittedDiscountCodeSaga";
import DiscountCode, {
  EDiscountCodeResultState
} from "../../../records/DiscountCode";
import TempStringMemory from "../../../util/TempStringMemory";

export function* registerAuthSaga(action: UserSubmittedRegisterAuthAction) {
  const fetchingAuthTask = yield fork(() =>
    fetchingAuthSaga(action.payload.authCode)
  );
  yield join(fetchingAuthTask);
  if (fetchingAuthTask.isCancelled()) {
    yield cancel();
  }
  const discount_code = TempStringMemory.load("DISCOUNT_CODE");
  if (typeof discount_code !== "undefined") {
    const fetchingDiscountCodeTask = yield fork(() =>
      fetchInputDiscountCodeSaga(discount_code)
    );
    const fetchingDiscountCodeResult: EDiscountCodeResultState = yield join(
      fetchingDiscountCodeTask
    );
    if (fetchingDiscountCodeTask.isCancelled()) {
      yield cancel();
    }
    if (DiscountCode.isSuccessState(fetchingDiscountCodeResult)) {
      TempStringMemory.delete("DISCOUNT_CODE");
      yield put(goBack());
    }
  } else {
    yield put(goBack());
  }
}

export function* registerAuthAtFirstAccessSaga(
  action: UserSubmittedRegisterAuthAtFirstAccessAction
) {
  const fetchingAuthTask = yield fork(() =>
    fetchingAuthSaga(action.payload.authCode)
  );
  yield join(fetchingAuthTask);
  if (fetchingAuthTask.isCancelled()) {
    yield cancel();
  }
  const discount_code = TempStringMemory.load("DISCOUNT_CODE");
  if (typeof discount_code !== "undefined") {
    const fetchingDiscountCodeTask = yield fork(() =>
      fetchInputDiscountCodeSaga(discount_code)
    );
    const fetchingDiscountCodeResult: EDiscountCodeResultState = yield join(
      fetchingDiscountCodeTask
    );
    if (fetchingDiscountCodeTask.isCancelled()) {
      yield cancel();
    }
    if (DiscountCode.isSuccessState(fetchingDiscountCodeResult)) {
      TempStringMemory.delete("DISCOUNT_CODE");
      yield put(userLoggedIn());
      yield put(replace("/"));
    }
  }
}

function* fetchingAuthSaga(authCode: string) {
  try {
    const user: UserModel = yield select<(state: ReduxModel) => UserModel>(
      state => state.user
    );
    const params: IAuthSmsRequest = {
      tel: user.getData().getTel(),
      auth_code: authCode
    };
    const commonApiTask = yield spawn(commonApiSaga, EApiKey.AUTH_SMS, params);
    const {
      result,
      error
    }: { result: IAuthSmsResponse; error: unknown } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(destroy(EFormID.AUTH_SMS));
      const getUserDataTask = yield fork(() => getUserDataSaga());
      const userData = yield join(getUserDataTask);
      yield put(updateUser(userData));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
    yield cancel();
  }
}
