import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import Price from "../../records/Price";
import ShopItem from "../../records/ShopItem";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";

const Container = styled.div`
  padding: 4px;
  padding-bottom: calc(4px + constant(safe-area-inset-bottom));
  padding-bottom: calc(4px + env(safe-area-inset-bottom));
  font-size: 17px;
  font-weight: bold;
  color: #5b5b5b;
  background: #000000;
`;

const ButtonContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  background: #000000;
`;

const buttonStyle: React.CSSProperties = {
  maxWidth: "212px",
  width: "60%",
  padding: "13px 20px",
  marginLeft: "auto",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "20px"
};

const disabledButtonStyle: React.CSSProperties = {
  padding: "13px 20px",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "20px"
};

const PriceContainer = styled.div`
  padding-left: 8px;
  font-weight: bold;
  font-size: 5.7vw;
  line-height: 41px;
  letter-spacing: 0.04em;
  color: #ffffff;
`;

interface IProps {
  shopId: number;
  item: ShopItem | undefined;
  options: List<number>;
  quantity: number;
  notes: string;
  handleSubmit: (quantity: number, options: number[], notes: string) => void;
  stateText?: string;
  submitButtonLabel: string;
  connectingAddOrUpdateItem?: boolean;
  connectingDeleteFromCart?: boolean;
  disabled?: boolean;
  children?: never;
}

const ShopItemViewerFooter: React.FC<IProps> = React.memo(props => {
  const {
    item,
    options,
    quantity,
    notes,
    handleSubmit,
    stateText,
    submitButtonLabel,
    connectingAddOrUpdateItem,
    connectingDeleteFromCart,
    disabled
  } = props;

  /**
   * 送信処理
   */
  const handleSubmitWrap = useCallback(() => {
    handleSubmit(quantity, options.toArray(), notes);
  }, [handleSubmit, quantity, options, notes]);

  /**
   * 選択したオプション分の価格の合計を取得する
   */
  const additionalPrice = useMemo(() => {
    return options.toArray().reduce((acc: number, crr: number) => {
      const option =
        typeof item !== "undefined" ? item.findOptionById(crr) : null;
      return option !== null ? acc + option.getPrice() : acc;
    }, 0);
  }, [options, item]);

  /**
   * オプションや個数も加味した価格の合計を取得する
   */
  const getTotalPrice = useCallback(
    (shopItem: ShopItem | undefined) => {
      return typeof shopItem !== "undefined"
        ? (shopItem.getPrice() + additionalPrice) * quantity
        : 0;
    },
    [quantity, additionalPrice]
  );

  return (
    <Container>
      <ButtonContainer>
        {disabled ? (
          <Button
            block
            disabled
            grayout={item ? item.isSoldOut() : true}
            style={disabledButtonStyle}
          >
            {stateText}
          </Button>
        ) : (
          <>
            <PriceContainer>
              {Price.getPresentationValue(getTotalPrice(item))}
            </PriceContainer>
            <Button
              block
              loading={connectingAddOrUpdateItem}
              disabled={connectingDeleteFromCart}
              onClick={handleSubmitWrap}
              style={buttonStyle}
            >
              {submitButtonLabel}
            </Button>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
});

export default ShopItemViewerFooter;
