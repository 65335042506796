import { replace } from "connected-react-router";
import { call, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { ReduxModel } from "../../../reducer";
import CordovaUtil from "../../../util/CordovaUtil";
import { updateLoginToken } from "../../user/actions";
import UserModel from "../../user/model";
import { loginByTokenSaga } from "../../user/sagas/loginByTokenSaga";
import { launch, setAppVersion, systemReadyForUserOperation } from "../actions";
import commonApiSaga from "./commonApiSaga";

export default function* systemLaunchedSaga() {
  try {
    yield put(launch());
    const appVersion: string | null = yield call(CordovaUtil.getAppVersion);
    yield put(setAppVersion(appVersion));

    const launchApiTask = yield fork(() => commonApiSaga(EApiKey.LAUNCH, {}));
    yield join(launchApiTask);

    const user = yield select<(state: ReduxModel) => UserModel>(
      state => state.user
    );
    const token = user.getLoginToken();
    if (token !== null) {
      yield put(updateLoginToken(token));
      const loginByTokenTask = yield fork(() => loginByTokenSaga());
      yield join(loginByTokenTask);
      yield put(systemReadyForUserOperation());
    } else {
      yield put(replace("/top"));
      yield put(systemReadyForUserOperation());
    }
  } catch (exception) {
    console.log({ exception });
  }
}
