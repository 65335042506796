import {
  HandleOnCardIOCancel,
  HandleOnCardIOSuccess,
  ICardIO,
  ICardIOOption
} from "../CardIO";

declare var CardIO: ICardIO;

export default class CreditCardReader {
  public static scan(
    option: ICardIOOption,
    onSuccess: HandleOnCardIOSuccess,
    onCancel: HandleOnCardIOCancel
  ) {
    if (!CreditCardReader.isEnabled()) {
      return;
    }
    return CardIO.scan(option, onSuccess, onCancel);
  }

  private static isEnabled() {
    return "CardIO" in window;
  }
}
