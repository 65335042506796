import * as React from "react";
import { ColorProperty } from "csstype";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const PencilIcon: React.FC<IProps> = ({
  size = 13,
  color = "#fff",
  ...rest
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 13" fill="none" {...rest}>
      <rect
        x="1.7998"
        y="8.64404"
        width="11.0456"
        height="3.5067"
        transform="rotate(-48.9933 1.7998 8.64404)"
        fill={color}
      />
      <path
        d="M1.0712 9.42744L0.677734 12.6069L3.77164 11.7754L1.0712 9.42744Z"
        fill={color}
      />
    </svg>
  );
};

export default PencilIcon;
