import { triangle } from "polished";
import React from "react";
import styled from "styled-components";
import { preventMapHitsFrom } from "../../util/GoogleMapsUtil";
import CircleThumbnail from "../molecules/CircleThumbnail";

const shadowStyle: React.CSSProperties = {};

const triangleStyle = triangle({
  width: "12px",
  height: "8px",
  pointingDirection: "bottom",
  foregroundColor: "#ffffff"
});

const Container = styled.div<{ beak?: boolean }>`
  position: relative;
  border-radius: 100%;
  background-color: #ffffff;
  padding: 5px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
  &:after {
    position: absolute;
    left: calc(50% - 6px);
    bottom: -6px;
    ${p =>
      typeof p.beak !== "undefined" ? (p.beak ? "" : "display: none;") : ""}
    ${triangleStyle}
    content: "";
  }
`;

interface IProps {
  src: string;
  handleClick?: () => void;
  radius?: number;
  beak?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ShopImageMarkerContent: React.FC<IProps> = React.memo(props => {
  const { src, handleClick, radius, beak, disabled, ...rest } = props;
  const circleRadius = typeof radius !== "undefined" ? radius : 30;
  return (
    <Container
      onClick={handleClick}
      beak={beak}
      {...rest}
      ref={ref => preventMapHitsFrom<HTMLDivElement>(ref)}
    >
      <CircleThumbnail
        src={src}
        radius={circleRadius}
        style={shadowStyle}
        disabled={disabled}
      />
    </Container>
  );
});

export default ShopImageMarkerContent;
