import { IAppAvailability } from "../appAvailability";

declare var appAvailability: IAppAvailability;

export default class AppAvailability {
  public static isPluginEnabled() {
    return "appAvailability" in window && "cordova" in window;
  }

  public static check(app: string) {
    if (!AppAvailability.isPluginEnabled()) {
      return Promise.resolve(false);
    }
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        appAvailability.check(app, () => resolve(true), () => resolve(false));
      } catch (exception) {
        reject(exception);
      }
    });
  }
}
