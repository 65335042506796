import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMapL = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 17 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.929 1.133c-3.803 0-6.857 2.876-6.857 6.39 0 1.077.42 2.321 1.099 3.615.675 1.285 1.581 2.573 2.497 3.726a41.804 41.804 0 0 0 3.26 3.624 41.831 41.831 0 0 0 3.26-3.625c.917-1.152 1.824-2.44 2.499-3.725.679-1.294 1.099-2.538 1.099-3.614 0-3.515-3.054-6.39-6.857-6.39zm0 18.135l-.399.394-.001-.002-.005-.004-.017-.016-.065-.061a40.457 40.457 0 0 1-1.094-1.097 42.91 42.91 0 0 1-2.587-2.95C3.82 14.347 2.869 13 2.15 11.632 1.437 10.274.929 8.85.929 7.523c0-4.15 3.597-7.489 8-7.489 4.402 0 8 3.338 8 7.49 0 1.325-.509 2.75-1.222 4.11-.718 1.367-1.67 2.714-2.61 3.897a42.893 42.893 0 0 1-3.682 4.048l-.065.061-.017.016-.004.004-.002.002-.398-.394zm0 0l.398.394-.398.372-.398-.372.398-.394z"
      fill="#272727"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.929 9.815c1.262 0 2.285-.984 2.285-2.198S10.191 5.418 8.93 5.418c-1.263 0-2.286.985-2.286 2.199s1.023 2.198 2.286 2.198zm0 1.099c1.893 0 3.428-1.476 3.428-3.297 0-1.821-1.535-3.298-3.428-3.298C7.035 4.32 5.5 5.796 5.5 7.617c0 1.82 1.535 3.297 3.429 3.297z"
      fill="#272727"
    />
  </svg>
);

const MapPinIconAlter: React.FC<IProps> = props => <SvgMapL {...props} />;

export default MapPinIconAlter;
