import { ReducerActions } from "./reducer";
import { UserAndSystemActions } from "./userAndSystem";

export enum ActionTypes {
  U_ACCESSED_TO_CART = "order/U_ACCESSED_TO_CART",
  U_SUBMITTED_ADD_TO_CART = "order/U_SUBMITTED_ADD_TO_CART",
  U_SUBMITTED_UPDATE_CART = "order/U_SUBMITTED_UPDATE_CART",
  U_TOUCHED_DELETE_CART_ITEM_BUTTON = "order/U_TOUCHED_DELETE_CART_ITEM_BUTTON",
  U_SUBMITTED_ORDER = "order/U_SUBMITTED_ORDER",
  U_SUBMITTED_CANCEL_ORDER = "order/U_SUBMITTED_CANCEL_ORDER",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY = "order/U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL = "order/U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL",
  U_TOUCHED_REQUEST_CLEAR_CART_BUTTON = "order/U_TOUCHED_REQUEST_CLEAR_CART_BUTTON",
  U_ACCESSED_TO_PRE_ORDER_PAGE = "order/U_ACCESSED_TO_PRE_ORDER_PAGE",
  U_CHANGED_PRE_ORDER_OPTION_LIST = "order/U_CHANGED_PRE_ORDER_OPTION_LIST",
  U_CHANGED_PRE_ORDER_COUNT = "order/U_CHANGED_PRE_ORDER_COUNT",
  U_CHANGED_PRE_ORDER_TEXT = "order/U_CHANGED_PRE_ORDER_TEXT",
  U_CHANGED_DRAFT_FEEDBACK_TEXT = "order/U_CHANGED_DRAFT_FEEDBACK_TEXT",
  U_CHANGED_DRAFT_FEEDBACK_POINT = "order/U_CHANGED_DRAFT_FEEDBACK_POINT",
  U_SUBMITTED_FEEDBACK = "order/U_SUBMITTED_FEEDBACK",
  U_TOUCHED_NOTIFY_ARRIVAL_FOR_LUXURY_SHOP = "order/U_TOUCHED_NOTIFY_ARRIVAL_FOR_LUXURY_SHOP",
  U_TOUCHED_OPEN_DIALOG_WITH_ORDER_NUMBER = "order/U_TOUCHED_OPEN_DIALOG_WITH_ORDER_NUMBER",
  U_TOUCHED_CLOSE_DIALOG_WITH_ORDER_NUMBER = "order/U_TOUCHED_CLOSE_DIALOG_WITH_ORDER_NUMBER",
  U_TOUCHED_SEND_RECEIPT_MAIL = "order/U_TOUCHED_SEND_RECEIPT_MAIL",
  U_CHANGED_ORDER_ITEM_QUANTITY = "order/U_CHANGED_ORDER_ITEM_QUANTITY",
  U_TOUCHED_OPEN_BUSY_ORDER_DIALOG_BUTTON = "order/U_TOUCHED_OPEN_BUSY_ORDER_DIALOG_BUTTON",
  U_SUBMITTED_ADD_TO_CART_BEFORE_REGISTER = "order/U_SUBMITTED_ADD_TO_CART_BEFORE_REGISTER",
  U_CHANGED_CART_RESERVATION_TIME = "order/U_CHANGED_CART_RESERVATION_TIME",
  U_TOUCHED_OPEN_SETTING_RESERVATION_DIALOG_BUTTON = "order/U_TOUCHED_OPEN_SETTING_RESERVATION_DIALOG_BUTTON",
  U_TOUCHED_OPEN_SELECT_OPTION_DIALOG_BUTTON = "order/U_TOUCHED_OPEN_SELECT_OPTION_DIALOG_BUTTON",
  U_CHANGED_CART_SELECT_OPTION = "order/U_CHANGED_CART_SELECT_OPTION",
  U_CHANGED_CLEAR_CART_SELECT_OPTION = "order/U_CHANGED_CLEAR_CART_SELECT_OPTION",
  U_CLOSE_SELECT_OPTION_DIALOG = "order/U_CLOSE_SELECT_OPTION_DIALOG",
  U_TOUCHED_ORDER_BUTTON = "order/U_TOUCHED_ORDER_BUTTON",
  U_CONFIRMED_ORDER = "order/U_CONFIRMED_ORDER",
  U_SELECTED_PAYMENT_TYPE = "order/U_SELECTED_PAYMENT_TYPE",

  S_STARTED_POLLING_ORDER_DETAIL = "order/S_STARTED_POLLING_ORDER_DETAIL",
  S_STOPPED_POLLING_ORDER_DETAIL = "order/S_STOPPED_POLLING_ORDER_DETAIL",
  S_FAILED_POLLING_ORDER_DETAIL = "order/S_FAILED_POLLING_ORDER_DETAIL",
  S_REQUESTED_FETCH_CART_LIST = "order/S_REQUESTED_FETCH_CART_LIST",

  R_UPDATE_FEEDBACK_STATE = "order/R_UPDATE_FEEDBACK_STATE",
  R_UPDATE_FEEDBACK_POINT = "order/R_UPDATE_FEEDBACK_POINT",
  R_UPDATE_FEEDBACK_TEXT = "order/R_UPDATE_FEEDBACK_TEXT",
  R_UPDATE_DRAFT_FEEDBACK_POINT = "order/R_UPDATE_DRAFT_FEEDBACK_POINT",
  R_UPDATE_DRAFT_FEEDBACK_TEXT = "order/R_UPDATE_DRAFT_FEEDBACK_TEXT",
  R_UPDATE_CART = "order/R_UPDATE_CART",
  R_CHANGE_TIME_TYPE = "order/R_CHANGE_TIME_TYPE",
  R_SET_RECEIVE_TIME = "order/R_SET_RECEIVE_TIME",
  R_SET_RECEIVE_DATE = "order/R_SET_RECEIVE_DATE",
  R_UPDATE_ORDER_LIST = "order/R_UPDATE_ORDER_LIST",
  R_UPDATE_CURRENT_ORDER_DETAIL = "order/R_UPDATE_CURRENT_ORDER_DETAIL",
  R_INIT_PRE_ORDER = "order/R_INIT_PRE_ORDER",
  R_UPDATE_PRE_ORDER_OPTION_LIST = "order/R_UPDATE_PRE_ORDER_OPTION_LIST",
  R_UPDATE_PRE_ORDER_COUNT = "order/R_UPDATE_PRE_ORDER_COUNT",
  R_UPDATE_PRE_ORDER_TEXT = "order/R_UPDATE_PRE_ORDER_TEXT",
  R_UPDATE_ORDER_NUMBER_FOR_DIALOG = "order/R_UPDATE_ORDER_NUMBER_FOR_DIALOG",
  R_UPDATE_ORDER_CART_ID = "order/R_UPDATE_ORDER_CART_ID",
  R_UPDATE_RESERVATION_TIME_FOR_DIALOG = "order/R_UPDATE_RESERVATION_FOR_DIALOG",
  R_UPDATE_CART_ID_FOR_DIALOG = "order/R_UPDATE_CART_ID_FOR_DIALOG",
  R_UPDATE_ITEM_INDEX_FOR_DIALOG = "order/R_UPDATE_ITEM_INDEX_FOR_DIALOG",
  R_UPDATE_SHOP_ITEM_FAVORITE_STATE = "order/R_UPDATE_SHOP_ITEM_FAVORITE_STATE",
  R_UPDATE_PRE_ORDER_CART_ID = "order/R_UPDATE_PRE_ORDER_CART_ID",
  R_UPDATE_PAYMENT_TYPE = "order/R_UPDATE_PAYMENT_TYPE"
}

export type OrderActions = UserAndSystemActions | ReducerActions;
export * from "./userAndSystem";
export * from "./reducer";
