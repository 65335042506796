import React from "react";
import styled from "styled-components";
import Grid from "../atoms/Grid";

const Container = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  color: #4e4e4e;
`;

const PromotionCodeKey = styled(Grid).attrs({ item: true })``;
const PromotionCodeValue = styled(Grid).attrs({ item: true, rate: 1 })`
  text-align: right;
`;

interface IProps {
  name?: string;
  code: string;
}

const PromotionCodeInfo: React.FC<IProps> = React.memo(props => {
  const { name, code } = props;
  return (
    <Container>
      <PromotionCodeKey>
        {name ? name : "プロモーションコード"}
      </PromotionCodeKey>
      <PromotionCodeValue>{code}</PromotionCodeValue>
    </Container>
  );
});

export default PromotionCodeInfo;
