import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import OrderData from "../../records/OrderData";
import colorsConst from "../../styles/const/colorsConst";
import EvilDatetime from "../../util/EvilDatetime";
import Grid from "../atoms/Grid";

interface IContainerProps {
  isOrderStatus?: boolean;
}

const Container = styled(Grid).attrs({
  container: true,
  align: "flex-end",
  justify: "center",
  wrap: "wrap"
})<IContainerProps>`
  ${p => (p.isOrderStatus ? "flex-direction: column;" : "")}
  ${p => (p.isOrderStatus ? "align-items: flex-start;" : "")}
`;

const DisplayDate = styled(Grid).attrs({ item: true })`
  padding: 0 7px 0 0;
  font-size: 1.4rem;
  line-height: 1;
`;

const DisplayTime = styled(Grid).attrs({ item: true })<{ isActive?: boolean }>`
  margin-bottom: -5px;
  padding-right: 10px;
  font-size: 3.6rem;
  line-height: 1;
  color: ${p => (p.isActive ? colorsConst.MAIN : "#000")};
  font-weight: 500;
`;

const DisplayTimeAim = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 5px;
  padding-bottom: 4px;
`;

const DisplayTimeTitle = styled.div`
  margin-left: auto;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-bottom: 4px;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  order?: OrderData;
  date: Date;
  isActive?: boolean;
  isOrderStatus?: boolean;
  dateStyle?: React.CSSProperties;
  timeStyle?: React.CSSProperties;
}

const TimeDisplay: React.FC<IProps> = React.memo(
  props => {
    const {
      order,
      date,
      isActive,
      isOrderStatus,
      dateStyle,
      timeStyle
    } = props;

    const dateString = useMemo(
      () =>
        `${date.getMonth() +
          1}/${date.getDate()}(${EvilDatetime.getWeekdayString(date)})`,
      [date]
    );

    const timeString = useMemo(
      () =>
        `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")}`,
      [date]
    );

    return (
      <Container isOrderStatus={isOrderStatus}>
        <DisplayDate style={dateStyle}>{dateString}</DisplayDate>
        <DisplayTime style={timeStyle} isActive={isActive}>
          <div>{timeString}</div>
          {order && (
            <>
              {isOrderStatus && order.canDisplayRecieveTimeAim() && (
                <DisplayTimeAim>目安</DisplayTimeAim>
              )}
            </>
          )}
          {isOrderStatus && <DisplayTimeTitle>受取日時</DisplayTimeTitle>}
        </DisplayTime>
      </Container>
    );
  },
  (prev, next) => prev.date.getTime() === next.date.getTime()
);

export default TimeDisplay;
