import * as React from "react";
import styled from "styled-components";
import fontsConst from "../../../styles/const/fontsConst";
import Utility, {
  fixBodyScrollTopWhenInputBlurred
} from "../../../util/Utility";

const gap = 2;
const width = 4;

const Wrapper = styled.div`
  padding-top: 6px;
  text-align: center;
`;

const Inner = styled("div")<{ textWidth: number }>`
  position: relative;
  display: inline-block;
  width: ${p => p.textWidth * ((gap + width) * 4 - gap)}px;
  height: 2em;
  background-image: linear-gradient(
    to right,
    #000000,
    #000000 ${p => p.textWidth * width}px,
    transparent ${p => p.textWidth * width}px,
    transparent ${p => p.textWidth * (width + gap)}px
  );
  background-size: ${p => p.textWidth * (width + gap)}px 2px;
  background-position: left bottom;
  background-repeat: repeat-x;
  text-align: left;
  padding-bottom: 8px;
  font-size: 1.4rem;
  font-weight: bold;
`;

const Input = styled("input").attrs<{
  type: string;
  minLength: number;
  maxLength: number;
}>({
  maxLength: 4,
  minLength: 4,
  type: "tel"
})<{ textWidth: number }>`
  position: absolute;
  top: 0;
  left: ${p => p.textWidth * ((width - 1) / 2)}px;
  width: ${p => p.textWidth * (gap + width) * 5}px;
  background-color: transparent;
  letter-spacing: ${p => p.textWidth * (width + gap - 1)}px;
  font-family: ${fontsConst.FAMILY};
  padding: 0;
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleChangeAuthCode: (code: string) => void;
}
interface IState {
  textWidth: number;
}

export default class AuthCodeFieldAlter extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      textWidth: 0
    };

    this.handleChangeAuthCode = this.handleChangeAuthCode.bind(this);
  }

  public componentDidMount() {
    const textWidth = Utility.getTextWidth("0");
    this.setState({
      textWidth
    });
  }

  public render() {
    const { handleChangeAuthCode, ...otherProps } = this.props;
    return (
      <Wrapper>
        <Inner textWidth={this.state.textWidth}>
          <Input
            textWidth={this.state.textWidth}
            onChange={this.handleChangeAuthCode}
            onBlur={fixBodyScrollTopWhenInputBlurred}
            {...otherProps}
          />
        </Inner>
      </Wrapper>
    );
  }

  private handleChangeAuthCode(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    let value = event.currentTarget.value;
    if (value.length > 4) {
      value = event.currentTarget.value = value.slice(-4);
    }
    this.props.handleChangeAuthCode(value);
    if (value.length > 3) {
      event.currentTarget.blur();
    }
  }
}
