import * as React from "react";

import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
// tslint:disable-next-line:ordered-imports
import "slick-carousel/slick/slick-theme.css";
import { createGlobalStyle } from "styled-components";

const FixStyle = createGlobalStyle`
  .slick-list {
    overflow: hidden;
  }
`;

interface IProps extends Settings {
  sliderRef?: React.RefObject<Slider>;
  [otherProps: string]: any;
}
const Carousel = React.memo((props: IProps) => {
  return (
    <>
      <FixStyle />
      <Slider ref={props.sliderRef} {...props} />
    </>
  );
});

export default Carousel;
