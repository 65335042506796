import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgCapture = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 13" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H2C1.44772 3 1 3.44773 1 4V11C1 11.5523 1.44772 12 2 12H14C14.5523 12 15 11.5523 15 11V4C15 3.44773 14.5523 3 14 3H11V2C11 1.44773 10.5523 1 10 1H6C5.44772 1 5 1.44773 5 2V3ZM4 2C4 0.895447 4.89543 0 6 0H10C11.1046 0 12 0.895447 12 2H14C15.1046 2 16 2.89545 16 4V11C16 12.1046 15.1046 13 14 13H2C0.895432 13 0 12.1046 0 11V4C0 2.89545 0.895432 2 2 2H4Z"
        fill="#707070"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.5C9.10457 9.5 10 8.60457 10 7.5C10 6.39543 9.10457 5.5 8 5.5C6.89543 5.5 6 6.39543 6 7.5C6 8.60457 6.89543 9.5 8 9.5ZM8 10.5C9.65685 10.5 11 9.15685 11 7.5C11 5.84315 9.65685 4.5 8 4.5C6.34315 4.5 5 5.84315 5 7.5C5 9.15685 6.34315 10.5 8 10.5Z"
        fill="#707070"
      />
    </svg>
  );
};

const CaptureIcon: React.FC<IProps> = props => <SvgCapture {...props} />;

export default CaptureIcon;
