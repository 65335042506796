import { goBack, push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import {
  userAccessedToPageThatNeedsCreditCardData,
  userSubmittedChangeSelectCreditCard
} from "../../modules/user/actions";
import CreditCardData from "../../records/CreditCardData";
import CreditCardItem from "../../records/CreditCardItem";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import CheckBox from "../atoms/CheckBox";
import Grid from "../atoms/Grid";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

const ItemContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})``;
const ItemIcon = styled(Grid).attrs({ item: true, rate: 0 })``;
const ItemValue = styled(Grid).attrs({ item: true, rate: 1 })`
  padding-left: 7px;
`;

const CardList = styled.ul`
  margin-top: 48px;
`;
const CardItem = styled.li`
  & + & {
    margin-top: 35px;
  }
`;

const SubmitButtonContainer = styled.div`
  margin-top: 48px;
`;

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public userSubmittedChangeSelectCreditCard(cardId: string) {
    this.dispatch(userSubmittedChangeSelectCreditCard(cardId));
  }

  public goToRegisterNewCreditCard() {
    this.dispatch(push("/user/registerNewCreditCard"));
  }

  public fetchCreditCardData() {
    this.dispatch(userAccessedToPageThatNeedsCreditCardData());
  }
}

interface IProps {
  submittingUpdateSelectCreditCard: boolean;
  fetchingCreditCardList: boolean;
  creditCardData: CreditCardData;
  selectedCreditCard: CreditCardItem | null;
  actions: ActionDispatcher;
}

interface IState {
  selectedCardId: string;
}

class ChangeCreditCardTemplate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const selectedItem = this.props.selectedCreditCard;
    this.state = {
      selectedCardId: selectedItem !== null ? selectedItem.card_id : ""
    };

    this.goBack = this.goBack.bind(this);
    this.handleChangeSelectCard = this.handleChangeSelectCard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    this.props.actions.fetchCreditCardData();
  }

  public render() {
    const data = this.props.creditCardData;
    return (
      <Auth>
        <Page
          header={
            <SubPageHeader
              title="クレジットカード選択"
              handleClickBack={this.goBack}
            />
          }
        >
          <PageBody>
            <Notes>使用するカードを選択してください。</Notes>
            <CardList>
              {this.props.fetchingCreditCardList ? (
                <li style={{ textAlign: "center" }}>
                  <Loading />
                </li>
              ) : (
                <>
                  {data.getCreditCardList().map(item => {
                    const selected = this.state.selectedCardId === item.getId();
                    return (
                      <CardItem key={item.getId()}>
                        <label htmlFor={item.getCardNumber()}>
                          <ItemContainer>
                            <ItemIcon>
                              <CheckBox
                                checked={selected}
                                style={{ fontSize: "2.4rem" }}
                              />
                              <input
                                id={item.getCardNumber()}
                                type="radio"
                                name="selectedCard"
                                value={item.getId()}
                                checked={selected}
                                onChange={this.handleChangeSelectCard}
                              />
                            </ItemIcon>
                            <ItemValue>{item.getCardNumber()}</ItemValue>
                          </ItemContainer>
                        </label>
                      </CardItem>
                    );
                  })}
                  <CardItem>
                    <label htmlFor="registerNewCreditCard">
                      <ItemContainer>
                        <ItemIcon>
                          <CheckBox
                            checked={this.state.selectedCardId === ""}
                            style={{ fontSize: "2.4rem" }}
                          />
                          <input
                            id="registerNewCreditCard"
                            type="radio"
                            name="selectedCard"
                            value=""
                            checked={this.state.selectedCardId === ""}
                            onChange={this.handleChangeSelectCard}
                          />
                        </ItemIcon>
                        <ItemValue>新しいクレジットカードを登録</ItemValue>
                      </ItemContainer>
                    </label>
                  </CardItem>
                </>
              )}
            </CardList>
            <SubmitButtonContainer>
              <Button
                block
                onClick={this.handleSubmit}
                loading={this.props.submittingUpdateSelectCreditCard}
              >
                選択
              </Button>
            </SubmitButtonContainer>
          </PageBody>
        </Page>
      </Auth>
    );
  }

  private handleChangeSelectCard(event: React.FormEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    this.setState({
      selectedCardId: value
    });
  }

  private handleSubmit() {
    const cardId = this.state.selectedCardId;
    if (cardId === "") {
      this.props.actions.goToRegisterNewCreditCard();
      return;
    }
    this.props.actions.userSubmittedChangeSelectCreditCard(cardId);
  }

  private goBack() {
    this.props.actions.goBack();
  }
}

export default connect(
  (state: ReduxModel) => ({
    submittingUpdateSelectCreditCard: state.app.isConnectedApi(
      EApiKey.UPDATE_SELECT_CREDIT_CARD
    ),
    fetchingCreditCardList: state.app.isConnectedApi(
      EApiKey.GET_CREDIT_CARD_LIST
    ),
    creditCardData: state.user.getCreditCard(),
    selectedCreditCard: state.user.getSelectedCreditCardItem()
  }),
  (dispatch: Dispatch) => ({ actions: new ActionDispatcher(dispatch) })
)(ChangeCreditCardTemplate);
