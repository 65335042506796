import { put } from "redux-saga/effects";
import { systemOpenedModal } from "../../app/actions";
import {
  updateCartIdForDialog,
  UserTouchedOpenSettingReservationTimeAction
} from "../actions";

export default function* userTouchedOpenSettingReservationTimeSaga(
  action: UserTouchedOpenSettingReservationTimeAction
) {
  yield put(updateCartIdForDialog(action.payload.cartId));
  yield put(systemOpenedModal("SETTING_RESERVATION_FOR_CART", {}));
}
