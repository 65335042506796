import { cancel, delay, fork, join, put, take } from "redux-saga/effects";
import { EApiKey } from "../../..//apis";
import {
  IGetOrderDetailRequest,
  IGetOrderDetailResponse
} from "../../../apis/getOrderDetail";
import { IOrderDataProps } from "../../../records/OrderData";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  ActionTypes,
  systemFailedPollingOrderDetail,
  SystemStartedPollingOrderDetailAction,
  updateCurrentOrderDetail
} from "../actions";

export default function* systemStartedPollingOrderDetailSaga(
  action: SystemStartedPollingOrderDetailAction
) {
  const updateOrderDetailTask = yield fork(() =>
    updateOrderDetail(action.payload.orderNumber)
  );
  yield take(ActionTypes.S_STOPPED_POLLING_ORDER_DETAIL);
  yield cancel(updateOrderDetailTask);
}

function* updateOrderDetail(orderNumber: string) {
  while (true) {
    try {
      yield delay(10000);
      const params: IGetOrderDetailRequest = {
        order_no: orderNumber
      };
      const commonApiTask = yield fork(() =>
        commonApiSaga(EApiKey.GET_ORDER_DETAIL, params)
      );
      const {
        result,
        error
      }: { result: IGetOrderDetailResponse; error: unknown } = yield join(
        commonApiTask
      );
      if (commonApiTask.isCancelled()) {
        yield cancel();
      }
      if (result && !error) {
        const orderData: IOrderDataProps = result.order_data;
        yield put(updateCurrentOrderDetail(orderData));
      } else {
        throw error;
      }
    } catch (exception) {
      // TODO: 後ほど具体的な処理を繋ぎこみ
      yield put(systemFailedPollingOrderDetail());
    }
  }
}
