import React from "react";
import StarIcon from "./StarIcon";

interface IProps {
  high?: boolean;
}

const RatingStar: React.FC<IProps> = props => {
  const fillColor = props.high ? "#F5B000" : "#272727";
  return <StarIcon color={fillColor} />;
};

export default RatingStar;
