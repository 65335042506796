import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgSolidCheck = (props: IProps) => {
  const { fill, ...rest } = props;
  const fillColor = fill ? fill : "#000";
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 12" fill="none" {...rest}>
      <path d="M1.5 4l6 6 8-9" stroke={fillColor} strokeWidth={2} />
    </svg>
  );
};

const SolidCheckIcon: React.FC<IProps> = props => {
  return <SvgSolidCheck {...props} />;
};

export default SolidCheckIcon;
