import React, { useMemo } from "react";
import { RefObject } from "react";
import {
  GoogleMap,
  GoogleMapProps,
  withGoogleMap,
  WithGoogleMapProps,
  withScriptjs,
  WithScriptjsProps
} from "react-google-maps";

const defaultGoogleMapsStylesOptions: google.maps.MapTypeStyle[] = [
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: -5
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        saturation: -100
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  }
];

const defaultGoogleMapsOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  tilt: 0,
  minZoom: 5,
  noClear: true,
  rotateControl: false,
  panControl: false,
  clickableIcons: false,
  styles: defaultGoogleMapsStylesOptions
};

export const DEFAULT_ZOOM_LEVEL = 18;

// FIXME: 新宿駅、あとでなんとかする
export const DEFAULT_MAP_LOCATION: google.maps.LatLngLiteral = {
  lat: 35.6897376,
  lng: 139.6982025
};

const Map = withScriptjs(
  withGoogleMap((props: any) => {
    const { defaultCenter, mapRef, options, ...rest } = props;
    const mergedOptions = useMemo(
      () => ({ ...defaultGoogleMapsOptions, ...options }),
      [options]
    );

    return (
      <GoogleMap
        ref={mapRef && mapRef}
        options={mergedOptions}
        defaultZoom={DEFAULT_ZOOM_LEVEL}
        defaultCenter={defaultCenter ? defaultCenter : DEFAULT_MAP_LOCATION}
        {...rest}
      />
    );
  })
);

interface IProps extends WithScriptjsProps, WithGoogleMapProps, GoogleMapProps {
  mapRef?: RefObject<GoogleMap>;
}

const MapViewerStruct: React.FC<IProps> = React.memo(props => {
  return <Map {...props} />;
});

export default MapViewerStruct;
