import React from "react";
import styled from "styled-components";
import OrderItem from "../../records/OrderItem";
import colorsConst from "../../styles/const/colorsConst";
import Grid from "../atoms/Grid";
import MenuMinimalInfo from "./MenuMinimalInfo";
import OptionMinimalInfo from "./OptionMinimalInfo";

const Container = styled.div`
  border-bottom: 1px solid #f1f1f1;
`;

const orderContainerStyle: React.CSSProperties = {
  display: "block",
  padding: "20px 24px"
};

const OptionList = styled.ul``;

const OptionListItem = styled.li`
  padding-top: 10px;
`;

const InstructionText = styled.div`
  padding: 10px 24px;
  font-size: 1.4rem;
  color: #9d9da2;
  border-top: 1px solid #f1f1f1;
  word-break: break-all;
`;

const CountValue = styled(Grid).attrs({
  item: true,
  container: true,
  direction: "row",
  align: "center",
  justify: "center"
})`
  border: 1px solid ${colorsConst.BORDER.LIGHT};
  color: ${colorsConst.MAIN};
  width: 28px;
  height: 28px;
  text-align: center;
`;

interface IProps {
  item: OrderItem;
  children?: never;
}

const OrderItemViewer: React.FC<IProps> = React.memo(props => {
  const { item } = props;
  const optionList = item.getOptionList();
  const instructionText = item.getInstructionText();

  return (
    <Container>
      <div style={orderContainerStyle}>
        <Grid container align="center">
          <Grid item grow={0}>
            <CountValue>{item.getOrderCount()}</CountValue>
          </Grid>
          <Grid item grow={1} style={{ paddingLeft: "16px" }}>
            <MenuMinimalInfo item={item} />
          </Grid>
        </Grid>
        <OptionList>
          {optionList.map((option, optionIndex) => (
            <OptionListItem key={optionIndex}>
              <OptionMinimalInfo option={option} />
            </OptionListItem>
          ))}
        </OptionList>
      </div>
      {instructionText !== "" && (
        <InstructionText>{instructionText}</InstructionText>
      )}
    </Container>
  );
});

export default OrderItemViewer;
