import { push } from "connected-react-router";
import { List } from "immutable";
import { ellipsis } from "polished";
import React, { useCallback, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import {
  ORDERABLE_COUNT_MAX,
  ORDERABLE_COUNT_MIN
} from "../../records/OrderData";
import ShopData from "../../records/ShopData";
import ShopItem from "../../records/ShopItem";
import ShopItemCategoryList from "../../records/ShopItemCategoryList";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import ScrollTopMemory, { EScrollTopKey } from "../../util/ScrollTopMemory";
import Utility from "../../util/Utility";
import ArrowSmallIcon from "../atoms/ArrowSmallIcon";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";
import Loading from "../molecules/Loading";
import QuantityCounter from "../molecules/QuantityCounter";
import MapViewer from "./MapViewer";
import MemoTextViewer from "./MemoTextViewer";
import OptionSetListViewer from "./OptionSetListViewer";
import ShopMarker from "./ShopMarker";
import ShopMenuItem from "../molecules/ShopMenuItem";
import Analytics from "../../util/Analytics";
import { EShopTabType } from "../../modules/search/model";

const MenuHeadContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const MenuHead = styled("div")<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-image: url(${p => p.src});
  background-color: ${colorsConst.DISABLED};
`;

const ShopItemImage = styled.div`
  padding-top: ${(380 / 375) * 100}%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 5%,
    rgba(255, 255, 255, 0) 50%
  );
`;

const MenuHeadOverlayText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MenuDescriptionContainer = styled.div`
  padding: 17px 0;
`;

const MenuInfo = styled.div`
  padding: 0 20px 22px;
`;

const MenuShopName = styled.div`
  position: relative;
  padding-right: 20px;
  width: 100%;
  font-size: 13px;
  line-height: 19px;
  color: #757575;
  ${ellipsis()}
`;

const ArrowSmallIconContainer = styled.div`
  position: absolute;
  top: 40%;
  right: 0;
  transform: translate(0%, -50%);
`;

const MenuName = styled.div`
  padding: 3px 0 4px;
  font-size: 18px;
  line-height: 24px;
`;

const MenuCookingInfo = styled.div`
  font-size: 13px;
  line-height: 19px;
  color: #757575;
`;

const MenuDescription = styled.div`
  padding: 0px 20px;
  font-size: 1.7rem;
  line-height: 1.6;
  text-align: justify;
`;

const AddToCartButtonContainer = styled.div`
  padding: 18px 0 0;
`;

const containerStyle: React.CSSProperties = {
  paddingTop: "16px"
};

const QuantityContainer = styled.div`
  margin-top: 12px;
  text-align: center;
`;

const MapTitle = styled.div`
  padding: 20px;
  font-size: 18px;
  line-height: 23px;
  color: #1f1f1f;
`;

const MapContainer = styled.div`
  position: relative;
  height: calc(100vw * ${240 / 375});
`;

const MapLoadingContainer = styled(MapContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddressContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  position: absolute;
  bottom: 19px;
  width: calc(100% - ${19 * 2}px);
  margin: 0 19px;
  padding: 8px 15px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

const mapLinkContainerStyle: React.CSSProperties = {
  paddingLeft: "10px"
};

const mapLinkStyle: React.CSSProperties = {
  width: "118px",
  marginLeft: "auto",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#757575"
};

const DeleteTextInline = styled.span`
  color: ${colorsConst.MAIN};
`;

const LuxuryAttention = styled.div`
  color: #ff6056;
  font-size: 16px;
  line-height: 22px;
  padding: 24px 20px;
`;

const OtherShopItemTitle = styled.div`
  padding: 20px 20px 0;
  font-size: 18px;
  line-height: 23px;
  color: #272727;
`;

const OtherShopItemShopLink = styled.div`
  display: block;
  width: 270px;
  margin: 0 auto 50px;
  padding: 9px 0;
  background: #c7c7c7;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const BlankContainer = styled.div`
  text-align: center;
  position: relative;
  color: #757575;
`;

const mapStateToProps = (state: ReduxModel) => ({
  currentShopItemList: state.search.getCurrentShopItemList()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGotoShop(shopId: number, shopItemId: number) {
    Analytics.logEvent("shop_item_to_top", {
      content_type: "shop_item_id",
      item_id: `${shopItemId}`
    });
    dispatch(push(`/shop/${shopId}`));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  shopId: number;
  shopData: ShopData;
  item: ShopItem;
  options: List<number>;
  quantity: number;
  notes: string;
  handleDelete?: () => void;
  handleMount?: () => void;
  handleChangePreOrderOptionList: (list: number[]) => void;
  handleChangePreOrderInstructionText: (text: string) => void;
  handleChangePreOrderCount: (count: number) => void;
  connectingAddOrUpdateItem?: boolean;
  connectingDeleteFromCart?: boolean;
  disabled?: boolean;
  handleValidateOptionList: (disable: boolean) => void;
  isCartEdit?: boolean;
  isLuxury?: boolean;
  currentShopItemList: ShopItemCategoryList;
}

const ShopItemViewer = React.memo((props: IProps) => {
  const {
    shopId,
    shopData,
    item,
    options,
    quantity,
    notes,
    handleDelete,
    handleMount,
    handleChangePreOrderOptionList,
    handleChangePreOrderInstructionText,
    handleChangePreOrderCount,
    connectingAddOrUpdateItem,
    connectingDeleteFromCart,
    disabled,
    handleValidateOptionList,
    isCartEdit,
    isLuxury,
    currentShopItemList,
    handleGotoShop
  } = props;

  useLayoutEffect(() => {
    if (handleMount) {
      handleMount();
    }
  }, [item, handleMount]);

  /**
   * 数量変更
   */
  const handleChangeQuantity = useCallback(
    (count: number) => {
      handleChangePreOrderCount(count);
    },
    [handleChangePreOrderCount]
  );

  const handleClickLaunchMapApp = useCallback(() => {
    Analytics.logEvent("shop_item_launch_map", {
      content_type: "shop_item_id",
      item_id: `${item.getId()}`
    });
    Utility.launchMapApp(shopData.getLocation());
  }, [shopData, item]);

  const handleGotoShopWrap = useCallback(() => {
    ScrollTopMemory.save(
      `${EScrollTopKey.SHOP_TAB}-${shopId}`,
      EShopTabType.TOP
    );
    ScrollTopMemory.clear(`${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`);
    handleGotoShop(shopId, item.getId());
  }, [handleGotoShop, shopId, item]);

  const handleGotoShopMenu = useCallback(() => {
    ScrollTopMemory.save(
      `${EScrollTopKey.SHOP_TAB}-${shopId}`,
      EShopTabType.MENU
    );
    ScrollTopMemory.clear(`${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`);
    handleGotoShop(shopId, item.getId());
  }, [handleGotoShop, shopId, item]);

  if (item === null) {
    return <BlankContainer>メニューが見つかりません</BlankContainer>;
  } else {
    return (
      <>
        <MenuHeadContainer>
          <MenuHead src={item.getImagePath()}>
            <ShopItemImage />
            {item.isSoldOut() ? (
              <MenuHeadOverlayText>SOLD OUT</MenuHeadOverlayText>
            ) : null}
          </MenuHead>
        </MenuHeadContainer>
        <MenuDescriptionContainer>
          <MenuInfo>
            <MenuShopName onClick={handleGotoShopWrap}>
              {shopData.getShopName()}
              <ArrowSmallIconContainer>
                <ArrowSmallIcon />
              </ArrowSmallIconContainer>
            </MenuShopName>
            <MenuName>{item.getName()}</MenuName>
            <MenuCookingInfo>
              調理時間：
              {item.existsItemCookingTime()
                ? `${item.getCookingTime()}分`
                : `${shopData.getStandardCookingTime()}分`}
            </MenuCookingInfo>
          </MenuInfo>
          <MenuDescription>{item.getDescription()}</MenuDescription>
          {isLuxury ? (
            <LuxuryAttention>
              当店は受取時に受取場所の指定がございます。
            </LuxuryAttention>
          ) : null}
          <div style={containerStyle}>
            <OptionSetListViewer
              item={item}
              options={options}
              connectingAddOrUpdateItem={connectingAddOrUpdateItem}
              connectingDeleteFromCart={connectingDeleteFromCart}
              disabled={disabled}
              handleChangePreOrderOptionList={handleChangePreOrderOptionList}
              handleValidateOptionList={handleValidateOptionList}
              isCartEdit={isCartEdit}
            />
            <MemoTextViewer
              connectingAddOrUpdateItem={connectingAddOrUpdateItem}
              connectingDeleteFromCart={connectingDeleteFromCart}
              disabled={disabled}
              notes={notes}
              handleBlurInstructionText={handleChangePreOrderInstructionText}
            />

            <QuantityContainer>
              <QuantityCounter
                count={quantity}
                handleChangeCount={handleChangeQuantity}
                disabled={
                  connectingAddOrUpdateItem ||
                  connectingDeleteFromCart ||
                  disabled
                }
                min={ORDERABLE_COUNT_MIN}
                max={ORDERABLE_COUNT_MAX}
              />
            </QuantityContainer>

            {handleDelete && (
              <AddToCartButtonContainer>
                <Button
                  block
                  loading={connectingDeleteFromCart}
                  disabled={connectingAddOrUpdateItem}
                  onClick={handleDelete}
                  style={{ backgroundColor: "inherit" }}
                >
                  <DeleteTextInline>カートから商品を削除する</DeleteTextInline>
                </Button>
              </AddToCartButtonContainer>
            )}

            {!isCartEdit && (
              <>
                <div>
                  <MapTitle>地図</MapTitle>
                  {shopData.getLocation() ? (
                    <>
                      <MapViewer
                        containerElement={<MapContainer />}
                        loadingElement={
                          <MapLoadingContainer>
                            <Loading />
                          </MapLoadingContainer>
                        }
                        defaultCenter={shopData.getLocation()}
                      >
                        <ShopMarker
                          location={shopData.getLocation()}
                          isOpen={true}
                        />
                        <AddressContainer onClick={handleClickLaunchMapApp}>
                          <Grid item grow={1}>
                            {shopData.getAddress()}
                          </Grid>
                          <Grid item grow={1} style={mapLinkContainerStyle}>
                            <Grid container align="center" style={mapLinkStyle}>
                              <Grid item style={{ paddingRight: "5px" }}>
                                マップアプリで見る
                              </Grid>
                              <ArrowSmallIcon lightGray />
                            </Grid>
                          </Grid>
                        </AddressContainer>
                      </MapViewer>
                    </>
                  ) : (
                    undefined
                  )}
                </div>
                {currentShopItemList.exists() && (
                  <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                    <OtherShopItemTitle>このお店のメニュー</OtherShopItemTitle>
                    {currentShopItemList
                      .getNumberOfOtherItems(item.getId(), 3)
                      .map((shopItem: ShopItem, index: number) => {
                        return (
                          <ShopMenuItem
                            key={shopItem.getId()}
                            shopId={shopData.getId()}
                            item={shopItem}
                          />
                        );
                      })}
                    <OtherShopItemShopLink onClick={handleGotoShopMenu}>
                      全てのメニューを見る
                    </OtherShopItemShopLink>
                  </div>
                )}
              </>
            )}
          </div>
        </MenuDescriptionContainer>
      </>
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopItemViewer);
