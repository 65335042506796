import { Action } from "redux";
import { IGetHomeResponse } from "../../../apis/getHome";
import { ICategoryTag } from "../../../records/CategoryTag";
import Area, { IArea } from "../../../records/Area";
import SubArea from "../../../records/SubArea";
import CategoryTag from "../../../records/CategoryTag";
import { ISubArea } from "../../../records/SubArea";
import SearchQuery from "../../../records/SearchQuery";
import { SearchResultType } from "../../../records/SearchResult";
import { SearchSetting } from "../../../records/SearchSetting";
import { IShopData } from "../../../records/ShopData";
import { IShopForSearchProps } from "../../../records/ShopForSearch";
import { IShopItemCategoryProps } from "../../../records/ShopItemCategory";
import { EPositionError } from "../../../util/Geolocation";
import { ILatLng } from "../model";
import { ActionTypes } from "./index";

export interface UpdateSearchHistoryListAction extends Action {
  type: ActionTypes.R_UPDATE_SEARCH_HISTORY_LIST;
  payload: {
    historyList: string[];
  };
}
export const updateSearchHistoryList = (
  historyList: string[]
): UpdateSearchHistoryListAction => ({
  type: ActionTypes.R_UPDATE_SEARCH_HISTORY_LIST,
  payload: {
    historyList
  }
});

export interface UpdateCurrentLocationAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_LOCATION;
  payload: {
    location: ILatLng;
  };
}
export const updateLocation = (
  location: ILatLng
): UpdateCurrentLocationAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_LOCATION,
  payload: {
    location
  }
});

export interface UpdateCurrentShopDataAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_SHOP_DATA;
  payload: {
    data: IShopData;
  };
}
export const updateCurrentShopData = (
  data: IShopData
): UpdateCurrentShopDataAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_SHOP_DATA,
  payload: {
    data
  }
});

export interface UpdateCurrentShopItemListAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_SHOP_ITEM_LIST;
  payload: {
    itemList: IShopItemCategoryProps[];
  };
}
export const updateCurrentShopItemList = (
  itemList: IShopItemCategoryProps[]
): UpdateCurrentShopItemListAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_SHOP_ITEM_LIST,
  payload: {
    itemList
  }
});

export interface UpdateCurrentLocationInquiryStatusAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_LOCATION_INQUIRY_STATUS;
  payload: {
    status: EPositionError | null;
  };
}
export const updateCurrentLocationInquiryStatus = (
  status: EPositionError | null
): UpdateCurrentLocationInquiryStatusAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_LOCATION_INQUIRY_STATUS,
  payload: {
    status
  }
});

export interface UpdateFetchingCurrentLocationFlagAction extends Action {
  type: ActionTypes.R_UPDATE_FETCHING_CURRENT_LOCATION_FLAG;
  payload: {
    started: boolean;
  };
}
export const updateFetchingCurrentLocationFlag = (
  started: boolean
): UpdateFetchingCurrentLocationFlagAction => ({
  type: ActionTypes.R_UPDATE_FETCHING_CURRENT_LOCATION_FLAG,
  payload: {
    started
  }
});

export interface UpdateCategoryTagListAction extends Action {
  type: ActionTypes.R_UPDATE_CATEGORY_TAG_LIST;
  payload: {
    list: ICategoryTag[];
  };
}
export const updateCategoryTagList = (
  list: ICategoryTag[]
): UpdateCategoryTagListAction => ({
  type: ActionTypes.R_UPDATE_CATEGORY_TAG_LIST,
  payload: {
    list
  }
});

export interface UpdateHomeCategoryTagListAction extends Action {
  type: ActionTypes.R_UPDATE_HOME_CATEGORY_TAG_LIST;
  payload: {
    list: ICategoryTag[];
  };
}
export const updateHomeCategoryTagList = (
  list: ICategoryTag[]
): UpdateHomeCategoryTagListAction => ({
  type: ActionTypes.R_UPDATE_HOME_CATEGORY_TAG_LIST,
  payload: {
    list
  }
});

export interface UpdateShopFavoriteStateAction extends Action {
  type: ActionTypes.R_UPDATE_SHOP_FAVORITE_STATE;
  payload: {
    shopId: number;
    bool: boolean;
  };
}
export const updateShopFavoriteState = (
  shopId: number,
  bool: boolean
): UpdateShopFavoriteStateAction => ({
  type: ActionTypes.R_UPDATE_SHOP_FAVORITE_STATE,
  payload: {
    shopId,
    bool
  }
});

export interface UpdateSearchQueryAction extends Action {
  type: ActionTypes.R_UPDATE_SEARCH_QUERY;
  payload: {
    query: SearchQuery;
  };
}
export const updateSearchQuery = (
  query: SearchQuery
): UpdateSearchQueryAction => ({
  type: ActionTypes.R_UPDATE_SEARCH_QUERY,
  payload: {
    query
  }
});

export interface UpdateSearchSettingAction extends Action {
  type: ActionTypes.R_UPDATE_SEARCH_SETTING;
  payload: {
    resultType: SearchResultType;
    searchSetting: SearchSetting;
  };
}
export const updateSearchSetting = (
  resultType: SearchResultType,
  searchSetting: SearchSetting
) => ({
  type: ActionTypes.R_UPDATE_SEARCH_SETTING,
  payload: {
    resultType,
    searchSetting
  }
});

export interface UpdateHomeListAction extends Action {
  type: ActionTypes.R_UPDATE_HOME_LIST;
  payload: {
    list: Omit<
      IGetHomeResponse,
      "feature_list" | "area_list" | "category_tag_list"
    >;
  };
}
export const updateHomeList = (
  list: Omit<
    IGetHomeResponse,
    "feature_list" | "area_list" | "category_tag_list"
  >
): UpdateHomeListAction => ({
  type: ActionTypes.R_UPDATE_HOME_LIST,
  payload: {
    list
  }
});

export interface UpdateAreaListAction extends Action {
  type: ActionTypes.R_UPDATE_AREA_LIST;
  payload: {
    list: IArea[];
  };
}
export const updateAreaList = (list: IArea[]): UpdateAreaListAction => ({
  type: ActionTypes.R_UPDATE_AREA_LIST,
  payload: {
    list
  }
});

export interface UpdateSearchResultAction extends Action {
  type: ActionTypes.R_UPDATE_SEARCH_RESULT;
  payload: {
    resultType: SearchResultType;
    list: IShopForSearchProps[];
  };
}
export const updateSearchResult = (
  resultType: SearchResultType,
  list: IShopForSearchProps[]
): UpdateSearchResultAction => ({
  type: ActionTypes.R_UPDATE_SEARCH_RESULT,
  payload: {
    resultType,
    list
  }
});

export interface UpdateCurrentSearchResultTypeAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_SEARCH_RESULT_TYPE;
  payload: {
    type: SearchResultType;
  };
}
export const updateCurrentSearchResultType = (
  type: SearchResultType
): UpdateCurrentSearchResultTypeAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_SEARCH_RESULT_TYPE,
  payload: {
    type
  }
});

export interface InitSearchSettingAction extends Action {
  type: ActionTypes.R_INIT_RESERVE_SEARCH_SETTING;
}
export const initReserveSearchSetting = (): InitSearchSettingAction => ({
  type: ActionTypes.R_INIT_RESERVE_SEARCH_SETTING
});

export interface UpdateOnceSearchedAction extends Action {
  type: ActionTypes.R_UPDATE_ONCE_SEARCHED;
  payload: {
    type: SearchResultType;
  };
}
export const updateOnceSearched = (
  type: SearchResultType
): UpdateOnceSearchedAction => ({
  type: ActionTypes.R_UPDATE_ONCE_SEARCHED,
  payload: {
    type
  }
});

export interface UpdateCurrentAreaAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_AREA;
  payload: {
    area: Area;
  };
}
export const updateCurrentArea = (area: Area): UpdateCurrentAreaAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_AREA,
  payload: {
    area
  }
});

export interface UpdateCurrentSubAreaListAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_SUB_AREA_LIST;
  payload: {
    list: ISubArea[];
  };
}
export const updateCurrentSubAreaList = (
  list: ISubArea[]
): UpdateCurrentSubAreaListAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_SUB_AREA_LIST,
  payload: {
    list
  }
});

export interface UpdateCurrentSubAreaAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_SUB_AREA;
  payload: {
    subArea: SubArea;
  };
}
export const updateCurrentSubArea = (
  subArea: SubArea
): UpdateCurrentSubAreaAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_SUB_AREA,
  payload: {
    subArea
  }
});

export interface UpdateCurrentAreaShopListAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_AREA_SHOP_LIST;
  payload: {
    list: IShopForSearchProps[];
  };
}
export const updateCurrentAreaShopList = (
  list: IShopForSearchProps[]
): UpdateCurrentAreaShopListAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_AREA_SHOP_LIST,
  payload: {
    list
  }
});

export interface UpdateCurrentCategoryTagAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_CATEGORY_TAG;
  payload: {
    categoryTag: CategoryTag;
  };
}
export const updateCurrentCategoryTag = (
  categoryTag: CategoryTag
): UpdateCurrentCategoryTagAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_CATEGORY_TAG,
  payload: {
    categoryTag
  }
});

export interface UpdateCurrentCategoryShopListAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_CATEGORY_SHOP_LIST;
  payload: {
    list: IShopForSearchProps[];
  };
}
export const updateCurrentCategoryShopList = (
  list: IShopForSearchProps[]
): UpdateCurrentCategoryShopListAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_CATEGORY_SHOP_LIST,
  payload: {
    list
  }
});

export interface UpdateNearOfficeShopListAction extends Action {
  type: ActionTypes.R_UPDATE_NEAR_OFFICE_SHOP_LIST;
  payload: {
    list: IShopForSearchProps[];
  };
}
export const updateNearOfficeShopList = (
  list: IShopForSearchProps[]
): UpdateNearOfficeShopListAction => ({
  type: ActionTypes.R_UPDATE_NEAR_OFFICE_SHOP_LIST,
  payload: {
    list
  }
});

export interface UpdateCurrentHomeListAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_HOME_SHOP_LIST;
  payload: {
    list: IShopForSearchProps[];
  };
}
export const updateCurrentHomeList = (
  list: IShopForSearchProps[]
): UpdateCurrentHomeListAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_HOME_SHOP_LIST,
  payload: {
    list
  }
});

export type ReducerActions =
  | UpdateCurrentSearchResultTypeAction
  | UpdateSearchResultAction
  | UpdateHomeListAction
  | UpdateSearchQueryAction
  | UpdateShopFavoriteStateAction
  | UpdateCategoryTagListAction
  | UpdateHomeCategoryTagListAction
  | UpdateFetchingCurrentLocationFlagAction
  | UpdateSearchHistoryListAction
  | UpdateCurrentLocationAction
  | UpdateCurrentShopItemListAction
  | UpdateCurrentShopDataAction
  | UpdateCurrentLocationInquiryStatusAction
  | UpdateSearchSettingAction
  | UpdateAreaListAction
  | InitSearchSettingAction
  | UpdateOnceSearchedAction
  | UpdateCurrentAreaAction
  | UpdateCurrentSubAreaListAction
  | UpdateCurrentSubAreaAction
  | UpdateCurrentAreaShopListAction
  | UpdateCurrentCategoryTagAction
  | UpdateCurrentCategoryShopListAction
  | UpdateNearOfficeShopListAction
  | UpdateCurrentHomeListAction;
