import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { systemOpenedModal } from "../../../modules/app/actions";
import { SearchResultType } from "../../../records/SearchResult";
import { ReduxModel } from "../../../reducer";
import colorsConst from "../../../styles/const/colorsConst";
import Analytics, {
  AnalyticsEventSearchOpenFilterSetting,
  AnalyticsEventSearchOpenPlaceSetting
} from "../../../util/Analytics";
import ConsoleIcon from "../../atoms/ConsoleIcon";
import Grid from "../../atoms/Grid";
import PlaceSettingInfo from "./PlaceSettingInfo";
import ResultTypeSymbol from "./ResultTypeSymbol";

export const MAIN_HEADER_HEIGHT = 58;

const Container = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #c9caca;
  overflow: hidden;
`;

const Top = styled.div`
  position: relative;
  padding-top: 10px;
  padding-top: calc(10px + constant(safe-area-inset-top));
  padding-top: calc(10px + env(safe-area-inset-top));
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
`;

const ConfiguredIcon = styled.div`
  position: absolute;
  top: -3px;
  right: -6px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${colorsConst.MAIN};
`;

interface IProps {
  searchResultType: Exclude<SearchResultType, "keyword">;
}

const MainHeader: React.FC<IProps> = React.memo(({ searchResultType }) => {
  const searchPlace = useSelector((state: ReduxModel) => {
    const searchSetting = state.search
      .getSearchSettings()
      .getData(searchResultType);
    return searchSetting.getSearchPlace();
  });

  const isConfigured = useSelector((state: ReduxModel) => {
    return state.search.getSearchSettings().isConfigured(searchResultType);
  });

  const dispatch = useDispatch();

  const goToSettingReservation = useCallback(() => {
    const analyticsEventName = `search_${searchResultType}_open_place_setting` as AnalyticsEventSearchOpenPlaceSetting;
    Analytics.logEvent(analyticsEventName);
    dispatch(push(`/setting/search/${searchResultType}`));
  }, [dispatch, searchResultType]);

  const handleClickSettingFilterDialog = useCallback(() => {
    const analyticsEventName = `search_${searchResultType}_open_filter_setting` as AnalyticsEventSearchOpenFilterSetting;
    Analytics.logEvent(analyticsEventName);
    dispatch(systemOpenedModal("SETTING_FILTER", { searchResultType }));
  }, [dispatch, searchResultType]);

  return (
    <Container>
      <Top>
        <Grid container direction="row" align="center" justify="flex-start">
          <Grid item rate={0}>
            <ResultTypeSymbol searchResultType={searchResultType} />
          </Grid>
          <Grid
            item
            rate={1}
            onClick={goToSettingReservation}
            style={{ overflow: "hidden" }}
          >
            <PlaceSettingInfo searchPlace={searchPlace} />
          </Grid>
          <Grid
            item
            rate={0}
            style={{ paddingLeft: "11px", position: "relative" }}
            onClick={handleClickSettingFilterDialog}
          >
            {isConfigured && <ConfiguredIcon />}
            <ConsoleIcon />
          </Grid>
        </Grid>
      </Top>
    </Container>
  );
});

export default MainHeader;
