import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: string;
}

const SvgVector = (props: IProps) => {
  const { color, ...rest } = props;
  const fill = color ? color : "#000";
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 16" fill="none" {...rest}>
      <path
        d="M11 15.315a1.25 1.25 0 0 1-.707-.204l-10-6.961c-.39-.272-.39-.713 0-.985l10-6.961c.39-.272 1.023-.272 1.414 0 .39.272.39.712 0 .984l-9.293 6.47 9.293 6.468c.39.272.39.713 0 .985a1.25 1.25 0 0 1-.707.204z"
        fill={fill}
      />
    </svg>
  );
};

const style: React.CSSProperties = {
  transform: "scaleX(-1)"
};

const ChevronNarrowIcon = (props: IProps) => (
  <SvgVector style={style} {...props} />
);

export default ChevronNarrowIcon;
