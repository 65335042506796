import React, { useMemo } from "react";
import { Marker, MarkerProps } from "react-google-maps";
import pinSvg from "./Ellipse.png";

declare var google: any;

interface IProps extends MarkerProps {
  children?: never;
}

const CurrentLocationMarker: React.FC<IProps> = React.memo(props => {
  const icon: google.maps.Icon = useMemo(
    () => ({
      url: pinSvg,
      scaledSize: new google.maps.Size(38, 38),
      anchor: new google.maps.Point(19, 19)
    }),
    []
  );
  return <Marker {...props} icon={icon} />;
});

export default CurrentLocationMarker;
