import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Notes from "./Notes";

const ErrorContainer = styled.div`
  padding: 32px 0;
  text-align: center;
`;
const ErrorMessage = styled(Notes)`
  padding: 0.5em;
  font-weight: 300;
`;

interface ErrorBoundaryProps {
  error?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (
      typeof this.props.error !== "undefined" &&
      prevProps.error !== this.props.error
    ) {
      this.setState({ hasError: this.props.error });
    }
  }

  public componentDidCatch(error: unknown) {
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorMessage>エラーが発生しました。</ErrorMessage>
          <Link to="/">戻る</Link>
        </ErrorContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
