import React from "react";
import SubmittingAddCartDialog from "./SubmittingAddCartDialog";
import RequestForReviewDialog from "./RequestForReviewDialog";

interface IProps {}

const ShopModal: React.FC<IProps> = React.memo(props => {
  return (
    <>
      <SubmittingAddCartDialog />
      <RequestForReviewDialog />
    </>
  );
});

export default ShopModal;
