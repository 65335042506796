import React, { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { systemClosedModal } from "../../modules/app/actions";
import { userChangedPriceRange } from "../../modules/search/actions";
import Price from "../../records/Price";
import PriceRange, {
  PriceRangeValue
} from "../../records/SearchSetting/PriceRange";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";
import ButtonAlter from "../atoms/ButtonAlter";
import Grid from "../atoms/Grid";
import Modal from "../molecules/Modal";
import Analytics, {
  AnalyticsEventSearchChangePriceRange
} from "../../util/Analytics";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND};
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const Title = styled.div`
  font-size: 1.8rem;
  color: #272727;
  padding-bottom: 65px;
`;

const Top = styled.div`
  padding: 21px 23px;
`;

const Bottom = styled.div`
  padding: 8px;
  border-top: 1px solid #cdcdd2;
`;

const SelectNumberContainer = styled.div`
  position: relative;
  font-size: 1.8rem;
`;

const CurrentDatePrefix = styled.div`
  color: #272727;
  font-size: 1.4rem;
`;

const SelectNumber = styled.select`
  color: inherit;
  background-color: transparent;
  color: #272727;
  cursor: pointer;
  font-size: inherit;
`;

interface IProps {}

const SettingPriceRangeDialog: React.FC<IProps> = React.memo(() => {
  const isOpenDialog = useSelector((state: ReduxModel) =>
    state.app.getModalManager().canDisplay("SETTING_PRICE_RANGE")
  );

  const searchResultType = useSelector((state: ReduxModel) => {
    const modalPayload = state.app
      .getModalManager()
      .getPayload("SETTING_PRICE_RANGE");
    if (typeof modalPayload === "undefined") {
      return undefined;
    }
    return modalPayload.searchResultType;
  });

  const currentPriceRange = useSelector((state: ReduxModel) => {
    if (typeof searchResultType === "undefined") {
      return undefined;
    }
    const setting = state.search.getSearchSettings().getData(searchResultType);
    return setting.getPriceRange();
  });

  const [draftPriceRange, setDraftPriceRange] = useState(() => {
    if (typeof currentPriceRange === "undefined") {
      return PriceRange.asDefault();
    }
    return currentPriceRange;
  });

  const dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(systemClosedModal("SETTING_PRICE_RANGE"));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    if (typeof searchResultType === "undefined") {
      return;
    }
    const analyticsEventName = `search_${searchResultType}_change_price_range` as AnalyticsEventSearchChangePriceRange;
    Analytics.logEvent(analyticsEventName, {
      content_type: "price_range",
      item_id: `${draftPriceRange.getSettingText()}`
    });
    dispatch(userChangedPriceRange(searchResultType, draftPriceRange));
  }, [dispatch, draftPriceRange, searchResultType]);

  const handleChangeMin = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      const min = value === "null" ? null : parseInt(value, 10);
      if (min !== null && isNaN(min)) {
        return;
      }
      setDraftPriceRange(draftPriceRange.setMin(min as PriceRangeValue));
    },
    [draftPriceRange, searchResultType]
  );

  const handleChangeMax = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      const max = value === "null" ? null : parseInt(value, 10);
      if (max !== null && isNaN(max)) {
        return;
      }
      setDraftPriceRange(draftPriceRange.setMax(max as PriceRangeValue));
    },
    [draftPriceRange, searchResultType]
  );

  useLayoutEffect(() => {
    if (isOpenDialog) {
      setDraftPriceRange(() => {
        if (typeof currentPriceRange === "undefined") {
          return PriceRange.asDefault();
        }
        return currentPriceRange;
      });
    }
  }, [currentPriceRange, isOpenDialog]);

  return (
    <Modal
      isOpen={isOpenDialog}
      handleClickBackdrop={closeDialog}
      contentStyle={{ paddingBottom: 0 }}
      align="flex-end"
    >
      <Container>
        <Top>
          <Title>価格帯を指定する</Title>

          <Grid container align="flex-end">
            <Grid item rate={1}>
              <CurrentDatePrefix>{"下限"}</CurrentDatePrefix>
              <SelectNumberContainer>
                <SelectNumber
                  onChange={handleChangeMin}
                  onBlur={fixBodyScrollTopWhenInputBlurred}
                  value={`${draftPriceRange.getMin()}`}
                >
                  {draftPriceRange.getSelectableList("min").map(range => {
                    const text =
                      range === null
                        ? "なし"
                        : `${Price.getPresentationValue(range)}~`;
                    return (
                      <option key={`${range}`} value={`${range}`}>
                        {text}
                      </option>
                    );
                  })}
                </SelectNumber>
                {" ▼"}
              </SelectNumberContainer>
            </Grid>
            <Grid item rate={1}>
              <CurrentDatePrefix>{"上限"}</CurrentDatePrefix>
              <SelectNumberContainer>
                <SelectNumber
                  onChange={handleChangeMax}
                  onBlur={fixBodyScrollTopWhenInputBlurred}
                  value={`${draftPriceRange.getMax()}`}
                >
                  {draftPriceRange.getSelectableList("max").map(range => {
                    const text =
                      range === null
                        ? "なし"
                        : `~${Price.getPresentationValue(range)}`;
                    return (
                      <option key={`${range}`} value={`${range}`}>
                        {text}
                      </option>
                    );
                  })}
                </SelectNumber>
                {" ▼"}
              </SelectNumberContainer>
            </Grid>
          </Grid>
        </Top>
        <Bottom>
          <ButtonAlter block appearance="secondary" onClick={handleSubmit}>
            決定
          </ButtonAlter>
        </Bottom>
      </Container>
    </Modal>
  );
});

export default SettingPriceRangeDialog;
