import { put } from "redux-saga/effects";
import { systemOpenedModal } from "../../app/actions";
import { UserTouchedWriteReviewButtonAction } from "../actions";

export default function* userTouchedWriteReviewButtonSaga(
  action: UserTouchedWriteReviewButtonAction
) {
  const { shopId } = action.payload;
  yield put(systemOpenedModal("REQUEST_FOR_REVIEW", { shopId }));
}
