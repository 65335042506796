import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import { InjectedFormProps, reduxForm } from "redux-form";
import {
  IRequesrShopForm,
  initialRequesrShopForm,
  validateRequesrShopForm
} from "../../forms/requestShop";
import { userSubmittedRequestShop } from "../../modules/user/actions";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import colorsConst from "../../styles/const/colorsConst";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Notes from "../atoms/Notes";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";
import ButtonAlter from "../atoms/ButtonAlter";

const contentStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const labelStyle: React.CSSProperties = {
  marginLeft: "5px",
  padding: "2px",
  color: "#fff",
  backgroundColor: "#9fa0a0",
  borderRadius: "2px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RequestShopTemplate: React.FC<
  IProps & InjectedFormProps<IRequesrShopForm>
> = React.memo(props => {
  const {
    submittingRequestShop,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialRequesrShopForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Page
      header={
        <SubPageHeader
          title="お店をリクエスト"
          handleClickBack={handleClickBack}
        />
      }
      contentStyle={contentStyle}
    >
      <PageBody>
        <Notes>
          「お店をリクエスト」に関しては個別の回答は行っておりません。頂戴した内容は今後の参考とさせていただきます。
        </Notes>
        <Notes style={{ marginTop: "14px", marginBottom: "30px" }}>
          ※掲載をお約束するものではございませんことを、あらかじめご理解の程お願いいたします。
        </Notes>
        <form id={EFormID.REQUEST_SHOP} onSubmit={handleSubmitWrap}>
          <div>
            <LabelText htmlFor="postingShopName" style={{ fontWeight: "bold" }}>
              店名
            </LabelText>
            <TextField
              id="postingShopName"
              name="posting_shop_name"
              type="text"
            />
          </div>
          <div>
            <LabelText htmlFor="recommendReason" style={{ fontWeight: "bold" }}>
              オススメの理由<span style={labelStyle}>任意</span>
            </LabelText>
            <TextField
              id="recommendReason"
              name="recommend_reason"
              type="text"
            />
          </div>
          <div>
            <LabelText
              htmlFor="contactPhoneNumber"
              style={{ fontWeight: "bold" }}
            >
              店主のお名前とご連絡先<span style={labelStyle}>任意</span>
            </LabelText>
            <TextField
              id="contactPhoneNumber"
              name="contact_phone_number"
              type="text"
            />
          </div>
          <ButtonAlter
            block
            appearance={"secondary"}
            loading={submittingRequestShop}
            disabled={!valid}
            type="submit"
            form={EFormID.REQUEST_SHOP}
            style={{ paddingTop: "14px", paddingBottom: "14px" }}
          >
            送信
          </ButtonAlter>
        </form>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingRequestShop: state.app.isConnectedApi(
    EApiKey.SEND_SHOP_POSTING_DESIRED
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },
  handleSubmit() {
    dispatch(userSubmittedRequestShop());
  }
});

const reduxFormConfig = {
  form: EFormID.REQUEST_SHOP,
  initialValues: initialRequesrShopForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateRequesrShopForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<IRequesrShopForm, IProps>(reduxFormConfig)(RequestShopTemplate));
