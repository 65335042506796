import { cancelled } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import APICache from "../../../util/APICache";

export default function* commonApiSuccessSaga(
  response: Response,
  method: EApiKey,
  params: {}
): any {
  try {
    const { result } = yield response.json();

    APICache.setCache(method, params, { result });
    return { result };
  } catch (e) {
    // エラー時の処理
    console.log(e);
  } finally {
    if (yield cancelled()) {
      // ここにキャンセルされた時の処理を記述
      console.log("commonApiSuccessSaga is cancelled");
    }
  }
}
