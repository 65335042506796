import React from "react";
import ShopFavoriteIcon from "../atoms/ShopFavoriteIcon";

interface IProps {
  active: boolean;
  handleClick: () => void;
}

const ShopFavoriteButton: React.FC<IProps> = React.memo(
  ({ active, handleClick }) => {
    return (
      <div onClick={handleClick}>
        <ShopFavoriteIcon active={active} />
      </div>
    );
  }
);

export default ShopFavoriteButton;
