import { Cordova } from "../Cordova";

declare var cordova: Cordova;

export enum EPlatform {
  IOS = "iOS",
  ANDROID = "Android",
  OTHER = "Other"
}

export default class CordovaUtil {
  public static isCordovaEnabled() {
    return "cordova" in window;
  }

  public static initialize(callback: () => void) {
    document.addEventListener("deviceready", callback, false);
  }

  public static openAppLocationSettings() {
    if (cordova && cordova.plugins.settings) {
      if (cordova.platformId === "android") {
        cordova.plugins.settings.open("location");
      } else {
        cordova.plugins.settings.open("locations");
      }
    }
  }

  public static openAppNotificationSettings() {
    if (cordova && cordova.plugins.settings) {
      if (cordova.platformId === "android") {
        cordova.plugins.settings.open("application_details");
      } else {
        cordova.plugins.settings.open("notification_id");
      }
    }
  }

  public static getAppVersion() {
    if (!("cordova" in window && "getAppVersion" in cordova)) {
      return Promise.resolve(null);
    }
    return Promise.race<string | null>([
      new Promise<string | null>((resolve, reject) => {
        cordova.getAppVersion
          .getVersionNumber()
          .then((version: string) => resolve(version))
          .catch((_: any) => resolve(null));
      }),
      new Promise<null>(res => setTimeout(() => res(null), 5000))
    ]);
  }

  public static getPlatform(): EPlatform {
    if (!CordovaUtil.isCordovaEnabled()) {
      return EPlatform.OTHER;
    }
    switch ((window as any).cordova.platformId) {
      case "android":
        return EPlatform.ANDROID;
      case "ios":
        return EPlatform.IOS;
      default:
        return EPlatform.OTHER;
    }
  }

  public static getPlatformId(): "ios" | "android" | "unknown" {
    return "cordova" in window ? cordova.platformId : "unknown";
  }
}
