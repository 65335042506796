import { replace, push } from "connected-react-router";
import { List } from "immutable";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IOrderCartListRequest,
  IOrderCartListResponse
} from "../../../apis/orderCartList";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import UserData from "../../../records/UserData";
import OrderModel from "../../order/model";
import { EPaymentType } from "../../../records/PaymentType";
import { IOrderDataProps } from "../../../records/OrderData";
import { ReduxModel } from "../../../reducer";
import Appsflyer from "../../../util/Appsflyer";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert,
  systemOpenedModal
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCart,
  updateCurrentOrderDetail,
  UserSubmittedOrderAction
} from "../actions";
import checkCreditCardExistsSaga from "./checkCreditCardExistsSaga";

export default function* userSubmittedOrderSaga(
  action: UserSubmittedOrderAction
) {
  try {
    yield put(setProcessingFlag(EProcessingFlag.SUBMIT_ORDER));

    const { cartId } = action.payload;

    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());
    const cart = CartData.findById(cartList, cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    const userData: UserData = yield select<(state: ReduxModel) => UserData>(
      state => state.user.getData()
    );

    // ニックネーム未登録の場合処理を中断して登録画面へ
    if (!userData.existsNickName()) {
      yield put(push("/register/nickNameAtOrder"));
      yield cancel();
    }

    const order: OrderModel = yield select<(state: ReduxModel) => OrderModel>(
      state => state.order
    );

    // 支払い方法がクレジットカードの場合、クレジットカード情報がなければ処理を中断して登録画面へ
    if (order.getPaymentType() === EPaymentType.CREDIT) {
      const checkCreditCardExistsTask = yield fork(() =>
        checkCreditCardExistsSaga()
      );
      const existsCreditCard = yield join(checkCreditCardExistsTask);
      if (checkCreditCardExistsTask.isCancelled()) {
        yield cancel();
      }
      if (!existsCreditCard) {
        yield put(push("/user/registerNewCreditCard"));
        yield cancel();
      }
    }

    const userPaymentPrice = cart.getUserPaymentPrice();

    const params: IOrderCartListRequest = {
      shop_id: cart.getShopId(),
      total_price: userPaymentPrice,
      payment_type: order.getPaymentType()
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.ORDER_CART_LIST, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }

    const {
      result,
      error
    }: { result: IOrderCartListResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (result && !error) {
      const orderData: IOrderDataProps = result.order;
      yield put(updateCart(result.cart_list));
      const replaceDestination = `/history/${orderData.order_no}`;
      yield put(updateCurrentOrderDetail(orderData));
      yield put(
        replace(replaceDestination, {
          routerActionType: { [`${"REPLACE"}${replaceDestination}`]: "PUSH" }
        })
      );

      const isFirstOrder = result.is_first_order;

      // Appsflyer集計
      const selector = (state: ReduxModel) => ({
        appVersion: state.app.getAppVersion(),
        userId: state.user.getData().getId()
      });
      const { appVersion, userId }: ReturnType<typeof selector> = yield select(
        selector
      );
      Appsflyer.trackOrderEvent(
        userPaymentPrice,
        userId,
        isFirstOrder,
        appVersion
      );

      if (isFirstOrder) {
        yield put(systemOpenedModal("PUSH_NOTIFICATION", {}));
      }
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文の送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.SUBMIT_ORDER));
  }
}
