import { push } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import {
  userAccessedToHome,
  userTouchedCurrentLocationSearchButton,
  userTouchedCategoryTagThumb
} from "../../modules/search/actions";
import { ReduxModel } from "../../reducer";
import { EProcessingFlag } from "../../modules/app/model";
import colorsConst from "../../styles/const/colorsConst";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ButtonAlter from "../atoms/ButtonAlter";
import Loading from "../molecules/Loading";
import Auth from "../organisms/Auth";
import CartButton from "../organisms/CartButton";
import HomeCarousel from "../organisms/HomeCarousel";
import HomeCategoryTagList from "../organisms/HomeCategoryTagList";
import HomeAreaList from "../organisms/HomeAreaList";
import HomeShopList from "../organisms/HomeShopList";
import HomeTopImg from "../organisms/HomeTopImg";
import HomeSettingReservation from "../organisms/HomeSettingReservation";
import HomeNearOfficeShopList from "../organisms/HomeNearOfficeShopList";
import KeywordSearchField from "../organisms/KeywordSearchField";
import UnratedOrder from "../organisms/UnratedOrder";

const contentStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.45);
`;

const HomePageBody = styled(PageBody)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const HomeKeywordSearchFieldContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -22px;
  margin-bottom: 30px;
`;

const HomeKeywordSearchField = styled.div`
  margin-bottom: 12px;
`;

const CurrentLocationSearchButtonContainer = styled.div`
  padding-left: 90px;
  padding-right: 90px;
`;

const LoadingOverlay = () => (
  <LoadingContainer>
    <Loading invert />
  </LoadingContainer>
);

interface IProps {
  children?: never;
}

const HomeTemplate: React.FC<IProps> = React.memo(() => {
  const homeList = useSelector((state: ReduxModel) =>
    state.search.getHomeList()
  );

  const featureList = useSelector((state: ReduxModel) =>
    state.notification.getFeatureList()
  );

  const areaList = useSelector((state: ReduxModel) =>
    state.search.getAreaList()
  );

  const fetching = useSelector(
    (state: ReduxModel) =>
      state.app.isConnectedApi([
        EApiKey.GET_HOME,
        EApiKey.SEARCH_SHOP_WITH_KEYWORD,
        EApiKey.GET_CATEGORY_TAG_LIST,
        EApiKey.GET_SHOP_LIST_BY_CATEGORY
      ]) ||
      state.app.isProcessing(EProcessingFlag.FETCHING_HOME_FASTEST_SEARCH) ||
      state.app.isProcessing(EProcessingFlag.FETCHING_HOME_RESERVE_SEARCH)
  );

  const categoryTagList = useSelector((state: ReduxModel) => {
    return state.search.getHomeCategoryTagList();
  });

  const dispatch = useDispatch();

  const handleClickCategoryThumb = useCallback(
    (categoryId: number) => {
      dispatch(userTouchedCategoryTagThumb(categoryId));
    },
    [dispatch]
  );

  const handleClickCurrentLocationSearchButton = useCallback(() => {
    dispatch(userTouchedCurrentLocationSearchButton());
  }, [dispatch]);

  useEffect(() => {
    dispatch(userAccessedToHome());
  }, [dispatch]);

  return (
    <Auth>
      <Page contentStyle={contentStyle} footer={<CartButton />}>
        <HomePageBody>
          <UnratedOrder />

          <HomeTopImg />

          <HomeKeywordSearchFieldContainer>
            <HomeKeywordSearchField>
              <KeywordSearchField />
            </HomeKeywordSearchField>
            <CurrentLocationSearchButtonContainer>
              <ButtonAlter
                block
                appearance={"main"}
                onClick={handleClickCurrentLocationSearchButton}
                style={{ paddingTop: "9px", paddingBottom: "9px" }}
              >
                現在地周辺
              </ButtonAlter>
            </CurrentLocationSearchButtonContainer>
          </HomeKeywordSearchFieldContainer>

          <HomeCarousel features={featureList} />

          <HomeSettingReservation />

          <HomeAreaList areaList={areaList} />

          <HomeNearOfficeShopList />

          <HomeCategoryTagList
            handleClick={handleClickCategoryThumb}
            categoryTagList={categoryTagList}
          />

          <HomeShopList homeList={homeList} />
        </HomePageBody>
      </Page>
      {fetching && <LoadingOverlay />}
    </Auth>
  );
});

export default HomeTemplate;
