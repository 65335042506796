import { ReducerActions } from "./reducer";
import { UserAndSystemActions } from "./userAndSystem";

export enum ActionTypes {
  U_LOGGED_IN = "user/U_LOGGED_IN",
  U_SUBMITTED_REGISTER_PERSONAL = "user/U_SUBMITTED_REGISTER_PERSONAL",
  U_SUBMITTED_REGISTER_AUTH_AT_FIRST_ACCESS = "user/U_SUBMITTED_REGISTER_AUTH_AT_FIRST_ACCESS",
  U_SUBMITTED_REGISTER_AUTH = "user/U_SUBMITTED_REGISTER_AUTH",
  U_SUBMITTED_REGISTER_TEL_AT_FIRST_ACCESS = "user/U_SUBMITTED_REGISTER_TEL_AT_FIRST_ACCESS",
  U_SUBMITTED_UPDATE_TEL = "user/U_SUBMITTED_UPDATE_TEL",
  U_SUBMITTED_UPDATE_MAIL_ADDRESS_AND_PASSWORD = "user/U_SUBMITTED_UPDATE_MAIL_ADDRESS_AND_PASSWORD",
  U_SUBMITTED_UPDATE_MAIL_ADDRESS = "user/U_SUBMITTED_UPDATE_MAIL_ADDRESS",
  U_SUBMITTED_UPDATE_PASSWORD = "user/U_SUBMITTED_UPDATE_PASSWORD",
  U_SUBMITTED_REGISTER_NICK_NAME = "user/U_SUBMITTED_REGISTER_NICK_NAME",
  U_SUBMITTED_REGISTER_NICK_NAME_AT_ORDER = "user/U_SUBMITTED_REGISTER_NICK_NAME_AT_ORDER",
  U_TOUCHED_SKIP_REGISTER_NICK_NAME = "user/U_TOUCHED_SKIP_REGISTER_NICK_NAME",
  U_SUBMITTED_REQUEST_RESET_PASSOWORD = "user/U_SUBMITTED_REQUEST_RESET_PASSWORD",
  U_SUBMITTED_AUTH_RESET_PASSWORD = "user/U_SUBMITTED_AUTH_RESET_PASSWORD",
  U_SUBMITTED_RESET_PASSOWORD = "user/U_SUBMITTED_RESET_PASSWORD",
  U_SUBMITTED_DISCOUNT_CODE = "user/U_SUBMITTED_DISCOUNT_CODE",
  U_SUBMITTED_DISCOUNT_CODE_AT_FIRST_ACCESS = "user/U_SUBMITTED_DISCOUNT_CODE_AT_FIRST_ACCESS",
  U_TOUCHED_SKIP_INPUT_DISCOUNT_CODE_BUTTON = "user/U_TOUCHED_SKIP_INPUT_DISCOUNT_CODE_BUTTON",
  U_SUBMITTED_LOGIN = "user/U_SUBMITTED_LOGIN",
  U_SUBMITTED_CHANGE_SELECT_CREDIT_CARD = "user/U_SUBMITTED_CHANGE_SELECT_CREDIT_CARD",
  U_SUBMITTED_REGISTER_NEW_CREDIT_CARD = "user/U_SUBMITTED_REGISTER_NEW_CREDIT_CARD",
  U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_BUTTON = "user/U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_BUTTON",
  U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_FOR_RESET_PASSWORD_BUTTON = "user/U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_FOR_RESET_PASSWORD_BUTTON",
  U_TOUCHED_REQUEST_TO_NOTIFY_AUTH_CODE_BY_VIRTUAL_CALL_BUTTON = "user/U_TOUCHED_REQUEST_TO_NOTIFY_AUTH_CODE_BY_VIRTUAL_CALL_BUTTON",
  U_TOUCHED_LOGOUT_BUTTON = "user/U_TOUCHED_LOGOUT_BUTTON",
  U_TOUCHED_TO_BUTTON_THAT_RETURN_FROM_MAINTENANCE_PAGE = "user/U_TOUCHED_TO_BUTTON_THAT_RETURN_FROM_MAINTENANCE_PAGE",
  U_TOUCHED_ADD_CART_AFTER_REGISTER_BUTTON = "user/U_TOUCHED_ADD_CART_AFTER_REGISTER_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_USER_DATA = "user/U_ACCESSED_TO_PAGE_THAT_NEEDS_USER_DATA",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_DISCOUNT_CODES = "user/U_ACCESSED_TO_PAGE_THAT_NEEDS_DISCOUNT_CODES",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_CREDIT_CARD_DATA = "user/U_ACCESSED_TO_PAGE_THAT_NEEDS_CREDIT_CARD_DATA",
  U_ACCESSED_MAINTENANCE_PAGE = "user/U_ACCESSED_MAINTENANCE_PAGE",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_AUTH = "user/U_ACCESSED_TO_PAGE_THAT_NEEDS_AUTH",
  U_TOUCHED_GUEST_LOGIN_BUTTON = "user/U_TOUCHED_GUEST_LOGIN_BUTTON",
  U_SUBMITTED_REGISTRATION_PLACE = "user/U_SUBMITTED_REGISTRATION_PLACE",
  U_SUBMITTED_REQUEST_AREA = "user/U_SUBMITTED_REQUEST_AREA",
  U_SUBMITTED_REQUEST_SHOP = "user/U_SUBMITTED_REQUEST_SHOP",
  R_RESET_USER = "user/R_RESET_USER",
  R_UPDATE_DISCOUNT_CODES = "user/R_UPDATE_DISCOUNT_CODES",
  R_UPDATE_USER = "user/R_UPDATE_USER",
  R_UPDATE_LOGIN_TOKEN = "user/R_UPDATE_LOGIN_TOKEN",
  R_UPDATE_REGISTER_STEP = "user/R_UPDATE_REGISTER_STEP",
  R_UPDATE_CREDIT_CARD = "user/R_UPDATE_CREDIT_CARD",
  R_UPDATE_INSTALL_COUPON = "user/R_UPDATE_INSTALL_COUPON"
}

export type UserActions = UserAndSystemActions | ReducerActions;
export * from "./userAndSystem";
export * from "./reducer";
