import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppModel from "../../modules/app/model";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Dialog, { DialogBody, DialogFooter } from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
`;

const modalStyle: React.CSSProperties = {
  padding: "35px"
};

interface IProps {
  openShouldUpdateResourceModal: boolean;
  appModel: AppModel;
  children?: never;
}

const ShouldUpdateResourceModal: React.FC<IProps> = React.memo(props => {
  const { openShouldUpdateResourceModal, appModel } = props;
  const [processingFlag, setProcessingFlag] = useState(false);

  const handleClickUpdateResource = useCallback(() => {
    setProcessingFlag(true);
    appModel.updateResourceManually();
  }, [appModel]);

  return openShouldUpdateResourceModal ? (
    <Container>
      <Modal isOpen={true} innerStyle={modalStyle}>
        <Dialog>
          <DialogBody>
            <Notes>
              新しいデータがあります。
              <br />
              画面を読み込み直してください。
            </Notes>
          </DialogBody>
          <DialogFooter>
            <Button
              block
              onClick={handleClickUpdateResource}
              loading={processingFlag}
            >
              再読み込み
            </Button>
          </DialogFooter>
        </Dialog>
      </Modal>
    </Container>
  ) : null;
});

const mapStateToProps = (state: ReduxModel) => ({
  openShouldUpdateResourceModal: state.app
    .getModalManager()
    .canDisplay("SHOULD_UPDATE_RESOURCE"),
  appModel: state.app
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShouldUpdateResourceModal);
