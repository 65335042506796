import React, { useCallback } from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";

const Container = styled.div`
  padding: 15px 20px 0;
  font-size: 14px;
`;

const MemoInput = styled.textarea`
  display: block;
  width: 100%;
  padding: 10px;
  min-height: calc(1.5 * 2em + 20px);
  color: #4e4e4e;
  ::placeholder {
    color: #b8b8b8;
  }
  border-bottom: 1px solid #d7d7da;
  caret-color: ${colorsConst.MAIN};
`;

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  handleBlur?: () => void;
  children?: never;
}

const MemoTextInput: React.FC<IProps> = React.memo((props: IProps) => {
  const { handleBlur } = props;
  const handleBlurWrap = useCallback(() => {
    handleBlur && handleBlur();
    fixBodyScrollTopWhenInputBlurred();
  }, [handleBlur]);
  return (
    <Container>
      <MemoInput {...props} onBlur={handleBlurWrap} />
    </Container>
  );
});

export default MemoTextInput;
