import * as React from "react";
import { EFormID } from "../../forms";
import ButtonAlter from "../atoms/ButtonAlter";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";

const containerStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column"
};

interface IProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  disabled: boolean;
}

const InputTel = React.memo((props: IProps) => {
  const { handleSubmit, loading, disabled } = props;

  return (
    <div style={containerStyle}>
      <div style={{ flexGrow: 1 }}>
        <form id={EFormID.UPDATE_TEL} onSubmit={handleSubmit}>
          <LabelText htmlFor="tel">携帯電話番号</LabelText>
          <TextField
            id="tel"
            name="tel"
            type="tel"
            placeholder="09000000000"
            autoComplete="tel"
            maxLength={11}
          />
        </form>
      </div>
      <div style={{ flexGrow: 0 }}>
        <ButtonAlter
          appearance={"secondary"}
          block
          loading={loading}
          disabled={disabled}
          type="submit"
          form={EFormID.UPDATE_TEL}
        >
          送信
        </ButtonAlter>
      </div>
    </div>
  );
});

export default InputTel;
