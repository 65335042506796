import TWEEN from "@tweenjs/tween.js";
import * as React from "react";
import styled from "styled-components";
import ShopItemCategory from "../../records/ShopItemCategory";
import ShopItemCategoryList from "../../records/ShopItemCategoryList";
import colorsConst from "../../styles/const/colorsConst";
import CategoryMenuList from "./CategoryMenuList";

const MenuList = styled.ul`
  position: relative;
  z-index: 2;
  padding-top: 48px;
  margin-top: -48px;
  background-color: ${colorsConst.BACKGROUND};
`;

interface IProps {
  list: ShopItemCategoryList;
  shopId: number;
  children?: never;
}

const ShopTabMenu: React.FC<IProps> = React.memo(props => {
  const { list, shopId } = props;

  return (
    <MenuList>
      {list.getList().map((category: ShopItemCategory, index) => (
        <CategoryMenuList key={index} category={category} shopId={shopId} />
      ))}
    </MenuList>
  );
});

export default ShopTabMenu;
