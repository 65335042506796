import { push } from "connected-react-router";
import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  ISearchShopWithKeywordRequest,
  ISearchShopWithKeywordResponse
} from "../../../apis/searchShopWithKeyword";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateSearchQuery,
  updateSearchResult,
  UserSubmittedSearchQueryAction
} from "../actions";
import ScrollTopMemory, { EScrollTopKey } from "../../../util/ScrollTopMemory";

export default function* userSubmittedSearchQuerySaga(
  action: UserSubmittedSearchQueryAction
) {
  try {
    const { query } = action.payload;

    const params: ISearchShopWithKeywordRequest = {
      keyword_list: query.getTextList()
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.SEARCH_SHOP_WITH_KEYWORD, params)
    );

    const {
      result,
      error
    }: { result: ISearchShopWithKeywordResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      ScrollTopMemory.clear(`${EScrollTopKey.TEXT_SEARCH_RESULT}`);

      yield put(updateSearchQuery(query));
      yield put(updateSearchResult("keyword", result.shop_list));

      if (action.payload.locationChange) {
        yield put(push("/search/keyword"));
      }
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
