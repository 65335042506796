import { replace } from "connected-react-router";
import { put, select } from "redux-saga/effects";
import { ReduxModel } from "../../../reducer";
import { systemReadyForUserOperation } from "../../app/actions";
import AppModel from "../../app/model";
import { updateUser } from "../actions";
import UserModel from "../model";

export default function* userAccessedMaintenancePageSaga() {
  const { user, app }: { user: UserModel; app: AppModel } = yield select<
    (state: ReduxModel) => { user: UserModel; app: AppModel }
  >(state => ({
    user: state.user,
    app: state.app
  }));
  yield put(updateUser(user.getData().setMaintenanceFlag()));
  yield put(replace("/maintenance"));
  if (!app.completedToReadyForUserOperation()) {
    yield put(systemReadyForUserOperation());
  }
}
