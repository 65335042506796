import { ellipsis } from "polished";
import React from "react";
import styled from "styled-components";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 8px;
  background-color: #e9e9e9;
  border-radius: 6px;
  overflow: hidden;
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
`;

const Divider = styled.div`
  margin: 0 5px;
  flex: 0 0 auto;
  width: 1px;
  height: 1em;
  background-color: #9fa0a0;
`;

const PlaceInfoText = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  color: #666666;
  ${ellipsis()}
`;

interface IProps {
  searchPlace: SearchPlace;
}

const PlaceSettingInfo: React.FC<IProps> = React.memo(({ searchPlace }) => {
  return (
    <Container>
      <IconContainer>
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.84604 14.8731C5.52875 15.206 5.52859 15.2058 5.52859 15.2058L5.52742 15.2046L5.5243 15.2013L5.51283 15.1892L5.46972 15.1431C5.43231 15.103 5.37797 15.0442 5.3091 14.9684C5.17139 14.8168 4.97546 14.597 4.74071 14.3223C4.27165 13.7735 3.6453 13.0028 3.01789 12.1168C2.39151 11.2323 1.75754 10.2236 1.27852 9.19931C0.802639 8.18167 0.461426 7.11039 0.461426 6.11054C0.461426 2.99089 2.8722 0.461914 5.84604 0.461914C8.81988 0.461914 11.2307 2.99089 11.2307 6.11054C11.2307 7.11039 10.8894 8.18167 10.4136 9.19931C9.93454 10.2236 9.30057 11.2323 8.6742 12.1168C8.04679 13.0028 7.42044 13.7735 6.95138 14.3223C6.71662 14.597 6.52069 14.8168 6.38298 14.9684C6.31412 15.0442 6.25977 15.103 6.22236 15.1431L6.17925 15.1892L6.16778 15.2013L6.16466 15.2046L6.16377 15.2055C6.16377 15.2055 6.16333 15.206 5.84604 14.8731ZM5.84604 14.8731L6.16377 15.2055L5.84604 15.5388L5.52859 15.2058L5.84604 14.8731Z"
            fill="#666666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.85059 7.99985C7.08055 7.99985 8.07764 6.95388 8.07764 5.66361C8.07764 4.37333 7.08055 3.32736 5.85059 3.32736C4.62062 3.32736 3.62354 4.37333 3.62354 5.66361C3.62354 6.95388 4.62062 7.99985 5.85059 7.99985Z"
            fill="white"
          />
        </svg>
      </IconContainer>
      <Divider />
      <PlaceInfoText>{searchPlace.getSettingText()}</PlaceInfoText>
    </Container>
  );
});

export default PlaceSettingInfo;
