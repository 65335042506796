import { List, Record } from "immutable";
import ShopOption, { IShopOption } from "./ShopOption";

export enum EOptionSelectType {
  REQUIRED = 1,
  OPTIONAL = 2
}

export interface IShopOptionSetProps {
  option_set_id: number;
  option_set_name: string;
  note: string;
  description: string;
  description_en: string;
  option_select_type: EOptionSelectType;
  select_count_min: number;
  select_count_max: number;
  option_list: IShopOption[] | List<ShopOption>;
}

export interface IOptionSet extends IShopOptionSetProps {
  option_list: List<ShopOption>;
}

const initialValues: IOptionSet = {
  option_set_id: 0,
  option_set_name: "",
  note: "",
  description: "",
  description_en: "",
  option_select_type: EOptionSelectType.REQUIRED,
  select_count_min: 0,
  select_count_max: 0,
  option_list: List<ShopOption>()
};

/**
 * メニューのオプション項目
 */
export default class ShopOptionSet extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        option_list: List(
          args.option_list &&
            args.option_list.map(
              (option: IShopOption) => new ShopOption(option)
            )
        )
      })
    );
  }

  public getId(): number {
    return this.get("option_set_id");
  }

  public getSetName(): string {
    return this.get("option_set_name");
  }

  public getNote(): string {
    return this.get("note");
  }

  public getDescription(locale: "en" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("description_en");
      default:
        return this.get("description");
    }
  }

  public getOptionSelectType(): EOptionSelectType {
    return this.get("option_select_type");
  }

  public getSelectCountMin(): number {
    // 任意のときは最小値は 必ず0
    if (!this.isRequired()) {
      return 0;
    }
    // 既存店舗対応 - 必須なのに選択数の最小値0のときがあるので1に設定
    if (this.get("select_count_min") === 0) {
      return 1;
    } else {
      return this.get("select_count_min");
    }
  }

  public getSelectCountMax(): number {
    // 既存店舗対応 - 必須なのに選択数の最大値0のときがあるので1に設定
    if (this.isRequired() && this.get("select_count_max") === 0) {
      return 1;
    } else {
      return this.get("select_count_max");
    }
  }

  public getOptionList(): List<ShopOption> {
    return this.get("option_list");
  }

  public initializedRequiredOption(): List<ShopOption> {
    if (this.isRequired()) {
      return this.getOptionList().filter(
        (option: ShopOption, index: number) => {
          return index < this.getSelectCountMin();
        }
      );
    } else {
      return List<ShopOption>();
    }
  }

  public updateOptionList(optionId: number, remove: boolean) {
    const updateOptionList = this.getOptionList().map((option: ShopOption) =>
      option.getId() === optionId
        ? remove
          ? option.removeSelected()
          : option.updateSelected()
        : option
    );
    return this.set("option_list", updateOptionList);
  }

  public clearSelectedOptionList() {
    const updateOptionList = this.getOptionList().map((option: ShopOption) =>
      option.removeSelected()
    );
    return this.set("option_list", updateOptionList);
  }

  public getSelectedOptionTotalPrice(): number {
    const sum = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;
    const totalPrice: number = this.getOptionList()
      .map((option: ShopOption) =>
        option.isSelected() === true ? option.getPrice() : 0
      )
      .reduce(sum);
    return totalPrice;
  }

  public isRequired() {
    return this.getOptionSelectType() === EOptionSelectType.REQUIRED;
  }

  public hasNoSelectCountMax() {
    return this.getSelectCountMax() === 0;
  }

  public isSmallSelectCountMinThanSelectCountMax(): boolean {
    return this.getSelectCountMin() < this.getSelectCountMax();
  }

  public getOptionSetNotice() {
    const Notice = this.isRequired()
      ? this.isSmallSelectCountMinThanSelectCountMax()
        ? `必須・${this.getSelectCountMin()}点以上${this.getSelectCountMax()}点まで`
        : `必須・${this.getSelectCountMin()}点`
      : !this.hasNoSelectCountMax()
      ? `${this.getSelectCountMax()}点まで選択可`
      : "";
    return Notice;
  }

  public getOptionSetWarning() {
    const Warning = this.isSmallSelectCountMinThanSelectCountMax()
      ? `${this.getSelectCountMin()}つ以上選んでください。`
      : `${this.getSelectCountMin()}つ選んでください。`;
    return Warning;
  }

  public getSelectedOptionIdArray(): number[] {
    return this.getOptionList()
      .filter(o => o.isSelected())
      .map(i => i.getId())
      .toArray();
  }

  public getSelectedOptionList(): List<ShopOption> {
    return this.getOptionList().filter(o => o.isSelected());
  }

  public getOptionSetDescription() {
    return this.isRequired()
      ? "必須"
      : this.hasNoSelectCountMax()
      ? "選択可"
      : `${this.getSelectCountMax()}点まで選択可`;
  }

  public countSelectedOption(): number {
    return this.getOptionList().filter(option => option.isSelected()).size;
  }

  public canSelectedAnotherOption(): boolean {
    return (
      this.hasNoSelectCountMax() ||
      this.countSelectedOption() < this.getSelectCountMax()
    );
  }

  public isOnlyOneSelectOption(): boolean {
    return this.getSelectCountMin() === 1 && this.getSelectCountMax() === 1;
  }

  public pleaseSelectMoreOptions(): boolean {
    return (
      this.isRequired() && this.countSelectedOption() < this.getSelectCountMin()
    );
  }
}
