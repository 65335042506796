import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4782 2.36435L12.0704 0H7.92964L7.52171 2.36438C6.34544 2.72029 5.28207 3.32286 4.39652 4.10936L2.07036 3.26758L0 6.73145L1.91604 8.25545C1.78675 8.81701 1.71856 9.40081 1.71856 10C1.71856 10.599 1.78672 11.1827 1.91594 11.7441L0 13.2676L2.07036 16.7314L4.39574 15.8899C5.28146 16.6768 6.34514 17.2796 7.5218 17.6356L7.92964 20H12.0704L12.4783 17.6356C13.6545 17.2797 14.7179 16.6772 15.6034 15.8907L17.9291 16.7324L19.9995 13.2686L18.0838 11.7451C18.2132 11.1834 18.2814 10.5994 18.2814 10C18.2814 9.40111 18.2133 8.81758 18.0841 8.25627L20 6.73242L17.9296 3.26855L15.6043 4.11005C14.7185 3.32319 13.6549 2.72037 12.4782 2.36435ZM17.4716 4.50216L15.3717 5.26206L14.9035 4.84612C14.1277 4.15687 13.197 3.62973 12.1688 3.31864L11.5617 3.13495L11.1934 1H8.80657L8.43822 3.13497L7.83113 3.31866C6.80329 3.62966 5.87284 4.15658 5.09715 4.84551L4.62896 5.26134L2.52837 4.50118L1.33494 6.49789L3.06445 7.87352L2.92653 8.47255C2.81354 8.96331 2.75374 9.47432 2.75374 10C2.75374 10.5255 2.81351 11.0364 2.92644 11.5271L3.06434 12.1262L1.33501 13.5012L2.52837 15.4978L4.62827 14.7379L5.09647 15.1539C5.87231 15.8431 6.80303 16.3703 7.8312 16.6814L8.43833 16.8651L8.8066 19H11.1934L11.5618 16.865L12.1689 16.6813C13.1967 16.3703 14.1271 15.8435 14.9028 15.1545L15.371 14.7387L17.4711 15.4988L18.6645 13.5022L16.9354 12.1271L17.0734 11.5279C17.1864 11.037 17.2463 10.5259 17.2463 10C17.2463 9.47458 17.1865 8.96381 17.0736 8.47327L16.9358 7.8743L18.6651 6.49887L17.4716 4.50216Z"
        fill="#3A3A47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1408 10C14.1408 12.2091 12.287 14 10.0001 14C7.71324 14 5.85938 12.2091 5.85938 10C5.85938 7.79086 7.71324 6 10.0001 6C12.287 6 14.1408 7.79086 14.1408 10ZM10.0001 13C11.7152 13 13.1056 11.6569 13.1056 10C13.1056 8.34315 11.7152 7 10.0001 7C8.28495 7 6.89455 8.34315 6.89455 10C6.89455 11.6569 8.28495 13 10.0001 13Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const SettingIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default SettingIcon;
