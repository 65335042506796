import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetShopDataWithTimeTypeRequest,
  IGetShopDataWithTimeTypeResponse
} from "../../../apis/getShopDataWithTimeType";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCurrentShopData,
  updateCurrentShopItemList,
  UserAccessedShopPageAction
} from "../actions";

export default function* userAccessedShopPageSaga(
  action: UserAccessedShopPageAction
) {
  try {
    const selector = (state: ReduxModel) => {
      const type = state.search.getCurrentSearchResultType();
      const reservationTime = state.search
        .getSearchSettings()
        .getData(type)
        .getReservationTime();
      return {
        timeType: reservationTime.getTimeType(),
        receiveDatetime: reservationTime.getDatetimeString()
      };
    };

    const {
      timeType,
      receiveDatetime
    }: ReturnType<typeof selector> = yield select<typeof selector>(selector);

    const params: IGetShopDataWithTimeTypeRequest = {
      shop_id: action.payload.shopId,
      time_type: timeType,
      receive_datetime: receiveDatetime
    };
    const targetApi = EApiKey.GET_SHOP_DATA_WITH_TIME_TYPE;
    const commonApiTask = yield fork(() => commonApiSaga(targetApi, params));
    const {
      result,
      error
    }: {
      result: IGetShopDataWithTimeTypeResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateCurrentShopData(result.shop_data));
      yield put(updateCurrentShopItemList(result.item_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `店舗情報の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
