import { List, Map, Record } from "immutable";
import EvilDatetime, {
  NOT_EXIST_ITEM_COOKING_TIME,
  Weekday
} from "../util/EvilDatetime";
import BusinessBusyTime, { IBusinessBusyTime } from "./BusinessBusyTime";
import BusinessWeekTime, { IBusinessWeekTime } from "./BusinessWeekTime";

export interface IShopBusiness {
  cooking_time: number;
  crow_order_count: number;
  business_week_time: List<BusinessWeekTime>;
  business_busy_time: List<BusinessBusyTime>;
  holiday_list: string[];
  fastest_cooking_time: number; // 単品調理時間で最も早い調理時間
}

const initialValues: IShopBusiness = {
  cooking_time: 0,
  crow_order_count: 0,
  business_week_time: List<BusinessWeekTime>(),
  business_busy_time: List<BusinessBusyTime>(),
  holiday_list: [],
  fastest_cooking_time: NOT_EXIST_ITEM_COOKING_TIME
};

export default class ShopBusiness extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        business_week_time: List(
          args.business_week_time &&
            args.business_week_time.map(
              (item: IBusinessWeekTime) => new BusinessWeekTime(item)
            )
        ),
        business_busy_time: List(
          args.business_busy_time &&
            args.business_busy_time.map(
              (item: IBusinessBusyTime) => new BusinessBusyTime(item)
            )
        )
      })
    );
  }

  public getFastestCookingTime(): number {
    return this.get("fastest_cooking_time");
  }

  public getCookingTime(): number {
    return this.get("cooking_time");
  }

  public getPresentationCookingTime(): string {
    const fastestCookingTime = this.getFastestCookingTime();
    const regularCookingTime = this.getCookingTime();
    const isValidFastestCookingTime =
      fastestCookingTime !== NOT_EXIST_ITEM_COOKING_TIME &&
      fastestCookingTime !== regularCookingTime;
    return isValidFastestCookingTime
      ? `${fastestCookingTime}〜${regularCookingTime}`
      : `${regularCookingTime}`;
  }

  public getCrowOrderCount(): number {
    return this.get("crow_order_count");
  }

  public getBusinessWeekTimeList() {
    return this.get("business_week_time");
  }

  public getBusyWeekTimeList() {
    return this.get("business_busy_time");
  }

  public getWeekDaysStrToBusinessHoursStrEntries() {
    return this.getBusinessHoursStrToWeekDaysMap().flatMap(i => {
      return Array.from(i.entries()).map(([str, days]) => {
        return [str, this.getWeekdayRangeText(days)];
      });
    });
  }

  private getBusinessHoursStrToWeekDaysMap() {
    let [i, prev]: [number, string | null] = [0, null];
    type StrToWeekDaysMap = Map<string, List<Weekday>>;
    return Array.from(this.getBusinessHourStringList().entries()).reduce(
      (acc, [key, value]) => {
        const init = Map().set(value, List([key])) as StrToWeekDaysMap;
        if (prev === null) {
          const r = acc.set(i, init);
          prev = value;
          return r;
        }
        if (prev !== value) {
          i += 1;
          const r = acc.set(i, init);
          prev = value;
          return r;
        }
        const notSetValue = Map().set(value, List([key])) as StrToWeekDaysMap;
        return acc.set(
          i,
          acc.get(i, notSetValue).set(
            value,
            acc
              .get(i, notSetValue)
              .get(value, List<Weekday>())
              .push(key as Weekday)
          )
        );
      },
      List<StrToWeekDaysMap>()
    );
  }

  private getBusinessHourStringList() {
    return this.getBusinessWeekTimeList().map(businessWeekTime => {
      return businessWeekTime.isRegularHoliday()
        ? "定休日"
        : businessWeekTime
            .getBusinessTimes()
            .map(businessTime => {
              return `${EvilDatetime.getHHmmStringByTimeString(
                businessTime.getStartTime()
              )} - ${EvilDatetime.getHHmmStringByTimeString(
                businessTime.getEndTime()
              )}`;
            })
            .join("\n");
    });
  }

  private getWeekdayRangeText(days: List<Weekday>) {
    if (days.size === 7) {
      return "毎日";
    }
    if (days.size === 1) {
      return EvilDatetime.getLocaleDayName(days.first());
    }
    try {
      return `${EvilDatetime.getLocaleDayName(
        days.first()
      )} - ${EvilDatetime.getLocaleDayName(days.last())}`;
    } catch {
      return "";
    }
  }
}
