import React from "react";
import SearchTemplateBase from "../organisms/SearchTemplateBase";

interface IProps {}

const FastestOrderTemplate: React.FC<IProps> = () => {
  return <SearchTemplateBase searchResultType={"fastest"} />;
};

export default FastestOrderTemplate;
