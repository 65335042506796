import { goBack, push } from "connected-react-router";
import { useCallback, useMemo } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import PlaceListItem from "../../records/PlaceListItem";
import { ReduxModel } from "../../reducer";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import ChevronWideIcon from "../atoms/ChevronWideIcon";
import ButtonAlter from "../atoms/ButtonAlter";
import Grid from "../atoms/Grid";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const FieldGroup = styled.div`
  margin-bottom: 20px;
`;

const Head = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #595959;
`;

const Notes = styled.span`
  margin-left: 1em;
  font-size: 13px;
  font-weight: normal;
  color: #9fa0a0;
`;

const ReadOnlyField = styled.div`
  padding: 3px;
  font-size: 15px;
  color: #898989;
  border-bottom: 1px solid ${colorsConst.BORDER.LIGHT};
`;

interface IRegistrationablePlaceItemProps {
  item: PlaceListItem;
}

const RegistrationablePlaceItem: React.FC<
  IRegistrationablePlaceItemProps
> = React.memo(props => {
  const { item } = props;
  const Icon = item.getIconComponent();
  return (
    <div
      style={{
        padding: "12px 0",
        borderBottom: "1px solid #efefef"
      }}
    >
      <Link to={`/user/inputRegistrationPlace/${item.getType()}`}>
        <Grid container align="center" justify="flex-start" direction="row">
          <Grid item rate={0} style={{ fontSize: "16px" }}>
            <Icon />
          </Grid>
          <Grid item rate={0} style={{ paddingLeft: "10px" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#595959",
                whiteSpace: "nowrap"
              }}
            >
              {item.getLabelText()}
            </div>
          </Grid>
          <Grid item rate={1} style={{ paddingLeft: "20px" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#9fa0a0",
                textAlign: "right",
                whiteSpace: "normal"
              }}
            >
              {item.registered() ? (
                <span>{item.getAddressText()}</span>
              ) : (
                <span>登録する</span>
              )}
            </div>
          </Grid>
          <Grid item rate={0} style={{ fontSize: "12px", paddingLeft: "15px" }}>
            <ChevronWideIcon color={"#9fa0a0"} />
          </Grid>
        </Grid>
      </Link>
    </div>
  );
});
// =============================================

const mapStateToProps = (state: ReduxModel) => ({
  userData: state.user.getData()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public handleClickNickNameButton() {
    this.dispatch(push("/register/nickName"));
  }

  public handleClickRegisterMailButton() {
    this.dispatch(push("/register/mail"));
  }

  public handleClickUpdateMailButton() {
    this.dispatch(push("/update/mail"));
  }

  public handleClickUpdatePasswordButton() {
    this.dispatch(push("/update/password"));
  }

  public handleClickEditPhoneNumberButton() {
    this.dispatch(push("/update/tel"));
  }
}

interface IProps extends ReturnType<typeof mapStateToProps> {
  actions: ActionDispatcher;
}

const UserInfoTemplate = React.memo((props: IProps) => {
  const { userData, actions } = props;

  const placeList = useMemo(() => {
    return PlaceListItem.getOrderedList(userData.getPlaceList());
  }, [userData]);

  const handleGoBack = useCallback(() => actions.goBack(), [actions]);

  const handleClickNickNameButton = useCallback(() => {
    actions.handleClickNickNameButton();
  }, [actions]);

  const handleClickEditMailButton = useCallback(() => {
    if (userData.existEmail()) {
      actions.handleClickUpdateMailButton();
    } else {
      actions.handleClickRegisterMailButton();
    }
  }, [actions, userData]);

  const handleClickEditPasswordButton = useCallback(() => {
    if (userData.existEmail()) {
      actions.handleClickUpdatePasswordButton();
    } else {
      actions.handleClickRegisterMailButton();
    }
  }, [actions, userData]);

  const handleClickEditPhoneNumberButton = useCallback(() => {
    actions.handleClickEditPhoneNumberButton();
  }, [actions, userData]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="アカウント設定"
            handleClickBack={handleGoBack}
          />
        }
        containerStyle={containerStyle}
      >
        <PageBody>
          {!userData.exists() ? (
            <div style={{ textAlign: "center", padding: "1em" }}>
              アカウント情報がありません
            </div>
          ) : (
            <>
              <FieldGroup style={{ marginBottom: "60px" }}>
                <Head>
                  受取時の名前<Notes>※お店で商品受け取り時に使用します</Notes>
                </Head>
                <Grid container align={"center"}>
                  <Grid item rate={1}>
                    <ReadOnlyField>
                      {userData.existsNickName()
                        ? userData.getNickName()
                        : "未登録"}
                    </ReadOnlyField>
                  </Grid>
                  <Grid item rate={0} style={{ marginLeft: "15px" }}>
                    <ButtonAlter
                      appearance={"secondary"}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                      onClick={handleClickNickNameButton}
                    >
                      {userData.existsNickName() ? "変更" : "登録"}
                    </ButtonAlter>
                  </Grid>
                </Grid>
              </FieldGroup>

              <Head
                style={{
                  marginBottom: "20px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #dcdddd"
                }}
              >
                ユーザー情報<Notes>※アカウントの引き継ぎに必要です</Notes>
              </Head>

              <FieldGroup>
                <Head>メールアドレス</Head>
                <Grid container align={"center"}>
                  <Grid item rate={1}>
                    <ReadOnlyField>
                      {userData.existEmail() ? userData.getEmail() : "未登録"}
                    </ReadOnlyField>
                  </Grid>
                  <Grid item rate={0} style={{ marginLeft: "15px" }}>
                    <ButtonAlter
                      appearance={"secondary"}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                      onClick={handleClickEditMailButton}
                    >
                      {userData.existEmail() ? "変更" : "登録"}
                    </ButtonAlter>
                  </Grid>
                </Grid>
              </FieldGroup>

              <FieldGroup>
                <Head>パスワード</Head>
                <Grid container align={"center"}>
                  <Grid item rate={1}>
                    <ReadOnlyField>
                      {userData.existEmail() ? "●●●●●●●●" : "未登録"}
                    </ReadOnlyField>
                  </Grid>
                  <Grid item rate={0} style={{ marginLeft: "15px" }}>
                    <ButtonAlter
                      appearance={"secondary"}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                      onClick={handleClickEditPasswordButton}
                    >
                      {userData.existEmail() ? "変更" : "登録"}
                    </ButtonAlter>
                  </Grid>
                </Grid>
              </FieldGroup>

              <FieldGroup style={{ marginBottom: "40px" }}>
                <Head>
                  電話番号<Notes>※SMS認証を行います</Notes>
                </Head>
                <Grid container align={"center"}>
                  <Grid item rate={1}>
                    <ReadOnlyField>
                      {userData.existsTel() ? userData.getTel() : "未登録"}
                    </ReadOnlyField>
                  </Grid>
                  <Grid item rate={0} style={{ marginLeft: "15px" }}>
                    <ButtonAlter
                      appearance={"secondary"}
                      onClick={handleClickEditPhoneNumberButton}
                      style={{ paddingTop: "9px", paddingBottom: "9px" }}
                    >
                      {userData.existsTel() ? "変更" : "登録"}
                    </ButtonAlter>
                  </Grid>
                </Grid>
              </FieldGroup>

              <div>
                <Head>位置情報登録</Head>
                {placeList.map(place => {
                  return (
                    <RegistrationablePlaceItem
                      key={place.getType()}
                      item={place}
                    />
                  );
                })}
              </div>
            </>
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoTemplate);
