import * as React from "react";
interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgNounOrder1591470000000 = (props: IProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2082 6.64597V8.43347H30.7246V6.64597H16.2082ZM14.8332 6.48555C14.8332 5.81168 15.3815 5.27097 16.0504 5.27097H30.8824C31.5512 5.27097 32.0996 5.81168 32.0996 6.48555V8.59388C32.0996 9.26775 31.5512 9.80847 30.8824 9.80847H16.0504C15.3815 9.80847 14.8332 9.26775 14.8332 8.59388V6.48555Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4664 2.10846C23.8461 2.10846 24.1539 2.41626 24.1539 2.79596V5.95846C24.1539 6.33815 23.8461 6.64596 23.4664 6.64596C23.0867 6.64596 22.7789 6.33815 22.7789 5.95846V2.79596C22.7789 2.41626 23.0867 2.10846 23.4664 2.10846Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7721 0.347802C27.9597 0.677873 27.8443 1.09759 27.5142 1.28527L23.8062 3.3936C23.4762 3.58127 23.0565 3.46584 22.8688 3.13577C22.6811 2.8057 22.7965 2.38598 23.1266 2.19831L26.8346 0.0899728C27.1647 -0.0977027 27.5844 0.0177316 27.7721 0.347802Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.59045 28.0959C1.59045 27.7163 1.89826 27.4084 2.27795 27.4084H20.2882C20.6679 27.4084 20.9757 27.7163 20.9757 28.0959V30.2043C20.9757 31.1696 20.1906 31.9459 19.2288 31.9459H3.33738C2.37558 31.9459 1.59045 31.1696 1.59045 30.2043V28.0959ZM2.96545 28.7834V30.2043C2.96545 30.4038 3.12851 30.5709 3.33738 30.5709H19.2288C19.4376 30.5709 19.6007 30.4038 19.6007 30.2043V28.7834H2.96545Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.77634 25.6209C1.55212 25.6209 1.37634 25.8007 1.37634 26.0155V27.0138C1.37634 27.2286 1.55212 27.4084 1.77634 27.4084H20.7899C21.0141 27.4084 21.1899 27.2286 21.1899 27.0138V26.0155C21.1899 25.8007 21.0141 25.6209 20.7899 25.6209H1.77634ZM0.00134277 26.0155C0.00134277 25.0349 0.799183 24.2459 1.77634 24.2459H20.7899C21.767 24.2459 22.5649 25.0349 22.5649 26.0155V27.0138C22.5649 27.9945 21.767 28.7834 20.7899 28.7834H1.77634C0.799183 28.7834 0.00134277 27.9945 0.00134277 27.0138V26.0155Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0432736 21.341C0.264105 20.2087 1.31154 19.5022 2.37288 19.5022H20.2883C20.9114 19.5022 21.4765 19.7842 21.8783 20.184C22.28 20.5838 22.565 21.1475 22.565 21.7709C22.565 23.0404 21.499 23.9774 20.3242 24.0388M1.39286 21.6041C1.46977 21.21 1.87055 20.8772 2.37288 20.8772H20.2883C20.4931 20.8772 20.7227 20.9739 20.9084 21.1587C21.0943 21.3437 21.19 21.5705 21.19 21.7709C21.19 22.2134 20.7961 22.6373 20.2525 22.6656L20.2524 22.6656C19.7769 22.6905 19.3867 22.3279 19.3867 21.7709C19.3867 21.3913 19.0789 21.0834 18.6992 21.0834H16.0506C15.6709 21.0834 15.3631 21.3913 15.3631 21.7709C15.3631 22.2614 14.9627 22.6647 14.4615 22.6647C13.9602 22.6647 13.5598 22.2614 13.5598 21.7709C13.5598 21.3913 13.252 21.0834 12.8723 21.0834H9.69405C9.31435 21.0834 9.00655 21.3913 9.00655 21.7709C9.00655 22.2614 8.60618 22.6647 8.10491 22.6647C7.60364 22.6647 7.20327 22.2614 7.20327 21.7709C7.20327 21.3913 6.89547 21.0834 6.51577 21.0834H3.86721C3.48751 21.0834 3.17971 21.3913 3.17971 21.7709C3.17971 22.3279 2.78946 22.6905 2.314 22.6656L2.31398 22.6656C1.70092 22.6336 1.29293 22.1171 1.39285 21.6042M2.24215 24.0388C3.34124 24.0963 4.17666 23.3905 4.45478 22.4584L2.24215 24.0388ZM4.45478 22.4584H5.93456C6.2271 23.3769 7.09015 24.0397 8.10491 24.0397C9.11967 24.0397 9.98272 23.3769 10.2753 22.4584H12.2911C12.5837 23.3769 13.4467 24.0397 14.4615 24.0397C15.4762 24.0397 16.3393 23.3769 16.6318 22.4584H18.1116C18.3897 23.3904 19.2251 24.0962 20.3241 24.0388M0.0432736 21.341L0.0432586 21.3411L0.0432736 21.341ZM0.0432586 21.3411C-0.243456 22.8121 0.940846 23.9707 2.24213 24.0388L0.0432586 21.3411Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.59045 18.0814C1.59045 17.7017 1.89826 17.3939 2.27795 17.3939H20.2882C20.6679 17.3939 20.9757 17.7017 20.9757 18.0814V20.1897C20.9757 20.5694 20.6679 20.8772 20.2882 20.8772H2.27795C1.89826 20.8772 1.59045 20.5694 1.59045 20.1897V18.0814ZM2.96545 18.7689V19.5022H19.6007V18.7689H2.96545Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17959 21.771C3.17959 21.3913 3.4874 21.0835 3.86709 21.0835H6.51566C6.89535 21.0835 7.20316 21.3913 7.20316 21.771C7.20316 22.2614 7.60353 22.6647 8.1048 22.6647C8.60607 22.6647 9.00644 22.2614 9.00644 21.771C9.00644 21.3913 9.31424 21.0835 9.69394 21.0835H12.8722C13.2519 21.0835 13.5597 21.3913 13.5597 21.771C13.5597 22.2614 13.9601 22.6647 14.4614 22.6647C14.9626 22.6647 15.363 22.2614 15.363 21.771C15.363 21.3913 15.6708 21.0835 16.0505 21.0835H18.6991C19.0788 21.0835 19.3866 21.3913 19.3866 21.771C19.3866 22.3279 19.7768 22.6905 20.2523 22.6657C20.4406 22.6558 20.6247 22.7237 20.7615 22.8535C20.8982 22.9833 20.9757 23.1636 20.9757 23.3522V24.9335C20.9757 25.3132 20.6679 25.621 20.2882 25.621H2.27795C1.89826 25.621 1.59045 25.3132 1.59045 24.9335V23.3522C1.59045 23.1636 1.66791 22.9833 1.80469 22.8535C1.94148 22.7237 2.12557 22.6558 2.31389 22.6657C2.78935 22.6905 3.17959 22.3279 3.17959 21.771ZM4.45467 22.4585C4.24153 23.1727 3.70106 23.7542 2.96545 23.9609V24.246H19.6007V23.9609C18.8651 23.7542 18.3246 23.1727 18.1115 22.4585H16.6317C16.3392 23.3769 15.4761 24.0397 14.4614 24.0397C13.4466 24.0397 12.5835 23.3769 12.291 22.4585H10.2751C9.98261 23.3769 9.11956 24.0397 8.1048 24.0397C7.09003 24.0397 6.22699 23.3769 5.93445 22.4585H4.45467Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29372 12.6161C5.27404 11.6221 8.03813 11.0689 11.283 11.0689C14.5278 11.0689 17.2919 11.6221 19.2722 12.6161C21.2462 13.607 22.5647 15.1133 22.5647 17.0272V17.548C22.5647 18.2255 22.0137 18.7689 21.3412 18.7689H1.22479C0.552216 18.7689 0.0012207 18.2255 0.0012207 17.548V17.0272C0.0012207 15.1133 1.31979 13.607 3.29372 12.6161ZM3.91056 13.845C2.1765 14.7154 1.37622 15.8445 1.37622 17.0272V17.3939H21.1897V17.0272C21.1897 15.8445 20.3895 14.7154 18.6554 13.845C16.9277 12.9778 14.3947 12.4439 11.283 12.4439C8.17128 12.4439 5.63823 12.9778 3.91056 13.845Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5528 8.64657C15.6825 8.51046 15.8624 8.43344 16.0504 8.43344H30.8824C31.0704 8.43344 31.2503 8.51046 31.38 8.64656C31.5097 8.78267 31.5781 8.96598 31.5691 9.1538L30.5579 30.2883L30.5579 30.2884C30.5131 31.2197 29.743 31.9459 28.8139 31.9459H19.2287C18.849 31.9459 18.5412 31.6381 18.5412 31.2584C18.5412 30.8787 18.849 30.5709 19.2287 30.5709C19.4373 30.5709 19.6006 30.4035 19.6006 30.2043V28.0959C19.6006 27.7162 19.9084 27.4084 20.2881 27.4084H20.7898C21.014 27.4084 21.1898 27.2286 21.1898 27.0138V26.0155C21.1898 25.8007 21.014 25.6209 20.7898 25.6209H20.2881C19.9084 25.6209 19.6006 25.3131 19.6006 24.9334V23.3522C19.6006 22.9864 19.887 22.6847 20.2523 22.6656C20.796 22.6373 21.1898 22.2138 21.1898 21.7709C21.1898 21.5705 21.0941 21.3437 20.9082 21.1587C20.7225 20.9739 20.493 20.8772 20.2881 20.8772C19.9084 20.8772 19.6006 20.5694 19.6006 20.1897V18.0814C19.6006 17.7017 19.9084 17.3939 20.2881 17.3939H21.1898V17.0272C21.1898 16.1766 20.7822 15.374 19.9225 14.6619C19.0526 13.9413 17.7435 13.3392 16.0458 12.9447C15.7462 12.8751 15.5294 12.615 15.5147 12.3079L15.3637 9.15381C15.3547 8.96599 15.423 8.78267 15.5528 8.64657ZM20.9369 30.5709H28.8139C29.0151 30.5709 29.1751 30.415 29.1844 30.2225C29.1844 30.2225 29.1845 30.2224 29.1845 30.2224L30.1612 9.80844H16.7716L16.8637 11.7315C18.4672 12.1583 19.8149 12.7873 20.7996 13.603C21.8967 14.5117 22.5648 15.6756 22.5648 17.0272V17.548C22.5648 18.2255 22.0138 18.7689 21.3412 18.7689H20.9756V19.6181C21.3219 19.7367 21.6309 19.9381 21.8781 20.184C22.2798 20.5837 22.5648 21.1475 22.5648 21.7709C22.5648 22.7996 21.8655 23.6094 20.9756 23.9126V24.2555C21.8661 24.3481 22.5648 25.0979 22.5648 26.0155V27.0138C22.5648 27.9315 21.8661 28.6813 20.9756 28.7738V30.2043C20.9756 30.3301 20.9623 30.4528 20.9369 30.5709Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5314 14.1488C21.3496 13.5426 22.3642 13.1772 23.4664 13.1772C26.1832 13.1772 28.3916 15.3698 28.3916 18.0814C28.3916 20.793 26.1832 22.9855 23.4664 22.9855C22.8018 22.9855 22.1699 22.8511 21.5939 22.6146C21.3018 22.4947 21.1289 22.1912 21.1748 21.8787L21.1828 21.8257C21.1851 21.8107 21.1866 21.7998 21.1877 21.7906C21.1894 21.7771 21.1897 21.7713 21.1898 21.7702C21.1896 21.57 21.0939 21.3435 20.9083 21.1587C20.7225 20.9739 20.493 20.8772 20.2881 20.8772C19.9085 20.8772 19.6006 20.5694 19.6006 20.1897V18.0814C19.6006 17.7017 19.9085 17.3939 20.2881 17.3939H21.1898V17.0272C21.1898 16.3648 20.9352 15.739 20.4233 15.1539C20.2969 15.0094 20.2367 14.8186 20.2572 14.6277C20.2777 14.4368 20.3772 14.2632 20.5314 14.1488ZM21.9411 14.8975C22.3387 15.5371 22.5648 16.2515 22.5648 17.0272V17.548C22.5648 18.2255 22.0138 18.7689 21.3412 18.7689H20.9756V19.6181C21.3219 19.7367 21.6309 19.9381 21.8781 20.1841C22.2181 20.5223 22.4745 20.9782 22.5452 21.4882C22.8394 21.5677 23.1479 21.6105 23.4664 21.6105C25.4302 21.6105 27.0166 20.0271 27.0166 18.0814C27.0166 16.1356 25.4302 14.5522 23.4664 14.5522C22.92 14.5522 22.4035 14.6765 21.9411 14.8975Z"
      fill="black"
    />
  </svg>
);

const OrderReceivableIcon = React.memo((props: IProps) => (
  <SvgNounOrder1591470000000 {...props} />
));

export default OrderReceivableIcon;
