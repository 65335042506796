import { Record } from "immutable";

export interface IUpdateResourcePayload {
  message: string;
  latest_update_time: number;
}

const initial: IUpdateResourcePayload = {
  message: "",
  latest_update_time: 0
};

export default class UpdateResourcePayload extends Record(initial) {
  public getMessage() {
    return this.get("message");
  }

  public getLatestUpdateTime() {
    return this.get("latest_update_time");
  }
}
