import { replace } from "connected-react-router";
import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetUserDataRequest,
  IGetUserDataResponse
} from "../../../apis/getUserData";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser, userLoggedIn } from "../actions";

export function* loginByTokenSaga() {
  try {
    const params: IGetUserDataRequest = {};
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_USER_DATA, params)
    );
    const {
      result,
      error
    }: { result: IGetUserDataResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && result.user_data !== null && !error) {
      yield put(updateUser(result.user_data));
      yield put(replace("/"));
      yield put(userLoggedIn());
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `ログインに失敗しました。`
      })
    );
  }
}
