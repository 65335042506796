import * as React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import Grid from "../atoms/Grid";
import TimeDisplay from "../molecules/TimeDisplay";

const Container = styled(Grid).attrs({
  container: true,
  justify: "space-between",
  align: "flex-end"
})``;

const TimeDisplayContainer = styled(Grid).attrs({ item: true, shrink: 1 })``;

const InvalidText = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-decoration-line: underline;
  color: ${colorsConst.MAIN};
  text-align: right;
`;

interface IProps {
  date: Date;
  canOrder: boolean;
  handleClick: () => void;
}

/**
 * カート内の受け取り時間表示
 */
const CartReceiveTime: React.FC<IProps> = React.memo(
  ({ date, canOrder, handleClick }) => {
    return (
      <Container>
        <Grid item>受取日時</Grid>
        <TimeDisplayContainer>
          <Grid container align="flex-end">
            <Grid item>
              {!canOrder ? (
                <InvalidText onClick={handleClick}>予約設定する</InvalidText>
              ) : (
                <TimeDisplay date={date} isActive />
              )}
            </Grid>
            {!canOrder ? undefined : <Grid item>目安</Grid>}
          </Grid>
        </TimeDisplayContainer>
      </Container>
    );
  },
  (prev, next) => prev.date.getTime() === next.date.getTime()
);

export default CartReceiveTime;
