import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgShareFacebook = (props: IProps) => (
  <svg width={30} height={30} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M28.345 0H1.655C.741 0 0 .741 0 1.655v26.69C0 29.259.741 30 1.655 30h14.38V18.398h-3.906v-4.541h3.905v-3.341c0-3.875 2.37-5.985 5.825-5.985a33.207 33.207 0 0 1 3.486.176v4.05h-2.38c-1.882 0-2.25.89-2.25 2.203v2.892h4.5l-.584 4.541H20.69V30h7.655c.914 0 1.655-.741 1.655-1.655V1.655C30 .741 29.259 0 28.345 0z"
        fill="#4267B2"
      />
      <path
        d="M20.883 30V18.677h4.064l.606-4.432h-4.67v-2.822c0-1.282.382-2.15 2.335-2.15h2.496V5.314a36.622 36.622 0 0 0-3.623-.172c-3.59 0-6.038 2.06-6.038 5.84v3.262H12v4.432h4.053V30h4.83z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default function FacebookIcon(props: IProps) {
  return <SvgShareFacebook {...props} />;
}
