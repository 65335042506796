import { goBack } from "connected-react-router";
import * as React from "react";
import { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import { userAccessedAreaPage } from "../../modules/search/actions";
import styled from "styled-components";
import Auth from "../organisms/Auth";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Loading from "../molecules/Loading";
import ListLink from "../molecules/ListLink";

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AreaTemplate: React.FC<IProps> = React.memo(props => {
  const { areaId, currentArea, currentSubAreaList, actions } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  const isLoading = useMemo(
    () => !currentArea.existsData() || currentArea.getId() !== areaId,
    [currentArea, areaId]
  );

  useEffect(() => {
    actions.userAccessedAreaPage(areaId);
  }, [actions, areaId]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title={
              !isLoading && currentArea.getName() !== ""
                ? currentArea.getName()
                : " "
            }
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody style={{ padding: 0 }}>
          {isLoading ? (
            <BlankContainer>
              <Loading />
            </BlankContainer>
          ) : (
            <ul>
              <ListLink link={`/area/${areaId}/all/`}>
                {currentArea.getName()}（すべて）
              </ListLink>
              {currentSubAreaList.map(subArea => (
                <ListLink
                  key={subArea.getId()}
                  link={`/area/${areaId}/subArea/${subArea.getId()}`}
                >
                  {subArea.getName()}
                </ListLink>
              ))}
            </ul>
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ id: string }>
) => ({
  areaId: parseInt(ownProps.match.params.id, 10),
  currentArea: state.search.getCurrentArea(),
  currentSubAreaList: state.search.getCurrentSubAreaList()
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public userAccessedAreaPage(areaId: number) {
    this.dispatch(userAccessedAreaPage(areaId));
  }

  public goBack() {
    this.dispatch(goBack());
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AreaTemplate)
);
