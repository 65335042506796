import { replace } from "connected-react-router";
import { call, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IRegisterGuestUserRequest,
  IRegisterGuestUserResponse
} from "../../../apis/registerGuestUser";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import Advertising from "../../../util/Advertising";
import Appsflyer from "../../../util/Appsflyer";
import LocalRepository from "../../../util/LocalRepository";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateLoginToken,
  updateInstallCoupon,
  updateUser,
  userLoggedIn
} from "../actions";

export default function* userTouchedGuestLoginButtonSaga() {
  yield put(setProcessingFlag(EProcessingFlag.SUBMIT_GUEST_LOGIN));
  try {
    const deviceToken = LocalRepository.getItem("deviceToken", "");

    const buildNumber: string | null = yield select<
      (state: ReduxModel) => string | null
    >(state => state.app.getAppVersion());

    let advertisingId: string | undefined;
    if (buildNumber !== null) {
      advertisingId = yield call(Advertising.getTrackingId, buildNumber);
    }

    const param: IRegisterGuestUserRequest = {
      device_token: deviceToken,
      device_id: typeof advertisingId !== "undefined" ? advertisingId : ""
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.REGISTER_GUEST_USER, param)
    );

    const {
      result,
      error
    }: { result: IRegisterGuestUserResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (result && !error) {
      // ゲストログインイベントの集計
      const appVersion: string | null = yield select<
        (state: ReduxModel) => string | null
      >(state => state.app.getAppVersion());
      const userId = result.user_data.id;
      if (appVersion && Appsflyer.isEnableVersion(appVersion)) {
        Appsflyer.trackEvent("menu_register_guest_user", userId, "");
      }
      yield put(updateLoginToken(result.token));
      yield put(updateInstallCoupon(result.install_coupon));
      yield put(updateUser(result.user_data));
      if (result.first_install_flag) {
        yield put(replace("/register/discountCodeAtFirstAccess"));
      } else {
        yield put(userLoggedIn());
        yield put(replace("/"));
      }
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `ゲストログインに失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.SUBMIT_GUEST_LOGIN));
  }
}
