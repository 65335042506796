import { fork, join, put, select } from "redux-saga/effects";
import {
  searchApiKey,
  SearchApiRequest,
  SearchApiResponse
} from "../../../apis";
import { AlertType } from "../../../records/Alert";
import { SearchSetting } from "../../../records/SearchSetting";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { SearchRadius } from "../../../records/SearchSetting/searchRadius";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert, systemOpenedModal } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateSearchResult,
  updateSearchSetting,
  UserTouchedSearchByMapLocationButtonAction
} from "../actions";
import fetchAddressByLocation from "./fetchAddressByLocation";

export default function* userTouchedSearchByMapLocationButtonSaga(
  action: UserTouchedSearchByMapLocationButtonAction
) {
  try {
    const { searchResultType, latlng } = action.payload;

    const fetchAddressByLocationTask = yield fork(() =>
      fetchAddressByLocation(latlng)
    );
    const address: string = yield join(fetchAddressByLocationTask);

    const distance: SearchRadius = yield select<
      (state: ReduxModel) => SearchRadius
    >(state =>
      state.search
        .getSearchSettings()
        .getData(searchResultType)
        .getSearchRadius()
    );

    const params: Partial<SearchApiRequest[typeof searchResultType]> = {
      latitude: `${latlng.lat}`,
      longitude: `${latlng.lng}`,
      distance
    };

    if (searchResultType === "reserve") {
      const receiveDatetime = yield select<(state: ReduxModel) => string>(
        state =>
          state.search
            .getSearchSettings()
            .getData(searchResultType)
            .getReservationTime()
            .getDatetimeString()
      );
      (params as SearchApiRequest["reserve"]).receive_datetime = receiveDatetime;
    }

    const commonApiTask = yield fork(() =>
      commonApiSaga(searchApiKey[searchResultType], params)
    );

    const {
      result,
      error
    }: {
      result: SearchApiResponse[typeof searchResultType];
      error: unknown;
    } = yield join(commonApiTask);

    if (result && !error) {
      const searchSetting: SearchSetting = yield select<
        (state: ReduxModel) => SearchSetting
      >(state => state.search.getSearchSettings().getData(searchResultType));

      yield put(
        updateSearchSetting(
          searchResultType,
          searchSetting.setSearchPlace(
            SearchPlace.fromMapLocation(address, latlng)
          )
        )
      );

      if (searchResultType === "fastest" && result.shop_list.length === 0) {
        yield put(systemOpenedModal("ZERO_RESULT", {}));
      }

      yield put(updateSearchResult(searchResultType, result.shop_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
