import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IUpdatePasswordForm,
  initialUpdatePasswordForm,
  validateUpdatePasswordForm
} from "../../forms/updatePassword";
import { userSubmittedUpdatePassword } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import ButtonAlter from "../atoms/ButtonAlter";
import LabelText from "../atoms/LabelText";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import TextField from "../organisms/FormFields/TextField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const UpdatePasswordTemplate: React.FC<
  IProps & InjectedFormProps<IUpdatePasswordForm>
> = React.memo(props => {
  const {
    submittingUpdatePassword,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialUpdatePasswordForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="パスワード" handleClickBack={handleClickBack} />
        }
        containerStyle={containerStyle}
        contentStyle={contentStyle}
      >
        <PageBody style={pageBodyStyle}>
          <div style={{ flexGrow: 1 }}>
            <form id={EFormID.UPDATE_PASSWORD} onSubmit={handleSubmitWrap}>
              <div>
                <LabelText htmlFor="inputPassword">
                  パスワード<span>※半角英数字８文字以上</span>
                </LabelText>
                <TextField
                  id="inputPassword"
                  name="password"
                  type="password"
                  placeholder="パスワード"
                  autoComplete="current-password"
                />
              </div>
              <div>
                <TextField
                  id="confirmPassword"
                  name="confirmation_password"
                  type="password"
                  placeholder="パスワード（確認）"
                />
              </div>
            </form>
          </div>
          <div style={{ flexGrow: 0 }}>
            <ButtonAlter
              block
              appearance={"secondary"}
              loading={submittingUpdatePassword}
              disabled={!valid}
              type="submit"
              form={EFormID.UPDATE_PASSWORD}
            >
              変更
            </ButtonAlter>
          </div>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingUpdatePassword: state.app.isConnectedApi(EApiKey.UPDATE_PASSWORD)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleSubmit() {
    dispatch(userSubmittedUpdatePassword());
  }
});

const reduxFormConfig = {
  form: EFormID.UPDATE_PASSWORD,
  initialValues: initialUpdatePasswordForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateUpdatePasswordForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IUpdatePasswordForm, IProps>(reduxFormConfig)(
    UpdatePasswordTemplate
  )
);
