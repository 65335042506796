import { goBack } from "connected-react-router";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { ReduxModel } from "../../reducer";
import Utility from "../../util/Utility";
import Button from "../atoms/Button";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";

const LittleNotesList = styled.ul`
  padding-top: 20px;
`;

const LittleNotesItem = styled.li`
  font-size: 1rem;
  color: #4e4e4e;
  & + & {
    padding-top: 8px;
  }
`;

const ButtonContainer = styled.div`
  & + & {
    margin-top: 14px;
  }
`;

const buttonStyle: React.CSSProperties = {
  fontSize: "1.4rem"
};

enum EInquiryCategory {
  SHOP_AND_MENU = "店舗・商品について",
  CREDIT_CARD_AND_SETTLEMENT = "クレジットカードの登録や決済について",
  BUG_REPORT = "不具合について",
  OPINION_AND_REQUESTS = "ご意見・ご要望"
}

const INQUIRY_CATEGORY_LIST = [
  EInquiryCategory.SHOP_AND_MENU,
  EInquiryCategory.CREDIT_CARD_AND_SETTLEMENT,
  EInquiryCategory.BUG_REPORT,
  EInquiryCategory.OPINION_AND_REQUESTS
];

const InquiryButton: React.FC<{
  inquiryCategory: EInquiryCategory;
  handleClick: (category: EInquiryCategory) => void;
  children?: never;
}> = ({ inquiryCategory, handleClick }) => {
  const handleClickWrap = useCallback(() => {
    handleClick(inquiryCategory);
  }, [inquiryCategory, handleClick]);

  return (
    <ButtonContainer>
      <Button block style={buttonStyle} onClick={handleClickWrap}>
        {inquiryCategory}
      </Button>
    </ButtonContainer>
  );
};

const getInquiryMailFormat = (
  category: EInquiryCategory,
  userEmail: string,
  userId: string
): IInquiryMailFormat => {
  const mailAddress = userEmail === "" ? "なし" : userEmail;
  switch (category) {
    case EInquiryCategory.SHOP_AND_MENU:
      return {
        to: "cs@menu.inc",
        subject: "店舗・商品について",
        body: `＜お客様へ＞
        注文されたお店や商品についてのお問い合わせはこちらに記載してください。
        店舗名：
        商品名：
        注文番号：
        お問い合わせ内容：
        
        

        ${Utility.generateInquiryMainBodyBottom(userId, mailAddress)}
        `
      };
    case EInquiryCategory.CREDIT_CARD_AND_SETTLEMENT:
      return {
        to: "cs@menu.inc",
        subject: "クレジットカードの登録や決済について",
        body: `＜お客様へ＞
        クレジットカードのお問い合わせはこちらに記載してください。
        お問い合わせ内容：
        
        
        ${Utility.generateInquiryMainBodyBottom(userId, mailAddress)}
        `
      };
    case EInquiryCategory.BUG_REPORT:
      return {
        to: "cs@menu.inc",
        subject: "不具合について",
        body: `＜お客様へ＞
        アプリの不具合についてはこちらに記載してください。
        不具合の内容：
        
        
        ${Utility.generateInquiryMainBodyBottom(userId, mailAddress)}
        `
      };
    case EInquiryCategory.OPINION_AND_REQUESTS:
      return {
        to: "cs@menu.inc",
        subject: "ご意見・ご要望",
        body: `＜お客様へ＞
        ご意見・ご要望の内容をこちらに記載してください。
        ご意見・ご要望内容：
        
        
        ${Utility.generateInquiryMainBodyBottom(userId, mailAddress)}
        `
      };
    default:
      return { to: "", subject: "", body: "" };
  }
};

interface IInquiryMailFormat {
  to: string;
  subject: string;
  body: string;
}

const mapStateToProps = (state: ReduxModel) => ({
  userId: state.user.getData().getId(),
  userEmail: state.user.getData().getEmail()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  children?: never;
}

const UserInquiryTemplate: React.FC<IProps> = React.memo(
  ({ userId, userEmail, actions }) => {
    const handleGoBack = useCallback(() => {
      actions.goBack();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions.goBack]);

    const handleClickInquiryButton = useCallback(
      (category: EInquiryCategory) => {
        const { to, subject, body } = getInquiryMailFormat(
          category,
          userEmail,
          userId
        );
        Utility.launchMailer(to, subject, body);
      },
      [userEmail, userId]
    );

    return (
      <Page
        header={
          <SubPageHeader
            title="お問い合わせ(メール)"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          {INQUIRY_CATEGORY_LIST.map(inquiryCategory => (
            <InquiryButton
              key={inquiryCategory}
              inquiryCategory={inquiryCategory}
              handleClick={handleClickInquiryButton}
            />
          ))}
          <LittleNotesList>
            <LittleNotesItem>
              ・受付時間は、平日11:00 ~ 18:00です。
            </LittleNotesItem>
            <LittleNotesItem>
              ・土日祝日は受付時間外となります。
            </LittleNotesItem>
            <LittleNotesItem>
              ・ご意見、ご要望につきましては、原則返信を差し上げておりませんので、予めご了承ください。
            </LittleNotesItem>
            <LittleNotesItem>
              ・不具合、トラブルのお問い合わせは、アプリ内告知を持ちまして回答と代えさせていただく場合がございます。
            </LittleNotesItem>
            <LittleNotesItem>
              ・下記のドメインのメールが届くように設定をお願いします。
              <br />
              「@menu.inc」
            </LittleNotesItem>
          </LittleNotesList>
        </PageBody>
      </Page>
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInquiryTemplate);
