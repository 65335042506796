import { List } from "immutable";
import { ellipsis } from "polished";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { userTouchedOpenDialogWithOrderNumber } from "../../modules/order/actions";
import OrderData from "../../records/OrderData";
import OrderItem from "../../records/OrderItem";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Utility from "../../util/Utility";

const Container = styled.div`
  background: #ffffff;
  margin: 8px 0;
  padding: 15px 16px 18px;
`;
const ShopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ShopMessage = styled.div`
  max-width: calc(100% - ${72 + 44 + 16 * 2}px);
  margin-right: 44px;
  padding-bottom: 9px;
`;

const Message = styled.div`
  font-size: 21px;
  line-height: 30px;
  color: #272727;
`;
const ShopName = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #9d9da2;
  padding-bottom: 4px;
  ${ellipsis()};
`;
const ShopImageContainer = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
  background: #c4c4c4;
`;
const ShopImage = styled.img`
  height: 100%;
`;
const Notice = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const ButtonFeedback = styled.div`
  letter-spacing: 0.1rem;
  padding: 9px 16px;
  font-weight: normal;
  color: #ffffff;
  background-color: ${colorsConst.MAIN};
  font-size: 1.4rem;
  line-height: 2rem;
  margin-right: 8px;
`;
const ButtonInquiry = styled.div`
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #272727;
  display: block;
  padding: 8px 16px;
  background: #f5f5f5;
`;

const mapStateToProps = (state: ReduxModel) => ({
  userEmail: state.user.getData().getEmail(),
  userId: state.user.getData().getId()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedOpenFeedbackDialogButton(orderNumber: string) {
    this.dispatch(
      userTouchedOpenDialogWithOrderNumber(orderNumber, "SEND_FEEDBACK")
    );
  }
}

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  orderData: OrderData;
}

const UnratedOrderItem: React.FC<IProps> = React.memo(
  ({ orderData, userId, userEmail, actions }) => {
    const shopInfo = orderData.getShopInfo();
    const orderDataItems = orderData.getItemList();

    const handleClickOpenFeedbackButton = useCallback(
      (selectedOrderNumber: string) => {
        actions.userTouchedOpenFeedbackDialogButton(selectedOrderNumber);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [actions.userTouchedOpenFeedbackDialogButton]
    );

    const handleClickSendInquiry = useCallback(
      (orderShop: string, orderItems: List<OrderItem>, orderNumber: string) => {
        const { to, subject, body } = OrderData.getInquiryMailToOrderFormat(
          userEmail,
          userId,
          orderShop,
          orderItems,
          orderNumber
        );
        Utility.launchMailer(to, subject, body);
      },
      [userEmail, userId]
    );

    return (
      <>
        <Container>
          <ShopContainer>
            <ShopMessage>
              <ShopName>{shopInfo.getShopName()}</ShopName>
              <Message>
                ご注文ありがとう
                <br />
                ございました。
              </Message>
            </ShopMessage>
            <ShopImageContainer>
              <ShopImage src={shopInfo.getPickupImagePath()} />
            </ShopImageContainer>
          </ShopContainer>
          <Notice>商品の調理が完了したので、受取可能です。</Notice>
          <ButtonContainer>
            <ButtonFeedback
              onClick={() =>
                handleClickOpenFeedbackButton(orderData.getOrderNumber())
              }
            >
              注文を評価
            </ButtonFeedback>
            <ButtonInquiry
              onClick={() =>
                handleClickSendInquiry(
                  shopInfo.getShopName(),
                  orderDataItems,
                  orderData.getOrderNumber()
                )
              }
            >
              サポートに連絡
            </ButtonInquiry>
          </ButtonContainer>
        </Container>
      </>
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnratedOrderItem);
