import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import { ReduxModel } from "../../reducer";
import zIndexConst from "../../styles/const/zIndexConst";
import UserAgent from "../../util/UserAgent";
import Navigation from "./Navigation";

const Container = styled.footer<{ hidden: boolean }>`
  position: relative;
  z-index: ${zIndexConst.FIXED + 1};
  display: ${p => (p.hidden ? "none" : "block")};
`;

const mapStateToProps = (state: ReduxModel) => ({
  disableFooter: state.app.isUserInputtingText() && UserAgent.isAndroid()
});

const mapDispatchToProps = null;

interface IProps extends ReturnType<typeof mapStateToProps> {}

const GlobalFooter = (props: IProps) => {
  const { disableFooter } = props;
  return (
    <Container hidden={disableFooter}>
      <Switch>
        <Route path="/search" component={Navigation} />
        <Route exact path="/" component={Navigation} />
        <Route exact path="/history" component={Navigation} />
        <Route exact path="/history/:id" component={Navigation} />
        <Route exact path="/user" component={Navigation} />
      </Switch>
    </Container>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalFooter);
