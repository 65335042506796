export enum EFormID {
  REGISTER_AGREEMENTS = "form/registerAgreements",
  REGISTER_NICK_NAME = "form/registerNickname",
  UPDATE_TEL = "form/updateTel",
  UPDATE_MAIL_ADDRESS_AND_PASSWORD = "form/updateMailAddressAndPassword",
  UPDATE_MAIL_ADDRESS = "form/updateMailAddress",
  UPDATE_PASSWORD = "form/updatePassword",
  FIX_TEL = "form/fixTel",
  INPUT_DISCOUNT_CODE = "form/inputDiscountCode",
  REGISTER_PERSONAL = "form/registerPersonal",
  REGISTER_PAYMENT = "form/registerPayment",
  AUTH_SMS = "form/authSms",
  AUTH_MAIL = "form/authMail",
  LOGIN = "form/login",
  RESET_PASSWORD = "form/resetPassword",
  KEYWORD_SEARCH = "form/keywordSearch",
  KEYWORD_SEARCH_FIELD = "form/keywordSearchField",
  REQUEST_AREA = "form/requestArea",
  REQUEST_SHOP = "form/requestShop"
}
