import { goBack } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IAddCreditCardRequest,
  IAddCreditCardResponse
} from "../../../apis/addCreditCard";
import { EFormID } from "../../../forms";
import {
  initialRegisterPaymentForm,
  IRegisterPaymentForm
} from "../../../forms/registerPayment";
import { AlertType } from "../../../records/Alert";
import { ICreditCardItem } from "../../../records/CreditCardItem";
import { ReduxModel } from "../../../reducer";
import Appsflyer from "../../../util/Appsflyer";
import Utility from "../../../util/Utility";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCreditCard } from "../actions";

export default function* userSubmittedRegisterNewCreditCardSaga() {
  try {
    yield put(setProcessingFlag(EProcessingFlag.SUBMIT_REGISTER_CREDIT_CARD));

    const formValues: IRegisterPaymentForm = yield select<
      (state: ReduxModel) => IRegisterPaymentForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.REGISTER_PAYMENT,
        initialRegisterPaymentForm
      )
    );

    const params: IAddCreditCardRequest = {
      card_number: formValues.credit,
      expire_month: `${formValues.periodMonth}`,
      expire_year: `${formValues.periodYear}`,
      code: formValues.secureCode
    };

    const commonApiTask = yield spawn(() =>
      commonApiSaga(EApiKey.ADD_CREDIT_CARD, params)
    );

    const {
      result,
      error
    }: { result: IAddCreditCardResponse; error: unknown } = yield join(
      commonApiTask
    );

    if (commonApiTask.isCancelled()) {
      yield put(
        removeProcessingFlag(EProcessingFlag.SUBMIT_REGISTER_CREDIT_CARD)
      );
      yield cancel();
    }

    if (result && !error) {
      // クレジットカード登録時のイベント集計
      const appVersion: string | null = yield select<
        (state: ReduxModel) => string | null
      >(state => state.app.getAppVersion());
      const userId: string = yield select<(state: ReduxModel) => string>(
        state => state.user.getData().getId()
      );
      if (
        userId !== "" &&
        appVersion &&
        Appsflyer.isEnableVersion(appVersion)
      ) {
        Appsflyer.trackEvent("menu_register_credit_card", userId, "");
      }
      yield put(destroy(EFormID.REGISTER_PAYMENT));
      const creditCardList = result.credit_card_list as ICreditCardItem[];
      const selectCardIndex = result.select_card_index;
      yield put(updateCreditCard(creditCardList, selectCardIndex));
      yield put(goBack());
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クレジットカード登録に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  } finally {
    yield put(
      removeProcessingFlag(EProcessingFlag.SUBMIT_REGISTER_CREDIT_CARD)
    );
  }
}
