import { goBack } from "connected-react-router";
import { useCallback, useMemo } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import { systemAddedAlert } from "../../modules/app/actions";
import { ILatLng } from "../../modules/search/model";
import { userSubmittedRegistrationPlace } from "../../modules/user/actions";
import { AlertType } from "../../records/Alert";
import LabeledPlace from "../../records/LabeledPlace";
import { ERegistrationPlace } from "../../records/PlaceListItem";
import { ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import InputSuggestionPlace from "../organisms/InputSuggestionPlace";

const pageBodyStyle: React.CSSProperties = {
  padding: 0
};

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ placeType: string }>
) => {
  const placeType = parseInt(
    ownProps.match.params.placeType,
    10
  ) as ERegistrationPlace;
  return {
    placeType,
    placeListItem: state.user.getData().getPlaceListItemByType(placeType),
    submitting: state.app.isConnectedApi(EApiKey.UPDATE_PLACE)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleSubmit(type: ERegistrationPlace, text: string, latLng: ILatLng) {
    dispatch(userSubmittedRegistrationPlace(type, text, latLng));
  },
  handleGoBack() {
    dispatch(goBack());
  },
  openErrorDialog() {
    dispatch(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "住所情報の取得に失敗しました",
        message: "入力項目や通信環境をご確認の上、再度お試しください"
      })
    );
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const UserInfoTemplate = React.memo((props: IProps) => {
  const {
    submitting,
    placeType,
    placeListItem,
    handleGoBack,
    handleSubmit,
    openErrorDialog
  } = props;

  const handleSubmitWrap = useCallback(
    (text: string, latLng: ILatLng) => {
      if (text.trim() === "") {
        return;
      }
      handleSubmit(placeType, text, latLng);
    },
    [handleSubmit, placeType]
  );

  const defaultValue = useMemo(() => {
    if (typeof placeListItem === "undefined") {
      return undefined;
    }
    return placeListItem.registered()
      ? LabeledPlace.create(
          placeListItem.getAddressText(),
          placeListItem.getLocation()
        )
      : undefined;
  }, [placeListItem]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="位置情報登録" handleClickBack={handleGoBack} />
        }
      >
        <PageBody style={pageBodyStyle}>
          <div style={{ padding: "8px 0" }}>
            <InputSuggestionPlace
              defaultValue={defaultValue}
              handleSubmit={handleSubmitWrap}
              openErrorDialog={openErrorDialog}
            />
          </div>
          {submitting ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              <div style={{ padding: "30px 0" }}>
                <Loading />
              </div>
              <div style={{ color: "#757575" }}>処理中です...</div>
            </div>
          ) : null}
        </PageBody>
      </Page>
    </Auth>
  );
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserInfoTemplate)
);
