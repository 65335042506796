import { goBack } from "connected-react-router";
import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdatePlaceRequest,
  IUpdatePlaceResponse
} from "../../../apis/updatePlace";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser, UserSubmittedRegistrationPlaceAction } from "../actions";

export default function* userSubmittedRegistrationPlaceSaga(
  action: UserSubmittedRegistrationPlaceAction
) {
  try {
    const {
      type,
      text,
      latLng: { lat, lng }
    } = action.payload;

    const params: IUpdatePlaceRequest = {
      type,
      address_text: text,
      latitude: `${lat}`,
      longitude: `${lng}`
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.UPDATE_PLACE, params)
    );

    const {
      result,
      error
    }: { result?: IUpdatePlaceResponse; error?: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(goBack());
      yield put(updateUser(result.user_data));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `位置情報登録に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
