import { push } from "connected-react-router";
import { is } from "immutable";
import { useCallback } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import { NAVIGATION_PATH_HOME } from "../../modules/app/model";
import CartData from "../../records/CartData";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";

export const CART_BUTTON_HEIGHT = 48;

const CartButtonContainer = styled.div`
  position: relative;
  padding: 16px;
  font-size: 16px;
  background-color: ${colorsConst.MAIN};
  color: #ffffff;
  text-align: center;
`;

const Total = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

interface IProps extends RouteComponentProps {
  children?: never;
}

const CartButton: React.FC<IProps> = React.memo(
  ({ location: { pathname } }) => {
    const cartList = useSelector(
      (state: ReduxModel) => state.order.getCartList(),
      (left, right) => is(left, right)
    );

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      if (pathname === NAVIGATION_PATH_HOME) {
        Analytics.logEvent("home_cart");
      } else {
        Analytics.logEvent("go_to_cart", {
          content_type: "pathname",
          item_id: `${pathname}`
        });
      }
      dispatch(push("/cart"));
    }, [pathname, dispatch]);

    return cartList.isEmpty() ? null : (
      <CartButtonContainer onClick={handleClick}>
        <span>カートを見る</span>
        <Total>{CartData.getTotalPreOrderCount(cartList)}個</Total>
      </CartButtonContainer>
    );
  }
);

export default withRouter(CartButton);
