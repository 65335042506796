import { push } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import spaceConst from "../../styles/const/spaceConst";
import minWidth from "../../styles/mixins/minWidth";
import Analytics from "../../util/Analytics";
import ButtonAlter from "../atoms/ButtonAlter";
import MenuLogo from "../atoms/MenuLogo";
import Page from "../atoms/Page";
import TopImage from "../atoms/TopImage/bg.png";
import { EProcessingFlag } from "../../modules/app/model";
import { ReduxModel } from "../../reducer";

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${TopImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -27px;
  text-align: center;
  ${minWidth.tablet`
    margin-top: -37px;
  `};
`;

const TopMenuLogo = styled(MenuLogo)`
  height: 54px;
  ${minWidth.tablet`
    height: 74px;
  `};
`;

const ButtonList = styled.ul`
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding: 0 25px;
`;

const ButtonListItem = styled.li`
  & + & {
    margin-top: ${spaceConst.GUTTER.BETWEEN_BUTTON};
  }
`;

interface IProps {
  actions: ActionDispatcher;
  submittingGuestLogin: boolean;
}

const TopTemplate = React.memo((props: IProps) => {
  const { actions, submittingGuestLogin } = props;

  const handleClickGuestLoginButton = useCallback(() => {
    actions.handleClickGuestLoginButton();
  }, [actions]);

  const handleClickLoginButton = useCallback(() => {
    actions.userTouchedLoginButton();
  }, [actions]);

  return (
    <Page>
      <TopContainer>
        <LogoContainer>
          <TopMenuLogo />
        </LogoContainer>
        <ButtonList>
          <ButtonListItem>
            <ButtonAlter
              appearance={"main"}
              block
              style={{ fontWeight: "bold" }}
              loading={submittingGuestLogin}
              onClick={handleClickGuestLoginButton}
            >
              はじめる
            </ButtonAlter>
          </ButtonListItem>
          <ButtonListItem>
            <Link to="/login">
              <ButtonAlter
                invert
                appearance={"main"}
                block
                style={{ fontWeight: "bold" }}
                disabled={submittingGuestLogin}
                onClick={handleClickLoginButton}
              >
                ログイン
              </ButtonAlter>
            </Link>
          </ButtonListItem>
        </ButtonList>
      </TopContainer>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingGuestLogin: state.app.isProcessing(
    EProcessingFlag.SUBMIT_GUEST_LOGIN
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedLoginButton() {
    Analytics.logEvent("top_login");
    this.dispatch(push("/login"));
  }

  public handleClickGuestLoginButton() {
    Analytics.logEvent("top_guestplay");
    this.dispatch(push("/register/agreements"));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopTemplate);
