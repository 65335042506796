import { fork, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import systemStartedPollingOrderDetailSaga from "./sagas/pollingOrderDetailSaga";
import systemRequestedFetchCartListSaga from "./sagas/systemRequestedFetchCartListSaga";
import userAccessedToCartSaga from "./sagas/userAccessedToCartSaga";
import userAccessedToPageThatNeedsOrderDetailSaga from "./sagas/userAccessedToPageThatNeedsOrderDetail";
import userAccessedToPageThatNeedsOrderHistorySaga from "./sagas/userAccessedToPageThatNeedsOrderHistorySaga";
import userAccessedToPreOrderPageSaga from "./sagas/userAccessedToPreOrderPageSaga";
import userChangedCartReservationTimeSage from "./sagas/userChangedCartReservationTimeSaga";
import userChangedDraftFeedbackPointSaga from "./sagas/userChangedDraftFeedbackPointSaga";
import userChangedDraftFeedbackTextSaga from "./sagas/userChangedDraftFeedbackTextSaga";
import userChangedOrderItemQuantitySaga from "./sagas/userChangedOrderItemQuantitySaga";
import userChangedPreOrderCountSaga from "./sagas/userChangedPreOrderCountSaga";
import userChangedPreOrderOptionListSaga from "./sagas/userChangedPreOrderOptionListSaga";
import userChangedPreOrderTextSaga from "./sagas/userChangedPreOrderTextSaga";
import userConfirmedOrderSaga from "./sagas/userConfirmedOrderSaga";
import userSubmittedAddToCartSaga from "./sagas/userSubmittedAddToCartSaga";
import userSubmittedCancelOrderSaga from "./sagas/userSubmittedCancelOrderSaga";
import userSubmittedFeedbackSaga from "./sagas/userSubmittedFeedbackSaga";
import userSubmittedOrderSaga from "./sagas/userSubmittedOrderSaga";
import userSubmittedUpdateCartSaga from "./sagas/userSubmittedUpdateCartSaga";
import userTouchedCloseDialogWithOrderNumberSaga from "./sagas/userTouchedCloseDialogWithOrderNumberSaga";
import userTouchedDeleteCartItemButtonSaga from "./sagas/userTouchedDeleteCartItemButtonSaga";
import userTouchedNotifyArrivalForLuxuryShopSaga from "./sagas/userTouchedNotifyArrivalForLuxuryShopSaga";
import userTouchedOpenDialogWithOrderNumberSaga from "./sagas/userTouchedOpenDialogWithOrderNumberSaga";
import userTouchedOpenSettingReservationTimeSaga from "./sagas/userTouchedOpenSettingReservationTimeSaga";
import userTouchedOrderButtonSaga from "./sagas/userTouchedOrderButtonSaga";
import userTouchedRequestClearCartButtonSaga from "./sagas/userTouchedRequestClearCartButtonSaga";
import userTouchedSendReceiptMailSaga from "./sagas/userTouchedSendReceiptMailSaga";
import userSelectedPaymentTypeSaga from "./sagas/userSelectedPaymentTypeSaga";

export default function* orderSaga() {
  yield fork(() => watchUserSubmittedAddToCartSaga());
  yield fork(() => watchUserTouchedDeleteCartItemButtonSaga());
  yield fork(() => watchUserSubmittedOrderSaga());
  yield fork(() => watchUserSubmittedUpdateCartSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsOrderHistorySaga());
  yield fork(() => watchUserAccessedToCartSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsOrderDetailSaga());
  yield fork(() => watchUserTouchedRequestClearCartButtonSaga());
  yield fork(() => watchUserSubmittedCancelOrderSaga());
  yield fork(() => watchSystemStartedPollingOrderDetailSaga());
  yield fork(() => watchUserAccessedToPreOrderPageSaga());
  yield fork(() => watchUserChangedPreOrderOptionListSaga());
  yield fork(() => watchUserChangedPreOrderCountSaga());
  yield fork(() => watchUserChangedPreOrderTextSaga());
  yield fork(() => watchUserChangedDraftFeedbackTextSaga());
  yield fork(() => watchUserChangedDraftFeedbackPointSaga());
  yield fork(() => watchUserSubmittedFeedback());
  yield fork(() => watchUserTouchedNotifyArrivalForLuxuryShopSaga());
  yield fork(() => watchUserTouchedOpenDialogWithOrderNumberSaga());
  yield fork(() => watchUserTouchedCloseDialogWithOrderNumberSaga());
  yield fork(() => watchUserTouchedSendReceiptMailSaga());
  yield fork(() => watchUserChangedOrderItemQuantitySaga());
  yield fork(() => watchSystemRequestedFetchCartListSaga());
  yield fork(() => watchUserChangedCartReservationTimeSaga());
  yield fork(() => watchUserTouchedOpenSettingReservationTimeDialogSaga());
  yield fork(() => watchUserTouchedOrderButtonSaga());
  yield fork(() => watchUserConfirmedOrderSaga());
  yield fork(() => watchUserSelectedPaymentTypeSaga());
}

function* watchUserConfirmedOrderSaga() {
  yield takeLatest(ActionTypes.U_CONFIRMED_ORDER, userConfirmedOrderSaga);
}

function* watchUserTouchedOrderButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_ORDER_BUTTON,
    userTouchedOrderButtonSaga
  );
}

function* watchUserTouchedOpenSettingReservationTimeDialogSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_OPEN_SETTING_RESERVATION_DIALOG_BUTTON,
    userTouchedOpenSettingReservationTimeSaga
  );
}

function* watchUserChangedCartReservationTimeSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_CART_RESERVATION_TIME,
    userChangedCartReservationTimeSage
  );
}

function* watchSystemRequestedFetchCartListSaga() {
  yield takeLatest(
    ActionTypes.S_REQUESTED_FETCH_CART_LIST,
    systemRequestedFetchCartListSaga
  );
}

function* watchUserChangedOrderItemQuantitySaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_ORDER_ITEM_QUANTITY,
    userChangedOrderItemQuantitySaga
  );
}

function* watchUserTouchedSendReceiptMailSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SEND_RECEIPT_MAIL,
    userTouchedSendReceiptMailSaga
  );
}

function* watchUserTouchedOpenDialogWithOrderNumberSaga() {
  yield takeEvery(
    ActionTypes.U_TOUCHED_OPEN_DIALOG_WITH_ORDER_NUMBER,
    userTouchedOpenDialogWithOrderNumberSaga
  );
}

function* watchUserTouchedCloseDialogWithOrderNumberSaga() {
  yield takeEvery(
    ActionTypes.U_TOUCHED_CLOSE_DIALOG_WITH_ORDER_NUMBER,
    userTouchedCloseDialogWithOrderNumberSaga
  );
}

function* watchUserTouchedNotifyArrivalForLuxuryShopSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_NOTIFY_ARRIVAL_FOR_LUXURY_SHOP,
    userTouchedNotifyArrivalForLuxuryShopSaga
  );
}

function* watchUserChangedDraftFeedbackTextSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_DRAFT_FEEDBACK_TEXT,
    userChangedDraftFeedbackTextSaga
  );
}

function* watchUserChangedDraftFeedbackPointSaga() {
  yield takeLatest(
    ActionTypes.U_CHANGED_DRAFT_FEEDBACK_POINT,
    userChangedDraftFeedbackPointSaga
  );
}

function* watchUserSubmittedFeedback() {
  yield takeLatest(ActionTypes.U_SUBMITTED_FEEDBACK, userSubmittedFeedbackSaga);
}

function* watchUserAccessedToPreOrderPageSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PRE_ORDER_PAGE,
    userAccessedToPreOrderPageSaga
  );
}

function* watchUserChangedPreOrderOptionListSaga() {
  yield takeEvery(
    ActionTypes.U_CHANGED_PRE_ORDER_OPTION_LIST,
    userChangedPreOrderOptionListSaga
  );
}

function* watchUserChangedPreOrderCountSaga() {
  yield takeEvery(
    ActionTypes.U_CHANGED_PRE_ORDER_COUNT,
    userChangedPreOrderCountSaga
  );
}

function* watchUserChangedPreOrderTextSaga() {
  yield takeEvery(
    ActionTypes.U_CHANGED_PRE_ORDER_TEXT,
    userChangedPreOrderTextSaga
  );
}

function* watchSystemStartedPollingOrderDetailSaga() {
  yield takeLatest(
    ActionTypes.S_STARTED_POLLING_ORDER_DETAIL,
    systemStartedPollingOrderDetailSaga
  );
}

function* watchUserSubmittedCancelOrderSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_CANCEL_ORDER,
    userSubmittedCancelOrderSaga
  );
}

function* watchUserTouchedRequestClearCartButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_REQUEST_CLEAR_CART_BUTTON,
    userTouchedRequestClearCartButtonSaga
  );
}

function* watchUserSubmittedAddToCartSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_ADD_TO_CART,
    userSubmittedAddToCartSaga
  );
}

function* watchUserTouchedDeleteCartItemButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_DELETE_CART_ITEM_BUTTON,
    userTouchedDeleteCartItemButtonSaga
  );
}

function* watchUserSubmittedOrderSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_ORDER, userSubmittedOrderSaga);
}

function* watchUserSubmittedUpdateCartSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_CART,
    userSubmittedUpdateCartSaga
  );
}

function* watchUserAccessedToPageThatNeedsOrderHistorySaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_HISTORY,
    userAccessedToPageThatNeedsOrderHistorySaga
  );
}

function* watchUserAccessedToPageThatNeedsOrderDetailSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_ORDER_DETAIL,
    userAccessedToPageThatNeedsOrderDetailSaga
  );
}

function* watchUserAccessedToCartSaga() {
  yield takeLatest(ActionTypes.U_ACCESSED_TO_CART, userAccessedToCartSaga);
}

function* watchUserSelectedPaymentTypeSaga() {
  yield takeLatest(
    ActionTypes.U_SELECTED_PAYMENT_TYPE,
    userSelectedPaymentTypeSaga
  );
}
