import { put } from "redux-saga/effects";
import { SearchResultType } from "../../../records/SearchResult";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import ReservationTime from "../../../records/ReservationTime";
import { updateSearchSetting } from "../actions";
import { SearchSetting } from "../../../records/SearchSetting";
import { ILatLng } from "../model";

export default function* updateSearchSettingSaga(
  searchResultType: SearchResultType,
  searchLocation: ILatLng,
  searchSetting: SearchSetting,
  searchPlace?: SearchPlace,
  reservationTime?: ReservationTime
) {
  yield put(
    updateSearchSetting(
      searchResultType,
      searchSetting.withMutations(s => {
        return [searchPlace, reservationTime].reduce<SearchSetting>(
          (acc, crr) => {
            if (typeof crr !== "undefined") {
              if (crr instanceof SearchPlace) {
                return acc.setSearchPlace(crr);
              }
              if (crr instanceof ReservationTime) {
                return acc.setReservationTime(crr);
              }
            }
            return acc;
          },
          s.setSelectedMapItem(undefined).setMapLocation(searchLocation)
        );
      })
    )
  );
}
