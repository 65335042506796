import React, { useCallback, useMemo, useState } from "react";
import { WrappedFieldsProps } from "redux-form";
import styled from "styled-components";
import ErrorText from "../../../components/atoms/ErrorText";
import Grid from "../../../components/atoms/Grid";
import LabelText from "../../../components/atoms/LabelText";
import colorsConst from "../../../styles/const/colorsConst";
import {
  rangeInt,
  fixBodyScrollTopWhenInputBlurred
} from "../../../util/Utility";
import TooltipIcon from "../../atoms/TooltipIcon";

const InputContainerStyle: React.CSSProperties = {
  height: "46px",
  background: "#ffffff",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
  padding: "4px 8px"
};

const SelectNumberContainer = styled.label`
  display: block;
  background-color: ${colorsConst.BACKGROUND};
`;

const SelectNumber = styled.select<{ isSelected: boolean }>`
  display: block;
  background-color: transparent;
  color: ${p => (p.isSelected ? "#000000" : "#CCCCCC")};
`;

const periodLabelStyle: React.CSSProperties = {
  fontSize: "20px",
  paddingLeft: "2px",
  paddingRight: "4px"
};

const placeholderOptionStyle: React.CSSProperties = {
  color: "#CCCCCC",
  display: "none"
};

const tooltipIconStyle: React.CSSProperties = {
  padding: "0 3px 0 13px"
};

interface IProps {
  handleTouchedTooltip: () => void;
}

const PeriodFields = (fields: WrappedFieldsProps & IProps) => {
  const { periodYear, periodMonth, handleTouchedTooltip, ...rest } = fields;

  const [selectedMonthFlag, setSelectedMonthFlag] = useState(false);
  const [selectedYearFlag, setSelectedYearFlag] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleBlurPeriodYearField = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      periodYear.input.onBlur(event.currentTarget.value);
      fixBodyScrollTopWhenInputBlurred();
    },
    [periodYear.input.onBlur]
  );

  const handleBlurPeriodMonthField = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      periodMonth.input.onBlur(event.currentTarget.value);
      fixBodyScrollTopWhenInputBlurred();
    },
    [periodMonth.input.onBlur]
  );

  const handleChangePeriodMonthField = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      periodMonth.input.onChange(event.currentTarget.value);
      setSelectedMonthFlag(true);
    },
    [periodMonth.input.onChange]
  );

  const handleChangePeriodYearField = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      periodYear.input.onChange(event.currentTarget.value);
      setSelectedYearFlag(true);
    },
    [periodYear.input.onChange]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const periodYearRange = useMemo(() => {
    return rangeInt(
      parseInt(
        new Date()
          .getFullYear()
          .toString()
          .substr(-2),
        10
      ),
      100
    );
  }, []);

  const periodMonthRange = useMemo(() => {
    return rangeInt(1, 13);
  }, []);

  return (
    <div>
      <LabelText>有効期限</LabelText>
      <Grid
        style={InputContainerStyle}
        container
        align="center"
        justify="space-between"
      >
        <Grid item rate={1} container align="center">
          <Grid item rate={1}>
            <SelectNumberContainer>
              <SelectNumber
                {...periodMonth.input}
                id="selectPeriodMonth"
                autoComplete="cc-exp-month"
                onBlur={handleBlurPeriodMonthField}
                onChange={handleChangePeriodMonthField}
                style={periodLabelStyle}
                isSelected={selectedMonthFlag}
                {...rest}
              >
                <option value="" disabled style={placeholderOptionStyle}>
                  MM
                </option>
                {periodMonthRange.map(month => (
                  <option key={month} value={String(month).padStart(2, "0")}>
                    {String(month).padStart(2, "0")}
                  </option>
                ))}
              </SelectNumber>
            </SelectNumberContainer>
          </Grid>
          <Grid item rate={0}>
            /
          </Grid>
          <Grid item rate={1}>
            <SelectNumberContainer>
              <SelectNumber
                {...periodYear.input}
                id="selectPeriodYear"
                autoComplete="cc-exp-year"
                onBlur={handleBlurPeriodYearField}
                onChange={handleChangePeriodYearField}
                style={periodLabelStyle}
                isSelected={selectedYearFlag}
                {...rest}
              >
                <option value="" disabled style={placeholderOptionStyle}>
                  YY
                </option>
                {periodYearRange.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectNumber>
            </SelectNumberContainer>
          </Grid>
          <Grid item rate={0}>
            <TooltipIcon
              style={tooltipIconStyle}
              onClick={handleTouchedTooltip}
            />
          </Grid>
        </Grid>
      </Grid>
      <ErrorText>
        {periodYear.meta.touched && periodYear.meta.error && (
          <div>※{periodYear.meta.error}</div>
        )}
        {periodMonth.meta.touched && periodMonth.meta.error && (
          <div>※{periodMonth.meta.error}</div>
        )}
      </ErrorText>
    </div>
  );
};

export default PeriodFields;
