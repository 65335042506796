import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  children?: never;
}

const SvgStar = (props: IProps) => {
  const { color, ...rest } = props;
  const fill = typeof color !== "undefined" ? color : "#BCBCBC";
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 17" fill="none" {...rest}>
      <path
        d="M17.316 6.342V6.27h-6.3L9.036.222h-.072L6.984 6.27h-6.3v.072l5.094 3.69-1.998 6.084.054.018L9 12.39l5.166 3.744.054-.018-1.998-6.084 5.094-3.69z"
        fill={fill}
      />
    </svg>
  );
};

const StarIcon: React.FC<IProps> = props => <SvgStar {...props} />;

export default StarIcon;
