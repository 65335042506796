import { fork, join, put } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import { UserAccessedToPageThatNeedsInitialSearchResultAction } from "../actions";
import fetchCurrentLocationSaga from "./fetchCurrentLocationSaga";
import getSearchResultSaga from "./getSearchResultSaga";
import requestGeolocationPermissionSaga from "./requestGeolocationPermissionSaga";

export default function* userAccessedToPageThatNeedsInitialSearchResultSaga(
  action: UserAccessedToPageThatNeedsInitialSearchResultAction
) {
  yield put(setProcessingFlag(EProcessingFlag.FETCHING_INITIAL_SEARCH));
  try {
    yield fork(() => requestGeolocationPermissionSaga());

    const fetchCurrentLocationTask = yield fork(() =>
      fetchCurrentLocationSaga()
    );
    yield join(fetchCurrentLocationTask);

    const getResultTask = yield fork(() =>
      getSearchResultSaga(action.payload.resultType)
    );
    yield join(getResultTask);
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.FETCHING_INITIAL_SEARCH));
  }
}
