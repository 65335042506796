import { ReducerActions } from "./reducer";
import { UserAndSystemActions } from "./userAndSystem";

export enum ActionTypes {
  U_STARTED_INPUTTING_TEXT = "app/U_START_INPUT_TEXT",
  U_FINISHED_INPUTTING_TEXT = "app/U_FINISHED_INPUT_TEXT",
  U_TOUCHED_GRANT_GEOLOCATION_PERMISSION_BUTTON = "search/U_TOUCHED_GRANT_GEOLOCATION_PERMISSION_BUTTON",
  S_ADDED_ALERT = "app/S_ADDED_ALERT",
  S_REMOVED_ALERT = "app/S_REMOVED_ALERT",
  S_LAUNCHED = "app/S_LAUNCHED",
  S_READY_FOR_USER_OPERATION = "app/S_READY_FOR_USER_OPERATION",
  S_STARTED_CONNECT_API = "app/S_STARTED_CONNECT_API",
  S_FINISHED_CONNECT_API = "app/S_FINISHED_CONNECT_API",
  S_DETECTED_RESOURCE_UPDATE = "app/S_DETECTED_RESOURCE_UPDATE",
  S_OPENED_MODAL = "app/S_OPENED_MODAL",
  S_CLOSED_MODAL = "app/S_CLOSED_MODAL",
  S_STARTED_PLAYING_PAGE_TRANSITION_ANIMATION = "app/S_STARTED_PLAYING_PAGE_TRANSITION_ANIMATION",
  S_FINISHED_PLAYING_PAGE_TRANSITION_ANIMATION = "app/S_FINISHED_PLAYING_PAGE_TRANSITION_ANIMATION",
  S_SET_FLAG = "app/S_SET_FLAG",
  S_UNSET_FLAG = "app/S_UNSET_FLAG",
  R_UPDATE_FLAG = "app/R_UPDATE_FLAG",
  R_SET_PROCESSING_FLAG = "app/R_SET_PROCESSING_FLAG",
  R_REMOVE_PROCESSING_FLAG = "app/R_REMOVE_PROCESSING_FLAG",
  R_REMOVED_ALERT = "app/R_REMOVED_ALERT",
  R_UPDATE_USER_INPUTTING_TEXT_FLAG = "app/R_UPDATE_USER_INPUTTING_TEXT_FLAG",
  R_OPEN_MODAL = "app/R_OPEN_MODAL",
  R_CLOSE_MODAL = "app/R_CLOSE_MODAL",
  R_SET_APP_VERSION = "app/R_SET_APP_VERSION",
  R_UPDATE_PLAYING_PAGE_TRANSITION_ANIMATION_FLAG = "app/R_UPDATE_PLAYING_PAGE_TRANSITION_ANIMATION_FLAG",
  R_LAUNCH = "app/R_LAUNCH",
  R_READY_FOR_USER_OPERATION = "app/R_READY_FOR_USER_OPERATION",
  R_UPDATE_CONNECTING_API_SET = "app/R_UPDATE_CONNECTING_API_SET",
  R_SET_MAINTENANCE_INFO = "app/R_SET_MAINTENANCE_INFO",
  R_SET_SHOULD_UPDATE_RESOURCE_FLAG = "app/R_SET_SHOULD_UPDATE_RESOURCE_FLAG",
  R_SET_UPDATE_BUILD_PAYLOAD = "app/R_SET_UPDATE_BUILD_PAYLOAD",
  R_SET_UPDATE_RESOURCE_PAYLOAD = "app/R_SET_UPDATE_RESOURCE_PAYLOAD"
}

export type AppActions = UserAndSystemActions | ReducerActions;
export * from "./userAndSystem";
export * from "./reducer";
