import React from "react";
import styled from "styled-components";

const ShortCopy = styled.div`
  font-size: 13px;
  line-height: ${19 / 13};
  color: #898989;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ShopCaption: React.FC<{ children?: string }> = React.memo(
  ({ children }) => {
    return <ShortCopy>【{children}】</ShortCopy>;
  }
);

export default ShopCaption;
