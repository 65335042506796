import { Set } from "immutable";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../../apis";
import { systemClosedModal } from "../../../modules/app/actions";
import {
  userAccessedToPageThatNeedsCategoryTagList,
  userSubmittedFilterSettings
} from "../../../modules/search/actions";
import CategoryTag from "../../../records/CategoryTag";
import {
  DEFAULT_MAX_DISPLAYABLE_COOKING_TIME,
  MaxDisplayableCookingTime
} from "../../../records/SearchSetting/cookingTime";
import { ReduxModel } from "../../../reducer";
import colorsConst from "../../../styles/const/colorsConst";
import Grid from "../../atoms/Grid";
import Loading from "../../molecules/Loading";
import Modal from "../../molecules/Modal";
import CookingTimeSlider from "./CookingTimeSlider";
import FilterCategoryTagItem from "./FilterCategoryTagItem";
import SettingFilterDialogHeader from "./SettingFilterDialogHeader";
import Analytics, {
  AnalyticsEventSearchFilterSet,
  AnalyticsEventSearchFilterReset,
  AnalyticsEventSearchFilterSetTag,
  AnalyticsEventSearchFilterUnsetTag
} from "../../../util/Analytics";

// ↓この辺あとで捨て（ます｜て下さい）..
const InstantButtonInner = styled.button<{ active?: boolean }>`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: ${p => (p.active ? "#81b826" : "#B5B5B6")};
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: ${23 / 16};
  text-align: center;
`;
const InstantButton = styled.div`
  flex: 1 1 50%;
  padding-right: 7px;
  & + & {
    padding-left: 7px;
    padding-right: 0;
  }
`;
const InstantButtonList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px 44px;
`;

const contentStyle: React.CSSProperties = {
  position: "relative",
  width: "80%",
  height: "100vh",
  marginLeft: "auto",
  backgroundColor: colorsConst.BACKGROUND
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
`;

const ScrollContainer = styled.div`
  flex: 1 1 auto;
  padding-top: 33px;
  padding-bottom: 33px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const SectionHead = styled.div`
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 12px;
  line-height: ${17 / 12};
  font-weight: 500;
  color: #727272;
`;

interface IProps {}

const SettingFilterDialog: React.FC<IProps> = React.memo(() => {
  const isOpenDialog = useSelector((state: ReduxModel) =>
    state.app.getModalManager().canDisplay("SETTING_FILTER")
  );

  const searchResultType = useSelector((state: ReduxModel) => {
    const modalPayload = state.app
      .getModalManager()
      .getPayload("SETTING_FILTER");
    return typeof modalPayload !== "undefined"
      ? modalPayload.searchResultType
      : undefined;
  });

  const maxDisplayableCookingTime = useSelector((state: ReduxModel) => {
    if (typeof searchResultType === "undefined") {
      return undefined;
    }
    const setting = state.search.getSearchSettings().getData(searchResultType);
    return setting.getMaxDisplayableCookingTime();
  });

  const [
    currentMaxDisplayableCookingTime,
    setCurrentMaxDisplayableCookingTime
  ] = useState<MaxDisplayableCookingTime>(
    typeof maxDisplayableCookingTime === "undefined"
      ? DEFAULT_MAX_DISPLAYABLE_COOKING_TIME
      : maxDisplayableCookingTime
  );

  const categoryTagList = useSelector((state: ReduxModel) => {
    return state.search.getCategoryTagList();
  });

  const fetchingCategoryTagList = useSelector((state: ReduxModel) => {
    return state.app.isConnectedApi(EApiKey.GET_CATEGORY_TAG_LIST);
  });

  const filterCategoryTagList = useSelector((state: ReduxModel) => {
    if (typeof searchResultType === "undefined") {
      return undefined;
    }
    const setting = state.search.getSearchSettings().getData(searchResultType);
    return setting.getFilterCategoryTagList();
  });

  const [
    currentFilterCategoryTagList,
    setCurrentFilterCategoryTagList
  ] = useState<Set<CategoryTag>>(() => {
    if (typeof filterCategoryTagList === "undefined") {
      return Set();
    }
    return filterCategoryTagList;
  });

  const dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(systemClosedModal("SETTING_FILTER"));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    if (typeof searchResultType === "undefined") {
      return;
    }
    const analyticsEventName = `search_${searchResultType}_filter_set` as AnalyticsEventSearchFilterSet;
    Analytics.logEvent(analyticsEventName);
    dispatch(
      userSubmittedFilterSettings(
        searchResultType,
        currentMaxDisplayableCookingTime,
        currentFilterCategoryTagList
      )
    );
  }, [
    currentFilterCategoryTagList,
    currentMaxDisplayableCookingTime,
    dispatch,
    searchResultType
  ]);

  const handleClickFilterCategoryTagItem = useCallback(
    (tag: CategoryTag) => {
      const prefix = `search_${searchResultType}_filter_`;
      setCurrentFilterCategoryTagList(s => {
        if (s.includes(tag)) {
          Analytics.logEvent(
            `${prefix}unset_tag` as AnalyticsEventSearchFilterUnsetTag,
            {
              content_type: "tag_id",
              item_id: `${tag.getId()}`
            }
          );
          return s.filter(i => i.getId() !== tag.getId());
        }
        Analytics.logEvent(
          `${prefix}set_tag` as AnalyticsEventSearchFilterSetTag,
          {
            content_type: "tag_id",
            item_id: `${tag.getId()}`
          }
        );
        return s.add(tag);
      });
    },
    [searchResultType]
  );

  const handleClickClearButton = useCallback(() => {
    // should use `useReducer`
    const analyticsEventName = `search_${searchResultType}_filter_reset` as AnalyticsEventSearchFilterReset;
    Analytics.logEvent(analyticsEventName);
    setCurrentMaxDisplayableCookingTime(DEFAULT_MAX_DISPLAYABLE_COOKING_TIME);
    setCurrentFilterCategoryTagList(Set());
  }, [searchResultType]);

  useLayoutEffect(() => {
    if (isOpenDialog) {
      setCurrentFilterCategoryTagList(() => {
        if (typeof filterCategoryTagList === "undefined") {
          return Set();
        }
        return filterCategoryTagList;
      });
      setCurrentMaxDisplayableCookingTime(() => {
        return typeof maxDisplayableCookingTime === "undefined"
          ? DEFAULT_MAX_DISPLAYABLE_COOKING_TIME
          : maxDisplayableCookingTime;
      });
    }
  }, [filterCategoryTagList, isOpenDialog, maxDisplayableCookingTime]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsCategoryTagList());
  }, [dispatch]);

  return typeof searchResultType === "undefined" ? null : (
    <Modal
      isOpen={isOpenDialog}
      handleClickBackdrop={closeDialog}
      contentStyle={contentStyle}
      align="flex-start"
    >
      <Container>
        <Grid item rate={0}>
          <SettingFilterDialogHeader />
        </Grid>
        <ScrollContainer>
          <Grid item rate={0}>
            <SectionHead>出来上がりまでの時間</SectionHead>
            <CookingTimeSlider
              currentSettingValue={currentMaxDisplayableCookingTime}
              handleChange={setCurrentMaxDisplayableCookingTime}
            />
          </Grid>
          <Grid item rate={1}>
            <SectionHead>ジャンル</SectionHead>
            <div style={{ padding: "0 15px" }}>
              {fetchingCategoryTagList ? (
                <Grid container align="center" justify="center">
                  <Loading />
                </Grid>
              ) : (
                <ul>
                  {categoryTagList.map(categoryTag => (
                    <li
                      key={categoryTag.getId()}
                      style={{ display: "inline-block", padding: "8px" }}
                    >
                      <FilterCategoryTagItem
                        tag={categoryTag}
                        active={currentFilterCategoryTagList.includes(
                          categoryTag
                        )}
                        handleClick={handleClickFilterCategoryTagItem}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Grid>
        </ScrollContainer>
        <Grid item rate={0}>
          <InstantButtonList>
            <InstantButton onClick={handleClickClearButton}>
              <InstantButtonInner>クリア</InstantButtonInner>
            </InstantButton>
            <InstantButton onClick={handleSubmit}>
              <InstantButtonInner active>決定</InstantButtonInner>
            </InstantButton>
          </InstantButtonList>
        </Grid>
      </Container>
    </Modal>
  );
});

export default SettingFilterDialog;
