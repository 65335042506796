import React, { useCallback, FocusEventHandler } from "react";
import styled from "styled-components";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";
import SearchIcon from "../atoms/SearchIcon";
import colorsConst from "../../styles/const/colorsConst";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 8px 9px 7px;
  background-color: ${colorsConst.BACKGROUND};
  border: 1px solid #c9caca;
  border-radius: 2px;
`;

const searchIconStyle: React.CSSProperties = {
  marginRight: "9px",
  flex: "0 0 auto",
  fontSize: "18px"
};

const Input = styled.input`
  flex: 1 1 auto;
`;

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  inputRef:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
}

const SearchField: React.FC<IProps> = React.memo(
  ({ onBlur, inputRef, ...rest }) => {
    const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      event => {
        if (typeof onBlur !== "undefined") {
          onBlur(event);
        }
        fixBodyScrollTopWhenInputBlurred();
      },
      [onBlur]
    );
    return (
      <Container>
        <SearchIcon color={"#727272"} style={searchIconStyle} />
        <Input onBlur={handleBlur} {...rest} ref={inputRef} />
      </Container>
    );
  }
);

export default SearchField;
