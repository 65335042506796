export enum EScrollTopKey {
  ORDER_HISTORY_LIST,
  SHOP_TEMPLATE,
  SHOP_TAB,
  CART_TEMPLATE,
  CAMPAING_TEMPLATE,
  TEXT_SEARCH_RESULT,
  REGISTER_PROGRESS
}

export default class ScrollTopMemory {
  public static save(key: string, value: number) {
    // 値が0の場合は保持しない
    if (value === 0) {
      return;
    }
    ScrollTopMemory.memory.set(key, value);
  }

  public static loadOrDefault(key: string, def = 0) {
    const scrollTop = ScrollTopMemory.memory.get(key);
    return typeof scrollTop !== "undefined" ? scrollTop : def;
  }

  public static clear(key: string) {
    ScrollTopMemory.memory.delete(key);
  }

  private static memory = new Map<string, number>();
}
