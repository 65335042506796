import { Record } from "immutable";

export interface IUserInfo {
  user_name: string;
  tel: string;
}

const initialValues: IUserInfo = {
  user_name: "",
  tel: ""
};

export default class UserInfo extends Record(initialValues) {
  public getUserName(): string {
    return this.get("user_name");
  }

  public getTel(): string {
    return this.get("tel");
  }
}
