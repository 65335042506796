import { List, Record } from "immutable";
import ReceptionTime, { IReceptionTime } from "./ReceptionTime";

export enum EReceptionType {
  OPEN = 1,
  STOP = 2,
  DELAYED = 3, // クライアント側で使う必要なし
  VISITABLE_TIME = 4 // クライアント側で使う必要なし
}

export interface IReceptionDatetimeProp {
  reception_type: EReceptionType;
  reception_time: List<ReceptionTime>;
  fastest_receive_time: string;
}

const initial: IReceptionDatetimeProp = {
  reception_type: EReceptionType.OPEN,
  reception_time: List(),
  fastest_receive_time: ""
};

export default class ReceptionDatetime extends Record(initial) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        reception_time: List(
          args.reception_time &&
            args.reception_time.map(
              (item: IReceptionTime) => new ReceptionTime(item)
            )
        )
      })
    );
  }

  public getReceptionTimeList() {
    return this.get("reception_time");
  }
}
