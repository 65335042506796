import { TransitionTimingFunctionProperty } from "csstype";
import React, { useMemo } from "react";
import { Transition } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";
import styled from "styled-components";

const Container = styled.div<{
  open: boolean;
  duration: number;
  easing?: TransitionTimingFunctionProperty;
}>`
  transition: all ${p => p.duration}s ${p => (p.easing ? p.easing : "ease")};
  opacity: ${p => (p.open ? 1 : 0)};
`;

interface IProps
  extends Pick<TransitionProps, Exclude<keyof TransitionProps, "timeout">> {
  durationSeconds: number;
  easing?: TransitionTimingFunctionProperty;
  style?: React.CSSProperties;
}

const Fade: React.FC<IProps> = React.memo(props => {
  const { children, easing, durationSeconds, style } = props;

  const durationMillis = useMemo(() => durationSeconds * 1000, [
    durationSeconds
  ]);

  return (
    <Transition timeout={durationMillis} in={props.in}>
      {status => {
        const open = status === "entering" || status === "entered";
        return (
          <Container
            duration={durationSeconds}
            easing={easing}
            open={open}
            style={style}
          >
            {children}
          </Container>
        );
      }}
    </Transition>
  );
});

export default Fade;
