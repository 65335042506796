import { css, FlattenSimpleInterpolation } from "styled-components";

interface IBreakPoints {
  desktop: number;
  tablet: number;
  phone: number;
}

const sizes: IBreakPoints = {
  desktop: 992,
  tablet: 768,
  phone: 576
};

type BreakPointCSSMap = {
  [K in keyof IBreakPoints]: (
    args: TemplateStringsArray
  ) => FlattenSimpleInterpolation
};

export default Object.keys(sizes)
  .map(i => i as keyof IBreakPoints)
  .reduce<BreakPointCSSMap>(
    (acc, label) => {
      acc[label] = (args: TemplateStringsArray) =>
        css`
          @media (min-width: ${sizes[label] / 16}em) {
            ${css(args)};
          }
        `;
      return acc;
    },
    {} as BreakPointCSSMap
  );
