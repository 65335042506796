import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetOrderDetailRequest,
  IGetOrderDetailResponse
} from "../../../apis/getOrderDetail";
import { AlertType } from "../../../records/Alert";
import { IOrderDataProps } from "../../../records/OrderData";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import getShopDataSaga from "../../search/sagas/getShopDataSaga";
import {
  updateCurrentOrderDetail,
  UserAccessedToPageThatNeedsOrderDetailAction
} from "../actions";

export default function* userAccessedToPageThatNeedsOrderDetailSaga(
  action: UserAccessedToPageThatNeedsOrderDetailAction
) {
  try {
    const params: IGetOrderDetailRequest = {
      order_no: action.payload.orderNumber
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_ORDER_DETAIL, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IGetOrderDetailResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      const orderData: IOrderDataProps = result.order_data;
      yield put(updateCurrentOrderDetail(orderData));
      yield fork(() => getShopDataSaga(orderData.shop_info.shop_id));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文履歴の取得に失敗しました。
          通信環境を確認の上、再度お試しください`
      })
    );
  }
}
