import { replace } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, fork, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { IUpdateTelRequest, IUpdateTelResponse } from "../../../apis/updateTel";
import { EFormID } from "../../../forms";
import { EProcessingFlag } from "../../app/model";
import { removeProcessingFlag, setProcessingFlag } from "../../app/actions";
import { initialUpdateTelForm, IUpdateTelForm } from "../../../forms/updateTel";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import Utility from "../../../util/Utility";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser } from "../actions";
import { publishSMSCodeSaga } from "./publishSMSCodeSaga";

export function* updateTelSaga() {
  const fetchingTask = yield fork(() => fetchingUpdateTelSaga());
  yield join(fetchingTask);
  if (fetchingTask.isCancelled()) {
    yield cancel();
  }
  yield put(replace("/register/auth"));
}

export function* registerTelAtFirstAccessSaga() {
  const fetchingTask = yield fork(() => fetchingUpdateTelSaga());
  yield join(fetchingTask);
  if (fetchingTask.isCancelled()) {
    yield cancel();
  }
  yield put(replace("/register/authAtFirstAccess"));
}

function* fetchingUpdateTelSaga() {
  try {
    yield put(setProcessingFlag(EProcessingFlag.SUBMIT_UPDATE_TEL));
    const formValues: IUpdateTelForm = yield select<
      (state: ReduxModel) => IUpdateTelForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.UPDATE_TEL,
        initialUpdateTelForm
      )
    );
    const params: IUpdateTelRequest = {
      tel: formValues.tel.replace(/-/g, "")
    };

    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_TEL,
      params
    );
    const {
      result,
      error
    }: {
      result: IUpdateTelResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));

      const publishSMSCodeTask = yield fork(() => publishSMSCodeSaga());
      const apiResult = yield join(publishSMSCodeTask);

      if (!apiResult) {
        yield cancel();
      }
      yield put(destroy(EFormID.UPDATE_TEL));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `電話番号登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
    yield cancel();
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.SUBMIT_UPDATE_TEL));
  }
}
