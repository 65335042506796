import { ActionTypes, SearchActions } from "./actions";
import SearchModel from "./model";

export default function reducer(
  model = new SearchModel(),
  action: SearchActions
): SearchModel {
  switch (action.type) {
    case ActionTypes.R_UPDATE_CURRENT_LOCATION:
      return model.updateCurrentLocation(action.payload.location);
    case ActionTypes.R_UPDATE_CURRENT_SHOP_DATA:
      return model.setCurrentShopData(action.payload.data);
    case ActionTypes.R_UPDATE_CURRENT_SHOP_ITEM_LIST:
      return model.setCurrentShopItemList(action.payload.itemList);
    case ActionTypes.R_UPDATE_SEARCH_HISTORY_LIST:
      return model.updateSearchHistoryList(action.payload.historyList);
    case ActionTypes.R_UPDATE_CURRENT_LOCATION_INQUIRY_STATUS:
      return model.setCurrentLocationInquiryStatus(action.payload.status);
    case ActionTypes.R_UPDATE_FETCHING_CURRENT_LOCATION_FLAG:
      return action.payload.started
        ? model.startFetchCurrentLocation()
        : model.finishFetchCurrentLocation();
    case ActionTypes.R_UPDATE_CATEGORY_TAG_LIST:
      return model.setCategoryTagList(action.payload.list);
    case ActionTypes.R_UPDATE_HOME_CATEGORY_TAG_LIST:
      return model.setHomeCategoryTagList(action.payload.list);
    case ActionTypes.R_UPDATE_SHOP_FAVORITE_STATE:
      return model.updateShopFavoriteState(
        action.payload.shopId,
        action.payload.bool
      );
    case ActionTypes.R_UPDATE_SEARCH_QUERY:
      return model.setCurrentSearchQuery(action.payload.query);
    case ActionTypes.R_UPDATE_HOME_LIST:
      return model.updateHomeList(action.payload.list);
    case ActionTypes.R_UPDATE_AREA_LIST:
      return model.setAreaList(action.payload.list);
    case ActionTypes.R_UPDATE_SEARCH_SETTING:
      return model.updateSearchSettings(
        action.payload.resultType,
        action.payload.searchSetting
      );
    case ActionTypes.R_UPDATE_SEARCH_RESULT:
      return model.updateSearchResultByType(
        action.payload.resultType,
        action.payload.list
      );
    case ActionTypes.R_UPDATE_CURRENT_SEARCH_RESULT_TYPE:
      return model.updateCurrentSearchResultType(action.payload.type);
    case ActionTypes.R_INIT_RESERVE_SEARCH_SETTING:
      return model.initReserveSearchSetting();
    case ActionTypes.R_UPDATE_ONCE_SEARCHED:
      return model.setOnceSearchedFlag(action.payload.type);
    case ActionTypes.R_UPDATE_CURRENT_AREA:
      return model.updateCurrentArea(action.payload.area);
    case ActionTypes.R_UPDATE_CURRENT_SUB_AREA_LIST:
      return model.updateCurrentSubAreaList(action.payload.list);
    case ActionTypes.R_UPDATE_CURRENT_SUB_AREA:
      return model.updateCurrentSubArea(action.payload.subArea);
    case ActionTypes.R_UPDATE_CURRENT_AREA_SHOP_LIST:
      return model.updateCurrentAreaShopList(action.payload.list);
    case ActionTypes.R_UPDATE_CURRENT_CATEGORY_TAG:
      return model.updateCurrentCategoryTag(action.payload.categoryTag);
    case ActionTypes.R_UPDATE_CURRENT_CATEGORY_SHOP_LIST:
      return model.updateCurrentCategoryShopList(action.payload.list);
    case ActionTypes.R_UPDATE_NEAR_OFFICE_SHOP_LIST:
      return model.updateNearOfficeShopList(action.payload.list);
    case ActionTypes.R_UPDATE_CURRENT_HOME_SHOP_LIST:
      return model.updateCurrentHomeList(action.payload.list);
    default:
      return model;
  }
}
