import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import {
  userChangedDraftFeedbackPoint,
  userChangedDraftFeedbackText,
  userSubmittedFeedback,
  userTouchedCloseDialogWithOrderNumber
} from "../../modules/order/actions";
import {
  DraftFeedbackPointMap,
  DraftFeedbackTextMap
} from "../../modules/order/model";
import OrderData from "../../records/OrderData";
import { ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import Button from "../atoms/Button";
import Dialog, { DialogBody } from "../atoms/Dialog";
import Modal from "../molecules/Modal";
import FeedbackContent from "./FeedbackContent";

const feedbackDialogStyle: React.CSSProperties = {
  maxHeight: "80vh"
};

const contentStyle: React.CSSProperties = {
  margin: "35px"
};
const closeButtonStyle: React.CSSProperties = {
  paddingTop: "23px",
  color: "#838383",
  textAlign: "center"
};

interface IProps {
  orderData: OrderData;
  openFeedbackDialogFlag: boolean;
  submittingFeedback: boolean;
  draftFeedbackText: DraftFeedbackTextMap;
  draftFeedbackPoint: DraftFeedbackPointMap;
  actions: ActionDispatcher;
}

const FeedbackDialog: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    orderData,
    openFeedbackDialogFlag,
    submittingFeedback,
    draftFeedbackText,
    draftFeedbackPoint,
    actions
  } = props;

  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const handleChangeComment = useCallback(
    (currentText: string) => {
      setComment(currentText);
      actions.userChangedDraftFeedbackText(
        orderData.getOrderNumber(),
        currentText
      );
    },
    [actions, orderData]
  );

  const handleChangeRating = useCallback(
    (currentPoint: number) => {
      setRating(currentPoint);
      actions.userChangedDraftFeedbackPoint(
        orderData.getOrderNumber(),
        currentPoint
      );
    },
    [actions, orderData]
  );

  const handleCloseFeedback = useCallback(() => {
    actions.userTouchedCloseFeedbackDialogButton();
  }, [actions.userTouchedCloseFeedbackDialogButton]);

  const canSubmitFeedback = useMemo(() => {
    return rating !== 0;
  }, [rating]);

  const handleSubmitFeedback = useCallback(
    (orderNumber: string) => {
      if (canSubmitFeedback) {
        actions.userSubmittedFeedback(orderNumber, rating, comment);
        Analytics.logEvent("eval_submit", {
          content_type: "shop_id",
          item_id: `${orderData.getShopInfo().getId()}`
        });
      }
    },
    [canSubmitFeedback, actions, rating, comment, orderData]
  );

  const feedbackPoint = useMemo(() => {
    return draftFeedbackPoint.get<number>(
      orderData.getOrderNumber(),
      orderData.getFeedbackPoint()
    );
  }, [draftFeedbackPoint, orderData]);

  const feedbackText = useMemo(() => {
    return draftFeedbackText.get<string>(
      orderData.getOrderNumber(),
      orderData.getFeedbackText()
    );
  }, [draftFeedbackText, orderData]);

  useEffect(() => {
    setRating(feedbackPoint);
    setComment(feedbackText);
  }, [feedbackPoint, feedbackText]);

  useEffect(() => {
    if (comment !== "") {
      Analytics.logEvent("eval_comment", {
        content_type: "shop_id",
        item_id: `${orderData.getShopInfo().getId()}`
      });
    }
  }, [comment, orderData]);

  useEffect(() => {
    if (openFeedbackDialogFlag) {
      Analytics.logEvent("eval_", {
        content_type: "shop_id",
        item_id: `${orderData.getShopInfo().getId()}`
      });
    }
  }, [openFeedbackDialogFlag, orderData]);

  return (
    <Modal
      isOpen={openFeedbackDialogFlag}
      contentStyle={contentStyle}
      handleClickBackdrop={handleCloseFeedback}
    >
      <Dialog style={feedbackDialogStyle}>
        <DialogBody>
          <FeedbackContent
            shopInfo={orderData.getShopInfo()}
            rating={rating}
            comment={comment}
            handleChangeComment={handleChangeComment}
            handleChangeRating={handleChangeRating}
          />
        </DialogBody>
        <DialogBody>
          <Button
            block
            tertiary
            positionFooter
            onClick={() => handleSubmitFeedback(orderData.getOrderNumber())}
            disabled={!canSubmitFeedback}
            loading={submittingFeedback}
          >
            送信
          </Button>
        </DialogBody>
        <div style={closeButtonStyle} onClick={handleCloseFeedback}>
          閉じる
        </div>
      </Dialog>
    </Modal>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  openFeedbackDialogFlag: state.app
    .getModalManager()
    .canDisplay("SEND_FEEDBACK"),
  submittingFeedback: state.app.isConnectedApi(EApiKey.SEND_FEEDBACK),
  draftFeedbackText: state.order.getDraftFeedbackText(),
  draftFeedbackPoint: state.order.getDraftFeedbackPoint()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedCloseFeedbackDialogButton() {
    this.dispatch(userTouchedCloseDialogWithOrderNumber(null, "SEND_FEEDBACK"));
  }

  public userChangedDraftFeedbackText(orderNumber: string, text: string) {
    this.dispatch(userChangedDraftFeedbackText(orderNumber, text));
  }

  public userChangedDraftFeedbackPoint(orderNumber: string, point: number) {
    this.dispatch(userChangedDraftFeedbackPoint(orderNumber, point));
  }

  public userSubmittedFeedback(
    orderNumber: string,
    point: number,
    text: string
  ) {
    this.dispatch(userSubmittedFeedback(orderNumber, point, text));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDialog);
