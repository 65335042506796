import React, { useMemo } from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import UpdateBuildPayload from "../../records/UpdateBuildPayload";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Dialog, { DialogBody, DialogFooter } from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";

declare var location: Location;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
`;

const modalStyle: React.CSSProperties = {
  padding: "35px"
};

interface IProps {
  openShouldUpdateBuildModal: boolean;
  updateBuildPayload: UpdateBuildPayload;
  children?: never;
}

const ShouldUpdateBuildModal: React.FC<IProps> = React.memo(props => {
  const { openShouldUpdateBuildModal, updateBuildPayload } = props;

  const storeUrl = useMemo(() => {
    return updateBuildPayload.getStoreUrl();
  }, [updateBuildPayload]);

  const handleClickGotoStoreButton = useCallback(() => {
    location.href = storeUrl;
  }, [storeUrl]);

  return openShouldUpdateBuildModal ? (
    <Container>
      <Modal isOpen={true} innerStyle={modalStyle}>
        <Dialog>
          <DialogBody>
            <Notes>
              新しいバージョンがあります
              <br />
              アプリを最新バージョンに更新してください
            </Notes>
          </DialogBody>
          <DialogFooter>
            <Button block onClick={handleClickGotoStoreButton}>
              ストアを確認
            </Button>
          </DialogFooter>
        </Dialog>
      </Modal>
    </Container>
  ) : null;
});

const mapStateToProps = (state: ReduxModel) => ({
  openShouldUpdateBuildModal: state.app
    .getModalManager()
    .canDisplay("SHOULD_UPDATE_BUILD"),
  updateBuildPayload: state.app.getUpdateBuildPayload()
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShouldUpdateBuildModal);
