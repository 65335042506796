import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMapPinFillIcon = (props: IProps) => {
  const fillColor = props.fill ? props.fill : "inherit";
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M12,0C7.2,0,3.2,3.7,3.2,8.3S12,24,12,24s8.8-11.2,8.8-15.7C20.8,3.7,16.8,0,12,0z M12,12c-2.2,0-4-1.6-4-3.7s1.8-3.7,4-3.7 s4,1.6,4,3.7S14.2,12,12,12z"
        fill={fillColor}
      />
    </svg>
  );
};

const MapPinFillIcon: React.FC<IProps> = React.memo(props => (
  <SvgMapPinFillIcon {...props} />
));

export default MapPinFillIcon;
