import { put } from "redux-saga/effects";
import { systemClosedModal } from "../../app/actions";
import {
  updateOrderNumberForDialog,
  UserTouchedOpenDialogWithOrderNumberAction
} from "../actions";

export default function* userTouchedCloseDialogWithOrderNumberSaga(
  action: UserTouchedOpenDialogWithOrderNumberAction
) {
  yield put(updateOrderNumberForDialog(action.payload.orderNumber));
  yield put(systemClosedModal(action.payload.modalKey));
}
