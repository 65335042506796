import CustomError from "./CustomError";

class UndefinedEnvError extends CustomError {
  constructor(envName: string) {
    super(`${envName} is undefined`);
  }
}

export default class EnvUtil {
  public static getImageDomain(): string {
    const env = process.env.REACT_APP_IMAGE_DOMAIN;
    if (typeof env === "undefined") {
      throw new UndefinedEnvError("REACT_APP_IMAGE_DOMAIN");
    }
    return env;
  }

  public static withImageDomain(imagePath: string): string {
    return `${EnvUtil.getImageDomain()}${imagePath}`;
  }
}
