import { Record } from "immutable";
import { SearchResultType } from "./SearchResult";
export type IOnceSearched = { [key in SearchResultType]: boolean };

const initial: IOnceSearched = {
  fastest: false,
  reserve: false,
  keyword: false
};

export default class OnceSearched extends Record(initial) {
  public static asDefault() {
    return new OnceSearched();
  }

  private constructor(args: any = {}) {
    super(args);
  }

  public isOnceSearched(type: SearchResultType) {
    return this.get(type);
  }

  public setOnceSearched(type: SearchResultType) {
    return this.set(type, true);
  }
}
