import styled from "styled-components";

const LabelText = styled.label`
  display: block;
  padding-bottom: 13px;
  white-space: nowrap;
  cursor: pointer;
  letter-spacing: 0.03em;
  color: #464646;
  font-weight: bold;
`;

export default LabelText;
