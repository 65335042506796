import { Cordova } from "../Cordova";
import Utility from "./Utility";

declare var cordova: Cordova;

export type AppsflyerEvent =
  | "menu_register_guest_user"
  | "menu_register_id"
  | "menu_register_credit_card"
  | "menu_add_cart"
  | "menu_order"
  | "menu_order_first"
  | "menu_order_repeat";

export default class Appsflyer {
  public static trackEvent(
    eventName: AppsflyerEvent,
    userId: string,
    value: string
  ) {
    if (Appsflyer.enabled()) {
      cordova.plugins.appsflyer.trackEvent(eventName, userId, value);
    }
  }

  public static trackOrderEvent(
    userPaymentPrice: number,
    userId: string,
    isFirstOrder: boolean,
    appVersion: string | null
  ) {
    if (
      userId !== "" &&
      appVersion !== null &&
      Appsflyer.isEnableVersion(appVersion)
    ) {
      const secondEventName = isFirstOrder
        ? "menu_order_first"
        : "menu_order_repeat";
      Appsflyer.trackEvent("menu_order", userId, `${userPaymentPrice}`);
      Appsflyer.trackEvent(secondEventName, userId, `${userPaymentPrice}`);
    }
  }

  public static isEnableVersion(appVersion: string) {
    return Utility.isSameOrNewerVersion("1.7.0", appVersion);
  }

  public static getDeviceId(): Promise<string | number | undefined> {
    return new Promise<string | number | undefined>((resolve, reject) => {
      if (Appsflyer.enabled()) {
        cordova.plugins.appsflyer.getDeviceId(
          (deviceId: string | number) => {
            resolve(deviceId);
          },
          () => reject(undefined)
        );
      } else {
        resolve(undefined);
      }
    });
  }

  private static enabled() {
    return (
      !Utility.isDebug() &&
      (typeof cordova !== "undefined" &&
        typeof cordova.plugins !== "undefined" &&
        typeof cordova.plugins.appsflyer !== "undefined")
    );
  }
}
