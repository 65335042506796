import React from "react";
import { connect } from "react-redux";
import { EProcessingFlag } from "../../modules/app/model";
import { ReduxModel } from "../../reducer";
import LoadingDialog from "./LoadingDialog";

const mapStateToProps = (state: ReduxModel) => ({
  submittingOrder: state.app.isProcessing(EProcessingFlag.SUBMIT_ORDER)
});

interface IProps extends ReturnType<typeof mapStateToProps> {}

const SubmittingOrderDialog: React.FC<IProps> = React.memo(
  ({ submittingOrder }) => {
    return (
      <LoadingDialog
        isOpen={submittingOrder}
        statusText={"送信しています..."}
      />
    );
  }
);

export default connect(mapStateToProps)(SubmittingOrderDialog);
