import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import colorsConst from "../../../styles/const/colorsConst";
import Loading from "../../molecules/Loading";
import { ButtonType } from "./";

interface IContainerProps {
  appearance: ButtonType;
  disabled?: boolean;
  block?: boolean;
}

const getTextColor = (p: IContainerProps) => {
  if (p.disabled) {
    return "#fff";
  } else {
    switch (p.appearance) {
      case "main":
        return colorsConst.MAIN;
      case "sub":
        return "#000";
      default:
        return colorsConst.BUTTON.TEXT.DEFAULT;
    }
  }
};

const getBackgroundColor = (p: IContainerProps) => {
  if (p.disabled) {
    return "#C9CACA";
  } else {
    switch (p.appearance) {
      case "main":
        return "#fff";
      case "secondary":
        return "#fff";
      case "sub":
        return "#EFEFEF";
      default:
        return "#fff";
    }
  }
};

const getBorderColor = (p: IContainerProps) => {
  switch (p.appearance) {
    case "main":
      return colorsConst.MAIN;
    case "secondary":
      return colorsConst.SECONDARY;
    case "sub":
      return "#EFEFEF";
    default:
      return colorsConst.MAIN;
  }
};

interface IProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IContainerProps {
  loading?: boolean;
}

const Container = styled.button<IProps>`
  display: ${p => (p.block ? "block" : "inline-block")};
  width: ${p => (p.block ? "100%" : "auto")};
  padding: 20px;
  font-size: 14px;
  border-radius: 4px;
  touch-action: manipulation;
  color: ${getTextColor};
  background-color: ${getBackgroundColor};
  &:active {
    color: ${p => lighten(0.25, getTextColor(p))};
  }
  border: 1px solid ${p => getBorderColor(p)};
`;

const ButtonInvert: React.FC<IProps> = React.memo(
  ({ loading, children, disabled, appearance = "main" as const, ...rest }) => {
    return (
      <Container {...{ appearance }} {...rest} disabled={loading || disabled}>
        {loading ? <Loading /> : children}
      </Container>
    );
  }
);

export default ButtonInvert;
