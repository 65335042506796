import { replace } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdateMailAddressAndPasswordRequest,
  IUpdateMailAddressAndPasswordResponse
} from "../../../apis/updateMailAddressAndPassword";
import { EFormID } from "../../../forms";
import {
  IUpdateMailAddressAndPasswordForm,
  initialUpdateMailAddressAndPasswordForm
} from "../../../forms/updateMailAddressAndPassword";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import Appsflyer from "../../../util/Appsflyer";
import Utility from "../../../util/Utility";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser } from "../actions";

export default function* updateMailAddressAndPasswordSaga() {
  try {
    const formValues: IUpdateMailAddressAndPasswordForm = yield select<
      (state: ReduxModel) => IUpdateMailAddressAndPasswordForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.UPDATE_MAIL_ADDRESS_AND_PASSWORD,
        initialUpdateMailAddressAndPasswordForm
      )
    );
    const params: IUpdateMailAddressAndPasswordRequest = {
      mail: formValues.mail,
      password: formValues.password,
      confirmation_password: formValues.confirmation_password
    };

    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_MAIL_ADDRESS_AND_PASSWORD,
      params
    );
    const {
      result,
      error
    }: {
      result: IUpdateMailAddressAndPasswordResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));

      yield put(destroy(EFormID.UPDATE_MAIL_ADDRESS_AND_PASSWORD));
      const appVersion: string | null = yield select<
        (state: ReduxModel) => string | null
      >(state => state.app.getAppVersion());
      const userId: string = yield select<(state: ReduxModel) => string>(
        state => state.user.getData().getId()
      );
      if (
        userId !== "" &&
        appVersion &&
        Appsflyer.isEnableVersion(appVersion)
      ) {
        Appsflyer.trackEvent("menu_register_id", userId, "");
      }
      yield put(replace("/user/info"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
