import { push } from "connected-react-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { systemOpenedModal } from "../../modules/app/actions";
import {
  systemStartedPollingOrderDetail,
  systemStoppedPollingOrderDetail,
  userAccessedToPageThatNeedsOrderDetail
} from "../../modules/order/actions";
import OrderData from "../../records/OrderData";
import Price from "../../records/Price";
import ShopData from "../../records/ShopData";
import { ReduxAction, ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Utility from "../../util/Utility";
import ArrowIcon from "../atoms/ArrowIcon";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";
import HeaderContainer from "../atoms/HeaderContainer";
import MapPinIcon from "../atoms/MapPinIcon";
import MultiLine from "../atoms/MultiLine";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import TimeDisplay from "../molecules/TimeDisplay";
import Auth from "../organisms/Auth";
import CreditCardInfo from "../organisms/CreditCardInfo";
import MapViewer from "../organisms/MapViewer";
import OrderItemViewer from "../organisms/OrderItemViewer";
import OrderNotice from "../organisms/OrderNotice";
import OrderProcess from "../organisms/OrderProcess";
import PromotionCodeInfo from "../organisms/PromotionCodeInfo";
import ShopImageMarker from "../organisms/ShopImageMarker";

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CancelButtonContainer = styled.div`
  padding: 20px 0;
`;

const ShopAddress = styled.div`
  padding: 20px 0;
  color: #4e4e4e;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 22px;
  display: flex;
  align-items: center;
`;

const ShopAddressText = styled.div`
  padding-left: 7px;
`;

const CreditCardInfoContainer = styled.div`
  padding: 14px 22px;
  border-top: 1px solid #f1f1f1;
`;

const PromotionCodeInfoContainer = styled.div`
  padding: 14px 22px;
  border-top: 1px solid #f1f1f1;
`;

const TotalPriceContainer = styled.div`
  padding: 14px 22px;
`;

const DiscountPriceContainer = styled.div`
  display: flex;
  padding-bottom: 14px;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const DiscountPriceTitle = styled.div``;

const DiscountPriceValue = styled.div`
  color: #de3823;
`;

const UserPaymentPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  line-height: 2.6rem;
`;

const UserPaymentPriceTitle = styled.div``;

const UserPaymentPriceValue = styled.div``;

const OrderReceiveTimeContainer = styled.div<{ hasPadding: boolean }>`
  padding: ${p => (p.hasPadding ? "22px 0 0" : "0")};
`;

const OrderNumberContainer = styled(Grid).attrs({
  container: true,
  align: "flex-end"
})`
  padding: 22px 25px 22px 22px;
  border-top: 1px solid #f1f1f1;
  color: #4e4e4e;
`;

const OrderNumberTitle = styled.div`
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin-right: auto;
`;

const OrderNumberKey = styled(Grid).attrs({
  item: true,
  rate: 0
})`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-right: 6px;
  padding-bottom: 4px;
`;

const OrderNumberValue = styled(Grid).attrs({ item: true, rate: 0 })`
  text-align: right;
  font-size: 2rem;
  line-height: 2.9rem;
`;

const MapContainer = styled.div`
  position: relative;
  height: 132px;
  margin: 0;
`;

const MapContainerForStatus = styled.div`
  position: relative;
  width: calc(100% + 42px);
  height: 50vh;
  margin: 13px -21px 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f6f6f6;
`;

const OverMapButton = styled.button`
  position: absolute;
  top: 14px;
  left: 21px;
  display: block;
  padding: 10px;
  font-size: 1rem;
  color: #4e4e4e;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(41, 41, 41, 0.25);
  border-radius: 24px;
`;

const DisposalNoticeContainer = styled.div`
  margin-top: 10px;
`;

const OrderStatusContainer = styled.div<{ headerHeight: number }>`
  position: absolute;
  width: 100%;
  min-height: calc(100vh - ${p => p.headerHeight + 70}px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 21px;
`;

const OrderStatusInner = styled.div`
  width: 100%;
  padding-bottom: 70px;
`;

const SwipeContainer = styled.div<{
  swiped: boolean;
  initialPositionY: number;
  headerHeight: number;
}>`
  transition: all 600ms cubic-bezier(0.4, 0, 0.3, 0.8) 0ms;
  transform: ${p =>
    p.swiped
      ? "translate(0px, 0px)"
      : `translate(0px, ${p.initialPositionY}px)`};
  width: 100%;
  box-shadow: 0px 0px 23px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 3;
  position: relative;
  min-height: calc(100vh - ${p => p.headerHeight}px);
`;

const SwipeHandleContainer = styled.div`
  padding: 10px 0;
`;

const SwipeHandle = styled.span`
  width: 48px;
  height: 4px;
  display: block;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0 auto;
`;

const PageTitle = styled.div`
  padding: 10px 24px 15px;
  height: 61px;
  font-size: 1.8rem;
  line-height: 2.6rem;
`;

const ShopName = styled.div`
  font-size: 2.6rem;
  line-height: 3rem;
  text-align: center;
  padding: 0 24px;
`;

const BackIconContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border-radius: 100%;
  transform: translateY(50%);
  cursor: pointer;
`;

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const dateStyle: React.CSSProperties = {
  fontSize: "1.2rem",
  lineHeight: "1.7rem"
};

const timeStyle: React.CSSProperties = {
  fontSize: "3.6rem",
  lineHeight: "5.2rem",
  display: "flex",
  alignItems: "flex-end",
  width: "100%"
};

const cancelButtonStyle: React.CSSProperties = {
  background: "transparent",
  padding: 0,
  color: "#ef5f56"
};

const pageBodyStyle: React.CSSProperties = {
  padding: 0,
  backgroundColor: "#f5f5f5"
};

const OrderNumberStatusContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const OrderNumberStatusTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  padding-bottom: 4px;
  padding-right: 6px;
`;

const OrderNumberStatus = styled.div`
  font-size: 2rem;
  line-height: 2.9rem;
`;

const sendArrivedButtonStyle: React.CSSProperties = {
  padding: "10px 12px",
  margin: "10px auto",
  display: "inherit",
  borderRadius: 0
};

interface IProps extends RouteComponentProps<{ orderNumber: string }> {
  submittingCancelOrder: boolean;
  currentOrderDetail: OrderData;
  currentShopData: ShopData;
  orderNumber: string;
  openCancelOrderDialogFlag: boolean;
  actions: ActionDispatcher;
  children?: never;
}

const OrderHistoryDetailTemplate: React.FC<IProps> = React.memo(props => {
  const { orderNumber, currentOrderDetail, currentShopData, actions } = props;

  useEffect(() => {
    actions.userAccessedToPageThatNeedsOrderDetail(orderNumber);
    actions.startPolling(orderNumber);
    return () => {
      actions.stopPolling();
    };
  }, []);

  const goToHistoryList = useCallback(() => {
    actions.goToHistoryList();
  }, [actions]);

  const handleClickCancelOrderButton = useCallback(() => {
    actions.userTouchedOpenCancelOrderDialogButton();
  }, [actions.userTouchedOpenCancelOrderDialogButton]);

  const handleOpenSendArrivedDialog = useCallback(() => {
    actions.userTouchedOpenSendArrivedDialogButton();
  }, [actions.userTouchedOpenSendArrivedDialogButton]);

  const shopInfo = useMemo(() => currentOrderDetail.getShopInfo(), [
    currentOrderDetail
  ]);

  const isLoading = useMemo(() => {
    return (
      !currentOrderDetail.exists() ||
      !shopInfo.exists() ||
      currentOrderDetail.getOrderNumber() !== orderNumber
    );
  }, [currentOrderDetail, orderNumber, shopInfo]);

  const fetchingCurrentShopData = useMemo(() => {
    return (
      !currentShopData.existsData() ||
      currentShopData.getId() !== currentOrderDetail.getShopInfo().getId()
    );
  }, [currentShopData, currentOrderDetail]);

  const shopLocation = useMemo(() => {
    if (fetchingCurrentShopData) {
      return undefined;
    } else {
      return currentShopData.getLocation();
    }
  }, [currentShopData, fetchingCurrentShopData]);

  const handleClickLaunchMapApp = useCallback(() => {
    if (!shopLocation) {
      return;
    }
    Utility.launchMapApp(shopLocation);
  }, [shopLocation]);

  const [opend, setOpend] = useState<boolean>(false);
  const [swipePosition, setSwipePosition] = useState<{
    y: number;
    swiping: boolean;
  }>({ y: 0, swiping: true });

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLElement>) => {
      event.persist();
      const touch = event.touches[0];
      setSwipePosition({ y: touch.clientY, swiping: false });
    },
    [opend, swipePosition, setOpend, setSwipePosition]
  );

  const handleTouchMove = useCallback(
    (event: React.TouchEvent<HTMLElement>) => {
      event.persist();
      event.stopPropagation();
      event.preventDefault();
      if (event.changedTouches && event.changedTouches.length) {
        setSwipePosition({ y: swipePosition.y, swiping: true });
      }
    },
    [opend, swipePosition, setOpend, setSwipePosition]
  );

  const handleTouchEnd = useCallback(
    (event: React.TouchEvent<HTMLElement>) => {
      event.persist();
      const touch = event.changedTouches[0];
      const absY = Math.abs(touch.clientY - swipePosition.y);
      if (swipePosition.swiping && absY > 50) {
        setOpend(!opend);
      }
    },
    [opend, swipePosition, setOpend, setSwipePosition]
  );

  const headerContainer = useRef<HTMLDivElement>(null);
  const [
    swipeContainerInitialPositionY,
    setSwipeContainerInitialPositionY
  ] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const initialSwipeContainerProps = useCallback(() => {
    const contentVisibleHeight = 125;
    const footerHeight = 70;
    const safeAreaPaddingBottom = Utility.getSafeAreaInsetBottom();
    const headerClientHeight = headerContainer.current
      ? headerContainer.current.clientHeight
      : 0;
    setHeaderHeight(headerClientHeight);
    setSwipeContainerInitialPositionY(
      document.documentElement.clientHeight -
        (contentVisibleHeight + footerHeight + safeAreaPaddingBottom)
    );
  }, [headerContainer.current]);

  useEffect(() => {
    initialSwipeContainerProps();
    setOpend(false);
  }, []);

  const opendStyle: React.CSSProperties = !opend
    ? { height: "100vh", overflow: "hidden" }
    : {};

  return (
    <Auth>
      <Page containerStyle={{ ...containerStyle, ...opendStyle }}>
        <PageBody style={{ ...pageBodyStyle, ...opendStyle }}>
          <HeaderContainer ref={headerContainer} transparent>
            <BackIconContainer onClick={goToHistoryList}>
              <ArrowIcon />
            </BackIconContainer>
          </HeaderContainer>
          {isLoading ? (
            <BlankContainer>
              <Loading />
            </BlankContainer>
          ) : (
            <>
              <OrderStatusContainer headerHeight={headerHeight}>
                <OrderStatusInner>
                  {!fetchingCurrentShopData && (
                    <OrderNotice
                      order={currentOrderDetail}
                      luxuryWaitInductionText={currentShopData.getLuxuryWaitInductionText()}
                    />
                  )}
                  <OrderReceiveTimeContainer
                    hasPadding={!currentOrderDetail.canShowMapInOrderDetail()}
                  >
                    <TimeDisplay
                      order={currentOrderDetail}
                      isOrderStatus
                      date={currentOrderDetail.getReceiveDatetimeAsDate()}
                      dateStyle={dateStyle}
                      timeStyle={timeStyle}
                    />
                  </OrderReceiveTimeContainer>
                  {!fetchingCurrentShopData && (
                    <OrderProcess order={currentOrderDetail} />
                  )}
                  {currentShopData.isLuxury() && (
                    <Button
                      style={sendArrivedButtonStyle}
                      onClick={handleOpenSendArrivedDialog}
                      disabled={
                        !currentOrderDetail.canDisplayUserArrivedButton()
                      }
                    >
                      受取場所を確認する
                    </Button>
                  )}
                  <OrderNumberStatusContainer>
                    <OrderNumberStatusTitle>注文番号</OrderNumberStatusTitle>
                    <OrderNumberStatus>
                      {currentOrderDetail.getOrderNumber()}
                    </OrderNumberStatus>
                  </OrderNumberStatusContainer>
                  {/* {!fetchingCurrentShopData && (
                    <OrderStuffMessage order={currentOrderDetail} />
                  )} */}
                  {currentOrderDetail.canCancelOrder() && (
                    <CancelButtonContainer>
                      <Button
                        block
                        secondary
                        onClick={handleClickCancelOrderButton}
                        style={cancelButtonStyle}
                      >
                        注文をキャンセル
                      </Button>
                    </CancelButtonContainer>
                  )}
                  {currentOrderDetail.canDisplayFinishedOrderNotice() && (
                    <DisposalNoticeContainer>
                      <div
                        style={{
                          fontSize: "10px",
                          lineHeight: 1.25,
                          color: "#EF5F56",
                          borderRadius: "5px"
                        }}
                      >
                        <MultiLine>
                          {
                            "調理完了から２時間経過または\nお店の営業が終了した場合、商品は破棄されます。"
                          }
                        </MultiLine>
                      </div>
                    </DisposalNoticeContainer>
                  )}
                  {currentOrderDetail.canShowMapInOrderDetail() && (
                    <>
                      {shopLocation ? (
                        <MapViewer
                          containerElement={<MapContainerForStatus />}
                          defaultCenter={shopLocation}
                        >
                          <OverMapButton onClick={handleClickLaunchMapApp}>
                            マップアプリで見る
                          </OverMapButton>
                          <ShopImageMarker
                            src={currentShopData.getMapIconImagePath()}
                            location={shopLocation}
                          />
                        </MapViewer>
                      ) : (
                        <MapContainerForStatus>
                          <LoadingContainer>
                            <Loading />
                          </LoadingContainer>
                        </MapContainerForStatus>
                      )}
                    </>
                  )}
                </OrderStatusInner>
              </OrderStatusContainer>
              <SwipeContainer
                swiped={opend}
                initialPositionY={swipeContainerInitialPositionY}
                headerHeight={headerHeight}
              >
                <SwipeHandleContainer
                  onTouchStart={e => handleTouchStart(e)}
                  onTouchMove={e => handleTouchMove(e)}
                  onTouchEnd={e => handleTouchEnd(e)}
                >
                  <SwipeHandle />
                  <PageTitle>注文の詳細</PageTitle>
                  <ShopName>{shopInfo.getShopName()}</ShopName>
                </SwipeHandleContainer>

                {shopLocation ? (
                  <MapViewer
                    containerElement={<MapContainer />}
                    defaultCenter={shopLocation}
                  >
                    <OverMapButton onClick={handleClickLaunchMapApp}>
                      マップアプリで見る
                    </OverMapButton>
                    <ShopImageMarker
                      src={currentShopData.getMapIconImagePath()}
                      location={shopLocation}
                    />
                  </MapViewer>
                ) : (
                  <MapContainer>
                    <LoadingContainer>
                      <Loading />
                    </LoadingContainer>
                  </MapContainer>
                )}

                <ShopAddress>
                  <MapPinIcon />
                  <ShopAddressText>
                    {currentOrderDetail.getShopInfo().getAddress()}
                  </ShopAddressText>
                </ShopAddress>

                <OrderNumberContainer>
                  <OrderNumberTitle>ご注文内容</OrderNumberTitle>
                  <OrderNumberKey>注文番号</OrderNumberKey>
                  <OrderNumberValue>
                    {currentOrderDetail.getOrderNumber()}
                  </OrderNumberValue>
                </OrderNumberContainer>

                <ul>
                  {currentOrderDetail.getItemList().map((item, index) => (
                    <li key={index}>
                      <OrderItemViewer item={item} />
                    </li>
                  ))}
                </ul>

                {currentOrderDetail.existsDiscountCode() && (
                  <PromotionCodeInfoContainer>
                    <PromotionCodeInfo
                      code={currentOrderDetail.getDiscountCode()}
                    />
                  </PromotionCodeInfoContainer>
                )}
                <TotalPriceContainer>
                  {currentOrderDetail.existsDiscountCode() && (
                    <DiscountPriceContainer>
                      <DiscountPriceTitle>割引</DiscountPriceTitle>
                      <DiscountPriceValue>
                        {`-${Price.getPresentationValue(
                          currentOrderDetail.getDiscountPrice()
                        )}`}
                      </DiscountPriceValue>
                    </DiscountPriceContainer>
                  )}
                  <UserPaymentPriceContainer>
                    <UserPaymentPriceTitle>合計</UserPaymentPriceTitle>
                    <UserPaymentPriceValue>
                      {Price.getPresentationValue(
                        currentOrderDetail.getUserPaymentPrice()
                      )}
                    </UserPaymentPriceValue>
                  </UserPaymentPriceContainer>
                </TotalPriceContainer>
                <CreditCardInfoContainer>
                  <CreditCardInfo
                    cardNumber={currentOrderDetail.getCardNumber()}
                    editable={false}
                  />
                </CreditCardInfoContainer>
              </SwipeContainer>
            </>
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ orderNumber: string }>
) => ({
  currentOrderDetail: state.order.getCurrentOrderDetail(),
  currentShopData: state.search.getCurrentShopData(),
  submittingCancelOrder: state.app.isConnectedApi(EApiKey.CANCEL_ORDER),
  orderNumber: ownProps.match.params.orderNumber
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goToHistoryList() {
    this.dispatch(
      push("/history", {
        routerActionType: { [`${"PUSH"}${"/history"}`]: "POP" }
      })
    );
  }

  public userAccessedToPageThatNeedsOrderDetail(orderNumber: string) {
    this.dispatch(userAccessedToPageThatNeedsOrderDetail(orderNumber));
  }

  public startPolling(orderNumber: string) {
    this.dispatch(systemStartedPollingOrderDetail(orderNumber));
  }

  public stopPolling() {
    this.dispatch(systemStoppedPollingOrderDetail());
  }

  public userTouchedOpenCancelOrderDialogButton() {
    this.dispatch(systemOpenedModal("CANCEL_ORDER", {}));
  }
  public userTouchedOpenSendArrivedDialogButton() {
    this.dispatch(systemOpenedModal("SEND_ARRIVED", {}));
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderHistoryDetailTemplate)
);
