import { stripUnit } from "polished";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import fontsConst from "../../styles/const/fontsConst";
import MultiLine from "../atoms/MultiLine";

const LINE_HEIGHT = 1.5;

const Container = styled.div`
  position: relative;
  line-height: ${LINE_HEIGHT};
  transition: max-height 0.5s;
  overflow: hidden;
`;
const CollapseButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 0.5em;
  background-color: #fff;
`;

interface IProps {
  comment: string;
}

const FeedbackListItem: React.FC<IProps> = React.memo(props => {
  const { comment } = props;
  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const innerRef = useRef<HTMLDivElement>(null);

  const innerHeight = useMemo(() => {
    return innerRef.current !== null ? innerRef.current.clientHeight : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current]);

  const containerStyle: React.CSSProperties = useMemo(() => {
    return isCollapse
      ? { maxHeight: `calc(3em * ${LINE_HEIGHT})` }
      : { maxHeight: innerHeight };
  }, [innerHeight, isCollapse]);

  useLayoutEffect(() => {
    if (innerRef.current !== null) {
      const elem = innerRef.current as HTMLElement;
      const fontSize = getComputedStyle(elem).fontSize;
      const fontSizeVal =
        fontSize !== null
          ? (stripUnit(fontSize) as number)
          : (stripUnit(fontsConst.SIZE.DEFAULT) as number) * 10;
      if (elem.clientHeight <= fontSizeVal * LINE_HEIGHT * 3) {
        setIsCollapse(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, setIsCollapse]);

  return (
    <Container style={containerStyle}>
      <div
        ref={innerRef}
        style={{
          color: "#595959"
        }}
      >
        <MultiLine>{comment}</MultiLine>
      </div>
      {isCollapse && (
        <CollapseButton onClick={() => setIsCollapse(false)}>
          ... <span style={{ color: colorsConst.MAIN }}>もっと読む</span>
        </CollapseButton>
      )}
    </Container>
  );
});

export default FeedbackListItem;
