import { List, Record } from "immutable";
import CreditCardData, {
  ICreditCardDataProps
} from "../../records/CreditCardData";
import CreditCardItem from "../../records/CreditCardItem";
import DiscountCode, { IDiscountCode } from "../../records/DiscountCode";
import UserData, { IUserDataProps, IUserData } from "../../records/UserData";
import LocalRepository from "../../util/LocalRepository";
import InstallCoupon, { IInstallCoupon } from "../../records/InstallCoupon";

export interface UserState {
  value: number;
  loginToken: string | null;
  data: UserData;
  creditCard: CreditCardData;
  discountCodes: List<DiscountCode>;
  installCoupon: InstallCoupon;
}

const initialState: UserState = {
  value: 0,
  loginToken: null,
  data: new UserData(),
  creditCard: new CreditCardData(),
  discountCodes: List<DiscountCode>(),
  installCoupon: new InstallCoupon()
};

export default class UserModel extends Record(initialState) {
  public static validCreditCardNumber(cardNumber: string) {
    const regExp = new RegExp(/^d+$/);
    return (
      regExp.test(cardNumber) &&
      14 <= cardNumber.length &&
      cardNumber.length <= 16
    );
  }

  public static validDiscrountCode(code: string) {
    const regExp = new RegExp(/^[\x01-\x7E]+$/);
    return regExp.test(code) && code.length <= 16;
  }

  public static validName(name: string) {
    const regExp = new RegExp(/^([^\x01-\x7E\xA1-\xDF]|[a-zA-Z])+$/);
    return regExp.test(name);
  }

  public static validNickName(name: string) {
    const regExp = new RegExp(
      /^[ぁ-んァ-ンーa-zａ-ｚA-ZＡ-Ｚ0-9一-龠０-９\-]+$/
    );
    return regExp.test(name);
  }

  public static validBirthday(year: number, month: number, day: number) {
    return year.toString().length === 4;
  }

  public static validPassword(password: string): boolean {
    // const regExp = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/);
    // 半角英数記号のみ
    const regExp = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/);
    return regExp.test(password);
  }

  public static validEmail(email: string): boolean {
    const regExp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    return regExp.test(email);
  }

  public static validTel(tel: string): boolean {
    const regExp = new RegExp(/^\d*$/);
    return regExp.test(tel);
  }

  /**
   * @unsafe
   * @FIXME: 不要になったら消す
   */
  public isDebugUser() {
    const token = this.getLoginToken();
    if (token === null) {
      return false;
    }
    const debugUserTokenListEnv = process.env.REACT_APP_DEBUG_USER_TOKEN_LIST;
    const debugUserTokenList =
      typeof debugUserTokenListEnv !== "undefined"
        ? debugUserTokenListEnv.split(" ")
        : [];
    return debugUserTokenList.some(debugToken => {
      return token.trim() === debugToken.trim();
    });
  }

  public resetUser() {
    return this.set("data", this.getData().resetData());
  }

  public updateDiscountCodes(list: IDiscountCode[]) {
    return this.set(
      "discountCodes",
      List(list.map(item => new DiscountCode(item)))
    );
  }

  public getDiscountCodes(): List<DiscountCode> {
    return this.get("discountCodes");
  }

  public updateCreditCard(data: ICreditCardDataProps) {
    return this.set("creditCard", new CreditCardData(data));
  }

  public getCreditCard(): CreditCardData {
    return this.get("creditCard");
  }

  public setMaintenanceFlag() {
    const data = this.getData();
    return this.set("data", data.setMaintenanceFlag());
  }

  public getMaintenanceFlag() {
    return this.getData().getMaintenanceFlag();
  }

  public setPasswordResetFlag() {
    const data = this.getData();
    return this.set("data", data.setPasswordResetFlag());
  }

  public getPasswordResetFlag() {
    return this.getData().getPasswordResetFlag();
  }

  public existsUnreadNotification() {
    const userData = this.getData();
    return userData.exists() && userData.checkedInformation();
  }

  public getSelectedCreditCardItem(): CreditCardItem | null {
    const data = this.getCreditCard();
    if (!data.exists()) {
      return null;
    }
    return data.getSelectedItem();
  }

  public updateData(data: IUserDataProps | IUserData) {
    return this.set("data", new UserData(data));
  }

  public updateLoginToken(loginToken: string | null) {
    if (loginToken === null) {
      LocalRepository.removeItem("token");
    } else {
      LocalRepository.setItem("token", loginToken);
    }
    return this.set("loginToken", loginToken);
  }

  public getLoginToken(): string | null {
    if (LocalRepository.hasItem("token")) {
      return LocalRepository.getItem("token");
    }
    return this.get("loginToken");
  }

  public getData(): UserData {
    return this.get("data");
  }

  /**
   * メニューの購入が可能か
   */
  public canPurchase() {
    return this.getCreditCard().exists() && this.getData().getId() !== "";
  }

  public updateInstallCoupon(installCoupon: IInstallCoupon) {
    return this.set("installCoupon", new InstallCoupon(installCoupon));
  }

  public getInstallCoupon() {
    return this.get("installCoupon");
  }
}
