import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IUpdateMailAddressAndPasswordForm,
  initialUpdateMailAddressAndPasswordForm,
  validateUpdateMailAddressAndPasswordForm
} from "../../forms/updateMailAddressAndPassword";
import { userSubmittedUpdateMailAddressAndPassword } from "../../modules/user/actions";
import { ReduxModel } from "../../reducer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";
import ButtonAlter from "../atoms/ButtonAlter";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RegisterMailTemplate: React.FC<
  IProps & InjectedFormProps<IUpdateMailAddressAndPasswordForm>
> = React.memo(props => {
  const {
    submittingInputUserData,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialUpdateMailAddressAndPasswordForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Page
      header={
        <SubPageHeader
          title="メールアドレス・パスワード"
          handleClickBack={handleClickBack}
        />
      }
      containerStyle={containerStyle}
      contentStyle={contentStyle}
    >
      <PageBody>
        <form
          id={EFormID.UPDATE_MAIL_ADDRESS_AND_PASSWORD}
          onSubmit={handleSubmitWrap}
        >
          <div>
            <LabelText htmlFor="mail">メールアドレス</LabelText>
            <TextField
              id="mail"
              name="mail"
              type="email"
              placeholder="menu@mail.com"
              autoComplete="email"
            />
          </div>
          <div>
            <LabelText htmlFor="inputPassword">
              パスワード<span>※半角英数字８文字以上</span>
            </LabelText>
            <TextField
              id="inputPassword"
              name="password"
              type="password"
              placeholder="パスワード"
              autoComplete="current-password"
            />
          </div>
          <div>
            <TextField
              id="confirmPassword"
              name="confirmation_password"
              type="password"
              placeholder="パスワード（確認）"
            />
          </div>
          <ButtonAlter
            block
            appearance={"secondary"}
            loading={submittingInputUserData}
            disabled={!valid}
            type="submit"
          >
            登録
          </ButtonAlter>
        </form>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingInputUserData: state.app.isConnectedApi(
    EApiKey.UPDATE_MAIL_ADDRESS_AND_PASSWORD
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },
  handleSubmit() {
    dispatch(userSubmittedUpdateMailAddressAndPassword());
  }
});

const reduxFormConfig = {
  form: EFormID.UPDATE_MAIL_ADDRESS_AND_PASSWORD,
  initialValues: initialUpdateMailAddressAndPasswordForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateUpdateMailAddressAndPasswordForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IUpdateMailAddressAndPasswordForm, IProps>(reduxFormConfig)(
    RegisterMailTemplate
  )
);
