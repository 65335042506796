import { is } from "immutable";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { EProcessingFlag } from "../../modules/app/model";
import {
  systemChangedCurrentSearchResultType,
  userAccessedToPageThatNeedsInitialSearchResult
} from "../../modules/search/actions";
import { SearchResultType } from "../../records/SearchResult";
import { ResultViewType } from "../../records/SearchSetting";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import zIndexConst from "../../styles/const/zIndexConst";
import Analytics, {
  AnalyticsEventSearchClickShopLink
} from "../../util/Analytics";
import ScrollTopMemory, { EScrollTopKey } from "../../util/ScrollTopMemory";
import Utility from "../../util/Utility";
import Page, { PageContainerContext } from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import CartButton, { CART_BUTTON_HEIGHT } from "./CartButton";
import ConditionList from "./ConditionList";
import GeolocationPermissionErrorForHome from "./GeolocationPermissionErrorForHome";
import ListSearchResult from "./ListSearchResult";
import MainHeader, { MAIN_HEADER_HEIGHT } from "./MainHeader";
import { MAP_CAROUSEL_HEIGHT } from "./MapCarousel";
import MapSearchResult from "./MapSearchResult";
import OrderHistoryShopItemList from "./OrderHistoryShopItemList";
import ToggleResultViewTypeButton from "./ToggleResultViewTypeButton";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

const ToggleButtonContainer = styled.div<{
  viewType: ResultViewType;
  existsCart: boolean;
}>`
  position: absolute;
  bottom: 11px;
  right: 11px;
  z-index: ${zIndexConst.ABSOLUTE};
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  transform: translateY(
    -${p => {
        const base = p.existsCart ? CART_BUTTON_HEIGHT : 0;
        return p.viewType === "list" ? base : MAP_CAROUSEL_HEIGHT + 11;
      }}px
  );
`;

const ToggleViewTypeButton: React.FC<{
  searchResultType: SearchResultType;
  currentViewType: ResultViewType;
  existsCart: boolean;
}> = ({ searchResultType, currentViewType, existsCart }) => {
  const pageContainer = useContext(PageContainerContext);

  return pageContainer !== null
    ? createPortal(
        <ToggleButtonContainer
          viewType={currentViewType}
          existsCart={existsCart}
        >
          <ToggleResultViewTypeButton searchResultType={searchResultType} />
        </ToggleButtonContainer>,
        pageContainer
      )
    : null;
};

const ConditionListContainer = React.memo(
  React.forwardRef<
    HTMLDivElement,
    {
      searchResultType: Exclude<SearchResultType, "keyword">;
      visible: boolean;
    }
  >(({ searchResultType, visible }, ref) => {
    const pageContainer = useContext(PageContainerContext);
    const SAFE_AREA_INSET_TOP = useRef(Utility.getSafeAreaInset("top"));

    return pageContainer !== null
      ? createPortal(
          <div
            ref={ref}
            style={{
              position: "absolute",
              top: MAIN_HEADER_HEIGHT + SAFE_AREA_INSET_TOP.current,
              left: 0,
              width: "100%",
              transition: "transform 0.25s ease-out",
              transform: `translate3d(0, ${visible ? 0 : "-100%"}, 0)`
            }}
          >
            <ConditionList searchResultType={searchResultType} />
          </div>,
          pageContainer
        )
      : null;
  })
);

const LoadingOverlay: React.FC = () => {
  const pageContainer = useContext(PageContainerContext);

  return pageContainer !== null
    ? createPortal(
        <LoadingContainer>
          <Loading invert />
        </LoadingContainer>,
        pageContainer
      )
    : null;
};

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndexConst.ABSOLUTE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface IProps {
  searchResultType: Exclude<SearchResultType, "keyword">;
}

const SearchTemplateBase: React.FC<IProps> = ({ searchResultType }) => {
  const searchResult = useSelector(
    (state: ReduxModel) => {
      const searchSetting = state.search
        .getSearchSettings()
        .getData(searchResultType);
      return state.search
        .getSearchResult()
        .getList(
          searchResultType,
          searchSetting.getSearchResultDisplayCondition()
        );
    },
    (left, right) => is(left, right)
  );

  const fetchingResult = useSelector((state: ReduxModel) => {
    const api =
      searchResultType === "reserve"
        ? EApiKey.SEARCH_SHOP_WITH_RESERVE
        : EApiKey.SEARCH_SHOP_WITH_FASTEST;
    return (
      state.app.isConnectedApi(api) ||
      state.app.isProcessing(EProcessingFlag.FETCHING_INITIAL_SEARCH)
    );
  });

  const resultViewType = useSelector((state: ReduxModel) =>
    state.search
      .getSearchSettings()
      .getData(searchResultType)
      .getViewType()
  );

  const searchPlace = useSelector((state: ReduxModel) =>
    state.search
      .getSearchSettings()
      .getData(searchResultType)
      .getSearchPlace()
  );

  const shouldShowGeolocationPermissionError = useSelector(
    (state: ReduxModel) => {
      return (
        state.search.hasBeenDeniedGeolocationPermission() &&
        state.search
          .getSearchSettings()
          .getData(searchResultType)
          .getSearchPlace()
          .isCurrentLocation()
      );
    }
  );

  const onceSearched = useSelector((state: ReduxModel) =>
    state.search.isOnceSearched(searchResultType)
  );

  const existsCart = useSelector(
    (state: ReduxModel) => !state.order.getCartList().isEmpty()
  );

  const dispatch = useDispatch();

  const handleClickShop = useCallback(
    (shopId: number) => {
      const eventName = `search_${searchResultType}_shop` as AnalyticsEventSearchClickShopLink;
      Analytics.logEvent(eventName, {
        content_type: "shop_id",
        item_id: `${shopId}`
      });
    },
    [searchResultType]
  );

  useEffect(() => {
    dispatch(systemChangedCurrentSearchResultType(searchResultType));
  }, [dispatch, searchResultType]);

  useEffect(() => {
    if (!onceSearched) {
      dispatch(
        userAccessedToPageThatNeedsInitialSearchResult(searchResultType)
      );
    }
  }, [dispatch, onceSearched, searchResultType]);

  const [conditionListHeight, setConditionListHeight] = useState(0);

  const pageBodyStyle = useMemo<React.CSSProperties>(() => {
    return {
      position: "relative",
      padding: 0,
      paddingTop: `${conditionListHeight}px`,
      height: "100%"
    };
  }, [conditionListHeight]);

  const setConditionListHeightFromRef = useCallback(
    (elem: HTMLDivElement | null) => {
      if (elem !== null) {
        setConditionListHeight(elem.clientHeight);
      }
    },
    []
  );

  const [isVisibleConditionList, setVisibleConditionListFlag] = useState(
    // ScrollTopMemory.loadOrDefault(`${EScrollTopKey.TEXT_SEARCH_RESULT}`, 0) ===
    //   0
    true
  );

  const prevScrollTop = useRef(
    ScrollTopMemory.loadOrDefault(`${EScrollTopKey.TEXT_SEARCH_RESULT}`, 0)
  );
  const bufferWhenDirectionChange = useRef(0);

  const handleScroll = useCallback((event: React.UIEvent<HTMLElement>) => {
    // const { scrollTop } = event.currentTarget;
    // const diff = Math.abs(scrollTop - prevScrollTop.current);
    // console.log({
    //   scrollTop,
    //   prevScrollTop: prevScrollTop.current,
    //   diff,
    //   bufferWhenDirectionChange: bufferWhenDirectionChange.current
    // });
    // const visibleConditionList =
    //   scrollTop <= 0 || prevScrollTop.current >= scrollTop;
    // if (scrollTop > 0) {
    //   bufferWhenDirectionChange.current += diff;
    // }
    // if (
    //   isVisibleConditionList !== visibleConditionList &&
    //   scrollTop >= 0 &&
    //   bufferWhenDirectionChange.current > 10
    // ) {
    //   setVisibleConditionListFlag(visibleConditionList);
    //   bufferWhenDirectionChange.current = 0;
    // }
    // prevScrollTop.current = scrollTop;
  }, []);

  return (
    <Page
      header={<MainHeader searchResultType={searchResultType} />}
      footer={resultViewType !== "map" ? <CartButton /> : null}
      handleScroll={handleScroll}
      containerStyle={containerStyle}
    >
      <PageBody style={pageBodyStyle}>
        {resultViewType === "list" ? (
          <>
            <ConditionListContainer
              ref={setConditionListHeightFromRef}
              searchResultType={searchResultType}
              visible={isVisibleConditionList}
            />
            <OrderHistoryShopItemList />
          </>
        ) : null}
        {shouldShowGeolocationPermissionError ? (
          <GeolocationPermissionErrorForHome />
        ) : (
          <>
            {resultViewType === "list" ? (
              <ListSearchResult
                shopList={searchResult}
                searchPlace={searchPlace}
                handleClickShop={handleClickShop}
                resultType={searchResultType}
                existsCart={existsCart}
              />
            ) : (
              <MapSearchResult
                shopList={searchResult}
                resultType={searchResultType}
                fetchingResult={fetchingResult}
              />
            )}
          </>
        )}
        <ToggleViewTypeButton
          searchResultType={searchResultType}
          currentViewType={resultViewType}
          existsCart={existsCart}
        />
        {fetchingResult ? <LoadingOverlay /> : null}
      </PageBody>
    </Page>
  );
};

export default SearchTemplateBase;
