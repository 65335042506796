import LocalStorage from "./LocalStorage";

export default class LocalRepository {
  public static getItem(key: string, def: string = ""): string {
    return LocalStorage.loadOrDefault(key, def);
  }

  public static setItem(key: string, value: string) {
    LocalStorage.save(key, value);
  }

  public static removeItem(key: string) {
    LocalStorage.remove(key);
  }

  public static hasItem(key: string) {
    return LocalStorage.load(key) !== null;
  }
}
