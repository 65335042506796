import AppAvailability from "./AppAvailability";

export enum ESocialShareVia {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  LINE = "line",
  TWITTER = "twitter"
}

export enum EPlatform {
  IOS = "ios",
  ANDROID = "android"
}

export default class SocialSharing {
  public static canShareVia(via: ESocialShareVia): boolean {
    const viaStr = SocialSharing.getShareViaString(via);
    return (window as any).plugins.socialsharing.canShareVia(viaStr);
  }

  public static socialShareText(text: string) {
    if (SocialSharing.isPluginEnabled()) {
      (window as any).plugins.socialsharing.shareWithOptions({
        message: text
      });
    }
  }

  public static shareViaFacebook(message: string) {
    if (
      SocialSharing.isPluginEnabled() &&
      SocialSharing.canShareVia(ESocialShareVia.FACEBOOK)
    ) {
      (window as any).plugins.socialsharing.shareViaFacebook(message);
    }
  }

  public static shareViaInstagram(message: string) {
    if (
      SocialSharing.isPluginEnabled() &&
      SocialSharing.canShareVia(ESocialShareVia.INSTAGRAM)
    ) {
      (window as any).plugins.socialsharing.shareViaInstagram(message);
    }
  }

  public static async shareViaLINE(message: string) {
    const available = await SocialSharing.check(ESocialShareVia.LINE);
    if (!available) {
      return;
    }
    if (
      SocialSharing.isPluginEnabled() &&
      SocialSharing.canShareVia(ESocialShareVia.LINE)
    ) {
      (window as any).plugins.socialsharing.shareVia(
        SocialSharing.getShareViaString(ESocialShareVia.LINE)
      );
    }
  }

  public static shareViaTwitter(message: string) {
    if (
      SocialSharing.isPluginEnabled() &&
      SocialSharing.canShareVia(ESocialShareVia.TWITTER)
    ) {
      (window as any).plugins.socialsharing.shareViaTwitter(message);
    }
  }

  private static check(via: ESocialShareVia): Promise<boolean> {
    if (!AppAvailability.isPluginEnabled()) {
      return Promise.resolve(false);
    }
    return new Promise(async (resolve, reject) => {
      try {
        const available = await AppAvailability.check(
          SocialSharing.getUriSchemeString(via)
        );
        resolve(available);
      } catch (exception) {
        reject(exception);
      }
    });
  }

  private static getUriSchemeString(via: ESocialShareVia) {
    const mapUriSchemeToPlatform = {
      [ESocialShareVia.FACEBOOK]: {
        [EPlatform.IOS]: "fb://",
        [EPlatform.ANDROID]: "com.facebook.katana"
      },
      [ESocialShareVia.INSTAGRAM]: {
        [EPlatform.IOS]: "instagram://",
        [EPlatform.ANDROID]: "com.instagram.android"
      },
      [ESocialShareVia.LINE]: {
        [EPlatform.IOS]: "line://",
        [EPlatform.ANDROID]: "jp.naver.line.android"
      },
      [ESocialShareVia.TWITTER]: {
        [EPlatform.IOS]: "twitter://",
        [EPlatform.ANDROID]: "com.twitter.android"
      }
    };
    const platform: EPlatform = (window as any).cordova.platformId;
    return mapUriSchemeToPlatform[via][platform];
  }

  private static isPluginEnabled() {
    return (
      "cordova" in window &&
      "plugins" in window &&
      "socialsharing" in (window as any).plugins
    );
  }

  private static getShareViaString(via: ESocialShareVia) {
    const mapViaToPlatform = {
      [ESocialShareVia.FACEBOOK]: {
        [EPlatform.IOS]: "com.apple.social.facebook",
        [EPlatform.ANDROID]: "facebook"
      },
      [ESocialShareVia.INSTAGRAM]: {
        [EPlatform.IOS]: "com.burbn.instagram",
        [EPlatform.ANDROID]: "com.instagram.android"
      },
      [ESocialShareVia.LINE]: {
        [EPlatform.IOS]: "jp.naver.line.Share",
        [EPlatform.ANDROID]: "jp.naver.line.android"
      },
      [ESocialShareVia.TWITTER]: {
        [EPlatform.IOS]: "com.apple.social.twitter",
        [EPlatform.ANDROID]: "com.twitter.android"
      }
    };
    const platform: EPlatform = (window as any).cordova.platformId;
    return mapViaToPlatform[via][platform];
  }
}
