import { put } from "redux-saga/effects";
import { systemOpenedModal } from "../../app/actions";
import {
  updateOrderNumberForDialog,
  UserTouchedOpenDialogWithOrderNumberAction
} from "../actions";

export default function* userTouchedOpenDialogWithOrderNumberSaga(
  action: UserTouchedOpenDialogWithOrderNumberAction
) {
  yield put(updateOrderNumberForDialog(action.payload.orderNumber));
  yield put(systemOpenedModal(action.payload.modalKey, {}));
}
