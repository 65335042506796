import { List, Record, Set } from "immutable";
import CategoryTag from "./CategoryTag";
import { MaxDisplayableCookingTime } from "./SearchSetting/cookingTime";
import PriceRange from "./SearchSetting/PriceRange";
import { ResultSortType } from "./SearchSetting/resultSortType";
import ShopForSearch, { IShopForSearchProps } from "./ShopForSearch";
import {
  AnalyticsEventSearchLike,
  AnalyticsEventSearchLikeCancel
} from "../util/Analytics";

export type SearchResultType = "fastest" | "reserve" | "keyword";
export const DEFAULT_SEARCH_RESULT_TYPE: SearchResultType = "fastest";

export type ISearchResult = { [key in SearchResultType]: List<ShopForSearch> };

export interface SearchResultDisplayCondition {
  sortType: ResultSortType;
  priceRange: PriceRange;
  maxDisplayableCookingTime: MaxDisplayableCookingTime;
  filterCategoryTagList: Set<CategoryTag>;
}

const initial: ISearchResult = {
  fastest: List(),
  reserve: List(),
  keyword: List()
};

export default class SearchResult extends Record(initial) {
  public static asDefault() {
    return new SearchResult();
  }

  public static getAnalyticsFavoriteEventNameBySearchResultType = (
    type: SearchResultType,
    isFavorite: boolean
  ): AnalyticsEventSearchLike | AnalyticsEventSearchLikeCancel => {
    switch (type) {
      case "fastest":
        return isFavorite
          ? "search_fastest_like_cancel"
          : "search_fastest_like";
      case "reserve":
        return isFavorite
          ? "search_reserve_like_cancel"
          : "search_reserve_like";
      case "keyword":
        return isFavorite
          ? "search_keyword_like_cancel"
          : "search_keyword_like";
    }
  };

  private static sortResult(
    sortType: ResultSortType,
    list: List<ShopForSearch>
  ) {
    switch (sortType) {
      case "distance":
        return list.sort(ShopForSearch.sortByDistance);
      case "lowerPrice":
        return list.sort((a, b) => ShopForSearch.sortByPrice(a, b, false));
      case "higherPrice":
        return list.sort((a, b) => ShopForSearch.sortByPrice(a, b, true));
      case "openDate":
        return list.sort(ShopForSearch.sortByReleaseDatetime);
    }
  }

  private static filterResult(
    priceRange: PriceRange,
    maxDisplayableCookingTime: MaxDisplayableCookingTime,
    filterCategoryTagList: Set<CategoryTag>,
    list: List<ShopForSearch>
  ) {
    return list
      .filter(i => {
        if (!priceRange.isActive()) {
          return true;
        }
        return priceRange.contains(i.getLowestPrice());
      })
      .filter(i => {
        if (maxDisplayableCookingTime === null) {
          return true;
        }
        const fastest = i.getShopListBusinessData().getFastestCookingTime();
        if (fastest !== -1) {
          return maxDisplayableCookingTime >= fastest;
        }
        return (
          maxDisplayableCookingTime >=
          i.getShopListBusinessData().getCookingTime()
        );
      })
      .filter(i => {
        if (filterCategoryTagList.isEmpty()) {
          return true;
        }
        const categoryTagList = i.getCategoryTag();
        return categoryTagList.some(tag =>
          filterCategoryTagList
            .map(categoryTag => categoryTag.getTagName())
            .includes(tag)
        );
      });
  }

  private constructor(args: any = {}) {
    super(args);
  }

  public getList(
    type: SearchResultType,
    condition: SearchResultDisplayCondition
  ) {
    const {
      sortType,
      priceRange,
      filterCategoryTagList,
      maxDisplayableCookingTime
    } = condition;
    const sorted = SearchResult.sortResult(sortType, this.get(type)).sort(
      ShopForSearch.sortByNotBusinessReason
    );

    return SearchResult.filterResult(
      priceRange,
      maxDisplayableCookingTime,
      filterCategoryTagList,
      sorted
    );
  }

  public setList(type: SearchResultType, list: IShopForSearchProps[]) {
    return this.set(type, List(list.map(ShopForSearch.create)));
  }

  public updateFavoriteState(shopId: number, bool: boolean) {
    const typeList = ["fastest", "reserve", "keyword"] as const;
    return this.withMutations(s => {
      return typeList.reduce<SearchResult>((acc, crr) => {
        const list = this.get(crr);
        if (list.some(shop => shop.getId() === shopId)) {
          return acc.set(
            crr,
            list.map(shop =>
              shop.getId() === shopId ? shop.setFavorite(bool) : shop
            )
          );
        }
        return acc;
      }, s);
    });
  }
}
