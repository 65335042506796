import { push } from "connected-react-router";
import { List } from "immutable";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import GoogleMap from "react-google-maps/lib/components/GoogleMap";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import {
  userTouchedMapSearchPin,
  userTouchedSearchByMapLocationButton
} from "../../../modules/search/actions";
import { ILatLng } from "../../../modules/search/model";
import { SearchResultType } from "../../../records/SearchResult";
import ShopForSearch from "../../../records/ShopForSearch";
import { ReduxModel } from "../../../reducer";
import zIndexConst from "../../../styles/const/zIndexConst";
import Analytics, {
  AnalyticsEventCarouselChange as AnalyticsEventMapCarouselChange,
  AnalyticsEventClickMapCarouselItem,
  AnalyticsEventClickMapPinItem,
  AnalyticsEventMapSearchAgain,
  AnalyticsEventPanToCurrentLocationButton
} from "../../../util/Analytics";
import Geolocation from "../../../util/Geolocation";
import Utility from "../../../util/Utility";
import Fade from "../../atoms/Fade";
import CurrentLocationMarker from "../CurrentLocationMarker";
import GotoCurrentLocationButton from "../GoToCurrentLocationButton";
import MapCarousel from "../MapCarousel";
import MapViewer from "../MapViewer";
import ShopMarker from "../ShopMarker";
import MapSearchAgainButton from "./MapSearchAgainButton";

const GotoCurrentLocationButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: ${zIndexConst.ABSOLUTE - 1};
`;

const MapViewerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
`;

const SearchAgainButtonContainer = styled.div`
  position: absolute;
  top: 18px;
  left: 50%;
  z-index: ${zIndexConst.ABSOLUTE - 1};
  transform: translateX(-50%);
  white-space: nowrap;
`;

const MapCarouselContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

interface IProps {
  resultType: SearchResultType;
  shopList: List<ShopForSearch>;
  fetchingResult: boolean;
}

const MapSearchResult: React.FC<IProps> = React.memo(
  ({ resultType, shopList, fetchingResult }) => {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [movedMapCenter, setMovedMapCenterFlag] = useState(false);

    const mapRef = useRef<GoogleMap>(null);

    const currentLocation = useSelector((state: ReduxModel) =>
      state.search.getCurrentLocation()
    );

    const selectedItem = useSelector((state: ReduxModel) =>
      state.search
        .getSearchSettings()
        .getData(resultType)
        .getSelectedMapItem()
    );

    const mapLocation = useSelector((state: ReduxModel) =>
      state.search
        .getSearchSettings()
        .getData(resultType)
        .getMapLocation()
    );

    const defaultCenter = useMemo(() => {
      if (typeof selectedItem !== "undefined") {
        return selectedItem.getLocation();
      }
      if (!shopList.isEmpty()) {
        return Geolocation.getCenterOfGravity(
          shopList.map(i => i.getLocation()).toArray()
        );
      }
      return mapLocation;
    }, [mapLocation, selectedItem, shopList]);

    const listForRenderShopMapPin = useMemo(() => {
      const list = shopList.sort(ShopForSearch.sortByLatitude);
      if (typeof selectedItem === "undefined") {
        return list;
      }
      const selected = list.find(i => i.getId() === selectedItem.getId());
      if (typeof selected === "undefined") {
        return list;
      }
      return list
        .filter(i => i.getId() !== selectedItem.getId())
        .push(selected);
    }, [selectedItem, shopList]);

    const dispatch = useDispatch();

    const handleChangeMapSearchPin = useCallback(
      (item: ShopForSearch) => {
        dispatch(userTouchedMapSearchPin(resultType, item));

        const eventName = `map_${resultType}_click_map_pin_item` as AnalyticsEventClickMapPinItem;
        Analytics.logEvent(eventName, {
          content_type: "shop_id",
          item_id: `${item.getId()}`
        });
      },
      [dispatch, resultType]
    );

    const handleChangeCarousel = useCallback(
      (index: number, item: ShopForSearch) => {
        setCarouselIndex(index);
        handleChangeMapSearchPin(item);

        const eventName = `map_${resultType}_carousel_swipe` as AnalyticsEventMapCarouselChange;
        Analytics.logEvent(eventName, {
          content_type: "shop_id",
          item_id: `${item.getId()}`
        });
      },
      [handleChangeMapSearchPin, resultType]
    );

    const handleCenterChanged = useCallback(() => {
      setMovedMapCenterFlag(true);
    }, []);

    const handleClickSearchAgainButton = useCallback(() => {
      if (mapRef.current) {
        const { lat, lng } = mapRef.current.getCenter();
        const center: ILatLng = { lat: lat(), lng: lng() };
        dispatch(userTouchedSearchByMapLocationButton(resultType, center));

        const eventName = `map_${resultType}_search_again` as AnalyticsEventMapSearchAgain;
        Analytics.logEvent(eventName, {
          content_type: "latlng",
          item_id: `${center.lat},${center.lng}`
        });
      }
    }, [dispatch, resultType]);

    const handleClickGotoCurrentLocationButton = useCallback(() => {
      if (mapRef.current !== null && currentLocation !== null) {
        mapRef.current.panTo(currentLocation);
        const eventName = `map_${resultType}_click_pan_to_current_location_button` as AnalyticsEventPanToCurrentLocationButton;
        Analytics.logEvent(eventName);
      }
    }, [currentLocation, resultType]);

    const handleClickCarouselItem = useCallback(
      (shopId: number) => {
        dispatch(push(`/shop/${shopId}`));

        const eventName = `map_${resultType}_click_carousel_item` as AnalyticsEventClickMapCarouselItem;
        Analytics.logEvent(eventName, {
          content_type: "shop_id",
          item_id: `${shopId}`
        });
      },
      [dispatch, resultType]
    );

    useEffect(() => {
      if (typeof selectedItem !== "undefined") {
        const selectedIndex = shopList.findIndex(
          i => i.getId() === selectedItem.getId()
        );
        if (selectedIndex !== -1) {
          setCarouselIndex(selectedIndex);
        }
      }
    }, [selectedItem, shopList]);

    useEffect(() => {
      let canceled = false;
      const panTo = async () => {
        await Utility.sleep(500);
        if (canceled) {
          return;
        }
        if (mapRef.current !== null && typeof selectedItem !== "undefined") {
          mapRef.current.panTo(selectedItem.getLocation());
        }
      };
      panTo();

      return () => {
        canceled = true;
      };
    }, [mapLocation, selectedItem]);

    useEffect(() => {
      if (mapRef.current !== null) {
        mapRef.current.panTo(mapLocation);
      }
    }, [mapLocation]);

    return (
      <MapViewerContainer>
        <MapViewer
          containerElement={<MapContainer />}
          defaultCenter={defaultCenter}
          mapRef={mapRef}
          onCenterChanged={handleCenterChanged}
        >
          <TransitionGroup>
            {movedMapCenter ? (
              <Fade durationSeconds={0.15}>
                <SearchAgainButtonContainer>
                  <MapSearchAgainButton
                    handleClick={handleClickSearchAgainButton}
                  />
                </SearchAgainButtonContainer>
              </Fade>
            ) : null}
          </TransitionGroup>

          {currentLocation !== null ? (
            <GotoCurrentLocationButtonContainer
              onClick={handleClickGotoCurrentLocationButton}
            >
              <GotoCurrentLocationButton />
            </GotoCurrentLocationButtonContainer>
          ) : null}

          {currentLocation !== null ? (
            <CurrentLocationMarker position={currentLocation} />
          ) : null}

          {listForRenderShopMapPin.map((i, index) => (
            <ShopMarker
              key={index} // to force update by index
              isOpen={i.isOpen()}
              selected={
                typeof selectedItem !== "undefined" &&
                i.getId() === selectedItem.getId()
              }
              location={i.getLocation()}
              handleClick={() => handleChangeMapSearchPin(i)}
            />
          ))}

          <MapCarouselContainer>
            <MapCarousel
              shopList={shopList}
              currentIndex={carouselIndex}
              handleChange={handleChangeCarousel}
              handleClickItem={handleClickCarouselItem}
              selectedItem={selectedItem}
              fetchingResult={fetchingResult}
            />
          </MapCarouselContainer>
        </MapViewer>
      </MapViewerContainer>
    );
  }
);

export default MapSearchResult;
