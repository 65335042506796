import TWEEN from "@tweenjs/tween.js";
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * 一定時間立ったら折れるフラグを生成する
 *
 * @param initialFlag 初期値となる真偽値
 * @param duration フラグがたってから元に戻るまでの時間(ms)
 */
export function useSetTimeoutFlag(initialFlag: boolean, duration: number) {
  const [flag, setFlag] = useState(initialFlag);

  const timeoutId = useRef(0);
  useEffect(() => {
    if (initialFlag !== flag) {
      timeoutId.current = setTimeout(() => setFlag(initialFlag), duration);
    }
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [duration, flag, initialFlag]);

  return [flag, setFlag] as [typeof flag, typeof setFlag];
}

export function useAnimate() {
  const [abortFlag, setAbortFlag] = useState(false);

  const stop = useCallback(() => {
    setAbortFlag(true);
  }, [setAbortFlag]);

  const animate = useCallback(() => {
    if (abortFlag) {
      return;
    }
    requestAnimationFrame(animate);
    TWEEN.update();
  }, [abortFlag]);

  const start = useCallback(() => {
    setAbortFlag(false);
    animate();
  }, [setAbortFlag, animate]);

  return [start, stop];
}

export function useForceUpdate() {
  const [, rerender] = useState(true);
  return () => rerender(b => !b);
}

export function useWhyDidYouUpdate(name: string, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef({} as any);

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({
        ...previousProps.current,
        ...props
      }) as any[];

      // Use this object to keep track of changed props
      const changesObj: any = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}
