import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducer";
import rootSaga from "./sagas";

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
  const sagaMonitor = (window as any).__SAGA_MONITOR_EXTENSION__;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const store =
    process.env.NODE_ENV !== "production"
      ? createStore(
          createRootReducer(history),
          initialState,
          composeWithDevTools(
            applyMiddleware(routerMiddleware(history), sagaMiddleware)
          )
        )
      : createStore(
          createRootReducer(history),
          initialState,
          applyMiddleware(routerMiddleware(history), sagaMiddleware)
        );
  sagaMiddleware.run(rootSaga);
  return store;
}
