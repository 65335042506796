import { cancel, fork, join, put, select } from "redux-saga/effects";
import {
  ISearchShopWithFastestRequest,
  ISearchShopWithFastestResponse
} from "../../../apis/searchShopWithFastest";
import { EApiKey } from "../../../apis";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { ReduxModel } from "../../../reducer";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import PlaceListItem, {
  ERegistrationPlace
} from "../../../records/PlaceListItem";
import { updateNearOfficeShopList } from "../actions";

export default function* userAccessedToPageThatNeedsNearOfficeShopListSaga() {
  try {
    const searchLocation: PlaceListItem | undefined = yield select<
      (state: ReduxModel) => PlaceListItem | undefined
    >(state =>
      state.user
        .getData()
        .getPlaceListItemByType(ERegistrationPlace.OFFICE_SCHOOL)
    );

    if (typeof searchLocation === "undefined") {
      throw new Error("location is null");
    }

    const params: ISearchShopWithFastestRequest = {
      latitude: `${searchLocation.latitude}`,
      longitude: `${searchLocation.longitude}`,
      distance: 500
    };

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.SEARCH_SHOP_WITH_FASTEST, params)
    );
    const {
      result,
      error
    }: {
      result: ISearchShopWithFastestResponse;
      error: unknown;
    } = yield join(commonApiTask);

    if (error) {
      throw error;
    }

    yield put(updateNearOfficeShopList(result.shop_list));
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
