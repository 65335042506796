import { SearchResultType } from "../records/SearchResult";
import {
  ISearchShopWithFastestRequest,
  ISearchShopWithFastestResponse
} from "./searchShopWithFastest";
import {
  ISearchShopWithKeywordRequest,
  ISearchShopWithKeywordResponse
} from "./searchShopWithKeyword";
import {
  ISearchShopWithReserveRequest,
  ISearchShopWithReserveResponse
} from "./searchShopWithReserve";

export enum EApiKey {
  // 検索関連

  /**
   * いますぐ受け取り検索
   */
  SEARCH_SHOP_WITH_FASTEST = "searchShopWithFastest",

  /**
   * 予約注文検
   */
  SEARCH_SHOP_WITH_RESERVE = "searchShopWithReserve",

  /**
   * キーワード検索
   */
  SEARCH_SHOP_WITH_KEYWORD = "searchShopWithKeyword",

  /**
   * 検索履歴取得
   * @deprecated
   */
  GET_SEARCH_HISTORY_LIST = "getSearchHistoryList",

  /**
   * カテゴリータグ一覧取得
   */
  GET_CATEGORY_TAG_LIST = "getCategoryTagList",

  /**
   * 店舗情報の取得
   */
  GET_SHOP_DATA_WITH_TIME_TYPE = "getShopDataWithTimeType",

  // カート関連

  /**
   * カートの情報を取得
   */
  GET_CART_LIST = "getCartList",

  /**
   * メニューをカートに追加
   */
  ADD_CART_LIST = "addCartList",

  /**
   * カートからメニューを削除
   */
  DELETE_CART_LIST_ITEM = "deleteCartListItem",

  /**
   * カート内のメニュー内容を更新
   */
  UPDATE_CART_LIST = "updateCartList",

  /**
   * 店舗を指定してカートを空にする
   */
  CLEAR_CART_LIST_WITH_SHOP = "clearCartListWithShop",

  // 注文関連

  /**
   * 注文を送信する
   */
  ORDER_CART_LIST = "orderCartList",

  /**
   * 送信ずみの注文をキャンセルする
   */
  CANCEL_ORDER = "cancelOrder",

  /**
   * 注文履歴を取得
   */
  GET_ORDER_HISTORY = "getOrderHistory",

  /**
   * 注文履歴の詳細を取得
   */
  GET_ORDER_DETAIL = "getOrderDetail",

  // 登録、ログイン関連

  /**
   * メールアドレス＆パスワード
   */
  UPDATE_MAIL_ADDRESS_AND_PASSWORD = "updateMailAddressAndPassword",

  /**
   * ログイン
   */
  LOGIN_USER = "loginUser",

  // ユーザー関連情報

  /**
   * ユーザーデータ取得
   */
  GET_USER_DATA = "getUserData",

  // クレジットカード関連

  /**
   * クレジットカード登録
   */
  REGISTER_CREDIT_CARD = "registerCreditCard",

  /**
   * クレジットカード情報取得
   */
  GET_CREDIT_CARD_LIST = "getCreditCardList",

  /**
   * どのクレジットカードを使うかを変更
   */
  UPDATE_SELECT_CREDIT_CARD = "updateSelectCreditCard",

  /**
   * クレジットカードを追加登録
   */
  ADD_CREDIT_CARD = "addCreditCard",

  // ユーザー情報更新関連

  /**
   * ニックネーム登録・更新
   */
  UPDATE_NICK_NAME = "updateNickName",

  /**
   * 登録メールアドレス更新
   */
  UPDATE_MAIL_ADDRESS = "updateMailAddress",

  /**
   * 電話番号登録・更新
   */
  UPDATE_TEL = "updateTel",

  /**
   * 登録パスワード変更
   */
  UPDATE_PASSWORD = "updatePassword",

  // クーポンコード関連

  /**
   * クーポンコード入力
   */
  INPUT_DISCOUNT_CODE = "inputDiscountCode",

  /**
   * 割引クーポン取得
   */
  GET_USER_COUPON_LIST = "getUserCouponList",

  // お知らせ関連

  /**
   * お知らせとニュースの取得
   */
  GET_INFORMATION = "getInformation",

  // 認証関連

  /**
   * SMS認証コード発行
   */
  PUBLISH_SMS = "publishSms",

  /**
   * 電話番号認証、架電で認証コードが通知される
   */
  REPUBLISH_SMS = "republishSms",

  /**
   * 電話番号からSMS認証コードを発行
   */
  PUBLISH_SMS_BY_TEL = "publishSmsByTel",

  /**
   * SMS認証
   */
  AUTH_SMS = "authSms",

  /**
   * 電話番号を元に発行されたSMS認証コードを認証
   */
  AUTH_SMS_BY_TEL = "authSmsByTel",

  /**
   * ユーザー情報更新
   */
  UPDATE_USER_DATA = "updateUserData",

  /**
   * ゲストユーザー登録
   */
  REGISTER_GUEST_USER = "registerGuestUser",

  /**
   * ゲストユーザーの登録情報更新
   */
  UPDATE_GUEST_USER = "updateGuestUser",

  /**
   * お気に入りステート更新
   */
  UPDATE_SHOP_FAVORITE_STATE = "updateShopFavoriteState",

  /**
   * フィードバックを送信する
   */
  SEND_FEEDBACK = "sendFeedback",

  /**
   * 起動時に叩くやつ
   */
  LAUNCH = "launch",

  /**
   * 店舗到着通知送信
   */
  ARRIVAL_FOR_LUXURY = "arrivalForLuxury",

  /**
   * ホーム画面の情報を取得する
   */
  GET_HOME = "getHome",

  /**
   * 特集ページ詳細
   */
  GET_FEATURE_DETAIL = "getFeatureDetail",

  /**
   * 登録地点の更新
   */
  UPDATE_PLACE = "updatePlace",

  /**
   * 領収書をメールで送る
   */
  SEND_RECEIPT_MAIL = "sendReceiptMail",

  /**
   * サブエリアリスト取得
   */
  GET_SUB_AREA_LIST = "getSubAreaList",

  /**
   * エリア店舗リスト取得
   */
  GET_SHOP_LIST_WITH_AREA = "getShopListWithArea",

  /**
   * カテゴリー店舗リスト取得
   */
  GET_SHOP_LIST_BY_CATEGORY = "getShopListByCategory",

  /**
   * HomeListTypeによる全件取得
   */
  GET_SHOP_LIST_BY_TYPE = "getShopListByType",

  /**
   * エリア拡大希望
   */
  SEND_AREA_EXPANSION_DESIRED = "sendAreaExpansionDesired",

  /**
   * 店舗掲載希望
   */
  SEND_SHOP_POSTING_DESIRED = "sendShopPostingDesired"
}

type SearchApiKey =
  | typeof EApiKey.SEARCH_SHOP_WITH_FASTEST
  | typeof EApiKey.SEARCH_SHOP_WITH_RESERVE
  | typeof EApiKey.SEARCH_SHOP_WITH_KEYWORD;

export const searchApiKey: { [key in SearchResultType]: SearchApiKey } = {
  fastest: EApiKey.SEARCH_SHOP_WITH_FASTEST,
  reserve: EApiKey.SEARCH_SHOP_WITH_RESERVE,
  keyword: EApiKey.SEARCH_SHOP_WITH_KEYWORD
};

export interface SearchApiRequest {
  ["fastest"]: ISearchShopWithFastestRequest;
  ["reserve"]: ISearchShopWithReserveRequest;
  ["keyword"]: ISearchShopWithKeywordRequest;
}

export interface SearchApiResponse {
  ["fastest"]: ISearchShopWithFastestResponse;
  ["reserve"]: ISearchShopWithReserveResponse;
  ["keyword"]: ISearchShopWithKeywordResponse;
}
