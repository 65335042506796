import { put, select } from "redux-saga/effects";
import Alert from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { openModal, SystemAddedAlertAction } from "../actions";

export default function* systemAddedAlertSaga(action: SystemAddedAlertAction) {
  const alert = yield select<(state: ReduxModel) => Alert>(state =>
    state.app.createAlert(action.payload.alertProps)
  );
  yield put(openModal("COMMON_ALERT", { alert }));
}
