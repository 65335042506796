export type MaxDisplayableCookingTime = 10 | 20 | 30 | null;
export const DEFAULT_MAX_DISPLAYABLE_COOKING_TIME = null;

export const toSliderValueFromSetting = (
  setting: MaxDisplayableCookingTime
): number => {
  switch (setting) {
    case null:
      return 100;
    case 10:
      return 0;
    case 20:
      return 33;
    case 30:
      return 66;
    default:
      return 100;
  }
};

export const toSettingFromSliderValue = (
  value: number
): MaxDisplayableCookingTime => {
  switch (value) {
    case 0:
      return 10;
    case 33:
      return 20;
    case 66:
      return 30;
    case 100:
      return null;
    default:
      return null;
  }
};
