import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgHome = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 0L17 6.424l-.605.659-1.064-.796V16H1.67V6.287l-1.064.796L0 6.424 8.5 0zm0 1.178l5.92 4.428v9.513H2.58V5.606L8.5 1.178zM6.678 7.634h1.366v1.321H6.678v-1.32zm0 2.202v1.32h1.366v-1.32H6.678zm2.277 0v1.32h1.367v-1.32H8.954zm1.367-.881v-1.32H8.954v1.32h1.367zm-4.555 3.082V6.753h5.465v5.284H5.767z"
      fill="#3A3A47"
    />
  </svg>
);

const HomePlaceIcon: React.FC<IProps> = props => <SvgHome {...props} />;

export default HomePlaceIcon;
