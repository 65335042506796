import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetShopListWithAreaRequest,
  IGetShopListWithAreaResponse
} from "../../../apis/getShopListWithArea";
import { ReduxModel } from "../../../reducer";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import Area from "../../../records/Area";
import SubArea from "../../../records/SubArea";
import {
  UserAccessedToPageThatNeedsAreaShopListAction,
  updateCurrentSubArea,
  updateCurrentAreaShopList
} from "../actions";
import { SUB_AREA_ID_WHEN_GET_AREA_ALL_SHOP } from "../model";

export default function* userAccessedToPageThatNeedsAreaShopListSaga(
  action: UserAccessedToPageThatNeedsAreaShopListAction
) {
  try {
    const currentArea: Area | undefined = yield select<
      (state: ReduxModel) => Area | undefined
    >(state =>
      state.search
        .getAreaList()
        .find(area => area.getId() === action.payload.areaId)
    );

    if (typeof currentArea === "undefined") {
      throw new Error("currentArea is null");
    }

    if (action.payload.subAreaId !== SUB_AREA_ID_WHEN_GET_AREA_ALL_SHOP) {
      const currentSubArea: SubArea | undefined = yield select<
        (state: ReduxModel) => SubArea | undefined
      >(state =>
        state.search
          .getCurrentSubAreaList()
          .find(subArea => subArea.getId() === action.payload.subAreaId)
      );

      if (typeof currentSubArea === "undefined") {
        throw new Error("currentSubArea is null");
      } else {
        yield put(updateCurrentSubArea(currentSubArea));
      }
    }

    const params: IGetShopListWithAreaRequest = {
      area_id: action.payload.areaId,
      sub_area_id: action.payload.subAreaId
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_SHOP_LIST_WITH_AREA, params)
    );
    const {
      result,
      error
    }: {
      result: IGetShopListWithAreaResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateCurrentAreaShopList(result.shop_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
