import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgComponent = (props: IProps) => (
  <svg viewBox="0 0 110 110" {...props}>
    <g fill="#ffffff">
      <path d="M44.1,31c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.1,0.5v11.5c0,2.1,0.9,3.9,2.2,4.4v31 c0,0.3,0.3,0.6,0.6,0.6h0.1c0.3,0,0.6-0.3,0.6-0.6v-31c1.3-0.5,2.2-2.3,2.2-4.4V31.6c0-0.3-0.3-0.5-0.6-0.5c-0.2,0-0.3,0.1-0.5,0.2 c-0.1,0.1-0.2,0.3-0.2,0.5v7.4c0,0.5-0.2,1-0.5,1s-0.5-0.5-0.5-1v-7.5c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.5-0.2-0.7-0.1 c-0.2,0.1-0.3,0.4-0.3,0.7v7.4c0,0.5-0.2,1-0.5,1s-0.5-0.5-0.5-1v-7.5c0-0.2,0-0.3-0.2-0.5C44.3,31,44.1,30.9,44.1,31L44.1,31 L44.1,31z" />
      <path d="M65.9,30.9h-0.3c-0.5,0.1-0.9,0.3-1.2,0.6c-1.3,1.5-2.3,4-2.9,7.4c-0.5,3.1-0.8,6.2-0.9,9.3v3.5 c0,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3h3.6v25.6c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5 V31.2c0-0.2-0.1-0.3-0.2-0.4C66.2,30.9,66,30.8,65.9,30.9L65.9,30.9z" />
    </g>
  </svg>
);

const MenuIcon = React.memo((props: IProps) => <SvgComponent {...props} />);

export default MenuIcon;
