import { List, Record } from "immutable";
import ShopForSearch, { IShopForSearchProps } from "./ShopForSearch";

export type HomeListType = keyof IHomeList;
export type HomeSliderListType = HomeListType | "shop_near_office_list";

export const displayableHomeList = List<HomeListType>([
  "shop_popular_list",
  "shop_topic_list",
  "shop_luxury_list",
  "shop_favorite_list"
] as const);

export enum EFetchShopListType {
  FAVORITE = 1,
  HISTORY = 2,
  POPULAR = 3,
  TOPIC = 4,
  LUXURY = 5
}

export interface IHomeListProps {
  shop_favorite_list: IShopForSearchProps[];
  shop_popular_list: IShopForSearchProps[];
  shop_luxury_list: IShopForSearchProps[];
  shop_topic_list: IShopForSearchProps[];
}

export interface IHomeList {
  shop_favorite_list: List<ShopForSearch>;
  shop_popular_list: List<ShopForSearch>;
  shop_topic_list: List<ShopForSearch>;
  shop_luxury_list: List<ShopForSearch>;
}

const initial: IHomeList = {
  shop_favorite_list: List<ShopForSearch>(),
  shop_popular_list: List<ShopForSearch>(),
  shop_topic_list: List<ShopForSearch>(),
  shop_luxury_list: List<ShopForSearch>()
};

export default class HomeList extends Record(initial) {
  public static create(list: IHomeListProps) {
    return new HomeList(list);
  }

  public static asDefault() {
    return new HomeList();
  }

  public static getFetchType(type: HomeListType) {
    switch (type) {
      case "shop_favorite_list":
        return EFetchShopListType.FAVORITE;
      case "shop_popular_list":
        return EFetchShopListType.POPULAR;
      case "shop_topic_list":
        return EFetchShopListType.TOPIC;
      case "shop_luxury_list":
        return EFetchShopListType.LUXURY;
    }
  }

  public static getTitle(type: HomeListType) {
    switch (type) {
      case "shop_favorite_list":
        return "お気に入りのお店";
      case "shop_luxury_list":
        return "高級店";
      case "shop_topic_list":
        return "話題のお店";
      case "shop_popular_list":
        return "人気のお店";
      default:
        return "";
    }
  }

  private constructor(args: Partial<IHomeListProps> = {}) {
    super(
      Object.assign({}, args, {
        shop_favorite_list: List(
          args.shop_favorite_list
            ? args.shop_favorite_list.map(ShopForSearch.create)
            : []
        ),
        shop_popular_list: List(
          args.shop_popular_list
            ? args.shop_popular_list.map(ShopForSearch.create)
            : []
        ),
        shop_topic_list: List(
          args.shop_topic_list
            ? args.shop_topic_list.map(ShopForSearch.create)
            : []
        ),
        shop_luxury_list: List(
          args.shop_luxury_list
            ? args.shop_luxury_list.map(ShopForSearch.create)
            : []
        )
      })
    );
  }

  public getList(type: HomeListType) {
    return this.get(type);
  }

  public updateFavoriteState(shopId: number, bool: boolean) {
    return this.withMutations(s => {
      return displayableHomeList.reduce((acc, crr) => {
        const list = this.getList(crr);
        if (list.some(i => i.getId() === shopId)) {
          return acc.set(
            crr,
            list.map(i => (i.getId() === shopId ? i.setFavorite(bool) : i))
          );
        }
        return acc;
      }, s);
    });
  }
}
