import { Record } from "immutable";
import CordovaUtil, { EPlatform } from "../util/CordovaUtil";

export interface IUpdateBuildPayload {
  message: string;
  app_store_url: string;
  google_play_url: string;
  latest_version: string;
}

const initial: IUpdateBuildPayload = {
  message: "",
  app_store_url: "",
  google_play_url: "",
  latest_version: ""
};

export default class UpdateBuildPayload extends Record(initial) {
  public getMessage() {
    return this.get("message");
  }

  public getLatestVersion() {
    return this.get("latest_version");
  }

  public getStoreUrl() {
    const platform = CordovaUtil.getPlatform();
    return platform === EPlatform.IOS
      ? this.get("app_store_url")
      : this.get("google_play_url");
  }
}
