import { put, select } from "redux-saga/effects";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { updateSearchSetting, UserChangedSortTypeAction } from "../actions";

export default function* userChangedSortTypeSaga(
  action: UserChangedSortTypeAction
) {
  const { resultType, sortType } = action.payload;

  const currentSetting: SearchSetting = yield select<
    (state: ReduxModel) => SearchSetting
  >(state => state.search.getSearchSettings().getData(resultType));

  yield put(
    updateSearchSetting(resultType, currentSetting.setSortType(sortType))
  );
}
