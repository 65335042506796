import { goBack } from "connected-react-router";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import { ReduxModel } from "../../reducer";
import {
  IUpdateTelForm,
  initialUpdateTelForm,
  validateUpdateTelForm
} from "../../forms/updateTel";
import { userSubmittedUpdateTel } from "../../modules/user/actions";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import InputTel from "../organisms/InputTel";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const UpdateTelTemplate: React.FC<
  IProps & InjectedFormProps<IUpdateTelForm>
> = React.memo(props => {
  const {
    submittingUpdateTel,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialUpdateTelForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Page
      header={
        <SubPageHeader title="電話番号" handleClickBack={handleClickBack} />
      }
      containerStyle={containerStyle}
    >
      <PageBody>
        <InputTel
          handleSubmit={handleSubmitWrap}
          loading={submittingUpdateTel}
          disabled={!valid}
        />
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingUpdateTel: state.app.isConnectedApi(EApiKey.UPDATE_TEL),
  initialValues: { tel: state.user.getData().getTel() }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },
  handleSubmit() {
    dispatch(userSubmittedUpdateTel());
  }
});

const reduxFormConfig = {
  form: EFormID.UPDATE_TEL,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateUpdateTelForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<IUpdateTelForm, IProps>(reduxFormConfig)(UpdateTelTemplate));
