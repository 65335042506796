import React from "react";
import styled from "styled-components";
import ShopForSearch from "../../../records/ShopForSearch";

const CookingTimeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 0 0 auto;
  padding-bottom: 4px;
  border-bottom: 2px solid #ff3008;
`;

const CookingTimeInfoKey = styled.div`
  font-size: 10px;
  line-height: ${14 / 10};
  font-weight: 500;
  color: #595959;
`;

const CookingTimeInfoValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #272727;
`;

const CookingTimeInfoUnit = styled.span`
  font-size: 10px;
  line-height: ${14 / 10};
  font-weight: 500;
  color: #272727;
`;

interface IProps {
  item: ShopForSearch;
}

const ShopCookingTimeInfo: React.FC<IProps> = React.memo(({ item }) => {
  return (
    <CookingTimeInfo>
      <CookingTimeInfoKey>{"出来上がり"}</CookingTimeInfoKey>
      <CookingTimeInfoValue>
        {item
          .getShopBusinessDisplayCookingTime()
          .split("")
          .map((char, index) => {
            const isLastChar =
              item.getShopBusinessDisplayCookingTime().split("").length ===
              index + 1;
            return isLastChar ? (
              <CookingTimeInfoUnit key={index}>{char}</CookingTimeInfoUnit>
            ) : (
              char
            );
          })}
      </CookingTimeInfoValue>
    </CookingTimeInfo>
  );
});

export default ShopCookingTimeInfo;
