import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 1H1V2H19V1ZM19 4H1V12H19V4ZM0 0V13H20V0H0ZM3 7H6V10H3V7ZM13 10H10V9H13V10ZM10 8H17V7H10V8Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const PaymentIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default PaymentIcon;
