import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import fetchCreditCardSaga from "./sagas/getCreditCardSaga";
import updateMailAddressAndPasswordSaga from "./sagas/updateMailAddressAndPasswordSaga";
import updateMailAddressSaga from "./sagas/updateMailAddressSaga";
import updatePasswordSaga from "./sagas/updatePasswordSaga";
import {
  registerNickNameSaga,
  registerNickNameAtOrderSaga,
  skipRegisterNickNameSaga
} from "./sagas/registerNickNameSaga";
import { loginUserSaga } from "./sagas/loginUserSaga";
import {
  registerAuthAtFirstAccessSaga,
  registerAuthSaga
} from "./sagas/registerAuthSaga";
import {
  registerTelAtFirstAccessSaga,
  updateTelSaga
} from "./sagas/updateTelSaga";
import requestToResentSMSAuthCodeForResetPasswordSaga from "./sagas/requestToResentSMSAuthCodeForResetPasswordSaga";
import { requestToResentSMSAuthCodeSaga } from "./sagas/requestToResentSMSAuthCodeSaga";
import userAccessedMaintenancePageSaga from "./sagas/userAccessedMaintenancePageSaga";
import { userAccessedToPageThatNeedsAuthSaga } from "./sagas/userAccessedToPageThatNeedsAuthSaga";
import userAccessedToPageThatNeedsDiscountCodesSaga from "./sagas/userAccessedToPageThatNeedsDiscountCodesSaga";
import userAccessedToPageThatNeedsUserDataSaga from "./sagas/userAccessedToPageThatNeedsUserDataSaga";
import userLoggedInSaga from "./sagas/userLoggedInSaga";
import userSubmittedAuthResetPasswordSaga from "./sagas/userSubmittedAuthResetPasswordSaga";
import userSubmittedChangeSelectCreditCardSaga from "./sagas/userSubmittedChangeSelectCreditCardSaga";
import {
  userSubmittedDiscountCodeSaga,
  userSubmittedDiscountCodeAtFisrtAccessSaga
} from "./sagas/userSubmittedDiscountCodeSaga";
import userSubmittedRegisterNewCreditCardSaga from "./sagas/userSubmittedRegisterNewCreditCardSaga";
import userSubmittedRegistrationPlaceSaga from "./sagas/userSubmittedRegistrationPlaceSaga";
import { userSubmittedRequestResetPasswordSaga } from "./sagas/userSubmittedRequestResetPasswordSaga";
import { userSubmittedResetPasswordSaga } from "./sagas/userSubmittedResetPasswordSaga";
import userTouchedGuestLoginButtonSaga from "./sagas/userTouchedGuestLoginButtonSaga";
import userTouchedLogoutButtonSaga from "./sagas/userTouchedLogoutButtonSaga";
import userTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga from "./sagas/userTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga";
import userTouchedSkipInputDiscountCodeButtonSaga from "./sagas/userTouchedSkipInputDiscountCodeButtonSaga";
import userTouchedToButtonThatReturnFromMaintenancePageSaga from "./sagas/userTouchedToButtonThatReturnFromMaintenancePageSaga";
import userSubmittedRequestAreaSaga from "./sagas/userSubmittedRequestAreaSaga";
import userSubmittedRequestShopSaga from "./sagas/userSubmittedRequestShopSaga";

export default function* userSaga() {
  yield fork(() => watchRequestToResentSMSAuthCodeSaga());
  yield fork(() => watchRequestToResentSMSAuthCodeForResetPasswordSaga());
  yield fork(() => watchRegisterTelAtFirstAccessSaga());
  yield fork(() => watchUpdateTelSaga());
  yield fork(() => watchRegisterAuthAtfirstAccessSaga());
  yield fork(() => watchRegisterAuthSaga());
  yield fork(() => watchRegisterMailAddressAndPasswordSaga());
  yield fork(() => watchRegisterNickNameSaga());
  yield fork(() => watchRegisterNickNameAtOrderSaga());
  yield fork(() => watchTouchedSkipRegisterNickNameSaga());
  yield fork(() => watchUpdateMailAddressSaga());
  yield fork(() => watchUpdatePasswordSaga());
  yield fork(() => watchLoginUserSaga());
  yield fork(() => watchUserSubmittedRequestResetPasswordSaga());
  yield fork(() => watchUserSubmittedResetPasswordSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsAuthSaga());
  yield fork(() => watchUserSubmittedAuthResetPasswordSaga());
  yield fork(() => watchUserLoggedInSaga());
  yield fork(() => watchUserSubmittedChangeSelectCreditCardSaga());
  yield fork(() => watchUserSubmittedRegisterNewCreditCardSaga());
  yield fork(() => watchUserTouchedLogoutButtonSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsDiscountCodesSaga());
  yield fork(() => watchUserSubmittedDiscountCodeSaga());
  yield fork(() => watchUserSubmittedDiscountCodeAtFisrtAccessSaga());
  yield fork(() => watchUserTouchedSkipInputDiscountCodeButtonSaga());
  yield fork(() => watchUserAccessedToPageThatNeedsCreditCardDataSaga());
  yield fork(() => watchUserAccessedMaintenancePageSaga());
  yield fork(() => watchUserTouchedToButtonThatReturnFromMaintenancePageSaga());
  yield fork(() => watchUserTouchedGuestLoginButtonSaga());
  yield fork(() =>
    watchUserTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga()
  );
  yield fork(() => watchUserAccessedToPageThatNeedsUserDataSaga());
  yield fork(() => watchUserSubmittedRegistrationPlaceSaga());
  yield fork(() => watchUserSubmittedRequestAreaSaga());
  yield fork(() => watchUserSubmittedRequestShopSaga());
}

function* watchUserSubmittedRegistrationPlaceSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTRATION_PLACE,
    userSubmittedRegistrationPlaceSaga
  );
}

function* watchUserAccessedToPageThatNeedsUserDataSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_USER_DATA,
    userAccessedToPageThatNeedsUserDataSaga
  );
}

function* watchUserTouchedGuestLoginButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_GUEST_LOGIN_BUTTON,
    userTouchedGuestLoginButtonSaga
  );
}

function* watchUserTouchedToButtonThatReturnFromMaintenancePageSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_TO_BUTTON_THAT_RETURN_FROM_MAINTENANCE_PAGE,
    userTouchedToButtonThatReturnFromMaintenancePageSaga
  );
}

function* watchUserAccessedMaintenancePageSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_MAINTENANCE_PAGE,
    userAccessedMaintenancePageSaga
  );
}

function* watchUserAccessedToPageThatNeedsCreditCardDataSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CREDIT_CARD_DATA,
    fetchCreditCardSaga
  );
}

// ユーザーがクーポンコードを送信した
function* watchUserSubmittedDiscountCodeSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DISCOUNT_CODE,
    userSubmittedDiscountCodeSaga
  );
}
function* watchUserSubmittedDiscountCodeAtFisrtAccessSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DISCOUNT_CODE_AT_FIRST_ACCESS,
    userSubmittedDiscountCodeAtFisrtAccessSaga
  );
}
function* watchUserTouchedSkipInputDiscountCodeButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SKIP_INPUT_DISCOUNT_CODE_BUTTON,
    userTouchedSkipInputDiscountCodeButtonSaga
  );
}

// ユーザーがクーポンコードの情報が必要なページにアクセスした
function* watchUserAccessedToPageThatNeedsDiscountCodesSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_DISCOUNT_CODES,
    userAccessedToPageThatNeedsDiscountCodesSaga
  );
}

// ユーザーがログアウトボタンをタッチした
function* watchUserTouchedLogoutButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_LOGOUT_BUTTON,
    userTouchedLogoutButtonSaga
  );
}

// ユーザーが新しいクレジットカード登録をサブミットした
function* watchUserSubmittedRegisterNewCreditCardSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_NEW_CREDIT_CARD,
    userSubmittedRegisterNewCreditCardSaga
  );
}

// ユーザーが使用するクレジットカード変更をサブミットした
function* watchUserSubmittedChangeSelectCreditCardSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_CHANGE_SELECT_CREDIT_CARD,
    userSubmittedChangeSelectCreditCardSaga
  );
}

// ユーザーがログインした時
function* watchUserLoggedInSaga() {
  yield takeLatest(ActionTypes.U_LOGGED_IN, userLoggedInSaga);
}

// パスワードリセット用のSMS認証
function* watchUserSubmittedAuthResetPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_AUTH_RESET_PASSWORD,
    userSubmittedAuthResetPasswordSaga
  );
}

// ユーザー認証が必要なページへのアクセス時
function* watchUserAccessedToPageThatNeedsAuthSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AUTH,
    userAccessedToPageThatNeedsAuthSaga
  );
}

// SMS認証コードの再送信のアクション
function* watchRequestToResentSMSAuthCodeSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_BUTTON,
    requestToResentSMSAuthCodeSaga
  );
}

// パスワードリセット用のSMS認証コードの再送信のアクション
function* watchRequestToResentSMSAuthCodeForResetPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_FOR_RESET_PASSWORD_BUTTON,
    requestToResentSMSAuthCodeForResetPasswordSaga
  );
}

// パスワードリセットのリクエスト
function* watchUserSubmittedRequestResetPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REQUEST_RESET_PASSOWORD,
    userSubmittedRequestResetPasswordSaga
  );
}

// パスワードリセット
function* watchUserSubmittedResetPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESET_PASSOWORD,
    userSubmittedResetPasswordSaga
  );
}

// 初回起動時電話番号登録
function* watchRegisterTelAtFirstAccessSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_TEL_AT_FIRST_ACCESS,
    registerTelAtFirstAccessSaga
  );
}

// 電話番号登録
function* watchUpdateTelSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_UPDATE_TEL, updateTelSaga);
}

// メールアドレス・パスワード登録
function* watchRegisterMailAddressAndPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS_AND_PASSWORD,
    updateMailAddressAndPasswordSaga
  );
}

// メールアドレス変更
function* watchUpdateMailAddressSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS,
    updateMailAddressSaga
  );
}

// パスワード変更

function* watchUpdatePasswordSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_UPDATE_PASSWORD, updatePasswordSaga);
}

// ニックネーム登録
function* watchRegisterNickNameSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME,
    registerNickNameSaga
  );
}

// ニックネーム登録（注文時）
function* watchRegisterNickNameAtOrderSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME_AT_ORDER,
    registerNickNameAtOrderSaga
  );
}

function* watchTouchedSkipRegisterNickNameSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SKIP_REGISTER_NICK_NAME,
    skipRegisterNickNameSaga
  );
}

// SMS認証（初回ログイン時）
function* watchRegisterAuthAtfirstAccessSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REGISTER_AUTH_AT_FIRST_ACCESS,
    registerAuthAtFirstAccessSaga
  );
}

// SMS認証
function* watchRegisterAuthSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_REGISTER_AUTH, registerAuthSaga);
}

// ユーザーログイン
function* watchLoginUserSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_LOGIN, loginUserSaga);
}

// 架電での認証コード通知のリクエスト
function* watchUserTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_REQUEST_TO_NOTIFY_AUTH_CODE_BY_VIRTUAL_CALL_BUTTON,
    userTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga
  );
}

function* watchUserSubmittedRequestAreaSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REQUEST_AREA,
    userSubmittedRequestAreaSaga
  );
}

function* watchUserSubmittedRequestShopSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_REQUEST_SHOP,
    userSubmittedRequestShopSaga
  );
}
