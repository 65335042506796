import React from "react";
import OverlayView from "react-google-maps/lib/components/OverlayView";
import styled from "styled-components";
import { ILatLng } from "../../modules/search/model";

const Container = styled.div<{ selected?: boolean }>`
  transform-origin: bottom center;
  transform: scale(${p => (p.selected ? 1.5 : 1)});
`;

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -height
});

interface IProps {
  location: ILatLng;
  isOpen: boolean;
  selected?: boolean;
  handleClick?: () => void;
  size?: number;
  children?: never;
}

const ShopMarker: React.FC<IProps> = React.memo(
  ({ size = 31, selected, isOpen: active, location, handleClick }) => {
    const ratio = 42 / size;
    return (
      <OverlayView
        position={location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <Container onClick={handleClick} selected={selected}>
          <svg width={size} height={size * ratio} fill="none">
            {active ? (
              <>
                <path
                  d="M15.408.939h-.003c-7.943.014-14.4 6.48-14.4 14.427 0 4.531 2.23 8.635 6.45 12.182.344.289.652.555.964.826 1.56 1.514 2.564 2.5 3.357 3.683.782 1.166 1.38 2.56 2.03 4.898a1.8 1.8 0 00.562.965c.294.255.654.38 1.017.39h.043c.363-.01.723-.135 1.018-.39a1.8 1.8 0 00.56-.965c.65-2.337 1.25-3.732 2.032-4.898.792-1.182 1.796-2.169 3.356-3.683.312-.27.62-.537.964-.826 4.221-3.547 6.45-7.65 6.45-12.182 0-7.946-6.457-14.413-14.4-14.427z"
                  fill="#FF3008"
                  stroke="#fff"
                  strokeWidth={1.6}
                />
                <path
                  d="M15.406 20.967a4.807 4.807 0 100-9.613 4.807 4.807 0 000 9.613z"
                  fill="#fff"
                />
              </>
            ) : (
              <>
                <path
                  d="M15.318 1.755h-.003C7.372 1.77.915 8.237.915 16.183c0 4.531 2.23 8.635 6.45 12.182.344.289.652.555.964.826 1.56 1.514 2.564 2.5 3.357 3.683.782 1.166 1.38 2.56 2.03 4.898a1.8 1.8 0 00.562.964c.295.256.654.38 1.017.39h.044c.362-.01.722-.134 1.017-.39.288-.25.472-.587.56-.964.65-2.337 1.25-3.732 2.032-4.898.792-1.182 1.796-2.169 3.356-3.683.312-.27.62-.537.964-.826 4.221-3.547 6.45-7.65 6.45-12.182 0-7.946-6.457-14.413-14.4-14.428z"
                  fill="#fff"
                  stroke="#FF3008"
                  strokeWidth={1.6}
                />
                <path
                  d="M15.316 21.784a4.807 4.807 0 100-9.614 4.807 4.807 0 000 9.614z"
                  fill="#fff"
                  stroke="#FF3008"
                  strokeWidth={1.6}
                />
              </>
            )}
          </svg>
        </Container>
      </OverlayView>
    );
  }
);

export default ShopMarker;
