import { goBack } from "connected-react-router";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import { Dispatch } from "redux";
import styled from "styled-components";
import { ReduxModel } from "../../reducer";
import { useSetTimeoutFlag } from "../../util/CustomHooks";
import Utility from "../../util/Utility";
import Button from "../atoms/Button";
import CommonNotice from "../atoms/CommonNotice";
import Fade from "../atoms/Fade";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";

const pageBodyStyle: React.CSSProperties = {
  backgroundColor: "transparent"
};

const successNoticeStyle: React.CSSProperties = {
  marginTop: "20px"
};

const notesStyle: React.CSSProperties = {
  fontSize: "1.5rem",
  textAlign: "center",
  color: "#464646"
};

const DisplayInvitationCode = styled.div`
  margin: 37px 0;
  padding: 20px;
  font-size: 1.9rem;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
`;

interface IProps {
  invitationCode: string;
  actions: ActionDispatcher;
  children?: never;
}

const UserInvitationTemplate: React.FC<IProps> = React.memo(props => {
  const { invitationCode, actions } = props;

  const [displaySuccess, setDisplaySuccess] = useSetTimeoutFlag(false, 1000);
  const [displayFailed, setDisplayFailed] = useSetTimeoutFlag(false, 1000);

  const handleClickGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  const handleClickCopyInvitationCode = useCallback(() => {
    const succeeded = Utility.execCopyText(invitationCode);
    if (succeeded) {
      setDisplaySuccess(true);
    } else {
      setDisplayFailed(true);
    }
  }, [invitationCode]);

  return (
    <Page
      header={
        <SubPageHeader
          title="友達招待コード"
          handleClickBack={handleClickGoBack}
        />
      }
    >
      <PageBody style={pageBodyStyle}>
        <Notes style={notesStyle}>
          下記のコードを友達にシェアしましょう。
          <br />
          <br />
          シェアされたお友達がコード入力後、
          <br />
          初めて注文した際に、 <br />
          500円分のクーポンが付与されます。
          <br />
          <br />
          またお友達はコードを入力するだけで
          <br />
          同様のクーポンが付与されます。
          <br />
          <br />
          一緒に登録して、注文してみましょう！
        </Notes>
        <DisplayInvitationCode>{invitationCode}</DisplayInvitationCode>
        <Button block onClick={handleClickCopyInvitationCode}>
          友達招待コードをコピー
        </Button>

        <div style={successNoticeStyle}>
          <TransitionGroup>
            {displaySuccess && (
              <Fade durationSeconds={0.25}>
                <CommonNotice type="primary">コピーしました</CommonNotice>
              </Fade>
            )}
            {displayFailed && (
              <Fade durationSeconds={0.25}>
                <CommonNotice type="warning">コピーに失敗しました</CommonNotice>
              </Fade>
            )}
          </TransitionGroup>
        </div>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  invitationCode: state.user.getData().getInviteCode()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInvitationTemplate);
