import React from "react";
import styled from "styled-components";
import MultiLine from "./MultiLine";

const Container = styled.div`
  padding: 8px 12px;
  font-size: 17px;
  color: #ef5f56;
  border: 1px solid #de3823;
  border-radius: 5px;
  line-height: 1.25;
`;

interface IProps {
  type: "primary" | "warning";
  children: string;
}

const CommonNotice: React.FC<IProps> = React.memo(props => {
  const { children } = props;
  return (
    <Container>
      <MultiLine>{children}</MultiLine>
    </Container>
  );
});

export default CommonNotice;
