import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const Paper = styled.div`
  padding: 28px 23px;
  border-radius: 4px;
  background-color: ${colorsConst.BACKGROUND};
`;

export default Paper;
