import { List } from "immutable";
import { goBack } from "connected-react-router";
import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { ReduxModel } from "../../reducer";
import { EApiKey } from "../../apis";
import { systemOpenedModal } from "../../modules/app/actions";
import { userTouchedSendReceiptMail } from "../../modules/order/actions";
import { useSetTimeoutFlag } from "../../util/CustomHooks";
import styled from "styled-components";
import Price from "../../records/Price";
import OrderData from "../../records/OrderData";
import OrderItem from "../../records/OrderItem";
import colorsConst from "../../styles/const/colorsConst";
import ButtonAlter from "../atoms/ButtonAlter";
import Grid from "../atoms/Grid";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";
import EvilDatetime from "../../util/EvilDatetime";
import Loading from "../molecules/Loading";

const ThanksText = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  color: #fff;
  background-color: #ff6056;
`;

const DetailContainer = styled.div`
  padding-right: 20px;
  padding-left: 20px;
`;

const TotalPriceContainer = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 34px;
  border-bottom: 1px solid #bdbdbd;
`;

const OrderItemsContainer = styled.div``;
const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #bdbdbd;
`;

const OrderItemCount = styled.div`
  flex-grow: 0;
  min-width: 25px;
  font-size: 14px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #000;
`;
const OrderItemDetail = styled.div`
  flex-grow: 1;
  padding-left: 10px;
  font-size: 16px;
`;
const OrderItemName = styled.div`
  letter-spacing: 0.1rem;
`;
const OrderItemOption = styled.div`
  padding-top: 5px;
  padding-left: 1em;
  font-size: 14px;
`;

const PriceContainer = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  border-bottom: 1px solid #bdbdbd;
`;

const PaymentContainer = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 16px;
`;

const ShopInfoConteiner = styled.div`
  padding: 20px;
  background-color: #f8f8fa;
`;

const ShopImg = styled.div<{ src: string }>`
  width: 100%;
  padding-top: ${(110 / 285) * 100}%;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ orderNumber: string }>
) => ({
  userData: state.user.getData(),
  orderData: state.order.getOrderList(),
  orderNumber: ownProps.match.params.orderNumber,
  submittingSendReceiptMail: state.app.isConnectedApi(EApiKey.SEND_RECEIPT_MAIL)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userTouchedSendReceiptMailButton(orderNumber: string) {
    dispatch(userTouchedSendReceiptMail(orderNumber));
  },
  userTouchedOpenRegisterEmailDialogButton() {
    dispatch(systemOpenedModal("REQUEST_REGISTER_EMAIL", {}));
  },
  handleGoBack() {
    dispatch(goBack());
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const OrderReceiptTemplate: React.FC<IProps> = React.memo(props => {
  const {
    userData,
    orderData,
    orderNumber,
    submittingSendReceiptMail,
    userTouchedSendReceiptMailButton,
    userTouchedOpenRegisterEmailDialogButton,
    handleGoBack
  } = props;

  const currentReceiptData: OrderData = useMemo(() => {
    const selectedReciptDataList: List<OrderData> = orderData.filter(
      order => order.getOrderNumber() === orderNumber
    );
    return selectedReciptDataList.get(0, new OrderData());
  }, [orderData, orderNumber]);

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const [
    disabledSendReceiptButton,
    setDisabledSendReceiptButton
  ] = useSetTimeoutFlag(false, 3000);

  const handleTouchedSendReceiptMailButton = useCallback(
    (orderNumber: string) => {
      userTouchedSendReceiptMailButton(orderNumber);
      setDisabledSendReceiptButton(true);
    },
    [userTouchedSendReceiptMailButton]
  );

  const handleTouchedOpenRegisterEmailDialogButton = useCallback(() => {
    userTouchedOpenRegisterEmailDialogButton();
  }, [userTouchedOpenRegisterEmailDialogButton]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader title="領収書" handleClickBack={handleClickBack} />
        }
      >
        <PageBody>
          <div style={{ textAlign: "right" }}>
            {EvilDatetime.toSettingDateStringFromYYYYMMDD(
              EvilDatetime.getDateStringByDate(
                currentReceiptData.getReceiveDatetimeAsDate()
              )
            )}
          </div>

          <ThanksText>
            <div style={{ fontSize: "34px", marginBottom: "10px" }}>
              {userData.getNickName()}様、ご注文いただきありがとうございます。
            </div>
            <div style={{ fontSize: "20px" }}>
              {currentReceiptData.getShopInfo().getShopName()}
              の領収書をお受取りください。
            </div>
          </ThanksText>

          <DetailContainer>
            <TotalPriceContainer>
              <Grid container>
                <Grid item grow={1}>
                  合計
                </Grid>
                <Grid item>
                  {Price.getPresentationValue(
                    currentReceiptData.getItemTotalPrice()
                  )}
                </Grid>
              </Grid>
              {/**
               * FIXME:酒類など、商品ごとにフラグを持たせるまでの暫定対応
               */}
              {!EvilDatetime.isPastTime(
                currentReceiptData.getOrderDateAsDate(),
                EvilDatetime.getDateByYYYYMMDDHHmmString("20191001", "0000")
              ) && (
                <div
                  style={{
                    fontSize: "14px",
                    paddingTop: "10px"
                  }}
                >
                  但し、全商品が軽減税率対象
                </div>
              )}
            </TotalPriceContainer>

            <OrderItemsContainer>
              {currentReceiptData
                .getItemList()
                .map((item: OrderItem, index: number) => (
                  <OrderItemContainer key={index}>
                    <OrderItemCount>{index + 1}</OrderItemCount>
                    <OrderItemDetail style={{ paddingTop: "5px" }}>
                      <Grid container align="flex-start">
                        <Grid item grow={1}>
                          <OrderItemName>{item.getItemName()}</OrderItemName>
                        </Grid>
                        <Grid item grow={0}>
                          {Price.getPresentationValue(item.getUnitPrice())}
                        </Grid>
                      </Grid>
                      <ul>
                        {item.getOptionList().map((option, optionIndex) => (
                          <OrderItemOption key={optionIndex}>
                            <Grid container align="flex-start">
                              <Grid item grow={1}>
                                {option.getOptionName()}
                              </Grid>
                              {Math.abs(option.getPrice()) > 0 && (
                                <Grid item grow={0}>
                                  {option.getPresentationPrice()}
                                </Grid>
                              )}
                            </Grid>
                          </OrderItemOption>
                        ))}
                      </ul>
                    </OrderItemDetail>
                  </OrderItemContainer>
                ))}
            </OrderItemsContainer>

            <PriceContainer>
              <Grid container>
                <Grid item grow={1}>
                  小計
                </Grid>
                <Grid item>
                  {Price.getPresentationValue(
                    currentReceiptData.getItemTotalPrice()
                  )}
                </Grid>
              </Grid>
              {Math.abs(currentReceiptData.getDiscountPrice()) > 0 && (
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item grow={1}>
                    割引
                  </Grid>
                  <Grid item style={{ color: colorsConst.MAIN }}>
                    {Price.getPresentationValue(
                      currentReceiptData.getDiscountPrice()
                    )}
                  </Grid>
                </Grid>
              )}
            </PriceContainer>

            <PaymentContainer>
              <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
                お支払いカード
              </div>
              <Grid container>
                <Grid item grow={1}>
                  {currentReceiptData.getCardNumber()}
                </Grid>
                <Grid item>
                  {Price.getPresentationValue(
                    currentReceiptData.getUserPaymentPrice()
                  )}
                </Grid>
              </Grid>
            </PaymentContainer>

            <ShopInfoConteiner>
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginBottom: "20px"
                }}
              >
                {currentReceiptData.getShopInfo().getShopName()}でのご注文
              </div>
              <div
                style={{
                  fontSize: "16px",
                  marginBottom: "20px"
                }}
              >
                お店の住所：
                <br />
                {currentReceiptData.getShopInfo().getAddress()}
              </div>
              <ShopImg
                src={currentReceiptData.getShopInfo().getPickupImagePath()}
              />
            </ShopInfoConteiner>

            <ButtonAlter
              block
              type="button"
              appearance={"main"}
              disabled={submittingSendReceiptMail || disabledSendReceiptButton}
              onClick={() =>
                userData.existEmail()
                  ? handleTouchedSendReceiptMailButton(
                      currentReceiptData.getOrderNumber()
                    )
                  : handleTouchedOpenRegisterEmailDialogButton()
              }
            >
              {submittingSendReceiptMail ? <Loading /> : "領収書をメールで送信"}
            </ButtonAlter>
          </DetailContainer>
        </PageBody>
      </Page>
    </Auth>
  );
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderReceiptTemplate)
);
