import { push } from "connected-react-router";
import { ellipsis } from "polished";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { userTouchedChangeShopFavoriteStateButton } from "../../../modules/search/actions";
import SearchResult, { SearchResultType } from "../../../records/SearchResult";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import ShopForSearch from "../../../records/ShopForSearch";
import Analytics from "../../../util/Analytics";
import ScrollTopMemory, { EScrollTopKey } from "../../../util/ScrollTopMemory";
import Grid from "../../atoms/Grid";
import Tag from "../../atoms/Tag";
import ShopFavoriteButton from "../ShopFavoriteButton";
import ShopCaption from "./ShopCaption";
import ShopCookingTimeInfo from "./ShopCookingTimeInfo";
import ShopImage from "./ShopImage";
import ShopName from "./ShopName";
import ShopReviewPoint from "./ShopReviewPoint";

const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 20px;
`;

const ShopFavoriteButtonContainer = styled.div`
  padding-left: 18px;
`;

const InfoContainer = styled.div`
  padding: 0 15px;
`;

const TagContainer = styled.div`
  height: 15px;
`;

const ShopImageContainer = styled.div`
  padding: 9px 0;
  border-radius: 2px;
  overflow: hidden;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 5px;
`;

const PriceRangeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 0 0 auto;
  padding-bottom: 4px;
  border-bottom: 2px solid #ffa592;
`;

const PriceRangeInfoKey = styled.div`
  flex: 0 0 auto;
  font-size: 10px;
  line-height: ${14 / 10};
  color: #595959;
  font-weight: 500;
`;

const PriceRangeInfoValue = styled.div`
  padding-left: 16px;
  flex: 1 1 auto;
  font-size: 14px;
  color: #272727;
  font-weight: 500;
  text-align: right;
`;

const CategoryTagList = styled.div`
  padding-left: 4px;
  font-size: 11px;
  line-height: ${16 / 11};
  color: #969696;
  ${ellipsis()}
`;

const DistanceInfoText = styled.div`
  font-size: 12px;
  line-height: ${17 / 12};
  font-weight: 500;
  color: #595959;
`;

interface IProps {
  item: ShopForSearch;
  onMount?: () => void;
  searchPlace?: SearchPlace;
  resultType: SearchResultType;
}

const ShopListItemViewer: React.FC<IProps> = React.memo(
  ({ item, onMount, searchPlace, resultType }) => {
    const description = item.getDescription();

    const categoryTagList = useMemo(() => {
      return item.getCategoryTag().join("・");
    }, [item]);

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
      const shopId = item.getId();
      ScrollTopMemory.clear(`${EScrollTopKey.SHOP_TEMPLATE}-${shopId}`);
      dispatch(push(`/shop/${shopId}`));
    }, [dispatch, item]);

    const handleClickFavoriteButton = useCallback(() => {
      Analytics.logEvent(
        SearchResult.getAnalyticsFavoriteEventNameBySearchResultType(
          resultType,
          item.isFavorite()
        ),
        {
          content_type: "shop_id",
          item_id: `${item.getId()}`
        }
      );
      dispatch(
        userTouchedChangeShopFavoriteStateButton(
          item.getId(),
          !item.isFavorite()
        )
      );
    }, [dispatch, item, resultType]);

    const distanceInfo = useMemo(() => {
      if (typeof searchPlace === "undefined") {
        return "";
      }
      return `${searchPlace.getSettingText()}から${item.getDistanceInfoText()}`;
    }, [item, searchPlace]);

    useEffect(() => {
      if (onMount) {
        onMount();
      }
    }, [onMount]);

    return (
      <Container>
        <InfoContainer>
          <TagContainer>
            {item.existsTag() && <Tag tagType={item.getTag()} />}
          </TagContainer>
          <Grid container justify="space-between" align="center">
            <ShopName>{item.getShopName()}</ShopName>
            <ShopCookingTimeInfo item={item} />
            <ShopFavoriteButtonContainer>
              <ShopFavoriteButton
                active={item.isFavorite()}
                handleClick={handleClickFavoriteButton}
              />
            </ShopFavoriteButtonContainer>
          </Grid>
          <Grid container>
            <Grid item rate={1}>
              <DistanceInfoText>{distanceInfo}</DistanceInfoText>
            </Grid>
            <Grid item shrink={1}>
              <CategoryTagList>{categoryTagList}</CategoryTagList>
            </Grid>
          </Grid>
        </InfoContainer>
        <ShopImageContainer onClick={handleClick}>
          <ShopImage shop={item} />
        </ShopImageContainer>
        <InfoContainer>
          {description.trim() !== "" ? (
            <ShopCaption>{description}</ShopCaption>
          ) : null}
          <InfoList>
            <PriceRangeInfo>
              <PriceRangeInfoKey>{"価格"}</PriceRangeInfoKey>
              <PriceRangeInfoValue>
                {item.getPresentationalPriceRangeText()}
              </PriceRangeInfoValue>
            </PriceRangeInfo>
            {item.canDisplayReviewPoint() ? (
              <ShopReviewPoint item={item} />
            ) : null}
          </InfoList>
        </InfoContainer>
      </Container>
    );
  }
);

export default ShopListItemViewer;
