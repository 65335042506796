import { RefObject } from "react";
import React from "react";
import GoogleMap, {
  GoogleMapProps
} from "react-google-maps/lib/components/GoogleMap";
import { WithGoogleMapProps } from "react-google-maps/lib/withGoogleMap";
import { WithScriptjsProps } from "react-google-maps/lib/withScriptjs";
import styled from "styled-components";
import zIndexConst from "../../styles/const/zIndexConst";
import Utility from "../../util/Utility";
import Grid from "../atoms/Grid";
import Loading from "../molecules/Loading";
import MapViewerStruct from "./MapViewerStruct";

const DefaultMapElement = styled.div`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const DefaultContainerElement = styled.div`
  position: absolute;
  z-index: ${zIndexConst.ABSOLUTE};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const LoadingMap = styled(Grid).attrs({
  container: true,
  align: "center",
  justify: "center"
})`
  height: 100%;
  background-color: #e5e5e5;
`;

const DefaultLoadingElement = React.memo(
  () => (
    <LoadingMap>
      <Loading />
    </LoadingMap>
  ),
  () => true
);

const googleMapsApiUrl = Utility.getGoogleMapsApiUrl();

interface IProps
  extends Partial<WithScriptjsProps>,
    Partial<WithGoogleMapProps>,
    GoogleMapProps {
  mapRef?: RefObject<GoogleMap>;
}

const MapViewer: React.FC<IProps> = React.memo(props => {
  const { loadingElement, containerElement, mapElement, ...rest } = props;
  if (typeof googleMapsApiUrl === "undefined") {
    throw new Error("Google Maps API URL is not found.");
  }

  return (
    <MapViewerStruct
      googleMapURL={googleMapsApiUrl}
      loadingElement={
        typeof loadingElement === "undefined" ? (
          <DefaultLoadingElement />
        ) : (
          loadingElement
        )
      }
      containerElement={
        typeof containerElement === "undefined" ? (
          <DefaultContainerElement />
        ) : (
          containerElement
        )
      }
      mapElement={
        typeof mapElement === "undefined" ? <DefaultMapElement /> : mapElement
      }
      defaultCenter={props.defaultCenter && props.defaultCenter}
      {...rest}
    />
  );
});

export default MapViewer;
