import { List, Record } from "immutable";
import { ILatLng } from "../modules/search/model";
import EnvUtil from "../util/EnvUtil";
import Price from "./Price";
import ShopItem from "./ShopItem";
import ShopItemCategory, {
  IShopItemCategory,
  IShopItemCategoryProps
} from "./ShopItemCategory";
import ShopListBusinessData, {
  IShopListBusinessDataProps
} from "./ShopListBusinessData";

/**
 * 高評価となるレビューの閾値
 */
export const HIGH_RATING_MIN_VALUE = 4.5;

/**
 * 表示可能な最大距離
 */
export const MAX_DISPLAYABLE_DISTANCE_METER = 1500;

export enum ETagType {
  NONE = 0,
  MICHELIN = 1,
  GRANDPRIX = 2,
  TABELOG = 3
}

interface IShopForSearchBaseProps {
  id: number;
  shop_name: string;
  category_tag: string[];
  pickup_image_path: string;
  header_image_path: string;
  latitude: string;
  longitude: string;
  is_favorite: boolean;
  most_recommend_item: ShopItem;
  is_review_display: boolean;
  review_point: string;
  luxury_flag: boolean;
  distance_meter: number;
  description: string;
  is_new_open: boolean;
  release_datetime: number; // unix timestamp
  lowest_price: number;
  tag: ETagType;
}

export interface IShopForSearchProps extends IShopForSearchBaseProps {
  item_list: IShopItemCategory[];
  shop_list_business_data: IShopListBusinessDataProps;
}

export interface IShopForSearch extends IShopForSearchBaseProps {
  item_list: List<ShopItemCategory>;
  shop_list_business_data: ShopListBusinessData;
}

const initial: IShopForSearch = {
  id: 0,
  shop_name: "",
  category_tag: [],
  pickup_image_path: "",
  header_image_path: "",
  latitude: "0.0",
  longitude: "0.0",
  is_favorite: false,
  most_recommend_item: new ShopItem(),
  item_list: List<ShopItemCategory>(),
  is_review_display: false,
  review_point: "0.0",
  luxury_flag: false,
  distance_meter: -1,
  description: "",
  is_new_open: false,
  shop_list_business_data: new ShopListBusinessData(),
  release_datetime: -1,
  lowest_price: 0,
  tag: ETagType.NONE
};

export default class ShopForSearch extends Record(initial) {
  public static create(props: IShopForSearchProps) {
    return new ShopForSearch(props);
  }

  public static findByItemId(itemId: number, list: List<ShopForSearch>) {
    return list.find(shop =>
      shop.getAllItemList().some(item => item.getId() === itemId)
    );
  }

  public static sortByCanOrder(valueA: ShopForSearch, valueB: ShopForSearch) {
    const [a, b] = [valueA.canOrder(), valueB.canOrder()];
    return a === b ? 0 : b ? 1 : -1;
  }

  public static sortByDistance(valueA: ShopForSearch, valueB: ShopForSearch) {
    const [distanceA, distanceB] = [
      valueA.getDistanceMeter(),
      valueB.getDistanceMeter()
    ];
    return distanceA === distanceB ? 0 : distanceA > distanceB ? 1 : -1;
  }

  public static sortByLatitude(a: ShopForSearch, b: ShopForSearch) {
    const [locA, locB] = [a.getLocation(), b.getLocation()];
    return locA.lat === locB.lat ? 0 : locA.lat > locB.lat ? -1 : 1;
  }

  public static sortByRating(valueA: ShopForSearch, valueB: ShopForSearch) {
    const [ratingA, ratingB] = [
      valueA.getReviewPointAsFloat(),
      valueB.getReviewPointAsFloat()
    ];
    return ratingA === ratingB ? 0 : ratingA < ratingB ? 1 : -1;
  }

  public static sortByPrice(
    valueA: ShopForSearch,
    valueB: ShopForSearch,
    desc: boolean
  ) {
    const [a, b] = [valueA.getLowestPrice(), valueB.getLowestPrice()];
    if (a === b) {
      return 0;
    }
    if (desc) {
      return a < b ? 1 : -1;
    }
    return a < b ? -1 : 1;
  }

  public static sortByReleaseDatetime(
    valueA: ShopForSearch,
    valueB: ShopForSearch
  ) {
    const [a, b] = [valueA.getReleaseDatetime(), valueB.getReleaseDatetime()];
    return a === b ? 0 : a < b ? 1 : -1;
  }

  public static sortByNotBusinessReason(
    valueA: ShopForSearch,
    valueB: ShopForSearch
  ) {
    const [a, b] = [
      valueA.shouldDisplayNotBusinessReason(),
      valueB.shouldDisplayNotBusinessReason()
    ];
    return a === b ? 0 : a ? 1 : -1;
  }

  private constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        item_list: List(
          args.item_list &&
            args.item_list.map(
              (i: IShopItemCategoryProps) => new ShopItemCategory(i)
            )
        ),
        most_recommend_item: new ShopItem(
          args.most_recommend_item ? args.most_recommend_item : {}
        ),
        shop_list_business_data: new ShopListBusinessData(
          args.shop_list_business_data ? args.shop_list_business_data : {}
        )
      })
    );
  }

  public existsData() {
    return this.getId() !== 0;
  }

  public getId() {
    return this.get("id");
  }

  public getShopName() {
    return this.get("shop_name");
  }

  public getCategoryTag() {
    return this.get("category_tag");
  }

  public getPickupImagePath() {
    return EnvUtil.withImageDomain(this.get("pickup_image_path"));
  }

  public getHeaderImagePath() {
    return EnvUtil.withImageDomain(this.get("header_image_path"));
  }

  public getLocation(): ILatLng {
    return {
      lat: parseFloat(this.get("latitude")),
      lng: parseFloat(this.get("longitude"))
    };
  }

  public isFavorite() {
    return this.get("is_favorite");
  }

  /**
   * クライアント側で演出的に利用する
   */
  public setFavorite(bool: boolean) {
    return this.set("is_favorite", bool);
  }

  public getMostRecommendItem() {
    return this.get("most_recommend_item");
  }

  public getItemList() {
    return this.get("item_list");
  }

  /**
   * カテゴリーを跨いだ全メニューのデータを取得する
   */
  public getAllItemList() {
    const list = this.getItemList();
    return list.isEmpty()
      ? List<ShopItem>()
      : list.flatMap(item => item.getItemList());
  }

  public canDisplayReviewPoint() {
    return this.get("is_review_display");
  }

  public getReviewPoint() {
    return this.get("review_point");
  }

  public getReviewPointAsFloat() {
    return parseFloat(this.getReviewPoint());
  }

  public isHighRating() {
    if (!this.canDisplayReviewPoint()) {
      return false;
    }
    return this.getReviewPointAsFloat() >= HIGH_RATING_MIN_VALUE;
  }

  public isLuxury() {
    return this.get("luxury_flag");
  }

  public getDistanceMeter() {
    return this.get("distance_meter");
  }

  public getPresentationalDistanceMeterText() {
    const distanceMeter = this.getDistanceMeter();
    if (!this.isValidDistanceMeter()) {
      return "";
    }
    if (this.isVeryFar()) {
      return `${(MAX_DISPLAYABLE_DISTANCE_METER / 1000).toFixed(1)}km以上`;
    }
    if (distanceMeter >= 1000) {
      return `${(distanceMeter / 1000).toFixed(1)}km`;
    }
    return `${distanceMeter}m`;
  }

  public getDistanceInfoText() {
    const distanceMeter = this.getPresentationalDistanceMeterText();
    const timeRequired = this.getPresentationalMoveMinutesRequiredText();
    if (distanceMeter === "") {
      return "";
    }
    if (this.isVeryFar()) {
      return distanceMeter;
    }
    return `${distanceMeter}(徒歩${timeRequired})`;
  }

  public isVeryFar() {
    return this.getDistanceMeter() >= MAX_DISPLAYABLE_DISTANCE_METER;
  }

  public isValidDistanceMeter() {
    return this.getDistanceMeter() !== -1;
  }

  public getPresentationalMoveMinutesRequiredText() {
    const METER_PER_MINUTES = 60;
    if (!this.isValidDistanceMeter()) {
      return "";
    }
    const distanceMeter = this.getDistanceMeter();
    const minute = Math.ceil(distanceMeter / METER_PER_MINUTES);
    return minute >= 60 ? `${(minute / 60).toFixed(1)}時間` : `${minute}分`;
  }

  public getDescription() {
    return this.get("description");
  }

  public isNewOpen() {
    return this.get("is_new_open");
  }

  public getShopListBusinessData() {
    return this.get("shop_list_business_data");
  }

  public getReleaseDatetime() {
    return this.get("release_datetime");
  }

  public getLowestPrice() {
    return this.get("lowest_price");
  }

  public canOrder() {
    return (
      this.isOpen() && (this.canFastestOrder() || this.canReservationOrder())
    );
  }

  public isOpen() {
    return this.getShopListBusinessData().isOpen();
  }

  public canFastestOrder() {
    return this.getShopListBusinessData().canFastestOrder();
  }

  public canReservationOrder() {
    return this.getShopListBusinessData().canReservationOrder();
  }

  public getShopBusinessDisplayCookingTime() {
    return this.getShopListBusinessData().getDisplayCookingTime();
  }

  public getPresentationalPriceRangeText() {
    return `${Price.getPresentationValue(this.getLowestPrice())}~`;
  }

  public shouldDisplayNotBusinessReason() {
    const notBusinessReason = this.getShopListBusinessData().getNotBusinessReason();
    return (
      !this.isOpen() &&
      typeof notBusinessReason !== "undefined" &&
      notBusinessReason.exists()
    );
  }

  public getTag() {
    return this.get("tag");
  }

  public existsTag() {
    return this.getTag() !== ETagType.NONE;
  }
}
