import { Cordova } from "../Cordova";

declare let cordova: Cordova;

interface ICDVPluginIDFAResult {
  limitAdTracking: boolean;
  idfa?: string;
  aaid?: string;
}

export interface ICDVPluginIDFA {
  getInfo(): Promise<ICDVPluginIDFAResult | undefined>;
}

export async function getAdvertisingId() {
  if (!enabledPlugin) {
    return undefined;
  }
  const result = await cordova.plugins.idfa.getInfo();
  if (typeof result === "undefined" || result.limitAdTracking) {
    return undefined;
  }
  const { idfa, aaid } = result;
  return typeof idfa !== "undefined" ? idfa : aaid;
}

function enabledPlugin() {
  return (
    typeof cordova !== "undefined" &&
    typeof cordova.plugins !== "undefined" &&
    typeof cordova.plugins.idfa !== "undefined"
  );
}
