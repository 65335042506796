import { push } from "connected-react-router";
import { fork, put, join } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import {
  removeProcessingFlag,
  setProcessingFlag,
  systemAddedAlert
} from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import { initReserveSearchSetting } from "../actions";
import getSearchResultSaga from "./getSearchResultSaga";

export default function* userTouchedHomeReservationButtonSaga() {
  yield put(setProcessingFlag(EProcessingFlag.FETCHING_HOME_RESERVE_SEARCH));
  try {
    yield put(initReserveSearchSetting());
    const fetchingTask = yield fork(() => getSearchResultSaga("reserve"));
    yield join(fetchingTask);
    yield put(push("/search/reserve"));
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  } finally {
    yield put(
      removeProcessingFlag(EProcessingFlag.FETCHING_HOME_RESERVE_SEARCH)
    );
  }
}
