import { List } from "immutable";
import * as React from "react";
import styled from "styled-components";
import { EShopTabType } from "../../modules/search/model";
import ExternalLink from "../../records/ExternalLink";
import Feedback from "../../records/Feedback";
import Price from "../../records/Price";
import ShopData from "../../records/ShopData";
import ShopItem from "../../records/ShopItem";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import ButtonAlter from "../atoms/ButtonAlter";
import ChevronWideIcon from "../atoms/ChevronWideIcon";
import ExternalLinkIcon from "../atoms/ExternalLinkIcon";
import Grid from "../atoms/Grid";
import MultiLine from "../atoms/MultiLine";
import Stars from "../atoms/Stars";
import Loading from "../molecules/Loading";
import ShopMenuItem from "../molecules/ShopMenuItem";
import FeedbackList from "../organisms/FeedbackList";
import MapViewer from "../organisms/MapViewer";
import ShopFavoriteButton from "./ShopFavoriteButton";
import ShopMarker from "./ShopMarker";

const Inner = styled.div`
  padding: 20px;
`;

const Head = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #595959;
`;

const ShopImage = styled.div<{ src: string }>`
  width: 100vw;
  height: calc((200 / 375) * 100vw);
  background-image: url(${p => p.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const MapContainer = styled.div`
  position: relative;
  height: calc((220 / 375) * 100vw);
  margin-right: -20px;
  margin-left: -20px;
`;

const AddressContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  position: absolute;
  bottom: 19px;
  width: calc(100% - ${19 * 2}px);
  margin: 0 19px;
  padding: 8px 15px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f6f6f6;
`;

const FavIconContaier = styled.div`
  width: 40px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag = styled(Grid)<{ item: true }>`
  white-space: nowrap;
  color: #757575;
  & + & {
    &::before {
      content: "・";
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }
`;

const ExternalLinkItem = styled.a``;

const ExternalLinkElement = styled.div`
  padding-bottom: 17px;
`;

const ExternalLinkText = styled.span`
  display: inline-block;
  padding-right: 6px;
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 500;
  text-decoration-line: underline;
  color: #595959;
`;

const mapLinkStyle: React.CSSProperties = {
  fontWeight: 500,
  fontSize: "12px",
  color: "#383838",
  flexWrap: "nowrap"
};

interface IProps {
  shopData: ShopData;
  currentShopRecommendItemList: List<ShopItem>;
  currentFeedbackList: List<Feedback>;
  handleClickReviewButton: () => void;
  handleClickLaunchMapApp: () => void;
  handleClickFavoriteButton: () => void;
  isUpdatingFavoriteState: boolean;
  existExternalLinkUrl: boolean;
  externalLink: List<ExternalLink>;
  handleChangeIndex: (nextTab: number, currentTab: number) => void;
}

const ShopTabTop: React.FC<IProps> = React.memo(props => {
  const {
    shopData,
    currentShopRecommendItemList,
    currentFeedbackList,
    handleClickLaunchMapApp,
    handleClickReviewButton,
    handleClickFavoriteButton,
    isUpdatingFavoriteState,
    existExternalLinkUrl,
    externalLink,
    handleChangeIndex
  } = props;

  return (
    <>
      <ShopImage src={shopData.getHeaderImagePath()} />
      <Inner
        style={{
          borderBottomStyle: "solid",
          borderBottomColor: "#EFEFEF",
          borderBottomWidth: "2px"
        }}
      >
        <Grid container align="start">
          <Grid item grow={1}>
            <div
              style={{
                fontSize: "14px",
                lineHeight: 20 / 14,
                fontWeight: 500,
                marginBottom: "10px",
                color: "#727272"
              }}
            >
              【{shopData.getDescription()}】
            </div>
            <div
              style={{
                fontSize: "24px",
                fontWeight: 500,
                marginBottom: "12px"
              }}
            >
              {shopData.getShopName()}
            </div>
          </Grid>
          <Grid item grow={0}>
            <FavIconContaier>
              {isUpdatingFavoriteState ? (
                <Loading size={6} color="#C9CACA" />
              ) : (
                <ShopFavoriteButton
                  active={shopData.isFavorite()}
                  handleClick={handleClickFavoriteButton}
                />
              )}
            </FavIconContaier>
          </Grid>
        </Grid>
        <Grid container align="center" style={{ marginBottom: "10px" }}>
          {shopData.canDisplayReviewPoint() && (
            <Grid item style={{ marginRight: "10px" }}>
              <Grid container align="flex-end">
                <Grid item style={{ fontSize: "18px" }}>
                  <Stars rate={shopData.getReviewPointAsFloat()} size={18} />
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginLeft: "4px",
                    color: colorsConst.MAIN
                  }}
                >
                  {shopData.getReviewPoint()}
                </Grid>
                <Grid item style={{ marginLeft: "5px", color: "#727272" }}>
                  （{shopData.getDisplayableFeedbackList().size}件）
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <span
              style={{ marginRight: "5px", fontSize: "11px", color: "#80b927" }}
            >
              価格
            </span>
            <span
              style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: 1.5,
                color: "#727272"
              }}
            >
              {Price.getPresentationValue(shopData.getLowestPrice())}～
            </span>
          </Grid>
        </Grid>
        <Grid container wrap="wrap" style={{ marginBottom: "10px" }}>
          {shopData.getCategoryTags().map(tag => {
            return (
              <Tag item key={tag}>
                {tag}
              </Tag>
            );
          })}
        </Grid>
        <div>
          <span
            style={{
              marginRight: "5px",
              fontSize: "11px",
              fontWeight: 500,
              color: colorsConst.MAIN
            }}
          >
            出来上がり
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: 20 / 14,
              color: "#272727"
            }}
          >
            {shopData.getPresentationShopBusinessCookingTime()}分
          </span>
        </div>
      </Inner>

      <Inner>
        <Head>メニュー</Head>
        {!currentShopRecommendItemList.isEmpty() && (
          <>
            {currentShopRecommendItemList.map(item => (
              <ShopMenuItem
                key={item.getId()}
                shopId={shopData.getId()}
                item={item}
              />
            ))}
          </>
        )}
        <div
          style={{
            padding: "0 32px",
            paddingTop: "25px"
          }}
        >
          <ButtonAlter
            block
            appearance="sub"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
            onClick={() => {
              handleChangeIndex(EShopTabType.MENU, EShopTabType.TOP);
              Analytics.logEvent("shop_all_menu", {
                content_type: "shop_id",
                item_id: `${shopData.getId()}`
              });
            }}
          >
            全てのメニューを見る
          </ButtonAlter>
        </div>
      </Inner>

      <Inner>
        <Head style={{ marginBottom: "20px" }}>口コミ</Head>
        {currentFeedbackList.isEmpty() ? (
          <div
            style={{
              marginBottom: "10px"
            }}
          >
            口コミがありません
          </div>
        ) : (
          <FeedbackList list={currentFeedbackList} />
        )}
        <div
          style={{
            padding: "0 32px"
          }}
        >
          {currentFeedbackList.isEmpty() ? null : (
            <ButtonAlter
              block
              appearance="sub"
              style={{
                marginBottom: "10px",
                paddingTop: "10px",
                paddingBottom: "10px"
              }}
              onClick={() => {
                handleChangeIndex(EShopTabType.FEEDBACK, EShopTabType.TOP);
                Analytics.logEvent("shop_all_feedback", {
                  content_type: "shop_id",
                  item_id: `${shopData.getId()}`
                });
              }}
            >
              全ての口コミを見る
            </ButtonAlter>
          )}
          <ButtonAlter
            block
            appearance="sub"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
            onClick={() => handleClickReviewButton()}
          >
            口コミを書く
          </ButtonAlter>
        </div>
      </Inner>

      <Inner>
        <Head style={{ marginBottom: "20px" }}>マップ</Head>
        {shopData.getLocation() ? (
          <MapViewer
            containerElement={<MapContainer />}
            defaultCenter={shopData.getLocation()}
          >
            <ShopMarker
              location={shopData.getLocation()}
              isOpen={shopData.isOpen()}
            />
            <AddressContainer onClick={handleClickLaunchMapApp}>
              <Grid item grow={1}>
                {shopData.getAddress()}
              </Grid>
              <Grid item grow={0} style={{ paddingLeft: "10px" }}>
                <Grid container align="center" style={mapLinkStyle}>
                  <Grid item style={{ whiteSpace: "nowrap" }}>
                    マップアプリで見る
                  </Grid>
                  <ChevronWideIcon
                    color="#383838"
                    style={{ fontSize: "11px", marginLeft: "5px" }}
                  />
                </Grid>
              </Grid>
            </AddressContainer>
          </MapViewer>
        ) : (
          <MapContainer>
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          </MapContainer>
        )}
      </Inner>

      <Inner>
        <Head style={{ marginBottom: "20px" }}>営業時間</Head>
        <ul>
          {Array.from(shopData.getWeekDaysStrToBusinessHoursStrEntries()).map(
            ([businessHours, weekdays]) => {
              return (
                <li key={weekdays} style={{ marginBottom: "20px" }}>
                  <div
                    style={{
                      color: "#272727",
                      fontSize: "1.6rem"
                    }}
                  >
                    {weekdays}
                  </div>
                  <div style={{ color: "#707070", fontSize: "1.4rem" }}>
                    <MultiLine lineStyle={{ paddingTop: "8px" }}>
                      {businessHours}
                    </MultiLine>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </Inner>

      {shopData.existsAppearanceImagePath() && (
        <Inner>
          <Head style={{ marginBottom: "20px" }}>外観写真</Head>
          <div style={{ marginRight: "-20px", marginLeft: "-20px" }}>
            <ShopImage src={shopData.getAppearanceImagePath()} />
          </div>
        </Inner>
      )}

      {existExternalLinkUrl && (
        <Inner>
          <Head style={{ marginBottom: "20px" }}>リンク</Head>
          {externalLink.map((link: ExternalLink, index) => {
            return (
              link.existLinkUrl() && (
                <ExternalLinkElement key={index}>
                  <ExternalLinkItem
                    target="_system"
                    href={link.getUrl()}
                    onClick={() =>
                      Analytics.logEvent(link.getAnalyticsEventName(), {
                        content_type: "shop_id",
                        item_id: `${shopData.getId()}`
                      })
                    }
                  >
                    <ExternalLinkText>
                      {link.getLinkText()}で見る
                    </ExternalLinkText>
                    <ExternalLinkIcon />
                  </ExternalLinkItem>
                </ExternalLinkElement>
              )
            );
          })}
        </Inner>
      )}
    </>
  );
});

export default ShopTabTop;
