import { List, Record } from "immutable";
import ShopItem, { IShopItem, IShopItemProps } from "./ShopItem";

export interface IShopItemCategoryProps {
  item_list: IShopItemProps[] | List<ShopItem>;
  category_name: string;
}

export interface IShopItemCategory extends IShopItemCategoryProps {
  item_list: List<ShopItem>;
}

const initialValues: IShopItemCategory = {
  category_name: "",
  item_list: List<ShopItem>()
};

/**
 * 店舗メニューのカテゴリ
 */
export default class ShopItemCategory extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        item_list: List(
          args.item_list &&
            args.item_list.map((item: IShopItem) => new ShopItem(item))
        )
      })
    );
  }

  public getCategoryName(): string {
    return this.get("category_name");
  }

  public getItemList(): List<ShopItem> {
    return this.get("item_list");
  }

  public getOtherItemList(itemId: number): List<ShopItem> {
    const itemList = this.getItemList();
    return itemList.filter((item: ShopItem) => {
      return item.getId() !== itemId;
    });
  }
}
