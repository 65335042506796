import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../atoms/Button";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";

export default function ResetPasswordCompletedTemplate(props: any) {
  return (
    <Page header={<SubPageHeader title="パスワード変更" />}>
      <PageBody>
        <div style={{ margin: "160px 0" }}>
          <Notes style={{ textAlign: "center" }}>
            パスワードを変更しました。
          </Notes>
        </div>
        <div>
          <Link to="/">
            <Button block type="button">
              ホームへ
            </Button>
          </Link>
        </div>
      </PageBody>
    </Page>
  );
}
