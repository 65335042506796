import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgError = (props: IProps) => (
  <svg width={119} height={108} {...props}>
    <defs>
      <path id="Error_svg__a" d="M0 .121h118.651V108H.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -.121)">
        <mask id="Error_svg__b" fill="#fff">
          <use xlinkHref="#Error_svg__a" />
        </mask>
        <path
          d="M59.325.121c-6.032 0-12.065 3.963-16.642 11.89L3.907 79.176C-5.247 95.029 2.243 108 20.549 108h77.553c18.307 0 25.795-12.971 16.644-28.824L75.968 12.011C71.39 4.084 65.358.121 59.325.121m0 3c5.188 0 10.176 3.69 14.044 10.39l38.778 67.165c3.867 6.7 4.57 12.864 1.975 17.357-2.594 4.493-8.282 6.967-16.02 6.967H20.55c-7.737 0-13.427-2.474-16.02-6.967-2.595-4.493-1.893-10.657 1.976-17.357l38.777-67.165c3.868-6.7 8.857-10.39 14.044-10.39"
          fill="#464646"
          mask="url(#Error_svg__b)"
        />
      </g>
      <path
        d="M56.642 74.456h5.368l1.102-41.58H55.54l1.102 41.58zm6.342 2h-7.316c-.542 0-.985-.432-1-.974l-1.154-43.579a1.004 1.004 0 0 1 1-1.027h9.624a1 1 0 0 1 1 1.027l-1.154 43.579a1.001 1.001 0 0 1-1 .974zm-7.47 15.093h7.547v-7.778h-7.547v7.778zm8.547 2h-9.547a1 1 0 0 1-1-1v-9.778a1 1 0 0 1 1-1h9.547a1 1 0 0 1 1 1v9.778a1 1 0 0 1-1 1z"
        fill="#464646"
      />
    </g>
  </svg>
);

export default function ErrorIcon(props: IProps) {
  return <SvgError {...props} />;
}
