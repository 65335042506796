import { put } from "redux-saga/effects";
import {
  SystemFinishedConnectApiAction,
  updateConnectingApiSet
} from "../actions";

export default function* systemFinishedConnectApiSaga(
  action: SystemFinishedConnectApiAction
) {
  yield put(updateConnectingApiSet(action.payload.apiKey, false));
}
