import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetUserCouponListRequest,
  IGetUserCouponListResponse
} from "../../../apis/getUserCouponList";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateDiscountCodes } from "../actions";

export default function* fetchDiscountCodesSaga() {
  try {
    const params: IGetUserCouponListRequest = {};
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_USER_COUPON_LIST, params)
    );
    const {
      result,
      error
    }: { result: IGetUserCouponListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateDiscountCodes(result.discount_code_list));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クーポンコードの取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
