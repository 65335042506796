import { destroy } from "redux-form";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { ILoginUserRequest, ILoginUserResponse } from "../../../apis/loginUser";
import { EFormID } from "../../../forms";
import { ILoginForm, initialLoginForm } from "../../../forms/login";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import LocalRepository from "../../../util/LocalRepository";
import Utility from "../../../util/Utility";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateLoginToken, UserSubmittedLoginAction } from "../actions";
import { loginByTokenSaga } from "./loginByTokenSaga";

export function* loginUserSaga(action: UserSubmittedLoginAction) {
  try {
    const deviceToken = LocalRepository.getItem("deviceToken", "");
    const formValues: ILoginForm = yield select<
      (state: ReduxModel) => ILoginForm
    >(state =>
      Utility.getReduxFormValues(state, EFormID.LOGIN, initialLoginForm)
    );
    const params: ILoginUserRequest = Object.assign({}, formValues, {
      device_token: deviceToken
    });

    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.LOGIN_USER, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: ILoginUserResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      const token = result.token;
      yield put(updateLoginToken(token));
      const loginByTokenTask = yield fork(() => loginByTokenSaga());
      yield join(loginByTokenTask);
      yield put(destroy(EFormID.LOGIN));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: "ログインに失敗しました。"
      })
    );
  }
}
