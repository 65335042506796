import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ReduxModel } from "../../reducer";
import styled from "styled-components";
import UnratedOrderItem from "./UnratedOrderItem";

const Container = styled.div<{ isEmpty: boolean }>`
  padding-top: 0;
  ${p =>
    !p.isEmpty &&
    `
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  `}
`;

const UnratedOrder: React.FC = React.memo(() => {
  const orderList = useSelector((state: ReduxModel) =>
    state.order.getDisplayableOrderList()
  );

  const unratedOrderList = useMemo(() => {
    return orderList.filter(
      order => order.isLatestOrder() && order.getFeedbackPoint() === 0
    );
  }, [orderList]);

  return (
    <Container isEmpty={unratedOrderList.isEmpty()}>
      {unratedOrderList.map((orderData, index) => (
        <UnratedOrderItem key={index} orderData={orderData} />
      ))}
    </Container>
  );
});

export default UnratedOrder;
