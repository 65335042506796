import React from "react";
import styled from "styled-components";
import CardIcon from "../atoms/CardIcon";
import Grid from "../atoms/Grid";
import Loading from "../molecules/Loading";

const Container = styled(Grid).attrs({
  container: true,
  align: "center"
})``;

const creditCardIconStyle: React.CSSProperties = {
  fontSize: "2.8rem"
};
const CardIconContainer = styled(Grid).attrs({ item: true, rate: 0 })`
  font-size: 0;
  padding-right: 12px;
`;

const CreditCardValue = styled(Grid).attrs({ item: true, rate: 1 })`
  color: #4e4e4e;
`;

const CreditCardButton = styled(Grid).attrs({ item: true, rate: 0 })``;

const ChangeButton = styled.button`
  font-size: 12px;
  color: #272727;
`;

interface IProps {
  cardNumber: string | null;
  fetching?: boolean;
  editable?: boolean;
  disabled?: boolean;
  children?: never;
  handleClickChange?: () => void;
}

const CreditCardInfo: React.FC<IProps> = React.memo(props => {
  const { fetching, cardNumber, editable, disabled, handleClickChange } = props;
  return (
    <Container>
      <CardIconContainer>
        <CardIcon style={creditCardIconStyle} />
      </CardIconContainer>
      <CreditCardValue>
        {fetching ? (
          <Loading />
        ) : cardNumber !== null ? (
          cardNumber
        ) : (
          "情報が取得できません"
        )}
      </CreditCardValue>
      {editable && (
        <CreditCardButton>
          {disabled || fetching ? (
            <ChangeButton disabled>変更</ChangeButton>
          ) : (
            <div onClick={handleClickChange}>
              <ChangeButton>変更</ChangeButton>
            </div>
          )}
        </CreditCardButton>
      )}
    </Container>
  );
});

export default CreditCardInfo;
