import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Price from "../../records/Price";
import ShopItem from "../../records/ShopItem";
import ShopItemReceptionTime from "../../records/ShopItemReceptionTime";
import EvilDatetime from "../../util/EvilDatetime";
import Grid from "../atoms/Grid";
import Ellipsis from "../organisms/Ellipsis";

const ShopMenuImageContainer = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

const ShopMenuImage = styled.div<{ src: string }>`
  width: 88px;
  height: 88px;
  background-image: url(${p => p.src});
  background-color: #e5e5e5;
  background-size: cover;
  background-position: center;
  border: 1px solid #c9caca;
`;

const ShopMenuImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
`;

const ShopItemTimeText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
`;

const MenuItem = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
`;

const MenuNameContainer = styled(Ellipsis)`
  margin-bottom: 5px;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
`;

const MenuDescription = styled(Ellipsis)`
  margin-bottom: 10px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #727272;
`;

const MenuPrice = styled.div`
  font-size: 18px;
  line-height: ${26 / 18};
  font-weight: 500;
  color: #272727;
`;

const MenuCookingTime = styled.div`
  margin-bottom: 10px;
  font-size: 10px;
  white-space: nowrap;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ff866d;
`;

interface IProps {
  shopId: number;
  item: ShopItem;
}

const ShopMenuItem: React.FC<IProps> = React.memo((props: IProps) => {
  const { shopId, item } = props;

  return (
    <MenuItem key={item.getId()}>
      <Link to={`/shop/${shopId}/item/${item.getId()}`}>
        <Grid container>
          <Grid item grow={1} style={{ paddingRight: "25px" }}>
            <MenuNameContainer lines={2}>{item.getName()}</MenuNameContainer>
            <MenuDescription lines={2}>{item.getDescription()}</MenuDescription>
            <MenuPrice>{Price.getPresentationValue(item.getPrice())}</MenuPrice>
          </Grid>
          <Grid item grow={0}>
            <Grid container direction="column" align="flex-end">
              <Grid item grow={0}>
                {item.existsItemCookingTime() && (
                  <MenuCookingTime>
                    <span style={{ color: "#595959" }}>出来上がり</span>
                    <span
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        fontSize: "18px",
                        fontWeight: 500
                      }}
                    >
                      {item.getCookingTime()}
                    </span>
                    分
                  </MenuCookingTime>
                )}
              </Grid>
              <Grid item grow={0}>
                <ShopMenuImageContainer>
                  <ShopMenuImage src={item.getImagePath()} />
                  {item.existShopItemRectptionTimeList() && !item.canOrder() && (
                    <ShopMenuImageOverlay>
                      {item
                        .getShopItemReceptionTimeList()
                        .map((receptionTime: ShopItemReceptionTime, index) => {
                          return (
                            <ShopItemTimeText key={index}>
                              {EvilDatetime.getHHmmStringByTimeString(
                                receptionTime.getStartTime()
                              )}
                              〜
                              {EvilDatetime.getHHmmStringByTimeString(
                                receptionTime.getEndTime()
                              )}
                            </ShopItemTimeText>
                          );
                        })}
                    </ShopMenuImageOverlay>
                  )}
                </ShopMenuImageContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </MenuItem>
  );
});

export default ShopMenuItem;
