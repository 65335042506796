import { List, Set } from "immutable";
import React, { useCallback, useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import ShopItem from "../../records/ShopItem";
import ShopOption from "../../records/ShopOption";
import ShopOptionSet from "../../records/ShopOptionSet";
import colorConst from "../../styles/const/colorsConst";
import CircleCheckIcon from "../atoms/CircleCheckIcon";
import CommonNotice from "../atoms/CommonNotice";
import Grid from "../atoms/Grid";
import BackIcon from "../molecules/BackIcon";

const OptionSetItem = styled.li<{ errorMax: boolean }>`
  margin-bottom: 2px;
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
      opacity: 1;
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
      opacity: 0.6;
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
      opacity: 0.6;
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
      opacity: 0.6;
    }
  }
  ${p =>
    p.errorMax
      ? `
    > ul li {
      animation: shake 0.42s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }`
      : ""}
`;

const OptionSetTitle = styled.div`
  padding: 0 20px 11px;
  font-size: 12px;
  line-height: 17px;
  color: #797979;
`;

const OptionSetHead = styled.div`
  padding: 15px 20px;
  background-color: #f5f5f5;
`;

const OptionSetName = styled.div`
  font-size: 14px;
  word-break: break-all;
`;

const OptionSetNotice = styled.div<{ require: boolean }>`
  padding-top: 5px;
  font-size: 13px;
  color: ${p => (p.require ? colorConst.MAIN : "#757575")};
`;

const OptionSetError = styled.div<{
  active: boolean;
  displayWhenMount: boolean;
}>`
  margin: 10px 0 0;
  display: ${p => (p.active && p.displayWhenMount ? "block" : "none")};
`;

const OptionList = styled("ul")`
  padding: 0 20px;
  overflow: hidden;
`;

const OptionItem = styled("li")<{ disabled: boolean; shake: boolean }>`
  padding: 15px 0;
  font-weight: ${p => (p.disabled ? "normal" : "bold")};
  color: #646464;
  & + & {
    border-top: 1px solid #f1f1f1;
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
      opacity: 1;
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
      opacity: 0.6;
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
      opacity: 0.6;
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
      opacity: 0.6;
    }
  }
  ${p =>
    p.shake
      ? `
      &:hover {
        animation: shake 0.4s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    `
      : ""}
`;

const OptionItemInner = styled.div`
  display: flex;
  align-items: center;
`;

const OptionItemName = styled.div`
  flex: 1 1 auto;
`;

const OptionItemPrice = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const OptionItemIcon = styled.div`
  padding-right: 1em;
  flex-grow: 0;
  flex-shrink: 0;
`;

const invisibleStyle: React.CSSProperties = {
  display: "none"
};

const checkIconStyle: React.CSSProperties = {
  fontSize: "2.4rem"
};

interface IProps {
  item: ShopItem;
  options: List<number>;
  connectingAddOrUpdateItem?: boolean;
  connectingDeleteFromCart?: boolean;
  disabled?: boolean;
  handleChangePreOrderOptionList: (list: number[]) => void;
  handleValidateOptionList: (disable: boolean) => void;
  isCartEdit: boolean | undefined;
}

const OptionSetListViewer: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    item,
    options,
    connectingAddOrUpdateItem,
    connectingDeleteFromCart,
    disabled,
    handleChangePreOrderOptionList,
    handleValidateOptionList,
    isCartEdit
  } = props;

  const optionSets = item.getOptionSetList();
  /**
   * 必須オプションの場合は昇順から選択されている状態にする
   */
  useEffect(() => {
    if (!isCartEdit) {
      handleChangePreOrderOptionList(item.initialOptionIdArray());
    }
  }, [item, handleChangePreOrderOptionList, isCartEdit]);

  /**
   * 選択されているオプションか
   */

  const isActiveOption = useCallback(
    (optionId: number) => {
      return options.includes(optionId);
    },
    [options]
  );

  /**
   * 同オプションリスト内の現在選択されているオプションを返す
   */
  const getOptionsThatIncludedInSameOptionSet = useCallback(
    (currentOptionSet: ShopOptionSet) => {
      return [...options.toArray()].filter((optionId: number) => {
        return currentOptionSet
          .getOptionList()
          .map(option => option.getId())
          .includes(optionId);
      });
    },
    [options]
  );

  const [errorOptionSetIdList, setErrorOptionSetIdList] = useState(
    Set<number>()
  );
  const [errorMaxOptionSetIdList, setErrorMaxOptionSetIdList] = useState(
    Set<number>()
  );
  const [displayWhenMount, setDisplayWhenMount] = useState(false);

  /**
   * エラーのオプションリストIDをステートに保存
   */
  const addErrorOptionList = useCallback(
    (optionSetId: number, isMaxError: boolean) => {
      const newErrorOptionSetIdList = errorOptionSetIdList.add(optionSetId);
      setErrorOptionSetIdList(newErrorOptionSetIdList);
      if (isMaxError) {
        const newErrorMaxOptionSetIdList = errorMaxOptionSetIdList.add(
          optionSetId
        );
        setErrorMaxOptionSetIdList(newErrorMaxOptionSetIdList);
      }
      handleValidateOptionList(newErrorOptionSetIdList.size !== 0);
    },
    [
      errorOptionSetIdList,
      setErrorOptionSetIdList,
      errorMaxOptionSetIdList,
      setErrorMaxOptionSetIdList,
      handleValidateOptionList
    ]
  );

  /**
   * エラーのオプションリストIDをステートから削除
   */
  const removeErrorOptionList = useCallback(
    (optionSetId: number) => {
      const newErrorOptionSetIdList = errorOptionSetIdList.delete(optionSetId);
      setErrorOptionSetIdList(newErrorOptionSetIdList);
      const newErrorMaxOptionSetIdList = errorMaxOptionSetIdList.delete(
        optionSetId
      );
      setErrorMaxOptionSetIdList(newErrorMaxOptionSetIdList);
      handleValidateOptionList(newErrorOptionSetIdList.size !== 0);
    },
    [
      errorOptionSetIdList,
      setErrorOptionSetIdList,
      errorMaxOptionSetIdList,
      setErrorMaxOptionSetIdList,
      handleValidateOptionList
    ]
  );

  /**
   * エラーのオプションリストを更新
   */
  const updateErrorOptionList = useCallback(
    (
      currentOptionSet: ShopOptionSet,
      sameOptionListLength: number,
      min: number,
      max: number
    ) => {
      setDisplayWhenMount(true);
      if (currentOptionSet.isRequired()) {
        if (sameOptionListLength > max || sameOptionListLength < min) {
          sameOptionListLength > max
            ? addErrorOptionList(currentOptionSet.getId(), true)
            : addErrorOptionList(currentOptionSet.getId(), false);
        } else {
          removeErrorOptionList(currentOptionSet.getId());
        }
      } else {
        if (sameOptionListLength > max && max !== 0) {
          addErrorOptionList(currentOptionSet.getId(), true);
        } else {
          removeErrorOptionList(currentOptionSet.getId());
        }
      }
    },
    [setDisplayWhenMount, addErrorOptionList, removeErrorOptionList]
  );

  const [shakeAnimationFlag, setShakeAnimationFlag] = useState<boolean>(false);

  const addAnimationFlag = useCallback(() => {
    setShakeAnimationFlag(true);
  }, [setShakeAnimationFlag]);

  const endAnimation = useCallback(() => {
    setShakeAnimationFlag(false);
  }, [setShakeAnimationFlag]);

  const updateOptionIdArray = useCallback(
    (optionSetArray: number[]) => {
      optionSetArray.map((optionId: number) =>
        optionSets.map(optionSet => optionSet.updateOptionList(optionId, false))
      );
    },
    [optionSets]
  );

  const handleChangeOption = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (connectingAddOrUpdateItem || connectingDeleteFromCart || disabled) {
        return;
      }
      const input = event.currentTarget;
      const checked = input.checked;
      const value = parseInt(input.value, 10);
      const targetOptionSet = item.findOptionSetByShopOptionId(value);

      if (targetOptionSet === null) {
        return;
      }
      const currentValues = [...options.toArray()];
      const targetOptionSetValuesExcludeCurrentValue = getOptionsThatIncludedInSameOptionSet(
        targetOptionSet
      );
      const optionSetValuesWithoutTargetOptionSet = currentValues.filter(
        (currentValueOptionId: number) => {
          return !targetOptionSetValuesExcludeCurrentValue.includes(
            currentValueOptionId
          );
        }
      );

      const sameOptionSetValues = checked
        ? targetOptionSet.getSelectCountMax() === 1
          ? [value]
          : [value, ...targetOptionSetValuesExcludeCurrentValue]
        : targetOptionSet.getSelectCountMax() === 1
        ? []
        : targetOptionSetValuesExcludeCurrentValue.filter(
            (optionId: number) => optionId !== value
          );

      const newOptionSetValues = checked
        ? targetOptionSet.getSelectCountMax() === 1
          ? [value, ...optionSetValuesWithoutTargetOptionSet]
          : [value, ...currentValues]
        : currentValues.filter(optionId => optionId !== value);

      if (
        !targetOptionSet.hasNoSelectCountMax() &&
        sameOptionSetValues.length > targetOptionSet.getSelectCountMax()
      ) {
        addAnimationFlag();
        return;
      }

      handleChangePreOrderOptionList(newOptionSetValues);

      updateErrorOptionList(
        targetOptionSet,
        sameOptionSetValues.length,
        targetOptionSet.getSelectCountMin(),
        targetOptionSet.getSelectCountMax()
      );

      updateOptionIdArray(newOptionSetValues);
    },
    [
      handleChangePreOrderOptionList,
      updateErrorOptionList,
      connectingAddOrUpdateItem,
      connectingDeleteFromCart,
      disabled,
      item,
      options,
      getOptionsThatIncludedInSameOptionSet,
      updateOptionIdArray
    ]
  );

  return !optionSets.isEmpty() ? (
    <>
      <OptionSetTitle>オプション</OptionSetTitle>
      {optionSets.map((optionSet: ShopOptionSet) => {
        const hasError = errorOptionSetIdList.includes(optionSet.getId());
        const errorMax = errorMaxOptionSetIdList.includes(optionSet.getId());
        return (
          <OptionSetItem key={`${optionSet.getId()}`} errorMax={errorMax}>
            <OptionSetHead>
              <Grid container align="center">
                <Grid item grow={1}>
                  <OptionSetName>
                    {optionSet.getSetName()}
                    <OptionSetNotice require={optionSet.isRequired()}>
                      {optionSet.getOptionSetNotice()}
                    </OptionSetNotice>
                  </OptionSetName>
                </Grid>
              </Grid>
              <OptionSetError
                active={hasError}
                displayWhenMount={displayWhenMount}
              >
                {optionSet.isRequired() ? (
                  <CommonNotice type="warning">
                    {optionSet.getOptionSetWarning()}
                  </CommonNotice>
                ) : (
                  ""
                )}
              </OptionSetError>
            </OptionSetHead>
            <OptionList>
              {optionSet.getOptionList().map((option, index) => {
                const inputId = `option${option.getId()}`;
                const disabledOption = !isActiveOption(option.getId());
                return (
                  <Transition
                    key={option.getId()}
                    timeout={400}
                    in={shakeAnimationFlag}
                    onEntered={endAnimation}
                  >
                    {status => {
                      const shake =
                        status === "entering" || status === "entered";
                      return (
                        <OptionItem disabled={disabledOption} shake={shake}>
                          <label htmlFor={inputId}>
                            <OptionItemInner>
                              <OptionItemIcon>
                                <input
                                  id={inputId}
                                  name={`option${optionSet.getId()}[]`}
                                  type="checkbox"
                                  value={option.getId()}
                                  onChange={handleChangeOption}
                                  checked={!disabledOption}
                                  style={invisibleStyle}
                                />
                                <CircleCheckIcon
                                  checked={!disabledOption}
                                  style={checkIconStyle}
                                />
                              </OptionItemIcon>
                              <OptionItemName>
                                {option.getOptionName()}
                              </OptionItemName>
                              <OptionItemPrice>
                                {option.getPresentationPrice()}
                              </OptionItemPrice>
                            </OptionItemInner>
                          </label>
                        </OptionItem>
                      );
                    }}
                  </Transition>
                );
              })}
            </OptionList>
          </OptionSetItem>
        );
      })}
    </>
  ) : null;
});

export default OptionSetListViewer;
