import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { IMaintenanceInfo } from "../../../records/MaintenanceInfo";
import { IUpdateBuildPayload } from "../../../records/UpdateBuildPayload";
import { IUpdateResourcePayload } from "../../../records/UpdateResourcePayload";
import { ReduxModel } from "../../../reducer";
import APICache from "../../../util/APICache";
import { EPlatform } from "../../../util/CordovaUtil";
import Utility from "../../../util/Utility";
import { userAccessedMaintenancePage } from "../../user/actions";
import UserModel from "../../user/model";
import {
  setMaintenanceInfo,
  setUpdateBuildPayload,
  setUpdateResourcePayload,
  systemFinishedConnectApi,
  systemOpenedModal,
  systemStartedConnectApi
} from "../actions";
import AppModel from "../model";
import commonApiFailedSaga from "./commonApiFailedSaga";
import commonApiSuccessSaga from "./commonApiSuccessSaga";

const STATUS_CODE_SHOULD_UPDATE_BUILD = 202;
const STATUS_CODE_SHOULD_UPDATE_RESOURCE = 203;
const STATUS_CODE_MAINTENANCE = 503;

/**
 * apiの形式の種別
 * FILE => ローカルのjsonファイルをGETで取得する
 * RPC => ベースとなるendPointにリクエストを投げる
 * METHOD => ベースとなるendPoint/method名にリクエストを投げる
 */
enum ApiType {
  FILE = "file",
  RPC = "rpc",
  METHOD = "method"
}

/**
 * fetchメソッドに渡すRequestオブジェクトを生成する
 */
function createRequest(
  method: string,
  params: any,
  common: ICommonParams,
  isDebug: boolean = false
) {
  const apiTypeEnv = process.env.REACT_APP_API_TYPE;
  const apiType = typeof apiTypeEnv !== "undefined" ? apiTypeEnv : ApiType.FILE;

  const basePathEnv = process.env.REACT_APP_API_BASE_PATH;
  const debugBasePathEnv = process.env.REACT_APP_API_BASE_PATH_DEBUG;

  const basePath =
    isDebug && typeof debugBasePathEnv !== "undefined"
      ? debugBasePathEnv
      : typeof basePathEnv !== "undefined"
      ? basePathEnv
      : "";

  switch (apiType) {
    case ApiType.RPC:
      return new Request(
        `${basePath}?method=${method}${
          common.userId !== "" ? `&id=${common.userId}` : ""
        }`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jsonrpc: "2.0",
            method,
            params,
            common_params: common,
            id: 0
          })
        }
      );
    case ApiType.METHOD:
      return new Request(`${basePath}/${method}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          params,
          common_params: common,
          id: 0
        })
      });
    default:
      return new Request(`/api/user/${method}.json`);
  }
}

interface ICommonParams {
  token: string | null;
  platform: EPlatform;
  build_version: string | null;
  resource_version: number;
  resource_version_url: string;
  referrer_url: string;
  current_url: string;
  userId: string;
}

export interface APIOptions {
  fallback?: boolean;
  ignoreCache?: boolean;
}

const DEFAULT_API_OPTIONS: APIOptions = {
  fallback: false,
  ignoreCache: false
};

/**
 * @param method
 * @param params
 * @param fallback エラーハンドリングを呼び出し元で行いたい場合はtrueを指定
 */
export default function* commonApiSaga(
  method: EApiKey,
  params: any,
  options: APIOptions = DEFAULT_API_OPTIONS
) {
  try {
    if (!options.ignoreCache && APICache.shouldUseCache(method, params)) {
      return APICache.getCache(method, params);
    }

    const user: UserModel = yield select<(state: ReduxModel) => UserModel>(
      state => state.user
    );

    const app: AppModel = yield select<(state: ReduxModel) => AppModel>(
      state => state.app
    );

    const common: ICommonParams = {
      token: user.getLoginToken(),
      platform: AppModel.getPlatform(),
      build_version: app.getAppVersion(),
      resource_version: app.getResourceVersion(),
      resource_version_url: Utility.getResourceVersionJsonFilePath(),
      referrer_url: Utility.getReferrerUrl(),
      current_url: Utility.getCurrentUrl(),
      userId: user.getData().getId()
    };

    const request = createRequest(method, params, common, user.isDebugUser());
    yield put(systemStartedConnectApi(method));
    const response: Response = yield fetch(request);
    yield put(systemFinishedConnectApi(method));

    // アプリの更新が必要な場合
    if (response.status === STATUS_CODE_SHOULD_UPDATE_BUILD) {
      const {
        result: shouldUpdateBuildPayload
      }: { result: IUpdateBuildPayload } = yield response.json();
      yield put(setUpdateBuildPayload(shouldUpdateBuildPayload));
      yield put(systemOpenedModal("SHOULD_UPDATE_BUILD", {}));
      yield cancel();
    }

    // リソースの更新が必要な場合
    if (response.status === STATUS_CODE_SHOULD_UPDATE_RESOURCE) {
      const {
        result: shouldUpdateResourcePayload
      }: { result: IUpdateResourcePayload } = yield response.json();
      yield put(setUpdateResourcePayload(shouldUpdateResourcePayload));
      yield put(systemOpenedModal("SHOULD_UPDATE_RESOURCE", {}));
      yield cancel();
    }

    if (response.status >= 400) {
      throw response;
    } else {
      const task = yield fork(() =>
        commonApiSuccessSaga(response, method, params)
      );
      yield join(task);
      if (task.isCancelled()) {
        yield cancel();
      } else {
        return task.result();
      }
    }
  } catch (exception) {
    yield put(systemFinishedConnectApi(method));
    if (!(exception instanceof Response)) {
      return { error: exception };
    } else {
      // メンテナンス時のリダイレクト指定
      if (exception.status === STATUS_CODE_MAINTENANCE) {
        const maintenanceData: any = yield exception.json();
        if ("result" in maintenanceData) {
          yield put(
            setMaintenanceInfo(maintenanceData.result as IMaintenanceInfo)
          );
        }
        yield put(userAccessedMaintenancePage());
        yield cancel();
      }

      if (options.fallback) {
        return { error: exception };
      } else {
        const task = yield fork(() =>
          commonApiFailedSaga(exception as Response)
        );
        yield join(task);
        if (task.isCancelled()) {
          yield cancel();
        } else {
          return task.result();
        }
      }
    }
  }
}
