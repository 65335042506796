import { List } from "immutable";
import { Link } from "react-router-dom";
import React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import CategoryTag from "../../records/CategoryTag";
import ThumbnailContainer from "../atoms/ThumbnailContainer";
import Thumbnail from "../molecules/Thumbnail";

const containerStyle: React.CSSProperties = {
  overflow: "hidden",
  marginBottom: "20px"
};

const innerStyle: React.CSSProperties = {
  marginBottom: "-20px",
  paddingBottom: "20px",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch"
};

const listStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 26px"
};

const anchorStyle: React.CSSProperties = {
  flexGrow: 0,
  color: colorsConst.MAIN
};

const overlayTextContainerStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.3)"
};

const overlayTextStyle: React.CSSProperties = {
  padding: "0 0.5em",
  fontSize: "12px",
  lineHeight: 17 / 12,
  letterSpacing: "-0.03em",
  fontWeight: 500,
  color: "#FFFFFF",
  textShadow: "0px 0px 3px rgba(0, 0, 0, 0.8)",
  textAlign: "center"
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 5px;
  line-height: ${28 / 19};
  border-bottom: 2px solid #ff3008;
`;

const Title = styled.div`
  flex-grow: 1;
  font-size: 19px;
  font-weight: 500;
  color: #595959;
`;

interface IProps {
  categoryTagList: List<CategoryTag>;
  handleClick: (categoryId: number) => void;
}

const HomeCategoryTagList: React.FC<IProps> = React.memo(
  ({ categoryTagList, handleClick }) => {
    const handleClickWrap = useCallback(
      (categoryId: number) => {
        handleClick(categoryId);
      },
      [handleClick]
    );
    return (
      <div style={containerStyle}>
        <TitleContainer>
          <Title>ジャンルから選ぶ</Title>
          <Link to={"/category/tagList"} style={anchorStyle}>
            ジャンル一覧
          </Link>
        </TitleContainer>
        <div style={innerStyle}>
          <ul style={listStyle}>
            {categoryTagList.map((categoryTag, index) => {
              return (
                <li
                  key={categoryTag.getId()}
                  style={{
                    paddingRight:
                      index + 1 === categoryTagList.size ? "26px" : 0,
                    paddingLeft: index !== 0 ? "10px" : 0
                  }}
                >
                  <ThumbnailContainer
                    size={100}
                    borderRadius={5}
                    onClick={() => handleClickWrap(categoryTag.getId())}
                  >
                    <Thumbnail src={categoryTag.getImagePath()} />
                    <div style={overlayTextContainerStyle}>
                      <div style={overlayTextStyle}>
                        {categoryTag.getTagName()}
                      </div>
                    </div>
                  </ThumbnailContainer>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
);

export default HomeCategoryTagList;
