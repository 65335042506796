import { replace } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IUpdatePasswordRequest,
  IUpdatePasswordResponse
} from "../../../apis/updatePassword";
import { EFormID } from "../../../forms";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateUser,
  userLoggedIn,
  UserSubmittedResetPasswordAction
} from "../actions";

export function* userSubmittedResetPasswordSaga(
  action: UserSubmittedResetPasswordAction
) {
  try {
    const { password, passwordConfirm } = action.payload;
    const params: IUpdatePasswordRequest = {
      password,
      confirmation_password: passwordConfirm
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_PASSWORD,
      params
    );
    const {
      result,
      error
    }: { result: IUpdatePasswordResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));
      yield put(replace("/resetPasswordCompleted"));
      yield put(destroy(EFormID.RESET_PASSWORD));
      yield put(userLoggedIn());
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `パスワードの再設定に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
