import { is } from "immutable";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { systemOpenedModal } from "../../../modules/app/actions";
import {
  userChangedPriceRange,
  userChangedSearchRadius,
  userChangedSortType
} from "../../../modules/search/actions";
import { SearchResultType } from "../../../records/SearchResult";
import PriceRange from "../../../records/SearchSetting/PriceRange";
import {
  DISPLAYABLE_RESULT_SORT_TYPE_LIST,
  getResultSortTypeText
} from "../../../records/SearchSetting/resultSortType";
import {
  recordAnalyticsChangeResultSortType,
  ResultSortType
} from "../../../records/SearchSetting/resultSortType";
import {
  SearchRadius,
  displayableSearchRadiusList,
  displayableSearchRadiusPresentationText
} from "../../../records/SearchSetting/searchRadius";
import { ReduxModel } from "../../../reducer";
import colorsConst from "../../../styles/const/colorsConst";
import Analytics, {
  AnalyticsEventSearchChangeDistance,
  AnalyticsEventSearchOpenPriceSetting,
  AnalyticsEventSearchResetPriceRange
} from "../../../util/Analytics";
import ConditionItem from "./ConditionItem";

const conditionItemTextStyle: React.CSSProperties = {
  fontSize: "15px",
  lineHeight: `${22 / 15}`,
  fontWeight: "bold",
  color: " #80b927"
};

const Container = styled.div`
  width: 100%;
  background-color: ${colorsConst.BACKGROUND};
`;

const TopContainer = styled.div`
  overflow: hidden;
`;

const ConditionItemList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid #c4c4c4;
  padding-bottom: 30px;
  margin-bottom: -20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 15px;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
`;

const IconContainer = styled.div`
  flex: 0 0 auto;
  margin-left: 4px;
`;

const SortSettingInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  padding: 4px;
  color: #757575;
  border-bottom: 2px solid #ffa592;
`;

const ResultCount = styled.div`
  margin-left: 10px;
  color: #9fa0a0;
`;

interface IProps {
  searchResultType: Exclude<SearchResultType, "keyword">;
}

const ConditionList: React.FC<IProps> = React.memo(({ searchResultType }) => {
  const reservationTime = useSelector((state: ReduxModel) => {
    const searchSetting = state.search
      .getSearchSettings()
      .getData(searchResultType);
    return searchSetting.getReservationTime();
  });

  const sortType = useSelector((state: ReduxModel) => {
    const searchSetting = state.search
      .getSearchSettings()
      .getData(searchResultType);
    return searchSetting.getSortType();
  });

  const currentPriceRangeSetting = useSelector((state: ReduxModel) => {
    return state.search
      .getSearchSettings()
      .getData(searchResultType)
      .getPriceRange();
  });

  const resultList = useSelector(
    (state: ReduxModel) => {
      const setting = state.search
        .getSearchSettings()
        .getData(searchResultType);
      return state.search
        .getSearchResult()
        .getList(searchResultType, setting.getSearchResultDisplayCondition());
    },
    (left, right) => is(left, right)
  );

  const currentSearchRadius = useSelector((state: ReduxModel) =>
    state.search
      .getSearchSettings()
      .getData(searchResultType)
      .getSearchRadius()
  );

  const dispatch = useDispatch();

  const handleChangeSearchRadius = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      const radius = parseInt(value, 10);
      if (Number.isNaN(radius)) {
        return;
      }
      const analyticsEventName = `search_${searchResultType}_change_distance` as AnalyticsEventSearchChangeDistance;
      Analytics.logEvent(analyticsEventName, {
        content_type: "distance",
        item_id: `${radius}`
      });
      dispatch(
        userChangedSearchRadius(searchResultType, radius as SearchRadius)
      );
    },
    [dispatch, searchResultType]
  );

  const handleClickSettingReserveTime = useCallback(() => {
    Analytics.logEvent("search_reserve_open_reserve_time_setting");
    dispatch(
      systemOpenedModal("SETTING_RESERVATION", {
        searchResultType: "reserve"
      })
    );
  }, [dispatch]);

  const handleClickSettingPriceRange = useCallback(() => {
    const analyticsEventName = `search_${searchResultType}_open_price_setting` as AnalyticsEventSearchOpenPriceSetting;
    Analytics.logEvent(analyticsEventName);
    dispatch(systemOpenedModal("SETTING_PRICE_RANGE", { searchResultType }));
  }, [dispatch, searchResultType]);

  const handleChangeSortType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      const type = value as ResultSortType;

      recordAnalyticsChangeResultSortType(type, searchResultType);
      dispatch(userChangedSortType(searchResultType, type));
    },
    [dispatch, searchResultType]
  );

  const handleResetPriceRange = useCallback(() => {
    if (!currentPriceRangeSetting.isActive()) {
      return;
    }
    const analyticsEventName = `search_${searchResultType}_reset_price_range` as AnalyticsEventSearchResetPriceRange;
    Analytics.logEvent(analyticsEventName);
    dispatch(userChangedPriceRange(searchResultType, PriceRange.asDefault()));
  }, [currentPriceRangeSetting, dispatch, searchResultType]);

  return (
    <Container>
      <TopContainer>
        <ConditionItemList>
          <ConditionItem>
            <select
              defaultValue={`${currentSearchRadius}`}
              onChange={handleChangeSearchRadius}
              style={conditionItemTextStyle}
            >
              {displayableSearchRadiusList.map(radius => (
                <option key={radius} value={radius}>
                  {displayableSearchRadiusPresentationText(radius)}
                </option>
              ))}
            </select>
          </ConditionItem>
          {searchResultType === "reserve" ? (
            <ConditionItem handleClick={handleClickSettingReserveTime}>
              {reservationTime.getSettingText()}
            </ConditionItem>
          ) : null}
          <ConditionItem
            disabled={!currentPriceRangeSetting.isActive()}
            handleClick={handleClickSettingPriceRange}
            handleReset={
              currentPriceRangeSetting.isActive()
                ? handleResetPriceRange
                : undefined
            }
          >
            {currentPriceRangeSetting.getSettingText()}
          </ConditionItem>
        </ConditionItemList>
      </TopContainer>

      <BottomContainer>
        <SortSettingInfo>
          <select
            value={sortType}
            onChange={handleChangeSortType}
            style={{
              color: "#757575"
            }}
          >
            {DISPLAYABLE_RESULT_SORT_TYPE_LIST.map(type => (
              <option key={type} value={type}>
                {getResultSortTypeText(type)}
              </option>
            ))}
          </select>
          <IconContainer
            style={{
              fontSize: "10px",
              color: "#FF3008"
            }}
          >
            ▼
          </IconContainer>
        </SortSettingInfo>
        <ResultCount>{`${resultList.size}件`}</ResultCount>
      </BottomContainer>
    </Container>
  );
});

export default ConditionList;
