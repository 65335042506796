import { List } from "immutable";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import NewsItem, { ENewsReleaseType } from "../../records/NewsItem";
import { ReduxModel } from "../../reducer";
import MultiLine from "../atoms/MultiLine";
import Loading from "../molecules/Loading";

const NewsDate = styled.div`
  font-size: 16px;
  line-height: 23px;
  color: #4e4e4e;
  margin-bottom: 4px;
`;

const NewsTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 2px;
`;

const NewsListItem = styled.li`
  padding: 15px 0;
  border-bottom: 1px solid #d7d7d7;
`;

const NewsText = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #4e4e4e;
`;

const NewsBlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 103px;
`;

interface IProps {
  isFetchingNotifications: boolean;
  newsList: List<NewsItem>;
}

const NewsContent = React.memo((props: IProps) => {
  const { isFetchingNotifications, newsList } = props;
  return (
    <>
      {isFetchingNotifications ? (
        <NewsBlankContainer>
          <Loading />
        </NewsBlankContainer>
      ) : newsList.isEmpty() ? (
        <NewsBlankContainer>ニュースはありません</NewsBlankContainer>
      ) : (
        <ul>
          {newsList
            .filter(
              item =>
                item.release_type === ENewsReleaseType.USER ||
                item.release_type === ENewsReleaseType.BOTH
            )
            .map(item => {
              return (
                <NewsListItem key={item.getId()}>
                  <NewsDate>{item.getStartDatetime()}</NewsDate>
                  <NewsTitle>{item.getTitle()}</NewsTitle>
                  <NewsText>
                    <MultiLine>{item.getDetailText()}</MultiLine>
                  </NewsText>
                </NewsListItem>
              );
            })}
        </ul>
      )}
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  isFetchingNotifications: state.notification.isFetchingNotifications()
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsContent);
