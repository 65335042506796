import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IUpdateMailAddressAndPasswordForm {
  mail: string;
  password: string;
  confirmation_password: string;
}

export const initialUpdateMailAddressAndPasswordForm: IUpdateMailAddressAndPasswordForm = {
  mail: "",
  password: "",
  confirmation_password: ""
};

export function validateUpdateMailAddressAndPasswordForm(
  values: IUpdateMailAddressAndPasswordForm
) {
  const errors: FormErrors<IUpdateMailAddressAndPasswordForm> = {};
  if (!values.mail) {
    errors.mail = "メールアドレスを入力して下さい";
  } else if (!UserModel.validEmail(values.mail)) {
    errors.mail = "メールアドレスの形式が正しくありません";
  }
  if (!values.password) {
    errors.password = "パスワードを入力してください";
  } else if (!UserModel.validPassword(values.password)) {
    errors.password = "半角英数記号のみ使用可能です";
  } else if (values.password.length < 8) {
    errors.password = "8文字以上である必要があります";
  }
  if (!values.confirmation_password) {
    errors.confirmation_password =
      "確認のため、パスワードを再度入力してください";
  } else if (values.password !== values.confirmation_password) {
    errors.confirmation_password = "直前に入力されたパスワードと異なります";
  }
  return errors;
}
