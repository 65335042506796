import { Record } from "immutable";
import Utility from "../util/Utility";

export interface IShopInfo {
  shop_id: number;
  shop_name: string;
  shop_name_en: string;
  shop_name_ruby: string;
  address: string;
  pickup_image_path: string;
}

const initialValues: IShopInfo = {
  shop_id: 0,
  shop_name: "",
  shop_name_en: "",
  shop_name_ruby: "",
  address: "",
  pickup_image_path: ""
};

export default class ShopInfo extends Record(initialValues) {
  public getId(): number {
    return this.get("shop_id");
  }

  public exists() {
    return this.getId() !== 0;
  }

  public getShopName(locale: "en" | "ruby" | "" = ""): string {
    switch (locale) {
      case "en":
        return this.get("shop_name_en");
      case "ruby":
        return this.get("shop_name_ruby");
      default:
        return this.get("shop_name");
    }
  }

  public getAddress(): string {
    return this.get("address");
  }

  public getPickupImagePath(): string {
    return `${Utility.getImageDomain()}${this.get("pickup_image_path")}`;
  }
}
