import * as React from "react";
interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgNounOrder1591470000000 = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" fill="none" {...props}>
    <path
      d="M6.366 29.903l.006.004.007.004a.799.799 0 0 0 .785-.041l4.202-2.703 4.56 1.791s0 0 0 0a.769.769 0 0 0 .587 0h.002l3.217-1.333 6.188 2.783h0l.011.005.048.016c.071.026.154.056.256.056.15 0 .308-.037.432-.12a.773.773 0 0 0 .353-.665V3.3a.766.766 0 0 0-.785-.785H6.765a.787.787 0 0 0-.785.785v25.905c0 .276.156.545.386.698zm4.451-4.265l-3.3 2.148V4.085H25.45v24.399l-5.4-2.429h0l-.012-.005a1.993 1.993 0 0 1-.048-.016c-.071-.026-.154-.056-.256-.056h-.005c-.046 0-.098 0-.151.01a.474.474 0 0 0-.166.07l-3.243 1.33-4.64-1.83h0l-.005-.003c-.218-.072-.496-.081-.707.083zm.37-19.658a.787.787 0 0 0-.785.785c0 .432.353.785.785.785h10.626a.787.787 0 0 0 .785-.785.787.787 0 0 0-.785-.785H11.187zm0 4.653a.787.787 0 0 0-.785.785c0 .432.353.785.785.785h10.626a.787.787 0 0 0 .785-.785.787.787 0 0 0-.785-.785H11.187zm0 4.653a.787.787 0 0 0-.785.785c0 .432.353.785.785.785h10.626a.787.787 0 0 0 .785-.785.787.787 0 0 0-.785-.785H11.187zm4.983 7.939a.787.787 0 0 0 .785-.785.787.787 0 0 0-.785-.785h-4.95a.787.787 0 0 0-.785.785c0 .432.353.785.785.785h4.95z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.25}
    />
  </svg>
);

const OrderProcessIcon = React.memo((props: IProps) => (
  <SvgNounOrder1591470000000 {...props} />
));

export default OrderProcessIcon;
