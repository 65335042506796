import React from "react";
import { Route } from "react-router";
import PageTransitionSwitch from "../atoms/PageTransitionSwitch";
import CartEditTemplate from "../templates/CartEditTemplate";
import CartTemplate from "../templates/CartTemplate";
import ChangeCreditCardTemplate from "../templates/ChangeCreditCardTemplate";
import ExclusiveMemberArticlesTemplate from "../templates/ExclusiveMemberArticlesTemplate";
import FastestSearchTemplate from "../templates/FastestSearchTemplate";
import FeatureTemplate from "../templates/FeatureTemplate";
import HomeTemplate from "../templates/HomeTemplate";
import HomeShopListTemplate from "../templates/HomeShopListTemplate";
import AreaTemplate from "../templates/AreaTemplate";
import AreaAllTemplate from "../templates/AreaAllTemplate";
import AreaSubTemplate from "../templates/AreaSubTemplate";
import CategoryTagListTemplate from "../templates/CategoryTagListTemplate";
import CategoryShopListTemplate from "../templates/CategoryShopListTemplate";
import InputRegistrationPlaceTemplate from "../templates/InputRegistrationPlaceTemplate";
import InvitationTemplate from "../templates/InvitationTemplate";
import KeywordSearchTemplate from "../templates/KeywordSearchTemplate";
import LoginTemplate from "../templates/LoginTemplate";
import MaintenanceTemplate from "../templates/MaintenanceTemplate";
import NotificationTemplate from "../templates/NotificationTemplate";
import OrderHistoryDetailTemplate from "../templates/OrderHistoryDetailTemplate";
import OrderHistoryTemplate from "../templates/OrderHistoryTemplate";
import OrderReceiptTemplate from "../templates/OrderReceiptTemplate";
import PrivacyPolicyTemplate from "../templates/PrivacyPolicyTemplate";
import RegisterAgreementsTemplate from "../templates/RegisterAgreementsTemplate";
import RegisterAuthTemplate from "../templates/RegisterAuthTemplate";
import RegisterDiscountCodeTemplate from "../templates/RegisterDiscountCodeTemplate";
import RegisterNickNameTemplate from "../templates/RegisterNickNameTemplate";
import RegisterNickNameAtOrderTemplate from "../templates/RegisterNickNameAtOrderTemplate";
import RegisterTelTemplate from "../templates/RegisterTelTemplate";
import RegisterTelAtFirstAccessTemplate from "../templates/RegisterTelAtFirstAccessTemplate";
import RegisterAuthAtFirstAccessTemplate from "../templates/RegisterAuthAtFirstAccessTemplate";
import RegisterDiscountCodeAtFirstAccessTemplate from "../templates/RegisterDiscountCodeAtFirstAccessTemplate";
import RegisterMailTemplate from "../templates/RegisterMailTemplate";
import RegisterNewCreditCardTemplate from "../templates/RegisterNewCreditCardTemplate";
import UpdateMailTemplate from "../templates/UpdateMailTemplate";
import UpdatePasswordTemplate from "../templates/UpdatePasswordTemplate";
import UpdateTelTemplate from "../templates/UpdateTelTemplate";
import RequestResetPasswordTemplate from "../templates/RequestResetPasswordTemplate";
import ResendSMSAuthCodeForResetPasswordTemplate from "../templates/ResendSMSAuthCodeForResetPasswordTemplate";
import ResendSMSAuthCodeTemplate from "../templates/ResendSMSAuthCodeTemplate";
import ReserveSearchTemplate from "../templates/ReserveSearchTemplate";
import ResetPasswordCompletedTemplate from "../templates/ResetPasswordCompletedTemplate";
import ResetPasswordTemplate from "../templates/ResetPasswordTemplate";
import SettingSearchTemplate from "../templates/SettingReservationTemplate";
import ShopItemTemplate from "../templates/ShopItemTemplate";
import ShopTemplate from "../templates/ShopTemplate";
import SMSAuthForResetPasswordTemplate from "../templates/SMSAuthForResetPasswordTemplate";
import TermsTemplate from "../templates/TermsTemplate";
import TopTemplate from "../templates/TopTemplate";
import UserDebugTemplate from "../templates/UserDebugTemplate";
import UserFAQTemplate from "../templates/UserFAQTemplate";
import UserInfoTemplate from "../templates/UserInfoTemplate";
import UserInquiryTemplate from "../templates/UserInquiryTemplate";
import UserInvitationTemplate from "../templates/UserInvitationTemplate";
import UserTemplate from "../templates/UserTemplate";
import UserTermsPointTemplate from "../templates/UserTermsPointTemplate";
import UserTermsPrivacyPolicyTemplate from "../templates/UserTermsPrivacyPolicyTemplate";
import UserTermsSpecifiedCommercialTransactionLawTemplate from "../templates/UserTermsSpecifiedCommercialTransactionLawTemplate";
import UserTermsTemplate from "../templates/UserTermsTemplate";
import UserTermsUserTemplate from "../templates/UserTermsUserTemplate";
import RequestAreaTemplate from "../templates/RequestAreaTemplate";
import RequestShopTemplate from "../templates/RequestShopTemplate";

const GlobalContent = () => (
  <PageTransitionSwitch>
    <Route path="/top" component={TopTemplate} />
    <Route path="/maintenance" component={MaintenanceTemplate} />
    <Route path="/invitation" component={InvitationTemplate} />
    <Route path="/login" component={LoginTemplate} />
    <Route path="/terms" component={TermsTemplate} />
    <Route path="/privacyPolicy" component={PrivacyPolicyTemplate} />
    <Route path="/resendSMSAuthCode" component={ResendSMSAuthCodeTemplate} />
    <Route
      path="/requestResetPassword"
      component={RequestResetPasswordTemplate}
    />
    <Route
      path="/resendSMSAuthCodeForResetPassword"
      component={ResendSMSAuthCodeForResetPasswordTemplate}
    />
    <Route
      path="/authForResetPassword"
      component={SMSAuthForResetPasswordTemplate}
    />
    <Route path="/resetPassword" component={ResetPasswordTemplate} />
    <Route
      path="/resetPasswordCompleted"
      component={ResetPasswordCompletedTemplate}
    />

    <Route path="/register/agreements" component={RegisterAgreementsTemplate} />
    <Route
      exact
      path="/register/discountCode"
      component={RegisterDiscountCodeTemplate}
    />
    <Route
      path="/register/discountCodeAtFirstAccess"
      component={RegisterDiscountCodeAtFirstAccessTemplate}
    />
    <Route path="/register/nickName" component={RegisterNickNameTemplate} />
    <Route
      path="/register/nickNameAtOrder"
      component={RegisterNickNameAtOrderTemplate}
    />
    <Route exact path="/register/tel" component={RegisterTelTemplate} />
    <Route
      path="/register/telAtFirstAccess"
      component={RegisterTelAtFirstAccessTemplate}
    />
    <Route exact path="/register/auth" component={RegisterAuthTemplate} />
    <Route
      path="/register/authAtFirstAccess"
      component={RegisterAuthAtFirstAccessTemplate}
    />
    <Route path="/register/mail" component={RegisterMailTemplate} />
    <Route path="/update/mail" component={UpdateMailTemplate} />
    <Route path="/update/password" component={UpdatePasswordTemplate} />
    <Route path="/update/tel" component={UpdateTelTemplate} />

    <Route
      exact
      path="/setting/search/:searchResultType"
      component={SettingSearchTemplate}
    />

    <Route path="/search/fastest" component={FastestSearchTemplate} />
    <Route path="/search/reserve" component={ReserveSearchTemplate} />
    <Route path="/search/keyword" component={KeywordSearchTemplate} />

    <Route exact path="/area/:id" component={AreaTemplate} />
    <Route exact path="/area/:id/all" component={AreaAllTemplate} />
    <Route
      exact
      path="/area/:id/subArea/:subAreaId"
      component={AreaSubTemplate}
    />

    <Route exact path="/category/tagList" component={CategoryTagListTemplate} />
    <Route
      exact
      path="/category/shopList"
      component={CategoryShopListTemplate}
    />

    <Route exact path="/shop/:id" component={ShopTemplate} />
    <Route exact path="/shop/:id/item/:itemId" component={ShopItemTemplate} />

    <Route exact path="/cart" component={CartTemplate} />
    <Route exact path="/cart/:cartId/edit/:id" component={CartEditTemplate} />

    <Route exact path="/notification" component={NotificationTemplate} />

    <Route exact path="/" component={HomeTemplate} />

    <Route exact path="/homeShopList/:type" component={HomeShopListTemplate} />

    <Route exact path="/history" component={OrderHistoryTemplate} />
    <Route
      exact
      path="/history/:orderNumber"
      component={OrderHistoryDetailTemplate}
    />
    <Route path="/orderReceipt/:orderNumber" component={OrderReceiptTemplate} />

    <Route exact path="/user" component={UserTemplate} />
    <Route exact path="/user/info" component={UserInfoTemplate} />
    <Route exact path="/user/terms" component={UserTermsTemplate} />
    <Route exact path="/user/terms/user" component={UserTermsUserTemplate} />
    <Route
      exact
      path="/user/terms/privacyPolicy"
      component={UserTermsPrivacyPolicyTemplate}
    />
    <Route
      exact
      path="/user/terms/law"
      component={UserTermsSpecifiedCommercialTransactionLawTemplate}
    />
    <Route exact path="/user/terms/point" component={UserTermsPointTemplate} />
    <Route
      exact
      path="/user/changeCreditCard"
      component={ChangeCreditCardTemplate}
    />
    <Route
      exact
      path="/user/registerNewCreditCard"
      component={RegisterNewCreditCardTemplate}
    />
    <Route exact path="/user/invitation" component={UserInvitationTemplate} />
    <Route exact path="/user/faq" component={UserFAQTemplate} />
    <Route exact path="/user/inquiry" component={UserInquiryTemplate} />
    <Route exact path="/user/debug" component={UserDebugTemplate} />
    <Route
      exact
      path="/user/inputRegistrationPlace/:placeType"
      component={InputRegistrationPlaceTemplate}
    />

    <Route exact path="/feature/:featureId" component={FeatureTemplate} />
    <Route
      exact
      path="/exclusiveMemberArticles"
      component={ExclusiveMemberArticlesTemplate}
    />

    <Route exact path="/request/area" component={RequestAreaTemplate} />
    <Route exact path="/request/shop" component={RequestShopTemplate} />
  </PageTransitionSwitch>
);

export default GlobalContent;
