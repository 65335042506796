import { List } from "immutable";
import * as React from "react";
import Feedback from "../../records/Feedback";
import FeedbackList from "../organisms/FeedbackList";

interface IProps {
  feedbackList: List<Feedback>;
}

const ShopTabFeedback: React.FC<IProps> = React.memo(props => {
  const { feedbackList } = props;
  return (
    <div
      style={{ paddingTop: "20px", paddingRight: "20px", paddingLeft: "20px" }}
    >
      {feedbackList.isEmpty() ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "60px",
            color: "#b5b5b6"
          }}
        >
          口コミがありません
        </div>
      ) : (
        <FeedbackList list={feedbackList} />
      )}
    </div>
  );
});

export default ShopTabFeedback;
