import { Action } from "redux";
import { ICartDataProps } from "../../../records/CartData";
import { IOrderDataProps } from "../../../records/OrderData";
import ReservationTime from "../../../records/ReservationTime";
import { ActionTypes } from "./index";
import { EPaymentType } from "../../../records/PaymentType";

export interface UpdateCartAction extends Action {
  type: ActionTypes.R_UPDATE_CART;
  payload: {
    cartList: ICartDataProps[];
  };
}
export const updateCart = (cartList: ICartDataProps[]): UpdateCartAction => ({
  type: ActionTypes.R_UPDATE_CART,
  payload: {
    cartList
  }
});

export interface UpdateOrderListAction extends Action {
  type: ActionTypes.R_UPDATE_ORDER_LIST;
  payload: {
    orders: IOrderDataProps[];
  };
}
export const updateOrderList = (
  orders: IOrderDataProps[]
): UpdateOrderListAction => ({
  type: ActionTypes.R_UPDATE_ORDER_LIST,
  payload: {
    orders
  }
});

export interface UpdateCurrentOrderDetailAction extends Action {
  type: ActionTypes.R_UPDATE_CURRENT_ORDER_DETAIL;
  payload: {
    detail: IOrderDataProps | null;
  };
}
export const updateCurrentOrderDetail = (
  detail: IOrderDataProps | null
): UpdateCurrentOrderDetailAction => ({
  type: ActionTypes.R_UPDATE_CURRENT_ORDER_DETAIL,
  payload: {
    detail
  }
});

export interface InitPreOrderAction extends Action {
  type: ActionTypes.R_INIT_PRE_ORDER;
  payload: {
    initial: {
      option_list: number[];
      count: number;
      instruction_text: string;
    };
  };
}
export const initPreOrder = (initial: {
  option_list: number[];
  count: number;
  instruction_text: string;
}): InitPreOrderAction => ({
  type: ActionTypes.R_INIT_PRE_ORDER,
  payload: {
    initial
  }
});

export interface UpdatePreOrderOptionListAction extends Action {
  type: ActionTypes.R_UPDATE_PRE_ORDER_OPTION_LIST;
  payload: {
    optionList: number[];
  };
}
export const updatePreOrderOptionList = (optionList: number[]) => ({
  type: ActionTypes.R_UPDATE_PRE_ORDER_OPTION_LIST,
  payload: {
    optionList
  }
});

export interface UpdatePreOrderCountAction extends Action {
  type: ActionTypes.R_UPDATE_PRE_ORDER_COUNT;
  payload: {
    count: number;
  };
}
export const updatePreOrderCount = (
  count: number
): UpdatePreOrderCountAction => ({
  type: ActionTypes.R_UPDATE_PRE_ORDER_COUNT,
  payload: {
    count
  }
});

export interface UpdatePreOrderTextAction extends Action {
  type: ActionTypes.R_UPDATE_PRE_ORDER_TEXT;
  payload: {
    text: string;
  };
}
export const updatePreOrderText = (text: string) => ({
  type: ActionTypes.R_UPDATE_PRE_ORDER_TEXT,
  payload: {
    text
  }
});

export interface UpdateFeedbackStateAction extends Action {
  type: ActionTypes.R_UPDATE_FEEDBACK_STATE;
  payload: {
    orderNumber: string;
  };
}
export const updateFeedbackState = (
  orderNumber: string
): UpdateFeedbackStateAction => ({
  type: ActionTypes.R_UPDATE_FEEDBACK_STATE,
  payload: {
    orderNumber
  }
});

export interface UpdateFeedbackPointAction extends Action {
  type: ActionTypes.R_UPDATE_FEEDBACK_POINT;
  payload: {
    orderNumber: string;
    point: number;
  };
}
export const updateFeedbackPoint = (
  orderNumber: string,
  point: number
): UpdateFeedbackPointAction => ({
  type: ActionTypes.R_UPDATE_FEEDBACK_POINT,
  payload: {
    orderNumber,
    point
  }
});

export interface UpdateFeedbackTextAction extends Action {
  type: ActionTypes.R_UPDATE_FEEDBACK_TEXT;
  payload: {
    orderNumber: string;
    text: string;
  };
}
export const updateFeedbackText = (
  orderNumber: string,
  text: string
): UpdateFeedbackTextAction => ({
  type: ActionTypes.R_UPDATE_FEEDBACK_TEXT,
  payload: {
    orderNumber,
    text
  }
});

export interface UpdateDraftFeedbackPointAction extends Action {
  type: ActionTypes.R_UPDATE_DRAFT_FEEDBACK_POINT;
  payload: {
    orderNumber: string;
    point: number;
  };
}
export const updateDraftFeedbackPoint = (
  orderNumber: string,
  point: number
): UpdateDraftFeedbackPointAction => ({
  type: ActionTypes.R_UPDATE_DRAFT_FEEDBACK_POINT,
  payload: {
    orderNumber,
    point
  }
});

export interface UpdateDraftFeedbackTextAction extends Action {
  type: ActionTypes.R_UPDATE_DRAFT_FEEDBACK_TEXT;
  payload: {
    orderNumber: string;
    text: string;
  };
}
export const updateDraftFeedbackText = (
  orderNumber: string,
  text: string
): UpdateDraftFeedbackTextAction => ({
  type: ActionTypes.R_UPDATE_DRAFT_FEEDBACK_TEXT,
  payload: {
    orderNumber,
    text
  }
});

export interface UpdateOrderNumberForDialogAction extends Action {
  type: ActionTypes.R_UPDATE_ORDER_NUMBER_FOR_DIALOG;
  payload: {
    orderNumber: string;
  };
}
export const updateOrderNumberForDialog = (
  orderNumber: string
): UpdateOrderNumberForDialogAction => ({
  type: ActionTypes.R_UPDATE_ORDER_NUMBER_FOR_DIALOG,
  payload: {
    orderNumber
  }
});

export interface UpdateOrderCartIdAction extends Action {
  type: ActionTypes.R_UPDATE_ORDER_CART_ID;
  payload: {
    cartId: string | undefined;
  };
}
export const updateOrderCartId = (
  cartId: string | undefined
): UpdateOrderCartIdAction => ({
  type: ActionTypes.R_UPDATE_ORDER_CART_ID,
  payload: {
    cartId
  }
});

export interface UpdateReservationTimeForDialogAction extends Action {
  type: ActionTypes.R_UPDATE_RESERVATION_TIME_FOR_DIALOG;
  payload: {
    reservationTime: ReservationTime;
  };
}
export const updateReservationTimeForDialog = (
  reservationTime: ReservationTime
): UpdateReservationTimeForDialogAction => ({
  type: ActionTypes.R_UPDATE_RESERVATION_TIME_FOR_DIALOG,
  payload: {
    reservationTime
  }
});

export interface UpdateCartIdForDialogAction extends Action {
  type: ActionTypes.R_UPDATE_CART_ID_FOR_DIALOG;
  payload: {
    cartId: string | undefined;
  };
}
export const updateCartIdForDialog = (
  cartId: string
): UpdateCartIdForDialogAction => ({
  type: ActionTypes.R_UPDATE_CART_ID_FOR_DIALOG,
  payload: {
    cartId
  }
});

export interface UpdateItemIndexForDialogAction extends Action {
  type: ActionTypes.R_UPDATE_ITEM_INDEX_FOR_DIALOG;
  payload: {
    index: number;
  };
}
export const updateItemIndexForDialog = (
  index: number
): UpdateItemIndexForDialogAction => ({
  type: ActionTypes.R_UPDATE_ITEM_INDEX_FOR_DIALOG,
  payload: {
    index
  }
});

export interface UpdateShopItemFavoriteState extends Action {
  type: ActionTypes.R_UPDATE_SHOP_ITEM_FAVORITE_STATE;
  payload: {
    shopItemId: number;
    bool: boolean;
  };
}
export const updateShopItemFavoriteState = (
  shopItemId: number,
  bool: boolean
) => ({
  type: ActionTypes.R_UPDATE_SHOP_ITEM_FAVORITE_STATE,
  payload: {
    shopItemId,
    bool
  }
});

export interface UpdatePreOrderCartIdAction extends Action {
  type: ActionTypes.R_UPDATE_PRE_ORDER_CART_ID;
  payload: {
    cartId: string;
  };
}
export const updatePreOrderCartId = (cartId: string) => ({
  type: ActionTypes.R_UPDATE_PRE_ORDER_CART_ID,
  payload: {
    cartId
  }
});

interface UpdatePaymentTypeAction extends Action {
  type: ActionTypes.R_UPDATE_PAYMENT_TYPE;
  payload: {
    paymentType: EPaymentType;
  };
}
export const updatePaymentType = (
  paymentType: EPaymentType
): UpdatePaymentTypeAction => ({
  type: ActionTypes.R_UPDATE_PAYMENT_TYPE,
  payload: {
    paymentType
  }
});

export type ReducerActions =
  | UpdateOrderCartIdAction
  | UpdateFeedbackStateAction
  | UpdateFeedbackPointAction
  | UpdateFeedbackTextAction
  | UpdateDraftFeedbackPointAction
  | UpdateDraftFeedbackTextAction
  | InitPreOrderAction
  | UpdatePreOrderOptionListAction
  | UpdatePreOrderCountAction
  | UpdatePreOrderTextAction
  | UpdateOrderListAction
  | UpdateCurrentOrderDetailAction
  | UpdateCartAction
  | UpdateOrderNumberForDialogAction
  | UpdateReservationTimeForDialogAction
  | UpdateCartIdForDialogAction
  | UpdateItemIndexForDialogAction
  | UpdateShopItemFavoriteState
  | UpdatePreOrderCartIdAction
  | UpdatePaymentTypeAction;
