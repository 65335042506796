import { fork, put } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import { EDiscountCodeResultState } from "../../../records/DiscountCode";
import { systemAddedAlert } from "../../app/actions";
import fetchDiscountCodesSaga from "./fetchDiscountCodesSaga";

export default function* showDialogByInputDiscountCodeResultStatusSaga(
  status: EDiscountCodeResultState
) {
  switch (status) {
    case EDiscountCodeResultState.SUCCESSED:
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "クーポンを取得しました。",
          message: "次回注文時に自動で適用されます。"
        })
      );
      yield fork(() => fetchDiscountCodesSaga());
      break;
    case EDiscountCodeResultState.SUCCESSED_INVITE_CODE:
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "クーポンを取得しました。",
          message: "ご注文いただくとご友人にもクーポンが配布されます。"
        })
      );
      yield fork(() => fetchDiscountCodesSaga());
      break;
    case EDiscountCodeResultState.DUPLICATED:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title: "すでに取得済みのクーポンコードです。",
          message: "利用可能なクーポンコードを入力してください。"
        })
      );
      break;
    case EDiscountCodeResultState.INVALID:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title: "無効なクーポンコードです。",
          message: "利用可能なクーポンコードを入力してください。"
        })
      );
      break;
    case EDiscountCodeResultState.EMPTY:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title: "クーポンコードの入力がありません。",
          message: "対象のクーポンコードを入力してください。"
        })
      );
      break;
    case EDiscountCodeResultState.EXPIRED:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title: "期限切れのクーポンコードです。",
          message: "利用可能なクーポンコードを入力してください。"
        })
      );
      break;
    case EDiscountCodeResultState.ORDERED:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title: "初回注文前にクーポンコードを入力された方のみご利用できます。",
          message: ""
        })
      );
      break;
    case EDiscountCodeResultState.EXCLUSIVE:
      yield put(
        systemAddedAlert({
          type: AlertType.Warning,
          title:
            "初回限定クーポンと招待された友達招待クーポンの併用はできません。",
          message: ""
        })
      );
      break;
    default:
  }
}
