import { Record, Set } from "immutable";

export enum EFlagKey {
  OPEN_SEARCH_HELPER,
  OPEN_SEARCH_FIELD,
  FETCH_FASTEST_SEARCH
}

interface IFlagManager {
  flagSet: Set<EFlagKey>;
}

const initial: IFlagManager = {
  flagSet: Set()
};

export default class FlagManager extends Record(initial) {
  public setFlag(key: EFlagKey | EFlagKey[]) {
    const newSet = Array.isArray(key)
      ? this.getFlagSet().concat(Set(key))
      : this.getFlagSet().add(key);
    return this.set("flagSet", newSet);
  }

  public unsetFlag(key: EFlagKey | EFlagKey[]) {
    const newSet = Array.isArray(key)
      ? this.getFlagSet().subtract(Set(key))
      : this.getFlagSet().delete(key);
    return this.set("flagSet", newSet);
  }

  public enabled(key: EFlagKey) {
    return this.getFlagSet().has(key);
  }

  public someEnabled(key: EFlagKey[]) {
    const set = this.getFlagSet();
    return key.some(k => set.includes(k));
  }

  public allEnabled(key: EFlagKey[]) {
    const set = this.getFlagSet();
    return key.every(k => set.includes(k));
  }

  private getFlagSet() {
    return this.get("flagSet");
  }
}
