import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  red?: boolean;
  lightGray?: boolean;
}

const SvgArrow = (props: IProps) => {
  const { red, lightGray, ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 11" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59952 4.12488L1.4 0L0 1.37512L4.19952 5.5L0 9.62488L1.4 11L5.59952 6.87512L5.6 6.87559L7 5.50047L6.99952 5.5L7 5.49953L5.6 4.12441L5.59952 4.12488Z"
        fill={red ? "#ED2D21" : lightGray ? "#757575" : "#3F3D40"}
      />
    </svg>
  );
};

const ArrowSmallIcon: React.FC<IProps> = props => <SvgArrow {...props} />;

export default ArrowSmallIcon;
