import { List } from "immutable";
import { push } from "connected-react-router";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  CellMeasurer,
  CellMeasurerCache
} from "react-virtualized/dist/es/CellMeasurer";
import { List as RVList, ListRowProps } from "react-virtualized/dist/es/List";
import { WindowScroller } from "react-virtualized/dist/es/WindowScroller";
import styled from "styled-components";
import { SearchResultType } from "../../records/SearchResult";
import SearchPlace from "../../records/SearchSetting/SearchPlace";
import ShopForSearch from "../../records/ShopForSearch";
import colorsConst from "../../styles/const/colorsConst";
import ScrollTopMemory, { EScrollTopKey } from "../../util/ScrollTopMemory";
import { useScrollContainer } from "../atoms/Page";
import ButtonAlter from "../atoms/ButtonAlter";
import { CART_BUTTON_HEIGHT } from "./CartButton";
import ShopListItemViewer from "./ShopForSearchViewer/ShopListItemViewer";
import { TOGGLE_RESULT_VIEW_TYPE_BUTTON_HEIGHT } from "./ToggleResultViewTypeButton";

const BlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vw * ${115 / 375});
`;

const Divider = styled.div`
  margin: 0 15px;
  height: 2px;
  background-color: #efefef;
`;

const RequestButtonContainer = styled.div`
  margin-top: 25px;
  padding: 0 50px;
`;

interface IProps {
  shopList: List<ShopForSearch>;
  handleClickShop?: (shopId: number) => void;
  searchPlace?: SearchPlace;
  existsCart?: boolean;
  resultType: SearchResultType;
}

const ListSearchResult: React.FC<IProps> = React.memo(
  ({ shopList, handleClickShop, existsCart, searchPlace, resultType }) => {
    const renderCache = useRef(new CellMeasurerCache({ fixedWidth: true }));

    const rowRenderer = useCallback(
      ({ key, style, index, parent }: ListRowProps) => {
        const shop = shopList.get(index);
        const handleClickShopWrap = () => {
          if (
            typeof handleClickShop !== "undefined" &&
            typeof shop !== "undefined"
          ) {
            handleClickShop(shop.getId());
          }
        };

        return typeof shop === "undefined" ? null : (
          <CellMeasurer
            cache={renderCache.current}
            columnIndex={0}
            rowIndex={index}
            parent={parent}
            key={key}
          >
            {({ measure }) => (
              <div onClick={handleClickShopWrap} style={style}>
                <div
                  style={{
                    backgroundColor: colorsConst.BACKGROUND
                  }}
                >
                  {index !== 0 ? <Divider /> : null}
                  <ShopListItemViewer
                    item={shop}
                    onMount={measure}
                    searchPlace={searchPlace}
                    resultType={resultType}
                  />
                </div>
              </div>
            )}
          </CellMeasurer>
        );
      },
      [handleClickShop, resultType, searchPlace, shopList]
    );

    const scrollContainer = useScrollContainer();

    const handleScroll = useCallback(
      (_: { scrollLeft: number; scrollTop: number }) => {
        if (scrollContainer !== null) {
          ScrollTopMemory.save(
            `${EScrollTopKey.TEXT_SEARCH_RESULT}`,
            scrollContainer.scrollTop
          );
        }
      },
      [scrollContainer]
    );

    const noRowsRenderer = useCallback(
      () => <BlankContainer>検索結果がありません</BlankContainer>,
      []
    );

    const listContainerStyle = useMemo(() => {
      const base = existsCart ? CART_BUTTON_HEIGHT : 0;
      return {
        paddingBottom: `${base +
          TOGGLE_RESULT_VIEW_TYPE_BUTTON_HEIGHT +
          11 * 2}px`
      };
    }, [existsCart]);

    useEffect(() => {
      const defaultScrollTop = ScrollTopMemory.loadOrDefault(
        `${EScrollTopKey.TEXT_SEARCH_RESULT}`,
        0
      );
      if (scrollContainer !== null) {
        scrollContainer.scrollTop = defaultScrollTop;
      }
    }, [shopList, scrollContainer]);

    const dispatch = useDispatch();

    const handleClickRequestButton = useCallback(() => {
      dispatch(push("/request/shop"));
    }, [dispatch]);

    return (
      <div>
        <WindowScroller
          scrollElement={scrollContainer !== null ? scrollContainer : undefined}
          onScroll={handleScroll}
        >
          {({
            width,
            height,
            isScrolling,
            scrollTop,
            onChildScroll,
            registerChild
          }) => {
            return (
              <div
                ref={(registerChild as unknown) as any}
                style={listContainerStyle}
              >
                <RVList
                  autoHeight
                  deferredMeasurementCache={renderCache.current}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  rowHeight={renderCache.current.rowHeight}
                  rowCount={shopList.size}
                  width={width}
                  height={height}
                  rowRenderer={rowRenderer}
                  noRowsRenderer={noRowsRenderer}
                />
                <Divider />
                <RequestButtonContainer>
                  <ButtonAlter
                    block
                    appearance={"sub"}
                    onClick={handleClickRequestButton}
                    style={{ paddingTop: "9px", paddingBottom: "9px" }}
                  >
                    希望のお店をリクエスト
                  </ButtonAlter>
                </RequestButtonContainer>
              </div>
            );
          }}
        </WindowScroller>
      </div>
    );
  }
);

export default ListSearchResult;
