import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState
} from "react";
import ReactResizeDetector from "react-resize-detector";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import zIndexConst from "../../styles/const/zIndexConst";

export const PageContainerContext = createContext<HTMLDivElement | null>(null);
export const ScrollContainerContext = createContext<HTMLDivElement | null>(
  null
);

export function useScrollContainer() {
  return useContext(ScrollContainerContext);
}

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colorsConst.PAGE_BACKGROUND};
`;

const HeaderContainer = styled.header`
  position: absolute;
  z-index: ${zIndexConst.FIXED};
  top: 0;
  left: 0;
  width: 100%;
`;

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
`;

const FooterContainer = styled.footer`
  position: absolute;
  z-index: ${zIndexConst.FIXED};
  bottom: 0;
  left: 0;
  width: 100%;
`;

interface IProps {
  header?: JSX.Element | null;
  footer?: JSX.Element | null;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  defaultScrollTop?: number;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  headerContainerStyle?: React.CSSProperties;
  handleScroll?: (event: React.UIEvent<HTMLElement>) => void;
}

const Page = React.memo((props: IProps) => {
  const {
    header,
    footer,
    children,
    defaultScrollTop,
    handleScroll,
    containerStyle,
    contentStyle,
    headerContainerStyle,
    ...rest
  } = props;

  const [contentPadding, setContentPadding] = useState<React.CSSProperties>({});

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [headerContainer, setHeaderContainer] = useState<HTMLElement | null>(
    null
  );
  const [
    contentContainer,
    setContentContainer
  ] = useState<HTMLDivElement | null>(null);
  const [footerContainer, setFooterContainer] = useState<HTMLElement | null>(
    null
  );

  const handleScrollContent = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      if (handleScroll) {
        handleScroll(event);
      }
    },
    [handleScroll]
  );

  const setScrollTop = useCallback(() => {
    if (contentContainer !== null && defaultScrollTop) {
      contentContainer.scrollTop = defaultScrollTop;
    }
  }, [contentContainer, defaultScrollTop]);

  const setContentPaddingWrap = useCallback(() => {
    setContentPadding({
      paddingTop: `${
        headerContainer ? `${headerContainer.clientHeight}px` : "0"
      }`,
      paddingBottom: `${
        footerContainer ? `${footerContainer.clientHeight}px` : "0"
      }`
    });
  }, [headerContainer, footerContainer]);

  useLayoutEffect(() => {
    setContentPaddingWrap();
    setScrollTop();
  }, [header, footer, setContentPaddingWrap, setScrollTop]);

  return (
    <Container {...rest} style={containerStyle} ref={setContainerRef}>
      {header ? (
        <HeaderContainer ref={setHeaderContainer} style={headerContainerStyle}>
          <ReactResizeDetector handleHeight onResize={setContentPaddingWrap}>
            {header}
          </ReactResizeDetector>
        </HeaderContainer>
      ) : null}
      {children ? (
        <ContentContainer
          ref={setContentContainer}
          style={{ ...contentPadding, ...contentStyle }}
          onScroll={handleScrollContent}
        >
          <PageContainerContext.Provider value={containerRef}>
            <ScrollContainerContext.Provider value={contentContainer}>
              {children}
            </ScrollContainerContext.Provider>
          </PageContainerContext.Provider>
        </ContentContainer>
      ) : null}
      {footer ? (
        <FooterContainer ref={setFooterContainer}>
          <ReactResizeDetector handleHeight onResize={setContentPaddingWrap}>
            {footer}
          </ReactResizeDetector>
        </FooterContainer>
      ) : null}
    </Container>
  );
});

export default Page;
