import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  invert?: boolean;
  children?: never;
}

const SvgArrow = (props: IProps) => {
  const { invert, ...rest } = props;
  const fill = invert ? "#fff" : "#000";
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 17" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.485 0L0 8.485l8.485 8.486L9.9 15.556l-6.072-6.07h17.086v-2H3.828L9.9 1.413 8.485 0z"
        fill={fill}
      />
    </svg>
  );
};

const ArrowIcon: React.FC<IProps> = props => <SvgArrow {...props} />;

export default ArrowIcon;
