import * as React from "react";
import { ColorProperty } from "csstype";
import colorsConst from "../../styles/const/colorsConst";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const MoreArrowIcon: React.FC<IProps> = ({
  size = 40,
  color = colorsConst.MAIN,
  ...rest
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2643 20.2988L17.8362 29.7269L15.7466 27.6373L23.085 20.2988L15.7466 12.9604L17.8362 10.8707L27.2643 20.2988Z"
        fill={color}
      />
      <circle cx="20" cy="20" r="19.5" stroke={color} />
    </svg>
  );
};

export default MoreArrowIcon;
