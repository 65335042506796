import { Record } from "immutable";
import { EPaymentType } from "./PaymentType";

export interface IPayment {
  is_credit_card: boolean;
  is_local: boolean;
}

const initial: IPayment = {
  is_credit_card: false,
  is_local: false
};

export default class Payment extends Record(initial) {
  constructor(args: any = {}) {
    super(args);
  }

  public canPay(paymentType: EPaymentType) {
    switch (paymentType) {
      case EPaymentType.CREDIT:
        return this.canCredit();
      case EPaymentType.LOCALLY:
        return this.canLocal();
      default:
        return false;
    }
  }

  public canCredit() {
    return this.get("is_credit_card");
  }
  public canLocal() {
    return this.get("is_local");
  }
}
