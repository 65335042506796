import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IUpdatePasswordForm {
  password: string;
  confirmation_password: string;
}

export const initialUpdatePasswordForm: IUpdatePasswordForm = {
  password: "",
  confirmation_password: ""
};

export function validateUpdatePasswordForm(values: IUpdatePasswordForm) {
  const errors: FormErrors<IUpdatePasswordForm> = {};
  if (!values.password) {
    errors.password = "パスワードを入力してください";
  } else if (!UserModel.validPassword(values.password)) {
    errors.password = "半角英数記号のみ使用可能です";
  } else if (values.password.length < 8) {
    errors.password = "8文字以上である必要があります";
  }
  if (!values.confirmation_password) {
    errors.confirmation_password =
      "確認のため、パスワードを再度入力してください";
  } else if (values.password !== values.confirmation_password) {
    errors.confirmation_password = "直前に入力されたパスワードと異なります";
  }
  return errors;
}
