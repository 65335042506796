import { Action } from "redux";
import { ERegistrationPlace } from "../../../records/PlaceListItem";
import { ILatLng } from "../../search/model";
import { ActionTypes } from "./index";

export interface UserAccessedToPageThatNeedsDiscountCodesAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_DISCOUNT_CODES;
}
export const userAccessedToPageThatNeedsDiscountCodes = (): UserAccessedToPageThatNeedsDiscountCodesAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_DISCOUNT_CODES
});

export interface UserSubmittedDiscountCodeAction extends Action {
  type: ActionTypes.U_SUBMITTED_DISCOUNT_CODE;
}
export const userSubmittedDiscountCode = (): UserSubmittedDiscountCodeAction => ({
  type: ActionTypes.U_SUBMITTED_DISCOUNT_CODE
});

export interface UserSubmittedDiscountCodeAtFirstAccessAction extends Action {
  type: ActionTypes.U_SUBMITTED_DISCOUNT_CODE_AT_FIRST_ACCESS;
}
export const userSubmittedDiscountCodeAtFirstAccess = (): UserSubmittedDiscountCodeAtFirstAccessAction => ({
  type: ActionTypes.U_SUBMITTED_DISCOUNT_CODE_AT_FIRST_ACCESS
});

export interface UserTouchedSkipInputDiscountCodeButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_SKIP_INPUT_DISCOUNT_CODE_BUTTON;
}
export const userTouchedSkipInputDiscountCodeButton = (): UserTouchedSkipInputDiscountCodeButtonAction => ({
  type: ActionTypes.U_TOUCHED_SKIP_INPUT_DISCOUNT_CODE_BUTTON
});

export interface UserTouchedToButtonThatReturnFromMaintenancePageAciton
  extends Action {
  type: ActionTypes.U_TOUCHED_TO_BUTTON_THAT_RETURN_FROM_MAINTENANCE_PAGE;
}
export const userTouchedToButtonThatReturnFromMaintenancePage = (): UserTouchedToButtonThatReturnFromMaintenancePageAciton => ({
  type: ActionTypes.U_TOUCHED_TO_BUTTON_THAT_RETURN_FROM_MAINTENANCE_PAGE
});

export interface UserAccessedMaintenancePageAction extends Action {
  type: ActionTypes.U_ACCESSED_MAINTENANCE_PAGE;
}
export const userAccessedMaintenancePage = (): UserAccessedMaintenancePageAction => ({
  type: ActionTypes.U_ACCESSED_MAINTENANCE_PAGE
});

export interface UserAccessedToPageThatNeedsCreditCardDataAction
  extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CREDIT_CARD_DATA;
}
export const userAccessedToPageThatNeedsCreditCardData = (): UserAccessedToPageThatNeedsCreditCardDataAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CREDIT_CARD_DATA
});

// ユーザーがゲストログインのボタンを押した
export interface UserTouchedGuestLoginButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_GUEST_LOGIN_BUTTON;
}
export const userTouchedGuestLoginButton = (): UserTouchedGuestLoginButtonAction => ({
  type: ActionTypes.U_TOUCHED_GUEST_LOGIN_BUTTON
});

export interface UserTouchedLogoutButtonAction extends Action {
  type: ActionTypes.U_TOUCHED_LOGOUT_BUTTON;
}
export const userTouchedLogoutButton = (): UserTouchedLogoutButtonAction => ({
  type: ActionTypes.U_TOUCHED_LOGOUT_BUTTON
});

export interface UserSubmittedRegisterNewCreditCardAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_NEW_CREDIT_CARD;
}
export const userSubmittedRegisterNewCreditCard = (): UserSubmittedRegisterNewCreditCardAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_NEW_CREDIT_CARD
});

export interface UserSubmittedChangeSelectCreditCardAction extends Action {
  type: ActionTypes.U_SUBMITTED_CHANGE_SELECT_CREDIT_CARD;
  payload: {
    id: string;
  };
}
export const userSubmittedChangeSelectCreditCard = (
  id: string
): UserSubmittedChangeSelectCreditCardAction => ({
  type: ActionTypes.U_SUBMITTED_CHANGE_SELECT_CREDIT_CARD,
  payload: {
    id
  }
});

export interface UserLoggedInAction extends Action {
  type: ActionTypes.U_LOGGED_IN;
}
export const userLoggedIn = (): UserLoggedInAction => ({
  type: ActionTypes.U_LOGGED_IN
});

export interface UserAccessedToPageThatNeedsAuthAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AUTH;
}
export const userAccessedToPageThatNeedsAuth = (): UserAccessedToPageThatNeedsAuthAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_AUTH
});

// ユーザーがパスワード変更をリクエストした
export interface UserSubmittedRequestResetPasswordAction extends Action {
  type: ActionTypes.U_SUBMITTED_REQUEST_RESET_PASSOWORD;
}
export const userSubmittedRequestResetPassword = (): UserSubmittedRequestResetPasswordAction => ({
  type: ActionTypes.U_SUBMITTED_REQUEST_RESET_PASSOWORD
});

export interface UserSubmittedAuthResetPasswordAction extends Action {
  type: ActionTypes.U_SUBMITTED_AUTH_RESET_PASSWORD;
  payload: {
    tel: string;
    auth_code: string;
  };
}
export const userSubmittedAuthResetPassword = (
  authCode: string,
  tel: string
): UserSubmittedAuthResetPasswordAction => ({
  type: ActionTypes.U_SUBMITTED_AUTH_RESET_PASSWORD,
  payload: {
    tel,
    auth_code: authCode
  }
});

// ユーザーがパスワード変更をサブミットした
export interface UserSubmittedResetPasswordAction extends Action {
  type: ActionTypes.U_SUBMITTED_RESET_PASSOWORD;
  payload: {
    password: string;
    passwordConfirm: string;
  };
}
export const userSubmittedResetPassword = (
  password: string,
  passwordConfirm: string
): UserSubmittedResetPasswordAction => ({
  type: ActionTypes.U_SUBMITTED_RESET_PASSOWORD,
  payload: {
    password,
    passwordConfirm
  }
});

// ユーザーがメールアドレス・パスワード登録フォームをサブミットした
export interface UserSubmittedUpdateMailAddressAndPasswordAction
  extends Action {
  type: ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS_AND_PASSWORD;
}
export const userSubmittedUpdateMailAddressAndPassword = (): UserSubmittedUpdateMailAddressAndPasswordAction => ({
  type: ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS_AND_PASSWORD
});

// ユーザーがメールアドレス変更フォームをサブミットした
export interface UserSubmittedUpdateMailAddressAction extends Action {
  type: ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS;
}
export const userSubmittedUpdateMailAddress = (): UserSubmittedUpdateMailAddressAction => ({
  type: ActionTypes.U_SUBMITTED_UPDATE_MAIL_ADDRESS
});

// ユーザーがパスワード変更フォームをサブミットした
export interface UserSubmittedUpdatePasswordAction extends Action {
  type: ActionTypes.U_SUBMITTED_UPDATE_PASSWORD;
}
export const userSubmittedUpdatePassword = (): UserSubmittedUpdatePasswordAction => ({
  type: ActionTypes.U_SUBMITTED_UPDATE_PASSWORD
});

// ユーザーがニックネーム登録フォームをサブミットした
export interface UserSubmittedRegisterNickNameAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME;
}
export const userSubmittedRegisterNickName = (): UserSubmittedRegisterNickNameAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME
});

// ユーザーが注文時のニックネーム登録フォームをサブミットした
export interface UserSubmittedRegisterNickNameAtOrderAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME_AT_ORDER;
}
export const userSubmittedRegisterNickNameAtOrder = (): UserSubmittedRegisterNickNameAtOrderAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_NICK_NAME_AT_ORDER
});

// ユーザーが注文時のニックネーム登録フォームをスキップした
export interface UserTouchedSkipRegisterNickNameAction extends Action {
  type: ActionTypes.U_TOUCHED_SKIP_REGISTER_NICK_NAME;
}
export const userTouchedSkipRegisterNickName = (): UserTouchedSkipRegisterNickNameAction => ({
  type: ActionTypes.U_TOUCHED_SKIP_REGISTER_NICK_NAME
});

// ユーザーが個人情報登録フォームをサブミットした
export interface UserSubmittedRegisterPersonalAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_PERSONAL;
}
export const userSubmittedRegisterPersonal = (): UserSubmittedRegisterPersonalAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_PERSONAL
});

// ユーザーが初回ログイン時のSMS認証フォームをサブミットした
export interface UserSubmittedRegisterAuthAtFirstAccessAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_AUTH_AT_FIRST_ACCESS;
  payload: {
    authCode: string;
  };
}
export const userSubmittedRegisterAuthAtFirstAccess = (
  authCode: string
): UserSubmittedRegisterAuthAtFirstAccessAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_AUTH_AT_FIRST_ACCESS,
  payload: {
    authCode
  }
});

// ユーザーがSMS認証フォームをサブミットした
export interface UserSubmittedRegisterAuthAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_AUTH;
  payload: {
    authCode: string;
  };
}
export const userSubmittedRegisterAuth = (
  authCode: string
): UserSubmittedRegisterAuthAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_AUTH,
  payload: {
    authCode
  }
});

// ユーザーが初回ログイン時のTel登録フォームをサブミットした
export interface UserSubmittedRegisterTelAtFirstAccessAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTER_TEL_AT_FIRST_ACCESS;
}
export const userSubmittedRegisterTelAtFirstAccess = (): UserSubmittedRegisterTelAtFirstAccessAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTER_TEL_AT_FIRST_ACCESS
});
// ユーザーが***のTel登録フォームをサブミットした
export interface UserSubmittedUpdateTelAction extends Action {
  type: ActionTypes.U_SUBMITTED_UPDATE_TEL;
}
export const userSubmittedUpdateTel = (): UserSubmittedUpdateTelAction => ({
  type: ActionTypes.U_SUBMITTED_UPDATE_TEL
});

// ユーザーがSMS認証コードの再送信をリクエストするボタンを押した
export interface UserTouchedRequestToResentSMSAuthCodeButtonAction
  extends Action {
  type: ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_BUTTON;
  payload: {
    tel: string;
  };
}
export const userTouchedRequestToResentSMSAuthCodeButton = (
  tel: string
): UserTouchedRequestToResentSMSAuthCodeButtonAction => ({
  type: ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_BUTTON,
  payload: {
    tel
  }
});

export interface UserTouchedRequestToResentSMSAuthCodeForResetPasswordButtonAction
  extends Action {
  type: ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_FOR_RESET_PASSWORD_BUTTON;
  payload: {
    tel: string;
  };
}
export const userTouchedRequestToResentSMSAuthCodeForResetPasswordButton = (
  tel: string
): UserTouchedRequestToResentSMSAuthCodeForResetPasswordButtonAction => ({
  type:
    ActionTypes.U_TOUCHED_REQUEST_TO_RESENT_SMS_AUTH_CODE_FOR_RESET_PASSWORD_BUTTON,
  payload: {
    tel
  }
});

// ユーザーがログインフォームをサブミットした
export interface UserSubmittedLoginAction extends Action {
  type: ActionTypes.U_SUBMITTED_LOGIN;
}
export const userSubmittedLogin = (): UserSubmittedLoginAction => ({
  type: ActionTypes.U_SUBMITTED_LOGIN
});

export interface UserTouchedRequestToNotifyAuthCodeByVirtualCallButtonAction
  extends Action {
  type: ActionTypes.U_TOUCHED_REQUEST_TO_NOTIFY_AUTH_CODE_BY_VIRTUAL_CALL_BUTTON;
  payload: {
    tel: string;
  };
}
export const userTouchedRequestToNotifyAuthCodeByVirtualCallButton = (
  tel: string
): UserTouchedRequestToNotifyAuthCodeByVirtualCallButtonAction => ({
  type:
    ActionTypes.U_TOUCHED_REQUEST_TO_NOTIFY_AUTH_CODE_BY_VIRTUAL_CALL_BUTTON,
  payload: {
    tel
  }
});

export interface UserAccessedToPageThatNeedsUserDataAction extends Action {
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_USER_DATA;
}
export const userAccessedToPageThatNeedsUserData = (): UserAccessedToPageThatNeedsUserDataAction => ({
  type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_USER_DATA
});

export interface UserSubmittedRegistrationPlaceAction extends Action {
  type: ActionTypes.U_SUBMITTED_REGISTRATION_PLACE;
  payload: {
    type: ERegistrationPlace;
    text: string;
    latLng: ILatLng;
  };
}
export const userSubmittedRegistrationPlace = (
  type: ERegistrationPlace,
  text: string,
  latLng: ILatLng
): UserSubmittedRegistrationPlaceAction => ({
  type: ActionTypes.U_SUBMITTED_REGISTRATION_PLACE,
  payload: {
    type,
    text,
    latLng
  }
});

// ユーザーがエリア拡大希望フォームをサブミットした
export interface UserSubmittedRequestAreaAction extends Action {
  type: ActionTypes.U_SUBMITTED_REQUEST_AREA;
}
export const userSubmittedRequestArea = (): UserSubmittedRequestAreaAction => ({
  type: ActionTypes.U_SUBMITTED_REQUEST_AREA
});

// ユーザーが店舗掲載希望フォームをサブミットした
export interface UserSubmittedRequestShopAction extends Action {
  type: ActionTypes.U_SUBMITTED_REQUEST_SHOP;
}
export const userSubmittedRequestShop = (): UserSubmittedRequestShopAction => ({
  type: ActionTypes.U_SUBMITTED_REQUEST_SHOP
});

export type UserAndSystemActions =
  | UserSubmittedRegistrationPlaceAction
  | UserAccessedToPageThatNeedsUserDataAction
  | UserTouchedRequestToNotifyAuthCodeByVirtualCallButtonAction
  | UserAccessedMaintenancePageAction
  | UserTouchedToButtonThatReturnFromMaintenancePageAciton
  | UserAccessedToPageThatNeedsDiscountCodesAction
  | UserAccessedToPageThatNeedsCreditCardDataAction
  | UserSubmittedDiscountCodeAction
  | UserSubmittedDiscountCodeAtFirstAccessAction
  | UserTouchedSkipInputDiscountCodeButtonAction
  | UserTouchedLogoutButtonAction
  | UserSubmittedRegisterNewCreditCardAction
  | UserSubmittedChangeSelectCreditCardAction
  | UserLoggedInAction
  | UserSubmittedLoginAction
  | UserSubmittedRegisterTelAtFirstAccessAction
  | UserSubmittedUpdateTelAction
  | UserSubmittedUpdateMailAddressAndPasswordAction
  | UserSubmittedUpdateMailAddressAction
  | UserSubmittedUpdatePasswordAction
  | UserSubmittedRegisterNickNameAction
  | UserSubmittedRegisterNickNameAtOrderAction
  | UserTouchedSkipRegisterNickNameAction
  | UserSubmittedRegisterPersonalAction
  | UserSubmittedRequestResetPasswordAction
  | UserSubmittedAuthResetPasswordAction
  | UserSubmittedResetPasswordAction
  | UserAccessedToPageThatNeedsAuthAction
  | UserSubmittedRegisterAuthAtFirstAccessAction
  | UserSubmittedRegisterAuthAction
  | UserTouchedRequestToResentSMSAuthCodeButtonAction
  | UserTouchedRequestToResentSMSAuthCodeForResetPasswordButtonAction
  | UserTouchedGuestLoginButtonAction
  | UserSubmittedRequestAreaAction
  | UserSubmittedRequestShopAction;
