import { push } from "connected-react-router";
import React, { useCallback, useMemo } from "react";
import { batch, connect } from "react-redux";
import { Dispatch } from "redux";
import { systemClosedModal } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import Dialog, { DialogBody } from "../atoms/Dialog";
import Modal from "../molecules/Modal";

const modalStyle: React.CSSProperties = {
  padding: "35px"
};

const mapStateToProps = (state: ReduxModel) => ({
  isOpenDialog: state.app.getModalManager().canDisplay("REQUEST_FOR_REVIEW")
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeDialog() {
    dispatch(systemClosedModal("REQUEST_FOR_REVIEW"));
  },
  goToShop(shopId: number) {
    batch(() => {
      dispatch(push(`/shop/${shopId}`));
      dispatch(systemClosedModal("REQUEST_FOR_REVIEW"));
    });
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RequestForReviewDialog: React.FC<IProps> = React.memo(props => {
  const { isOpenDialog, closeDialog } = props;

  const handleClickClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <Modal
      isOpen={isOpenDialog}
      innerStyle={modalStyle}
      handleClickBackdrop={handleClickClose}
    >
      <Dialog style={{ padding: "30px", paddingBottom: "10px" }}>
        <DialogBody>
          <div
            style={{
              paddingBottom: "11px",
              fontSize: "17px",
              color: "#000000",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            注文してお店を評価しよう！
          </div>
          <div
            style={{
              paddingBottom: "22px",
              lineHeight: 1.5,
              fontSize: "13px",
              textAlign: "center",
              color: "#3A3A47"
            }}
          >
            注文後に評価してくださった方へ、抽選でクーポンをプレゼントします。
          </div>
          <div
            style={{
              padding: "17px",
              fontSize: "13px",
              color: "#3A3A47",
              textAlign: "center"
            }}
            onClick={handleClickClose}
          >
            閉じる
          </div>
        </DialogBody>
      </Dialog>
    </Modal>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestForReviewDialog);
