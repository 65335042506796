import React from "react";
import styled from "styled-components";
import ShopForSearch from "../../../records/ShopForSearch";
import Stars from "../../atoms/Stars";

const ReviewInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  padding-bottom: 4px;
  border-bottom: 2px solid #c9caca;
`;

const ReviewInfoStars = styled.div``;

const ReviewInfoValue = styled.div`
  padding-left: 4px;
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 500;
  color: #000000;
`;

interface IProps {
  item: ShopForSearch;
}

const ShopReviewPoint: React.FC<IProps> = React.memo(({ item }) => {
  return (
    <ReviewInfo>
      <ReviewInfoStars>
        <Stars rate={item.getReviewPointAsFloat()} />
      </ReviewInfoStars>
      <ReviewInfoValue>{item.getReviewPoint()}</ReviewInfoValue>
    </ReviewInfo>
  );
});

export default ShopReviewPoint;
