import { put } from "redux-saga/effects";
import {
  updatePreOrderOptionList,
  UserChangedPreOrderOptionListAction
} from "../actions";

export default function* userChangedPreOrderOptionListSaga(
  action: UserChangedPreOrderOptionListAction
) {
  yield put(updatePreOrderOptionList(action.payload.optionList));
}
