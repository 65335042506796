import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { systemLaunched } from "../../modules/app/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import LoadingApp from "../molecules/LoadingApp";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
`;

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public systemLaunched() {
    this.dispatch(systemLaunched());
  }
}

interface IProps {
  isLaunched: boolean;
  completedToReadyForUserOperation: boolean;
  actions: ActionDispatcher;
  children: React.ReactNode;
}

const AppContainer = React.memo((props: IProps) => {
  const {
    isLaunched,
    completedToReadyForUserOperation,
    actions,
    children
  } = props;

  useEffect(() => {
    if (!isLaunched) {
      actions.systemLaunched();
    }
  }, []);

  return !completedToReadyForUserOperation ? (
    <LoadingApp />
  ) : (
    <Container>{children}</Container>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  isLaunched: state.app.isLaunched(),
  completedToReadyForUserOperation: state.app.completedToReadyForUserOperation()
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
