import { push, replace } from "connected-react-router";
import { destroy } from "redux-form";
import { call, cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IInputDiscountCodeRequest,
  IInputDiscountCodeResponse
} from "../../../apis/inputDiscountCode";
import { EFormID } from "../../../forms";
import {
  IInputDiscountCodeForm,
  initialInputDiscountCodeForm
} from "../../../forms/InputDiscountCode";
import { AlertType } from "../../../records/Alert";
import DiscountCode, {
  EDiscountCodeResultState
} from "../../../records/DiscountCode";
import { ReduxModel } from "../../../reducer";
import Utility from "../../../util/Utility";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateUser,
  userLoggedIn,
  UserSubmittedDiscountCodeAction
} from "../actions";
import getUserDataSaga from "../../user/sagas/getUserDataSaga";
import showDialogByInputDiscountCodeResultStatusSaga from "./showDialogByInputDiscountCodeResultStatusSaga";
import TempStringMemory from "../../../util/TempStringMemory";

export function* userSubmittedDiscountCodeSaga(
  action: UserSubmittedDiscountCodeAction
) {
  TempStringMemory.delete("DISCOUNT_CODE");

  const formData: IInputDiscountCodeForm = yield select<
    (state: ReduxModel) => IInputDiscountCodeForm
  >(state =>
    Utility.getReduxFormValues(
      state,
      EFormID.INPUT_DISCOUNT_CODE,
      initialInputDiscountCodeForm
    )
  );
  const fetchingTask = yield fork(() =>
    fetchInputDiscountCodeSaga(formData.discount_code)
  );
  const fetchingResult: EDiscountCodeResultState = yield join(fetchingTask);

  if (fetchingTask.isCancelled()) {
    yield cancel();
  }
  if (fetchingResult === EDiscountCodeResultState.NEED_AUTH) {
    yield put(push("/register/tel"));
  } else if (DiscountCode.isSuccessState(fetchingResult)) {
    TempStringMemory.delete("DISCOUNT_CODE");
  }
}

export function* userSubmittedDiscountCodeAtFisrtAccessSaga() {
  const formData: IInputDiscountCodeForm = yield select<
    (state: ReduxModel) => IInputDiscountCodeForm
  >(state =>
    Utility.getReduxFormValues(
      state,
      EFormID.INPUT_DISCOUNT_CODE,
      initialInputDiscountCodeForm
    )
  );
  const fetchingTask = yield fork(() =>
    fetchInputDiscountCodeSaga(formData.discount_code)
  );
  const fetchingResult: EDiscountCodeResultState = yield join(fetchingTask);
  if (fetchingTask.isCancelled()) {
    yield cancel();
  }
  if (fetchingResult === EDiscountCodeResultState.NEED_AUTH) {
    yield put(push("/register/telAtFirstAccess"));
  } else if (DiscountCode.isSuccessState(fetchingResult)) {
    TempStringMemory.delete("DISCOUNT_CODE");
    yield put(userLoggedIn());
    yield put(replace("/"));
  }
}

export function* fetchInputDiscountCodeSaga(discount_code: string) {
  try {
    const params: IInputDiscountCodeRequest = {
      discount_code
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.INPUT_DISCOUNT_CODE, params)
    );
    const {
      result,
      error
    }: { result: IInputDiscountCodeResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield fork(() =>
        showDialogByInputDiscountCodeResultStatusSaga(
          result.discount_code_result_state
        )
      );

      if (DiscountCode.isSuccessState(result.discount_code_result_state)) {
        yield put(destroy(EFormID.INPUT_DISCOUNT_CODE));
        const userData = yield call(getUserDataSaga);
        yield put(updateUser(userData));
      } else if (
        result.discount_code_result_state === EDiscountCodeResultState.NEED_AUTH
      ) {
        TempStringMemory.save("DISCOUNT_CODE", discount_code);
      }
      return result.discount_code_result_state;
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `クーポンコードの送信に失敗しました。
          入力内容や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
