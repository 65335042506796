import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const SearchIcon = ({ size = 18, color = "#727272", ...rest }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.117 11.96C14.1201 10.706 14.7218 9.11691 14.7272 7.38746C14.7272 7.38299 14.7273 7.37853 14.7273 7.37406C14.7273 7.37083 14.7273 7.36761 14.7273 7.36438C14.7273 7.36413 14.7273 7.36388 14.7273 7.36364C14.7273 3.55099 11.8297 0.415109 8.11653 0.0380176C7.86898 0.0128782 7.61781 0 7.36364 0C3.29681 0 0 3.29681 0 7.36364C0 11.4305 3.29681 14.7273 7.36364 14.7273C8.8887 14.7273 10.3055 14.2637 11.4807 13.4697C11.6453 13.3585 11.8051 13.2408 11.9599 13.1171C12.387 12.7754 12.7753 12.3871 13.117 11.96ZM12.5416 13.6988C12.9647 13.3526 13.3525 12.9648 13.6987 12.5418L17.7604 16.6034C18.0799 16.9229 18.0799 17.441 17.7604 17.7605C17.4408 18.08 16.9228 18.08 16.6033 17.7605L12.5416 13.6988ZM7.36364 13.0909C10.5267 13.0909 13.0909 10.5267 13.0909 7.36364C13.0909 4.20055 10.5267 1.63636 7.36364 1.63636C4.20055 1.63636 1.63636 4.20055 1.63636 7.36364C1.63636 10.5267 4.20055 13.0909 7.36364 13.0909Z"
      fill={color}
    />
  </svg>
);

export default SearchIcon;
