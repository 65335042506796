import { goBack } from "connected-react-router";
import { cancel, fork, join, put, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IRepublishSmsRequest,
  IRepublishSmsResponse
} from "../../../apis/republishSms";
import { IUpdateTelRequest, IUpdateTelResponse } from "../../../apis/updateTel";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { UserTouchedRequestToNotifyAuthCodeByVirtualCallButtonAction } from "../actions";

export default function* userTouchedRequestToNotifyAuthCodeByVirtualCallButtonSaga(
  action: UserTouchedRequestToNotifyAuthCodeByVirtualCallButtonAction
) {
  try {
    const params: IUpdateTelRequest = {
      tel: action.payload.tel
    };
    const updateTelTask = yield fork(() => updateTel(params));
    const successedUpdateTel = yield join(updateTelTask);
    if (!successedUpdateTel) {
      yield cancel();
    }
    yield fork(() => notifyAuthCodeByVirtualCall(params));
  } catch (e) {
    console.log(e);
  }
}

function* updateTel(params: IUpdateTelRequest) {
  try {
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IUpdateTelResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      return true;
    } else {
      throw error;
    }
  } catch (exception) {
    return false;
  }
}

function* notifyAuthCodeByVirtualCall(params: IRepublishSmsRequest) {
  try {
    const task = yield spawn(commonApiSaga, EApiKey.REPUBLISH_SMS, params);
    const {
      result,
      error
    }: { result: IRepublishSmsResponse; error: unknown } = yield join(task);
    if (task.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(goBack());
      yield put(
        systemAddedAlert({
          type: AlertType.Success,
          title: "",
          message:
            "送信しました。\n電話がかかってくるまでしばらくお待ちください。"
        })
      );
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `リクエストの送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
