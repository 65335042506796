import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import { EApiKey } from "../../apis";
import styled from "styled-components";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Loading from "../molecules/Loading";
import ListSearchResult from "../organisms/ListSearchResult";
import Auth from "../organisms/Auth";

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const CategoryShopListTemplate: React.FC<IProps> = React.memo(props => {
  const {
    currentCategoryTag,
    currentCategoryShopList,
    fetchingResult,
    actions
  } = props;

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title={
              !fetchingResult && currentCategoryTag.getTagName() !== ""
                ? currentCategoryTag.getTagName()
                : " "
            }
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody style={{ padding: 0 }}>
          {fetchingResult ? (
            <BlankContainer>
              <Loading />
            </BlankContainer>
          ) : (
            <ListSearchResult
              shopList={currentCategoryShopList}
              resultType={"keyword"}
            />
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  currentCategoryTag: state.search.getCurrentCategoryTag(),
  currentCategoryShopList: state.search.getCurrentCategoryShopList(),
  fetchingResult: state.app.isConnectedApi(EApiKey.GET_SHOP_LIST_BY_CATEGORY)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}
  public goBack() {
    this.dispatch(goBack());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryShopListTemplate);
