import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import { InjectedFormProps, reduxForm } from "redux-form";
import {
  IRequesrAreaForm,
  initialRequesrAreaForm,
  validateRequesrAreaForm
} from "../../forms/requestArea";
import { userSubmittedRequestArea } from "../../modules/user/actions";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import colorsConst from "../../styles/const/colorsConst";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import Notes from "../atoms/Notes";
import LabelText from "../atoms/LabelText";
import TextField from "../organisms/FormFields/TextField";
import ButtonAlter from "../atoms/ButtonAlter";

const contentStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const RequestAreaTemplate: React.FC<
  IProps & InjectedFormProps<IRequesrAreaForm>
> = React.memo(props => {
  const {
    submittingRequestArea,
    handleGoBack,
    handleSubmit,
    valid,
    touch
  } = props;

  const handleClickBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        touch(...Object.keys(initialRequesrAreaForm));
        return;
      }
      handleSubmit();
    },
    [handleSubmit, valid, touch]
  );

  return (
    <Page
      header={
        <SubPageHeader
          title="エリアをリクエスト"
          handleClickBack={handleClickBack}
        />
      }
      contentStyle={contentStyle}
    >
      <PageBody>
        <Notes>
          「エリアをリクエスト」に関しては個別の回答は行っておりません。頂戴した内容は今後のエリア拡大の参考とさせていただきます。
        </Notes>
        <Notes style={{ marginTop: "14px", marginBottom: "30px" }}>
          ※掲載をお約束するものではございませんことを、あらかじめご理解の程お願いいたします。
        </Notes>
        <form id={EFormID.REQUEST_AREA} onSubmit={handleSubmitWrap}>
          <LabelText htmlFor="expansionArea" style={{ fontWeight: "bold" }}>
            希望エリアや駅名
          </LabelText>
          <TextField id="expansionArea" name="expansion_area" type="text" />
          <ButtonAlter
            block
            appearance={"secondary"}
            loading={submittingRequestArea}
            disabled={!valid}
            type="submit"
            form={EFormID.REQUEST_AREA}
            style={{ paddingTop: "14px", paddingBottom: "14px" }}
          >
            送信
          </ButtonAlter>
        </form>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingRequestArea: state.app.isConnectedApi(
    EApiKey.SEND_AREA_EXPANSION_DESIRED
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoBack() {
    dispatch(goBack());
  },

  handleSubmit() {
    dispatch(userSubmittedRequestArea());
  }
});

const reduxFormConfig = {
  form: EFormID.REQUEST_AREA,
  initialValues: initialRequesrAreaForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateRequesrAreaForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<IRequesrAreaForm, IProps>(reduxFormConfig)(RequestAreaTemplate));
