import { LOCATION_CHANGE } from "connected-react-router";
import { fork, take, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import changeLocationSaga from "./sagas/changeLocationSaga";
import systemAddedAlertSaga from "./sagas/systemAddedAlertSaga";
import systemClosedModalSaga from "./sagas/systemClosedModalSaga";
import systemDetectedResourceUpdateSaga from "./sagas/systemDetectedResourceUpdateSaga";
import systemFinishedConnectApiSaga from "./sagas/systemFinishedConnectApiSaga";
import systemFinishedPlayingPageTransitionAnimationSaga from "./sagas/systemFinishedPlayingPageTransitionAnimationSaga";
import systemLaunchedSaga from "./sagas/systemLaunchedSaga";
import systemOpenedModalSaga from "./sagas/systemOpenedModalSaga";
import systemReadyForUserOperationSaga from "./sagas/systemReadyForUserOperationSaga";
import systemRemovedAlertSaga from "./sagas/systemRemovedAlertSaga";
import systemSetFlagSaga from "./sagas/systemSetFlagSaga";
import systemStartedConnectApiSaga from "./sagas/systemStartedConnectApiSaga";
import systemStartedPlayingPageTransitionAnimationSaga from "./sagas/systemStartedPlayingPageTransitionAnimationSaga";
import systemUnsetFlagSaga from "./sagas/systemUnsetFlagSaga";
import userFinishedInputtingTextSaga from "./sagas/userFinishedInputtingTextSaga";
import userStartedInputtingTextSaga from "./sagas/userStartedInputtingTextSaga";

export default function* appSaga() {
  yield fork(() => watchSystemLaunchedSaga());
  yield fork(() => watchSystemReadyForUserOperationSaga());
  yield fork(() => watchSystemStartedConnectApiSaga());
  yield fork(() => watchSystemFinishedConnectApiSaga());
  yield fork(() => watchUserStartedInputtingTextSaga());
  yield fork(() => watchUserFinishedInputtingTextSaga());
  yield fork(() => watchSystemStartedPlayingPageTransitionAnimationSaga());
  yield fork(() => watchSystemFinishedPlayingPageTransitionAnimationSaga());
  yield fork(() => watchSystemDetectedResourceUpdateSaga());
  yield fork(() => watchSystemOpenedModalSaga());
  yield fork(() => watchSystemClosedModalSaga());
  yield fork(() => watchSystemAddedAlertSaga());
  yield fork(() => watchSystemRemovedAlertSaga());
  yield fork(() => watchSystemSetFlagSaga());
  yield fork(() => watchSystemUnsetFlagSaga());
  yield fork(() => watchChangeLocationSaga());
}

function* watchSystemSetFlagSaga() {
  yield takeEvery(ActionTypes.S_SET_FLAG, systemSetFlagSaga);
}

function* watchSystemUnsetFlagSaga() {
  yield takeEvery(ActionTypes.S_UNSET_FLAG, systemUnsetFlagSaga);
}

function* watchChangeLocationSaga() {
  yield takeEvery(LOCATION_CHANGE, changeLocationSaga);
}

function* watchSystemAddedAlertSaga() {
  yield takeEvery(ActionTypes.S_ADDED_ALERT, systemAddedAlertSaga);
}

function* watchSystemRemovedAlertSaga() {
  yield takeEvery(ActionTypes.S_REMOVED_ALERT, systemRemovedAlertSaga);
}

function* watchSystemOpenedModalSaga() {
  yield takeEvery(ActionTypes.S_OPENED_MODAL, systemOpenedModalSaga);
}

function* watchSystemClosedModalSaga() {
  yield takeEvery(ActionTypes.S_CLOSED_MODAL, systemClosedModalSaga);
}

function* watchSystemDetectedResourceUpdateSaga() {
  yield takeLatest(
    ActionTypes.S_DETECTED_RESOURCE_UPDATE,
    systemDetectedResourceUpdateSaga
  );
}

function* watchUserStartedInputtingTextSaga() {
  yield takeEvery(
    ActionTypes.U_STARTED_INPUTTING_TEXT,
    userStartedInputtingTextSaga
  );
}

function* watchUserFinishedInputtingTextSaga() {
  yield takeEvery(
    ActionTypes.U_FINISHED_INPUTTING_TEXT,
    userFinishedInputtingTextSaga
  );
}

function* watchSystemStartedPlayingPageTransitionAnimationSaga() {
  yield takeEvery(
    ActionTypes.S_STARTED_PLAYING_PAGE_TRANSITION_ANIMATION,
    systemStartedPlayingPageTransitionAnimationSaga
  );
}

function* watchSystemFinishedPlayingPageTransitionAnimationSaga() {
  yield takeEvery(
    ActionTypes.S_FINISHED_PLAYING_PAGE_TRANSITION_ANIMATION,
    systemFinishedPlayingPageTransitionAnimationSaga
  );
}

function* watchSystemStartedConnectApiSaga() {
  yield takeLatest(
    ActionTypes.S_STARTED_CONNECT_API,
    systemStartedConnectApiSaga
  );
}

function* watchSystemFinishedConnectApiSaga() {
  yield takeLatest(
    ActionTypes.S_FINISHED_CONNECT_API,
    systemFinishedConnectApiSaga
  );
}

function* watchSystemLaunchedSaga() {
  yield take(ActionTypes.S_LAUNCHED);
  yield fork(() => systemLaunchedSaga());
}

function* watchSystemReadyForUserOperationSaga() {
  yield take(ActionTypes.S_READY_FOR_USER_OPERATION);
  yield fork(() => systemReadyForUserOperationSaga());
}
