import { push } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { EFormID } from "../../../forms";
import { ReduxModel } from "../../../reducer";
import {
  IUpdatePasswordRequest,
  IUpdatePasswordResponse
} from "../../../apis/updatePassword";
import {
  IUpdatePasswordForm,
  initialUpdatePasswordForm
} from "../../../forms/updatePassword";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import Utility from "../../../util/Utility";

export default function* updatePasswordSaga() {
  try {
    const formValues: IUpdatePasswordForm = yield select<
      (state: ReduxModel) => IUpdatePasswordForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.UPDATE_PASSWORD,
        initialUpdatePasswordForm
      )
    );
    const params: IUpdatePasswordRequest = {
      password: formValues.password,
      confirmation_password: formValues.confirmation_password
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_PASSWORD,
      params
    );
    const {
      result,
      error
    }: {
      result: IUpdatePasswordResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(destroy(EFormID.UPDATE_PASSWORD));
      yield put(push("/user/info"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
