import { put } from "redux-saga/effects";
import {
  SystemDetectedPositionErrorAction,
  updateCurrentLocationInquiryStatus
} from "../actions/index";

export default function* systemDetectedPositionErrorSaga(
  action: SystemDetectedPositionErrorAction
) {
  yield put(updateCurrentLocationInquiryStatus(action.payload.status));
}
