import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EProcessingFlag } from "../../modules/app/model";
import {
  systemClosedModal,
  removeProcessingFlag
} from "../../modules/app/actions";
import {
  userChangedReservationTime,
  userChangedReservationTimeWithoutSearch
} from "../../modules/search/actions";
import ReservationTime from "../../records/ReservationTime";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import EvilDatetime from "../../util/EvilDatetime";
import ButtonAlter from "../atoms/ButtonAlter";
import Grid from "../atoms/Grid";
import Modal from "../molecules/Modal";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND};
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const Title = styled.div`
  font-size: 1.8rem;
  color: #272727;
  padding-bottom: 65px;
`;

const Top = styled.div`
  padding: 21px 23px;
`;

const Bottom = styled.div`
  padding: 8px;
  border-top: 1px solid #cdcdd2;
`;

const SelectNumberContainer = styled.div`
  position: relative;
  font-size: 1.8rem;
`;

const CurrentDatePrefix = styled.div`
  color: #272727;
  font-size: 1.4rem;
`;

const SelectNumber = styled.select`
  color: inherit;
  background-color: transparent;
  color: #272727;
  cursor: pointer;
  font-size: inherit;
`;

interface IProps {}

const SettingReservationTimeDialog: React.FC<IProps> = React.memo(() => {
  const isOpenDialog = useSelector((state: ReduxModel) =>
    state.app.getModalManager().canDisplay("SETTING_RESERVATION")
  );

  const searchResultType = useSelector((state: ReduxModel) => {
    const modalPayload = state.app
      .getModalManager()
      .getPayload("SETTING_RESERVATION");
    if (typeof modalPayload === "undefined") {
      return undefined;
    }
    return modalPayload.searchResultType;
  });

  const reservationTime = useSelector((state: ReduxModel) => {
    if (typeof searchResultType === "undefined") {
      return undefined;
    }
    return state.search
      .getSearchSettings()
      .getData(searchResultType)
      .getReservationTime();
  });

  const isSearchSettingAtHome = useSelector((state: ReduxModel) => {
    return state.app.isProcessing(EProcessingFlag.SEARCH_SETTING_AT_HOME);
  });

  const dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(systemClosedModal("SETTING_RESERVATION"));
  }, [dispatch]);

  const handleSubmitEdittingReservationTime = useCallback(
    (time: ReservationTime) => {
      if (typeof searchResultType === "undefined") {
        return;
      }
      if (isSearchSettingAtHome) {
        dispatch(
          userChangedReservationTimeWithoutSearch(searchResultType, time)
        );
      } else {
        dispatch(userChangedReservationTime(searchResultType, time));
      }
    },
    [dispatch, isSearchSettingAtHome, searchResultType]
  );

  const [currentReceiveDate, setCurrentReceiveDate] = useState(() => {
    if (typeof reservationTime === "undefined" || reservationTime.isFastest()) {
      return EvilDatetime.getFastestDate();
    }
    return reservationTime.getDateString();
  });

  const [currentReceiveTime, setCurrentReceiveTime] = useState(() => {
    if (typeof reservationTime === "undefined" || reservationTime.isFastest()) {
      return EvilDatetime.getFastestTime();
    }
    return reservationTime.getTimeString();
  });

  const receptionDateRange = useMemo(() => {
    return EvilDatetime.getDateRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDialog]);

  const receptionTimeRange = useMemo(() => {
    const isToday = EvilDatetime.isToday(currentReceiveDate);
    for (const [date, timeRange] of EvilDatetime.getDateAndTimeRange()) {
      if (date.split("/").join("") === currentReceiveDate) {
        return isToday
          ? EvilDatetime.filterTimeRangeOnlyAfter(timeRange)
          : timeRange;
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDialog, currentReceiveDate]);

  const currentDatePrefix = useMemo(() => {
    const today = EvilDatetime.getDateByYYYYMMDDHHmmString(
      currentReceiveDate.replace("/", ""),
      "0000"
    );
    return EvilDatetime.getPresentationalDateWord(today);
  }, [currentReceiveDate]);

  const handleChangeReceiveDate = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const value = event.currentTarget.value;
      setCurrentReceiveDate(value);
    },
    []
  );

  const handleChangeReceiveTime = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const value = event.currentTarget.value;
      setCurrentReceiveTime(value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const datetimeString = `${currentReceiveDate}${currentReceiveTime}`;
    const newReservationTime = ReservationTime.fromDatetimeString(
      datetimeString
    );
    Analytics.logEvent("search_reserve_set_time");
    handleSubmitEdittingReservationTime(newReservationTime);
  }, [
    currentReceiveDate,
    currentReceiveTime,
    handleSubmitEdittingReservationTime
  ]);

  useEffect(() => {
    const isToday = EvilDatetime.isToday(currentReceiveDate);
    if (isToday) {
      const nextReceiveTime = EvilDatetime.filterTimeRangeOnlyAfter(
        EvilDatetime.getTimeRange()
      )[0];
      setCurrentReceiveTime(
        EvilDatetime.getAfterTimeHHmmString(nextReceiveTime, currentReceiveTime)
      );
    }
  }, [currentReceiveDate, currentReceiveTime]);

  /**
   * HOMEから予約検索設定を開いた場合のみフラグを立てて確定時の検索を回避
   */
  useEffect(() => {
    return () => {
      removeProcessingFlag(EProcessingFlag.SEARCH_SETTING_AT_HOME);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpenDialog}
      handleClickBackdrop={closeDialog}
      contentStyle={{ paddingBottom: 0 }}
      align="flex-end"
    >
      <Container>
        <Top>
          <Title>受取予約時間を指定する</Title>

          <Grid container align="flex-end">
            <Grid item rate={1}>
              <CurrentDatePrefix>{currentDatePrefix}</CurrentDatePrefix>
              <SelectNumberContainer>
                <SelectNumber
                  onChange={handleChangeReceiveDate}
                  onBlur={fixBodyScrollTopWhenInputBlurred}
                  value={currentReceiveDate}
                >
                  {receptionDateRange.map(date => {
                    const [year, month, day] = date.split("/");
                    const today = EvilDatetime.getDateByYYYYMMDDHHmmString(
                      date.replace("/", ""),
                      "0000"
                    );
                    const dotw = EvilDatetime.getWeekdayString(today);
                    return (
                      <option key={date} value={`${year}${month}${day}`}>{`${
                        month[0] === "0" ? month[1] : month
                      }月${day}日(${dotw})`}</option>
                    );
                  })}
                </SelectNumber>
                {" ▼"}
              </SelectNumberContainer>
            </Grid>
            <Grid item rate={1}>
              <SelectNumberContainer>
                <SelectNumber
                  onChange={handleChangeReceiveTime}
                  onBlur={fixBodyScrollTopWhenInputBlurred}
                  value={currentReceiveTime}
                >
                  {receptionTimeRange.map(time => {
                    const [hours, minutes] = time.split(":");
                    const value = `${hours}${minutes}`;
                    return (
                      <option key={time} value={value}>
                        {hours[0] === "0" ? hours[1] : hours}時{minutes}分
                      </option>
                    );
                  })}
                </SelectNumber>
                {" ▼"}
              </SelectNumberContainer>
            </Grid>
          </Grid>
        </Top>
        <Bottom>
          <ButtonAlter block appearance="secondary" onClick={handleSubmit}>
            決定
          </ButtonAlter>
        </Bottom>
      </Container>
    </Modal>
  );
});

export default SettingReservationTimeDialog;
