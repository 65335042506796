import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20246 4.88229C4.43771 4.9911 4.69766 5.07646 4.97096 5.1428H0V12V13.1428V20H19V13.1428V12V5.1428H14.0368C14.3055 5.0762 14.561 4.99081 14.7926 4.88229C16.1426 4.26104 16.7307 2.63026 16.0761 1.32951C15.7949 0.77136 15.2938 0.349104 14.6699 0.140403C14 -0.0828587 13.233 -0.0391771 12.5784 0.266594C11.0136 0.989768 9.82216 4.23192 9.69432 4.60078L9.50228 5.1428H9.49791L9.30587 4.60078C9.17292 4.23192 7.98655 0.989768 6.42178 0.266594C5.76212 -0.0391771 5.00019 -0.0828587 4.32519 0.140403C3.70133 0.349104 3.20019 0.77136 2.92405 1.32951C2.26439 2.63026 2.85246 4.25618 4.20246 4.88229ZM5.20474 0.970354C5.02065 0.970354 4.83655 0.999475 4.66269 1.05772C4.40189 1.14508 4.04905 1.33437 3.84451 1.74692C3.38428 2.66423 3.89053 3.6592 4.65246 4.00865C5.61894 4.45518 7.22974 4.40664 8.1144 4.33869C7.60815 3.1059 6.73883 1.48968 5.97689 1.14023C5.73144 1.0286 5.46553 0.970354 5.20474 0.970354ZM17.8125 11.9999V6.28566H1.1875V11.9999H8.90625V6.2857H10.0938V11.9999H17.8125ZM8.90625 13.1429H1.1875V18.8572H8.90625V13.1429ZM10.0938 18.8572V13.1429H17.8125V18.8572H10.0938ZM14.3477 4.01351C13.3813 4.46003 11.7705 4.4115 10.8858 4.34355C11.392 3.1059 12.2614 1.49453 13.0284 1.14508C13.4426 0.955793 13.9182 0.921819 14.3375 1.06257C14.5932 1.14993 14.946 1.33922 15.1557 1.75177C15.6159 2.66908 15.1097 3.66405 14.3477 4.01351Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const CoupponIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default CoupponIcon;
