import moment from "moment";
import { formValueSelector } from "redux-form";
import { ILatLng } from "../modules/search/model";
import { ReduxModel } from "../reducer";
import AppAvailability from "./AppAvailability";
import LocalRepository from "./LocalRepository";
import UserAgent from "./UserAgent";

declare var location: Location;

// 経路表示の探索方法
enum EMapDirFlag {
  WALK = "w",
  RIDE_TRAIN = "r",
  DRIVE = "d"
}

export default class Utility {
  public static getReferrerUrl(): string {
    return LocalRepository.getItem("referrer_url", "");
  }

  public static getReferrerPath(): string {
    return Utility.getReferrerUrl().replace(location.origin, "");
  }

  public static getCurrentUrl(): string {
    return location.toString();
  }

  public static sleep(msec: number = 0): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  /**
   * @deprecated String.prototype.padStartを使う
   */
  public static zeroPadding(num: number, digit: number): string {
    return num.toString().padStart(digit, "0");
  }

  public static getImageDomain(): string {
    const env = process.env.REACT_APP_IMAGE_DOMAIN;
    return typeof env !== "undefined" ? env : "";
  }

  public static getGoogleMapsApiKey() {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return typeof googleMapsApiKey !== "undefined"
      ? googleMapsApiKey
      : undefined;
  }

  public static getGoogleMapsApiUrl() {
    const key = Utility.getGoogleMapsApiKey();
    if (typeof key === "undefined") {
      throw new Error("Google Maps API key is not found");
    }
    return `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=ja`;
  }

  public static getTextWidth(text: string): number {
    const ruler = document.createElement("span");
    ruler.style.visibility = "hidden";
    ruler.style.position = "absolute";
    ruler.style.whiteSpace = "nowrap";
    ruler.textContent = text;
    document.body.appendChild(ruler);
    const width = ruler.offsetWidth;
    document.body.removeChild(ruler);
    return width;
  }

  public static getReduxFormValues<T>(
    state: ReduxModel,
    formId: string,
    initialValues: T
  ): T {
    const selector = formValueSelector(formId);
    return Object.keys(initialValues)
      .map(i => i as keyof T)
      .reduce<T>(
        (acc: T, crr: keyof T) => {
          acc[crr] = selector(state, crr as string);
          return acc;
        },
        {} as T
      );
  }

  /**
   * メーラーを起動する
   */
  public static launchMailer(address: string, subject?: string, body?: string) {
    const params: { [key: string]: string } = {};
    if (subject) {
      params.subject = subject;
    }
    if (body) {
      params.body = body;
    }
    const url = `mailto:${address}${Utility.toQueryStringParameters(params)}`;
    location.href = url;
  }

  public static toQueryStringParameters(obj: { [key: string]: string }) {
    return encodeURI(
      "?" +
        Object.entries(obj)
          .map(([key, value]) => {
            return `${key}=${value}`;
          })
          .join("&")
    );
  }

  /**
   * マップアプリを起動
   */
  public static async launchMapApp(latLng: ILatLng) {
    const googlemaps = `https://maps.google.com/maps?daddr=${latLng.lat},${
      latLng.lng
    }&dirflg=${EMapDirFlag.WALK}`;
    const applemaps = `https://maps.apple.com/maps?daddr=${latLng.lat},${
      latLng.lng
    }&dirflg=${EMapDirFlag.WALK}`;

    if (UserAgent.isIOS()) {
      if (
        AppAvailability.isPluginEnabled() &&
        (await AppAvailability.check("comgooglemaps://"))
      ) {
        location.href = googlemaps;
      } else {
        location.href = applemaps;
      }
    } else {
      location.href = googlemaps;
    }
  }

  public static generateInquiryMainBodyBottom(
    userId: string,
    userMailAddress: string
  ) {
    const ua = UserAgent.getValue();
    const mailAddress = userMailAddress === "" ? "なし" : userMailAddress;
    return `
        ＜ここから下は変更しないでください＞
        ユーザーID：${userId}
        登録メールアドレス：${mailAddress}
        UA：${ua}
    `;
  }

  /**
   * クリップボードに文字列をコピーする
   *
   * @return {boolean} succeeded
   */
  public static execCopyText(text: string) {
    try {
      const selection = document.getSelection();
      if (selection === null) {
        return false;
      }

      const [tmp, pre] = ["div", "pre"].map(t => document.createElement(t));
      tmp.style.cssText = `
      position: fixed;
      top: 100%;
    `;
      pre.style.cssText = `
      -webkit-user-select: auto;
      user-select: auto;
    `;
      tmp.appendChild(pre).textContent = text;
      document.body.appendChild(tmp);
      selection.selectAllChildren(tmp);
      document.execCommand("copy");
      document.body.removeChild(tmp);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * バージョン番号の比較
   */
  public static isSameOrNewerVersion(specified: string, target: string) {
    if (specified === target) {
      return true;
    }
    const [majorA, minorA, patchA] = specified
      .split(".")
      .map(i => parseInt(i, 10));
    const [majorB, minorB, patchB] = target
      .split(".")
      .map(i => parseInt(i, 10));
    if (majorA < majorB) {
      return true;
    } else if (majorA === majorB) {
      if (minorA < minorB) {
        return true;
      } else if (minorA === minorB) {
        return patchA < patchB;
      }
      return false;
    }
  }

  /**
   * リソースのビルド時刻が記載されたjsonのファイルパス
   */
  public static getResourceVersionJsonFilePath() {
    const frontDomain = process.env.REACT_APP_FRONT_DOMAIN;
    if (typeof frontDomain === "undefined" || frontDomain === "") {
      return "";
    }
    return `${frontDomain}/data/buildVersion.json`;
  }

  public static isDebug() {
    const enableDebug = process.env.REACT_APP_DEBUG;
    return typeof enableDebug === "undefined" ? false : enableDebug === "true";
  }

  public static isExpiredDate(date: Date) {
    return moment().isSameOrAfter(date);
  }

  public static getSafeAreaInset(
    direction: "top" | "right" | "bottom" | "left"
  ) {
    let result = 0;
    const [constantSupport, envSupport] = [
      `top: constant(safe-area-inset-${direction})`,
      `top: env(safe-area-inset-${direction})`
    ].map(i => CSS.supports(i));

    const support = constantSupport || envSupport;
    if (!support) {
      return result;
    }

    const key = envSupport ? "env" : "constant";
    const paddingDirection = `padding-${direction}`;

    const element = document.createElement("div");
    element.setAttribute(
      "style",
      `${paddingDirection}: ${key}(safe-area-inset-${direction});`
    );
    element.style.display = "none";
    element.style.position = "absolute";
    document.body.appendChild(element);
    const conputedStyle = window.getComputedStyle(element);
    result = parseInt(conputedStyle.getPropertyValue(paddingDirection), 10);
    document.body.removeChild(element);

    return Number.isNaN(result) ? 0 : result;
  }

  /**
   * SafeAreaのtopのサイズを取得する
   */
  public static getSafeAreaInsetTop() {
    return Utility.initSafeArea("paddingTop");
  }

  public static getSafeAreaInsetBottom() {
    return Utility.initSafeArea("paddingBottom");
  }

  /**
   * テキストのバイト数を取得
   */
  public static getTextBytes(text: string) {
    let length = 0;
    if (typeof text !== "undefined") {
      for (let i = 0; i < text.length; i++) {
        const c = text.charCodeAt(i);
        if (
          (c >= 0x0 && c < 0x81) ||
          c === 0xf8f0 ||
          (c >= 0xff61 && c < 0xffa0) ||
          (c >= 0xf8f1 && c < 0xf8f4)
        ) {
          length += 1;
        } else {
          length += 2;
        }
      }
    }
    return length;
  }

  private static initSafeArea(paddingDirection: string): number {
    let result = 0;
    const isSafeAreaConst: boolean = CSS.supports(
      "padding-left: constant(safe-area-inset-left)"
    );
    const isSafeAreaEnv: boolean = CSS.supports(
      "padding-left: env(safe-area-inset-left)"
    );
    // 取得用のdiv要素を追加
    const element: HTMLDivElement = document.createElement("div");
    if (isSafeAreaConst || isSafeAreaEnv) {
      if (isSafeAreaConst) {
        element.setAttribute(
          "style",
          `${paddingDirection}:constant(safe-area-inset-top);`
        );
      } else {
        element.setAttribute(
          "style",
          `${paddingDirection}:env(safe-area-inset-top);`
        );
      }
      // レイアウトに影響を与えない styleで隠す
      element.style.display = "none";
      element.style.position = "absolute";
      document.body.appendChild(element);
      const conputedStyle = window.getComputedStyle(element);
      if (conputedStyle.getPropertyValue(paddingDirection)) {
        result = parseInt(conputedStyle.getPropertyValue(paddingDirection), 10);
      }
      // 要素を削除
      document.body.removeChild(element);
    }
    return result;
  }
}

/**
 * iosのinputやselectなど、ソフトウェアキーボードが迫り上がる場合の入力のblur時にbodyのscrollTopがずれた状態になるのを修正する関数
 * web only
 */
export function fixBodyScrollTopWhenInputBlurred() {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
}

export function rangeInt(min: number, max: number) {
  return Array(max - min)
    .fill(null)
    .map((_, index) => index + min);
}
