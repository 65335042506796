import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { destroy } from "redux-form";
import { EApiKey } from "../../../apis";
import {
  ISendAreaExpansionDesiredRequest,
  ISendAreaExpansionDesiredResponse
} from "../../../apis/sendAreaExpansionDesired";
import { systemAddedAlert, systemOpenedModal } from "../../app/actions";
import { AlertType } from "../../../records/Alert";
import { EFormID } from "../../../forms";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  IRequesrAreaForm,
  initialRequesrAreaForm
} from "../../../forms/requestArea";
import { ReduxModel } from "../../../reducer";
import Utility from "../../../util/Utility";

export default function* userSubmittedRequestAreaSaga() {
  try {
    const { expansion_area }: IRequesrAreaForm = yield select<
      (state: ReduxModel) => IRequesrAreaForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.REQUEST_AREA,
        initialRequesrAreaForm
      )
    );
    const params: ISendAreaExpansionDesiredRequest = {
      expansion_area
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.SEND_AREA_EXPANSION_DESIRED,
      params
    );
    const {
      result,
      error
    }: {
      result: ISendAreaExpansionDesiredResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(destroy(EFormID.REQUEST_AREA));
      yield put(systemOpenedModal("SEND_REQUEST_COMPLETE", {}));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `リクエストの送信に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
