import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const ShopMenuContainer: React.FC<{
  isCartEdit?: boolean;
  itemId?: number;
}> = React.memo(props => {
  const { isCartEdit, itemId } = props;

  const scrollContainer = useRef<HTMLDivElement>(null);

  const elementScroll = useCallback(() => {
    if (scrollContainer.current !== null && isCartEdit) {
      scrollContainer.current.scrollTo({
        top: scrollContainer.current.clientHeight,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [scrollContainer.current, isCartEdit]);

  useEffect(() => {
    const timer: number = setTimeout(() => elementScroll(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (scrollContainer.current !== null) {
      scrollContainer.current.scrollTo({
        top: 0,
        left: 0
      });
    }
  }, [itemId]);

  return <Container ref={scrollContainer}>{props.children}</Container>;
});

export default ShopMenuContainer;
