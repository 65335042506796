import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const AccountIcon: React.FC<IProps> = ({
  size = 21,
  color = "#000",
  ...rest
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.17 6c0 2.54-2.07 4.6-4.621 4.6-2.552 0-4.621-2.06-4.621-4.6 0-2.54 2.069-4.6 4.62-4.6 2.553 0 4.621 2.06 4.621 4.6zm1.406 0c0 3.314-2.699 6-6.027 6-3.329 0-6.027-2.686-6.027-6s2.698-6 6.027-6c3.328 0 6.027 2.686 6.027 6zm-2.006 7.386a8.613 8.613 0 0 1 4.504 6.214H2.024a8.613 8.613 0 0 1 4.504-6.214 8.436 8.436 0 0 0 4.02 1.014 8.436 8.436 0 0 0 4.022-1.014zM.553 20.002c.368-3.697 2.76-6.802 6.056-8.203A7.02 7.02 0 0 0 10.549 13c1.46 0 2.816-.443 3.94-1.201 3.296 1.4 5.687 4.506 6.055 8.203.055.55-.4.998-.955.998H1.51c-.555 0-1.01-.449-.956-.998z"
        fill={color}
      />
    </svg>
  );
};

export default AccountIcon;
