export default {
  PADDING: {
    FIX_BUTTON_CONTAINER: "20px 37px",
    PAGE: {
      DEFAULT: "48px 37px",
      DEFAULT2: "27px 20px",
      HORIZONTAL: "37px",
      VERTICAL: "48px",
      ORDER: "37px"
    }
  },
  GUTTER: {
    OVER_BUTTON: "60px",
    BETWEEN_INPUT_FIELD: "35px",
    OVER_BUTTON_WITH_ERROR_TEXT: "25px", // OVER_BUTTON - BETWEEN_INPUT_FIELD
    BETWEEN_BUTTON: "20px"
  }
};
