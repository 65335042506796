import { goBack } from "connected-react-router";
import { Link } from "react-router-dom";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import {
  InjectedFormProps,
  reduxForm,
  Fields,
  WrappedFieldsProps
} from "redux-form";
import { EFormID } from "../../forms";
import {
  IRegisterAgreementsForm,
  initialRegisterAgreementsForm,
  validateRegisterAgreementsForm
} from "../../forms/registerAgreements";
import { userTouchedGuestLoginButton } from "../../modules/user/actions";
import { EProcessingFlag } from "../../modules/app/model";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import ButtonAlter from "../atoms/ButtonAlter";
import ErrorText from "../atoms/ErrorText";
import LinkText from "../atoms/LinkText";
import Notes from "../atoms/Notes";
import CheckboxField from "../organisms/FormFields/CheckboxField";
import colorsConst from "../../styles/const/colorsConst";

const containerStyle: React.CSSProperties = {
  backgroundColor: colorsConst.BACKGROUND
};
const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column"
};
const pageBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "72px"
};

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colorsConst.BACKGROUND};
`;

const renderAgreeErrors = (fields: WrappedFieldsProps) => (
  <div>
    {fields.hasAgreedTerms.meta.touched && fields.hasAgreedTerms.meta.error && (
      <div>※{fields.hasAgreedTerms.meta.error}</div>
    )}
    {fields.hasAgreedPrivacyPolicy.meta.touched &&
      fields.hasAgreedPrivacyPolicy.meta.error && (
        <div>※{fields.hasAgreedPrivacyPolicy.meta.error}</div>
      )}
  </div>
);

interface IPropsBase {
  actions: ActionDispatcher;
  submittingGuestLogin: boolean;
}

interface IProps
  extends InjectedFormProps<IRegisterAgreementsForm, IPropsBase>,
    IPropsBase {}

const RegisterAgreementsTemplate = React.memo((props: IProps) => {
  const { actions, submittingGuestLogin } = props;

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!props.valid) {
        props.touch(...Object.keys(initialRegisterAgreementsForm));
        return;
      }
      actions.handleSubmit();
    },
    [actions, props.valid, props.touch]
  );

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions.goBack]);

  return (
    <Page
      header={<SubPageHeader title="はじめに" handleClickBack={handleGoBack} />}
      containerStyle={containerStyle}
      contentStyle={contentStyle}
    >
      <PageBody style={pageBodyStyle}>
        <div style={{ flexGrow: 1 }}>
          <form id={EFormID.REGISTER_AGREEMENTS} onSubmit={handleSubmit}>
            <Notes style={{ marginBottom: "35px" }}>
              本サービスを利用するには、利用規約、プライバシーポリシーに同意する必要がございます。
            </Notes>
            <AgreeContainer>
              <div>
                <CheckboxField
                  id="inputHasAgreedTerms"
                  name="hasAgreedTerms"
                  labelComponent={
                    <Link to="/terms">
                      <LinkText
                        style={{
                          color: colorsConst.SECONDARY,
                          borderBottomColor: colorsConst.SECONDARY
                        }}
                      >
                        利用規約
                      </LinkText>
                    </Link>
                  }
                />
              </div>
              <div style={{ paddingTop: "27px" }}>
                <CheckboxField
                  id="inputHasAgreedPrivacyPolicy"
                  name="hasAgreedPrivacyPolicy"
                  labelComponent={
                    <Link to="/privacyPolicy">
                      <LinkText
                        style={{
                          color: colorsConst.SECONDARY,
                          borderBottomColor: colorsConst.SECONDARY
                        }}
                      >
                        プライバシーポリシー
                      </LinkText>
                    </Link>
                  }
                />
              </div>
            </AgreeContainer>
            <ErrorText>
              <Fields
                names={["hasAgreedTerms", "hasAgreedPrivacyPolicy"]}
                component={renderAgreeErrors}
              />
            </ErrorText>
          </form>
        </div>
        <div style={{ flexGrow: 0 }}>
          <ButtonAlter
            appearance={"secondary"}
            block
            loading={submittingGuestLogin}
            disabled={!props.valid}
            type="submit"
            form={EFormID.REGISTER_AGREEMENTS}
          >
            同意して次へ
          </ButtonAlter>
        </div>
      </PageBody>
    </Page>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  submittingGuestLogin: state.app.isProcessing(
    EProcessingFlag.SUBMIT_GUEST_LOGIN
  )
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}
  public goBack() {
    this.dispatch(goBack());
  }
  public handleSubmit() {
    Analytics.logEvent("top_guestplay");
    this.dispatch(userTouchedGuestLoginButton());
  }
}

const reduxFormConfig = {
  form: EFormID.REGISTER_AGREEMENTS,
  initialValues: initialRegisterAgreementsForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateRegisterAgreementsForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IRegisterAgreementsForm, IPropsBase>(reduxFormConfig)(
    RegisterAgreementsTemplate
  )
);
