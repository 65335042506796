import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgX = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 9.193L14.807 16 16 14.807 9.217 8 16 1.193 14.807 0 8 6.807 1.217 0 0 1.217 6.807 8 0 14.807 1.217 16 8 9.193z"
      fill="#272727"
    />
  </svg>
);

const CrossMarkIcon: React.FC<IProps> = React.memo(props => (
  <SvgX {...props} />
));

export default CrossMarkIcon;
