import { Record } from "immutable";
import Price from "./Price";

// クーポンコードの入力結果
export enum EDiscountCodeResultState {
  SUCCESSED = 1,
  DUPLICATED = 2,
  INVALID = 3,
  EMPTY = 4,
  EXPIRED = 5,
  SUCCESSED_INVITE_CODE = 6,
  ORDERED = 7,
  EXCLUSIVE = 8,
  NEED_AUTH = 9
}

export enum EDiscountCodeType {
  INVITE = 1,
  PROMOTION = 2
}

export interface IDiscountCode {
  user_coupon_id: string;
  coupon_name: string;
  coupon_type: EDiscountCodeType;

  /**
   * 割引額
   * discount_rateが0の時は割引上限額になる
   */
  discount_price: number;

  /**
   * 割引率
   * 0の時は固定額割引となる
   */
  discount_rate: number;
  expire_datetime: string;
}

const initialValues: IDiscountCode = {
  user_coupon_id: "",
  coupon_name: "",
  coupon_type: EDiscountCodeType.PROMOTION,
  discount_price: 0,
  discount_rate: 0,
  expire_datetime: ""
};

export default class DiscountCode extends Record(initialValues) {
  public static isSuccessState(state: EDiscountCodeResultState) {
    switch (state) {
      case EDiscountCodeResultState.SUCCESSED:
      case EDiscountCodeResultState.SUCCESSED_INVITE_CODE:
        return true;
      default:
        return false;
    }
  }

  public getDescription(): string {
    const discountPriceStr = Price.getPresentationValue(
      this.getDiscountPrice()
    );
    if (this.isFixedAmount()) {
      return `割引額　${discountPriceStr}`;
    }
    return `${this.getDiscountRate()}％割引（最大${Price.getPresentationValue(
      this.getDiscountPrice()
    )}まで）`;
  }

  public getDiscountRate() {
    return this.get("discount_rate");
  }

  /**
   * 固定金額の割引か
   */
  public isFixedAmount() {
    return this.getDiscountRate() === 0;
  }

  public getId(): string {
    return this.get("user_coupon_id");
  }

  public getCouponName(): string {
    return this.get("coupon_name");
  }

  public getCouponType(): EDiscountCodeType {
    return this.get("coupon_type");
  }

  public getDiscountPrice(): number {
    return this.get("discount_price");
  }

  public getExpireDateTime(): string {
    return this.get("expire_datetime");
  }

  public getExpireDate(): Date {
    try {
      const date = new Date(this.getExpireDateTime().replace(/-/g, "/"));
      return date;
    } catch (_) {
      return new Date();
    }
  }

  public expired() {
    return this.getExpireDate().getTime() < new Date().getTime();
  }
}
