import { call, cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetFeatureDetailRequest,
  IGetFeatureDetailResponse
} from "../../../apis/getFeatureDetail";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateFeatureDetail,
  UserAccessedToPageThatNeedsFeatureDetailAction
} from "../actions";

export default function* userAccessedToPageThatNeedsFeatureDetailSaga(
  action: UserAccessedToPageThatNeedsFeatureDetailAction
) {
  try {
    const params: IGetFeatureDetailRequest = {
      id: action.payload.id
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_FEATURE_DETAIL, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IGetFeatureDetailResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      yield put(updateFeatureDetail(result.source));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `特集ページの取得に失敗しました。
          通信環境を確認の上、再度お試しください`
      })
    );
  }
}
