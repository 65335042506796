import React from "react";
import ShouldUpdateBuildModal from "./ShouldUpdateBuildModal";
import ShouldUpdateResourceModal from "./ShouldUpdateResourceModal";

interface IProps {
  children?: never;
}

const ShouldUpdateModal: React.FC<IProps> = props => {
  return (
    <>
      <ShouldUpdateResourceModal />
      <ShouldUpdateBuildModal />
    </>
  );
};

export default ShouldUpdateModal;
