import { List } from "immutable";
import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ReduxAction, ReduxModel } from "../../reducer";
import { userAccessedToPageThatNeedsCurrentHomeShopList } from "../../modules/search/actions";
import { EProcessingFlag } from "../../modules/app/model";
import styled from "styled-components";
import ShopForSearch from "../../records/ShopForSearch";
import HomeList, { HomeListType } from "../../records/HomeList";
import Auth from "../organisms/Auth";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import ListSearchResult from "../organisms/ListSearchResult";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import Loading from "../molecules/Loading";

const BlankContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const pageBodyStyle: React.CSSProperties = {
  position: "relative",
  padding: 0,
  height: "100%",
  backgroundColor: colorsConst.BACKGROUND
};

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const HomeShopListTemplate: React.FC<IProps> = React.memo(props => {
  const { homeListType, shopList } = props;

  const fetchingResult = useSelector((state: ReduxModel) =>
    state.app.isProcessing(EProcessingFlag.FETCHING_HOME_SHOP_LIST)
  );

  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleClickShop = useCallback((shopId: number) => {
    Analytics.logEvent("search_keyword_shop", {
      content_type: "shop_id",
      item_id: `${shopId}`
    });
  }, []);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsCurrentHomeShopList(homeListType));
  }, [homeListType]);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title={HomeList.getTitle(homeListType)}
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody style={pageBodyStyle}>
          {fetchingResult ? (
            <BlankContainer>
              <Loading />
            </BlankContainer>
          ) : (
            <ListSearchResult
              shopList={shopList}
              handleClickShop={handleClickShop}
              resultType={"keyword"}
            />
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ type: string }>
) => {
  const homeListType = ownProps.match.params.type as HomeListType;
  return {
    homeListType,
    shopList: state.search.getCurrentHomeList()
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeShopListTemplate)
);
