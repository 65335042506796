import { JustifyContentProperty } from "csstype";
import * as React from "react";
import styled from "styled-components";
import zIndexConst from "../../styles/const/zIndexConst";

const Wrapper = styled("div")<{
  isOpen: boolean;
  align?: JustifyContentProperty;
}>`
  position: fixed;
  z-index: ${zIndexConst.MODAL};
  top: 0;
  left: 0;
  display: ${p => (p.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: stretch;
  justify-content: ${p => (p.align ? p.align : "center")};
  width: 100vw;
  height: 100vh;
  opacity: ${p => (p.isOpen ? "1" : "0")};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  overflow: hidden;
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: ${zIndexConst.MODAL};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  overflow: hidden;
`;

const Content = styled.div`
  max-height: 100%;
  z-index: ${zIndexConst.MODAL + 1};
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const Inner = styled.div`
  height: 100%;
  position: relative;
`;

interface IProps {
  isOpen: boolean;
  handleClickBackdrop?: (event: React.MouseEvent<HTMLElement>) => void;
  backdropStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  align?: JustifyContentProperty;
  [other: string]: any;
}

const Modal: React.FC<IProps> = React.memo(props => {
  const {
    isOpen,
    handleClickBackdrop,
    backdropStyle,
    contentStyle,
    innerStyle,
    children,
    align
  } = props;

  return (
    <Wrapper isOpen={isOpen} align={align}>
      <Backdrop onClick={handleClickBackdrop} style={backdropStyle} />
      <Content style={contentStyle}>
        <Inner style={innerStyle}>{children}</Inner>
      </Content>
    </Wrapper>
  );
});

export default Modal;
