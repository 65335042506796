import { push } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import { EFormID } from "../../../forms";
import { ReduxModel } from "../../../reducer";
import {
  IUpdateMailAddressRequest,
  IUpdateMailAddressResponse
} from "../../../apis/updateMailAddress";
import {
  IUpdateMailAddressForm,
  initialUpdateMailAddressForm
} from "../../../forms/updateMailAddress";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser } from "../actions";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import Utility from "../../../util/Utility";

export default function* updateMailAddressSaga() {
  try {
    const formValues: IUpdateMailAddressForm = yield select<
      (state: ReduxModel) => IUpdateMailAddressForm
    >(state =>
      Utility.getReduxFormValues(
        state,
        EFormID.UPDATE_MAIL_ADDRESS,
        initialUpdateMailAddressForm
      )
    );
    const params: IUpdateMailAddressRequest = {
      mail: formValues.mail
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_MAIL_ADDRESS,
      params
    );
    const {
      result,
      error
    }: {
      result: IUpdateMailAddressResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));
      yield put(destroy(EFormID.UPDATE_MAIL_ADDRESS));
      yield put(push("/user/info"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
