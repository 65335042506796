import styled from "styled-components";

const ShopName = styled.div`
  flex: 1 1 auto;
  font-size: 18px;
  line-height: 2;
  font-weight: bold;
  color: #1f1f1f;
  overflow: hidden;
  letter-spacing: -0.03em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ShopName;
