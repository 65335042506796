import Analytics, { AnalyticsEventSearchSortType } from "../../util/Analytics";
import { SearchResultType } from "../SearchResult";

export type ResultSortType =
  | "distance"
  | "higherPrice"
  | "lowerPrice"
  | "openDate";

export const DISPLAYABLE_RESULT_SORT_TYPE_LIST: ResultSortType[] = [
  "distance",
  "higherPrice",
  "lowerPrice",
  "openDate"
];

export const getResultSortTypeText = (sortType: ResultSortType) => {
  switch (sortType) {
    case "distance":
      return "距離が近い順";
    case "lowerPrice":
      return "価格の安い順";
    case "higherPrice":
      return "価格の高い順";
    case "openDate":
      return "新着順";
    default:
      return "";
  }
};

export const recordAnalyticsChangeResultSortType = (
  type: ResultSortType,
  searchResultType: SearchResultType
) => {
  const prefix = `search_${searchResultType}`;
  switch (type) {
    case "distance":
      Analytics.logEvent(
        `${prefix}_sort_distance` as AnalyticsEventSearchSortType
      );
      break;
    case "lowerPrice":
      Analytics.logEvent(
        `${prefix}_sort_price_low` as AnalyticsEventSearchSortType
      );
      break;
    case "higherPrice":
      Analytics.logEvent(
        `${prefix}_sort_price_high` as AnalyticsEventSearchSortType
      );
      break;
    case "openDate":
      Analytics.logEvent(
        `${prefix}_sort_release_date` as AnalyticsEventSearchSortType
      );
      break;
    default:
      break;
  }
};
