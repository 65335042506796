import * as React from "react";
import { useCallback, useState } from "react";
import { EFormID } from "../../forms";
import spaceConst from "../../styles/const/spaceConst";
import ButtonAlter from "../atoms/ButtonAlter";
import FramelessButton from "../atoms/FramelessButton";
import Notes from "../atoms/Notes";
import AuthCodeFieldAlter from "../organisms/FormFields/AuthCodeFieldAlter";

const containerStyle: React.CSSProperties = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column"
};

const resendButtonContainerStyle: React.CSSProperties = {
  marginTop: "37px",
  textAlign: "center"
};

const primaryNotesStyle: React.CSSProperties = {
  textAlign: "center",
  marginBottom: "60px"
};

const secondaryNotesStyle: React.CSSProperties = {
  textAlign: "center"
};

const authCodeFieldContainerStyle: React.CSSProperties = {
  marginTop: "34px"
};

const submitButtonContainerStyle: React.CSSProperties = {
  marginTop: spaceConst.GUTTER.OVER_BUTTON
};

interface IProps {
  loading: boolean;
  handleSubmit: (authCode: string) => void;
  handleClickResendAuthCodeButton: () => void;
}

const InputAuth = React.memo((props: IProps) => {
  const { loading, handleSubmit, handleClickResendAuthCodeButton } = props;

  const [authCode, setAuthCode] = useState("");

  const handleChangeAuthCode = useCallback((value: string) => {
    const code = value.length > 4 ? value.slice(0, 4) : value;
    setAuthCode(code);
  }, []);

  const canSubmit = useCallback(() => {
    return authCode !== "" && authCode.length === 4;
  }, [authCode]);

  const handleSubmitWrap = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!canSubmit()) {
        return;
      }
      handleSubmit(authCode);
    },
    [canSubmit, handleSubmit, authCode]
  );

  return (
    <div style={containerStyle}>
      <div style={{ flexGrow: 1 }}>
        <Notes style={primaryNotesStyle}>
          ご登録の携帯電話番号に
          <br />
          ショートメールを送信しました。
        </Notes>
        <form
          id={EFormID.AUTH_SMS}
          name="inputAuthCode"
          onSubmit={handleSubmitWrap}
        >
          <div>
            <Notes style={secondaryNotesStyle}>
              ４ケタの認証コードを入力してください。
            </Notes>
            <div style={authCodeFieldContainerStyle}>
              <AuthCodeFieldAlter handleChangeAuthCode={handleChangeAuthCode} />
            </div>
          </div>
        </form>
      </div>
      <div style={{ flexGrow: 0 }}>
        <div style={submitButtonContainerStyle}>
          <ButtonAlter
            appearance={"secondary"}
            block
            loading={loading}
            disabled={!canSubmit()}
            type="submit"
            form={EFormID.AUTH_SMS}
          >
            認証
          </ButtonAlter>
        </div>
        <div style={resendButtonContainerStyle}>
          <FramelessButton onClick={handleClickResendAuthCodeButton}>
            コードを再送信
          </FramelessButton>
        </div>
      </div>
    </div>
  );
});

export default InputAuth;
