import { select } from "redux-saga/effects";
import { ReduxModel } from "../../../reducer";
import Analytics from "../../../util/Analytics";

export default function* setAnalyticsUserIdSaga() {
  const userId: string = yield select<(state: ReduxModel) => string>(state =>
    state.user.getData().getId()
  );
  const appVersion: string | null = yield select<
    (state: ReduxModel) => string | null
  >(state => state.app.getAppVersion());
  if (appVersion && Analytics.isEnableVersion(appVersion) && userId !== "") {
    Analytics.setUserId(userId);
  }
}
