import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetSearchHistoryListRequest,
  IGetSearchHistoryListResponse
} from "../../../apis/getSearchHistoryList";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateSearchHistoryList } from "../actions";

export default function* fetchSearchHistorySaga() {
  const logedIn: boolean = yield select<(state: ReduxModel) => boolean>(state =>
    state.user.getData().exists()
  );
  if (!logedIn) {
    yield cancel();
  }

  const params: IGetSearchHistoryListRequest = {};

  try {
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_SEARCH_HISTORY_LIST, params)
    );
    const {
      result,
      error
    }: { result: IGetSearchHistoryListResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      const historyList = result.history_list;
      yield put(updateSearchHistoryList(historyList));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `検索履歴の取得に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
