import { replace, goBack } from "connected-react-router";
import { destroy } from "redux-form";
import { cancel, join, put, select, spawn, fork } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { ReduxModel } from "../../../reducer";
import OrderModel from "../../order/model";
import { EPaymentType } from "../../../records/PaymentType";
import {
  IUpdateNickNameRequest,
  IUpdateNickNameResponse
} from "../../../apis/updateNickName";
import { EApiKey } from "../../../apis";
import { EFormID } from "../../../forms";
import {
  IRegisterNickNameForm,
  initialRegisterNickNameForm
} from "../../../forms/registerNickName";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateUser } from "../actions";
import Utility from "../../../util/Utility";
import checkCreditCardExistsSaga from "../../order/sagas/checkCreditCardExistsSaga";

// マイページのニックネーム登録／編集
export function* registerNickNameSaga() {
  const formValues: IRegisterNickNameForm = yield select<
    (state: ReduxModel) => IRegisterNickNameForm
  >(state =>
    Utility.getReduxFormValues(
      state,
      EFormID.REGISTER_NICK_NAME,
      initialRegisterNickNameForm
    )
  );
  const fetchingTask = yield fork(() =>
    fetchingRegisterNickNameSaga(formValues.nick_name)
  );
  yield join(fetchingTask);
  if (fetchingTask.isCancelled()) {
    yield cancel();
  }
  yield put(replace("/user/info"));
}

// 注文時のニックネーム登録
export function* registerNickNameAtOrderSaga() {
  const formValues: IRegisterNickNameForm = yield select<
    (state: ReduxModel) => IRegisterNickNameForm
  >(state =>
    Utility.getReduxFormValues(
      state,
      EFormID.REGISTER_NICK_NAME,
      initialRegisterNickNameForm
    )
  );
  yield fork(() => fetchingRegisterNickNameAtOrderSaga(formValues.nick_name));
}

// 注文時のニックネーム登録をスキップ
export function* skipRegisterNickNameSaga() {
  yield fork(() => fetchingRegisterNickNameAtOrderSaga(""));
}

// 注文時のニックネーム登録をフェッチ後クレジットカード情報判定
function* fetchingRegisterNickNameAtOrderSaga(nick_name: string) {
  const fetchingTask = yield fork(() =>
    fetchingRegisterNickNameSaga(nick_name)
  );
  yield join(fetchingTask);
  if (fetchingTask.isCancelled()) {
    yield cancel();
  }

  const order: OrderModel = yield select<(state: ReduxModel) => OrderModel>(
    state => state.order
  );
  // 支払い方法がクレジットカードの場合、クレジットカード情報がなければ登録画面へ
  if (order.getPaymentType() === EPaymentType.CREDIT) {
    const checkCreditCardExistsTask = yield fork(() =>
      checkCreditCardExistsSaga()
    );
    const existsCreditCard = yield join(checkCreditCardExistsTask);
    if (checkCreditCardExistsTask.isCancelled()) {
      yield cancel();
    }
    if (!existsCreditCard) {
      yield put(replace("/user/registerNewCreditCard"));
      yield cancel();
    }
  }
  yield put(goBack());
}

function* fetchingRegisterNickNameSaga(nick_name: string) {
  try {
    const params: IUpdateNickNameRequest = {
      nick_name
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.UPDATE_NICK_NAME,
      params
    );
    const {
      result,
      error
    }: {
      result: IUpdateNickNameResponse;
      error: unknown;
    } = yield join(commonApiTask);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      yield put(updateUser(result.user_data));
      yield put(destroy(EFormID.REGISTER_NICK_NAME));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `ニックネーム登録に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
