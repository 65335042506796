import { ColorProperty } from "csstype";
import * as React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import CheckedIcon from "./CheckedIcon";

const Container = styled.span`
  font-size: 14px;
`;

const Box = styled.span<{ checked?: boolean; fillColor: ColorProperty }>`
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid ${p => (p.checked ? "transparent" : "#DCDDDD")};
  border-radius: 2px;
  ${p => {
    if (p.checked) {
      return `background-color: ${p.fillColor};`;
    }
    return "";
  }}
  transition: background-color 0.1s ease, border 0.1s ease;
`;

const checkedIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate3d(-50%, -50%, 0)"
};

interface IProps extends React.SVGAttributes<SVGElement> {
  fillColor?: ColorProperty;
  checked?: boolean;
}

const CheckBox: React.FC<IProps> = ({
  checked,
  fillColor = colorsConst.MAIN
}) => (
  <Container>
    <Box checked={checked} fillColor={fillColor}>
      <CheckedIcon
        size={10}
        color={checked ? "#ffffff" : "#C9CACA"}
        style={checkedIconStyle}
      />
    </Box>
  </Container>
);

export default CheckBox;
