import { push } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import { systemOpenedModal } from "../../modules/app/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import HeaderContainer from "../atoms/HeaderContainer";
import CoupponIcon from "../atoms/MyPageIcon/CoupponIcon";
import HelpIcon from "../atoms/MyPageIcon/HelpIcon";
import MailIcon from "../atoms/MyPageIcon/MailIcon";
import NewsIcon from "../atoms/MyPageIcon/NewsIcon";
import PaymentIcon from "../atoms/MyPageIcon/PaymentIcon";
import PromotionIcon from "../atoms/MyPageIcon/PromotionIcon";
import SettingIcon from "../atoms/MyPageIcon/SettingIcon";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Auth from "../organisms/Auth";
import Debug from "../organisms/Debug";

const pageBodyStyle: React.CSSProperties = {
  padding: 0
};

const IconStyle: React.CSSProperties = {
  width: "20px",
  paddingRight: "17px"
};

const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 26px;
  height: 100%;
`;

const ListContainer = styled.div`
  padding: 10px 0;
  margin-bottom: 14px;
  background: #ffffff;
`;

const Element = styled.div`
  padding: 20px 26px;
  background: #ffffff;
`;

const LinkElement = styled(Link)`
  display: block;
`;

const SubElement = styled.div`
  margin-bottom: 14px;
  padding: 20px 26px;
  background: #ffffff;
`;

const mapStateToProps = (state: ReduxModel) => ({
  userData: state.user.getData()
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

export class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goToPromote() {
    this.dispatch(push("/register/discountCode"));
  }

  public openZeroResultDialog() {
    this.dispatch(systemOpenedModal("ZERO_RESULT", {}));
  }

  public goToInvite() {
    this.dispatch(push("/user/invitation"));
  }

  public goToSelectCreditCard() {
    this.dispatch(push("/user/changeCreditCard"));
  }

  public goToFAQ() {
    this.dispatch(push("/user/faq"));
  }
}

interface IProps extends ReturnType<typeof mapStateToProps> {
  actions: ActionDispatcher;
  children?: never;
}

const UserTemplate: React.FC<IProps> = React.memo(props => {
  const { userData, actions } = props;

  const handleOpenZeroResultDialog = useCallback(() => {
    actions.openZeroResultDialog();
  }, [actions]);

  const handleClickCreditCard = useCallback(() => {
    Analytics.logEvent("mypage_payment");
    actions.goToSelectCreditCard();
  }, [actions]);

  const handleClickFAQ = useCallback(() => {
    Analytics.logEvent("mypage_help");
    actions.goToFAQ();
  }, [actions]);

  const handleClickPromote = useCallback(() => {
    Analytics.logEvent("mypage_promote");
    actions.goToPromote();
  }, [actions]);

  const handleClickInvitation = useCallback(() => {
    Analytics.logEvent("mypage_invite");
    actions.goToInvite();
  }, [actions]);

  return (
    <Auth>
      <Page
        header={
          <HeaderContainer hasBorder>
            <PageTitle>
              {userData.existsNickName()
                ? `${userData.getNickName()}さん`
                : " "}
            </PageTitle>
          </HeaderContainer>
        }
      >
        <PageBody style={pageBodyStyle}>
          <ListContainer>
            <Element>
              <LinkElement to="/notification">
                <NewsIcon style={IconStyle} />
                ニュース
              </LinkElement>
            </Element>
            <Element>
              <div onClick={handleClickCreditCard}>
                <PaymentIcon style={IconStyle} />
                クレジットカード情報
              </div>
            </Element>
            <Element>
              <div onClick={handleClickFAQ}>
                <HelpIcon style={IconStyle} />
                よくある質問
              </div>
            </Element>
            <Element>
              <div onClick={handleClickPromote}>
                <PromotionIcon style={IconStyle} />
                クーポンコード入力
              </div>
            </Element>
            <Element>
              <div onClick={handleClickInvitation}>
                <CoupponIcon style={IconStyle} />
                友達招待コード
              </div>
            </Element>
            <Element>
              <LinkElement to="/user/info">
                <SettingIcon style={IconStyle} />
                アカウント情報
              </LinkElement>
            </Element>
            <Element>
              <div onClick={handleOpenZeroResultDialog}>
                <MailIcon style={IconStyle} />
                掲載リクエスト
              </div>
            </Element>
          </ListContainer>
          <SubElement>
            <LinkElement to="/user/terms">menuについて</LinkElement>
          </SubElement>
          <Debug>
            <Element>
              <LinkElement to="/user/debug">デバッグ機能</LinkElement>
            </Element>
          </Debug>
        </PageBody>
      </Page>
    </Auth>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTemplate);
