import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  color?: string;
}

const SvgNounPlus586827000000 = (props: IProps) => {
  const { color, ...rest } = props;
  const fillColor = color ? color : "black";
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...rest}>
      <path d="M0 6H12" stroke={fillColor} strokeWidth="2" />
      <path d="M6 0L6 12" stroke={fillColor} strokeWidth="2" />
    </svg>
  );
};

const PlusIcon = (props: IProps) => <SvgNounPlus586827000000 {...props} />;

export default PlusIcon;
