import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import Grid from "../atoms/Grid";
import MinusIcon from "../atoms/MinusIcon";
import PlusIcon from "../atoms/PlusIcon";

const CountButton = styled.button<{ after?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 1px solid ${colorsConst.BORDER.LIGHT};
  background: inherit;
  font-size: 1.2rem;
  border-radius: 100%;
`;

const CountNumber = styled.div`
  width: 4em;
  padding: 10px 0;
  font-size: 1.7rem;
  text-align: center;
  font-weight: bold;
  color: #5b5b5b;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  min?: number;
  max?: number;
  count: number;
  disabled?: boolean;
  handleChangeCount: (count: number) => void;
}

const QuantityCounter = (props: IProps) => {
  const { count, disabled, max, min, handleChangeCount, ...rest } = props;

  const isActiveByAmount = useCallback(
    (amount: number) => {
      if (disabled) {
        return false;
      }
      const after = count + amount;
      if (amount > 0) {
        return max ? max >= after : true;
      } else {
        return min ? min <= after : after > 0;
      }
    },
    [disabled, count, min, max]
  );

  const changeCount = useCallback(
    (amount: number) => {
      if (!isActiveByAmount(amount)) {
        return;
      }
      const after = count + amount;
      handleChangeCount(after);
    },
    [isActiveByAmount, count, handleChangeCount]
  );

  const increment = useCallback(() => {
    changeCount(1);
  }, [changeCount]);

  const decrement = useCallback(() => {
    changeCount(-1);
  }, [changeCount]);

  return (
    <Grid container align="center" justify="center" {...rest}>
      <Grid item>
        <CountButton onClick={decrement} disabled={!isActiveByAmount(-1)}>
          <MinusIcon color={!isActiveByAmount(-1) ? "#e0e0e0" : "black"} />
        </CountButton>
      </Grid>
      <Grid item>
        <CountNumber>{count}</CountNumber>
      </Grid>
      <Grid item>
        <CountButton after onClick={increment} disabled={!isActiveByAmount(1)}>
          <PlusIcon color={!isActiveByAmount(1) ? "#e0e0e0" : "black"} />
        </CountButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(QuantityCounter);
