import { List, Map, Record } from "immutable";
import CartData, { ICartDataProps } from "../../records/CartData";
import OrderData, {
  EFeedbackState,
  EOrderState,
  IOrderData,
  IOrderDataProps
} from "../../records/OrderData";
import ReservationTime from "../../records/ReservationTime";
import { EOptionSelectType, IOptionSet } from "../../records/ShopOptionSet";
import { EPaymentType } from "../../records/PaymentType";

export interface IAddToCartRequestParams {
  shop_id: number;
  shop_item_id: number;
  count: number;
  option_list: number[];
  instruction_text: string;
}

export type DraftFeedbackTextMap = Map<string, string>;
export type DraftFeedbackPointMap = Map<string, number>;

export interface OrderState {
  cartList: List<CartData>;

  /**
   * 注文するカートのid
   */
  orderCartId: string | undefined;

  orderList: List<OrderData>;
  currentOrderDetail: OrderData;

  /**
   * メニュー詳細や、カート編集時の項目
   */
  preOrderInstructionText: string;

  /**
   * メニュー詳細や、カート編集時の項目
   */
  preOrderCount: number;

  /**
   * メニュー詳細や、カート編集時の項目
   */
  preOrderOptionList: List<number>;

  /**
   * Dialog用の注文番号
   */
  orderNumberForDialog: string | null;

  /**
   * 編集中の評価に関する情報
   */
  draftFeedbackText: DraftFeedbackTextMap;
  draftFeedbackPoint: DraftFeedbackPointMap;

  /**
   * ダイアログ用の受取時間
   */
  reservationTimeForDialog: ReservationTime;

  /**
   * ダイアログ用のカートID
   */
  cartIdForDialog: string | undefined;

  /**
   * ダイアログ用の商品順
   */
  itemIndexForDialog: number;

  /**
   * お気に入り商品のID
   */
  favoriteShopItem: List<number>;

  /**
   * 注文確定時の確認ダイアログ用
   */
  preOrderCartId: string;

  /**
   * 支払い方法
   * FIXME:デフォルト値をユーザー毎に設定するかオーダー毎に設定するか検討中
   */
  paymentType: EPaymentType;
}

const initialState: OrderState = {
  cartList: List<CartData>(),
  orderCartId: undefined,
  orderList: List<OrderData>(),
  currentOrderDetail: new OrderData(),
  preOrderInstructionText: "",
  preOrderCount: 1,
  preOrderOptionList: List<number>(),
  orderNumberForDialog: null,
  draftFeedbackText: Map<string, string>(),
  draftFeedbackPoint: Map<string, number>(),
  reservationTimeForDialog: ReservationTime.createFastest(),
  cartIdForDialog: undefined,
  itemIndexForDialog: 0,
  favoriteShopItem: List<number>(),
  preOrderCartId: "",
  paymentType: EPaymentType.CREDIT
};

export default class OrderModel extends Record(initialState) {
  public static isValidReceiveDatetime(datetime: string) {
    // TODO: もう少しなんとかする
    return datetime.length === 12 || datetime === "";
  }

  public static getRequiredOptions(options: IOptionSet[]) {
    return options.filter(
      option => option.option_select_type === EOptionSelectType.REQUIRED
    );
  }

  public static getOptionalOptions(options: IOptionSet[]) {
    return options.filter(
      option => option.option_select_type === EOptionSelectType.OPTIONAL
    );
  }

  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        orderList: List(
          args.orderList &&
            args.orderList.map((order: IOrderData) => new OrderData(order))
        ),
        cartList: List(
          args.cartList &&
            args.cartList.map((i: ICartDataProps) => new CartData(i))
        ),

        currentOrderDetail:
          args.currentOrderDetail && new OrderData(args.currentOrderDetail),
        preCartData: List(
          args.preCartData &&
            args.preCartData.map((cart: ICartDataProps) => new CartData(cart))
        )
      })
    );
  }

  public getOrderCartId() {
    return this.get("orderCartId");
  }

  public setOrderCartId(id: string | undefined) {
    return this.set("orderCartId", id);
  }

  public updateFeedbackStateByOrderNumber(orderNumber: string) {
    const orderList = this.getOrderList();
    const updatedOrderList: List<OrderData> = orderList.map(
      (order: OrderData) => {
        return order.getOrderNumber() === orderNumber
          ? order.set("feedback_state", EFeedbackState.SENT)
          : order;
      }
    );
    return this.set("orderList", updatedOrderList);
  }

  public updateFeedbackPointByOrderNumber(orderNumber: string, point: number) {
    const orderList = this.getOrderList();
    const updatedOrderList: List<OrderData> = orderList.map(
      (order: OrderData) => {
        return order.getOrderNumber() === orderNumber
          ? order.set("feedback_point", point)
          : order;
      }
    );
    return this.set("orderList", updatedOrderList);
  }

  public updateFeedbackTextByOrderNumber(orderNumber: string, text: string) {
    const orderList = this.getOrderList();
    const updatedOrderList: List<OrderData> = orderList.map(
      (order: OrderData) => {
        return order.getOrderNumber() === orderNumber
          ? order.set("feedback_text", text)
          : order;
      }
    );
    return this.set("orderList", updatedOrderList);
  }

  public existsUnreviewedOrder() {
    return this.getOrderList().some(i => i.isUnreviewed());
  }

  public getPreOrderInstructionText() {
    return this.get("preOrderInstructionText");
  }

  public setPreOrderInstructionText(text: string) {
    return this.set("preOrderInstructionText", text);
  }

  public getPreOrderCount() {
    return this.get("preOrderCount");
  }

  public setPreOrderCount(count: number) {
    return this.set("preOrderCount", count);
  }

  public getPreOrderOptionList() {
    return this.get("preOrderOptionList");
  }

  public setPreOrderOptionList(list: number[]) {
    return this.set("preOrderOptionList", List<number>(list));
  }

  public initPreOrder(initial: {
    option_list: number[];
    count: number;
    instruction_text: string;
  }) {
    return this.withMutations(s =>
      s
        .set("preOrderOptionList", List<number>(initial.option_list))
        .set("preOrderCount", initial.count)
        .set("preOrderInstructionText", initial.instruction_text)
    );
  }

  public updateCurrentOrderDetail(detail: IOrderDataProps | null) {
    return this.set("currentOrderDetail", new OrderData(detail));
  }

  public updateCartList(cartList: ICartDataProps[]) {
    return this.set("cartList", List(cartList.map(cart => new CartData(cart))));
  }

  public updateOrderList(orders: IOrderDataProps[]) {
    return this.set(
      "orderList",
      List(orders.map(order => new OrderData(order)))
    );
  }

  public getCartList() {
    return this.get("cartList");
  }

  public getOrderList(): List<OrderData> {
    return this.get("orderList");
  }

  public getDisplayableOrderList(): List<OrderData> {
    return this.getOrderList().filter(
      order => order.getOrderState() !== EOrderState.CANCELED
    );
  }

  public getCurrentOrderDetail(): OrderData {
    return this.get("currentOrderDetail");
  }

  public getOrderNumberForDialog() {
    return this.get("orderNumberForDialog");
  }

  public updateOrderNumberForDialog(orderNumber: string | null) {
    return this.set("orderNumberForDialog", orderNumber);
  }

  public getDraftFeedbackText() {
    return this.get("draftFeedbackText");
  }

  public getDraftFeedbackPoint() {
    return this.get("draftFeedbackPoint");
  }

  public updateDraftFeedbackTextByOrderNumber(
    orderNumber: string,
    text: string
  ) {
    const draftFeedbackText = this.getDraftFeedbackText();
    return this.set(
      "draftFeedbackText",
      draftFeedbackText.set(orderNumber, text)
    );
  }

  public updateDraftFeedbackPointByOrderNumber(
    orderNumber: string,
    point: number
  ) {
    const draftFeedbackPoint = this.getDraftFeedbackPoint();
    return this.set(
      "draftFeedbackPoint",
      draftFeedbackPoint.set(orderNumber, point)
    );
  }

  public getReservationTimeForDialog() {
    return this.get("reservationTimeForDialog");
  }

  public updateReservationTimeForDialog(reservationTime: ReservationTime) {
    return this.set("reservationTimeForDialog", reservationTime);
  }

  public getCartIdForDialog() {
    return this.get("cartIdForDialog");
  }

  public updateCartIdForDialog(cartId: string | undefined) {
    return this.set("cartIdForDialog", cartId);
  }

  public getItemIndexForDialog() {
    return this.get("itemIndexForDialog");
  }

  public updateItemIndexForDialog(index: number) {
    return this.set("itemIndexForDialog", index);
  }

  public updateShopItemFavoriteState(shopItemId: number, bool: boolean) {
    const orderList = this.getCartList();
    const updatedCartList = orderList.map(order => {
      return order.updateShopItemFavoriteState(shopItemId, bool);
    });
    return this.set("cartList", updatedCartList);
  }

  public getPreOrderCartId() {
    return this.get("preOrderCartId");
  }

  public updatePreOrderCartId(cartId: string) {
    return this.set("preOrderCartId", cartId);
  }

  public findCartDataByCartId(cartId: string): CartData | undefined {
    return this.getCartList().find(cart => cart.getId() === cartId);
  }

  public getPaymentType() {
    return this.get("paymentType");
  }

  public updatePaymentType(type: EPaymentType) {
    return this.set("paymentType", type);
  }
}
