export function preventMapHitsAndGesturesFrom<T>(element: T | Element | null) {
  if (
    typeof element !== "undefined" &&
    element !== null &&
    typeof google !== "undefined"
  ) {
    // FIXME: use any type
    (google.maps as any).OverlayView.preventMapHitsAndGesturesFrom(element);
  }
}

export function preventMapHitsFrom<T>(element: T | Element | null) {
  if (
    typeof element !== "undefined" &&
    element !== null &&
    typeof google !== "undefined"
  ) {
    // FIXME: use any type
    (google.maps as any).OverlayView.preventMapHitsFrom(element);
  }
}

export function toLatLngFromLiteral(literal: google.maps.LatLngLiteral) {
  return new google.maps.LatLng(literal.lat, literal.lng);
}

export function distanceBetweenPoints(
  p1: google.maps.LatLng,
  p2: google.maps.LatLng
) {
  const R = 6371; // Radius of the Earth in km
  const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
  const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((p1.lat() * Math.PI) / 180) *
      Math.cos((p2.lat() * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}
