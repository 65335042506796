import { replace } from "connected-react-router";
import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  ICancelOrderRequest,
  ICancelOrderResponse
} from "../../../apis/cancelOrder";
import { AlertType } from "../../../records/Alert";
import { IOrderDataProps } from "../../../records/OrderData";
import { closeModal, openModal, systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateOrderList, UserSubmittedCancelOrderAction } from "../actions";

export default function* userSubmittedCancelOrderSaga(
  action: UserSubmittedCancelOrderAction
) {
  try {
    const params: ICancelOrderRequest = {
      order_no: action.payload.orderNumber
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.CANCEL_ORDER, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: ICancelOrderResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      const orderList: IOrderDataProps[] = result.order_list;
      yield put(updateOrderList(orderList));
      yield put(replace("/history"));
      yield put(closeModal("CANCEL_ORDER"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `注文のキャンセルに失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
