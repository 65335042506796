import { fork, join, put, select } from "redux-saga/effects";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { AlertType } from "../../../records/Alert";
import { SearchSetting } from "../../../records/SearchSetting";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert, systemClosedModal } from "../../app/actions";
import { UserChangedReservationTimeWithoutSearchAction } from "../actions";
import updateSearchSettingSaga from "./updateSearchSettingSaga";
import { ILatLng } from "../model";
import fetchCurrentLocationSaga from "./fetchCurrentLocationSaga";

export default function* userChangedReservationTimeWithoutSearchSaga(
  action: UserChangedReservationTimeWithoutSearchAction
) {
  try {
    const { resultType, time } = action.payload;

    const searchSetting: SearchSetting = yield select<
      (state: ReduxModel) => SearchSetting
    >(state => state.search.getSearchSettings().getData(resultType));

    const currentSearchPlace: SearchPlace = yield select<
      (state: ReduxModel) => SearchPlace
    >(state =>
      state.search
        .getSearchSettings()
        .getData(resultType)
        .getSearchPlace()
    );

    let needsCurrentLocation = false;
    if (
      typeof currentSearchPlace !== "undefined" &&
      currentSearchPlace.isCurrentLocation()
    ) {
      needsCurrentLocation = true;
    } else {
      const currentSearchPlace = yield select<
        (state: ReduxModel) => SearchPlace
      >(state =>
        state.search
          .getSearchSettings()
          .getData(resultType)
          .getSearchPlace()
      );
      if (currentSearchPlace.isCurrentLocation()) {
        needsCurrentLocation = true;
      }
    }

    if (needsCurrentLocation) {
      const fetchCurrentLocationTask = yield fork(() =>
        fetchCurrentLocationSaga()
      );
      yield join(fetchCurrentLocationTask);
    }

    const currentLocation: ILatLng | null = yield select<
      (state: ReduxModel) => ILatLng | null
    >(state => state.search.getCurrentLocation());

    const searchLocation = (typeof currentSearchPlace === "undefined"
      ? searchSetting.getSearchPlace()
      : currentSearchPlace
    ).getSearchLocationWithCurrentLocation(currentLocation);

    if (searchLocation === null) {
      throw new Error("location is null");
    }

    yield fork(() =>
      updateSearchSettingSaga(
        resultType,
        searchLocation,
        searchSetting,
        currentSearchPlace,
        time
      )
    );
    yield put(systemClosedModal("SETTING_RESERVATION"));
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `検索条件の変更に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
