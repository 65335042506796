import { ColorProperty } from "csstype";
import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  invert?: boolean;
  color?: ColorProperty;
  size?: number;
  children?: never;
}

const SvgVector = (props: IProps) => {
  const { invert, size = 18, color, ...rest } = props;
  const fill = invert ? "#fff" : color ? color : "#000";
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...rest}>
      <path
        d="M16.9652 0.669407C16.5425 0.246754 15.885 0.246754 15.4624 0.669407L8.81733 7.31447L2.17227 0.669407C1.74961 0.246754 1.09215 0.246754 0.669499 0.669407C0.246845 1.09206 0.246845 1.74952 0.669499 2.17218L7.31456 8.81723L0.669499 15.4623C0.246845 15.8849 0.246845 16.5424 0.669499 16.9651C0.880826 17.1764 1.13911 17.2703 1.42088 17.2703C1.70265 17.2703 1.96094 17.1764 2.17227 16.9651L8.81733 10.32L15.4624 16.9651C15.6737 17.1764 15.932 17.2703 16.2138 17.2703C16.4955 17.2703 16.7538 17.1764 16.9652 16.9651C17.3878 16.5424 17.3878 15.8849 16.9652 15.4623L10.3201 8.81723L16.9652 2.17218C17.3643 1.74952 17.3643 1.09206 16.9652 0.669407Z"
        fill={fill}
      />
    </svg>
  );
};

const CloseIcon = React.memo((props: IProps) => <SvgVector {...props} />);

export default CloseIcon;
