import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { systemClosedModal } from "../../modules/app/actions";
import { userChangedCartReservationTime } from "../../modules/order/actions";
import CartData from "../../records/CartData";
import ReservationTime from "../../records/ReservationTime";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import EvilDatetime from "../../util/EvilDatetime";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";
import Modal from "../molecules/Modal";
import { fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND};
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

const Title = styled.div`
  font-size: 1.8rem;
  color: #272727;
  padding-bottom: 65px;
`;

const Top = styled.div`
  padding: 21px 23px;
`;

const Bottom = styled.div`
  padding: 8px;
  border-top: 1px solid #cdcdd2;
`;

const SelectNumberContainer = styled.div`
  position: relative;
  font-size: 1.8rem;
`;

const CurrentDatePrefix = styled.div`
  color: #272727;
  font-size: 1.4rem;
`;

const SelectNumber = styled.select`
  color: inherit;
  background-color: transparent;
  color: #272727;
  cursor: pointer;
  font-size: inherit;
`;

const mapStateToProps = (state: ReduxModel) => ({
  cartId: state.order.getCartIdForDialog(),
  cartList: state.order.getCartList(),
  isOpenDialog: state.app
    .getModalManager()
    .canDisplay("SETTING_RESERVATION_FOR_CART"),
  submittingUpdateCartList: state.app.isConnectedApi(EApiKey.UPDATE_CART_LIST)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeDialog() {
    dispatch(systemClosedModal("SETTING_RESERVATION_FOR_CART"));
  },
  handleSubmitEdittingReservationTime(cartId: string, time: ReservationTime) {
    dispatch(userChangedCartReservationTime(cartId, time));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const SettingReservationTimeForCartDialog: React.FC<IProps> = React.memo(
  ({
    cartId,
    cartList,
    isOpenDialog,
    closeDialog,
    handleSubmitEdittingReservationTime,
    submittingUpdateCartList
  }) => {
    const cart = useMemo(() => {
      if (typeof cartId === "undefined") {
        return undefined;
      }
      return CartData.findById(cartList, cartId);
    }, [cartId, cartList]);

    /**
     * @type YYYY/MM/DD[]
     */
    const receptionDateRange = useMemo(() => {
      if (typeof cart === "undefined") {
        return [];
      }
      return cart.getReceivableDateArray();
    }, [cart]);

    const initCurrentReceiveDate = useCallback(() => {
      if (typeof cart === "undefined") {
        return "";
      }
      const cartDateString = cart.getReservationTime().getDateString();
      const dateRange = receptionDateRange.map(
        EvilDatetime.toYYYYMMDDFromSettingDateString
      );
      if (dateRange.length > 0 && dateRange.every(i => i !== cartDateString)) {
        return dateRange[0];
      }
      return cartDateString;
    }, [cart, receptionDateRange]);

    const [currentReceiveDate, setCurrentReceiveDate] = useState(
      initCurrentReceiveDate
    );

    /**
     * @type HH:mm[]
     */
    const receptionTimeRange = useMemo(() => {
      if (typeof cart === "undefined") {
        return [];
      }
      return cart.getReceivableTimeArray(currentReceiveDate);
    }, [cart, currentReceiveDate]);

    const initCurrentReceiveTime = useCallback(() => {
      if (typeof cart === "undefined") {
        return "";
      }
      const cartTimeString = cart.getReservationTime().getTimeString();
      const timeRange = receptionTimeRange.map(
        EvilDatetime.toHHmmFromSettingTimeString
      );
      if (timeRange.length > 0 && timeRange.every(i => i !== cartTimeString)) {
        return timeRange[0];
      }
      return cartTimeString;
    }, [cart, receptionTimeRange]);

    const [currentReceiveTime, setCurrentReceiveTime] = useState(
      initCurrentReceiveTime
    );

    const currentDatePrefix = useMemo(() => {
      const today = EvilDatetime.getDateByYYYYMMDDHHmmString(
        currentReceiveDate,
        "0000"
      );
      return EvilDatetime.getPresentationalDateWord(today);
    }, [currentReceiveDate]);

    const invalidData = useMemo(() => {
      return (
        typeof cart === "undefined" ||
        currentReceiveDate === "" ||
        currentReceiveTime === "" ||
        receptionDateRange.length === 0 ||
        receptionTimeRange.length === 0
      );
    }, [
      cart,
      currentReceiveDate,
      currentReceiveTime,
      receptionDateRange,
      receptionTimeRange
    ]);

    const handleChangeReceiveDate = useCallback(
      (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setCurrentReceiveDate(value);
      },
      []
    );

    const handleChangeReceiveTime = useCallback(
      (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        setCurrentReceiveTime(value);
      },
      []
    );

    const handleSubmit = useCallback(() => {
      const datetimeString = `${currentReceiveDate}${currentReceiveTime}`;
      const newReservationTime = ReservationTime.fromDatetimeString(
        datetimeString
      );
      if (typeof cartId !== "undefined") {
        handleSubmitEdittingReservationTime(cartId, newReservationTime);
      }
    }, [
      cartId,
      currentReceiveDate,
      currentReceiveTime,
      handleSubmitEdittingReservationTime
    ]);

    useLayoutEffect(() => {
      setCurrentReceiveDate(initCurrentReceiveDate);
    }, [initCurrentReceiveDate]);

    useLayoutEffect(() => {
      setCurrentReceiveTime(initCurrentReceiveTime);
    }, [initCurrentReceiveTime]);

    // MEMO: データに不整合がある場合はダイアログを閉じる
    // cartIdがない、該当するcartのデータがない、など
    useEffect(() => {
      if (invalidData) {
        closeDialog();
      }
    }, [closeDialog, invalidData]);

    return (
      <Modal
        isOpen={isOpenDialog}
        handleClickBackdrop={closeDialog}
        contentStyle={{ paddingBottom: 0 }}
        align="flex-end"
      >
        <Container>
          <Top>
            <Title>受取日時を指定する</Title>

            <Grid container align="flex-end">
              <Grid item rate={1}>
                <CurrentDatePrefix>{currentDatePrefix}</CurrentDatePrefix>
                <SelectNumberContainer>
                  <SelectNumber
                    onChange={handleChangeReceiveDate}
                    onBlur={fixBodyScrollTopWhenInputBlurred}
                    value={currentReceiveDate}
                  >
                    {receptionDateRange.map(date => {
                      const [year, month, day] = date.split("/");
                      const today = EvilDatetime.getDateByYYYYMMDDHHmmString(
                        `${year}${month}${day}`,
                        "0000"
                      );
                      const dotw = EvilDatetime.getWeekdayString(today);
                      return (
                        <option key={date} value={`${year}${month}${day}`}>{`${
                          month[0] === "0" ? month[1] : month
                        }月${
                          day[0] === "0" ? day[1] : day
                        }日(${dotw})`}</option>
                      );
                    })}
                  </SelectNumber>
                  {" ▼"}
                </SelectNumberContainer>
              </Grid>
              <Grid item rate={1}>
                <SelectNumberContainer>
                  <SelectNumber
                    onChange={handleChangeReceiveTime}
                    onBlur={fixBodyScrollTopWhenInputBlurred}
                    value={currentReceiveTime}
                  >
                    {receptionTimeRange.map(time => {
                      const [hours, minutes] = time.split(":");
                      const value = `${hours}${minutes}`;
                      return (
                        <option key={time} value={value}>
                          {hours[0] === "0" ? hours[1] : hours}時{minutes}分
                        </option>
                      );
                    })}
                  </SelectNumber>
                  {" ▼"}
                </SelectNumberContainer>
              </Grid>
            </Grid>
          </Top>
          <Bottom>
            <Button
              block
              onClick={handleSubmit}
              loading={submittingUpdateCartList}
            >
              受取予約を確定する
            </Button>
          </Bottom>
        </Container>
      </Modal>
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingReservationTimeForCartDialog);
