import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgGroup101 = (props: IProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 15" fill="none" {...props}>
    <path
      d="M10 0v15M10.5.5h7.26l1.6 2-1.6 2H10.5v-4zM9.5 8.5H2.24l-1.6-2 1.6-2H9.5v4z"
      stroke="#000"
    />
  </svg>
);

const RoutePlaceIcon: React.FC<IProps> = props => <SvgGroup101 {...props} />;

export default RoutePlaceIcon;
