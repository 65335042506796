import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as RVList,
  ListRowRenderer
} from "react-virtualized";
import { Dispatch } from "redux";
import styled from "styled-components";
import { userAccessedToPageThatNeedsOrderHistory } from "../../modules/order/actions";
import OrderData from "../../records/OrderData";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import Page from "../atoms/Page";
import Auth from "../organisms/Auth";
import OrderHistoryItem from "../organisms/OrderHistoryItem";
import colorsConst from "../../styles/const/colorsConst";

const HeaderInnerContainer = styled.div`
  position: relative;
  padding-top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  height: auto;
  height: calc(auto + constant(safe-area-inset-top));
  height: calc(auto + env(safe-area-inset-top));
  background-color: #ffffff;
  border-bottom: 1px solid #c9caca;
`;

const Tabs = styled.ul`
  position: relative;
  display: flex;
`;

const Tab: React.FC<{
  tabType: ETabType;
  current: ETabType;
  handleClick: (type: ETabType) => void;
}> = props => {
  const { tabType, current, handleClick, children } = props;

  const active = useMemo(() => {
    return tabType === current;
  }, [tabType, current]);

  const tabStyle: React.CSSProperties = useMemo(() => {
    return active
      ? {
          color: colorsConst.MAIN,
          fontWeight: "bold"
        }
      : {
          color: "#9D9DA2"
        };
  }, [active]);

  const handleClickTab = useCallback(() => {
    handleClick(tabType);
  }, [handleClick, tabType]);

  return (
    <li
      onClick={handleClickTab}
      style={{
        ...tabStyle,
        width: "50%",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "14px 0",
        textAlign: "center"
      }}
    >
      {children}
    </li>
  );
};

const Indicator = styled.div<{ current: ETabType }>`
  position: absolute;
  left: 0;
  bottom: -1px;
  width: ${window.innerWidth / 2}px;
  height: 3px;
  background-color: ${colorsConst.MAIN};
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(${p => p.current * (window.innerWidth / 2)}px, 0, 0);
  transform-origin: top left;
`;

const ListContainer = styled.div`
  position: relative;
  height: 100%;
`;

const NoArchiveListText = styled.p`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 16px;
  color: #6b6b75;
  text-align: center;
  transform: translateY(-50%);
`;

enum ETabType {
  PAST = 0,
  ONGOING = 1
}

const mapStateToProps = (state: ReduxModel) => ({
  orderList: state.order.getDisplayableOrderList()
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  fetchOrderHistory() {
    dispatch(userAccessedToPageThatNeedsOrderHistory());
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const OrderHistoryTemplate: React.FC<IProps> = React.memo(props => {
  const { orderList, fetchOrderHistory } = props;

  const pastRenderCache = useRef(
    new CellMeasurerCache({
      fixedWidth: true
    })
  );

  const ongoingOrderList = useMemo(() => {
    return orderList.filter(order => order.isOngoing());
  }, [orderList]);

  const pastOrderList = useMemo(() => {
    return orderList.filter(order => !order.isOngoing());
  }, [orderList]);

  const [tabIndex, setTabIndex] = useState(
    ongoingOrderList.isEmpty() ? ETabType.PAST : ETabType.ONGOING
  );

  const handleTouchTabIndex = useCallback((value: ETabType) => {
    setTabIndex(value);
  }, []);

  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index as ETabType);
  }, []);

  const pastRowRenderer = useCallback<ListRowRenderer>(
    ({ key, style, parent, index }) => {
      const order = pastOrderList.get(index, new OrderData());
      return (
        <CellMeasurer
          key={key}
          index={index}
          rowIndex={index}
          cache={pastRenderCache.current}
          columnIndex={0}
          parent={parent}
        >
          <div style={style}>
            <OrderHistoryItem key={order.getOrderNumber()} orderData={order} />
          </div>
        </CellMeasurer>
      );
    },
    [pastOrderList]
  );

  // ゲストユーザー以外は注文履歴を取得するapiを発行する
  useEffect(() => {
    fetchOrderHistory();
  }, [fetchOrderHistory]);

  useEffect(() => {
    if (tabIndex === ETabType.PAST) {
      Analytics.logEvent("hist_past_tab");
    } else if (tabIndex === ETabType.ONGOING) {
      Analytics.logEvent("hist_ongoing_tab");
    }
  }, [tabIndex]);

  return (
    <Auth>
      <Page
        header={
          <HeaderInnerContainer>
            <Tabs>
              <Tab
                tabType={ETabType.PAST}
                handleClick={handleTouchTabIndex}
                current={tabIndex}
              >
                注文履歴
              </Tab>
              <Tab
                tabType={ETabType.ONGOING}
                handleClick={handleTouchTabIndex}
                current={tabIndex}
              >
                現在のご注文
              </Tab>
              <Indicator current={tabIndex} />
            </Tabs>
          </HeaderInnerContainer>
        }
      >
        <div style={{ height: "100%" }}>
          <SwipeableViews
            style={{ height: "100%" }}
            containerStyle={{ height: "100%" }}
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >
            <ListContainer>
              {!pastOrderList.isEmpty() ? (
                <AutoSizer>
                  {({ width, height }) => (
                    <RVList
                      deferredMeasurementCache={pastRenderCache.current}
                      width={width}
                      height={height}
                      rowRenderer={pastRowRenderer}
                      rowCount={pastOrderList.size}
                      rowHeight={pastRenderCache.current.rowHeight}
                    />
                  )}
                </AutoSizer>
              ) : (
                <NoArchiveListText>注文履歴はありません。</NoArchiveListText>
              )}
            </ListContainer>
            <ListContainer>
              {!ongoingOrderList.isEmpty() ? (
                <>
                  {ongoingOrderList.map((orderData, index) => (
                    <OrderHistoryItem
                      key={orderData.getOrderNumber()}
                      orderData={orderData}
                    />
                  ))}
                </>
              ) : (
                <NoArchiveListText>
                  現在のご注文はありません。
                </NoArchiveListText>
              )}
            </ListContainer>
          </SwipeableViews>
        </div>
      </Page>
    </Auth>
  );
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryTemplate);
