import { push } from "connected-react-router";
import { List } from "immutable";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ShopForSearch from "../../records/ShopForSearch";
import colorsConst from "../../styles/const/colorsConst";
import Carousel from "../atoms/Carousel";
import { ShopSliderItemViewer } from "./ShopForSearchViewer";
import { HomeListType } from "../../records/HomeList";
import MoreArrowIcon from "../atoms/MoreArrowIcon";

const Container = styled.div`
  margin-bottom: 8px;
  background-color: ${colorsConst.BACKGROUND};
`;

const Inner = styled.div`
  margin-left: -10px;
  padding: 0 20px 0 30px;
  overflow: hidden;
  .slick-list {
    overflow: visible;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 8px;
  padding: 5px;
  line-height: ${28 / 19};
  border-bottom: 2px solid #ff3008;
`;

const Title = styled.div`
  flex-grow: 1;
  font-size: 19px;
  font-weight: 500;
  color: #595959;
`;

const MoreItem = styled.div`
  flex-grow: 0;
`;

const ItemInner = styled.div`
  width: calc(100vw - 40px);
  padding: 12px;
  border-radius: 3px;
`;

const MoreItemButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50vw - 40px);
  height: 100%;
  color: ${colorsConst.MAIN};
`;

interface IProps {
  type: HomeListType;
  title: string;
  shopList: List<ShopForSearch>;
}

const ShopListItemSlider: React.FC<IProps> = React.memo(props => {
  const { type, title, shopList } = props;

  const dispatch = useDispatch();

  const handleClickMore = useCallback(() => {
    dispatch(push(`/homeShopList/${type}`));
  }, [type, dispatch]);

  return (
    <>
      {shopList.isEmpty() ? null : (
        <Container>
          <Inner>
            <TitleContainer>
              <Title>{title}</Title>
              {shopList.size >= 10 && type === "shop_favorite_list" && (
                <MoreItem onClick={handleClickMore}>すべて見る</MoreItem>
              )}
            </TitleContainer>
            <Carousel
              infinite={false}
              dots={false}
              autoplay={false}
              speed={250}
              arrows={false}
            >
              {shopList.map(shop => {
                return (
                  <div key={shop.getId()}>
                    <ItemInner>
                      <ShopSliderItemViewer item={shop} type={type} />
                    </ItemInner>
                  </div>
                );
              })}
              {shopList.size >= 10 && type === "shop_favorite_list" && (
                <div>
                  <MoreItemButton onClick={handleClickMore}>
                    <MoreArrowIcon size={40} style={{ marginBottom: "15px" }} />
                    もっと見る
                  </MoreItemButton>
                </div>
              )}
            </Carousel>
          </Inner>
        </Container>
      )}
    </>
  );
});

export default ShopListItemSlider;
