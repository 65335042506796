import React from "react";
import Button from "./Button";
import ButtonInvert from "./ButtonInvert";

export type ButtonType = "main" | "secondary" | "sub";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  appearance: ButtonType;
  disabled?: boolean;
  block?: boolean;
  invert?: boolean;
  loading?: boolean;
}

/**
 * Buttonコンポーネントが肥大化しているので、レギュレーション決まり次第こちらに置き換え予定
 */
const ButtonAlter: React.FC<IProps> = React.memo(
  ({ appearance = "main" as const, invert, disabled, loading, ...rest }) => {
    return invert ? (
      <ButtonInvert {...{ appearance, disabled, loading }} {...rest} />
    ) : (
      <Button {...{ appearance, disabled, loading }} {...rest} />
    );
  }
);

export default ButtonAlter;
