import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { systemClosedModal } from "../../modules/app/actions";
import { ReduxModel } from "../../reducer";
import Button from "../atoms/Button";
import Dialog, { DialogBody, DialogHeader } from "../atoms/Dialog";
import Modal from "../molecules/Modal";

const contentStyle: React.CSSProperties = {
  position: "absolute",
  bottom: 0,
  width: "100%"
};

const dialogStyle: React.CSSProperties = {
  borderRadius: "0"
};

const dialogHeaderStyle: React.CSSProperties = {
  fontSize: "24px",
  lineHeight: "30px"
};

const dialogBodyStyle: React.CSSProperties = {
  fontSize: "16px",
  lineHeight: "25px",
  color: "#757575",
  paddingBottom: "18px"
};

const buttonStyle: React.CSSProperties = {
  background: "#000000",
  borderRadius: "2px",
  color: "#ffffff",
  fontSize: "16px",
  lineHeight: "23px"
};

interface IProps {
  period?: boolean;
  openTooltipCardPeriod: boolean;
  openTooltipCardSecurityCord: boolean;
  actions: ActionDispatcher;
}

const RegisterTooltipDialog: React.FC<IProps> = React.memo(props => {
  const {
    period,
    openTooltipCardPeriod,
    openTooltipCardSecurityCord,
    actions
  } = props;

  const userTouchedCloseCardPeriodDialog = useCallback(() => {
    actions.userTouchedCloseCardPeriodDialog();
  }, [actions]);

  const userTouchedCloseCardSecurityCordDialog = useCallback(() => {
    actions.userTouchedCloseCardSecurityCordDialog();
  }, [actions]);

  return period ? (
    <Modal
      isOpen={openTooltipCardPeriod}
      handleClickBackdrop={userTouchedCloseCardPeriodDialog}
      contentStyle={contentStyle}
    >
      <Dialog style={dialogStyle}>
        <DialogHeader style={dialogHeaderStyle}>有効期限</DialogHeader>
        <DialogBody style={dialogBodyStyle}>
          カードオモテ面の
          <br />
          カード番号下に表記されています。
        </DialogBody>
        <Button onClick={userTouchedCloseCardPeriodDialog} style={buttonStyle}>
          OK
        </Button>
      </Dialog>
    </Modal>
  ) : (
    <Modal
      isOpen={openTooltipCardSecurityCord}
      handleClickBackdrop={userTouchedCloseCardSecurityCordDialog}
      contentStyle={contentStyle}
    >
      <Dialog style={dialogStyle}>
        <DialogHeader style={dialogHeaderStyle}>
          セキュリティコード
        </DialogHeader>
        <DialogBody style={dialogBodyStyle}>
          カードウラ面3桁のコード番号です。
        </DialogBody>
        <Button
          onClick={userTouchedCloseCardSecurityCordDialog}
          style={buttonStyle}
        >
          OK
        </Button>
      </Dialog>
    </Modal>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  openTooltipCardPeriod: state.app
    .getModalManager()
    .canDisplay("TOOLTIP_CARD_PERIOD"),
  openTooltipCardSecurityCord: state.app
    .getModalManager()
    .canDisplay("TOOLTIP_CARD_SECURITY_CORD")
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userTouchedCloseCardPeriodDialog() {
    this.dispatch(systemClosedModal("TOOLTIP_CARD_PERIOD"));
  }

  public userTouchedCloseCardSecurityCordDialog() {
    this.dispatch(systemClosedModal("TOOLTIP_CARD_SECURITY_CORD"));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterTooltipDialog);
