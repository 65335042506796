import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetOrderHistoryRequest,
  IGetOrderHistoryResponse
} from "../../../apis/getOrderHistory";
import { IOrderDataProps } from "../../../records/OrderData";
import { removeProcessingFlag, setProcessingFlag } from "../../app/actions";
import { EProcessingFlag } from "../../app/model";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateOrderList } from "../../order/actions";

export default function* userAccessedToPageThatNeedsOrderHistoryListForSearchResultSaga() {
  try {
    yield put(setProcessingFlag(EProcessingFlag.FETCHING_ORDER_HISTORY));
    const params: IGetOrderHistoryRequest = {
      offset: 0
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.GET_ORDER_HISTORY, params)
    );
    const {
      result,
      error
    }: { result: IGetOrderHistoryResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      const orderList: IOrderDataProps[] = result.order_list;
      yield put(updateOrderList(orderList));
    } else {
      throw error;
    }
  } catch (exception) {
    // do nothing
  } finally {
    yield put(removeProcessingFlag(EProcessingFlag.FETCHING_ORDER_HISTORY));
  }
}
