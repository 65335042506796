import { Record } from "immutable";
import moment from "moment";

export interface ISearchQuery {
  text_list: string[];
  datetime: string;
}

const initial: ISearchQuery = {
  text_list: [],
  datetime: ""
};

export default class SearchQuery extends Record(initial) {
  public static fromString(query: string) {
    return new SearchQuery({
      text_list: query.split(/\s+/),
      datetime: `${moment().unix()}`
    });
  }

  public static asDefault() {
    return new SearchQuery();
  }

  public getTextList() {
    return this.get("text_list");
  }

  public getTextListAsString() {
    return this.getTextList().join(" ");
  }

  public getDatetime() {
    return this.get("datetime");
  }
}
