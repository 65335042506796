import { List, Record } from "immutable";
import { IGetHomeResponse } from "../../apis/getHome";
import CategoryTag, { ICategoryTag } from "../../records/CategoryTag";
import HomeList, { displayableHomeList } from "../../records/HomeList";
import Area, { IArea } from "../../records/Area";
import SubArea, { ISubArea } from "../../records/SubArea";
import SearchQuery from "../../records/SearchQuery";
import SearchResult, {
  DEFAULT_SEARCH_RESULT_TYPE,
  SearchResultType
} from "../../records/SearchResult";
import { SearchSetting } from "../../records/SearchSetting";
import SearchSettings from "../../records/SearchSettings";
import ShopData, { IShopData } from "../../records/ShopData";
import ShopForSearch, {
  IShopForSearch,
  IShopForSearchProps
} from "../../records/ShopForSearch";
import ShopItem from "../../records/ShopItem";
import { IShopItemCategoryProps } from "../../records/ShopItemCategory";
import ShopItemCategoryList from "../../records/ShopItemCategoryList";
import { EPositionError } from "../../util/Geolocation";
import {
  FastestSearchSetting,
  ReserveSearchSetting
} from "../../records/SearchSetting";
import OnceSearched from "../../records/OnceSearched";

export enum EShopTabType {
  TOP,
  MENU,
  FEEDBACK,
  MAP,
  LINK
}

export const FLAG_THAT_VIEWED_GEOLOCATION_PERMISSION_DIALOG =
  "flagThatViewedGeolocationPermissionDialog";

export const SUB_AREA_ID_WHEN_GET_AREA_ALL_SHOP = -1;

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface SearchState {
  /**
   * 現在地の座標
   */
  currentLocation: ILatLng | null;

  /**
   * 現在地情報の取得状況
   * @TODO: currentLocationと統合
   */
  currentLocationInquiryStatus: EPositionError | null;

  /**
   * 位置情報の取得中か
   * @TODO: flagManagerの方に移行
   */
  fetchingCurrentLocationFlag: boolean;

  /**
   * 検索結果を管理するフィールド
   */
  searchResult: SearchResult;

  /**
   * 検索設定を管理するフィールド
   */
  searchSettings: SearchSettings;

  /**
   * 現在閲覧しているSearchResultType
   */
  currentSearchResultType: SearchResultType;

  /**
   * 現在閲覧中の店舗情報
   */
  currentShopData: ShopData;

  /**
   * 現在閲覧中の店舗のメニュー一覧
   */
  currentShopItemList: ShopItemCategoryList;

  /**
   * 検索履歴
   */
  searchHistoryList: string[] | null;

  /**
   * カテゴリータグ一覧
   */
  categoryTagList: List<CategoryTag>;

  /**
   * HOMEに表示するカテゴリータグ10件
   */
  homeCategoryTagList: List<CategoryTag>;

  /**
   * 選択中のカテゴリータグ
   */
  currentCategoryTag: CategoryTag;

  /**
   * 選択中のカテゴリータグの店舗リスト
   */
  currentCategoryShopList: List<ShopForSearch>;

  /**
   * 検索文字列
   */
  currentSearchQuery: SearchQuery;

  /**
   * ホームに表示されるリスト
   */
  homeList: HomeList;

  /**
   * 選択中のHomeListTypeの店舗リスト
   */
  currentHomeList: List<ShopForSearch>;

  /**
   * エリアリスト
   */
  areaList: List<Area>;

  /**
   * 選択中のエリア
   */
  currentArea: Area;

  /**
   * 選択中エリアのサブエリアリスト
   */
  currentSubAreaList: List<SubArea>;

  /**
   * 選択中のサブエリア
   */
  currentSubArea: SubArea;

  /**
   * 選択中エリア・サブエリアの店舗リスト
   */
  currentAreaShopList: List<ShopForSearch>;

  /**
   * アプリを起動してから検索を行った
   */
  onceSearchedFlag: OnceSearched;

  /**
   * HOME上で検索した勤務地近くの店舗リスト
   */
  nearOfficeShopList: List<ShopForSearch>;
}

const initialState: SearchState = {
  currentLocation: null,
  fetchingCurrentLocationFlag: false,
  currentLocationInquiryStatus: null,
  searchResult: SearchResult.asDefault(),
  searchSettings: SearchSettings.asDefault(),
  currentSearchResultType: DEFAULT_SEARCH_RESULT_TYPE,
  currentShopData: new ShopData(),
  currentShopItemList: new ShopItemCategoryList(),
  searchHistoryList: null,
  categoryTagList: List<CategoryTag>(),
  homeCategoryTagList: List<CategoryTag>(),
  currentCategoryTag: new CategoryTag(),
  currentCategoryShopList: List<ShopForSearch>(),
  currentSearchQuery: SearchQuery.asDefault(),
  homeList: HomeList.asDefault(),
  currentHomeList: List<ShopForSearch>(),
  areaList: List<Area>(),
  currentArea: new Area(),
  currentSubAreaList: List<SubArea>(),
  currentSubArea: new SubArea(),
  currentAreaShopList: List<ShopForSearch>(),
  onceSearchedFlag: OnceSearched.asDefault(),
  nearOfficeShopList: List<ShopForSearch>()
};

export default class SearchModel extends Record(initialState) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        categoryTagList: List(
          args.categoryTagList &&
            args.categoryTagList.map(
              (item: ICategoryTag) => new CategoryTag(item)
            )
        )
      })
    );
  }

  public getSearchSettings() {
    return this.get("searchSettings");
  }

  public updateSearchSettings(
    searchResultType: SearchResultType,
    searchSetting: SearchSetting
  ) {
    return this.set(
      "searchSettings",
      this.getSearchSettings().updateData(searchResultType, searchSetting)
    );
  }

  public initReserveSearchSetting() {
    return this.set(
      "searchSettings",
      this.getSearchSettings().initReserveData()
    );
  }

  /**
   * クライアント側で演出的にステート内の値を書き換える
   */
  public updateShopFavoriteState(shopId: number, bool: boolean) {
    const currentShopData = this.getCurrentShopData();
    return this.withMutations(s =>
      s
        .set(
          "searchResult",
          this.getSearchResult().updateFavoriteState(shopId, bool)
        )
        .set("homeList", this.getHomeList().updateFavoriteState(shopId, bool))
        // FIXME:KeywordSearch形式を使用するリストをまとめるクラスを作るまで暫定対応
        .set(
          "currentAreaShopList",
          this.getCurrentAreaShopList().map(shop =>
            shop.getId() === shopId ? shop.setFavorite(bool) : shop
          )
        )
        .set(
          "currentHomeList",
          this.getCurrentHomeList().map(shop =>
            shop.getId() === shopId ? shop.setFavorite(bool) : shop
          )
        )
        .set(
          "currentCategoryShopList",
          this.getCurrentCategoryShopList().map(shop =>
            shop.getId() === shopId ? shop.setFavorite(bool) : shop
          )
        )
        .set(
          "nearOfficeShopList",
          this.getNearOfficeShopList().map(shop =>
            shop.getId() === shopId ? shop.setFavorite(bool) : shop
          )
        )
        // 暫定対応ここまで
        .set(
          "currentShopData",
          currentShopData.getId() === shopId
            ? currentShopData.setFavorite(bool)
            : currentShopData
        )
    );
  }

  public getCategoryTagList() {
    return this.get("categoryTagList");
  }

  public setCategoryTagList(list: ICategoryTag[]) {
    return this.set(
      "categoryTagList",
      List(list.map(item => new CategoryTag(item)))
    );
  }

  public getHomeCategoryTagList() {
    return this.get("homeCategoryTagList");
  }

  public setHomeCategoryTagList(list: ICategoryTag[]) {
    return this.set(
      "homeCategoryTagList",
      List(list.map(item => new CategoryTag(item)))
    );
  }

  public updateCurrentCategoryTag(categoryTag: CategoryTag | ICategoryTag) {
    return this.set("currentCategoryTag", new CategoryTag(categoryTag));
  }

  public getCurrentCategoryTag() {
    return this.get("currentCategoryTag");
  }

  public updateCurrentCategoryShopList(list: IShopForSearchProps[]) {
    return this.set(
      "currentCategoryShopList",
      List(list.map(item => ShopForSearch.create(item)))
    );
  }

  public getCurrentCategoryShopList() {
    return this.get("currentCategoryShopList");
  }

  public fetchingCurrentLocation() {
    return this.get("fetchingCurrentLocationFlag");
  }

  public startFetchCurrentLocation() {
    return this.set("fetchingCurrentLocationFlag", true);
  }

  public finishFetchCurrentLocation() {
    return this.set("fetchingCurrentLocationFlag", false);
  }

  public getCurrentLocationInquiryStatus() {
    return this.get("currentLocationInquiryStatus");
  }

  public hasBeenDeniedGeolocationPermission() {
    return (
      this.getCurrentLocationInquiryStatus() ===
      EPositionError.PERMISSION_DENIED
    );
  }

  public setCurrentLocationInquiryStatus(status: EPositionError | null) {
    return this.set("currentLocationInquiryStatus", status);
  }

  public getSearchResult() {
    return this.get("searchResult");
  }

  public updateSearchResultByType(
    type: SearchResultType,
    list: IShopForSearchProps[]
  ) {
    return this.set("searchResult", this.getSearchResult().setList(type, list));
  }

  public updateSearchHistoryList(list: string[] | null) {
    return this.set("searchHistoryList", list);
  }

  public getSearchHistoryList(): string[] | null {
    const list = this.get("searchHistoryList");
    return list !== null ? list.filter(history => history.length !== 0) : list;
  }

  public updateCurrentLocation(latlng: ILatLng) {
    return this.set("currentLocation", latlng);
  }

  public getCurrentLocation(): ILatLng | null {
    return this.get("currentLocation");
  }

  public setCurrentShopData(data: IShopData) {
    return this.set("currentShopData", new ShopData(data));
  }

  public setCurrentShopItemList(list: IShopItemCategoryProps[]) {
    return this.set("currentShopItemList", new ShopItemCategoryList(list));
  }

  public findItemData(shopItemId: number) {
    const itemList = this.getCurrentShopItemList()
      .getList()
      .reduce((acc: ShopItem[], crr) => {
        return acc.concat(crr.getItemList().toArray());
      }, []);
    for (const item of itemList) {
      if (item.getId() === shopItemId) {
        return item;
      }
    }
    return undefined;
  }

  public getCurrentShopData(): ShopData {
    return this.get("currentShopData");
  }

  public getCurrentShopItemList(): ShopItemCategoryList {
    return this.get("currentShopItemList");
  }

  public getCurrentShopRecommendItemList() {
    const itemList = this.getCurrentShopItemList()
      .getList()
      .reduce((acc: List<ShopItem>, crr) => {
        return acc.concat(crr.getItemList().filter(item => item.isRecommend()));
      }, List<ShopItem>());
    return itemList.slice(0, 3);
  }

  public getCurrentSearchQuery() {
    return this.get("currentSearchQuery");
  }

  public setCurrentSearchQuery(query: SearchQuery) {
    return this.set("currentSearchQuery", query);
  }

  public getHomeList() {
    return this.get("homeList");
  }

  public updateHomeList(
    list: Omit<
      IGetHomeResponse,
      "feature_list" | "area_list" | "category_tag_list"
    >
  ) {
    return this.set("homeList", HomeList.create(list));
  }

  public getCurrentHomeList() {
    return this.get("currentHomeList");
  }

  public updateCurrentHomeList(list: IShopForSearchProps[]) {
    return this.set(
      "currentHomeList",
      List(list.map(item => ShopForSearch.create(item)))
    );
  }

  public setAreaList(list: IArea[]) {
    return this.set("areaList", List(list.map(item => new Area(item))));
  }

  public getAreaList() {
    return this.get("areaList");
  }

  public updateCurrentArea(data: Area | IArea) {
    return this.set("currentArea", new Area(data));
  }

  public getCurrentArea() {
    return this.get("currentArea");
  }

  public updateCurrentSubAreaList(list: ISubArea[]) {
    return this.set(
      "currentSubAreaList",
      List(list.map(item => new SubArea(item)))
    );
  }

  public getCurrentSubAreaList() {
    return this.get("currentSubAreaList");
  }

  public updateCurrentSubArea(subArea: SubArea | ISubArea) {
    return this.set("currentSubArea", new SubArea(subArea));
  }

  public getCurrentSubArea() {
    return this.get("currentSubArea");
  }

  public updateCurrentAreaShopList(list: IShopForSearchProps[]) {
    return this.set(
      "currentAreaShopList",
      List(list.map(item => ShopForSearch.create(item)))
    );
  }

  public getCurrentAreaShopList() {
    return this.get("currentAreaShopList");
  }

  public getCurrentSearchResultType() {
    return this.get("currentSearchResultType");
  }

  public updateCurrentSearchResultType(type: SearchResultType) {
    return this.set("currentSearchResultType", type);
  }

  public getCurrentFeedbackList() {
    return this.getCurrentShopData()
      .getDisplayableFeedbackList()
      .slice(0, 3);
  }

  public setCurrentSearchResultType(type: SearchResultType) {
    return this.set("currentSearchResultType", type);
  }

  public setOnceSearchedFlag(type: SearchResultType) {
    return this.set(
      "onceSearchedFlag",
      this.get("onceSearchedFlag").setOnceSearched(type)
    );
  }

  public isOnceSearched(type: SearchResultType) {
    return this.get("onceSearchedFlag").isOnceSearched(type);
  }

  public getNearOfficeShopList() {
    return this.get("nearOfficeShopList");
  }

  public updateNearOfficeShopList(list: IShopForSearchProps[]) {
    return this.set(
      "nearOfficeShopList",
      List(list.map(item => ShopForSearch.create(item)))
    );
  }
}
