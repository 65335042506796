import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgExternalLinkIcon = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 16" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33333 2.66602H0.666667V14.666H13V7.33268H13.6667V15.3327H0V1.99935H8.33333V2.66602ZM15 5.99935H14.3333V1.80402L6.56933 9.56868L6.09733 9.09668L13.862 1.33268H9.66667V0.666016H15V5.99935Z"
        fill="#FF0E1E"
      />
    </svg>
  );
};

export default function ExternalLinkIcon(props: IProps) {
  return <SvgExternalLinkIcon {...props} />;
}
