import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface ILoginForm {
  mail: string;
  password: string;
}

export const initialLoginForm: ILoginForm = {
  mail: "",
  password: ""
};

export function validateLoginForm(values: ILoginForm) {
  const errors: FormErrors<ILoginForm> = {};
  if (!values.mail) {
    errors.mail = "メールアドレスを入力して下さい";
  } else if (!UserModel.validEmail(values.mail)) {
    errors.mail = "メールアドレスの形式が正しくありません";
  }
  if (!values.password) {
    errors.password = "パスワードを入力して下さい";
  }
  return errors;
}
