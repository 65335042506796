import React from "react";

const MenuUserTermsText = () => (
  <>
    menu利用規約
    <br />
    <br />
    <br />
    第1条（目的と適用範囲）
    <br />
    この規約（以下「本規約」といいます）は、menu株式会社（以下「当社」といいます）が提供するmenuに関する全ての製品及びサービス（以下「本サービス」といいます）の利用に関する条件を、本サービスを利用する会員と当社との間で定めるものです。会員は、本規約に従ってmenuを利用しなければならず、menuを利用することにより本規約に同意したものとみなします。
    <br />
    <br />
    第2条（定義）
    <br />
    2.1.
    「menu」とは、出店者が本サービス上にて自己の商品を有料で提供する商品のテイクアウト注文及び事前決済を行うことのできるサービス、その他これに付随するサービスを提供するアプリケーションをいいます（以下、文脈に応じて「menu」又は「本サービス」といいます）。
    <br />
    2.2.
    「会員」とは、本規約により当社と本サービスの利用契約（以下、単に「利用契約」といいます）を締結し、本サービスを利用する利用者をいいます。当社は、会員が出店者の提供するテイクアウト商品を注文するシステム及びそれに付随するサービス（会員が出店者に対して支払うべきテイクアウト商品代金を、出店者を代理して受領することを含みますが、これに限られません）を提供するにとどまり、テイクアウト商品に関する売買契約は、会員と出店者との間で成立するものとし、当該売買契約に関しては、全て会員と出店者間の自己責任とし、当社は一切責任を負わないものとします。
    <br />
    2.3.「出店者」とは、本サービス上に自己の商品の販売を目的として商品を掲載し、会員により注文がなされたときに会員に対して商品をテイクアウトの形式で提供する者をいいます。
    <br />
    <br />
    第3条（規約の掲示及び改定）
    <br />
    3.1.
    当社は、本規約及び個人情報取扱方針（プライバシーポリシー）を会員が容易に確認できるよう、本サービスの画面又は公開されたウェブページ等に掲示します。
    <br />
    3.2.
    当社は、会員の承諾なく、また事前の通知を行うことなく、関連法令に違反しない範囲内において本規約を改定することができるものとし、会員はこれに同意するものとします。
    <br />
    3.3.
    当社が前項により改定した本規約を表示告知した場合、当該改定の適用日以降に会員が本サービスを引き続き利用した場合、会員は、改定後の本規約に同意したものとみなします。
    <br />
    3.4.
    会員は、本規約に同意しない場合には、本サービスにアクセスし、又はこれを使用してはなりません。
    <br />
    <br />
    第4条（会員登録／退会）
    <br />
    4.1.
    本サービスの利用のため会員登録を希望する方（以下「登録申請者」といいます）は､本規約を理解し､これに同意の上､所定の手続きに従って､本サービスの会員登録申請を行うものとします｡
    <br />
    4.2.
    当社は､会員登録の申請を拒絶した場合又は会員の本サービスの利用を禁止した場合でも､拒絶又は禁止したことに関して､当該登録申請者又は会員に対して一切責任を負わないものとし､かつその理由を当該登録申請者又は会員に開示する義務も負わないものとします｡
    <br />
    4.3.
    登録申請者が本規約に同意し､会員登録を行った時点をもって､当該登録申請者と当社の間で､本規約を内容とする本サービスの利用契約が成立するものとします｡
    <br />
    4.4.
    登録申請者は､本サービスの会員登録申請にあたって､以下の各号のいずれの場合にも該当しないことを表明します｡
    <br />
    (1)未成年者で親権者の同意を得ていない場合
    <br />
    (2)過去に会員資格を抹消されている場合
    <br />
    (3)申し込みの際に届け出る内容に､虚偽､不正､二重登録､誤記､又は記入漏れがある場合
    <br />
    (4)満14歳未満の者
    <br />
    (5)反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ、特殊知能暴力集団等又はこれらに準じる者をいいます。以下同じ。）に該当する場合、その経営に反社会的勢力が実質的に関与していると認められる場合、反社会的勢力を利用していると認められる場合、反社会的勢力に対して資金等を提供し若しくは便宜を供与するなどの関与をしていると認められる場合、又は、反社会的勢力と社会的に非難されるべき関係を有している場合
    <br />
    (6)その他､当社が不適当と判断する事由がある場合
    <br />
    4.5.
    当社は､会員が本規約の内容に違反（前項による表明が真実又は正確でないこと､本規約規定の禁止行為を行うことを含みますが､これらに限りません｡以下同じです）していると判断した場合､何らの通知なしに､直ちに当該会員を強制的に退会させることができるものとします。なお､退会処理後に当社が当該会員の復帰を許可した場合も､復帰前のアカウントに紐付く情報は消滅し､当社から補填や復元等はしないものとします。
    <br />
    4.6.
    会員は、所定の退会フォームから当社に退会の申し出をすることにより、menuの登録を抹消することができます。ただし、退会は、退会前に行ったテイクアウト商品の注文・代金決済等には影響を与えないものとします。当社は、二重登録や不正防止の目的に限り会員が退会した後でも氏名と電話番号の情報は保持するものとし、会員はこれに同意するものとします。
    <br />
    <br />
    第5条（本サービスの内容）
    <br />
    5.1.
    本サービスの内容は､本規約又は本サービスにおいて当社が会員に提供する旨を開示した範囲に限られ､当社はその範囲を超える行為を行う義務は一切負いません｡
    <br />
    5.2.
    会員は､本サービスからのプッシュ通知及び本サービスの会員登録時に登録したメールアドレス宛に､メールマガジンその他広告又は宣伝を含むメールを回数・時間等の制限なく反復・継続的に送信されることにつき､あらかじめ承諾するものとします｡また､会員がメールマガジン等を解除する場合は､当社が定める解除手続を行うものとします。
    <br />
    5.3.
    本サービスを利用するにあたり､必要な通信費は全て会員が負担するものとします｡また､当社は本サービスが全ていかなる機器若しくは通信サービスプランからもアクセスできること､又は通信事業者が提供するいかなるサービスにも中断､瑕疵がないことをいずれも保証するものではありません。
    <br />
    5.4.
    当社は､理由の如何を問わず､事前に通知することなく､本サービスの全部又は一部を変更・追加・廃止することができるものとします｡ただし､本サービスの全部を廃止する場合は､会員に対しメール又は掲示により事前にその旨を通知します。当社はこれらの行為によって会員その他第三者に生じるいかなる損害及び障害等に関しても､一切の責任を負わないものとします。
    <br />
    5.5.
    当社は､本規約に基づく会員との間の権利義務の全部又は一部を､会員の同意なく､第三者に譲渡し､又は承継させることができるものとします｡当社はこれらの行為によって生じるいかなる損害及び障害等に関しても､一切の責任を負わないものとします。
    <br />
    5.6.
    会員は出店者からの調理完了通知を受け取った後、または本サービス上での調理完了の旨の画面表示がなされた後に商品を出店者から受け取ることができます。会員は商品の受け取りについて、調理完了から原則2時間以内に受け取るものとし、出店者からの別段の注意書きその他案内がある場合はこれに従うものとします。
    <br />
    <br />
    第6条（出店者に対する代金の支払い）
    <br />
    6.1.
    会員は、本サービス上の注文フォームより所定の情報を選択・入力し、各出店者が提供するテイクアウト商品を注文することができます。会員がmenuを通じて出店者から調理開始の通知を受信した時点で、会員と出店者との間で当該注文を内容とする売買契約が成立するものとします。会員は売買契約成立後の注文のキャンセルができないことに予め同意します。
    <br />
    6.2.
    会員は、会員と出店者との間で成立した売買契約のテイクアウト商品代金を、クレジットカード決済の方法により、出店者からテイクアウト商品代金の代理受領権限を与えられている当社に支払うものとします。
    <br />
    6.3.
    前項のテイクアウト商品代金の支払手段・方法の詳細は、本サービス内にて定めます｡当社は､テイクアウト商品代金の支払手段・方法を変更することができるものとし､その変更の通知が本サービス内で公開された後、最初に会員が本サービスにアクセスした時点で会員は当該変更に同意したものとみなします。会員は､このような支払手段・方法の変更の有無､内容の通知を遅滞なく知るために､定期的に本サービス上にある支払手段・方法の内容を確認することに同意します。
    <br />
    <br />
    <br />
    <br />
    第7条（本サービスに関する著作権その他の権利の帰属等）
    <br />
    7.1.
    本サービスに関する著作権（著作権法第27条及び第28条の権利を含みます。以下、同様です）、商標権その他一切の権利は、当社又は当社のライセンサーに帰属するものとします。会員による本サービスの利用において、本サービスに関する一切の権利、当社又は当社のライセンサーの会社名、ロゴ、製品及びサービス名、商標若しくはサービスマークについて、使用し又は参照する権利を、会員に移転し又は付与するものではありません。また、会員は､当社の許可なく､本サービスにおける全ての情報・コンテンツ（ただし､会員自らが送信したもので、当該会員にその権利が留保されているコンテンツは除きます。）を複製又は利用することはできないものとします｡
    <br />
    7.2 会員は、以下の行為を行ってはならないものとします。
    <br />
    ①本サービスに関する当社及び第三者の著作権、商標権その他一切の権利を侵害する行為
    <br />
    ②本サービスの変更、派生物の作成、頒布、ライセンス、リース、販売、譲渡、ストリーム、放映を行うこと
    <br />
    ③本サービスをデコンパイル、リバースエンジニアリング、又は解体すること
    <br />
    ④本サービスの全部又は一部をリンク、ミラー、又はフレームすること
    <br />
    ⑤本サービスの全部又は一部について、データマイニングを行うこと
    <br />
    ⑥本サービスに不当な負担を課し、若しくはその運用及び/又は機能を害する目的で、プログラム又はスクリプトを発生させ又は導入すること
    <br />
    ⑦本サービス又はその関連システムやネットワークに不当にアクセスし又はこれらの機能を損なわせようと試みること
    <br />
    7.3.
    本サービスは、当社がコントロールしない第三者が提供するサービス（第三者が提供する配達その他のサービスをいいます。以下同様です）及びコンテンツ（広告宣伝を含みます。以下同様です）に関連して、当該サービス及びコンテンツの提供業者に開示又はアクセスされることがあります。会員は、会員による当該第三者サービス及びコンテンツの利用について、別に定める使用条件及びプライバシーポリシーが適用される場合があることを了承することとします。当社は当該第三者が提供するサービス及びコンテンツを保証するものではなく、また当該サービス及びコンテンツの提供業者の商品又はサービスについて何ら責任を負いません。さらに、会員が、Apple
    iOS、Android、Microsoft
    Windows又はBlackberryのモバイルデバイスのために開発された本サービスにアクセスした場合において、Apple、Google、Microsoft
    Corporation又はBlackberry
    Ltd.及び/又はそれらの子会社及び関連会社（以下、これらを総称して「デバイスメーカー」といいます）は、本契約の当事者ではなく、いかなる方法によっても本サービスの提供又はサポートについて責任を負いません。これらのデバイスを使用した会員による本サービスへのアクセスは、デバイスメーカーの定める条件に従うものとします。
    <br />
    7.4.
    当社は、当社の単独の裁量により、会員が制作した本サービスに関連するコメンタリー、フィードバック等を含む、テキスト、音声、及び視覚的コンテンツ及び情報（以下「ユーザーコンテンツ」といいます）を、本サービスを通して当社に送信する等の方法により、利用可能なものとすることを随時会員に許可することができることとします。会員は、送信したユーザーコンテンツについて、当社が、会員への通知又は会員による個別の同意なく、また会員その他の者への支払いを要することなく、あらゆる方法により利用（本サービス上に表示することを含みますが、これに限りません）することに同意するものとします。
    <br />
    7.5.会員はユーザーコンテンツに、以下の内容を含めないものとします。
    <br />
    ① 他人（当社及び出店者を含む）の名誉を毀損又は誹謗する内容
    <br />
    ② 悪口、卑猥·猥褻な内容、暴力的な内容等、公序良俗に反する内容
    <br />
    ③
    他人（当社及び出店者を含む）のプライバシー権、知的財産権その他の権利を侵害する内容
    <br />
    ④ 根拠なく恐怖心や不安感を誘発する内容
    <br />
    ⑤ 善良な風俗その他、社会秩序に反する内容
    <br />
    7.6.会員の送信したユーザーコンテンツが不適当と判断された場合、以下のような措置が取られることがあります。会員は当該措置について、当社に対し、何らの請求も行わないものとします。
    <br />
    ①自動的な削除措置
    <br />
    ②当該ユーザーコンテンツを送信した会員に対し、本サービスの利用の制限又は当該会員のアカウントの停止
    <br />
    <br />
    第8条（ID及びパスワードの管理）
    <br />
    8.1.
    会員は本サービスから付与されたID・パスワードが漏洩しないよう厳格に管理し､第三者にこれを使用させたり開示してはならないものとします｡
    <br />
    8.2.
    本サービスが付与したID・パスワードを用いたアクセスがなされた場合は､当社は､当該アクセスを当該会員によるアクセスとみなして取り扱います｡また､付与されたID・パスワードの管理不十分､使用上の過誤その他一切の理由に基づき､第三者が当該会員のID・パスワードを用いたアクセスを行ったことにより､会員又は第三者に生じた一切の損害の責任は当該会員が負うものとし､当社は一切責任を負わないものとします。
    <br />
    8.3.
    会員に付与されたID・パスワードの使用・管理に起因して当社に損害が発生した場合､当該会員は当社に対して当該損害を賠償する責任を負うものとします｡
    <br />
    8.4.
    当社、子会社及び関連会社は、マーケティング目的その他の目的のために、会員の電話番号のいずれかに対し、電話又はテキストメッセージ（自動電話ダイヤルシステムによる場合を含む）の送信により会員に連絡を取ることができるものとします。
    <br />
    <br />
    第9条（禁止行為）
    <br />
    9.1.会員は以下の行為を行ってはならないものとします｡
    <br />
    (1)本規約に違反する行為
    <br />
    (2)不正な方法により商品を取得しようとする行為
    <br />
    (3)ユーザID及び本サービスアカウントデータを貸与､交換､譲渡､名義変更､売買､質入､担保供与その他の方法により第三者に利用させ､又は処分する行為
    <br />
    (4)本サービスによって提供され取得した商品等を営利目的で転売する行為
    <br />
    (5)当社、出店者、他の会員又は第三者に不利益若しくは損害を与える行為､又はそれを助長する行為
    <br />
    (6)法令､条例等に違反する行為､又はそれを助長する行為
    <br />
    (7)犯罪行為､公序良俗に反する行為､又はそれを助長する行為
    <br />
    (8)本サービスへの不正アクセス､コンピューターウィルス等による運営妨害
    <br />
    (9)他人の名誉､信用､プライバシー､著作権その他の権利を侵害する行為､又はそれを助長する行為
    <br />
    (10)年齢若しくは身分を偽る行為､又は本サービスの利用にあたって虚偽の情報を申告する行為
    <br />
    (11)他人の名前を登録名として選択又は使用し、その人になりすます行為
    <br />
    (12)本人以外の人又は団体の名前を適切な許可を得ずに使用し、その権利を侵害する行為
    <br />
    (13)低俗、猥褻な名前を使用し、他人に不快感を与える行為
    <br />
    (14)一切の加工行為を通じて本サービスを分解、変更、模倣する行為
    <br />
    (15)当社の同意なく、本サービスを営利的に使用する行為
    <br />
    (16)猥褻、暴力的な情報等を表示する行為
    <br />
    (17)当社、出店者、他の会員又は第三者に対する不当な差別、誹謗中傷、脅迫、その他、不安・不快にさせる言動や行為・行動
    <br />
    (18) 当社、出店者、他の会員又はその他の第三者に対する詐欺又は脅迫行為
    <br />
    (19)出店者への営業妨害行為又は迷惑行為
    <br />
    (20)その他､当社が不適切と判断する行為
    <br />
    <br />
    9.2.
    会員が前項に定める禁止行為を行ったと当社が判断した場合､当社は当該会員に通知することなく､直ちに該当箇所の削除や､当該会員に対する本サービスの提供を中止することができるものとします｡また､必要性があると当社が判断した場合には､裁判所､検察庁､警察､地方自治体､その他法律や条例などで認められた権限を持つ機関に､当該会員の許可なく利用情報を開示・提供することができるものとします。
    <br />
    <br />
    第10条（サービスの中断､停止）
    <br />
    本サービスの提供において天災､事故､停電その他非常事態と当社が判断した状況が生じた場合､本サービスの一時停止､中断ができるものとします｡これにより発生した会員の損害について当社は一切の責任を負わないものとします。
    <br />
    <br />
    第11条（個人情報の取扱い）
    <br />
    11.1当社は､当社が定めるプライバシーポリシーに従って､メールアドレス及び電話番号を含む個人情報を適切に管理するものとします｡ただし､裁判所､警察等の公的機関から開示､情報提供を要求された場合及び法令に基づく請求を受けた場合は､会員の承諾を得ずにこれに応じる場合があります。また､当社はサービスの向上の為に個人情報を利用できるものとします。
    <br />
    11.2会員は、当社が、会員の登録情報その他の個人情報を出店者及び7.3条に規定する第三者が提供するサービス及びコンテンツの提供業者に提供することがあることに同意します。
    <br />
    <br />
    第12条（Cookie及びIDFA､AndroidIDの利用について）
    <br />
    当社の運営する各サイトは､Cookieを利用することがあります｡また､iOSアプリは､IDFAを､Androidアプリは､AndroidIDを利用することがあります。当社は､主として下記の目的でCookie等の情報を利用します。
    <br />
    (1)会員が､毎回パスワードを入力しなくても済むようにするため
    <br />
    (2)ウェブサイトやメールなどの内容を会員により満足いただけるよう改良したり､個々の会員に合わせてカスタマイズしたりするため
    <br />
    (3)会員の関心事に合わせてサービスを提供するため　　
    <br />
    (4)会員に同じ広告を何度も表示しないように制御したり､有益と思われる情報を表示するよう調節したりするため
    <br />
    なお､Cookieの利用で入手した情報を統計的に処理した集約情報を公表することがありますが､これには､個々の会員を識別できる情報は含まれません｡また､個人が特定されない範囲での嗜好性データと､IDFA又はAndroidIDを紐付けて第三者に提供する場合があります。嗜好性データには､アクセスしたURL､コンテンツ､参照順などの行動履歴や､性別､職業､年齢などの属性情報が含まれますが､名前､住所､電話番号､メールアドレスなどは含まれません。
    <br />
    <br />
    第13条（責任範囲及び免責）
    <br />
    13.1.理由の如何を問わず､会員が本サービスを利用若しくは利用しなかった（利用できなかった場合を含みます）ことにより発生した逸失利益、逸失データ、精神的、身体的若しくは財産的損害を含む、間接損害、付随損害、特別損害、懲罰的損害又は派生損害について、当社は一切責任を負わないものとします｡
    <br />
    13.2.当社は、本サービスにおいて提供される情報､機能の完全性､正確性､確実性等については､いかなる保証も行わないものとします｡
    <br />
    13.3.本サービスの利用に関連して生じる会員と出店者又は第三者との間の連絡､取引又は紛争について､当社は一切の責任を負わないものとし､会員は､当社に何らかの損害､損失又は費用負担を与えないよう適切な措置を講じなければならないものとします｡会員が､法令又は本規約に違反して本サービスを利用したことで､当社が直接又は間接に何らかの損害､損失又は費用負担（弁護士費用の負担を含みます）を被った場合､当該会員は､当社の請求に従い､ただちにこれを賠償又は補償するものとします。
    <br />
    13.4.本サービス内において､商品の内容､注文・提供等に関してトラブルが発生した場合は､出店者と会員との間で協議､解決するものとし､当社は何ら責任を負いません｡
    <br />
    13.5本サービス内において表示されている出店者の商品に関する情報について、当社は何ら責任を負いません。
    <br />
    13.6
    会員は本サービス内において表示される当社または出店者からの指示、注意文その他案内文を遵守するものとします。会員がこれに従わなかったことによって直接又は間接に何らかの損害､損失又は費用負担が生じた場合であっても当社は何ら責任を負いません。
    <br />
    <br />
    第14条（会員の権利義務の譲渡等の禁止）
    <br />
    会員は､本規約に基づく権利義務の全部又は一部について､第三者に譲渡､貸与､担保提供等を行ってはならないものとします｡またこれらの行為が行われた場合､当社は､当該会員に係る全ての情報・権利を抹消することができるものとします。
    <br />
    <br />
    第15条（管轄裁判所）
    <br />
    本規約は日本法に従って解釈されるものとし､本規約及び本サービスに関する一切の紛争については､東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします｡
    <br />
    <br />
  </>
);

interface IProps {}

const UserTerms = React.memo((props: IProps) => {
  return <MenuUserTermsText />;
});

export default UserTerms;
