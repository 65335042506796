/**
 * URLSearchParamsの代用クラス
 */
export default class UrlSearchParams {
  public static get(key: string) {
    const ins = new UrlSearchParams();
    return ins.get(key);
  }

  public static has(key: string) {
    const ins = new UrlSearchParams();
    return ins.has(key);
  }

  /**
   * @deprecated
   * テスト用の為非推奨
   */
  public static from(search: string) {
    return new UrlSearchParams(search);
  }

  private static memo: Map<string, Map<string, string | boolean>> = new Map();
  private params: Map<string, string | boolean>;

  private constructor(search: string = self.location.search) {
    const memo = UrlSearchParams.memo.get(search);
    if (typeof memo !== "undefined") {
      this.params = memo;
    } else {
      this.params = search
        .substr(1)
        .split("&")
        .reduce((acc, crr) => {
          const [key, value] = crr.includes("=") ? crr.split("=") : [crr, true];
          return acc.set(key, value);
        }, new Map());
      UrlSearchParams.memo.set(search, new Map(this.params));
    }
  }

  /**
   * @deprecated
   * テスト用の為非推奨
   */
  public getTest(key: string) {
    return this.get(key);
  }

  /**
   * @deprecated
   * テスト用の為非推奨
   */
  public hasTest(key: string) {
    return this.has(key);
  }

  private get(key: string): string | boolean | null {
    const value = this.params.get(key);
    if (typeof value === "undefined") {
      return null;
    }
    return typeof value === "string" ? decodeURIComponent(value) : value;
  }

  private has(key: string) {
    return this.params.has(key);
  }
}
