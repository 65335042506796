import { is, List, Record } from "immutable";
import { ILatLng } from "../../modules/search/model";
import Geolocation from "../../util/Geolocation";
import LocalRepository from "../../util/LocalRepository";
import LabeledPlace from "../LabeledPlace";
import PlaceListItem from "../PlaceListItem";

type SearchLabeledPlaceHistory = [string, ILatLng];

export enum ESearchPlaceType {
  CURRENT_LOCATION,
  LABELED_PLACE,
  MAP_LOCATION,
  REGISTRATION_PLACE
}

interface ISearchPlace {
  type: ESearchPlaceType;
  labeledPlace: LabeledPlace | null;
  mapLocation: LabeledPlace | null;
  registrationPlace: PlaceListItem | null;
}

const initial: ISearchPlace = {
  type: ESearchPlaceType.CURRENT_LOCATION,
  labeledPlace: null,
  mapLocation: null,
  registrationPlace: null
};

export const SEARCH_LABELED_PLACE_HISTORY_STORAGE_KEY =
  "searchLabeledPlaceHistory";
const MAX_HISTORY_LENGTH = 5;

export default class SearchPlace extends Record(initial) {
  public static asCurrentLocation() {
    return new SearchPlace({ type: ESearchPlaceType.CURRENT_LOCATION });
  }

  public static fromRegistrationPlace(place: PlaceListItem) {
    return new SearchPlace({
      type: ESearchPlaceType.REGISTRATION_PLACE,
      registrationPlace: place
    });
  }

  public static fromMapLocation(address: string, latlng: ILatLng) {
    return new SearchPlace({
      mapLocation: LabeledPlace.create(address, latlng),
      type: ESearchPlaceType.MAP_LOCATION
    });
  }

  public static asLabeledPlace(address: string, latLng: ILatLng) {
    return new SearchPlace({
      labeledPlace: LabeledPlace.create(address, latLng),
      type: ESearchPlaceType.LABELED_PLACE
    });
  }

  public static getHistoryFromStorage(): List<SearchPlace> {
    const listJson = LocalRepository.getItem(
      SEARCH_LABELED_PLACE_HISTORY_STORAGE_KEY,
      "[]"
    );
    try {
      const list: SearchLabeledPlaceHistory[] = JSON.parse(listJson);
      return List(
        list.map(([address, latLng]) =>
          SearchPlace.asLabeledPlace(address, latLng)
        )
      );
    } catch {
      return List();
    }
  }

  private constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        registrationPlace: new PlaceListItem(
          args.registrationPlace && args.registrationPlace
        )
      })
    );
  }

  public saveHistory() {
    if (!this.isLabeledPlace()) {
      return;
    }
    try {
      const list: SearchLabeledPlaceHistory[] = JSON.parse(
        LocalRepository.getItem(SEARCH_LABELED_PLACE_HISTORY_STORAGE_KEY, "[]")
      );
      const place = this.getLabeledPlace() as LabeledPlace;
      const newHistoryItem: SearchLabeledPlaceHistory = [
        place.getAddress(),
        place.getLocation()
      ];

      const existsHistory = list.some(
        ([address, latLng]) =>
          address === place.getAddress() &&
          Geolocation.equalsLatLng(latLng, place.getLocation())
      );

      if (existsHistory) {
        return;
      }
      list.unshift(newHistoryItem);
      const listJson = JSON.stringify(list.slice(0, MAX_HISTORY_LENGTH));
      LocalRepository.setItem(
        SEARCH_LABELED_PLACE_HISTORY_STORAGE_KEY,
        listJson
      );
    } catch {
      // do nothing
    }
  }

  public getType() {
    return this.get("type");
  }

  public getMapLocation() {
    return this.get("mapLocation");
  }

  public getRegistrationPlace() {
    return this.get("registrationPlace");
  }

  public getLabeledPlace() {
    return this.get("labeledPlace");
  }

  public isSameRegistrationPlace(other: SearchPlace) {
    return is(this.getRegistrationPlace(), other.getRegistrationPlace());
  }

  public isCurrentLocation() {
    return this.getType() === ESearchPlaceType.CURRENT_LOCATION;
  }

  public isLabeledPlace() {
    return this.getType() === ESearchPlaceType.LABELED_PLACE;
  }

  public isMapLocation() {
    return this.getType() === ESearchPlaceType.MAP_LOCATION;
  }

  public isRegistrationPlace() {
    return this.getType() === ESearchPlaceType.REGISTRATION_PLACE;
  }

  public useLatLng() {
    return this.isMapLocation() || this.isCurrentLocation();
  }

  public getSettingText() {
    switch (this.getType()) {
      case ESearchPlaceType.CURRENT_LOCATION:
        return "現在地";
      case ESearchPlaceType.LABELED_PLACE:
        return this.getLabeledPlace()!.getAddress();
      case ESearchPlaceType.MAP_LOCATION:
        return this.getMapLocation()!.getAddress();
      case ESearchPlaceType.REGISTRATION_PLACE:
        return this.getRegistrationPlace()!.getLabelText();
      default:
        return "";
    }
  }

  public getSearchLocationWithCurrentLocation(
    currentLocation: ILatLng | null
  ): ILatLng | null {
    switch (this.getType()) {
      case ESearchPlaceType.CURRENT_LOCATION:
        return currentLocation;
      case ESearchPlaceType.LABELED_PLACE:
        return this.getLabeledPlace()!.getLocation();
      case ESearchPlaceType.MAP_LOCATION:
        return this.getMapLocation()!.getLocation();
      case ESearchPlaceType.REGISTRATION_PLACE:
        return this.getRegistrationPlace()!.getLocation();
      default:
        return null;
    }
  }
}
