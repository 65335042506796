import React from "react";
import { Route, Switch } from "react-router";
import CartModal from "./CartModal";
import CommonAlertContainer from "./CommonAlertContainer";
import HomeModal from "./HomeModal";
import MypageModal from "./MypageModal";
import OrderDetailModal from "./OrderDetailModal";
import OrderHistoryModal from "./OrderHistoryModal";
import RegisterTooltipModal from "./RegisterTooltipModal";
import ShopDetailModal from "./ShopDetailModal";
import ShopModal from "./ShopModal";
import RequestRegisterEmailDialog from "./RequestRegisterEmailDialog";
import SendRequestCompleteDialog from "./SendRequestCompleteDialog";

const GlobalModal = React.memo(props => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/setting/search/:searchResultType"
          component={HomeModal}
        />
        <Route path="/search" component={HomeModal} />
        <Route exact path="/" component={HomeModal} />

        <Route exact path="/history" component={OrderHistoryModal} />
        <Route
          exact
          path="/orderReceipt/:orderNumber"
          component={RequestRegisterEmailDialog}
        />
        <Route
          exact
          path="/history/:orderNumber"
          component={OrderDetailModal}
        />
        <Route exact path="/shop/:id" component={ShopModal} />
        <Route exact path="/shop/:id/detail" component={ShopDetailModal} />
        <Route exact path="/shop/:id/review" component={ShopDetailModal} />
        <Route exact path="/shop/:id/image" component={ShopDetailModal} />

        <Route exact path="/user" component={MypageModal} />

        <Route exact path="/cart" component={CartModal} />
        <Route
          exact
          path="/user/registerNewCreditCard"
          component={RegisterTooltipModal}
        />

        <Route path="/request" component={SendRequestCompleteDialog} />
      </Switch>
      <CommonAlertContainer />
    </>
  );
});

export default GlobalModal;
