import { cancel, join, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IGetUserDataRequest,
  IGetUserDataResponse
} from "../../../apis/getUserData";
import commonApiSaga from "../../app/sagas/commonApiSaga";

export default function* getUserDataSaga() {
  try {
    const params: IGetUserDataRequest = {};
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.GET_USER_DATA,
      params
    );
    const {
      result,
      error
    }: { result: IGetUserDataResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      return result.user_data;
    } else {
      throw error;
    }
  } catch (exception) {
    return null;
  }
}
