import { fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IArrivalForLuxuryRequest,
  IArrivalForLuxuryResponse
} from "../../../apis/arrivalForLuxury";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import {
  updateCurrentOrderDetail,
  UserTouchedNotifyArrivalForLuxuryShopAction
} from "../actions";

export default function* userTouchedNotifyArrivalForLuxuryShopSaga(
  action: UserTouchedNotifyArrivalForLuxuryShopAction
) {
  const { orderNumber } = action.payload;

  const params: IArrivalForLuxuryRequest = {
    order_no: orderNumber
  };

  try {
    const arrivalForLuxuryApiTask = yield fork(() =>
      commonApiSaga(EApiKey.ARRIVAL_FOR_LUXURY, params)
    );
    const {
      result,
      error
    }: { result: IArrivalForLuxuryResponse; error: unknown } = yield join(
      arrivalForLuxuryApiTask
    );
    if (result && !error) {
      yield put(updateCurrentOrderDetail(result.order));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `通知の送信に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
