import * as React from "react";
import { ColorProperty } from "csstype";
import colorsConst from "../../styles/const/colorsConst";

interface IProps extends React.SVGAttributes<SVGElement> {
  size?: number;
  color?: ColorProperty;
  children?: never;
}

const ChevronIconInCircle: React.FC<IProps> = ({
  size = 21,
  color = colorsConst.MAIN,
  ...rest
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3135 10.6572L9.36373 15.607L8.26667 14.5099L12.1194 10.6572L8.26667 6.80453L9.36373 5.70748L14.3135 10.6572Z"
        fill={color}
      />
      <circle cx="10.5" cy="10.5" r="10" stroke={color} />
    </svg>
  );
};

export default ChevronIconInCircle;
