import React from "react";
import styled from "styled-components";
import ReloadIcon from "../../atoms/ReloadIcon";

const reloadIconStyle: React.CSSProperties = {
  marginRight: "10px"
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  background-color: #80b927;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  cursor: pointer;
`;

interface IProps {
  handleClick?: () => void;
}

const MapSearchAgainButton: React.FC<IProps> = React.memo(({ handleClick }) => {
  return (
    <Container onClick={handleClick}>
      <ReloadIcon fill={"#ffffff"} style={reloadIconStyle} />
      このエリアで再検索
    </Container>
  );
});

export default MapSearchAgainButton;
