import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23555 17.8441C8.23555 19.0345 7.2699 19.9996 6.07871 19.9996C4.88752 19.9996 3.92188 19.0345 3.92188 17.8441C3.92188 16.6536 4.88752 15.6885 6.07871 15.6885C7.2699 15.6885 8.23555 16.6536 8.23555 17.8441ZM7.15713 17.8441C7.15713 18.4393 6.67431 18.9218 6.07871 18.9218C5.48312 18.9218 5.00029 18.4393 5.00029 17.8441C5.00029 17.2488 5.48312 16.7663 6.07871 16.7663C6.67431 16.7663 7.15713 17.2488 7.15713 17.8441Z"
        fill="#3A3A47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.05386L0.147577 4.99032C0.550336 2.08774 3.11545 0 6.04708 0C9.18201 0 12.0721 2.44335 11.9986 5.80422C11.9652 7.33206 11.1036 8.53729 10.5303 9.19896C10.2149 9.56308 9.90587 9.85443 9.67636 10.055C9.56031 10.1565 9.46122 10.2376 9.38731 10.2963L9.37636 10.305L8.59159 10.895L8.5735 10.9075C8.15322 11.1975 7.97397 11.3999 7.90722 11.4893V14.01L4.02946 14.0117V11.3613C4.02946 10.8867 4.12555 10.2529 4.53689 9.55257C4.92007 8.90018 5.51588 8.29358 6.34549 7.71597L6.99539 7.22711C7.02704 7.20178 7.07431 7.16293 7.13237 7.11218C7.26402 6.99711 7.43729 6.83296 7.6064 6.63775C8.00227 6.1808 8.11888 5.85201 8.1218 5.71847C8.14066 4.85635 7.33087 3.89922 6.04708 3.89922C4.90885 3.89922 4.1045 4.6906 3.98814 5.52917L3.83944 6.60078L0 6.05386ZM7.65588 8.07924L6.97772 8.58936C6.22744 9.10927 5.75215 9.61255 5.46693 10.0982C5.16945 10.6047 5.10788 11.0426 5.10788 11.3613V12.9335L6.8288 12.9327V11.3613C6.8288 11.2994 6.87306 11.1103 6.94581 10.9898C7.0688 10.7804 7.34331 10.4467 7.96079 10.0206L8.71683 9.4521C8.77888 9.40287 8.86467 9.33267 8.96641 9.24374C9.16874 9.06689 9.44013 8.81081 9.71505 8.49348C10.2387 7.88902 10.8954 6.92531 10.9205 5.78066C10.9789 3.1104 8.66725 1.07779 6.04708 1.07779C3.99842 1.07779 2.20015 2.305 1.49225 4.08907C1.36465 4.41064 1.27248 4.75031 1.22065 5.10414C1.21899 5.11554 1.21736 5.12694 1.21577 5.13836L2.91994 5.38112C2.92087 5.37439 2.92183 5.36768 2.92281 5.36096C3.12685 3.96796 4.40986 2.82143 6.04708 2.82143C7.86037 2.82143 9.23362 4.20292 9.19996 5.74203C9.18866 6.25873 8.86717 6.82902 8.42169 7.34324C8.21204 7.58523 8.00113 7.78465 7.84231 7.92348C7.76347 7.99238 7.69897 8.04505 7.65588 8.07924Z"
        fill="#3A3A47"
      />
    </svg>
  );
};

const HelpIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default HelpIcon;
