import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import CartData from "../../records/CartData";
import CreditCardData from "../../records/CreditCardData";
import Price from "../../records/Price";
import ReservationTime from "../../records/ReservationTime";
import colorsConst from "../../styles/const/colorsConst";
import Analytics from "../../util/Analytics";
import Utility from "../../util/Utility";
import ArrowSmallIcon from "../atoms/ArrowSmallIcon";
import CommonNotice from "../atoms/CommonNotice";
import Grid from "../atoms/Grid";
import RatingStar from "../atoms/RatingStar";
import TimeIcon from "../atoms/TimeIcon";
import Loading from "../molecules/Loading";
import OrderButton from "../molecules/OrderButton";
import CartItem from "./CartItem";
import CartReceiveTime from "./CartReceiveTime";
import CreditCardInfo from "./CreditCardInfo";
import MapViewer from "./MapViewer";
import PromotionCodeInfo from "./PromotionCodeInfo";
import ShopMarker from "./ShopMarker";

const Container = styled.div``;

const CartHeader = styled.div`
  text-align: center;
`;

const CartShopDescription = styled.div`
  padding-bottom: 7px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const ShopLinkContainer = styled.div`
  padding: 22px 34px 15px 34px;
  position: relative;
`;

const ArrowSmallIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "15px"
};

const CartShopName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: normal;
  font-size: 26px;
  line-height: 34px;
  padding-bottom: 12px;
`;

const MapContainer = styled.div`
  position: relative;
  height: calc(100vw * ${240 / 375});
`;

const MapLoadingContainer = styled(MapContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddressContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  position: absolute;
  bottom: 19px;
  width: calc(100% - ${19 * 2}px);
  margin: 0 19px;
  padding: 8px 15px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

const CartContainer = styled.div``;

const CartReceiveTimeContainer = styled.div`
  margin: 0 18px;
  padding: 22px 6px 4px;
  border-bottom: 1px solid #d7d7da;
`;

const CartReceiveTimeEditButton = styled.div`
  margin-top: 7px;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  text-decoration-line: underline;
  color: #9d9da2;
`;

const CartReceiveTimeEditButtonBlank = styled(CartReceiveTimeEditButton)`
  height: 20px;
`;

const CreditCardInfoContainer = styled.div`
  margin: 0 18px;
  padding: 20px 6px;
`;

const PromotionCodeInfoContainer = styled.div`
  margin: 0 18px;
  padding: 20px 6px;
  border-bottom: 1px solid #d7d7da;
`;

const PriceContainer = styled.div`
  margin: 0 18px;
  padding: 20px 6px;
  border-bottom: 1px solid #d7d7da;
`;

const TotalItemPriceContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})``;

const TotalItemPriceTitle = styled(Grid).attrs({
  item: true,
  rate: 1
})`
  font-size: 1.8rem;
`;

const TotalItemPrice = styled(Grid).attrs({
  item: true,
  rate: 0
})`
  font-size: 1.8rem;
`;

const DiscountPriceContainer = styled(Grid).attrs({
  container: true,
  align: "center"
})`
  margin-bottom: 10px;
`;

const DiscountPriceTitle = styled(Grid).attrs({
  item: true,
  rate: 1
})`
  font-size: 1.4rem;
`;

const DiscountPrice = styled(Grid).attrs({
  item: true,
  rate: 0
})`
  font-size: 1.6rem;
  color: ${colorsConst.MAIN};
`;

const DisposalNoticeContainer = styled.div`
  margin: 0 18px;
  padding: 20px 6px;
  border-top: 1px solid #d7d7da;
`;

const ClearOrderButton = styled.div`
  padding: 18px;
  font-size: 13px;
  line-height: 18px;
  color: #9d9da2;
  text-align: center;
  text-decoration-line: underline;
`;

const iconStyle: React.CSSProperties = {
  fontSize: "1.6rem",
  marginRight: "3px",
  marginBottom: "2px"
};

const mapLinkContainerStyle: React.CSSProperties = {
  paddingLeft: "10px"
};

const mapLinkStyle: React.CSSProperties = {
  width: "118px",
  marginLeft: "auto",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#757575"
};

const shopMinimalInfoStyle: React.CSSProperties = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#757575"
};

const CartItemTitle = styled(Grid).attrs({ container: true })`
  padding: 20px 24px 0;
  font-size: 18px;
  line-height: 23px;
`;

interface IProps {
  cart: CartData;
  submittingOrder: boolean;
  fetchingCreditCardList: boolean;
  creditCard: CreditCardData;
  handleChangeOrderQuantity: (
    cartId: string,
    index: number,
    quantity: number
  ) => void;
  submittingDeleteItem: boolean;
  submittingChangeQuantity: boolean;
  submittingClearCart: boolean;
  handleClickChangeCreditCardButton: () => void;
  handleDeleteItem: (cartId: string, index: number) => void;
  handleClearCart: (cartId: string) => void;
  handleSubmitOrder: (cartId: string) => void;
  goToShop: (shopId: number) => void;
  isStaticButton: boolean;
  zIndex: number;
  handleTouchedReservationTimeSettingButton: (
    cartId: string,
    reservationTime: ReservationTime
  ) => void;
}

const Cart: React.FC<IProps> = React.memo(props => {
  const {
    cart,
    submittingOrder,
    fetchingCreditCardList,
    creditCard,
    handleChangeOrderQuantity,
    handleClickChangeCreditCardButton,
    handleClearCart,
    handleSubmitOrder,
    submittingChangeQuantity,
    submittingDeleteItem,
    submittingClearCart,
    goToShop,
    isStaticButton,
    zIndex,
    handleTouchedReservationTimeSettingButton,
    handleDeleteItem
  } = props;

  const shopData = cart.getShopData();

  const handleClickLaunchMapApp = useCallback(() => {
    Utility.launchMapApp(shopData.getLocation());
  }, [shopData]);

  const creditCardItem = useMemo(
    () => (creditCard.exists() ? creditCard.getSelectedItem() : null),
    [creditCard]
  );

  const isLuxury = shopData.isLuxury();

  const waitInductionSentence = shopData.getLuxuryWaitInductionText();

  const handleChangeQuantity = useCallback(
    (index: number, quantity: number) => {
      handleChangeOrderQuantity(cart.getId(), index, quantity);
      Analytics.logEvent("cart_change_quant");
    },
    [handleChangeOrderQuantity, cart]
  );

  const handleDeleteItemWrap = useCallback(
    (index: number) => {
      handleDeleteItem(cart.getId(), index);
    },
    [cart, handleDeleteItem]
  );

  const handleClickClearOrderButton = useCallback(() => {
    handleClearCart(cart.getId());
    Analytics.logEvent("cart_delete_all", {
      content_type: "shop_id",
      item_id: `${cart.getShopId()}`
    });
  }, [handleClearCart, cart]);

  const handleClickSubmitOrderButton = useCallback(() => {
    handleSubmitOrder(cart.getId());
  }, [handleSubmitOrder, cart]);

  const handleClickGoToShopButton = useCallback(() => {
    Analytics.logEvent("cart_to_shop");
    goToShop(cart.getShopId());
  }, [goToShop, cart]);

  const handleClickShopTitle = useCallback(() => {
    Analytics.logEvent("cart_to_shop_title");
    goToShop(cart.getShopId());
  }, [goToShop, cart]);

  return (
    <Container>
      {isLuxury && waitInductionSentence !== "" && (
        <div style={{ padding: "20px 24px" }}>
          <CommonNotice type="warning">{waitInductionSentence}</CommonNotice>
        </div>
      )}

      <CartContainer>
        <CartHeader>
          <ShopLinkContainer onClick={handleClickShopTitle}>
            <CartShopDescription>
              【{shopData.getDescription()}】
            </CartShopDescription>
            <CartShopName>{shopData.getShopName()}</CartShopName>
            <Grid style={shopMinimalInfoStyle} container justify="center">
              <Grid item>
                <TimeIcon lightGray style={iconStyle} />
                {shopData.getPresentationShopBusinessCookingTime()}
                分で調理完了
              </Grid>
              {shopData.canDisplayReviewPoint() && (
                <Grid item style={{ paddingLeft: "8px" }}>
                  <RatingStar high={shopData.isHighRating()} />
                  {shopData.getReviewPoint()}
                </Grid>
              )}
            </Grid>
            <ArrowSmallIcon style={ArrowSmallIconStyle} lightGray />
          </ShopLinkContainer>
        </CartHeader>

        {shopData.getLocation() ? (
          <>
            <MapViewer
              containerElement={<MapContainer />}
              loadingElement={
                <MapLoadingContainer>
                  <Loading />
                </MapLoadingContainer>
              }
              defaultCenter={shopData.getLocation()}
            >
              <ShopMarker location={shopData.getLocation()} isOpen={true} />
              <AddressContainer onClick={handleClickLaunchMapApp}>
                <Grid item grow={1}>
                  {shopData.getAddress()}
                </Grid>
                <Grid item grow={1} style={mapLinkContainerStyle}>
                  <Grid container align="center" style={mapLinkStyle}>
                    <Grid item style={{ paddingRight: "5px" }}>
                      マップアプリで見る
                    </Grid>
                    <ArrowSmallIcon lightGray />
                  </Grid>
                </Grid>
              </AddressContainer>
            </MapViewer>
          </>
        ) : (
          undefined
        )}

        <CartItemTitle container>
          <Grid item>ご注文内容</Grid>
          <Grid item grow={0} style={{ marginLeft: "auto" }}>
            <div
              onClick={handleClickGoToShopButton}
              style={{
                fontSize: "1.6rem",
                color: colorsConst.MAIN
              }}
            >
              他のメニューを見る
            </div>
          </Grid>
        </CartItemTitle>
        <ul>
          {cart.getItemList().map((item, index) => (
            <li key={index}>
              <CartItem
                item={item}
                cartId={cart.getId()}
                index={index}
                handleChangeOrderQuantity={handleChangeQuantity}
                handleDeleteItem={handleDeleteItemWrap}
                submittingChangeQuantity={submittingChangeQuantity}
                submittingDeleteItem={submittingDeleteItem}
              />
            </li>
          ))}
        </ul>

        <CartReceiveTimeContainer>
          <CartReceiveTime
            date={cart.getPresentationReceiveDatetime()}
            canOrder={shopData.canOrder()}
            // すみません、雑ですがcanOrder=falseの際に受取時間設定ダイアログを開く処理です
            handleClick={() =>
              handleTouchedReservationTimeSettingButton(
                cart.getId(),
                cart.getReservationTime()
              )
            }
          />
          {shopData.canOrder() ? (
            <CartReceiveTimeEditButton
              onClick={() =>
                handleTouchedReservationTimeSettingButton(
                  cart.getId(),
                  cart.getReservationTime()
                )
              }
            >
              受取日時を編集
            </CartReceiveTimeEditButton>
          ) : (
            <CartReceiveTimeEditButtonBlank />
          )}
        </CartReceiveTimeContainer>

        {cart.existsDiscountCode() && (
          <PromotionCodeInfoContainer>
            <PromotionCodeInfo
              name={cart.getCouponName()}
              code={cart.getDiscountCode()}
            />
          </PromotionCodeInfoContainer>
        )}

        <PriceContainer>
          {cart.existsDiscountCode() && (
            <DiscountPriceContainer>
              <DiscountPriceTitle>{cart.getDiscountCode()}</DiscountPriceTitle>
              <DiscountPrice>
                {`-${Price.getPresentationValue(cart.getDiscountPrice())}`}
              </DiscountPrice>
            </DiscountPriceContainer>
          )}

          <TotalItemPriceContainer>
            <TotalItemPriceTitle>合計</TotalItemPriceTitle>
            <TotalItemPrice>
              {Price.getPresentationValue(cart.getUserPaymentPrice())}
            </TotalItemPrice>
          </TotalItemPriceContainer>
        </PriceContainer>

        <CreditCardInfoContainer>
          <CreditCardInfo
            cardNumber={creditCardItem ? creditCardItem.getCardNumber() : null}
            editable
            fetching={fetchingCreditCardList && !submittingOrder}
            disabled={submittingOrder || fetchingCreditCardList}
            handleClickChange={handleClickChangeCreditCardButton}
          />
        </CreditCardInfoContainer>

        <ClearOrderButton onClick={handleClickClearOrderButton}>
          {submittingClearCart ? (
            <Loading color="#9D9DA2" />
          ) : (
            "このお店の注文を削除する"
          )}
        </ClearOrderButton>
        <DisposalNoticeContainer>
          <CommonNotice type="warning">
            {shopData.isBusy()
              ? "混雑しているので受け取り時間が遅れる場合がございます。\n調理完了から２時間経過またはお店の営業が終了した場合、商品は破棄されます。"
              : "調理完了から２時間経過または\nお店の営業が終了した場合、商品は破棄されます。"}
          </CommonNotice>
        </DisposalNoticeContainer>
        <OrderButton
          shopData={shopData}
          isStatic={isStaticButton}
          zIndex={zIndex}
          handleClickButton={handleClickSubmitOrderButton}
          submittingOrder={submittingOrder}
          submittingClearCart={submittingClearCart}
        >
          購入手続きへ
        </OrderButton>
      </CartContainer>
    </Container>
  );
});

export default Cart;
