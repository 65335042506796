import { List, Record } from "immutable";
import moment from "moment";
import EvilDatetime, { Weekday } from "../util/EvilDatetime";

interface IMappedDateToTimeListBase {
  date: string; // YYYYMMDD
}

export interface IMappedDateToTimeListProps extends IMappedDateToTimeListBase {
  time_list: string[]; // HHmm[]
}

export interface IMappedDateToTimeList extends IMappedDateToTimeListBase {
  time_list: List<string>; // HHmm[]
}

const initial: IMappedDateToTimeList = {
  date: "",
  time_list: List()
};

export default class MappedDateToTimeList extends Record(initial) {
  private static validDate(date: Date) {
    return moment(date).isValid();
  }

  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        time_list: List(args.time_list ? args.time_list : [])
      })
    );
  }

  public getDate() {
    return this.get("date");
  }

  public getWeekday(): Weekday {
    const date = this.getDateAsDate();
    if (!MappedDateToTimeList.validDate(date)) {
      return 0;
    }
    return moment(date).weekday() as Weekday;
  }

  /**
   * @return YYYY/MM/DD
   */
  public getDateString() {
    return moment(this.getDateAsDate()).format("YYYY/MM/DD");
  }

  public getDateAsDate() {
    return moment(this.getDate(), "YYYYMMDD").toDate();
  }

  /**
   * @return HH:mm[]
   */
  public getTimeStringArray() {
    const list = this.get("time_list")
      .map(i => {
        return [i.slice(0, 2), i.slice(-2)].join(":");
      })
      .toArray();
    return this.isToday() ? EvilDatetime.filterTimeRangeOnlyAfter(list) : list;
  }

  private isToday() {
    return EvilDatetime.isToday(this.getDate());
  }
}
