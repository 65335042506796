import { put } from "redux-saga/effects";
import {
  SystemChangedCurrentSearchResultTypeAction,
  updateCurrentSearchResultType
} from "../actions";

export default function* systemChangedCurrentSearchResultTypeSaga(
  action: SystemChangedCurrentSearchResultTypeAction
) {
  const { type } = action.payload;
  yield put(updateCurrentSearchResultType(type));
}
