import { Record } from "immutable";

const DEFAULT_MESSAGE = `『menu』をご愛顧いただき誠にありがとうございます。
ただいまの時間はサーバーメンテナンスを行なっております。
メンテナンス終了まで今しばらくお待ち下さい。
`;

export interface IMaintenanceInfo {
  start_date: string;
  end_date: string;
  message: string;
}

const initial: IMaintenanceInfo = {
  start_date: "",
  end_date: "",
  message: DEFAULT_MESSAGE
};

export default class MaintenaceInfo extends Record(initial) {
  public getMessage() {
    return this.get("message");
  }
}
