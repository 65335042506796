import { cancel, put } from "redux-saga/effects";
import { AlertType } from "../../../records/Alert";
import CordovaUtil from "../../../util/CordovaUtil";
import { systemAddedAlert } from "../actions";

export default function* showUpdateDialogSaga() {
  const debugEnabled = process.env.REACT_APP_DEBUG;
  if (CordovaUtil.isCordovaEnabled() || debugEnabled === "true") {
    yield cancel();
  }
  yield put(
    systemAddedAlert({
      type: AlertType.Warning,
      title: "ご注意",
      message: `アプリのバージョンが最新でない為、一部の機能をご利用いただく事ができません。
        最新版アプリへのアップデートをお願い致します。`
    })
  );
}
