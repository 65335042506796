import { Record } from "immutable";
import Price from "../Price";

export type PriceRangeValue =
  | null
  | 500
  | 600
  | 700
  | 800
  | 900
  | 1000
  | 1200
  | 1500
  | 2000;

const DISPLAYABLE_PRICE_RANGE_LIST: PriceRangeValue[] = [
  null,
  500,
  600,
  700,
  800,
  900,
  1000,
  1200,
  1500,
  2000
];

interface IPriceRange {
  min: PriceRangeValue;
  max: PriceRangeValue;
}

const initial: IPriceRange = {
  min: null,
  max: null
};

export default class PriceRange extends Record(initial) {
  public static asDefault() {
    return new PriceRange();
  }

  private constructor(args: any = {}) {
    super(args);
  }

  public getMax() {
    return this.get("max");
  }

  public setMax(max: PriceRangeValue) {
    return this.set("max", max);
  }

  public getMin() {
    return this.get("min");
  }

  public setMin(min: PriceRangeValue) {
    return this.set("min", min);
  }

  public isActive() {
    return this.getMax() !== null || this.getMin() !== null;
  }

  public getSelectableList(type: "min" | "max") {
    const current = this.get(type === "min" ? "max" : "min");
    if (current === null) {
      return DISPLAYABLE_PRICE_RANGE_LIST;
    }
    return DISPLAYABLE_PRICE_RANGE_LIST.filter(range => {
      if (range === null) {
        return true;
      }
      return type === "min" ? current > range : current < range;
    });
  }

  public contains(price: number) {
    const [min, max] = [this.getMin(), this.getMax()];
    return (min === null || min <= price) && (max === null || price <= max);
  }

  public getSettingText() {
    if (!this.isActive()) {
      return "価格";
    }
    const [min, max] = [this.getMin(), this.getMax()];
    return `${min === null ? "" : Price.getPresentationValue(min)}~${
      max === null ? "" : Price.getPresentationValue(max)
    }`;
  }
}
