import { put } from "redux-saga/effects";
import {
  updateDraftFeedbackText,
  UserChangedDraftFeedbackTextAction
} from "../actions";

export default function* userChangedDraftFeedbackTextSaga(
  action: UserChangedDraftFeedbackTextAction
) {
  yield put(
    updateDraftFeedbackText(action.payload.orderNumber, action.payload.text)
  );
}
