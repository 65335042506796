import { Record } from "immutable";
import Utility from "../util/Utility";

export interface ICategoryTag {
  id: number;
  tag_name: string;
  image_path: string;
}

const initial: ICategoryTag = {
  id: 0,
  tag_name: "",
  image_path: ""
};

export default class CategoryTag extends Record(initial) {
  public exists() {
    return this.getId() !== 0;
  }

  public getId() {
    return this.get("id");
  }

  public getTagName() {
    return this.get("tag_name");
  }

  public getImagePath() {
    return `${Utility.getImageDomain()}${this.get("image_path")}`;
  }
}
