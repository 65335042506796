import { is, List, Record } from "immutable";
import Alert from "../records/Alert";
import { SearchResultType } from "./SearchResult";

export type ModalKey =
  | "SHOULD_UPDATE_BUILD"
  | "SHOULD_UPDATE_RESOURCE"
  | "COMMON_ALERT"
  | "SETTING_FILTER"
  | "SETTING_RESERVATION"
  | "SETTING_PRICE_RANGE"
  | "OTHER_SHOP_CART"
  | "ORDER_RECEIPT"
  | "CANCEL_ORDER"
  | "SEND_FEEDBACK"
  | "ZERO_RESULT"
  | "ORDER_RECEIPT"
  | "SEND_ARRIVED"
  | "PUSH_NOTIFICATION"
  | "TOOLTIP_CARD_PERIOD"
  | "TOOLTIP_CARD_SECURITY_CORD"
  | "REQUEST_FOR_REVIEW"
  | "SELECT_OPTION"
  | "SETTING_RESERVATION_FOR_CART"
  | "SETTING_MAP_SEARCH_RESULT_FILTER"
  | "REQUEST_GEOLOCATION_PERMISSION"
  | "CONFIRM_ORDER"
  | "REQUEST_REGISTER_EMAIL"
  | "SEND_REQUEST_COMPLETE";

// tslint:disable-next-line:interface-over-type-literal
type ModalPayloadMap = {
  ["COMMON_ALERT"]: { alert: Alert };
  ["SETTING_FILTER"]: { searchResultType: SearchResultType };
  ["SETTING_RESERVATION"]: { searchResultType: SearchResultType };
  ["SETTING_PRICE_RANGE"]: { searchResultType: SearchResultType };
};

export type ModalPayload<T extends ModalKey> = T extends keyof ModalPayloadMap
  ? ModalPayloadMap[T]
  : {};

interface ModalData<T extends ModalKey> {
  key: T;
  payload: ModalPayload<T>;
}

interface IModalManagerProps {
  list: List<ModalData<ModalKey>>;
}

const initial: IModalManagerProps = {
  list: List<ModalData<ModalKey>>()
};

export default class ModalManager extends Record(initial) {
  private static getPriority(key: ModalKey) {
    switch (key) {
      case "SHOULD_UPDATE_BUILD":
        return 1;
      case "SHOULD_UPDATE_RESOURCE":
        return 2;
      case "COMMON_ALERT":
        return 3;
      default:
        return 99;
    }
  }

  /**
   * whether Modal with the same key can be stacked
   */
  private static canStack(key: ModalKey) {
    switch (key) {
      case "COMMON_ALERT":
        return true;
      default:
        return false;
    }
  }

  public canDisplay(key: ModalKey) {
    const displayable = this.getDisplayable();
    if (typeof displayable === "undefined") {
      return false;
    }
    return displayable.key === key;
  }

  public getPayload<T extends ModalKey>(key: T) {
    const data = this.getDisplayable();

    if (typeof data === "undefined") {
      return undefined;
    }

    if (data.key !== key) {
      return undefined;
    }

    return data.payload as ModalPayload<T>;
  }

  public pushKey<T extends ModalKey>(key: T, payload: ModalPayload<T>) {
    return this.set("list", this.getList().push({ key, payload }));
  }

  public removeKey(removeKey: ModalKey) {
    if (ModalManager.canStack(removeKey)) {
      return this.removeFirst(removeKey);
    } else {
      return this.removeAll(removeKey);
    }
  }

  public removeById(id: string) {
    return this.set(
      "list",
      this.getList().filter(i => {
        // FIXME:COMMON_ALERT以外もID管理するようになったら分岐を除去
        if (i.key === "COMMON_ALERT") {
          return (
            (i.payload as ModalPayload<"COMMON_ALERT">).alert.getId() !== id
          );
        } else {
          return i;
        }
      })
    );
  }

  public getList() {
    return this.get("list");
  }

  /**
   * remove the first item that has same as `removeKey`.
   */
  private removeFirst(removeKey: ModalKey) {
    const target = this.getList().find(i => i.key === removeKey);
    if (typeof target === "undefined") {
      return this;
    }
    return this.set("list", this.getList().filter(i => !is(i, target)));
  }

  /**
   * remove all items with the same key as `removeKey`.
   */
  private removeAll(removeKey: ModalKey) {
    return this.set("list", this.getList().filter(i => i.key !== removeKey));
  }

  private getDisplayable() {
    return this.getList()
      .sort(this.sortByPriority)
      .first<undefined>();
  }

  private sortByPriority(a: ModalData<ModalKey>, b: ModalData<ModalKey>) {
    const [pA, pB] = [
      ModalManager.getPriority(a.key),
      ModalManager.getPriority(b.key)
    ];
    return pA === pB ? 0 : pA > pB ? 1 : -1;
  }
}
