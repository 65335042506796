import { goBack } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import { userSubmittedAuthResetPassword } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import LocalRepository from "../../util/LocalRepository";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import LinkText from "../atoms/LinkText";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import AuthCodeFieldAlter from "../organisms/FormFields/AuthCodeField";

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public handleSubmit(authCode: string, tel: string) {
    this.dispatch(userSubmittedAuthResetPassword(authCode, tel));
  }
}

interface IProps {
  submittingAuthSmsByTel: boolean;
  actions: ActionDispatcher;
}

interface IState {
  authCode: string;
}

class SMSAuthForResetPasswordTemplate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      authCode: ""
    };

    this.goBack = this.goBack.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeAuthCode = this.handleChangeAuthCode.bind(this);
  }

  public render() {
    return (
      <>
        <Page
          header={
            <SubPageHeader title="SMS認証" handleClickBack={this.goBack} />
          }
        >
          <PageBody>
            <Notes style={{ textAlign: "center", marginBottom: "60px" }}>
              ご登録の携帯電話番号に
              <br />
              ショートメールを送信しました。
            </Notes>
            <form
              id={EFormID.AUTH_SMS}
              name="inputAuthCode"
              onSubmit={this.handleSubmit}
            >
              <div>
                <LabelText style={{ textAlign: "center" }}>
                  ４ケタの認証コードをを入力してください。
                </LabelText>
              </div>
              <AuthCodeFieldAlter
                handleChangeAuthCode={this.handleChangeAuthCode}
              />
              <div style={{ marginTop: spaceConst.GUTTER.OVER_BUTTON }}>
                <Button
                  block
                  loading={this.props.submittingAuthSmsByTel}
                  type="submit"
                  form={EFormID.AUTH_SMS}
                >
                  認証
                </Button>
              </div>
              <div style={{ marginTop: "10px", textAlign: "center" }}>
                <Link to="/resendSMSAuthCodeForResetPassword">
                  <LinkText>コードを再送信</LinkText>
                </Link>
              </div>
            </form>
          </PageBody>
        </Page>
      </>
    );
  }

  private handleChangeAuthCode(authCode: string) {
    this.setState({ authCode });
  }
  private canSubmit() {
    return this.state.authCode !== "" && this.state.authCode.length === 4;
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // const tel = this.props.user.getTel();
    // FIXME: tel部分の繋ぎこみ
    const tel = LocalRepository.getItem("authSmsByTel") as string;
    if (!this.canSubmit()) {
      return;
    }
    const authCode = this.state.authCode;
    this.props.actions.handleSubmit(authCode, tel);
  }

  private goBack() {
    this.props.actions.goBack();
  }
}

export default connect(
  (state: ReduxModel) => ({
    submittingAuthSmsByTel: state.app.isConnectedApi(EApiKey.AUTH_SMS_BY_TEL)
  }),
  (dispatch: Dispatch<ReduxAction>) => ({
    actions: new ActionDispatcher(dispatch)
  })
)(SMSAuthForResetPasswordTemplate);
