import * as React from "react";
interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgVector = (props: IProps) => {
  const fillColor = props.fill ? props.fill : "#20C795";
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 18" fill="none" {...props}>
      <path
        d="M15.771 5.5h.027a.84.84 0 0 0 .505-.186.67.67 0 0 0 .24-.532l.159-3.298a.756.756 0 0 0-.745-.798.756.756 0 0 0-.798.745l-.053 1.17A8.964 8.964 0 0 0 8.936.128C3.99.128 0 4.144 0 9.064 0 14.011 4.016 18 8.936 18a8.898 8.898 0 0 0 7.447-3.99c.24-.345.133-.824-.213-1.063-.346-.24-.824-.133-1.064.213a7.402 7.402 0 0 1-6.17 3.297 7.395 7.395 0 0 1-7.393-7.393c0-4.07 3.324-7.394 7.393-7.394a7.38 7.38 0 0 1 5.32 2.261l-1.756-.08a.756.756 0 0 0-.798.745.756.756 0 0 0 .745.798l3.324.106z"
        fill={fillColor}
      />
    </svg>
  );
};

const ReloadIcon: React.FC<IProps> = props => <SvgVector {...props} />;

export default ReloadIcon;
