import { push } from "connected-react-router";
import { cancel, join, put, select, spawn } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IPublishSmsByTelRequest,
  IPublishSmsByTelResponse
} from "../../../apis/publishSmsByTel";
import { EFormID } from "../../../forms";
import { IFixTelForm, initialFixTelForm } from "../../../forms/fixTel";
import { AlertType } from "../../../records/Alert";
import { ReduxModel } from "../../../reducer";
import LocalRepository from "../../../util/LocalRepository";
import Utility from "../../../util/Utility";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";

export function* userSubmittedRequestResetPasswordSaga() {
  try {
    const { tel }: IFixTelForm = yield select<
      (state: ReduxModel) => IFixTelForm
    >(state =>
      Utility.getReduxFormValues(state, EFormID.FIX_TEL, initialFixTelForm)
    );
    const params: IPublishSmsByTelRequest = {
      tel
    };
    const commonApiTask = yield spawn(
      commonApiSaga,
      EApiKey.PUBLISH_SMS_BY_TEL,
      params
    );
    const {
      result,
      error
    }: { result: IPublishSmsByTelResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      LocalRepository.setItem("authSmsByTel", tel);
      yield put(push("/authForResetPassword"));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証コードの送信に失敗しました。
          入力項目や通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
