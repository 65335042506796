import { goBack } from "connected-react-router";
import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import SocialSharing from "../../util/SocialSharing";
import Utility from "../../util/Utility";
import FacebookIcon from "../atoms/FacebookIcon";
import InfoFrame from "../atoms/InfoFrame";
import LineIcon from "../atoms/LineIcon";
import LinkIcon from "../atoms/LinkIcon";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import TwitterIcon from "../atoms/TwitterIcon";
import SubPageHeader from "../molecules/SubPageHeader";
import Auth from "../organisms/Auth";

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public goBack() {
    this.dispatch(goBack());
  }
}

interface IProps {
  actions: ActionDispatcher;
}

const TempHead = styled.div`
  padding: 36px 0 24px;
  font-weight: 300;
  font-size: 1.5rem;
`;

const ShareItem = styled(InfoFrame)`
  display: flex;
  align-items: center;
  padding: 0.25em 1em;
  & + & {
    margin-top: 0.5em;
  }
`;
const ShareItemText = styled.div`
  padding-left: 1em;
`;
const ShareItemIcon = styled.div``;

interface IInviteCodeProps extends React.HTMLAttributes<HTMLElement> {
  code: string;
}
const InviteCode = (props: IInviteCodeProps) => {
  const { code, ...others } = props;
  return (
    <InfoFrame
      style={{ textAlign: "center", padding: "16px 0 12px" }}
      {...others}
    >
      <div style={{ fontWeight: 600, fontSize: "1.8rem" }}>{code}</div>
      <div style={{ fontSize: "1.0rem" }}>タップするとコピーされます</div>
    </InfoFrame>
  );
};

const InvitationTemplate = React.memo((props: IProps) => {
  const { actions } = props;

  const inviteCode = "STA4KDG76";

  const handleClickInviteCode = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      Utility.execCopyText(inviteCode);
    },
    [inviteCode]
  );

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  const shareViaFacebook = useCallback(() => {
    const message = "";
    SocialSharing.shareViaFacebook(message);
  }, []);

  const shareViaLINE = useCallback(() => {
    const message = "";
    SocialSharing.shareViaLINE(message);
  }, []);

  const shareViaTwitter = useCallback(() => {
    const message = "";
    SocialSharing.shareViaTwitter(message);
  }, []);

  const copyShareLink = useCallback(() => {
    const link = "https://tapgo.co.jp";
    Utility.execCopyText(link);
  }, []);

  return (
    <Auth>
      <Page
        header={
          <SubPageHeader
            title="友達招待コード"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          <Notes>
            下記のコードを友達にシェアしましょう。
            <br />
            <br />
            シェアされたお友達がコード入力後、
            <br />
            初めて注文した際に、 <br />
            500円分のクーポンが付与されます。
            <br />
            <br />
            またお友達はコードを入力するだけで
            <br />
            同様のクーポンが付与されます。
            <br />
            <br />
            一緒に登録して、注文してみましょう！
          </Notes>
          <TempHead>友達招待コード</TempHead>
          <InviteCode code={inviteCode} onClick={handleClickInviteCode} />
          <TempHead>シェアする</TempHead>
          <ul>
            <ShareItem onClick={shareViaFacebook}>
              <ShareItemIcon>
                <FacebookIcon />
              </ShareItemIcon>
              <ShareItemText>facebook</ShareItemText>
            </ShareItem>
            <ShareItem onClick={shareViaLINE}>
              <ShareItemIcon>
                <LineIcon />
              </ShareItemIcon>
              <ShareItemText>LINE</ShareItemText>
            </ShareItem>
            <ShareItem onClick={shareViaTwitter}>
              <ShareItemIcon>
                <TwitterIcon />
              </ShareItemIcon>
              <ShareItemText>Twitter</ShareItemText>
            </ShareItem>
            <ShareItem onClick={copyShareLink}>
              <ShareItemIcon>
                <LinkIcon />
              </ShareItemIcon>
              <ShareItemText>リンクをコピー</ShareItemText>
            </ShareItem>
          </ul>
        </PageBody>
      </Page>
    </Auth>
  );
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationTemplate);
