import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgShareTwitter = (props: IProps) => (
  <svg width={30} height={30} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.25 30H3.75C1.68 30 0 28.32 0 26.25V3.75C0 1.68 1.68 0 3.75 0h22.5C28.32 0 30 1.68 30 3.75v22.5c0 2.07-1.68 3.75-3.75 3.75z"
        fill="#1DA1F2"
      />
      <path
        d="M11.072 23.113c7.112 0 11.005-5.915 11.005-11.035 0-.166 0-.333-.008-.499A7.892 7.892 0 0 0 24 9.567c-.694.31-1.44.515-2.225.613a3.9 3.9 0 0 0 1.705-2.148 7.681 7.681 0 0 1-2.46.938 3.856 3.856 0 0 0-2.82-1.225 3.878 3.878 0 0 0-3.87 3.88c0 .302.038.597.098.884a10.974 10.974 0 0 1-7.973-4.054 3.887 3.887 0 0 0-.52 1.952c0 1.346.686 2.533 1.72 3.23a3.904 3.904 0 0 1-1.75-.485v.053a3.884 3.884 0 0 0 3.1 3.804c-.325.091-.664.136-1.018.136-.25 0-.49-.022-.725-.068a3.869 3.869 0 0 0 3.613 2.693 7.745 7.745 0 0 1-4.804 1.664c-.31 0-.619-.016-.92-.053a10.98 10.98 0 0 0 5.92 1.732"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default function TwitterIcon(props: IProps) {
  return <SvgShareTwitter {...props} />;
}
