import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {}

const SvgVector = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...rest}>
      <circle cx="10.5" cy="10.5" r="10" fill="white" stroke="#E0E0E0" />
    </svg>
  );
};

const CircleIcon = (props: IProps) => <SvgVector {...props} />;

export default CircleIcon;
