import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ArrowIcon from "../atoms/ArrowIcon";
import HeaderContainer from "../atoms/HeaderContainer";
import MenuLogo from "../atoms/MenuLogo";

const Container = styled.div<{ invert?: boolean }>`
  position: relative;
  height: 100%;
  padding: 0 60px;
  ${p =>
    p.invert
      ? `
    color: #fff;
    background-color: #000;
  `
      : ""};
`;

const PageTitle = styled.div`
  font-weight: 500;
  font-size: 1.9rem;
`;

const BackIconContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  transform: translateY(50%);
  cursor: pointer;
  font-size: 1.9rem;
`;

const PageTitleContainer = styled.div<{ flexStart?: boolean }>`
  display: flex;
  justify-content: ${p => (p.flexStart ? "flex-start" : "center")};
  align-items: center;
  height: 100%;
`;

const SubPageHeaderMenuLogo = styled(MenuLogo)`
  width: 93px;
  height: 28px;
`;

interface IProps extends React.HTMLAttributes<HTMLHeadingElement> {
  title?: string;
  link?: string;
  invert?: boolean;
  handleClickBack?: () => void;
}

const SubPageHeader = React.memo((props: IProps) => {
  const { title, link, invert, handleClickBack, ...rest } = props;
  return (
    <HeaderContainer {...rest}>
      <Container invert={invert}>
        <PageTitleContainer>
          {title ? (
            link ? (
              <Link to={link}>
                <PageTitle>{title}</PageTitle>
              </Link>
            ) : (
              <PageTitle>{title}</PageTitle>
            )
          ) : (
            <SubPageHeaderMenuLogo />
          )}
        </PageTitleContainer>
        {handleClickBack && (
          <BackIconContainer onClick={handleClickBack}>
            <ArrowIcon invert={invert} />
          </BackIconContainer>
        )}
      </Container>
    </HeaderContainer>
  );
});

export default SubPageHeader;
