import { FormErrors } from "redux-form";
import UserModel from "../modules/user/model";

export interface IFixTelForm {
  tel: string;
}
export const initialFixTelForm: IFixTelForm = {
  tel: ""
};

export function validateFixTelForm(values: IFixTelForm) {
  const errors: FormErrors<IFixTelForm> = {};
  if (!values.tel) {
    errors.tel = "携帯電話番号を入力してください";
  } else if (!UserModel.validTel(values.tel)) {
    errors.tel = "携帯電話番号の形式が正しくありません";
  } else if (values.tel.length !== 11) {
    errors.tel = "11桁の番号を入力してください";
  }
  return errors;
}
