import * as React from "react";
import { useCallback } from "react";
import { OverlayView, OverlayViewProps } from "react-google-maps";
import { ILatLng } from "../../modules/search/model";
import ShopImageMarkerContent from "./ShopImageMarkerContent";

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -height
});

interface IProps extends OverlayViewProps {
  src: string;
  location: ILatLng;
  handleClick?: () => void;
  disabled?: boolean;
}

const ShopImageMarker = React.memo((props: IProps) => {
  const { src, location, disabled } = props;

  const handleClick = useCallback(() => {
    if (props.handleClick) {
      props.handleClick();
    }
  }, [props.handleClick]);

  return (
    <OverlayView
      position={location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <ShopImageMarkerContent
        src={src}
        handleClick={handleClick}
        disabled={disabled}
      />
    </OverlayView>
  );
});

export default ShopImageMarker;
