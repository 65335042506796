import * as React from "react";
import { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  NAVIGATION_PATH_FASTEST_ORDER,
  NAVIGATION_PATH_HISTORY,
  NAVIGATION_PATH_HOME,
  NAVIGATION_PATH_RESERVE_ORDER,
  NAVIGATION_PATH_USER,
  NavigationPath
} from "../../../modules/app/model";
import { ReduxModel } from "../../../reducer";
import Analytics from "../../../util/Analytics";
import AccountIcon from "../../atoms/AccountIcon";
import FastestIcon from "../../atoms/FastestIcon";
import Grid from "../../atoms/Grid";
import HomeIcon from "../../atoms/HomeIcon";
import ReceiptIcon from "../../atoms/ReceiptIcon";
import ReserveIcon from "../../atoms/ReserveIcon";
import navigationIcon from "./navigationIcon";
import NavigationItem from "./NavigationItem";

const NavigationContainer = styled(Grid.type.withComponent("nav")).attrs({
  container: true,
  align: "center",
  justify: "space-between"
})`
  padding: 20px;
  padding-top: 0;
  padding-bottom: calc(15px + constant(safe-area-inset-bottom));
  padding-bottom: calc(15px + env(safe-area-inset-bottom));
  font-size: 2.4rem;
  background-color: #ffffff;
`;

interface INavItem {
  link: NavigationPath;
  active: boolean;
  icon: React.FC<{ active?: boolean } & {}>;
  label: string;
}

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{}>
) => ({
  pathName: ownProps.location.pathname
});

const mapDispatchToProps = null;

interface IProps extends ReturnType<typeof mapStateToProps> {}

const Navigation: React.FC<IProps> = React.memo(({ pathName }) => {
  const isActiveItem = useCallback(
    (regExp: RegExp) => {
      return regExp.test(pathName);
    },
    [pathName]
  );

  const handleClickItem = useCallback(
    (path: NavigationPath) => {
      if (path === pathName) {
        return;
      }
      switch (path) {
        case NAVIGATION_PATH_HOME:
          Analytics.logEvent("footer_home");
          break;
        case NAVIGATION_PATH_FASTEST_ORDER:
          Analytics.logEvent("footer_fastest");
          break;
        case NAVIGATION_PATH_RESERVE_ORDER:
          Analytics.logEvent("footer_reserve");
          break;
        case NAVIGATION_PATH_HISTORY:
          Analytics.logEvent("footer_hist");
          break;
        case NAVIGATION_PATH_USER:
          Analytics.logEvent("footer_mypage");
          break;
        default:
      }
    },
    [pathName]
  );

  const navMap = useMemo<INavItem[]>(() => {
    return [
      {
        link: "/search/fastest",
        active: isActiveItem(new RegExp(/^\/search\/fastest/)),
        icon: navigationIcon(FastestIcon),
        label: "今すぐ受取"
      },
      {
        link: "/search/reserve",
        active: isActiveItem(new RegExp(/^\/search\/reserve/)),
        icon: navigationIcon(ReserveIcon),
        label: "予約"
      },
      {
        link: "/",
        active: isActiveItem(new RegExp(/^\/$/)),
        icon: navigationIcon(HomeIcon),
        label: "ホーム"
      },
      {
        link: "/history",
        active: isActiveItem(new RegExp(/^\/history/)),
        icon: navigationIcon(ReceiptIcon),
        label: "注文履歴"
      },
      {
        link: "/user",
        active: isActiveItem(new RegExp(/^\/user/)),
        icon: navigationIcon(AccountIcon),
        label: "マイページ"
      }
    ];
  }, [isActiveItem]);

  const itemWidth = useMemo(() => `${100 / navMap.length}%`, [navMap]);

  return (
    <NavigationContainer>
      {navMap.map(item => (
        <NavigationItem
          key={item.link}
          link={item.link}
          active={item.active}
          icon={item.icon}
          label={item.label}
          itemWidth={itemWidth}
          handleClick={handleClickItem}
        />
      ))}
    </NavigationContainer>
  );
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navigation)
);
