import { useCallback } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  initialResetPasswordForm,
  IResetPasswordForm,
  validateResetPasswordForm
} from "../../forms/resetPassword";
import { userSubmittedResetPassword } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Utility from "../../util/Utility";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public handleSubmit(password: string, passwordConfirm: string) {
    this.dispatch(userSubmittedResetPassword(password, passwordConfirm));
  }
}

interface IPropsBase {
  submittingUpdatePassword: boolean;
  actions: ActionDispatcher;
  formValues: IResetPasswordForm;
}

interface IProps
  extends InjectedFormProps<IResetPasswordForm, IPropsBase>,
    IPropsBase {}

const ResetPasswordTemplate = React.memo((props: IProps) => {
  const { submittingUpdatePassword, formValues, actions } = props;

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!props.valid) {
        props.touch(...Object.keys(initialResetPasswordForm));
        return;
      }
      actions.handleSubmit(
        formValues.newPassword,
        formValues.newPasswordConfirm
      );
    },
    [props, actions, formValues.newPassword, formValues.newPasswordConfirm]
  );

  return (
    <>
      <Page header={<SubPageHeader title="パスワード変更" />}>
        <PageBody>
          <Notes style={{ marginBottom: "36px" }}>
            パスワードがリセットされました。
            <br />
            新しいパスワードを入力してください。
          </Notes>
          <form id={EFormID.RESET_PASSWORD} onSubmit={handleSubmit}>
            <div>
              <LabelText htmlFor="newPassword">新しいパスワード</LabelText>
              <TextField
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="半角英数字８文字以上"
                autoComplete="new-password"
              />
            </div>
            <div style={{ marginTop: "36px" }}>
              <LabelText htmlFor="password">
                新しいパスワード（確認用）
              </LabelText>
              <TextField
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                type="password"
                placeholder="半角英数字８文字以上"
                autoComplete="new-password"
              />
            </div>
            <div
              style={{
                marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
              }}
            >
              <Button
                block
                loading={submittingUpdatePassword}
                type="submit"
                form={EFormID.RESET_PASSWORD}
              >
                送信
              </Button>
            </div>
          </form>
        </PageBody>
      </Page>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  formValues: Utility.getReduxFormValues<IResetPasswordForm>(
    state,
    EFormID.RESET_PASSWORD,
    initialResetPasswordForm
  ),
  submittingUpdatePassword: state.app.isConnectedApi(EApiKey.UPDATE_PASSWORD)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

const reduxFormConfig = {
  form: EFormID.RESET_PASSWORD,
  initialValues: initialResetPasswordForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateResetPasswordForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IResetPasswordForm, IPropsBase>(reduxFormConfig)(
    ResetPasswordTemplate
  )
);
