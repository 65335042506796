import React from "react";
import { connect } from "react-redux";
import { EProcessingFlag } from "../../modules/app/model";
import { ReduxModel } from "../../reducer";
import LoadingDialog from "./LoadingDialog";

const mapStateToProps = (state: ReduxModel) => ({
  submittingAddCart: state.app.isProcessing(EProcessingFlag.SUBMIT_ADD_CART)
});

interface IProps extends ReturnType<typeof mapStateToProps> {}

const SubmittingAddCartDialog: React.FC<IProps> = React.memo(
  ({ submittingAddCart }) => {
    return (
      <LoadingDialog
        isOpen={submittingAddCart}
        statusText={"カートに追加しています..."}
      />
    );
  }
);

export default connect(mapStateToProps)(SubmittingAddCartDialog);
