import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IFixTelForm,
  initialFixTelForm,
  validateFixTelForm
} from "../../forms/fixTel";
import { userSubmittedRequestResetPassword } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Analytics from "../../util/Analytics";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";

const PhoneNumberInputContainer = styled.div`
  margin-top: 40px;
`;

const SubmitButtonContainer = styled.div`
  margin-top: ${spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT};
`;

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public handleSubmit() {
    Analytics.logEvent("pwreset");
    this.dispatch(userSubmittedRequestResetPassword());
  }
}

interface IPropsBase {
  submittingPublishSmsByTel: boolean;
  tel: string;
  actions: ActionDispatcher;
}

interface IProps
  extends InjectedFormProps<IFixTelForm, IPropsBase>,
    IPropsBase {}

const RequestResetPasswordTemplate = React.memo((props: IProps) => {
  const { submittingPublishSmsByTel, tel, actions } = props;

  useEffect(() => {
    if (tel !== null) {
      props.change("tel", tel);
    }
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!props.valid) {
        props.touch(...Object.keys(initialFixTelForm));
        return;
      }
      actions.handleSubmit();
    },
    [actions]
  );

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <>
      <Page
        header={
          <SubPageHeader
            title="パスワード再設定"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          <Notes>
            ご登録の携帯電話番号を入力してください。
            <br />
            SMSで認証コードをお送りします。
          </Notes>
          <form id={EFormID.FIX_TEL} onSubmit={handleSubmit}>
            <PhoneNumberInputContainer>
              <LabelText>携帯電話番号</LabelText>
              <TextField
                name="tel"
                placeholder={"000 0000 0000"}
                autoComplete="tel"
              />
            </PhoneNumberInputContainer>
            <SubmitButtonContainer>
              <Button
                block
                loading={submittingPublishSmsByTel}
                disabled={!props.valid}
                type="submit"
                form={EFormID.FIX_TEL}
              >
                送信
              </Button>
            </SubmitButtonContainer>
          </form>
        </PageBody>
      </Page>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  tel: state.user.getData().getTel(),
  submittingPublishSmsByTel: state.app.isConnectedApi(
    EApiKey.PUBLISH_SMS_BY_TEL
  )
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

const reduxFormConfig = {
  form: EFormID.FIX_TEL,
  initialValues: initialFixTelForm,
  validate: validateFixTelForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IFixTelForm, IPropsBase>(reduxFormConfig)(
    RequestResetPasswordTemplate
  )
);
