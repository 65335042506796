import { FormErrors } from "redux-form";

export interface IRequesrAreaForm {
  expansion_area: string;
}

export const initialRequesrAreaForm: IRequesrAreaForm = {
  expansion_area: ""
};

export function validateRequesrAreaForm(values: IRequesrAreaForm) {
  const errors: FormErrors<IRequesrAreaForm> = {};
  if (!values.expansion_area) {
    errors.expansion_area = "必須項目が入力されていません";
  }
  return errors;
}
