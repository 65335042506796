import { Record } from "immutable";

export enum ENewsType {
  ONLY_TITLE = 1,
  TEXT = 2,
  HTML = 3
}

export enum ENewsReleaseType {
  USER = 1,
  SHOP = 2,
  BOTH = 3
}

export interface INewsItem {
  id: number;
  release_type: ENewsReleaseType;
  news_type: ENewsType;
  path: string;
  title: string;
  detail_text: string;
  start_datetime: string;
}

const initialValues: INewsItem = {
  id: 0,
  release_type: ENewsReleaseType.USER,
  news_type: ENewsType.ONLY_TITLE,
  path: "",
  title: "",
  detail_text: "",
  start_datetime: ""
};

export default class NewsItem extends Record(initialValues) {
  public getId(): number {
    return this.get("id");
  }

  public getReleaseType(): ENewsReleaseType {
    return this.get("release_type");
  }

  public getNewsType(): ENewsType {
    return this.get("news_type");
  }

  /**
   * HTMLパス
   */
  public getHTMLPath(): string {
    return this.get("path");
  }

  public getTitle(): string {
    return this.get("title");
  }

  public getDetailText(): string {
    return this.get("detail_text");
  }

  public getDetailTextMultiLine(): string[] {
    return this.getDetailText().split("\n");
  }

  public getStartDatetime(): string {
    return this.get("start_datetime");
  }
}
