import React from "react";
import RequestForReviewDialog from "./RequestForReviewDialog";
import SubmittingOrderDialog from "./SubmittingOrderDialog";

interface IProps {}

const ShopDetailModal: React.FC<IProps> = React.memo(props => {
  return (
    <>
      <SubmittingOrderDialog />
      <RequestForReviewDialog />
    </>
  );
});

export default ShopDetailModal;
