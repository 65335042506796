import React from "react";
import ZeroResultDialog from "./ZeroResultDialog";

interface IProps {
  children?: never;
}

const MypageModal: React.FC<IProps> = React.memo(props => {
  return (
    <>
      <ZeroResultDialog />
    </>
  );
});

export default MypageModal;
