import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";

const FramelessButton = styled.button`
  display: block;
  width: 100%;
  font-size: 1.7rem;
  color: ${colorsConst.MAIN};
  text-align: center;
`;

export default FramelessButton;
