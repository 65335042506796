import { Record } from "immutable";

export enum ENoticeType {
  ORDER_STATE = 1,
  PRIVATE = 2
}

export interface INotificationItem {
  notification_type: ENoticeType;
  order_no: string;
  message: string;
  created_datetime: string;
}

const initialValues: INotificationItem = {
  notification_type: ENoticeType.PRIVATE,
  order_no: "",
  message: "",
  created_datetime: ""
};

export default class NotificationItem extends Record(initialValues) {
  public getNotificationType(): ENoticeType {
    return this.get("notification_type");
  }

  public getOrderNumber(): string {
    return this.get("order_no");
  }

  public getMessage(): string {
    return this.get("message");
  }

  public getMessageMultiLine(): string[] {
    return this.getMessage().split("\n");
  }

  public getCreatedDatetime(): string {
    return this.get("created_datetime");
  }

  public isPrivateNotification() {
    return this.getNotificationType() === ENoticeType.PRIVATE;
  }
}
