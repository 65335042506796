import { cancel, fork, join, put } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IPublishSmsRequest,
  IPublishSmsResponse
} from "../../../apis/publishSms";
import { AlertType } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";

export function* publishSMSCodeSaga() {
  try {
    const params: IPublishSmsRequest = {};
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.PUBLISH_SMS, params)
    );
    const {
      result,
      error
    }: { result: IPublishSmsResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    if (result && !error) {
      return true;
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `認証コードの発行に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
    return false;
  }
}
