import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IFixTelForm,
  initialFixTelForm,
  validateFixTelForm
} from "../../forms/fixTel";
import {
  userTouchedRequestToNotifyAuthCodeByVirtualCallButton,
  userTouchedRequestToResentSMSAuthCodeButton
} from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Utility from "../../util/Utility";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import Notes from "../atoms/Notes";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";

interface IPropsBase {
  submittingUpdateTel: boolean;
  submittingPublishSms: boolean;
  tel: string;
  formValues: IFixTelForm;
  actions: ActionDispatcher;
}

interface IProps
  extends InjectedFormProps<IFixTelForm, IPropsBase>,
    IPropsBase {}

const ResendSMSAuthCodeTemplate = React.memo((props: IProps) => {
  const {
    submittingPublishSms,
    submittingUpdateTel,
    tel,
    formValues,
    actions
  } = props;

  const setDefaultValue = useCallback(() => {
    if (tel !== null) {
      props.change("tel", tel);
    }
  }, [tel]);

  useEffect(() => {
    setDefaultValue();
  }, []);

  const requestToResentSMSAuthCode = useCallback(() => {
    actions.userTouchedRequestToResentSMSAuthCodeButton(formValues);
  }, [actions, formValues]);

  const requestToNotifyAuthCodeByVirtualCall = useCallback(() => {
    actions.userTouchedRequestToNotifyAuthCodeByVirtualCallButton(formValues);
  }, [actions, formValues]);

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <>
      <Page
        header={
          <SubPageHeader
            title="ショートメール再送信"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          <LabelText>携帯電話番号</LabelText>
          <TextField
            name="tel"
            type="number"
            placeholder="000 0000 0000"
            autoComplete="tel"
          />
          <div
            style={{
              marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
            }}
          >
            <Button
              block
              loading={submittingUpdateTel || submittingPublishSms}
              onClick={requestToResentSMSAuthCode}
            >
              SMSを再送信
            </Button>
          </div>
          <div
            style={{
              marginTop: "60px"
            }}
          >
            <Notes>
              ＜ショートメールが送られてこない方はこちら＞
              <br />
              電話がかかってきて自動音声でコードをお伝えします。
            </Notes>
            <div
              style={{
                marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
              }}
            >
              <Button
                block
                disabled={submittingUpdateTel || submittingPublishSms}
                onClick={requestToNotifyAuthCodeByVirtualCall}
              >
                電話でコードを聞く
              </Button>
            </div>
          </div>
        </PageBody>
      </Page>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  formValues: Utility.getReduxFormValues<IFixTelForm>(
    state,
    EFormID.FIX_TEL,
    initialFixTelForm
  ),
  tel: state.user.getData().getTel(),
  submittingUpdateTel: state.app.isConnectedApi(EApiKey.UPDATE_TEL),
  submittingPublishSms: state.app.isConnectedApi(EApiKey.PUBLISH_SMS)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public userTouchedRequestToResentSMSAuthCodeButton(values: IFixTelForm) {
    this.dispatch(userTouchedRequestToResentSMSAuthCodeButton(values.tel));
  }

  public userTouchedRequestToNotifyAuthCodeByVirtualCallButton(
    values: IFixTelForm
  ) {
    this.dispatch(
      userTouchedRequestToNotifyAuthCodeByVirtualCallButton(values.tel)
    );
  }
}

const reduxFormConfig = {
  form: EFormID.FIX_TEL,
  initialValues: initialFixTelForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateFixTelForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IFixTelForm, IPropsBase>(reduxFormConfig)(ResendSMSAuthCodeTemplate)
);
