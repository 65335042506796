import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgTooltip = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 13" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 12C9.53757 12 12 9.53757 12 6.5C12 3.46243 9.53757 1 6.5 1C3.46243 1 1 3.46243 1 6.5C1 9.53757 3.46243 12 6.5 12ZM6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM7 9.20239V10.2024H6V9.20239H7ZM6.50046 4.21875C5.95332 4.21875 5.50977 4.6623 5.50977 5.20945H4.50977C4.50977 4.11001 5.40103 3.21875 6.50046 3.21875C7.59989 3.21875 8.49116 4.11001 8.49116 5.20945C8.49116 6.13636 7.85765 6.91531 7 7.13694V8.19043H6V6.19043H6.63972C7.12093 6.12273 7.49116 5.70932 7.49116 5.20945C7.49116 4.6623 7.04761 4.21875 6.50046 4.21875Z"
        fill="#6E6E6E"
      />
    </svg>
  );
};

const TooltipIcon: React.FC<IProps> = props => <SvgTooltip {...props} />;

export default TooltipIcon;
