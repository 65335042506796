import * as React from "react";
import { useCallback } from "react";
import {
  BaseFieldProps,
  Field,
  GenericField,
  WrappedFieldProps
} from "redux-form";
import styled from "styled-components";
import Grid from "../../../components/atoms/Grid";
import CheckBox from "../../atoms/CheckBox";

const RenderCheckbox = (props: WrappedFieldProps) => {
  const {
    input,
    input: { onBlur, onChange, value },
    meta,
    ...rest
  } = props;

  // FIXME: reduxFormのバグ？
  // checkboxの場合はFieldのonBlur関数が呼ばれずにtouchedが常にfalseとなる..
  // 解決策としてonChange内でonBlur関数を呼ぶようにした
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onBlur(value);
      onChange(event.target.checked);
    },
    [value, onBlur, onChange]
  );

  return <input {...input} {...rest} onChange={handleChange} />;
};

const CheckIconContainer = styled.span`
  display: inline-block;
`;

const checkIconStyle: React.CSSProperties = {
  fontSize: "2.4rem"
};

const checkFieldStyle: React.CSSProperties = {
  display: "none"
};

const gutterStyle: React.CSSProperties = {
  paddingLeft: "17px"
};

interface IBaseProps {
  id?: string;
  labelComponent?: React.ReactChild;
}

interface IFieldProps extends WrappedFieldProps, IBaseProps {}

interface IProps extends BaseFieldProps, IBaseProps {}

const CheckboxField = Field as new () => GenericField<IProps>;

const RenderCheckboxField = (props: IFieldProps) => {
  const { input } = props;

  return (
    <div>
      <label htmlFor={props.id}>
        <Grid container align="center">
          <Grid item rate={0}>
            <CheckIconContainer>
              <CheckBox checked={input.value} style={checkIconStyle} />
              <Field
                id={props.id}
                name={input.name}
                type="checkbox"
                component={RenderCheckbox}
                style={checkFieldStyle}
              />
            </CheckIconContainer>
          </Grid>
          <Grid item rate={1} style={gutterStyle}>
            {props.labelComponent}
          </Grid>
        </Grid>
      </label>
    </div>
  );
};

export default function(props: IProps) {
  return (
    <CheckboxField
      id={props.id}
      name={props.name}
      component={RenderCheckboxField}
      labelComponent={props.labelComponent}
    />
  );
}
