import { ellipsis } from "polished";
import React, { useCallback } from "react";
import styled from "styled-components";
import Price from "../../../records/Price";
import ShopForSearch from "../../../records/ShopForSearch";
import colorsConst from "../../../styles/const/colorsConst";
import ThumbnailContainer from "../../atoms/ThumbnailContainer";
import Thumbnail from "../../molecules/Thumbnail";
import { MAP_CAROUSEL_HEIGHT } from "./index";

const Container = styled.div`
  position: relative;
  background-color: ${colorsConst.BACKGROUND};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
`;

export const EmptyCarouselItem = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${() => MAP_CAROUSEL_HEIGHT}px;
  background-color: ${colorsConst.BACKGROUND};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

const ThumbContainer = styled(ThumbnailContainer).attrs({ size: 58 })`
  position: relative;
  flex: 0 0 auto;
  margin-right: 8px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;

const ShopName = styled.div`
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: #484848;
  ${ellipsis()}
`;

const ShopDescription = styled.div`
  flex: 1 1 auto;
  padding-top: 6px;
  font-size: 12px;
  color: #a6a6a6;
  ${ellipsis()}
`;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
`;

const CookingTimeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 4px;
  font-size: 10px;
  font-weight: 500;
  border-bottom: 2px solid #ff3008;
  color: #272727;
`;

const CookingTimeInfoKey = styled.span`
  padding-right: 2px;
  font-size: 10px;
  color: #595959;
`;
const CookingTimeInfoValue = styled.span`
  font-size: 18px;
  line-height: 1;
  transform: translateY(2px);
`;
const CookingTimeInfoUnit = styled.span`
  padding-left: 2px;
  font-size: 10px;
  transform: translateY(-2px);
`;

const PriceRangeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 12px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #272727;
  border-bottom: 2px solid #ffa592;
`;

interface IProps {
  shop: ShopForSearch;
  handleClick: (shopId: number) => void;
}

const MapCarouselItem: React.FC<IProps> = React.memo(
  ({ shop, handleClick }) => {
    const handleClickWrap = useCallback(() => {
      handleClick(shop.getId());
    }, [handleClick, shop]);

    return (
      <Container onClick={handleClickWrap}>
        <Inner>
          <ThumbContainer>
            <Thumbnail src={shop.getMostRecommendItem().getImagePath()} />
          </ThumbContainer>
          <DetailContainer>
            <ShopName>{shop.getShopName()}</ShopName>
            <ShopDescription>{shop.getDescription()}</ShopDescription>
            <DetailInfo>
              <CookingTimeInfo>
                <CookingTimeInfoKey>{"出来上がり"}</CookingTimeInfoKey>
                <CookingTimeInfoValue>
                  {shop
                    .getShopBusinessDisplayCookingTime()
                    .split("")
                    .map((char, index) => {
                      const isLastChar =
                        shop.getShopBusinessDisplayCookingTime().length ===
                        index + 1;
                      if (isLastChar) {
                        return (
                          <CookingTimeInfoUnit key={index}>
                            {char}
                          </CookingTimeInfoUnit>
                        );
                      }
                      return char;
                    })}
                </CookingTimeInfoValue>
              </CookingTimeInfo>
              <PriceRangeInfo>{`${Price.getPresentationValue(
                shop.getLowestPrice()
              )}~`}</PriceRangeInfo>
            </DetailInfo>
          </DetailContainer>
        </Inner>
      </Container>
    );
  }
);

export default MapCarouselItem;
