import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import MemoTextInput from "../atoms/MemoTextInput";

const MemoTextHead = styled.div`
  display: inline-block;
  padding: 7px 24px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background-color: #f5f5f5;
`;

const MemoTextInputContainer = styled.div`
  padding-bottom: 14px;
`;

interface IProps {
  connectingAddOrUpdateItem?: boolean;
  connectingDeleteFromCart?: boolean;
  disabled?: boolean;
  notes: string;
  handleBlurInstructionText?: (text: string) => void;
}

const MemoTextViewer: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    connectingAddOrUpdateItem,
    connectingDeleteFromCart,
    disabled,
    notes,
    handleBlurInstructionText
  } = props;

  const [draftNotes, setDraftNotes] = useState(notes);

  const handleChangeNotes = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      const value = event.currentTarget.value;
      setDraftNotes(value.length > 50 ? value.substr(0, 50) : value);
    },
    []
  );

  const handleBlur = useCallback(() => {
    if (typeof handleBlurInstructionText !== "undefined") {
      handleBlurInstructionText(draftNotes);
    }
  }, [handleBlurInstructionText, draftNotes]);

  useEffect(() => {
    setDraftNotes(notes);
  }, [notes]);

  return (
    <>
      <MemoTextHead>その他の指示事項</MemoTextHead>
      <MemoTextInputContainer>
        <MemoTextInput
          id="inputNotes"
          placeholder="お店への連絡事項はこちらにご記入ください。例「お箸は必要ありません」など"
          maxLength={50}
          disabled={
            connectingAddOrUpdateItem || connectingDeleteFromCart || disabled
          }
          value={draftNotes}
          onChange={handleChangeNotes}
          handleBlur={handleBlur}
        />
      </MemoTextInputContainer>
    </>
  );
});

export default MemoTextViewer;
