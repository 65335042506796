import { List, Record } from "immutable";
import CreditCardItem, { ICreditCardItem } from "./CreditCardItem";

export interface ICreditCardDataProps {
  credit_card_list: ICreditCardItem[] | List<CreditCardItem>;
  select_card_index: number;
}

export interface ICreditCardData extends ICreditCardDataProps {
  credit_card_list: List<CreditCardItem>;
}

const initialValues: ICreditCardData = {
  credit_card_list: List<CreditCardItem>(),
  select_card_index: 0
};

export default class CreditCardData extends Record(initialValues) {
  constructor(args: any = {}) {
    super(
      Object.assign({}, args, {
        credit_card_list: List(
          args.credit_card_list &&
            args.credit_card_list.map(
              (item: ICreditCardItem) => new CreditCardItem(item)
            )
        )
      })
    );
  }

  public exists() {
    return !this.getCreditCardList().isEmpty();
  }

  public getCreditCardList(): List<CreditCardItem> {
    return this.get("credit_card_list");
  }

  public getSelectCardIndex(): number {
    return this.get("select_card_index");
  }

  public getSelectedItem(): CreditCardItem | null {
    const target = this.getCreditCardList().find(
      (_, index) => index === this.getSelectCardIndex()
    );
    return typeof target === "undefined" ? null : target;
  }

  public isSelectedItemById(cardId: string) {
    const selected = this.getSelectedItem();
    return selected !== null && selected.getId() === cardId;
  }
}
