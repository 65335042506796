import { Record } from "immutable";
import { NOT_EXIST_ITEM_COOKING_TIME } from "../util/EvilDatetime";
import NotBusinessReason, { INotBusinessReason } from "./NotBusinessReason";

interface IShopListBusinessDataBase {
  cooking_time: number;
  is_fastest_order: boolean;
  is_reservation_order: boolean;
  is_business: boolean;
  fastest_cooking_time: number; // 単品調理時間で最も早い調理時間
  display_cooking_time: string; // "〇〇分～××分"または"〇〇分"
}

export interface IShopListBusinessDataProps extends IShopListBusinessDataBase {
  not_business_reason: INotBusinessReason;
}

export interface IShopListBusinessData extends IShopListBusinessDataBase {
  not_business_reason: NotBusinessReason;
}

const initialValues: IShopListBusinessData = {
  cooking_time: 0,
  is_fastest_order: false,
  is_reservation_order: false,
  is_business: true,
  not_business_reason: new NotBusinessReason(),
  fastest_cooking_time: NOT_EXIST_ITEM_COOKING_TIME,
  display_cooking_time: ""
};

export default class ShopListBusinessData extends Record(initialValues) {
  constructor(args: Partial<IShopListBusinessDataProps> = {}) {
    super(
      Object.assign({}, args, {
        not_business_reason: new NotBusinessReason(
          args.not_business_reason ? args.not_business_reason : {}
        )
      })
    );
  }

  public getCookingTime(): number {
    return this.get("cooking_time");
  }

  public getFastestCookingTime(): number {
    return this.get("fastest_cooking_time");
  }

  public getPresentationCookingTime(): string {
    const fastestCookingTime = this.getFastestCookingTime();
    const regularCookingTime = this.getCookingTime();
    return this.isValidFastestCookingTime()
      ? `${fastestCookingTime}分〜`
      : `${regularCookingTime}分`;
  }

  public getDisplayCookingTime() {
    return this.get("display_cooking_time");
  }

  public isValidFastestCookingTime() {
    return (
      this.getFastestCookingTime() !== NOT_EXIST_ITEM_COOKING_TIME &&
      this.getFastestCookingTime() !== this.getCookingTime()
    );
  }

  public isOpen(): boolean {
    return this.get("is_business");
  }

  public getNotBusinessReason() {
    return this.get("not_business_reason");
  }

  public canFastestOrder(): boolean {
    return this.get("is_fastest_order");
  }

  public canReservationOrder(): boolean {
    return this.get("is_reservation_order");
  }
}
