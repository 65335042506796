import { ellipsis } from "polished";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ORDERABLE_COUNT_MAX,
  ORDERABLE_COUNT_MIN
} from "../../records/OrderData";
import OrderItem from "../../records/OrderItem";
import Price from "../../records/Price";
import ShopOption from "../../records/ShopOption";
import ShopOptionSet from "../../records/ShopOptionSet";
import colorsConst from "../../styles/const/colorsConst";
import { rangeInt, fixBodyScrollTopWhenInputBlurred } from "../../util/Utility";
import Grid from "../atoms/Grid";
import Loading from "../molecules/Loading";
import OptionMinimalInfo from "./OptionMinimalInfo";

const MenuWrapContainer = styled.div`
  margin: 0 16px;
  padding-top: 20px;
  border-bottom: 1px solid #d7d7da;
`;

const MenuContainer = styled(Grid).attrs({ container: true })`
  padding: 0 8px;
`;

const MenuHeadContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 66px;
  height: 66px;
`;

const MenuHead = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-image: url(${p => p.src});
  background-color: ${colorsConst.DISABLED};
  padding-top: 100%;
`;
const MenuPriceContainer = styled(Grid).attrs({ item: true })`
  margin-left: 12px;
  width: 100%;
  max-width: calc(100% - ${66 + 12}px);
`;

const MenuName = styled.div`
  padding: 0 0 5px;
  font-size: 16px;
  line-height: 23px;
  ${ellipsis()}
`;

const MenuPrice = styled(Grid).attrs({ item: true, shrink: "0" })`
  margin-left: auto;
  font-size: 16px;
  line-height: 23px;
`;

const OptionList = styled.ul`
  flex: 1;
`;

const OptionListItem = styled.li`
  margin-bottom: 8px;
  margin-left: 17px;
  text-align: right;
`;

const EditButtonList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 8px;
  padding-top: 11px;
`;

const QuantitySelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 27px;
  padding: 4px;
  border: 2px solid ${colorsConst.MAIN};
  border-radius: 3px;
`;

const QuantitySelect = styled.select`
  padding: 0 4px 0 2px;
  padding-right: 17px;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: ${colorsConst.MAIN};
`;

const ChevronDownContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 7px;
`;

const ChevronDown = styled.div`
  border-width: 0px 2px 2px 0px;
  height: 10px;
  transform: rotate(45deg);
  width: 10px;
  border-color: ${colorsConst.MAIN};
  border-style: solid;
`;

const DeleteItemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  padding: 4px;
  width: 42px;
  height: 27px;
  font-size: 14px;
  border: 2px solid #c0c0c7;
  color: #c0c0c7;
  border-radius: 3px;
`;

const InstructionText = styled.div<{ placeHolder?: boolean }>`
  margin: 0 16px;
  padding: 12px 8px;
  font-size: 14px;
  line-height: 19px;
  color: ${p => (p.placeHolder ? "#9D9DA2" : "#272727")};
  border-bottom: 1px solid #d7d7da;
`;

interface IProps {
  item: OrderItem;
  index: number;
  cartId: string;
  submittingDeleteItem: boolean;
  submittingChangeQuantity: boolean;
  handleChangeOrderQuantity: (index: number, quantity: number) => void;
  handleDeleteItem: (index: number) => void;
  children?: never;
}

const CartItem: React.FC<IProps> = React.memo(props => {
  const {
    item,
    cartId,
    index,
    handleChangeOrderQuantity,
    submittingChangeQuantity,
    submittingDeleteItem,
    handleDeleteItem
  } = props;

  const optionSetList = useMemo(() => item.getOptionSetList(), [item]);

  const selectableQuantityRange = rangeInt(
    ORDERABLE_COUNT_MIN,
    ORDERABLE_COUNT_MAX + 1
  );

  const handleChangeQuantity = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const quantity = parseInt(event.currentTarget.value, 10);
      handleChangeOrderQuantity(index, quantity);
      document.body.scrollTop = 0;
    },
    [handleChangeOrderQuantity, index]
  );

  const handleDeleteItemWrap = useCallback(() => {
    handleDeleteItem(index);
  }, [handleDeleteItem, index]);

  const imageContent = useCallback(() => {
    return (
      <>
        <MenuHeadContainer>
          <MenuHead src={item.getImagePath()} />
        </MenuHeadContainer>
      </>
    );
  }, [item]);

  const optionContent = useCallback(() => {
    return (
      <Grid container direction="row" align="flex-start">
        <Grid item rate={1} style={{ overflow: "hidden" }}>
          <OptionList>
            {optionSetList.map((optionSet: ShopOptionSet, optionSetIndex) => {
              const selectedOptionList = optionSet.getSelectedOptionList();
              return (
                <OptionListItem key={optionSetIndex}>
                  {typeof selectedOptionList !== "undefined" &&
                  selectedOptionList.size > 0
                    ? selectedOptionList.map(
                        (option: ShopOption, optionIndex) => (
                          <OptionMinimalInfo
                            key={optionIndex}
                            option={option}
                          />
                        )
                      )
                    : null}
                </OptionListItem>
              );
            })}
          </OptionList>
        </Grid>
      </Grid>
    );
  }, [optionSetList]);

  return (
    <div>
      <MenuWrapContainer>
        <Link to={`/cart/${cartId}/edit/${index}`}>
          <MenuContainer>
            <Grid item grow={0}>
              {imageContent()}
            </Grid>
            <MenuPriceContainer>
              <Grid container>
                <MenuName>{item.getItemName()}</MenuName>
                <MenuPrice>
                  {Price.getPresentationValue(item.getUnitPrice())}
                </MenuPrice>
              </Grid>
              {optionContent()}
            </MenuPriceContainer>
          </MenuContainer>
        </Link>
        <EditButtonList>
          <QuantitySelectContainer>
            {submittingChangeQuantity ? (
              <Loading size={4} />
            ) : (
              <>
                <QuantitySelect
                  onChange={handleChangeQuantity}
                  onBlur={fixBodyScrollTopWhenInputBlurred}
                  value={`${item.getOrderCount()}`}
                >
                  {selectableQuantityRange.map(i => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </QuantitySelect>
                <ChevronDownContainer>
                  <ChevronDown />
                </ChevronDownContainer>
              </>
            )}
          </QuantitySelectContainer>
          <DeleteItemButton onClick={handleDeleteItemWrap}>
            {submittingDeleteItem ? (
              <Loading size={4} color="#C0C0C7" />
            ) : (
              "削除"
            )}
          </DeleteItemButton>
        </EditButtonList>
      </MenuWrapContainer>
      <Link to={`/cart/${cartId}/edit/${index}`}>
        {item.getInstructionText() ? (
          <InstructionText>{item.getInstructionText()}</InstructionText>
        ) : (
          <InstructionText placeHolder>
            お店への連絡事項はこちらにご記入ください。例「お箸は必要ありません」など
          </InstructionText>
        )}
      </Link>
    </div>
  );
});

export default CartItem;
