import * as React from "react";

interface IProps extends React.SVGAttributes<SVGElement> {
  children?: never;
}

const SvgMyp = (props: IProps) => {
  const { ...rest } = props;
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...rest}>
      <rect x="0.5" y="0.5" width="19" height="14" stroke="#3A3A47" />
      <path d="M0.5 1L10 10.5L19.5 1" stroke="black" />
    </svg>
  );
};

const MailIcon: React.FC<IProps> = props => <SvgMyp {...props} />;

export default MailIcon;
