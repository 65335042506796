import { Record } from "immutable";

export interface IInstallCoupon {
  code: string;
}

const initial: IInstallCoupon = {
  code: ""
};

export default class InstallCoupon extends Record(initial) {
  public getCode() {
    return this.get("code");
  }
  public existsCode() {
    return this.getCode() !== "";
  }
}
