import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import colorsConst from "../../styles/const/colorsConst";
import Loading from "../molecules/Loading";

interface IContainerProps {
  block?: boolean;
  grayout?: boolean;
  secondary?: boolean;
  cancel?: boolean;
  positionFooter?: boolean;
  tertiary?: boolean;
  transparent?: boolean;
}

const getTextColor = (p: IContainerProps) => {
  if (p.grayout) {
    return colorsConst.BUTTON.TEXT.GRAYOUT;
  }
  if (p.secondary) {
    return colorsConst.BUTTON.TEXT.SECONDARY;
  }
  if (p.cancel) {
    return "#fff";
  }
  return colorsConst.BUTTON.TEXT.DEFAULT;
};

const getBackgroundColor = (p: IContainerProps) => {
  if (p.grayout) {
    return colorsConst.BUTTON.BACKGROUND.GRAYOUT;
  }
  if (p.secondary) {
    return colorsConst.BUTTON.BACKGROUND.SECONDARY;
  }
  if (p.tertiary) {
    return colorsConst.BUTTON.BACKGROUND.TERTIARY;
  }
  if (p.transparent) {
    return "transparent";
  }
  if (p.cancel) {
    return "#000";
  }
  return colorsConst.MAIN;
};

const Container = styled("button")<IContainerProps>`
  display: ${props => (props.block ? "block" : "inline-block")};
  width: ${props => (props.block ? "100%" : "auto")};
  ${p => (p.positionFooter ? "" : "border-radius: 4px;")}
  padding: ${p => (p.transparent ? "0 20px" : "20px")};
  color: ${getTextColor};
  background-color: ${getBackgroundColor};
  ${p => (p.transparent ? "background-color: transparent" : "")};
  font-size: ${p => (p.positionFooter ? "1.6rem" : "1.4rem")};
  ${p => (p.positionFooter ? "line-height: 23px;" : "")}
  touch-action: manipulation;
  &:active {
    background-color: ${p =>
      lighten(
        0.25,
        p.secondary ? colorsConst.BUTTON.BACKGROUND.SECONDARY : colorsConst.MAIN
      )};
  }
  &:disabled {
    ${p =>
      !p.grayout
        ? `background-color: ${colorsConst.BUTTON.BACKGROUND.DISABLED};`
        : ""}
  }
`;

interface IProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IContainerProps {
  loading?: boolean;
}

const Button: React.FC<IProps> = React.memo(
  ({
    loading,
    children,
    secondary,
    tertiary,
    transparent,
    disabled,
    grayout,
    ...rest
  }) => (
    <Container
      {...rest}
      disabled={loading || disabled || grayout}
      grayout={grayout}
      secondary={secondary}
      tertiary={tertiary}
      transparent={transparent}
    >
      {loading ? <Loading invert /> : children}
    </Container>
  )
);

export default Button;
