import { List } from "immutable";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Area from "../../records/Area";

const Container = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  padding: 5px;
  font-size: 19px;
  line-height: ${28 / 19};
  font-weight: 500;
  color: #595959;
  border-bottom: 2px solid #ff3008;
`;

const AreaList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -3px;
  margin-left: -3px;
`;

const AreaListItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: ${100 / 3}%;
  padding: 3px;
`;

const AreaLink = styled(Link)<{ src: string }>`
  display: block;
  color: #fff;
  line-height: 1;
  font-weight: bold;
  border-radius: 5px;
  background-image: url(${p => p.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(70 / 375) * 100}vw;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const AreaName = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
`;

const AreaShopNum = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;
const AreaShopNumVal = styled.div`
  font-size: 36px;
  margin-bottom: -7px;
`;
const AreaShopNumUnit = styled.div`
  font-size: 10px;
  width: 1em;
  margin-left: 2px;
`;

interface IProps {
  areaList: List<Area>;
}

const HomeAreaList: React.FC<IProps> = React.memo(({ areaList }) => {
  return (
    <Container>
      <Title>エリアから選ぶ</Title>
      <AreaList>
        {areaList.map(area => (
          <AreaListItem key={area.getId()}>
            <AreaLink to={`/area/${area.getId()}`} src={area.getImagePath()}>
              <OverlayContainer>
                <AreaName>{area.getName()}</AreaName>
                <AreaShopNum>
                  <AreaShopNumVal>{area.getShopCount()}</AreaShopNumVal>
                  <AreaShopNumUnit>店舗</AreaShopNumUnit>
                </AreaShopNum>
              </OverlayContainer>
            </AreaLink>
          </AreaListItem>
        ))}
      </AreaList>
    </Container>
  );
});

export default HomeAreaList;
