import React, { useMemo } from "react";
import OrderData, {
  EOrderTimeType,
  EOrderState
} from "../../records/OrderData";
import CommonNotice from "../atoms/CommonNotice";

interface IProps {
  order: OrderData;
  luxuryWaitInductionText: string;
}

const OrderNotice = React.memo((props: IProps) => {
  const { order, luxuryWaitInductionText } = props;

  const state = order.getOrderState();

  const data: {
    type: "warning" | "primary";
    message: string;
  } | null = useMemo(() => {
    switch (state) {
      case EOrderState.ORDERED:
        return {
          type: "warning",
          message: `注文内容をお店に連絡しています。　調理開始まで少々お待ちください。${
            order.getTimeType() === EOrderTimeType.FASTEST
              ? "（最大5分程度）"
              : ""
          }`
        };
      case EOrderState.FINISHED_LUXURY:
        return {
          type: "warning",
          message: luxuryWaitInductionText
        };
      default:
        return null;
    }
  }, [luxuryWaitInductionText, state]);

  return data !== null && data.message !== "" ? (
    <CommonNotice type={data.type}>{data.message}</CommonNotice>
  ) : null;
});

export default OrderNotice;
