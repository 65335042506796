import { goBack } from "connected-react-router";
import { useCallback, useEffect } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { EApiKey } from "../../apis";
import { EFormID } from "../../forms";
import {
  IFixTelForm,
  initialFixTelForm,
  validateFixTelForm
} from "../../forms/fixTel";
import { userTouchedRequestToResentSMSAuthCodeForResetPasswordButton } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import spaceConst from "../../styles/const/spaceConst";
import Utility from "../../util/Utility";
import Button from "../atoms/Button";
import LabelText from "../atoms/LabelText";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import SubPageHeader from "../molecules/SubPageHeader";
import TextField from "../organisms/FormFields/TextField";

class ActionDispatcher {
  constructor(private dispatch: (action: ReduxAction) => void) {}

  public goBack() {
    this.dispatch(goBack());
  }

  public userTouchedRequestToResentSMSAuthCodeButton(tel: string) {
    this.dispatch(
      userTouchedRequestToResentSMSAuthCodeForResetPasswordButton(tel)
    );
  }
}

interface IPropsBase {
  submittingPublishSmsByTel: boolean;
  submittingUpdateTel: boolean;
  tel: string;
  formValues: IFixTelForm;
  actions: ActionDispatcher;
}

interface IProps
  extends InjectedFormProps<IFixTelForm, IPropsBase>,
    IPropsBase {}

const ResendSMSAuthCodeTemplate = React.memo((props: IProps) => {
  const {
    tel,
    formValues,
    submittingPublishSmsByTel,
    submittingUpdateTel,
    actions
  } = props;

  const setDefaultValue = useCallback(() => {
    if (tel !== "") {
      props.change("tel", tel);
    }
  }, [tel]);

  useEffect(() => {
    setDefaultValue();
  }, []);

  const requestToResentSMSAuthCode = useCallback(() => {
    actions.userTouchedRequestToResentSMSAuthCodeButton(formValues.tel);
  }, [actions, formValues.tel]);

  const handleGoBack = useCallback(() => {
    actions.goBack();
  }, [actions]);

  return (
    <>
      <Page
        header={
          <SubPageHeader
            title="ショートメール再送信"
            handleClickBack={handleGoBack}
          />
        }
      >
        <PageBody>
          <LabelText>携帯電話番号</LabelText>
          <TextField
            name="tel"
            type="number"
            placeholder="000 0000 0000"
            autoComplete="tel"
          />
          <div
            style={{
              marginTop: spaceConst.GUTTER.OVER_BUTTON_WITH_ERROR_TEXT
            }}
          >
            <Button
              block
              loading={submittingPublishSmsByTel || submittingUpdateTel}
              onClick={requestToResentSMSAuthCode}
            >
              SMSを再送信
            </Button>
          </div>
        </PageBody>
      </Page>
    </>
  );
});

const mapStateToProps = (state: ReduxModel) => ({
  formValues: Utility.getReduxFormValues<IFixTelForm>(
    state,
    EFormID.FIX_TEL,
    initialFixTelForm
  ),
  tel: state.user.getData().getTel(),
  submittingPublishSmsByTel: state.app.isConnectedApi(
    EApiKey.PUBLISH_SMS_BY_TEL
  ),
  submittingUpdateTel: state.app.isConnectedApi(EApiKey.UPDATE_TEL)
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  actions: new ActionDispatcher(dispatch)
});

const reduxFormConfig = {
  form: EFormID.FIX_TEL,
  initialValues: initialFixTelForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: validateFixTelForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<IFixTelForm, IPropsBase>(reduxFormConfig)(ResendSMSAuthCodeTemplate)
);
