import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EApiKey } from "../../apis";
import { systemClosedModal } from "../../modules/app/actions";
import {
  userAccessedToPageThatNeedsOrderDetail,
  userSubmittedCancelOrder,
  userTouchedNotifyArrivalForLuxuryShop
} from "../../modules/order/actions";
import OrderData, { EOrderState } from "../../records/OrderData";
import ShopData from "../../records/ShopData";
import { ReduxModel } from "../../reducer";
import colorsConst from "../../styles/const/colorsConst";
import CordovaUtil from "../../util/CordovaUtil";
import Button from "../atoms/Button";
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from "../atoms/Dialog";
import Notes from "../atoms/Notes";
import Modal from "../molecules/Modal";
import FeedbackDialog from "./FeedbackDialog";

const dialogStyle: React.CSSProperties = {
  maxHeight: "100%"
};

const innerStyle: React.CSSProperties = {
  padding: "35px"
};
const luxuryDialogStyle: React.CSSProperties = {
  maxHeight: "100%",
  padding: 0,
  borderRadius: "4px"
};

const LuxuryContant = styled.div`
  font-size: 17px;
  line-height: 24px;
  padding: 25px 22px 20px;
  text-align: center;
`;

const luxulyButtonStyle: React.CSSProperties = {
  fontSize: "17px",
  lineHeight: "140%",
  width: "100%",
  borderRadius: "0 0 4px 4px",
  padding: "13px",
  letterSpacing: "1px",
  marginBottom: 0
};

const luxulyCloseButtonStyle: React.CSSProperties = {
  color: colorsConst.MAIN,
  backgroundColor: "transparent",
  borderTop: "1px solid #CDCDD2"
};

const pushNotificationDialogStyle: React.CSSProperties = {
  borderRadius: "4px",
  padding: "0"
};

const pushNotificationDialogHeaderStyle: React.CSSProperties = {
  fontSize: "16px",
  lineHeight: "23px",
  letterSpacing: "1px",
  textAlign: "center",
  padding: "23px 0 0 0",
  marginBottom: "0"
};

const pushNotificationDialogBodyStyle: React.CSSProperties = {
  fontSize: "13px",
  lineHeight: "19px",
  letterSpacing: "1px",
  color: "#707070",
  textAlign: "center",
  padding: "9px 21px 23px"
};

const pushNotificationDialogFooterStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  marginTop: "0"
};

const pushNotificationLeftButtonStyle: React.CSSProperties = {
  padding: "10px 0 13px 0",
  borderRadius: "0 0 0 4px",
  color: "#707070",
  fontSize: "15px",
  lineHeight: "22px",
  background: "#ffffff",
  borderTop: "solid 1px #CDCDD2",
  borderRight: "solid 1px #CDCDD2"
};

const userArrivedDialogFooterStyle: React.CSSProperties = {
  marginTop: 0
};

const pushNotificationRightButtonStyle: React.CSSProperties = {
  padding: "10px 0 13px 0",
  borderRadius: "0 0 4px 0",
  color: "#FF6056",
  fontSize: "15px",
  lineHeight: "22px",
  background: "#ffffff",
  borderTop: "solid 1px #CDCDD2"
};

interface IProps extends RouteComponentProps<{ orderNumber: string }> {
  orderNumber: string;
  currentOrderDetail: OrderData;
  openCancelOrderDialogFlag: boolean;
  openSendArrivedDialogFlag: boolean;
  openPushNotificationPermissionDialogFlag: boolean;
  submittingCancelOrder: boolean;
  submittingNotifyArrivalForLuxuryShop: boolean;
  currentShopData: ShopData;
  actions: ActionDispatcher;
  children?: never;
}

const OrderDetailModal: React.FC<IProps> = React.memo(props => {
  const {
    orderNumber,
    openCancelOrderDialogFlag,
    openSendArrivedDialogFlag,
    openPushNotificationPermissionDialogFlag,
    currentOrderDetail,
    submittingCancelOrder,
    submittingNotifyArrivalForLuxuryShop,
    currentShopData,
    actions
  } = props;

  const handleCloseCancelOrder = useCallback(() => {
    actions.userTouchedCloseCancelOrderDialogButton();
  }, [actions.userTouchedCloseCancelOrderDialogButton]);

  const handeleCloseArrived = useCallback(() => {
    actions.userTouchedCloseSendArrivedDialogButton();
  }, [actions.userTouchedCloseSendArrivedDialogButton]);

  const handleSubmitCancelOrder = useCallback(() => {
    actions.userSubmittedCancelOrder(orderNumber);
  }, [actions, orderNumber]);

  const handleSubmitArrived = useCallback(() => {
    actions.userTouchedNotifyArrivalForLuxuryShop(
      currentOrderDetail.getOrderNumber()
    );
  }, [currentOrderDetail, actions.userTouchedNotifyArrivalForLuxuryShop]);

  const notifiedForLuxuryShop =
    currentOrderDetail.getOrderState() === EOrderState.UER_ARRIVALED ||
    currentOrderDetail.getOrderState() === EOrderState.CONFIRM_USER_ARRIVAL;

  const handleClosePushNotificationPermissionDialog = useCallback(() => {
    actions.userTouchedClosePushNotificationPermissionDialogButton();
  }, [actions.userTouchedClosePushNotificationPermissionDialogButton]);

  const handleSettingPushNotificationPermission = useCallback(() => {
    if (CordovaUtil.isCordovaEnabled()) {
      CordovaUtil.openAppNotificationSettings();
    } else {
      actions.userTouchedClosePushNotificationPermissionDialogButton();
    }
  }, [actions.userTouchedClosePushNotificationPermissionDialogButton]);

  useEffect(() => {
    actions.userAccessedToPageThatNeedsOrderDetail(orderNumber);
  }, [orderNumber]);

  return (
    <>
      <Modal
        isOpen={openCancelOrderDialogFlag}
        innerStyle={innerStyle}
        handleClickBackdrop={handleCloseCancelOrder}
      >
        <Dialog style={dialogStyle} handleCloseButton={handleCloseCancelOrder}>
          <DialogHeader>注文キャンセル</DialogHeader>
          <DialogBody>
            <Notes>
              キャンセル依頼を出しますか？
              <br />
              店舗が注文を承認すると
              <br />
              キャンセルできません。
            </Notes>
          </DialogBody>
          <DialogFooter>
            <Button
              block
              loading={submittingCancelOrder}
              secondary
              onClick={handleSubmitCancelOrder}
            >
              決定する
            </Button>
          </DialogFooter>
        </Dialog>
      </Modal>

      <FeedbackDialog orderData={currentOrderDetail} />

      <Modal
        isOpen={openSendArrivedDialogFlag}
        innerStyle={innerStyle}
        handleClickBackdrop={handeleCloseArrived}
      >
        <Dialog style={luxuryDialogStyle}>
          <DialogBody>
            <LuxuryContant>
              {notifiedForLuxuryShop
                ? `お店にお知らせしました。
                少々お待ちください。10分待って店員が来なければ、入店してください`
                : currentShopData.getLuxuryWaitInductionText() === ""
                ? `到着しましたら下記ボタンを押してお知らせください。`
                : `${currentShopData.getLuxuryWaitInductionText()}`}
            </LuxuryContant>
          </DialogBody>
          <DialogFooter style={userArrivedDialogFooterStyle}>
            {notifiedForLuxuryShop ? (
              <Button
                style={{ ...luxulyButtonStyle, ...luxulyCloseButtonStyle }}
                onClick={handeleCloseArrived}
              >
                閉じる
              </Button>
            ) : (
              <Button
                style={luxulyButtonStyle}
                onClick={handleSubmitArrived}
                loading={submittingNotifyArrivalForLuxuryShop}
              >
                到着を通知する
              </Button>
            )}
          </DialogFooter>
        </Dialog>
      </Modal>

      <Modal
        isOpen={openPushNotificationPermissionDialogFlag}
        innerStyle={innerStyle}
        handleClickBackdrop={handleClosePushNotificationPermissionDialog}
      >
        <Dialog style={pushNotificationDialogStyle}>
          <DialogHeader style={pushNotificationDialogHeaderStyle}>
            ご注文状況を通知しますか？
          </DialogHeader>
          <DialogBody style={pushNotificationDialogBodyStyle}>
            プッシュ通知をオンにすると
            <br />
            調理開始・完了などご注文状況を
            <br />
            お知らせします。
          </DialogBody>
          <DialogFooter style={pushNotificationDialogFooterStyle}>
            <Button
              block
              secondary
              positionFooter
              onClick={handleClosePushNotificationPermissionDialog}
              style={pushNotificationLeftButtonStyle}
            >
              いいえ
            </Button>
            <Button
              block
              cancel
              positionFooter
              onClick={handleSettingPushNotificationPermission}
              style={pushNotificationRightButtonStyle}
            >
              はい
            </Button>
          </DialogFooter>
        </Dialog>
      </Modal>
    </>
  );
});

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ orderNumber: string }>
) => ({
  orderNumber: ownProps.match.params.orderNumber,
  currentOrderDetail: state.order.getCurrentOrderDetail(),
  openCancelOrderDialogFlag: state.app
    .getModalManager()
    .canDisplay("CANCEL_ORDER"),
  openSendArrivedDialogFlag: state.app
    .getModalManager()
    .canDisplay("SEND_ARRIVED"),
  openPushNotificationPermissionDialogFlag: state.app
    .getModalManager()
    .canDisplay("PUSH_NOTIFICATION"),
  submittingCancelOrder: state.app.isConnectedApi(EApiKey.CANCEL_ORDER),
  submittingNotifyArrivalForLuxuryShop: state.app.isConnectedApi(
    EApiKey.ARRIVAL_FOR_LUXURY
  ),
  currentShopData: state.search.getCurrentShopData()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: new ActionDispatcher(dispatch)
});

class ActionDispatcher {
  constructor(private dispatch: Dispatch) {}

  public userAccessedToPageThatNeedsOrderDetail(orderNumber: string) {
    this.dispatch(userAccessedToPageThatNeedsOrderDetail(orderNumber));
  }

  public userTouchedCloseCancelOrderDialogButton() {
    this.dispatch(systemClosedModal("CANCEL_ORDER"));
  }

  public userSubmittedCancelOrder(orderNumber: string) {
    this.dispatch(userSubmittedCancelOrder(orderNumber));
  }

  public userTouchedCloseSendArrivedDialogButton() {
    this.dispatch(systemClosedModal("SEND_ARRIVED"));
  }
  public userTouchedNotifyArrivalForLuxuryShop(orderNumber: string) {
    this.dispatch(userTouchedNotifyArrivalForLuxuryShop(orderNumber));
  }
  public userTouchedClosePushNotificationPermissionDialogButton() {
    this.dispatch(systemClosedModal("PUSH_NOTIFICATION"));
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderDetailModal)
);
