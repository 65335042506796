import { goBack } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled, { css } from "styled-components";
import { EApiKey } from "../../apis";
import {
  userAccessedToPreOrderPage,
  userChangedPreOrderCount,
  userChangedPreOrderOptionList,
  userChangedPreOrderText,
  userSubmittedUpdateCart,
  userTouchedDeleteCartItemButton
} from "../../modules/order/actions";
import { userAccessedShopPage } from "../../modules/search/actions";
import CartData from "../../records/CartData";
import { ReduxAction, ReduxModel } from "../../reducer";
import Analytics from "../../util/Analytics";
import ArrowIcon from "../atoms/ArrowIcon";
import HeaderContainer from "../atoms/HeaderContainer";
import Page from "../atoms/Page";
import PageBody from "../atoms/PageBody";
import Loading from "../molecules/Loading";
import Auth from "../organisms/Auth";
import ShopItemViewer from "../organisms/ShopItemViewer";
import ShopItemViewerFooter from "../organisms/ShopItemViewerFooter";
import ShopMenuContainer from "../organisms/ShopMenuContainer";

const pageBodyStyle: React.CSSProperties = {
  padding: 0,
  height: "100%"
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackIconContainer = styled.div`
  position: absolute;
  top: calc(10px + constant(safe-area-inset-top));
  top: calc(10px + env(safe-area-inset-top));
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  cursor: pointer;
  font-size: 19px;
`;

const shopImageLinkStyle = css`
  padding-top: ${(380 / 375) * 100}%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 5%,
    rgba(255, 255, 255, 0) 50%
  );
`;

const BlankContainer = styled.div`
  position: relative;
`;
const BlankImg = styled.div`
  ${shopImageLinkStyle}
`;
const BlankInfo = styled.div`
  text-align: center;
  color: #757575;
  padding-bottom: 40px;
`;

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ cartId: string; id: string }>
) => ({
  submittingDeleteCartItem: state.app.isConnectedApi(
    EApiKey.DELETE_CART_LIST_ITEM
  ),
  submittingUpdateCart: state.app.isConnectedApi(EApiKey.UPDATE_CART_LIST),
  fetchingShopData: state.app.isConnectedApi(
    EApiKey.GET_SHOP_DATA_WITH_TIME_TYPE
  ),
  cartList: state.order.getCartList(),
  search: state.search,
  options: state.order.getPreOrderOptionList(),
  instructionText: state.order.getPreOrderInstructionText(),
  count: state.order.getPreOrderCount(),
  cartId: ownProps.match.params.cartId,
  id: parseInt(ownProps.match.params.id, 10),
  shopData: state.search.getCurrentShopData()
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  handleClickGoBack() {
    dispatch(goBack());
  },
  submitUpdateCartItem(
    cartId: string,
    index: number,
    count: number,
    optionList: number[],
    instructionText: string
  ) {
    dispatch(
      userSubmittedUpdateCart(cartId, index, count, optionList, instructionText)
    );
  },
  initPreOrderState(initial: {
    option_list: number[];
    count: number;
    instruction_text: string;
  }) {
    dispatch(userAccessedToPreOrderPage(initial));
  },
  deleteCartItem(cartId: string, deleteKey: number) {
    dispatch(userTouchedDeleteCartItemButton(cartId, deleteKey, true));
  },
  changePreOrderOptionList(list: number[]) {
    dispatch(userChangedPreOrderOptionList(list));
  },
  changePreOrderInstructionText(text: string) {
    dispatch(userChangedPreOrderText(text));
  },
  changePreOrderCount(count: number) {
    dispatch(userChangedPreOrderCount(count));
  },
  fetchShopData(shopId: number) {
    dispatch(userAccessedShopPage(shopId));
  }
});

interface IProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps<{ cartId: string; id: string }> {}

const CartEditTemplate = React.memo((props: IProps) => {
  const {
    cartId,
    id,
    cartList,
    search,
    submittingDeleteCartItem,
    submittingUpdateCart,
    options,
    instructionText,
    count,
    handleClickGoBack,
    submitUpdateCartItem,
    initPreOrderState,
    deleteCartItem,
    changePreOrderOptionList,
    changePreOrderInstructionText,
    changePreOrderCount,
    fetchShopData,
    fetchingShopData
  } = props;

  const cart = useMemo(() => {
    return CartData.findById(cartList, cartId);
  }, [cartList, cartId]);

  const cartItem = useMemo(() => {
    if (typeof cart === "undefined") {
      return undefined;
    }
    return cart.getOrderItemById(id);
  }, [cart, id]);

  const shopId = useMemo(() => {
    if (typeof cart === "undefined") {
      return undefined;
    }
    return cart.getShopId();
  }, [cart]);

  const shopItem = useMemo(() => {
    if (typeof cartItem === "undefined") {
      return undefined;
    }
    return search.findItemData(cartItem.getShopItemId());
  }, [search, cartItem]);

  const optionList = useMemo(() => {
    if (!cartItem) {
      return [];
    }
    return cartItem.getSelectedOptionIdArray();
  }, [cartItem]);

  const [optionListError, setOptionListError] = useState(false);

  const handleDeleteCartItem = useCallback(() => {
    if (typeof cart === "undefined") {
      return;
    }
    Analytics.logEvent("cart_delete");
    deleteCartItem(cart.getId(), id);
  }, [cart, deleteCartItem, id]);

  const handleSubmit = useCallback(() => {
    submitUpdateCartItem(cartId, id, count, optionList, instructionText);
  }, [cartId, submitUpdateCartItem, id, count, optionList, instructionText]);

  const handleChangePreOrderOption = useCallback(
    (list: number[]) => {
      changePreOrderOptionList(list);
    },
    [changePreOrderOptionList]
  );

  const handleChangePreOrderInstructionText = useCallback(
    (text: string) => {
      changePreOrderInstructionText(text);
    },
    [changePreOrderInstructionText]
  );

  const handleChangePreOrderCount = useCallback(
    (preOrderCount: number) => {
      changePreOrderCount(preOrderCount);
    },
    [changePreOrderCount]
  );

  const handleMountViewer = useCallback(() => {
    if (cartItem) {
      initPreOrderState({
        count: cartItem.getOrderCount(),
        option_list: optionList,
        instruction_text: cartItem.getInstructionText()
      });
    }
  }, [cartItem, initPreOrderState, optionList]);

  const handleValidateOptionList = useCallback(
    (disable: boolean) => {
      setOptionListError(disable);
    },
    [optionListError, setOptionListError]
  );

  useEffect(() => {
    if (typeof shopId !== "undefined") {
      fetchShopData(shopId);
    }
  }, [shopId, fetchShopData]);

  return (
    <Auth>
      <Page
        footer={
          typeof cart === "undefined" ? (
            undefined
          ) : (
            <ShopItemViewerFooter
              item={shopItem}
              shopId={cart.getShopId()}
              options={options}
              notes={instructionText}
              quantity={count}
              stateText={
                optionListError
                  ? "オプションを確認してください"
                  : "カートの更新"
              }
              submitButtonLabel="カートの更新"
              handleSubmit={handleSubmit}
              connectingDeleteFromCart={submittingDeleteCartItem}
              connectingAddOrUpdateItem={submittingUpdateCart}
              disabled={optionListError}
            />
          )
        }
      >
        <PageBody style={pageBodyStyle}>
          <HeaderContainer transparent shopItem>
            <BackIconContainer onClick={handleClickGoBack}>
              <ArrowIcon invert />
            </BackIconContainer>
          </HeaderContainer>
          {fetchingShopData ? (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          ) : !(cart && cartItem && shopItem) ? (
            <BlankContainer>
              <BlankImg />
              <BlankInfo>メニューが見つかりません</BlankInfo>
            </BlankContainer>
          ) : (
            <ShopMenuContainer isCartEdit>
              <ShopItemViewer
                shopId={cart.getShopId()}
                shopData={cart.getShopData()}
                item={shopItem}
                options={options}
                quantity={count}
                notes={instructionText}
                handleDelete={handleDeleteCartItem}
                handleMount={handleMountViewer}
                handleChangePreOrderOptionList={handleChangePreOrderOption}
                handleChangePreOrderInstructionText={
                  handleChangePreOrderInstructionText
                }
                handleChangePreOrderCount={handleChangePreOrderCount}
                handleValidateOptionList={handleValidateOptionList}
                isCartEdit={true}
              />
            </ShopMenuContainer>
          )}
        </PageBody>
      </Page>
    </Auth>
  );
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CartEditTemplate)
);
