import { fork, join, put, select } from "redux-saga/effects";
import {
  searchApiKey,
  SearchApiRequest,
  SearchApiResponse
} from "../../../apis";
import { AlertType } from "../../../records/Alert";
import ReservationTime from "../../../records/ReservationTime";
import { SearchResultType } from "../../../records/SearchResult";
import { SearchSetting } from "../../../records/SearchSetting";
import SearchPlace from "../../../records/SearchSetting/SearchPlace";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert, systemOpenedModal } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateOnceSearched, updateSearchResult } from "../actions";
import { ILatLng } from "../model";
import fetchCurrentLocationSaga from "./fetchCurrentLocationSaga";
import updateSearchSettingSaga from "./updateSearchSettingSaga";
import requestGeolocationPermissionSaga from "./requestGeolocationPermissionSaga";
import ScrollTopMemory, { EScrollTopKey } from "../../../util/ScrollTopMemory";

export default function* getSearchResultSaga(
  searchResultType: SearchResultType,
  searchPlace?: SearchPlace,
  reservationTime?: ReservationTime
) {
  try {
    const searchSetting: SearchSetting = yield select<
      (state: ReduxModel) => SearchSetting
    >(state => state.search.getSearchSettings().getData(searchResultType));

    let needsCurrentLocation = false;
    if (typeof searchPlace !== "undefined" && searchPlace.isCurrentLocation()) {
      needsCurrentLocation = true;
    } else {
      const currentSearchPlace = yield select<
        (state: ReduxModel) => SearchPlace
      >(state =>
        state.search
          .getSearchSettings()
          .getData(searchResultType)
          .getSearchPlace()
      );
      if (currentSearchPlace.isCurrentLocation()) {
        needsCurrentLocation = true;
      }
    }

    if (needsCurrentLocation) {
      yield fork(() => requestGeolocationPermissionSaga());

      const fetchCurrentLocationTask = yield fork(() =>
        fetchCurrentLocationSaga()
      );
      yield join(fetchCurrentLocationTask);
    }

    const currentLocation: ILatLng | null = yield select<
      (state: ReduxModel) => ILatLng | null
    >(state => state.search.getCurrentLocation());

    const searchLocation = (typeof searchPlace === "undefined"
      ? searchSetting.getSearchPlace()
      : searchPlace
    ).getSearchLocationWithCurrentLocation(currentLocation);

    const searchRadius = searchSetting.getSearchRadius();

    const receiveDatetime = (typeof reservationTime === "undefined"
      ? searchSetting.getReservationTime()
      : reservationTime
    ).getDatetimeString();

    if (searchLocation === null) {
      throw new Error("location is null");
    }

    const params: SearchApiRequest[typeof searchResultType] = {
      latitude: `${searchLocation.lat}`,
      longitude: `${searchLocation.lng}`,
      distance: searchRadius
    };
    if (searchResultType === "reserve") {
      (params as SearchApiRequest["reserve"]).receive_datetime = receiveDatetime;
    }

    const commonApiTask = yield fork(() =>
      commonApiSaga(searchApiKey[searchResultType], params)
    );
    const {
      result,
      error
    }: {
      result: SearchApiResponse[typeof searchResultType];
      error: unknown;
    } = yield join(commonApiTask);

    if (error) {
      throw error;
    }

    if (searchResultType === "fastest" && result.shop_list.length === 0) {
      yield put(systemOpenedModal("ZERO_RESULT", {}));
    }

    yield fork(() =>
      updateSearchSettingSaga(
        searchResultType,
        searchLocation,
        searchSetting,
        searchPlace,
        reservationTime
      )
    );

    if (typeof searchPlace !== "undefined") {
      searchPlace.saveHistory();
    }
    /**
     * FIXME:fastest,reserve,keywordで共有してしまっているのをmain[fastest,reserve],sub,tempで個別に管理する
     */
    ScrollTopMemory.clear(`${EScrollTopKey.TEXT_SEARCH_RESULT}`);

    yield put(updateSearchResult(searchResultType, result.shop_list));
    yield put(updateOnceSearched(searchResultType));
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `結果の取得に失敗しました。
          通信環境を確認の上、再度お試し下さい。`
      })
    );
  }
}
