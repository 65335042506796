import moment from "moment";

export type Weekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;

// 無効な単品調理時間
export const NOT_EXIST_ITEM_COOKING_TIME = -1;

export default class EvilDatetime {
  /**
   * @param value HHmm
   *
   * @return HH:mm
   */
  public static toSettingTimeStringFromHHmm(value: string) {
    return [value.slice(0, 2), value.slice(-2)].join(":");
  }

  /**
   * @param value YYYYMMDD
   *
   * @return YYYY/MM/DD
   */
  public static toSettingDateStringFromYYYYMMDD(value: string) {
    const v = value;
    return [v.slice(0, 4), v.slice(4, 6), v.slice(-2)].join("/");
  }

  /**
   * @param value YYYY/MM/DD
   *
   * @return YYYYMMDD
   */
  public static toYYYYMMDDFromSettingDateString(value: string) {
    return value.split("/").join("");
  }

  /**
   * @param value HH:mm
   *
   * @return HHmm
   */
  public static toHHmmFromSettingTimeString(value: string) {
    return value.split(":").join("");
  }

  /**
   * 何日後までの予約注文が可能か
   */
  public static get DEFAULT_DATE_RANGE() {
    return 6;
  }

  /**
   * 日をまたぐ時刻を設定する
   */
  public static get DEFAULT_OFFSET_TIME() {
    return "06:00";
  }

  /**
   * 設定可能な時間の間隔
   */
  public static get DEFAULT_INTERVAL_TIME() {
    return 15;
  }

  /**
   * 予約する際に最低限必要な猶予
   * 現在時刻に分単位で加算される
   */
  public static get DEFAULT_RESERVATION_DELAY() {
    return 60;
  }

  /**
   * @return {string} YYYYMMDD
   */
  public static getDateStringByDate(date = new Date()) {
    return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}${String(date.getDate()).padStart(2, "0")}`;
  }

  /**
   * @return {string} HHmm
   */
  public static getTimeStringByDate(date = new Date()) {
    return `${String(date.getHours()).padStart(2, "0")}${String(
      date.getMinutes()
    ).padStart(2, "0")}`;
  }

  /**
   * @param timeString HHmm
   * @return {string} HH:mm
   */
  public static getHHmmStringByTimeString(timeString: string) {
    return `${timeString.substr(0, 2)}:${timeString.substr(2, 2)}`;
  }

  /**
   * @param receiveDate YYYYMMDD
   * @param receiveTime HHmm
   */
  public static getDisplaySpecifiedDateString(
    receiveDate: string,
    receiveTime: string
  ) {
    const date = EvilDatetime.getDateByYYYYMMDDHHmmString(
      receiveDate,
      receiveTime
    );
    const dotw = EvilDatetime.getWeekdayString(date);
    return `${moment(date).format(`M月D日(${dotw})HH:mm`)}`;
  }

  /**
   * @param offsetTime HH:mm
   * @param intervalTime mm
   *
   * @return HH:mm []
   */
  public static getTimeRange(isTail?: boolean) {
    const start = moment("00:00", "HH:mm");
    const end = isTail
      ? moment(EvilDatetime.DEFAULT_OFFSET_TIME, "HH:mm")
      : moment("00:00", "HH:mm").add(1, "day");

    const range: string[] = [];
    for (
      const time = start.clone();
      time.isBefore(end);
      time.add(EvilDatetime.DEFAULT_INTERVAL_TIME, "minute")
    ) {
      range.push(time.format("HH:mm"));
    }
    return range;
  }

  /**
   * @return YYYYMMDDHHmm
   */
  public static getFastestReservableDatetime() {
    const fastestDate = EvilDatetime.getFastestDate();
    const fastestTime = EvilDatetime.getFastestTime();
    return `${fastestDate}${fastestTime}`;
  }

  /**
   * 現在時刻から最短で設定可能な時間を取得する
   *
   * @return HHmm
   */
  public static getFastestTime(): string {
    const range = EvilDatetime.filterTimeRangeOnlyAfter(
      EvilDatetime.getTimeRange()
    );
    const fastest = range.shift();
    const time =
      typeof fastest !== "undefined" ? fastest : moment().format("HH:mm");
    return time.split(":").join("");
  }

  /**
   * @return YYYY/MM/DD []
   */
  public static getDateRange(now: Date = new Date()) {
    const inOffsetTime = EvilDatetime.inOffsetTime(now);
    const end = moment(now).add(
      inOffsetTime
        ? EvilDatetime.DEFAULT_DATE_RANGE + 1
        : EvilDatetime.DEFAULT_DATE_RANGE + 2,
      "day"
    );
    const range: string[] = [];
    for (const time = moment(now); time.isBefore(end); time.add(1, "day")) {
      range.push(time.format("YYYY/MM/DD"));
    }
    return range;
  }

  /**
   * 現在時刻から最短で設定可能な日付を取得する
   *
   * @return YYYYMMDD
   */
  public static getFastestDate(): string {
    const range = EvilDatetime.getDateRange();
    const fastest = range.shift();
    const date =
      typeof fastest !== "undefined" ? fastest : moment().format("YYYY/MM/DD");
    return date.split("/").join("");
  }

  /**
   * @param offsetTime HH:mm
   * @param intervalTime mm
   */
  public static getDateAndTimeRange() {
    const result = EvilDatetime.getDateRange().reduce<
      Array<[string, string[]]>
    >((prev, current, index, array) => {
      const isTail = index + 1 === array.length;
      prev.push([current, EvilDatetime.getTimeRange(isTail)]);
      return prev;
    }, []);
    return result;
  }

  /**
   * @param offsetTime HH:mm
   */
  public static inOffsetTime(now: Date = new Date()) {
    return moment(moment(now).format("HH:mm"), "HH:mm").isBefore(
      moment(EvilDatetime.DEFAULT_OFFSET_TIME, "HH:mm")
    );
  }

  public static getCurrentTimeByTimeRange(
    timeRange: string[],
    now: Date = new Date()
  ) {
    for (const time of timeRange.slice().reverse()) {
      if (moment(now).isAfter(moment(time, "HH:mm"))) {
        return time;
      }
    }
    return timeRange[0];
  }

  /**
   *
   * @param timeRange HH:mm[]
   * @param now Date
   *
   * @return HH:mm[]
   */
  public static filterTimeRangeOnlyAfter(
    timeRange: string[],
    now: Date = new Date()
  ) {
    return timeRange.filter(time => {
      const nowTime = moment(now)
        .add(EvilDatetime.DEFAULT_RESERVATION_DELAY, "minute")
        .format("HH:mm");
      return moment(time, "HH:mm").isAfter(moment(nowTime, "HH:mm"));
    });
  }

  public static getAfterTimeHHmmString(nextTime: string, currentTime: string) {
    return moment(nextTime, "HH:mm").isAfter(moment(currentTime, "HH:mm"))
      ? moment(nextTime, "HH:mm").format("HHmm")
      : moment(currentTime, "HH:mm").format("HHmm");
  }

  /**
   * サーバーサイドから受け取った日付のフォーマットをDateに変換する
   */
  public static getDateByYYYYMMDDHHmmString(YYYYMMDD: string, HHmm: string) {
    return moment(`${YYYYMMDD}${HHmm}`, "YYYYMMDDHHmm").toDate();
  }

  public static getPresentationalDateWord(date: Date, now: Date = new Date()) {
    const diffDateCount = EvilDatetime.getDiffDateCount(date, now);
    switch (diffDateCount) {
      case 0:
        return "本日";
      case 1:
        return "明日";
      case 2:
        return "明後日";
      default:
        return `${diffDateCount}日後`;
    }
  }

  /**
   * @param date YYYYMMDD
   */
  public static isToday(date: string, now: Date = new Date()) {
    return moment(now).format("YYYYMMDD") === date;
  }

  /**
   * 曜日の文字列を取得する
   */
  public static getWeekdayString(date: Date): string {
    switch (moment(date).day()) {
      case 0:
        return "日";
      case 1:
        return "月";
      case 2:
        return "火";
      case 3:
        return "水";
      case 4:
        return "木";
      case 5:
        return "金";
      case 6:
        return "土";
      default:
        return "";
    }
  }

  /**
   *  刻みの時刻を取得する
   *
   * @return HHmm
   */
  public static getValidTimeStringByDate(date = new Date()) {
    const isTail = EvilDatetime.isTailDate(date);
    const timeRange = EvilDatetime.getTimeRange(isTail);
    return EvilDatetime.getCurrentTimeByTimeRange(timeRange).replace(":", "");
  }

  public static getValidDateStringByDate(date = new Date()) {
    return EvilDatetime.getDateRange(date)[0].replace(/\//g, "");
  }

  public static isPastTime(date: Date, now = new Date()) {
    return date.getTime() < now.getTime();
  }

  public static getLocaleDayName(week: Weekday) {
    switch (week) {
      case 0:
        return "日曜日";
      case 1:
        return "月曜日";
      case 2:
        return "火曜日";
      case 3:
        return "水曜日";
      case 4:
        return "木曜日";
      case 5:
        return "金曜日";
      case 6:
        return "土曜日";
      default:
        return "";
    }
  }

  /**
   * 期間の最終日か
   */
  private static isTailDate(date = new Date()) {
    const dateString = EvilDatetime.getDateStringByDate(date);
    const dateRange = EvilDatetime.getDateRange(date);
    const lastDateString = dateRange.reverse().shift();
    return lastDateString
      ? lastDateString.replace("/", "") === dateString
      : false;
  }

  /**
   * 二つの日付から差分の日数を算出
   */
  private static getDiffDateCount(a: Date, b: Date) {
    const [A, B] = [
      moment(moment(a).format("YYYYMMDD"), "YYYYMMDD"),
      moment(moment(b).format("YYYYMMDD"), "YYYYMMDD")
    ];
    return A.diff(B, "day");
  }
}
