import { put } from "redux-saga/effects";
import { systemOpenedModal } from "../../app/actions";
import { updatePreOrderCartId, UserTouchedOrderButtonAction } from "../actions";

export default function* userTouchedOrderButtonSaga(
  action: UserTouchedOrderButtonAction
) {
  const { cartId } = action.payload;
  yield put(systemOpenedModal("CONFIRM_ORDER", {}));
  yield put(updatePreOrderCartId(cartId));
}
