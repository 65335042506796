import { go, goBack } from "connected-react-router";
import { List } from "immutable";
import { cancel, fork, join, put, select } from "redux-saga/effects";
import { EApiKey } from "../../../apis";
import {
  IDeleteCartListItemRequest,
  IDeleteCartListItemResponse
} from "../../../apis/deleteCartListItem";
import { AlertType } from "../../../records/Alert";
import CartData from "../../../records/CartData";
import { ReduxModel } from "../../../reducer";
import { systemAddedAlert } from "../../app/actions";
import commonApiSaga from "../../app/sagas/commonApiSaga";
import { updateCart, UserTouchedDeleteCartItemButtonAction } from "../actions";

export default function* userTouchedDeleteCartItemButtonSaga(
  action: UserTouchedDeleteCartItemButtonAction
) {
  try {
    const { cartId, deleteKey, isCartEdit } = action.payload;

    const cartList: List<CartData> = yield select<
      (state: ReduxModel) => List<CartData>
    >(state => state.order.getCartList());

    const cart = CartData.findById(cartList, cartId);

    if (typeof cart === "undefined") {
      throw new Error("cart is not found.");
    }

    const reservationTime = cart.getReservationTime();

    const params: IDeleteCartListItemRequest = {
      shop_id: cart.getShopId(),
      delete_key: deleteKey,
      time_type: reservationTime.getTimeType(),
      receive_datetime: reservationTime.getDatetimeString()
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(EApiKey.DELETE_CART_LIST_ITEM, params)
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error
    }: { result: IDeleteCartListItemResponse; error: unknown } = yield join(
      commonApiTask
    );
    if (result && !error) {
      if (typeof isCartEdit !== "undefined" && isCartEdit) {
        if (result.cart_list.length === 0) {
          yield put(go(-2));
        } else {
          yield put(goBack());
        }
      }
      yield put(updateCart(result.cart_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: AlertType.Danger,
        title: "エラー",
        message: `カート内アイテムの削除に失敗しました。
          通信環境を確認の上、再度お試しください。`
      })
    );
  }
}
